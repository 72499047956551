import React, { useEffect, useState, useContext } from 'react';
import {
  Box, Button, Dialog, DialogContent, DialogTitle,
  Stepper, Step, StepLabel, ToggleButtonGroup, ToggleButton,
  Table, TableBody, TableCell, TableHead, TableRow, TextField, MenuItem, IconButton
} from "@mui/material";
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/recruiting/recruiting';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import FilterListIcon from '@mui/icons-material/FilterList';
import { FilterContext } from '../../../provider/FilterProvider';

const Overview = () => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false); // Dialog-Zustand
  const [activeStep, setActiveStep] = useState(0); // Stepper-Zustand
  const [selectedDateRange, setSelectedDateRange] = useState([dayjs().subtract(2, 'year'), dayjs()]); // DateRangePicker-Zustand
  const [timeLevel, setTimeLevel] = useState('Monat'); // Zeitebene
  const [columns, setColumns] = useState([]); // Dynamische Spalten
  const [monthOptions, setMonthOptions] = useState([]); // Optionen für die Monatsauswahl
  const [weekdayOptions, setWeekdayOptions] = useState([]); // Optionen für die Wochentagsauswahl
  const [selectedMonth, setSelectedMonth] = useState('Alle'); // Ausgewählter Monat (Standard: Alle)
  const [selectedDay, setSelectedDay] = useState('Alle'); // Ausgewählter Wochentag (Standard: Alle)
  const [aggregationLevel, setAggregationLevel] = useState('Warengruppe'); // Aggregationsebene

  // Neue States für die Dropdown-Felder (Schritt 2)
  const [warengruppeOptions, setWarengruppeOptions] = useState([]);
  const [artikelgruppeOptions, setArtikelgruppeOptions] = useState([]);
  const [artikelOptions, setArtikelOptions] = useState([]);

  const [selectedWarengruppe, setSelectedWarengruppe] = useState('');
  const [selectedArtikelgruppe, setSelectedArtikelgruppe] = useState('');
  const [selectedArtikel, setSelectedArtikel] = useState('');

  // Neue States für Schritt 3 (Filiale)
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [verkaufsgebietOptions, setVerkaufsgebietOptions] = useState([]);
  const [selectedFiliale, setSelectedFiliale] = useState('');
  const [selectedVerkaufsgebiet, setSelectedVerkaufsgebiet] = useState('');
  const [filialeAggregationLevel, setFilialeAggregationLevel] = useState('Filiale'); // Aggregationsebene für Filiale

  const steps = ['Zeitdimension', 'Artikel', 'Filiale']; // Schritte des Assistenten
  const { filterState } = useContext(FilterContext);

  // Funktionen für den Dialog (Filter-Assistent)
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setActiveStep(0); // Zurück zum ersten Schritt
  };

  // Button-Handler für vordefinierte Zeiträume
  const handleLastYear = () => {
    setSelectedDateRange([dayjs().subtract(1, 'year'), dayjs()]);
  };

  const handleLast6Months = () => {
    setSelectedDateRange([dayjs().subtract(6, 'month'), dayjs()]);
  };

  // Zeitebene-Handler
  const handleTimeLevelChange = (event, newTimeLevel) => {
    if (newTimeLevel !== null) {
      setTimeLevel(newTimeLevel);
      setSelectedMonth('Alle'); // Ausgewählten Monat zurücksetzen
      setSelectedDay('Alle'); // Ausgewählten Wochentag zurücksetzen
    }
  };

  // Aggregationsebene-Handler (Schritt 2)
  const handleAggregationLevelChange = (event, newAggregationLevel) => {
    if (newAggregationLevel !== null) {
      setAggregationLevel(newAggregationLevel);
    }
  };

  // Handler für die Dropdown-Felder (Schritt 2)
  const handleWarengruppeChange = (event) => {
    setSelectedWarengruppe(event.target.value);
    // Hier könntest du einen API-Aufruf hinzufügen, um die Artikelgruppen basierend auf der ausgewählten Warengruppe zu laden
  };

  const handleArtikelgruppeChange = (event) => {
    setSelectedArtikelgruppe(event.target.value);
    // Hier könntest du einen API-Aufruf hinzufügen, um die Artikel basierend auf der ausgewählten Artikelgruppe zu laden
  };

  const handleArtikelChange = (event) => {
    setSelectedArtikel(event.target.value);
  };

  // Dynamische Spalten basierend auf Zeitebene und Zeitraum erstellen
  const generateColumns = () => {
    const start = selectedDateRange[0];
    const end = selectedDateRange[1];
    let columnArray = [];

    if (timeLevel === 'Monat') {
      // Monatsoptionen generieren
      const monthsInYear = [{ label: 'Alle', value: 'Alle' }, ...Array.from({ length: 12 }, (_, i) => ({
        label: dayjs().month(i).format('MMMM'),
        value: i,
      }))];
      setMonthOptions(monthsInYear);

      let current = start.startOf('month');
      while (current.isBefore(end) || current.isSame(end, 'month')) {
        if (selectedMonth === 'Alle' || current.month() === selectedMonth) {
          columnArray.push(current.format('MMMM YYYY'));
        }
        current = current.add(1, 'month');
      }
    } else if (timeLevel === 'Tag') {
      // Wochentagsoptionen generieren
      const weekdays = ['Alle', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
      setWeekdayOptions(weekdays);

      let current = start.startOf('day');
      while (current.isBefore(end) || current.isSame(end, 'day')) {
        if (selectedDay === 'Alle' || current.format('dddd') === selectedDay) {
          columnArray.push(current.format('dddd, DD.MM.YYYY'));
        }
        current = current.add(1, 'day');
      }
    } else if (timeLevel === 'Woche') {
      // Spalten für Wochen generieren
      let current = start.startOf('week');
      while (current.isBefore(end) || current.isSame(end, 'week')) {
        columnArray.push(`KW ${current.week()} ${current.year()}`);
        current = current.add(1, 'week');
      }
    }

    setColumns(columnArray);
  };

  useEffect(() => {
    generateColumns();
  }, [selectedDateRange, timeLevel, selectedMonth, selectedDay]);

  useEffect(() => {
    // Platzhalter-Daten für die Demonstration (Schritt 2)
    setWarengruppeOptions(['Alle', 'Warengruppe A', 'Warengruppe B', 'Warengruppe C']);
    setArtikelgruppeOptions(['Alle', 'Artikelgruppe A', 'Artikelgruppe B', 'Artikelgruppe C']);
    setArtikelOptions(['Alle', 'Artikel A', 'Artikel B', 'Artikel C']);

    // Optionen für Verkaufsgebiet und Filiale (Schritt 3)
    setVerkaufsgebietOptions(['Alle', 'Verkaufsgebiet 1', 'Verkaufsgebiet 2', 'Verkaufsgebiet 3']);
    setFilialeOptions(['Alle', 'Filiale 1', 'Filiale 2', 'Filiale 3']);
  }, []);

  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
        }}
      >
        <PageHeader
          title="Datenübersicht"
          subtitle="Übersicht der Umsätze, Artikel und Filialen"
          Icon={BarChartIcon}
        />
        {/* Button für den Filter-Assistenten */}
        <Button variant="contained" color="primary" onClick={handleDialogOpen}>
          Filter-Assistent starten
        </Button>
      </Box>

      <IconButton
        sx={{
          position: 'fixed',
          bottom: 32,
          right: 32,
          transform: 'translateX(-50%)',
          backgroundColor: 'primary.main',
          color: 'white',
          '&:hover': {
            backgroundColor: 'primary.dark',
          },
          width: 80,
          height: 80,
        }}
        onClick={() => setIsFilterOpen(true)}
      >
        <FilterListIcon sx={{ fontSize: 40 }} />
      </IconButton>

      {/* Dialog für den Filter-Assistenten */}
      <Dialog open={openDialog} onClose={handleDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>Filter-Assistent</DialogTitle>
        <DialogContent>
          {/* Stepper horizontal oben */}
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          {/* Inhalt darunter nebeneinander */}
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2, mt: 4 }}>
            {/* Linke Spalte: Filter-Assistent */}
            <Box sx={{ width: '40%' }}>
              {/* Schritt 1: Zeitdimension */}
              {activeStep === 0 && (
                <Box sx={{ mt: 2 }}>
                  <h3>Zeitraum wählen</h3>
                  {/* DateRangePicker für den Zeitraum */}
                  <DateRangePicker
                    startText="Von"
                    endText="Bis"
                    value={selectedDateRange}
                    onChange={(newValue) => setSelectedDateRange(newValue)}
                    renderInput={(startProps, endProps) => (
                      <Box sx={{ display: 'flex', gap: 2 }}>
                        <TextField {...startProps} fullWidth />
                        <TextField {...endProps} fullWidth />
                      </Box>
                    )}
                  />
                  {/* Vordefinierte Zeiträume */}
                  <Box sx={{ mt: 2 }}>
                    <Button variant="outlined" onClick={handleLastYear} sx={{ mr: 2 }}>
                      Letztes Jahr
                    </Button>
                    <Button variant="outlined" onClick={handleLast6Months}>
                      Letzte 6 Monate
                    </Button>
                  </Box>

                  {/* Zeitebene wählen */}
                  <Box sx={{ mt: 4 }}>
                    <h3>Auf welcher Ebene?</h3>
                    <ToggleButtonGroup
                      color="primary"
                      value={timeLevel}
                      exclusive
                      onChange={handleTimeLevelChange}
                      aria-label="Zeitebene"
                    >
                      <ToggleButton value="Monat">Monat</ToggleButton>
                      <ToggleButton value="Woche">Woche</ToggleButton>
                      <ToggleButton value="Tag">Tag</ToggleButton>
                    </ToggleButtonGroup>
                  </Box>

                  {/* Monatsauswahl bei Zeitebene "Monat" */}
                  {timeLevel === 'Monat' && (
                    <Box sx={{ mt: 4 }}>
                      <TextField
                        select
                        label="Monat auswählen"
                        value={selectedMonth}
                        onChange={(e) => setSelectedMonth(e.target.value)}
                        fullWidth
                      >
                        {monthOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}

                  {/* Wochentagsauswahl bei Zeitebene "Tag" */}
                  {timeLevel === 'Tag' && (
                    <Box sx={{ mt: 4 }}>
                      <TextField
                        select
                        label="Wochentag auswählen"
                        value={selectedDay}
                        onChange={(e) => setSelectedDay(e.target.value)}
                        fullWidth
                      >
                        {weekdayOptions.map((day) => (
                          <MenuItem key={day} value={day}>
                            {day}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}
                </Box>
              )}

              {/* Schritt 2: Artikel */}
              {activeStep === 1 && (
                <Box sx={{ mt: 2 }}>
                  <h3>Filter auswählen</h3>
                  {/* Dropdown für Warengruppe */}
                  <TextField
                    select
                    label="Warengruppe auswählen"
                    value={selectedWarengruppe}
                    onChange={handleWarengruppeChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {warengruppeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* Dropdown für Artikelgruppe */}
                  <TextField
                    select
                    label="Artikelgruppe auswählen"
                    value={selectedArtikelgruppe}
                    onChange={handleArtikelgruppeChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {artikelgruppeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* Dropdown für Artikel */}
                  <TextField
                    select
                    label="Artikel auswählen"
                    value={selectedArtikel}
                    onChange={handleArtikelChange}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {artikelOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <h3 style={{ marginTop: '16px' }}>Aggregationsdimension wählen</h3>
                  {/* ToggleButtons für die Aggregationsdimension */}
                  <ToggleButtonGroup
                    color="primary"
                    value={aggregationLevel}
                    exclusive
                    onChange={handleAggregationLevelChange}
                    aria-label="Aggregationsebene"
                    sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                  >
                    <ToggleButton value="Warengruppe">Warengruppe</ToggleButton>
                    <ToggleButton value="Artikelgruppe">Artikelgruppe</ToggleButton>
                    <ToggleButton value="Artikel">Artikel</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}

              {/* Schritt 3: Filiale */}
              {activeStep === 2 && (
                <Box sx={{ mt: 2 }}>
                  <h3>Filter auswählen</h3>
                  {/* Dropdown für Verkaufsgebiet */}
                  <TextField
                    select
                    label="Verkaufsgebiet auswählen"
                    value={selectedVerkaufsgebiet}
                    onChange={(e) => setSelectedVerkaufsgebiet(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {verkaufsgebietOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* Dropdown für Filiale */}
                  <TextField
                    select
                    label="Filiale auswählen"
                    value={selectedFiliale}
                    onChange={(e) => setSelectedFiliale(e.target.value)}
                    fullWidth
                    sx={{ mt: 2 }}
                  >
                    {filialeOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <h3 style={{ marginTop: '16px' }}>Aggregationsdimension wählen</h3>
                  {/* ToggleButtons für die Aggregationsdimension */}
                  <ToggleButtonGroup
                    color="primary"
                    value={filialeAggregationLevel}
                    exclusive
                    onChange={(event, newAggregationLevel) => {
                      if (newAggregationLevel !== null) {
                        setFilialeAggregationLevel(newAggregationLevel);
                      }
                    }}
                    aria-label="Aggregationsebene"
                    sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
                  >
                    <ToggleButton value="Gesamtunternehmen">Gesamtunternehmen</ToggleButton>
                    <ToggleButton value="Verkaufsgebiet">Verkaufsgebiet</ToggleButton>
                    <ToggleButton value="Filiale">Filiale</ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              )}

              {/* Navigationsbuttons */}
              <Box sx={{ mt: 4 }}>
                <Button disabled={activeStep === 0} onClick={handleBack}>
                  Zurück
                </Button>
                <Button onClick={activeStep === steps.length - 1 ? handleDialogClose : handleNext}>
                  {activeStep === steps.length - 1 ? 'Fertigstellen' : 'Weiter'}
                </Button>
              </Box>
            </Box>

            {/* Rechte Spalte: Vorschau-Tabelle */}
            <Box sx={{ width: '60%' }}>
              {columns.length > 0 && (
                <Box sx={{ mt: 2 }}>
                  <h3>Vorschau</h3>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {activeStep === 2 ? filialeAggregationLevel : aggregationLevel}
                        </TableCell>
                        {columns.map((col, index) => (
                          <TableCell key={index}>{col}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* Dummy-Daten mit drei Zeilen: A, B und C */}
                      {['A', 'B', 'C'].map((item) => (
                        <TableRow key={item}>
                          <TableCell>
                            {activeStep === 2
                              ? `${filialeAggregationLevel} ${item}`
                              : `${aggregationLevel} ${item}`}
                          </TableCell>
                          {columns.map((_, index) => (
                            <TableCell key={index}>123</TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default Overview;
