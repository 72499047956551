import React, { useState, useEffect, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import BigTableCard from '../../../components/card/BigTableCard';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import FaceRetouchingOffOutlinedIcon from '@mui/icons-material/FaceRetouchingOffOutlined';


const austritte = () => {
  const { filterState } = useContext(FilterContext);
  const [employees, setEmployees] = useState([]);

  useEffect(() => {
    fetchEmployees();
  }, [filterState]);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
        params: {
          austritt_jahrMonat: filterState.datum?.value,
        },
      });
  
      // Daten formatieren (Datum ohne Zeit)
      const formattedEmployees = response.data.results.map((employee) => ({
        ...employee,
        austritt: employee.austritt
          ? new Date(employee.austritt).toLocaleDateString('de-DE', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
          : 'N/A',
      }));
  
      setEmployees(formattedEmployees);
    } catch (error) {
      console.error('Fehler beim Laden der Mitarbeiterdaten:', error);
    }
  };
  

  // Spaltenkonfiguration für die Tabelle
  const columns = useMemo(
    () => [
      { accessorKey: 'mitarbeiterId', header: 'Mitarbeiter-ID' },
      { accessorKey: 'name', header: 'Name' },
      { accessorKey: 'stammkostenstelle', header: 'Stammkostenstelle' },
      {accessorKey: 'austritt', header: 'Austritt'}
    ],
    []
  );

  const handleRowClick = (row) => {
    console.log('Row clicked:', row);
  };

  return (
    <Layout>
      <PageHeader
        title="Austritte"
        subtitle="Dieser Bericht bietet eine Übersicht über aller Austrtitte des ausgewählten Monats. Er zeigt, welche neuen Mitarbeiter in den verschiedenen Kostenstellen ausgetreten sind und ermöglicht es, Entwicklungen in der Personalaufstockung zu erkennen. Diese Analyse gibt Einblick in die Verstärkung der Belegschaft und hilft bei der Bewertung des aktuellen Einstellungsprozesses sowie der Integration neuer Mitarbeiter in das Unternehmen."
        Icon={FaceRetouchingOffOutlinedIcon}
      />

      {/* BigTableCard Komponente */}
      <BigTableCard
        title="Mitarbeiter Übersicht"
        data={employees}
        columns={columns}
        onRowClick={handleRowClick}
        muiTableBodyCellProps={{
          sx: {
            fontSize: {
              xs: '0.6rem',
              sm: '0.8rem',
              md: '1rem',
            },
          },
        }}
      />
    </Layout>
  );
};

export default austritte;
