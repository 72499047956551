import React, {useState} from 'react';
import {TextField, Button, Box} from '@mui/material';

const NeueKategorieForm = ({onAddCategory}) => {
    const [newCategory, setNewCategory] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddCategory(newCategory);
        setNewCategory('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{display: 'flex', gap: 2}}>
            <TextField
                label="Neue Kategorie"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
            />
            <Button type="submit" variant="contained">Hinzufügen</Button>
        </Box>
    );
};

export default NeueKategorieForm;
