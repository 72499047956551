import React from 'react';
import {
    Box,
    Container,
    Typography,
    Button,
    Grid,
    Card,
    CardContent,
    useTheme
} from '@mui/material';
import AssessmentIcon from '@mui/icons-material/Assessment';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InsightsIcon from '@mui/icons-material/Insights';

const Landing = () => {
    const theme = useTheme();

    return (
        <Box>
            {/* Hero Section */}
            <Box
                sx={{
                    bgcolor: theme.palette.primary.main,
                    color: 'white',
                    py: 8
                }}
            >
                <Container maxWidth="lg">
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2" component="h1" gutterBottom>
                                Datengesteuerte Erkenntnisse für Ihre Bäckerei
                            </Typography>
                            <Typography variant="h5">
                                Optimieren Sie Ihr Geschäft mit intelligenten Analysen und Scorecards
                            </Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                sx={{ mt: 2 }}
                                href="mailto:b.reeke@databites.de"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Jetzt Demo anfragen
                            </Button>
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* Features Section */}
            <Container maxWidth="lg" sx={{ py: 4 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent sx={{ py: 2 }}>
                                <AssessmentIcon sx={{ fontSize: 36 }} />
                                <Typography variant="h5" component="h2" sx={{ mt: 1 }}>
                                    Scorecards
                                </Typography>
                                <Typography>
                                    Übersichtliche Darstellung Ihrer wichtigsten Kennzahlen auf einen Blick
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <StorefrontIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                                <Typography variant="h5" component="h2" sx={{ mt: 2 }}>
                                    Sortimentsplaner
                                </Typography>
                                <Typography>
                                    Optimieren Sie Ihr Produktangebot datenbasiert (Coming Soon)
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Card>
                            <CardContent>
                                <InsightsIcon sx={{ fontSize: 40, color: theme.palette.primary.main }} />
                                <Typography variant="h5" component="h2" sx={{ mt: 2 }}>
                                    Datenanalyse
                                </Typography>
                                <Typography>
                                    Tiefgehende Einblicke in Ihre Geschäftsprozesse
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            {/* Contact Section */}
            <Box sx={{ bgcolor: 'grey.100', py: 2, mt: -2 }}>
                <Container maxWidth="lg">
                    <Typography 
                        variant="h5"
                        component="h2"
                        align="center"
                        gutterBottom
                    >
                        Kontaktieren Sie uns
                    </Typography>

                    <Box sx={{ textAlign: 'center', mt: 1 }}>
                        <Button
                            variant="contained"
                            color="primary"
                            href="mailto:B.reeke@databites.de"
                        >
                            E-Mail senden
                        </Button>
                    </Box>
                </Container>
            </Box>
        </Box>
    );
};

export default Landing;
