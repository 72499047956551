import React, { useEffect, useState } from 'react';
import axios from '../../../services/axiosInstance';  // Stelle sicher, dass dein axios-Setup korrekt ist.
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  ListItemText,
  OutlinedInput,
  FormControlLabel,
} from '@mui/material';

const shortcutsItems = [
  {
    label: 'This Week',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('week'), today.endOf('week')];
    },
  },
  {
    label: 'Last Week',
    getValue: () => {
      const today = dayjs();
      const prevWeek = today.subtract(7, 'day');
      return [prevWeek.startOf('week'), prevWeek.endOf('week')];
    },
  },
  {
    label: 'Last 7 Days',
    getValue: () => {
      const today = dayjs();
      return [today.subtract(7, 'day'), today];
    },
  },
  {
    label: 'Current Month',
    getValue: () => {
      const today = dayjs();
      return [today.startOf('month'), today.endOf('month')];
    },
  },
  {
    label: 'Next Month',
    getValue: () => {
      const today = dayjs();
      const startOfNextMonth = today.endOf('month').add(1, 'day');
      return [startOfNextMonth, startOfNextMonth.endOf('month')];
    },
  },
  { label: 'Reset', getValue: () => [null, null] },
];

const KombinierteAnalyseTable = () => {
  const [groupedData, setGroupedData] = useState({});
  const [warengruppenData, setWarengruppenData] = useState({});
  const [months, setMonths] = useState([]); // Dynamische Monate
  const [showAbverkauf, setShowAbverkauf] = useState(true);
  const [showLiefermenge, setShowLiefermenge] = useState(true);
  const [showWarengruppen, setShowWarengruppen] = useState(false); // Zustand für Warengruppenansicht
  const [selectedWarengruppen, setSelectedWarengruppen] = useState([]); // Ausgewählte Warengruppen
  const [allWarengruppen, setAllWarengruppen] = useState([]); // Alle Warengruppen
  const [selectedArticles, setSelectedArticles] = useState([]); // Ausgewählte Artikel
  const [allArticles, setAllArticles] = useState([]); // Alle Artikel basierend auf ausgewählten Warengruppen

  // Neuer State für das Datum
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);

  // API-Aufruf für detaillierte Artikeldaten
  useEffect(() => {
    // Überprüfe, ob ein gültiger Datumsbereich ausgewählt wurde
    if (dateRange[0] && dateRange[1]) {
      // Formatiere die Daten für den API-Aufruf
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');

      axios.get('/fact/aggregated/by-month-warengruppe-artikel/', {
        params: { start_date: startDate, end_date: endDate },
      })
        .then((response) => {
          const filteredData = response.data;

          const grouped = filteredData.reduce((acc, row) => {
            const warengruppe = row.warengruppennummer__warengruppe;
            const artikel = row.artikelnummer__artikelbezeichnung;
            const month = dayjs(row.month).format('MMMM YYYY');

            if (!acc[warengruppe]) {
              acc[warengruppe] = { articles: {} };
            }

            if (!acc[warengruppe].articles[artikel]) {
              acc[warengruppe].articles[artikel] = { abverkauf: {}, liefermenge: {} };
            }

            acc[warengruppe].articles[artikel].abverkauf[month] = row.total_verkaufsmenge;
            acc[warengruppe].articles[artikel].liefermenge[month] = row.total_liefermenge;

            return acc;
          }, {});

          setGroupedData(grouped);

          const uniqueMonths = Array.from(
            new Set(filteredData.map((row) => dayjs(row.month).format('YYYY-MM')))
          ).sort() // Sortiere nach 'YYYY-MM'
            .map((dateString) => dayjs(dateString, 'YYYY-MM').format('MMMM YYYY')); // Formatiere in 'MMMM YYYY'
          
          setMonths(uniqueMonths);
          

          const uniqueWarengruppen = [...new Set(filteredData.map((row) => row.warengruppennummer__warengruppe))];
          setAllWarengruppen(uniqueWarengruppen);
          setSelectedWarengruppen(uniqueWarengruppen);

          // Extrahiere alle Artikel für die ausgewählten Warengruppen
          const uniqueArticles = [...new Set(filteredData.map((row) => row.artikelnummer__artikelbezeichnung))];
          setAllArticles(uniqueArticles);
          setSelectedArticles(uniqueArticles);
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error);
        });
    }
  }, [dateRange]);

  // API-Aufruf für aggregierte Warengruppen-Daten
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      // Formatiere die Daten für den API-Aufruf
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');

      axios.get('/fact/aggregated/by-month-warengruppe/', {
        params: { start_date: startDate, end_date: endDate },
      })
        .then((response) => {
          const filteredData = response.data;

          const grouped = filteredData.reduce((acc, row) => {
            const warengruppe = row.warengruppennummer__warengruppe;
            const month = dayjs(row.month).format('MMMM YYYY');

            if (!acc[warengruppe]) {
              acc[warengruppe] = { abverkauf: {}, liefermenge: {} };
            }

            acc[warengruppe].abverkauf[month] = row.total_verkaufsmenge;
            acc[warengruppe].liefermenge[month] = row.total_liefermenge;

            return acc;
          }, {});

          setWarengruppenData(grouped);
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der aggregierten Warengruppen-Daten:', error);
        });
    }
  }, [dateRange]);

  // Dynamische Aktualisierung der Artikel basierend auf den ausgewählten Warengruppen
  useEffect(() => {
    if (selectedWarengruppen.length > 0) {
      const filteredArticles = Object.entries(groupedData)
        .filter(([warengruppe]) => selectedWarengruppen.includes(warengruppe))
        .flatMap(([warengruppe, groupData]) => Object.keys(groupData.articles));
      setAllArticles(filteredArticles);
      setSelectedArticles(filteredArticles);
    } else {
      // Wenn keine Warengruppen ausgewählt sind, leere die Artikelliste
      setAllArticles([]);
      setSelectedArticles([]);
    }
  }, [selectedWarengruppen, groupedData]);

  return (
    <div>
      {/* Datumsbereich-Filter */}
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateRangePicker
          value={dateRange}
          onChange={(newValue) => setDateRange(newValue)}
          slotProps={{
            shortcuts: {
              items: shortcutsItems,
            },
            actionBar: { actions: [] },
          }}
          calendars={2}
        />
      </LocalizationProvider>

      {/* Button, um zwischen der Ansicht zu wechseln */}
      <Button
        variant="contained"
        onClick={() => setShowWarengruppen(!showWarengruppen)}
        style={{ marginBottom: '20px', marginTop: '20px' }}
      >
        {showWarengruppen ? 'Detaillierte Ansicht' : 'Warengruppen-Ansicht'}
      </Button>

      {/* Auswahlmöglichkeiten für Abverkauf und Liefermenge */}
      <div style={{ marginBottom: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={showAbverkauf} onChange={() => setShowAbverkauf(!showAbverkauf)} />}
          label="Abverkauf anzeigen"
        />
        <FormControlLabel
          control={<Checkbox checked={showLiefermenge} onChange={() => setShowLiefermenge(!showLiefermenge)} />}
          label="Liefermenge anzeigen"
        />
      </div>

      {/* Dropdown für die Auswahl von Warengruppen */}
      <FormControl style={{ marginBottom: '20px', width: '300px' }}>
        <InputLabel>Warengruppen filtern</InputLabel>
        <Select
          multiple
          value={selectedWarengruppen}
          onChange={(event) => setSelectedWarengruppen(event.target.value)}
          input={<OutlinedInput label="Warengruppen filtern" />}
          renderValue={(selected) => selected.join(', ')}
        >
          {allWarengruppen.map((warengruppe) => (
            <MenuItem key={warengruppe} value={warengruppe}>
              <Checkbox checked={selectedWarengruppen.indexOf(warengruppe) > -1} />
              <ListItemText primary={warengruppe} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {/* Button "Alles abwählen" für Warengruppen */}
      <Button
        variant="outlined"
        onClick={() => setSelectedWarengruppen([])} // Setzt die ausgewählten Warengruppen auf ein leeres Array
        style={{ marginBottom: '20px', marginLeft: '10px' }}
      >
        Alles abwählen (Warengruppen)
      </Button>

      {/* Dropdown für die Auswahl von Artikeln */}
      {!showWarengruppen && (
        <>
          <FormControl style={{ marginBottom: '20px', width: '300px' }}>
            <InputLabel>Artikel filtern</InputLabel>
            <Select
              multiple
              value={selectedArticles}
              onChange={(event) => setSelectedArticles(event.target.value)}
              input={<OutlinedInput label="Artikel filtern" />}
              renderValue={(selected) => selected.join(', ')}
            >
              {allArticles.map((artikel) => (
                <MenuItem key={artikel} value={artikel}>
                  <Checkbox checked={selectedArticles.indexOf(artikel) > -1} />
                  <ListItemText primary={artikel} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {/* Button "Alles abwählen" für Artikel */}
          <Button
            variant="outlined"
            onClick={() => setSelectedArticles([])} // Setzt die ausgewählten Artikel auf ein leeres Array
            style={{ marginBottom: '20px', marginLeft: '10px' }}
          >
            Alles abwählen (Artikel)
          </Button>
        </>
      )}

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table aria-label="Kombinierte Analyse">
          <TableHead>
            <TableRow>
              <TableCell>Warengruppe</TableCell>
              {!showWarengruppen && <TableCell>Artikel</TableCell>}
              <TableCell>Typ</TableCell>
              {months.map((month, index) => (
                <TableCell key={index}>{month}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {showWarengruppen
              ? // Aggregierte Warengruppen-Ansicht
                Object.entries(warengruppenData)
                  .filter(([warengruppe]) => selectedWarengruppen.includes(warengruppe)) // Filter nach ausgewählten Warengruppen
                  .map(([warengruppe, values], warengruppeIndex) => {
                    // Berechne die Anzahl der Zeilen für diese Warengruppe
                    const rowSpan = (showAbverkauf ? 1 : 0) + (showLiefermenge ? 1 : 0);

                    if (rowSpan === 0) return null;

                    return (
                      <React.Fragment key={warengruppeIndex}>
                        {showAbverkauf && (
                          <TableRow>
                            <TableCell rowSpan={rowSpan}>{warengruppe}</TableCell>
                            <TableCell>Abverkauf</TableCell>
                            {months.map((month, index) => (
                              <TableCell key={index}>{values.abverkauf[month] || ''}</TableCell>
                            ))}
                          </TableRow>
                        )}
                        {showLiefermenge && (
                          <TableRow>
                            {!showAbverkauf && <TableCell rowSpan={1}>{warengruppe}</TableCell>}
                            <TableCell>Liefermenge</TableCell>
                            {months.map((month, index) => (
                              <TableCell key={index}>{values.liefermenge[month] || ''}</TableCell>
                            ))}
                          </TableRow>
                        )}
                      </React.Fragment>
                    );
                  })
              : // Detaillierte Artikel-Ansicht
                Object.entries(groupedData)
                  .filter(([warengruppe]) => selectedWarengruppen.includes(warengruppe)) // Filter nach ausgewählten Warengruppen
                  .map(([warengruppe, groupData], warengruppeIndex) => {
                    // Filtere Artikel nach ausgewählten Artikeln
                    const filteredArticles = Object.entries(groupData.articles).filter(([artikel]) =>
                      selectedArticles.includes(artikel)
                    );

                    // Berechne die Anzahl der Zeilen für die Warengruppe
                    const warengruppeRowSpan = filteredArticles.reduce((acc, [artikel, values]) => {
                      const articleRowSpan = (showAbverkauf ? 1 : 0) + (showLiefermenge ? 1 : 0);
                      return acc + articleRowSpan;
                    }, 0);

                    if (warengruppeRowSpan === 0) return null;

                    let renderWarengruppeCell = true;

                    return filteredArticles.map(([artikel, values], artikelIndex) => {
                      const articleRowSpan = (showAbverkauf ? 1 : 0) + (showLiefermenge ? 1 : 0);

                      if (articleRowSpan === 0) {
                        return null;
                      }

                      let renderArtikelCell = true;

                      return (
                        <React.Fragment key={`${warengruppe}-${artikel}`}>
                          {showAbverkauf && (
                            <TableRow>
                              {/* Warengruppe-Zelle */}
                              {renderWarengruppeCell && (
                                <TableCell rowSpan={warengruppeRowSpan}>{warengruppe}</TableCell>
                              )}
                              {/* Artikel-Zelle */}
                              {renderArtikelCell && (
                                <TableCell rowSpan={articleRowSpan}>{artikel}</TableCell>
                              )}
                              <TableCell>Abverkauf</TableCell>
                              {months.map((month, index) => (
                                <TableCell key={index}>{values.abverkauf[month] || ''}</TableCell>
                              ))}
                            </TableRow>
                          )}
                          {showLiefermenge && (
                            <TableRow>
                              {/* Warengruppe-Zelle */}
                              {renderWarengruppeCell && !showAbverkauf && (
                                <TableCell rowSpan={warengruppeRowSpan}>{warengruppe}</TableCell>
                              )}
                              {/* Artikel-Zelle */}
                              {renderArtikelCell && !showAbverkauf && (
                                <TableCell rowSpan={articleRowSpan}>{artikel}</TableCell>
                              )}
                              <TableCell>Liefermenge</TableCell>
                              {months.map((month, index) => (
                                <TableCell key={index}>{values.liefermenge[month] || ''}</TableCell>
                              ))}
                            </TableRow>
                          )}
                          {/* Nach dem ersten Rendern setzen wir die Flags auf false */}
                          {(renderWarengruppeCell = false)}
                          {(renderArtikelCell = false)}
                        </React.Fragment>
                      );
                    });
                  })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default KombinierteAnalyseTable;
