import React from 'react';
import { ResponsiveContainer, PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { useTheme } from '@mui/material/styles';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, outerRadius, percent, name }) => {
  const radius = outerRadius + 20; // Abstand der Labels außerhalb des Kreises
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="black"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${name} ${(percent * 100).toFixed(2)}%`}
    </text>
  );
};

const CustomPieChart = ({ data, dataKey, nameKey, title }) => {
  const theme = useTheme(); // Verwende den Theme Hook, um die Farben zu bekommen

  // Farben aus dem Theme
  const COLORS = [
    theme.palette.chart.red,
    theme.palette.chart.purpleLight,
    theme.palette.chart.orange,
  ];

  return (
    <div className="chart-wrapper" style={{ textAlign: 'center' }}>
      <h2 style={{ fontSize: '1rem', color: '#808080' }}>{title}</h2>
      <ResponsiveContainer width="100%" height={250}>
        <PieChart>
          <Pie
            data={data}
            dataKey={dataKey}
            nameKey={nameKey}
            cx="50%"
            cy="50%"
            innerRadius={50}   
            outerRadius={80}    
            label={renderCustomizedLabel}
            labelLine={false}
            paddingAngle={5}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend verticalAlign="bottom" height={36} iconType="circle" />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomPieChart;
