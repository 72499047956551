import axios from "axios";

let BASE_URL = process.env.REACT_APP_BASE_URL
let BASE_URL_TEMPLATE = process.env.REACT_APP_BASE_URL_TEMPLATE

// manually set Urls if env missing
if (!process.env.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL_TEMPLATE === '') {
    console.log("manullay set urls if missing")
    BASE_URL = "https://databites.de/api"
    BASE_URL_TEMPLATE = "https://{subdomain}.databites.de/api"
}

let refreshTokenPromise = null;

function getSubdomain() {
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    if (parts.length > 1) {
        return parts[0];
    }
    return null;
}

const axiosInstance = axios.create({
    baseURL: BASE_URL,
    withCredentials: true,
    headers: {
        "Content-Type": "application/json",
    },
});

export const setupAxiosInterceptors = (getToken, refreshAccessToken) => {
    axiosInstance.interceptors.request.use(config => {
        const subdomain = getSubdomain();

        // If a subdomain is detected, use it to set the base URL dynamically
        if (subdomain) {
            config.baseURL = BASE_URL_TEMPLATE.replace("{subdomain}", subdomain);
        }

        // Attach the token if available
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    }, error => {
        return Promise.reject(error.message);
    });

    // Add a response interceptor to handle token refresh
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            if (
                error.response?.status === 401
                && !originalRequest._retry
                && error.config.url !== "/auth/refresh/"
            ) {
                originalRequest._retry = true;
                console.log("access token expired")

                // Promise to refresh the access token, so that not all requests are made at once
                if (!refreshTokenPromise) {
                    refreshTokenPromise = refreshAccessToken();
                }

                const newAccessToken = await refreshTokenPromise;
                refreshTokenPromise = null;

                if (newAccessToken) {
                    return axiosInstance(originalRequest);
                } else {
                    alert("Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.");
                    window.location.href = "/login";
                }
            }
            return Promise.reject(error);
        }
    );
}

export default axiosInstance;
