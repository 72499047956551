import React from 'react';
import { Box, Typography, LinearProgress, Paper, Grid, Card, CardContent, CardHeader } from '@mui/material';
import Score from '../score/Score';  // Stelle sicher, dass der Pfad korrekt ist

const ProgressBarList = ({ data, title }) => {
    return (
        <Grid item xs={12}>
            <Paper>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '0px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                    }}
                >
                    <CardHeader
                        title={
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        }
                    />
                    <CardContent>
                        {data.map((row, index) => {
                            const retourmengeTag = parseFloat(row.RetourmengeTag);
                            const retoureSoll = parseFloat(row.RetoureSoll);
                            const scoreRetoureArtikelA = parseFloat(row.ScoreRetoureArtikelA);

                            // Berechne den Prozentsatz von Retourmenge Tag im Vergleich zu Retoure Soll
                            const percentage = retoureSoll > 0 ? (retourmengeTag / retoureSoll) * 100 : 0;

                            return (
                                <Box key={index} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                                    {/* Score Komponente */}
                                    <Score score={scoreRetoureArtikelA} />

                                    {/* Beschreibung und ProgressBar */}
                                    <Box sx={{ flexGrow: 1, ml: 2 }}>
                                        <Typography variant="body1" gutterBottom>
                                            <strong>{row.Artikel}</strong> - Sollretoure pro Tag: {retoureSoll}, ∅ Istretoure: {retourmengeTag}
                                        </Typography>
                                        <LinearProgress
                                            variant="determinate"
                                            value={percentage}
                                            sx={{ height: 5, borderRadius: 5 }}
                                        />
                                    </Box>
                                </Box>
                            );
                        })}
                    </CardContent>
                </Card>
            </Paper>
        </Grid>
    );
};

export default ProgressBarList;
