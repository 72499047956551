import React, { useState } from 'react';
import { Paper, Button, CircularProgress } from '@mui/material';
import { Form, useNavigate } from 'react-router-dom';
import { useAuth } from "../../provider/authProvider";
import axiosInstance from "../../services/axiosInstance";

const LogoutPage = () => {
    const { setToken } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [loggedOut, setLoggedOut] = useState(false);

    const handleLogout = async (e) => {
        e.preventDefault();
        setLoading(true);
        setToken(null);
        try {
            await axiosInstance.post('/auth/logout/', {}, { withCredentials: true });
            setLoggedOut(true);
        } catch (error) {
            console.log('Error logging out:', error);
        }
        setLoading(false);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Paper
                sx={{
                    padding: 6,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    maxWidth: '400px',
                    width: '100%',
                    backgroundColor: '#ffffff',
                    borderRadius: '12px',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                }}
            >
                <h1 style={{ color: '#00796b', fontSize: '2rem', fontWeight: 'bold' }}>Logout</h1>
                {loading ? (
                    <CircularProgress size={24} />
                ) : loggedOut ? (
                    <div style={{ textAlign: 'center', width: '100%' }}>
                        <div>Erfolgreich abgemeldet</div>
                        <Button
                            onClick={() => navigate('/login')}
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{
                                marginTop: 3,
                                marginBottom: 2,
                                padding: 1.5,
                                backgroundColor: '#00796b',
                                '&:hover': {
                                    backgroundColor: '#004d40',
                                },
                            }}
                        >
                            Zurück zum Login
                        </Button>
                    </div>
                ) : (
                    <Form onSubmit={handleLogout} style={{ width: '100%', marginTop: '16px' }}>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{
                                marginTop: 3,
                                marginBottom: 2,
                                padding: 1.5,
                                backgroundColor: '#00796b',
                                '&:hover': {
                                    backgroundColor: '#004d40',
                                },
                            }}
                            disabled={loading}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Logout'}
                        </Button>
                    </Form>
                )}
            </Paper>
        </div>
    );
};

export default LogoutPage;
