import * as React from 'react';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Score({ score }) {
    const circularProgressValue = score * 10;
    const theme = useTheme();
    
    // Define colors based on the score
    let color;
    if (score < 4) {
        color = theme.palette.error.main;
    } else if (score >= 4 && score < 7) {
        color = '#EFB358'; // Warning color
    } else {
        color = theme.palette.success.main;
    }

    return (
        <Box className="db_score">
            <Typography>
                {score}
            </Typography>
            <CircularProgress size="30px" className="db_score_circle" variant="determinate" value={100} style={{ color: theme.palette.grey[300] }} />
            <CircularProgress size="30px" className="db_score_circle" variant="determinate" value={circularProgressValue} style={{ color: color }} />
        </Box>
    );
}
