import React from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography} from '@mui/material';

const ErrorPopup = ({open, handleClose, message}) => {
    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="error-dialog-title"
            aria-describedby="error-dialog-description"
        >
            <DialogTitle id="error-dialog-title">Error</DialogTitle>
            <DialogContent>
                <Typography variant="body1" id="error-dialog-description">
                    {message}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ErrorPopup;
