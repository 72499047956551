// TodosÜberblick.jsx
import React, { useState, useEffect } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Select, MenuItem, InputLabel, FormControl, Checkbox, ListItemText,
    OutlinedInput, Snackbar, Alert, Typography
} from '@mui/material';
import Layout from '../../../components/layout/Tagesplaner/Tagesplaner';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance'; // Importiere deine Axios-Instanz

const weekdays = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
const hoursArray = Array.from({ length: 16 }, (_, i) => i + 5); // 5 bis 20 Uhr

const TodosOverview = () => {
    const [todos, setTodos] = useState([]); // Zustand für alle Todos
    const [filialeOptions, setFilialeOptions] = useState([]); // Zustand für Filialoptionen
    const [selectedFilialnummer, setSelectedFilialnummer] = useState([]); // Zustand für ausgewählte Filialen
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    // Fetch Filialoptionen vom Backend
    useEffect(() => {
        const fetchFilialeOptions = async () => {
            try {
                const response = await axiosInstance.get('stammdaten/unique-filiale/');
                setFilialeOptions(response.data); // Annahme: response.data ist ein Array von Filialen
            } catch (error) {
                console.error('Fehler beim Laden der Filialen:', error);
                setSnackbar({ open: true, message: 'Fehler beim Laden der Filialen.', severity: 'error' });
            }
        };
        fetchFilialeOptions();
    }, []);

    // Fetch alle Todos vom Backend
    useEffect(() => {
        const fetchTodos = async () => {
            try {
                const response = await axiosInstance.get('/tagesplaner/recurring-todos/');
                console.log('Todos API Response:', response.data); // Debugging

                if (response.data.results) {
                    setTodos(response.data.results);
                } else if (Array.isArray(response.data)) {
                    setTodos(response.data);
                } else {
                    console.warn('Unerwartete API-Antwortstruktur:', response.data);
                    setTodos([]); // Setze auf leeres Array, um Fehler zu vermeiden
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Todos:', error);
                setSnackbar({ open: true, message: 'Fehler beim Laden der Todos.', severity: 'error' });
            }
        };

        fetchTodos();
    }, []);

    // Handler für Filialfilter
    const handleFilialChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedFilialnummer(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // Handler für Snackbar schließen
    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Filtern der Todos basierend auf ausgewählten Filialnummern
    const filteredTodos = selectedFilialnummer.length > 0
        ? todos.filter(todo => selectedFilialnummer.includes(todo.filialnummer))
        : todos;

    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader
                    title="Todos Überblick"
                    subtitle="Übersicht aller Todos nach Filialen."
                    Icon={ChecklistIcon}
                />

                {/* Filialfilter */}
                <Box mt={3} mb={3}>
                    <FormControl fullWidth>
                        <InputLabel id="filial-filter-label">Filialen</InputLabel>
                        <Select
                            labelId="filial-filter-label"
                            id="filial-filter"
                            multiple
                            value={selectedFilialnummer}
                            onChange={handleFilialChange}
                            input={<OutlinedInput label="Filialen" />}
                            renderValue={(selected) => {
                                if (selected.length === 0) return 'Alle Filialen';
                                const selectedNames = filialeOptions
                                    .filter(filiale => selected.includes(filiale.filialnummer))
                                    .map(filiale => filiale.filiale)
                                    .join(', ');
                                return selectedNames;
                            }}
                        >
                            {filialeOptions.map((filiale) => (
                                <MenuItem key={filiale.filialnummer} value={filiale.filialnummer}>
                                    <Checkbox checked={selectedFilialnummer.indexOf(filiale.filialnummer) > -1} />
                                    <ListItemText primary={filiale.filiale} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Matrix anzeigen */}
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Uhrzeit</TableCell>
                                    {weekdays.map((day) => (
                                        <TableCell key={day} align="center">{day}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hoursArray.map((hour) => (
                                    <TableRow key={hour}>
                                        <TableCell component="th" scope="row">
                                            {`${hour}:00`}
                                        </TableCell>
                                        {weekdays.map((day) => (
                                            <TableCell
                                                key={day}
                                                align="center"
                                                sx={{ bgcolor: '#f5f5f5', minWidth: 100, height: 50 }}
                                            >
                                                {Array.isArray(filteredTodos) && filteredTodos.filter(todo => {
                                                    // Annahme: todo.due_time ist im Format 'HH:MM'
                                                    const [todoHour, todoMinute] = todo.due_time.split(':').map(Number);
                                                    return todoHour === hour && todo.weekdays.includes(day);
                                                }).map(todo => (
                                                    <Box
                                                        key={todo.id}
                                                        sx={{
                                                            bgcolor: '#1976d2',
                                                            color: '#fff',
                                                            borderRadius: 1,
                                                            p: 0.5,
                                                            mb: 0.5,
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        {todo.titel}
                                                    </Box>
                                                ))}
                                                {filteredTodos.filter(todo => {
                                                    const [todoHour, todoMinute] = todo.due_time.split(':').map(Number);
                                                    return todoHour === hour && todo.weekdays.includes(day);
                                                }).length === 0 && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        Keine Todos
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Snackbar für Erfolg und Fehler */}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Layout>
    );
};

export default TodosOverview;
