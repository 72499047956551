import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Stepper, Step, StepLabel, List, ListItem, ListItemText, IconButton, Collapse, Alert, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

// Dialog-Komponenten für Kategorien, Themen und Schritte
const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

const TopicDialog = ({ open, onClose, topicName, setTopicName, handleSaveTopic }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{topicName ? 'Thema bearbeiten' : 'Neues Thema anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Themenname"
                fullWidth
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveTopic} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

const StepDialog = ({ open, onClose, stepName, setStepName, handleSaveStep }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{stepName ? 'Schritt bearbeiten' : 'Neuen Schritt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Schrittbeschreibung"
                fullWidth
                value={stepName}
                onChange={(e) => setStepName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveStep} variant="contained" color="primary">
                Speichern
            </Button>
            <Button onClick={onClose} variant="outlined">
                Abbrechen
            </Button>
        </DialogActions>
    </Dialog>
);

const OnboardingHinzufuegen = ({ open, handleClose }) => {
    const [activeStep, setActiveStep] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [isActive, setIsActive] = useState(true);
    const [onboardingPlanId, setOnboardingPlanId] = useState(null);
    const [categories, setCategories] = useState([]);
    const [categoryName, setCategoryName] = useState('');
    const [topics, setTopics] = useState([]);
    const [topicName, setTopicName] = useState('');
    const [stepsData, setStepsData] = useState([]);
    const [stepName, setStepName] = useState('');
    const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
    const navigate = useNavigate();

    // Zustände für die Dialoge und Bearbeitungsmodi
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [openStepDialog, setOpenStepDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTopic, setEditingTopic] = useState(null);
    const [editingStep, setEditingStep] = useState(null);

    const steps = ['Name und Beschreibung', 'Kategorien hinzufügen', 'Themen und Schritte konfigurieren'];

    const handleNext = async () => {
        if (activeStep === 0) {
            if (onboardingPlanId === null) {
                // Neuen Onboarding-Plan erstellen
                try {
                    const response = await axiosInstance.post('/onboarding/onboarding-plans/', {
                        name,
                        description,
                        is_active: isActive
                    });
                    setOnboardingPlanId(response.data.id);
                } catch (error) {
                    console.error('Fehler beim Hinzufügen des Onboarding-Plans:', error);
                    return;
                }
            } else {
                // Bestehenden Onboarding-Plan aktualisieren
                try {
                    await axiosInstance.patch(`/onboarding/onboarding-plans/${onboardingPlanId}/`, {
                        name,
                        description,
                        is_active: isActive
                    });
                } catch (error) {
                    console.error('Fehler beim Aktualisieren des Onboarding-Plans:', error);
                    return;
                }
            }
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSaveAndClose = () => {
        resetForm(); // Setze den Zustand zurück
        handleClose();
        navigate('/onboarding/overview/onboardingkonfiguration');
    };

    // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Kategorien
    const handleAddCategory = async () => {
        if (categoryName.trim() === '') {
            return;
        }

        try {
            const response = await axiosInstance.post('/onboarding/categories/', {
                name: categoryName,
                onboarding_plan: onboardingPlanId,
            });
            setCategories([...categories, response.data]);
            setCategoryName('');
        } catch (error) {
            console.error('Fehler beim Hinzufügen der Kategorie:', error);
        }
    };

    const handleDeleteCategory = async (categoryId) => {
        try {
            await axiosInstance.delete(`/onboarding/categories/${categoryId}/`);
            setCategories(categories.filter((category) => category.id !== categoryId));
        } catch (error) {
            console.error('Fehler beim Löschen der Kategorie:', error);
        }
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleSaveCategoryInStep3 = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            // Bearbeitete Kategorie speichern
            axiosInstance.patch(`/onboarding/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    setCategories(categories.map(category => category.id === editingCategory.id ? response.data : category));
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Fehler beim Bearbeiten der Kategorie:', error);
                });
        } else {
            // Neue Kategorie speichern
            axiosInstance.post('/onboarding/categories/', { name: categoryName, onboarding_plan: onboardingPlanId })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Fehler beim Speichern der Kategorie:', error);
                });
        }
    };

    // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Themen
    const handleAddTopicPlaceholderClick = (categoryId) => {
        setTopicName('');
        setEditingTopic(null);
        setSelectedCategory(categoryId);
        setOpenTopicDialog(true);
    };

    const handleSaveTopic = () => {
        if (topicName.trim() === '') {
            setError('Themenname darf nicht leer sein.');
            return;
        }

        if (editingTopic) {
            // Bearbeitetes Thema speichern
            axiosInstance.patch(`/onboarding/topics/${editingTopic.id}/`, { name: topicName })
                .then((response) => {
                    setTopics(topics.map(topic => topic.id === editingTopic.id ? response.data : topic));
                    setEditingTopic(null);
                    setTopicName('');
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Themas.');
                    console.error('Fehler beim Bearbeiten des Themas:', error);
                });
        } else {
            // Neues Thema speichern
            axiosInstance.post('/onboarding/topics/', { name: topicName, category: selectedCategory })
                .then((response) => {
                    setTopics([...topics, response.data]);
                    setTopicName('');
                    setError(null);
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Themas.');
                    console.error('Fehler beim Speichern des Themas:', error);
                });
        }
    };

    const handleDeleteTopic = (topicId) => {
        axiosInstance.delete(`/onboarding/topics/${topicId}`)
            .then(() => {
                setTopics(topics.filter(topic => topic.id !== topicId));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Themas:', error);
            });
    };

    const handleEditTopic = (topic) => {
        setEditingTopic(topic);
        setTopicName(topic.name);
        setSelectedCategory(topic.category);
        setOpenTopicDialog(true);
    };

    // Funktionen zum Hinzufügen, Bearbeiten und Löschen von Schritten
    const handleAddStepPlaceholderClick = (topicId) => {
        setStepName('');
        setEditingStep(null);
        setSelectedTopic(topicId);
        setOpenStepDialog(true);
    };

    const handleSaveStep = () => {
        if (stepName.trim() === '') {
            setError('Schrittbeschreibung darf nicht leer sein.');
            return;
        }

        if (editingStep) {
            // Bearbeiteten Schritt speichern
            axiosInstance.patch(`/onboarding/onboarding-steps/${editingStep.id}/`, { description: stepName })
                .then((response) => {
                    setStepsData(stepsData.map(step => step.id === editingStep.id ? response.data : step));
                    setEditingStep(null);
                    setStepName('');
                    setOpenStepDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Schritts.');
                    console.error('Fehler beim Bearbeiten des Schritts:', error);
                });
        } else {
            // Neuen Schritt speichern
            axiosInstance.post('/onboarding/onboarding-steps/', { description: stepName, topic: selectedTopic })
                .then((response) => {
                    setStepsData([...stepsData, response.data]);
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Schritts.');
                    console.error('Fehler beim Speichern des Schritts:', error);
                });
        }
    };

    const handleDeleteStep = (stepId) => {
        axiosInstance.delete(`/onboarding/onboarding-steps/${stepId}`)
            .then(() => {
                setStepsData(stepsData.filter(step => step.id !== stepId));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Schritts:', error);
            });
    };

    const handleEditStep = (step) => {
        setEditingStep(step);
        setStepName(step.description);
        setSelectedTopic(step.topic);
        setOpenStepDialog(true);
    };

    useEffect(() => {
        if (activeStep === 2 && onboardingPlanId) {
            // Kategorien für den Onboarding-Plan abrufen
            axiosInstance.get('/onboarding/categories/')
                .then(response => {
                    const filteredCategories = response.data.results.filter(category => category.onboarding_plan === onboardingPlanId);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Kategorien:', error);
                });

            // Themen abrufen
            axiosInstance.get('/onboarding/topics/')
                .then(response => {
                    setTopics(response.data.results);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Themen:', error);
                });

            // Schritte abrufen
            axiosInstance.get('/onboarding/onboarding-steps/')
                .then(response => {
                    setStepsData(response.data.results);
                })
                .catch(error => {
                    console.error('Fehler beim Abrufen der Schritte:', error);
                });
        }
    }, [activeStep, onboardingPlanId]);

    // Funktion zum Löschen des erstellten Onboarding-Plans und Zurücksetzen des Formulars
    const deleteOnboardingPlan = async () => {
        if (onboardingPlanId) {
            try {
                // Lösche alle zugehörigen Kategorien
                for (let category of categories) {
                    await axiosInstance.delete(`/onboarding/categories/${category.id}/`);
                }
                // Lösche den Onboarding-Plan
                await axiosInstance.delete(`/onboarding/plans/${onboardingPlanId}/`);
            } catch (error) {
                console.error('Fehler beim Löschen des Onboarding-Plans:', error);
            }
        }
    };

    const resetForm = () => {
        setActiveStep(0);
        setName('');
        setDescription('');
        setIsActive(true);
        setOnboardingPlanId(null);
        setCategories([]);
        setCategoryName('');
        setTopics([]);
        setTopicName('');
        setStepsData([]);
        setStepName('');
        setError(null);
        setSelectedCategory(null);
        setSelectedTopic(null);
        setOpenCategoryDialog(false);
        setOpenTopicDialog(false);
        setOpenStepDialog(false);
        setEditingCategory(null);
        setEditingTopic(null);
        setEditingStep(null);
    };

    // Funktion zum Öffnen des Bestätigungsdialogs beim Schließen
    const handleCloseWithConfirmation = () => {
        setOpenConfirmDialog(true);
    };

    const handleConfirmClose = async () => {
        // Überprüfen, ob der Onboarding-Plan bereits erstellt wurde
        if (onboardingPlanId) {
            try {
                // Lösche alle zugehörigen Kategorien
                for (let category of categories) {
                    // Lösche die zugehörigen Themen und Schritte der Kategorie
                    const relatedTopics = topics.filter(topic => topic.category === category.id);
                    
                    for (let topic of relatedTopics) {
                        // Lösche alle Schritte, die mit dem Thema verbunden sind
                        const relatedSteps = stepsData.filter(step => step.topic === topic.id);
                        for (let step of relatedSteps) {
                            await axiosInstance.delete(`/onboarding/onboarding-steps/${step.id}/`);
                        }
                        // Lösche das Thema selbst
                        await axiosInstance.delete(`/onboarding/topics/${topic.id}/`);
                    }
                    
                    // Lösche die Kategorie
                    await axiosInstance.delete(`/onboarding/categories/${category.id}/`);
                }
    
                // Lösche den Onboarding-Plan
                await axiosInstance.delete(`/onboarding/onboarding-plans/${onboardingPlanId}/`);
            } catch (error) {
                console.error('Fehler beim Löschen des Onboarding-Plans und seiner Kategorien:', error);
            }
        }
    
        // Setze das Formular zurück und schließe den Dialog
        resetForm();
        setOpenConfirmDialog(false);
        handleClose();
    };
    

    // Funktion, die ausgeführt wird, wenn der Nutzer das Schließen abbricht
    const handleCancelClose = () => {
        setOpenConfirmDialog(false);
    };

    // Anpassung des onClose-Events, um Schließen über Klick außerhalb oder Escape-Taste abzufangen
    const handleDialogClose = (event, reason) => {
        if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
            handleCloseWithConfirmation();
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={handleDialogClose} // Anpassung des onClose-Events
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Neuen Onboarding-Plan hinzufügen</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} alternativeLabel>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <Box sx={{ mt: 3 }}>
                        {activeStep === 0 && (
                            <Box component="form" sx={{ width: '100%' }}>
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="name"
                                    label="Name des Onboarding-Plans"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                <TextField
                                    margin="normal"
                                    fullWidth
                                    id="description"
                                    label="Beschreibung"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    multiline
                                    rows={4}
                                />
                            </Box>
                        )}
                        {activeStep === 1 && (
                            <Box>
                                <Typography variant="h6" gutterBottom>Kategorien hinzufügen</Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                    <TextField
                                        label="Kategoriename"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        fullWidth
                                    />
                                    <IconButton color="primary" onClick={handleAddCategory}>
                                        <AddIcon />
                                    </IconButton>
                                </Box>
                                <List>
                                    {categories.map((category) => (
                                        <React.Fragment key={category.id}>
                                            <ListItem
                                                secondaryAction={
                                                    <>
                                                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                                                            <EditIcon />
                                                        </IconButton>
                                                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                                                            <DeleteIcon />
                                                        </IconButton>
                                                    </>
                                                }
                                            >
                                                <ListItemText primary={category.name} />
                                            </ListItem>
                                            <Divider />
                                        </React.Fragment>
                                    ))}
                                </List>
                            </Box>
                        )}
                        {activeStep === 2 && onboardingPlanId && (
                            <Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                <Box component="form" sx={{ mb: 2 }}>
                                    <List>
                                        {categories.length > 0 ? categories.map((category) => (
                                            <CategoryItem
                                                key={category.id}
                                                category={category}
                                                topics={topics.filter(topic => topic.category === category.id)}
                                                stepsData={stepsData}
                                                handleDeleteCategory={handleDeleteCategory}
                                                handleEditCategory={handleEditCategory}
                                                handleDeleteTopic={handleDeleteTopic}
                                                handleEditTopic={handleEditTopic}
                                                handleDeleteStep={handleDeleteStep}
                                                handleEditStep={handleEditStep}
                                                handleAddTopicPlaceholderClick={handleAddTopicPlaceholderClick}
                                                handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                            />
                                        )) : (
                                            <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                                        )}
                                    </List>
                                    
                                    {/* Button zum Hinzufügen einer neuen Kategorie im gleichen Stil wie der Platzhalter */}
                                    <Box
                                        sx={{
                                            border: '2px dashed #ddd',
                                            borderRadius: '8px',
                                            padding: '16px',
                                            textAlign: 'center',
                                            cursor: 'pointer',
                                            color: '#888',
                                            mt: 2,
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: '#f5f5f5'
                                            },
                                            boxSizing: 'border-box',
                                        }}
                                        onClick={() => setOpenCategoryDialog(true)}
                                    >
                                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                                        Neue Kategorie anlegen
                                    </Box>
                                </Box>
                            </Box>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        variant="outlined"
                    >
                        Zurück
                    </Button>
                    <Button
                        onClick={handleCloseWithConfirmation}
                        variant="outlined"
                        color="secondary"
                    >
                        Abbrechen
                    </Button>
                    {activeStep === steps.length - 1 ? (
                        <Button
                            onClick={handleSaveAndClose}
                            variant="contained"
                            color="primary"
                        >
                            Fertigstellen
                        </Button>
                    ) : (
                        <Button
                            onClick={handleNext}
                            variant="contained"
                            color="primary"
                            disabled={activeStep === 1 && categories.length === 0}
                        >
                            Weiter
                        </Button>
                    )}
                </DialogActions>
            </Dialog>

            {/* Bestätigungsdialog */}
            <Dialog
                open={openConfirmDialog}
                onClose={handleCancelClose}
            >
                <DialogTitle>Bestätigung</DialogTitle>
                <DialogContent>
                    <Typography>
                        Möchtest du wirklich abbrechen? Alle nicht gespeicherten Änderungen gehen verloren.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClose} color="primary">
                        Nein
                    </Button>
                    <Button onClick={handleConfirmClose} color="secondary">
                        Ja, abbrechen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Kategoriedialog */}
            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => {
                    setOpenCategoryDialog(false);
                    setEditingCategory(null);
                    setCategoryName('');
                    setError(null);
                }}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategoryInStep3}
            />

            {/* Themendialog */}
            <TopicDialog
                open={openTopicDialog}
                onClose={() => {
                    setOpenTopicDialog(false);
                    setEditingTopic(null);
                    setTopicName('');
                    setError(null);
                }}
                topicName={topicName}
                setTopicName={setTopicName}
                handleSaveTopic={handleSaveTopic}
            />

            {/* Schritt-Dialog */}
            <StepDialog
                open={openStepDialog}
                onClose={() => {
                    setOpenStepDialog(false);
                    setEditingStep(null);
                    setStepName('');
                    setError(null);
                }}
                stepName={stepName}
                setStepName={setStepName}
                handleSaveStep={handleSaveStep}
            />
        </>
    );
};

// Kategorie-Item-Komponente
const CategoryItem = ({ category, topics, stepsData, handleDeleteCategory, handleEditCategory, handleDeleteTopic, handleEditTopic, handleDeleteStep, handleEditStep, handleAddTopicPlaceholderClick, handleAddStepPlaceholderClick }) => {
    const [openCategory, setOpenCategory] = useState(false);

    const toggleCategory = () => {
        setOpenCategory(!openCategory);
    };

    return (
        <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2 }}>
            <ListItem
                secondaryAction={
                    <>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                }
                onClick={toggleCategory}
            >
                <ListItemText primary={category.name} />
            </ListItem>
            <Collapse in={openCategory} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <TopicList
                        topics={topics}
                        stepsData={stepsData}
                        handleDeleteTopic={handleDeleteTopic}
                        handleEditTopic={handleEditTopic}
                        handleDeleteStep={handleDeleteStep}
                        handleEditStep={handleEditStep}
                        handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                    />
                    <Box
                        sx={{
                            border: '2px dashed #ddd',
                            borderRadius: '8px',
                            padding: '16px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            color: '#888',
                            mt: 2,
                            width: '100%',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            },
                            boxSizing: 'border-box',
                        }}
                        onClick={() => handleAddTopicPlaceholderClick(category.id)}
                    >
                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Neues Thema anlegen
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

// Themenliste-Komponente
const TopicList = ({ topics, stepsData, handleDeleteTopic, handleEditTopic, handleDeleteStep, handleEditStep, handleAddStepPlaceholderClick }) => (
    <List sx={{ width: '100%' }}>
        {topics.length > 0 ? (
            topics.map((topic) => (
                <TopicItem
                    key={topic.id}
                    topic={topic}
                    stepsData={stepsData}
                    handleDeleteTopic={handleDeleteTopic}
                    handleEditTopic={handleEditTopic}
                    handleDeleteStep={handleDeleteStep}
                    handleEditStep={handleEditStep}
                    handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                />
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">
                Keine Themen vorhanden.
            </Typography>
        )}
    </List>
);

// Thema-Item-Komponente
const TopicItem = ({ topic, stepsData, handleDeleteTopic, handleEditTopic, handleDeleteStep, handleEditStep, handleAddStepPlaceholderClick }) => {
    const [openTopic, setOpenTopic] = useState(false);

    const toggleTopic = () => {
        setOpenTopic(!openTopic);
    };

    const steps = stepsData.filter(step => step.topic === topic.id);

    return (
        <Box
            sx={{
                border: '1px solid #ddd',
                borderRadius: '8px',
                p: 2,
                mb: 1,
                width: '100%',
                boxSizing: 'border-box',
            }}
        >
            <ListItem
                secondaryAction={
                    <>
                        <IconButton edge="end" aria-label="edit" onClick={() => handleEditTopic(topic)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTopic(topic.id)}>
                            <DeleteIcon />
                        </IconButton>
                    </>
                }
                onClick={toggleTopic}
            >
                <ListItemText primary={topic.name} />
            </ListItem>
            <Collapse in={openTopic} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <StepList
                        steps={steps}
                        handleDeleteStep={handleDeleteStep}
                        handleEditStep={handleEditStep}
                    />
                    <Box
                        sx={{
                            border: '2px dashed #ddd',
                            borderRadius: '8px',
                            padding: '16px',
                            textAlign: 'center',
                            cursor: 'pointer',
                            color: '#888',
                            mt: 2,
                            width: '100%',
                            '&:hover': {
                                backgroundColor: '#f5f5f5'
                            },
                            boxSizing: 'border-box',
                        }}
                        onClick={() => handleAddStepPlaceholderClick(topic.id)}
                    >
                        <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                        Neuen Schritt anlegen
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
};

// Schrittliste-Komponente
const StepList = ({ steps, handleDeleteStep, handleEditStep }) => (
    <List sx={{ width: '100%' }}>
        {steps.length > 0 ? (
            steps.map((step) => (
                <Box
                    key={step.id}
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        p: 2,
                        mb: 1,
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <ListItem
                        secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditStep(step)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStep(step.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText primary={step.description} />
                    </ListItem>
                </Box>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">
                Keine Schritte vorhanden.
            </Typography>
        )}
    </List>
);

export default OnboardingHinzufuegen;
