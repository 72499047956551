// pages/verkaufsmenge/Verkaufsmenge.js

import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Paper,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BarChartIcon from '@mui/icons-material/BarChart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search'; // Lupen-Icon
import FullscreenIcon from '@mui/icons-material/Fullscreen'; // Fullscreen-Icon
import CloseIcon from '@mui/icons-material/Close'; // Close-Icon
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import DataCard from '../../../components/card/scorecardsmonat/WarengruppenCard';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import BigTableCard from '../../../components/card/BigTableCard';
import BarChartCardFlexibleYAxis from '../../../components/card/scorecardsmonat/BarChartCardFlexibleYAxis';
import LineChartVariableYAxis from '../../../components/charts/LineChartVariableYAxis';

export default function Verkaufsmenge() {
  const { filterState, handleDatumChange } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [warengruppenData, setWarengruppenData] = useState([]);
  const [articleCounts, setArticleCounts] = useState({});
  const [soldArticleCounts, setSoldArticleCounts] = useState({});
  const [yAxisMax, setYAxisMax] = useState(1000000);
  const [yAxisSliderMax, setYAxisSliderMax] = useState(1000000);
  const [combinedLineData, setCombinedLineData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);

  // Zustände für die Dialogs
  const [openTableModal, setOpenTableModal] = useState(false);
  const [openFullscreenChart, setOpenFullscreenChart] = useState(false);

  // Zustand für die Fenstergröße
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setWindowHeight(window.innerHeight);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchMonthlyWarengruppenData();
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(
        response.data.map((item) => ({ label: item, value: item }))
      );
    } catch (error) {
      console.error('Fehler beim Abrufen der Datumsoptionen:', error);
    }
  };

  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  // Hilfsfunktion zum Parsen von 'YYYY.MM' in Date-Objekte
  function parseYYYYMM(str) {
    const [year, month] = str.split('.').map(Number);
    return new Date(year, month - 1);
  }

  const fetchMonthlyWarengruppenData = async () => {
    const last6MonthsArray = getLast6Months(filterState.datum?.value);

    try {
      const promises = last6MonthsArray.map((month) =>
        axiosInstance
          .get('/scorecards_month/ware-warengruppe-monat/', {
            params: {
              jahrMonat: month,
            },
          })
          .then((response) => ({ month, data: response.data.results }))
      );

      const results = await Promise.all(promises);

      const aggregatedData = {};

      results.forEach(({ month, data }) => {
        console.log(`Monat: ${month}`, data); // Zum Debuggen

        data.forEach((item) => {
          // Korrigierter Feldname
          const warengruppennummer = item.warengruppennummer; // Angenommen, das ist der richtige Feldname
          const warengruppe = item.warengruppe;
          const verkaufsmenge = item.verkaufsmenge || 0;
          const verkaufsmengeVJ = item.verkaufsmengeVJ || 0;

          console.log(`Warengruppe: ${warengruppe}, Warengruppennummer: ${warengruppennummer}`); // Zum Debuggen

          if (!aggregatedData[warengruppennummer]) {
            aggregatedData[warengruppennummer] = {
              warengruppe,
              data: {},
            };
          }

          if (!aggregatedData[warengruppennummer].data[month]) {
            aggregatedData[warengruppennummer].data[month] = {
              verkaufsmenge: 0,
              verkaufsmengeVJ: 0,
            };
          }

          aggregatedData[warengruppennummer].data[month].verkaufsmenge += verkaufsmenge;
          aggregatedData[warengruppennummer].data[month].verkaufsmengeVJ += verkaufsmengeVJ;
        });
      });

      const formattedData = Object.values(aggregatedData).map((warengruppe) => ({
        warengruppe: warengruppe.warengruppe,
        data: last6MonthsArray.map((month) => ({
          name: month,
          value: warengruppe.data[month]?.verkaufsmenge || 0,
          previousValue: warengruppe.data[month]?.verkaufsmengeVJ || 0,
        })),
        verkaufsmenge:
          warengruppe.data[filterState.datum?.value]?.verkaufsmenge || 0,
        verkaufsmengeVJ:
          warengruppe.data[filterState.datum?.value]?.verkaufsmengeVJ || 0,
      }));

      console.log('Aggregated Data:', aggregatedData); // Zum Debuggen
      console.log('Formatted Data:', formattedData); // Zum Debuggen

      // Berechnung des maximalen Verkaufswerts über alle Monate hinweg
      const maxDataValue = Math.max(...formattedData.flatMap(wg => wg.data.map(d => d.value)));
      const bufferedMax = Math.ceil(maxDataValue * 1.1); // Rundet auf die nächste ganze Zahl
      setYAxisSliderMax(bufferedMax);
      setYAxisMax(bufferedMax);

      setWarengruppenData(formattedData);

      // Daten für das Liniendiagramm vorbereiten (für Recharts)
      // Kombiniere die Daten aller Warengruppen in ein einzelnes Array
      const combinedData = last6MonthsArray.map((month) => {
        const entry = { name: month };
        formattedData.forEach((warengruppe) => {
          entry[warengruppe.warengruppe] = warengruppe.data.find((d) => d.name === month)?.value || 0;
        });
        return entry;
      });

      setCombinedLineData(combinedData);

      // Tabellenspalten vorbereiten (ohne verkaufsmengeVJ)
      const newTableColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        ...last6MonthsArray.map((month) => ({
          accessorKey: month,
          header: month,
        })),
      ];
      setTableColumns(newTableColumns);

      // Tabellendaten vorbereiten (ohne verkaufsmengeVJ)
      const newTableData = formattedData.map((warengruppe) => {
        const row = { warengruppe: warengruppe.warengruppe };
        warengruppe.data.forEach((point) => {
          row[point.name] = point.value;
        });
        return row;
      });
      setTableData(newTableData);

      fetchArticleCounts(formattedData);
      fetchSoldArticleCounts(formattedData);
    } catch (error) {
      console.error('Fehler beim Abrufen der monatlichen Warengruppen-Daten:', error);
    }
  };

  const fetchArticleCounts = async (warengruppenData) => {
    try {
      const counts = await Promise.all(
        warengruppenData.map(async (warengruppe) => {
          const response = await axiosInstance.get(
            '/scorecard_month_article/abc-unternehmen/',
            {
              params: {
                ABC: 'A',
                warengruppe: warengruppe.warengruppe,
                jahrMonat: filterState.datum?.value,
              },
            }
          );
          return {
            warengruppe: warengruppe.warengruppe,
            count: response.data.results.length,
          };
        })
      );

      const articleCountMap = counts.reduce((acc, { warengruppe, count }) => {
        acc[warengruppe] = count;
        return acc;
      }, {});

      setArticleCounts(articleCountMap);
    } catch (error) {
      console.error('Fehler beim Abrufen der Artikelanzahl:', error);
    }
  };

  const fetchSoldArticleCounts = async (warengruppeData) => {
    try {
      const counts = await Promise.all(
        warengruppeData.map(async (warengruppe) => {
          const response = await axiosInstance.get(
            '/scorecard_month_article/ware-artikel-monat/',
            {
              params: {
                warengruppe: warengruppe.warengruppe,
                jahrMonat: filterState.datum?.value,
              },
            }
          );

          const uniqueArticleNumbers = new Set(
            response.data.results
              .filter((item) => item.verkaufsmenge > 0)
              .map((item) => item.artikelnummer.artikelnummer)
          );

          return {
            warengruppe: warengruppe.warengruppe,
            count: uniqueArticleNumbers.size,
          };
        })
      );

      const soldArticleCountMap = counts.reduce((acc, { warengruppe, count }) => {
        acc[warengruppe] = count;
        return acc;
      }, {});

      setSoldArticleCounts(soldArticleCountMap);
    } catch (error) {
      console.error('Fehler beim Abrufen der verkauften Artikelanzahl:', error);
    }
  };

  const handleRowClick = (row) => {
    console.log('Zeile geklickt:', row);
    // Hier können Sie eine Aktion beim Klicken auf eine Tabellenzeile implementieren
  };

  // Berechne die Top 6 Warengruppen basierend auf verkaufsmenge
  const top7WarengruppenData = useMemo(() => {
    return [...warengruppenData]
      .sort((a, b) => b.verkaufsmenge - a.verkaufsmenge)
      .slice(0, 7);
  }, [warengruppenData]);

  const top7DataKeys = useMemo(() => {
    return top7WarengruppenData.map((wg) => wg.warengruppe);
  }, [top7WarengruppenData]);

  const top7CombinedData = useMemo(() => {
    return getLast6Months(filterState.datum?.value).map((month) => {
      const entry = { name: month };
      top7WarengruppenData.forEach((wg) => {
        const dataPoint = wg.data.find((d) => d.name === month);
        entry[wg.warengruppe] = dataPoint ? dataPoint.value : 0;
      });
      return entry;
    });
  }, [top7WarengruppenData, filterState.datum?.value]);

  const barChartData = useMemo(() => {
    return top7WarengruppenData.map((item) => ({
      warengruppe: item.warengruppe,
      verkaufsmenge: item.verkaufsmenge,
      verkaufsmengeVJ: item.verkaufsmengeVJ,
    }));
  }, [top7WarengruppenData]);

  return (
    <Layout>
      {/* Header und Filter */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          mt: 2,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title="Verkaufsmengen"
            subtitle="Übersicht über die Verkaufsmengen im Sortiment"
            Icon={BarChartIcon}
          />
        </Box>

        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
          <DropdownFilter
            label="Datum"
            options={datumOptions}
            value={filterState.datum}
            onChange={handleDatumChange}
          />
        </Box>
      </Box>

      {/* Diagrammsektion */}
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={2}>
          {/* Linke Spalte: BarChartCardFlexibleYAxis */}
          <Grid item xs={12} md={6}>
            <Box sx={{ height: '100%' }}>
              <BarChartCardFlexibleYAxis
                data={barChartData}
                dataKeys={['verkaufsmenge', 'verkaufsmengeVJ']}
                xAxisKey="warengruppe"
                title="Verkaufsmengen der Warengruppen"
                infoContent="Top 6 Warengruppen nach Verkaufsmenge"
                height={300} // Feste Höhe
                legendNames={['Verkaufsm. Aktuell', 'Verkaufsm. VJ']}
                yAxisMin={0}
                yAxisMax={yAxisSliderMax}
              />
            </Box>
          </Grid>

          {/* Rechte Spalte: Liniendiagramm */}
          <Grid item xs={12} md={6}>
            <Box sx={{ height: '100%' }}>
              <Paper
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6">
                      Verkaufsmengen der letzten 6 Monate
                    </Typography>
                  }
                  action={
                    <Box>
                      {/* Info-Icon */}
                      <Tooltip
                        title="Entwicklung der Verkaufsmengen pro Warengruppe über die letzten 6 Monate"
                        arrow
                        placement="top"
                      >
                        <IconButton>
                          <InfoOutlinedIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Lupen-Icon */}
                      <Tooltip
                        title="Details in Tabellenform anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenTableModal(true)}>
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>

                      {/* Fullscreen-Icon */}
                      <Tooltip
                        title="Diagramm im Vollbildmodus anzeigen"
                        arrow
                        placement="top"
                      >
                        <IconButton onClick={() => setOpenFullscreenChart(true)}>
                          <FullscreenIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  }
                />
                <CardContent
                  sx={{
                    flexGrow: 1,
                    height: '100%',
                    width: '100%',
                  }}
                >
                  {top7CombinedData && top7CombinedData.length > 0 ? (
                    <LineChartVariableYAxis
                      data={top7CombinedData}
                      dataKeys={top7DataKeys}
                      xAxisKey="name"
                      yAxisMax={yAxisMax} // Nun mit Puffer gesetzt
                      yAxisMin={0}
                      height={300} // Gleiche feste Höhe
                      width="100%"
                      lineColors={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c']}
                      legendNames={top7WarengruppenData.map((wg) => wg.warengruppe)}
                    />
                  ) : (
                    <Typography variant="body2" color="text.secondary">
                      Keine Daten verfügbar
                    </Typography>
                  )}
                </CardContent>
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Fullscreen Dialog für das LineChart */}
      <Dialog
        open={openFullscreenChart}
        onClose={() => setOpenFullscreenChart(false)}
        fullScreen
        aria-labelledby="fullscreen-chart-title"
      >
        <DialogTitle id="fullscreen-chart-title">
          Verkaufsmengen der letzten 6 Monate
          <IconButton
            aria-label="schließen"
            onClick={() => setOpenFullscreenChart(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {top7CombinedData && top7CombinedData.length > 0 ? (
            <LineChartVariableYAxis
              data={top7CombinedData}
              dataKeys={top7DataKeys}
              xAxisKey="name"
              yAxisMax={yAxisMax} // Mit Puffer gesetzt
              yAxisMin={0}
              height={windowHeight * 0.8} // Dynamische Höhe basierend auf Fenstergröße
              width="100%"
              lineColors={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57', '#a4de6c']}
              legendNames={top7WarengruppenData.map((wg) => wg.warengruppe)}
            />
          ) : (
            <Typography variant="body2" color="text.secondary">
              Keine Daten verfügbar
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenFullscreenChart(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog für die Tabelle */}
      <Dialog
        open={openTableModal}
        onClose={() => setOpenTableModal(false)}
        fullWidth
        maxWidth="lg" // Passe die Größe nach Bedarf an
      >
        <DialogTitle>Verkaufsdaten in Tabellenform</DialogTitle>
        <DialogContent>
          <BigTableCard
            title="Verkaufsdaten"
            data={tableData}
            columns={tableColumns}
            onRowClick={handleRowClick}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenTableModal(false)} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Datenkarten */}
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {warengruppenData.map((warengruppe) => (
          <Grid item xs={12} sm={6} md={4} key={warengruppe.warengruppe}>
            <DataCard
              chartData={warengruppe.data}
              value={warengruppe.warengruppe}
              selectedMonth={filterState.datum?.value}
              articleCount={articleCounts[warengruppe.warengruppe] || 0}
              soldArticleCount={soldArticleCounts[warengruppe.warengruppe] || 0}
            />
          </Grid>
        ))}
      </Grid>
    </Layout>
  );
}
