import {Alert, Collapse, IconButton} from "@mui/material";
import {getAlertIcon, getAlertSeverity} from "./AlertPopup";
import CloseIcon from "@mui/icons-material/Close";
import React, {useState} from "react";


const AlertField = ({data, feedbackField, scoreField}) => {
    const [alertOpen, setAlertOpen] = useState(false);

    const handleAlertIconClick = () => {
        setAlertOpen(!alertOpen);
    };

    return (
        <div>
            <div style={{display: 'flex', justifyContent: 'flex-start', width: '100%', marginTop: '16px'}}>
                <IconButton
                    aria-label="alert"
                    color="inherit"
                    size="large"
                    onClick={handleAlertIconClick}
                    sx={{fontSize: '32px'}}
                >
                    {getAlertIcon(data.length > 0 ? data[0][scoreField] : null)}
                </IconButton>
            </div>
            <Collapse in={alertOpen} sx={{width: '100%', mt: 2}}>
                <Alert
                    severity={getAlertSeverity(data.length > 0 ? data[0][scoreField] : null)}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="large"
                            onClick={() => setAlertOpen(false)}
                        >
                            <CloseIcon fontSize="inherit"/>
                        </IconButton>
                    }
                >
                    {data.length > 0 ? data[0][feedbackField] : 'Keine Rückmeldung verfügbar'}
                </Alert>
            </Collapse>
        </div>
    )
}

export default AlertField;
