// components/popup/unternehmen/ArticleDetailsPopup.js

import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton, Typography } from '@mui/material';
import { ScatterChart, Scatter, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';
import { Close as CloseIcon } from '@mui/icons-material';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import BigTableCard from '../../card/BigTableCard'; // BigTableCard importieren

export default function ArticleDetailsPopupRetoure({ open, onClose, warengruppe, jahrMonat }) {
    const [articleData, setArticleData] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (open) {
            fetchTableData();
            fetchChartData();
        }
    }, [open, warengruppe, jahrMonat]); // Abhängigkeiten erweitert

    const fetchTableData = async () => {
        try {
            const previousYear = (parseInt(jahrMonat.split('.')[0]) - 1).toString() + '.' + jahrMonat.split('.')[1];
            
            // API-Anfragen für das aktuelle Jahr und das Vorjahr
            const currentYearData = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                params: {
                    warengruppe,
                    jahrMonat,
                },
            });

            const previousYearData = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                params: {
                    warengruppe,
                    jahrMonat: previousYear,
                },
            });

            const articleMap = {};

            // Summe der Retouren, Lieferungen und Korrekturen für das aktuelle Jahr
            currentYearData.data.results.forEach(item => {
                const { artikelnummer, retourmenge = 0, liefermenge = 0, korrekturmenge = 0 } = item;
                const artikel = artikelnummer.artikel;
                if (!articleMap[artikel]) {
                    articleMap[artikel] = { retourmenge: 0, liefermenge: 0, korrekturmenge: 0 };
                }
                articleMap[artikel].retourmenge += retourmenge;
                articleMap[artikel].liefermenge += liefermenge;
                articleMap[artikel].korrekturmenge += korrekturmenge;
            });

            // Summe der Retouren, Lieferungen und Korrekturen für das Vorjahr (optional verarbeiten)
            previousYearData.data.results.forEach(item => {
                const { artikelnummer, retourmenge = 0, liefermenge = 0, korrekturmenge = 0 } = item;
                const artikel = artikelnummer.artikel;
                if (!articleMap[artikel]) {
                    articleMap[artikel] = { retourmenge: 0, liefermenge: 0, korrekturmenge: 0 };
                }
                // Hier kannst du Vorjahresdaten verarbeiten, falls erforderlich
                // Beispiel: articleMap[artikel].retourQuoteVJ += ...;
            });

            // Formatierung der Daten in ein Array für die Tabelle und Berechnung der Retourenquote
            const formattedTableData = Object.entries(articleMap)
                .map(([artikel, data]) => {
                    const totalLiefermenge = data.liefermenge;
                    const totalRetourmenge = data.retourmenge;
                    const totalKorrekturmenge = data.korrekturmenge;
                    const verkaufsmenge = totalLiefermenge + totalKorrekturmenge;
                    const retourQuote = verkaufsmenge
                        ? ((totalRetourmenge / verkaufsmenge) * 100).toFixed(2) + '%'
                        : '0.00%';

                    return {
                        artikel,
                        liefermenge: data.liefermenge.toLocaleString('de-DE'),
                        retourmenge: data.retourmenge.toLocaleString('de-DE'),
                        korrekturmenge: data.korrekturmenge.toLocaleString('de-DE'),
                        verkaufsmenge: verkaufsmenge.toLocaleString('de-DE'), // Verkaufsmenge hinzugefügt
                        retourQuote,
                    };
                })
                .filter(item => parseFloat(item.retourQuote) > 0); // Filterung der Einträge mit retourQuote > 0

            setArticleData(formattedTableData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Artikeldaten:', error);
        }
    };


    const fetchChartData = async () => {
        try {
            // Nur den aktuellen Monat berücksichtigen
            const currentMonth = jahrMonat;

            const response = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                params: {
                    warengruppe,
                    jahrMonat: currentMonth,
                },
            });

            const data = response.data.results;

            const articleMap = {};

            // Daten für den aktuellen Monat sammeln und aggregieren
            data.forEach(item => {
                const { artikelnummer, retourmenge = 0, liefermenge = 0, korrekturmenge = 0 } = item;
                const artikel = artikelnummer.artikel;

                if (!articleMap[artikel]) {
                    articleMap[artikel] = { retourmenge: 0, liefermenge: 0, korrekturmenge: 0 };
                }
                
                // Aggregiere die Retourenquote für denselben Artikel
                articleMap[artikel].retourmenge += retourmenge;
                articleMap[artikel].liefermenge += liefermenge;
                articleMap[artikel].korrekturmenge += korrekturmenge;
            });

            // Filtern der Artikel mit retourQuote > 0
            const filteredArticles = Object.entries(articleMap)
                .map(([artikel, data]) => {
                    const totalLiefermenge = data.liefermenge;
                    const totalRetourmenge = data.retourmenge;
                    const totalKorrekturmenge = data.korrekturmenge;
                    const verkaufsmenge = totalLiefermenge + totalKorrekturmenge;
                    const retourQuote = totalLiefermenge
                        ? (totalRetourmenge / verkaufsmenge) * 100
                        : 0;

                    return {
                        artikel,
                        liefermenge: data.liefermenge,
                        verkaufsmenge, // Verkaufsmenge hinzugefügt
                        retourQuote: parseFloat(retourQuote.toFixed(2)),
                    };
                })
                .filter(item => item.retourQuote > 0); // Nur Artikel mit retourQuote > 0

            // Daten für das ScatterChart formatieren
            const formattedChartData = filteredArticles.map(artikel => ({
                ...artikel,
            }));

            setChartData(formattedChartData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Retourendaten für das Chart:', error);
        }
    };

    // Spaltenkonfiguration für die Tabelle
    const columns = [
        { accessorKey: 'artikel', header: 'Artikel' },
        { accessorKey: 'liefermenge', header: 'Liefermenge', align: 'right' },
        { accessorKey: 'retourmenge', header: 'Retourmenge', align: 'right' },
        { accessorKey: 'korrekturmenge', header: 'Korrekturmenge', align: 'right' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge', align: 'right' }, // Verkaufsmenge hinzugefügt
        { accessorKey: 'retourQuote', header: 'Retourenquote %', align: 'right' },
    ];

    // Farben für die Scatter-Punkte
    const scatterColors = [
        '#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#d0ed57',
        '#a4de6c', '#a4de6c', '#8dd1e1', '#83a6ed', '#8dd1e1',
        '#ff8042', '#00C49F', '#FFBB28', '#FF8042', '#0088FE',
        '#00C49F', '#FFBB28', '#FF8042', '#0088FE', '#00C49F'
        // Füge weitere Farben hinzu, falls nötig
    ];

    // Custom Tooltip, um den Artikelnamen anzuzeigen
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const data = payload[0].payload;
            return (
                <Box sx={{ backgroundColor: 'white', border: '1px solid #ccc', padding: '10px' }}>
                    <Typography variant="subtitle2">{data.artikel}</Typography>
                    <Typography variant="body2">Verkaufsmenge: {data.verkaufsmenge.toLocaleString('de-DE')}</Typography>
                    <Typography variant="body2">Retourenquote: {data.retourQuote}%</Typography>
                </Box>
            );
        }

        return null;
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Artikeldetails - {warengruppe} - {jahrMonat}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {/* Scatter Chart */}
                <Box sx={{ width: '100%', height: 400, mb: 3 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <ScatterChart
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                        >
                            <CartesianGrid />
                            <XAxis 
                                dataKey="verkaufsmenge" 
                                name="Verkaufsmenge" 
                                type="number" 
                                tick={{ fontSize: 12 }} 
                                label={{ value: 'Verkaufsmenge', position: 'insideBottom', offset: -10 }}
                            />
                            <YAxis 
                                dataKey="retourQuote" 
                                name="Retourenquote (%)" 
                                label={{ value: 'Retourenquote (%)', angle: -90, position: 'insideLeft' }}
                            />
                            <Tooltip content={<CustomTooltip />} />
                            {/* <Legend verticalAlign="top" height={36}/> */} {/* Legend entfernt */}
                            {chartData.length > 0 && chartData.map((artikel, index) => (
                                <Scatter 
                                    key={artikel.artikel} 
                                    name={artikel.artikel} 
                                    data={[artikel]} 
                                    fill={scatterColors[index % scatterColors.length]} 
                                />
                            ))}
                        </ScatterChart>
                    </ResponsiveContainer>
                </Box>

                {/* Data Table */}
                <BigTableCard
                    title="Artikeldetails"
                    data={articleData}
                    columns={columns}
                    onRowClick={(row) => console.log("Row clicked:", row)}
                    muiTableBodyCellProps={{ align: 'right' }}
                />
            </DialogContent>
        </Dialog>
    );
}
