import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { LineChart } from '@mui/x-charts/LineChart';
import { format } from 'date-fns';

const LineChartSameYAxis = ({ data, title }) => {
    // Prepare data for the chart
    const series = data.reduce((acc, item) => {
        const xValue = format(new Date(item.x), 'MMMM yyyy');
        if (!acc[item.label]) {
            acc[item.label] = {
                label: item.label,  // Add label for legend
                data: [],
                color: item.label === 'Filiale' ? '#395D67' : '#95A78D'
            };
        }
        acc[item.label].data.push({ x: xValue, y: item.y });
        return acc;
    }, {});

    const uniqueLabels = Object.keys(series);

    return (
        <Box>
            <Typography variant="h6" component="div" gutterBottom>
                {title}
            </Typography>
            <LineChart
                xAxis={[
                    {
                        scaleType: 'band',
                        data: Array.from(new Set(data.map(item => format(new Date(item.x), 'MM.yyyy')))),
                        show: true,
                        title: "Monat",
                        tickFormatter: (value) => format(new Date(value), 'MMMM')
                    }
                ]}
                yAxis={[
                    { id: 'y', show: true, title: "Wert" }  // Single Y-Axis
                ]}
                series={Object.values(series).map(s => ({
                    data: s.data.map(d => d.y),
                    color: s.color
                }))}
                height={400}
                margin={{ top: 10, bottom: 30, left: 50, right: 50 }}
                tooltip
            />

            {/* Custom Legend */}
            <Box display="flex" justifyContent="center" mt={2}>
                {uniqueLabels.map((label) => (
                    <Typography key={label} variant="body2" sx={{ marginX: 1, display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: series[label].color,
                            marginRight: 4,
                        }} />
                        {label}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default LineChartSameYAxis;
