import * as React from 'react';
import { Card, Typography, Stack, Box, CardHeader,CardActionArea, CardContent } from '@mui/material';
import Sparkline from '../../charts/Sparkline2';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import {useTheme} from '@mui/material/styles';
import {useNavigate} from "react-router-dom";



const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };

    switch (category) {
        case 'Umsatz':
            return <EuroIcon {...iconProps} />;
        case 'Kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'Kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        case 'Leistung':
            return <TimerIcon {...iconProps} />;
        case 'Krankenquote':
            return <VaccinesIcon {...iconProps} />;
        case 'Fluktuation':
            return <GroupRemoveIcon {...iconProps} />;
        case 'Bedarf':
            return <ManageSearchIcon {...iconProps} />;
        case 'Auslastung':
            return <Battery3BarIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({ category,sector , value, subHeaders = [], numDataPoints = 7 }) {
    const navigate = useNavigate();
    const theme = useTheme();

    const handleNavigate = () => {
        navigate(`/unternehmen/${sector}/${category.toLowerCase()}`); // Adjust the path as necessary
    };



    return (
        <Card sx={{
            padding: '2px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent white background
            backdropFilter: 'blur(10px)', // Optional blur effect
            display: 'flex',
        }}>
            <CardActionArea onClick={handleNavigate}>
                <CardHeader
                    className="db_datacard_cardheader"
                    title={
                        <Stack direction="row" alignItems="center" justifyContent="space-between">
                            <Stack direction="row" alignItems="center">
                                {getCategoryIcon(category)}
                                <Typography variant="h2" className="db_datacard_category">
                                    {category}
                                </Typography>
                            </Stack>
                        </Stack>
                    }
                />
                <CardContent className="db_datacard_cardcontent">
                    {value && (
                        <Typography className="db_datacard_categorydata" sx={{ textAlign: 'left', ml: 2 }}>
                            {value}
                        </Typography>
                    )}
        
                    {subHeaders.map((subHeader, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            className="db_datacard_subheader"
                            sx={{
                                fontSize: theme.typography.body2.fontSize, // Use theme font size
                                color: theme.palette.text.secondary, // Use theme secondary text color
                                display: 'flex',
                                alignItems: 'center',
                                ml: 2, // Add margin to move the arrow further to the right, adjusting alignment with 'value'
                            }}
                        >
                            <Box sx={{ mr: 1}}> {/* Adjusted margin to position the arrow */}
                                {subHeader.startsWith('-') ? (
                                    <Box sx={{ color: theme.palette.error.main }}>↓</Box>
                                ) : (
                                    <Box sx={{ color: theme.palette.success.main }}>↑</Box>
                                )}
                            </Box>
                            {subHeader}
                        </Typography>
                    ))}
                </CardContent>
            </CardActionArea>
        </Card>
        
    );
}
