export const roundToDecimals = (value, decimals = 1) => {
    if (value === null || !isFinite(value)) return 0;
    return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
};

export const calculateTotal = (data, field) => {
    return data.reduce((acc, item) => {
        const value = item[field];
        return acc + (value !== null && isFinite(value) ? value : 0);
    }, 0);
}

export const calculateTotalAverage = (data, field) => {
    const validData = data.filter(item => item[field] !== null && isFinite(item[field]));
    return validData.length ? calculateTotal(validData, field) / validData.length : 0;
}

export const calculatePercentageDifference = (currentValue, previousValue) => {
    if (!isFinite(currentValue) || !isFinite(previousValue) || currentValue === null || previousValue === null) {
        return null;
    }
    return previousValue ? ((currentValue - previousValue) / previousValue) * 100 : null;
}

export const formatPercentage = (value) => {
    return `${(value * 100).toFixed(2)}`;
};

export const normaliseValue = (value) => {
    return (value / 10) * 100;
}

export const formatCurrency = (value) => {
    return `${parseFloat(value).toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} €`;
}

export const formatPercentageWithoutMultiplier = (value) => {
    return `${(value).toFixed(2)} %`;
};

export const tausenderTrennung = (value, decimalPlaces) => {
    return parseFloat(value).toLocaleString('de-DE', {minimumFractionDigits: decimalPlaces, maximumFractionDigits: decimalPlaces });
}

export const formatPercentageWithMultiplier = (value) => {
    return `${(value*100).toFixed(2)} %`;
};

// Hilfsfunktion zur Formatierung von Mengen (z.B. Verkaufsmenge, Liefermenge)
export const formatQuantity = (value) => {
    if (value === null || value === undefined) return '';
    return Math.floor(value).toLocaleString('de-DE'); // Tausendertrennzeichen, keine Nachkommastellen
  };
  
  // Hilfsfunktion zur Formatierung von Werten mit 2 Nachkommastellen (z.B. Preise, Umsätze)
  export const formatValue = (value) => {
    if (value === null || value === undefined) return '';
    return value.toFixed(2).replace('.', ',').replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // Tausendertrennzeichen, 2 Nachkommastellen
  };
  
  // Hilfsfunktion zur Formatierung der Retourquote (mit 100 multiplizieren und Prozentzeichen)
  export const formatPercentageMitZeichen = (value) => {
    if (value === null || value === undefined) return '';
    return (value * 100).toFixed(2) + '%'; // 2 Nachkommastellen, Prozentzeichen
  };
  