import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BigTableCard from '../card/BigTableCard';
import axiosInstance from '../../services/axiosInstance';
import dayjs from 'dayjs';

const VerkaufsmengenWarengruppenGesamtPopup = ({ open, onClose, warengruppennummer, warengruppe, datum }) => {
    const [artikelData, setArtikelData] = useState([]);

    useEffect(() => {
        if (open) {
            fetchArtikelData();
        }
    }, [open]);

    const fetchArtikelData = async () => {
        try {
            // Fetch data for current date
            const responseCurrent = await axiosInstance.get('/scorecard_month_article/produkte-artikel-gesamt/', {
                params: {
                    jahrMonat: datum,
                    warengruppennummer: warengruppennummer,
                },
            });
            console.log('Current Data Response:', responseCurrent.data);
    
            // Fetch data for previous year's same month
            const previousYearDate = dayjs(datum, 'YYYY.MM').subtract(1, 'year').format('YYYY.MM');
    
            const responsePrevious = await axiosInstance.get('/scorecard_month_article/produkte-artikel-gesamt/', {
                params: {
                    jahrMonat: previousYearDate,
                    warengruppennummer: warengruppennummer,
                },
            });
            console.log('Previous Year Data Response:', responsePrevious.data);
    
            // Process current data
            const currentData = responseCurrent.data.results;
    
            // Process previous year data
            const previousData = responsePrevious.data.results;
    
            // Aggregate previous year's data
            const previousDataMap = {};
            previousData.forEach(item => {
                const artikelnummer = item.artikelnummer.artikelnummer; // Access the nested property
                const verkaufsmenge = item.verkaufsmenge || 0;
    
                if (previousDataMap[artikelnummer]) {
                    previousDataMap[artikelnummer] += verkaufsmenge;
                } else {
                    previousDataMap[artikelnummer] = verkaufsmenge;
                }
            });
    
            // Aggregate current data
            const aggregatedDataMap = {};
            currentData.forEach(item => {
                const artikelnummer = item.artikelnummer.artikelnummer; // Access the nested property
                const artikelbezeichnung = item.artikelnummer.artikelbezeichnung; // Access the nested property
                const verkaufsmenge = item.verkaufsmenge || 0;
    
                if (aggregatedDataMap[artikelnummer]) {
                    aggregatedDataMap[artikelnummer].verkaufsmenge += verkaufsmenge;
                } else {
                    aggregatedDataMap[artikelnummer] = {
                        id: artikelnummer, // Use the unique article number as the id
                        artikelnummer,
                        artikelbezeichnung,
                        verkaufsmenge,
                    };
                }
            });
    
            // Combine current and previous year data
            const finalData = Object.values(aggregatedDataMap).map(entry => {
                const artikelnummer = entry.artikelnummer;
                const verkaufsmengeVJ = previousDataMap[artikelnummer] || 0;
                const steigerungProzent = verkaufsmengeVJ > 0
                    ? ((entry.verkaufsmenge - verkaufsmengeVJ) / verkaufsmengeVJ) * 100
                    : 0;
    
                return {
                    ...entry,
                    verkaufsmengeVJ,
                    steigerungProzent: steigerungProzent.toFixed(2),
                };
            });
    
            console.log('Final Article Data:', finalData);
    
            setArtikelData(finalData);
    
        } catch (error) {
            console.error('Error fetching article data:', error);
        }
    };
    
    const columns = [
        { accessorKey: 'artikelnummer', header: 'Artikelnummer' },
        { accessorKey: 'artikelbezeichnung', header: 'Artikelbezeichnung' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ' },
        { accessorKey: 'steigerungProzent', header: 'Steigerung (%)' },
    ];
    
    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Verkaufsmengen für Warengruppe: {warengruppe}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ mt: 2 }}>
                    <BigTableCard
                        columns={columns}
                        data={artikelData}
                        onRowClick={(row) => console.log('Clicked row:', row)}
                    />
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default VerkaufsmengenWarengruppenGesamtPopup;
