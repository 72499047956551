import React from 'react';
import { Typography, Box, Grid } from '@mui/material';

const PageHeader = ({ title, subtitle, Icon }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={2} alignItems="center">
                <Grid item xs={12}>
                    <Box display="flex" alignItems="center">
                        {Icon && (
                            <Icon fontSize="large" sx={{ color: 'primary.main', mr: 1 }} /> // Abstand zwischen Icon und Titel
                        )}
                        <Typography variant="h1" sx={{ mb: 0, lineHeight: 1 }}>
                            {title}
                        </Typography>
                    </Box>
                </Grid>
                {subtitle && (
                    <Grid item xs={8}>
                        <Typography variant="subtitle1" sx={{ mt: '-5px' }}>
                            {subtitle}
                        </Typography>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default PageHeader;
