import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import {
  ExpandLess,
  ExpandMore,
  Close as CloseIcon,
  Fullscreen as FullscreenIcon,
  Download as DownloadIcon,
  PictureAsPdf as PictureAsPdfIcon,
  GridOn as GridOnIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import axiosInstance from '../../../services/axiosInstance';

export default function SalesRegionPopup({ open, onClose, warengruppe, jahrMonat }) {
    const [salesData, setSalesData] = useState([]);
    const [expanded, setExpanded] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    const isMenuOpen = Boolean(anchorEl);

    useEffect(() => {
        if (open) {
            fetchSalesData();
        }
    }, [open]);

    const fetchSalesData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
                params: {
                    warengruppe,
                    jahrMonat,
                },
            });
    
            const previousYear = (parseInt(jahrMonat.split('.')[0]) - 1).toString() + '.' + jahrMonat.split('.')[1];
            const previousYearData = await axiosInstance.get('/scorecards_month/ware-warengruppe-monat/', {
                params: {
                    warengruppe,
                    jahrMonat: previousYear,
                },
            });
    
            const salesMap = {};
    
            response.data.results.forEach(item => {
                const { verkaufsgebiet, filiale, verkaufsmenge = 0 } = item;
                if (!salesMap[verkaufsgebiet]) {
                    salesMap[verkaufsgebiet] = {};
                }
                if (!salesMap[verkaufsgebiet][filiale]) {
                    salesMap[verkaufsgebiet][filiale] = { verkaufsmenge: 0, verkaufsmengeVJ: 0 };
                }
                salesMap[verkaufsgebiet][filiale].verkaufsmenge += verkaufsmenge;
            });
    
            previousYearData.data.results.forEach(item => {
                const { verkaufsgebiet, filiale, verkaufsmenge = 0 } = item;
                if (!salesMap[verkaufsgebiet]) {
                    salesMap[verkaufsgebiet] = {};
                }
                if (!salesMap[verkaufsgebiet][filiale]) {
                    salesMap[verkaufsgebiet][filiale] = { verkaufsmenge: 0, verkaufsmengeVJ: 0 };
                }
                salesMap[verkaufsgebiet][filiale].verkaufsmengeVJ += verkaufsmenge;
            });
    
            const formattedData = Object.entries(salesMap).map(([verkaufsgebiet, filials]) => {
                const totalVerkaufsmenge = Object.values(filials).reduce((acc, curr) => acc + curr.verkaufsmenge, 0);
                const totalVerkaufsmengeVJ = Object.values(filials).reduce((acc, curr) => acc + curr.verkaufsmengeVJ, 0);
                const differenz = totalVerkaufsmenge - totalVerkaufsmengeVJ;
                const vjPercent = totalVerkaufsmengeVJ ? ((differenz / totalVerkaufsmengeVJ) * 100).toFixed(2) + '%' : 'N/A';
    
                // Filtern der Filialen mit Verkaufsmenge und VerkaufsmengeVJ > 0
                const filteredFilials = Object.entries(filials)
                    .filter(([, data]) => data.verkaufsmenge > 0 || data.verkaufsmengeVJ > 0)
                    .map(([filiale, data]) => ({
                        filiale,
                        verkaufsmenge: data.verkaufsmenge,
                        verkaufsmengeVJ: data.verkaufsmengeVJ,
                        differenz: data.verkaufsmenge - data.verkaufsmengeVJ,
                        vjPercent: data.verkaufsmengeVJ ? ((data.verkaufsmenge - data.verkaufsmengeVJ) / data.verkaufsmengeVJ * 100).toFixed(2) + '%' : 'N/A',
                    }));
    
                // Verkaufsgebiete nur aufnehmen, wenn sie Filialen mit Verkaufsmenge oder VerkaufsmengeVJ > 0 enthalten
                if (filteredFilials.length > 0 || totalVerkaufsmenge > 0 || totalVerkaufsmengeVJ > 0) {
                    return {
                        verkaufsgebiet,
                        verkaufsmenge: totalVerkaufsmenge,
                        verkaufsmengeVJ: totalVerkaufsmengeVJ,
                        differenz,
                        vjPercent,
                        filials: filteredFilials,
                    };
                }
                return null; // Wenn das Verkaufsgebiet keine relevanten Filialen hat, wird es herausgefiltert
            }).filter(region => region !== null); // Entferne null-Einträge
    
            setSalesData(formattedData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Verkaufsdaten:', error);
        }
    };
    

    const filteredData = useMemo(() => {
        if (!searchTerm) return salesData;
        return salesData.filter((region) =>
            region.verkaufsgebiet.toLowerCase().includes(searchTerm.toLowerCase()) ||
            region.filials.some((filiale) =>
                filiale.filiale.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [salesData, searchTerm]);

    const handleExpandClick = (verkaufsgebiet) => {
        setExpanded((prevExpanded) => ({
            ...prevExpanded,
            [verkaufsgebiet]: !prevExpanded[verkaufsgebiet],
        }));
    };

    const toggleFullscreen = () => setIsFullscreen((prev) => !prev);

    const handleMenuOpen = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const handleExportCSV = () => {
        const csvContent = [
            ["Verkaufsgebiet", "Filiale", "Verkaufsmenge", "Verkaufsmenge VJ", "Differenz", "VJ %"],
            ...salesData.flatMap((region) => [
                [region.verkaufsgebiet, "", region.verkaufsmenge, region.verkaufsmengeVJ, region.differenz, region.vjPercent],
                ...region.filials.map((filiale) => [
                    "", filiale.filiale, filiale.verkaufsmenge, filiale.verkaufsmengeVJ, filiale.differenz, filiale.vjPercent
                ])
            ])
        ].map(row => row.join(",")).join("\n");

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'table_data.csv');
    };

    const handleExportPDF = () => {
        const doc = new jsPDF();
        const tableColumn = ["Verkaufsgebiet", "Filiale", "Verkaufsmenge", "Verkaufsmenge VJ", "Differenz", "VJ %"];
        const tableRows = salesData.flatMap(region => [
            [region.verkaufsgebiet, "", region.verkaufsmenge, region.verkaufsmengeVJ, region.differenz, region.vjPercent],
            ...region.filials.map(filiale => [
                "", filiale.filiale, filiale.verkaufsmenge, filiale.verkaufsmengeVJ, filiale.differenz, filiale.vjPercent
            ])
        ]);

        doc.autoTable({
            head: [tableColumn],
            body: tableRows,
            styles: { fontSize: 8 },
            headStyles: { fillColor: [22, 160, 133] },
        });

        doc.save('table_data.pdf');
    };

    const handleExportExcel = () => {
        const worksheetData = salesData.flatMap(region => [
            { Verkaufsgebiet: region.verkaufsgebiet, Filiale: "", Verkaufsmenge: region.verkaufsmenge, VerkaufsmengeVJ: region.verkaufsmengeVJ, Differenz: region.differenz, "VJ %": region.vjPercent },
            ...region.filials.map(filiale => ({
                Verkaufsgebiet: "", Filiale: filiale.filiale, Verkaufsmenge: filiale.verkaufsmenge, VerkaufsmengeVJ: filiale.verkaufsmengeVJ, Differenz: filiale.differenz, "VJ %": filiale.vjPercent
            }))
        ]);

        const worksheet = XLSX.utils.json_to_sheet(worksheetData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Daten');
        XLSX.writeFile(workbook, 'tabelle_daten.xlsx');
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth={isFullscreen ? false : 'lg'} fullScreen={isFullscreen}>
            <DialogTitle>
                Verkaufsgebiet Details - {warengruppe}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <Box display="flex" justifyContent="space-between" mb={2}>
                    <TextField
                        label="Suchen"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        size="small"
                    />
                    <Box>
                        <IconButton onClick={handleMenuOpen}>
                            <MoreVertIcon />
                        </IconButton>
                        <Menu anchorEl={anchorEl} open={isMenuOpen} onClose={handleMenuClose}>
                            <MenuItem onClick={() => { handleExportCSV(); handleMenuClose(); }}>
                                <DownloadIcon sx={{ marginRight: 1 }} /> CSV exportieren
                            </MenuItem>
                            <MenuItem onClick={() => { handleExportPDF(); handleMenuClose(); }}>
                                <PictureAsPdfIcon sx={{ marginRight: 1 }} /> PDF exportieren
                            </MenuItem>
                            <MenuItem onClick={() => { handleExportExcel(); handleMenuClose(); }}>
                                <GridOnIcon sx={{ marginRight: 1 }} /> Excel exportieren
                            </MenuItem>
                        </Menu>
                        <IconButton onClick={toggleFullscreen}>
                            <FullscreenIcon />
                        </IconButton>
                    </Box>
                </Box>

                <div className="db_styled_table">
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Verkaufsgebiet</TableCell>
                                    <TableCell>Filiale</TableCell>
                                    <TableCell align="right">Verkaufsmenge</TableCell>
                                    <TableCell align="right">Verkaufsmenge VJ</TableCell>
                                    <TableCell align="right">Differenz</TableCell>
                                    <TableCell align="right">VJ %</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.map((region, regionIndex) => (
                                    <React.Fragment key={regionIndex}>
                                        <TableRow>
                                            <TableCell>
                                                <Box display="flex" alignItems="center">
                                                    <IconButton onClick={() => handleExpandClick(region.verkaufsgebiet)} size="small">
                                                        {expanded[region.verkaufsgebiet] ? <ExpandLess /> : <ExpandMore />}
                                                    </IconButton>
                                                    <Typography variant="subtitle1" sx={{ fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal' }}>
                                                        {region.verkaufsgebiet}
                                                    </Typography>
                                                </Box>
                                            </TableCell>
                                            <TableCell />
                                            <TableCell align="right" sx={{ fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal' }}>{region.verkaufsmenge.toLocaleString('de-DE')}</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal' }}>{region.verkaufsmengeVJ.toLocaleString('de-DE')}</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal' }}>{region.differenz.toLocaleString('de-DE')}</TableCell>
                                            <TableCell align="right" sx={{ fontWeight: expanded[region.verkaufsgebiet] ? 'bold' : 'normal' }}>{region.vjPercent}</TableCell>
                                        </TableRow>

                                        {expanded[region.verkaufsgebiet] && region.filials.map((filiale, filialeIndex) => (
                                            <TableRow key={filialeIndex}>
                                                <TableCell />
                                                <TableCell>{filiale.filiale}</TableCell>
                                                <TableCell align="right">{filiale.verkaufsmenge.toLocaleString('de-DE')}</TableCell>
                                                <TableCell align="right">{filiale.verkaufsmengeVJ.toLocaleString('de-DE')}</TableCell>
                                                <TableCell align="right">{filiale.differenz.toLocaleString('de-DE')}</TableCell>
                                                <TableCell align="right">{filiale.vjPercent}</TableCell>
                                            </TableRow>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </DialogContent>
        </Dialog>
    );
}
