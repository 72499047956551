import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { tausenderTrennung } from '../../utils/mathStuff';

const LineChartStunden = ({ data, displayOption }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  // Dynamische Anpassung der Fontgrößen basierend auf Bildschirmgröße
  const getFontSize = () => {
    if (isSmallScreen) {
      return 8;
    } else if (isMediumScreen) {
      return 10;
    } else {
      return 14;
    }
  };

  const formatNumberWithCommas = (number) => {
    return tausenderTrennung(number, displayOption === 'umsatz' ? 2 : 0);
  };

  return (
    <ResponsiveContainer width="100%" aspect={isSmallScreen ? 1 : 3}>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="uhrzeit"
          tick={{ fontSize: getFontSize() }}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          tick={{ fontSize: getFontSize() }}
          tickFormatter={formatNumberWithCommas}
          width={isSmallScreen ? 40 : 60}
        />
        <Tooltip
          contentStyle={{
            fontSize: getFontSize(),
          }}
          formatter={(value) => displayOption === 'umsatz' ? `${formatNumberWithCommas(value)} €` : formatNumberWithCommas(value)}
          labelFormatter={(label) => `Uhrzeit: ${label}`}
        />
        <Legend
          wrapperStyle={{
            fontSize: getFontSize(),
          }}
          iconType="circle"
          iconSize={getFontSize()}
        />
        <Line
          type="monotone"
          dataKey="average"
          stroke={theme.palette.primary.main}
          connectNulls
          activeDot={{ r: 8 }}
          name={displayOption === 'umsatz' ? 'Durchschnittlicher Umsatz' : 'Durchschnittliche Kundenanzahl'}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartStunden;
