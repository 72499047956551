import React from 'react';
import { Box, ListItem, ListItemText, IconButton, Collapse } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import TopicList from './TopicList';
import AddIcon from '@mui/icons-material/Add';

const CategoryItem = ({
    category,
    selectedCategory,
    setSelectedCategory,
    topics,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteCategory,
    handleEditCategory,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddTopicPlaceholderClick,
    handleAddStepPlaceholderClick,
}) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2 }}>
        <ListItem
            secondaryAction={
                <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            selected={selectedCategory === category.id}
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <TopicList
                topics={topics}
                steps={steps}
                openTopics={openTopics}
                handleToggleTopic={handleToggleTopic}
                handleDeleteTopic={handleDeleteTopic}
                handleEditTopic={handleEditTopic}
                handleDeleteStep={handleDeleteStep}
                handleEditStep={handleEditStep}
                handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
            />
            <Box
                sx={{
                    border: '2px dashed #ddd',
                    borderRadius: '8px',
                    padding: '16px',
                    textAlign: 'center',
                    cursor: 'pointer',
                    color: '#888',
                    mt: 2,
                    width: '100%',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    boxSizing: 'border-box',
                }}
                onClick={() => handleAddTopicPlaceholderClick(category.id)}
            >
                <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                Neues Thema hinzufügen
            </Box>
        </Collapse>
    </Box>
);

export default CategoryItem;
