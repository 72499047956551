import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import VariableTable from '../table/VariableTable';
import axiosInstance from '../../services/axiosInstance';
import { roundToDecimals } from "../../utils/mathStuff";
import CloseIcon from '@mui/icons-material/Close';

const TagesreportFilialeArtikelDetailPopup = ({ open, handleClose, filiale, warengruppe, datum }) => {
    const [artikelData, setArtikelData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && filiale && warengruppe && datum) {
            fetchArtikelData(); // Filter nach Filiale und Warengruppe
        }
    }, [open, filiale, warengruppe, datum]);

    const fetchArtikelData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    filiale: filiale,
                    warengruppenbezeichnung: warengruppe,
                    startdatum: datum,
                    enddatum: datum,
                }
            });

            if (Array.isArray(response.data.results)) {
                const mappedArtikelData = response.data.results
                .filter(item => item.retourmenge > 0)
                .map(item => {
                    const lieferwertTotal = item.lieferwert + item.korrekturwert;
                    const retourquote = lieferwertTotal > 0 ? (item.retourwert / lieferwertTotal) * 100 : 0;
                    return {
                        Artikel: item.artikel,
                        Retourmenge: item.retourmenge || 0,
                        Retourwert: `${roundToDecimals(item.retourwert || 0, 2)} €`,
                        Retourquote: `${retourquote.toFixed(2)} %`,
                    };
                });

                setArtikelData(mappedArtikelData);
            }
        } catch (error) {
            console.error('Error fetching Artikel data:', error);
        } finally {
            setLoading(false);
        }
    };

    const artikelColumns = [
        { accessorKey: 'Artikel', header: 'Artikel', size: 200 },
        { accessorKey: 'Retourmenge', header: 'Retourmenge', size: 150 },
        { accessorKey: 'Retourwert', header: 'Retourwert (€)', size: 150 },
        { accessorKey: 'Retourquote', header: 'Retourquote (%)', size: 150 },
    ];

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Details für Filiale: {filiale} und Warengruppe: {warengruppe}
            <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <VariableTable
                    columns={artikelColumns}
                    data={artikelData}
                    loading={loading}
                />
            </DialogContent>
            
        </Dialog>
    );
};

export default TagesreportFilialeArtikelDetailPopup;
