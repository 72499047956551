import React, { useEffect, useState } from 'react';
import axios from '../../../services/axiosInstance';  // Axios für API-Aufrufe
import { Box, Grid, Typography } from "@mui/material";
import Layout from '../../../components/layout/recruiting/recruiting'; // Passe den Pfad entsprechend an
import BigTableCard from '../../../components/card/BigTableCard'; // Passe den Pfad für BigTableCard an

const Overview = () => {
  const [loading, setLoading] = useState(false);
  const [filialeMonthData, setFilialeMonthData] = useState([]);
  const [filialeWeekData, setFilialeWeekData] = useState([]);
  const [warengruppeMonthData, setWarengruppeMonthData] = useState([]);
  const [warengruppeWeekData, setWarengruppeWeekData] = useState([]);
  const [artikelgruppeMonthData, setArtikelgruppeMonthData] = useState([]);
  const [artikelgruppeWeekData, setArtikelgruppeWeekData] = useState([]);

  const columns = [
    { header: 'Name', accessorKey: 'name' },
    { header: 'Monat/Woche', accessorKey: 'date' },  // Dynamische Anzeige von Woche oder Monat
    { header: 'Verkaufsmenge', accessorKey: 'total_verkaufsmenge' },
    { header: 'Liefermenge', accessorKey: 'total_liefermenge' },
    { header: 'Retourmenge', accessorKey: 'total_retourmenge' },
    { header: 'Korrekturmenge', accessorKey: 'total_korrekturmenge' }
  ];

  // Funktion zur Transformation der Daten für die Tabelle
  const transformData = (data, type) => {
    return data.map(item => ({
      name: type === 'filiale' ? item.filialnummer__filialnummer :
            type === 'warengruppe' ? item.warengruppennummer__warengruppe :
            item.artikelgruppennummer,  // Falls Artikelgruppe
      date: item.month ? new Date(item.month).toLocaleDateString('de-DE', { year: 'numeric', month: 'long' }) :
            new Date(item.week).toLocaleDateString('de-DE', { year: 'numeric', week: 'numeric' }),
      total_verkaufsmenge: item.total_verkaufsmenge,
      total_liefermenge: item.total_liefermenge,
      total_retourmenge: item.total_retourmenge,
      total_korrekturmenge: item.total_korrekturmenge
    }));
  };

  // Funktion zum Abrufen der aggregierten Daten
  const fetchAggregatedData = async () => {
    setLoading(true);
    try {
      const responses = await Promise.all([
        axios.get('/fact/aggregated/by-month-filiale/'),
        axios.get('/fact/aggregated/by-week-filiale/'),
        axios.get('/fact/aggregated/by-month-warengruppe/'),
        axios.get('/fact/aggregated/by-week-warengruppe/'),
        axios.get('/fact/aggregated/by-month-artikelgruppe/'),
        axios.get('/fact/aggregated/by-week-artikelgruppe/')
      ]);

      // Transformation der Daten für jede Tabelle
      setFilialeMonthData(transformData(responses[0].data, 'filiale'));
      setFilialeWeekData(transformData(responses[1].data, 'filiale'));
      setWarengruppeMonthData(transformData(responses[2].data, 'warengruppe'));
      setWarengruppeWeekData(transformData(responses[3].data, 'warengruppe'));
      setArtikelgruppeMonthData(transformData(responses[4].data, 'artikelgruppe'));
      setArtikelgruppeWeekData(transformData(responses[5].data, 'artikelgruppe'));
    } catch (error) {
      console.error('Fehler beim Abrufen der aggregierten Daten:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAggregatedData();
  }, []);

  return (
    <Layout>
      <Box sx={{ mt: 4 }}>
        <Typography variant="h4">Aggregierte Daten</Typography>

        {loading ? (
          <Typography variant="h6">Daten werden geladen...</Typography>
        ) : (
          <Grid container spacing={2}>
            {/* Filiale Monat */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Filiale (Monat)" 
                data={filialeMonthData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>

            {/* Filiale Woche */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Filiale (Woche)" 
                data={filialeWeekData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>

            {/* Warengruppe Monat */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Warengruppe (Monat)" 
                data={warengruppeMonthData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>

            {/* Warengruppe Woche */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Warengruppe (Woche)" 
                data={warengruppeWeekData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>

            {/* Artikelgruppe Monat */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Artikelgruppe (Monat)" 
                data={artikelgruppeMonthData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>

            {/* Artikelgruppe Woche */}
            <Grid item xs={12}>
              <BigTableCard 
                title="Aggregierte Daten nach Artikelgruppe (Woche)" 
                data={artikelgruppeWeekData} 
                columns={columns} 
                onRowClick={(rowData) => console.log('Row clicked:', rowData)} 
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </Layout>
  );
};

export default Overview;
