import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent, Tooltip, List, ListItem, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/InfoOutlined';  // Importiere das InfoOutlined-Icon
import axiosInstance from "../../../services/axiosInstance";
import dayjs from "dayjs";
import { FilterContext } from "../../../provider/FilterProvider";
import PieChartFluktuation from "../../charts/PieChartFluktuation";
import GroupedBarChart from "../../charts/GroupedBarChartFluktuation";

const TAPersonalFluktuation = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [entranceExitData, setEntranceExitData] = useState([]);
    const [entryTableData, setEntryTableData] = useState([]);
    const [exitTableData, setExitTableData] = useState([]);
    const [totalEntries, setTotalEntries] = useState(0);
    const [totalExits, setTotalExits] = useState(0);

    useEffect(() => {
        if (open && filterState.filiale && filterState.datum) {
            fetchEntranceExitData();
            fetchEmployeeData();
        }
    }, [filterState.filiale, filterState.datum, open]);

    const handleClose = () => {
        setOpen(false);
    };

    const fetchEntranceExitData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(5, 'month').startOf('month').format('YYYY-MM-DD');

            const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
                params: {
                    startdatum: startDate,
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                }
            });

            const chartData = response.data.results.map(item => ({
                month: dayjs(item.datum).format('YYYY-MM'),
                EintritteM: item.eintritteM,
                AustritteM: item.austritteM
            }));

            setEntranceExitData(chartData);

            const totalEntries = chartData.reduce((sum, item) => sum + item.EintritteM, 0);
            const totalExits = chartData.reduce((sum, item) => sum + item.AustritteM, 0);

            setTotalEntries(totalEntries);
            setTotalExits(totalExits);

        } catch (error) {
            console.error('An error occurred while fetching entrance and exit data:', error);
        }
    };

    const fetchEmployeeData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(5, 'month').startOf('month').format('YYYY-MM-DD');

            const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
                params: {
                    filiale: filterState.filiale?.value,
                    startdate: startDate,
                    enddate: endDate
                }
            });

            const entryData = response.data.results
                .filter(item => item.eintritt && dayjs(item.eintritt).isBetween(startDate, endDate, null, '[]'))
                .map(item => ({
                    Name: item.name,
                    Arbeitnehmertyp: item.arbeitnehmertyp,
                    Wochensoll: (item.wochensollBerechnet || 0).toFixed(2),
                    Eintritt: item.eintritt ? dayjs(item.eintritt).format('YYYY-MM-DD') : 'N/A',
                    Austritt: item.austritt ? dayjs(item.austritt).format('YYYY-MM-DD') : 'N/A'
                }));

            const exitData = response.data.results
                .filter(item => item.austritt && dayjs(item.austritt).isBetween(startDate, endDate, null, '[]'))
                .map(item => ({
                    Name: item.name,
                    Arbeitnehmertyp: item.arbeitnehmertyp,
                    Wochensoll: (item.wochensollBerechnet || 0).toFixed(2),
                    Eintritt: item.eintritt ? dayjs(item.eintritt).format('YYYY-MM-DD') : 'N/A',
                    Austritt: item.austritt ? dayjs(item.austritt).format('YYYY-MM-DD') : 'N/A'
                }));

            setEntryTableData(entryData);
            setExitTableData(exitData);

        } catch (error) {
            console.error('An error occurred while fetching employee data:', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Eintritte und Austritte
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" style={{ flexGrow: 1 }}>Verteilung Eintritte und Austritte</Typography>
                                        <Tooltip title="Verlauf der Eintritte und Austritte in den letzten 6 Monaten.">
                                            <IconButton>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            />
                            <CardContent>
                                <GroupedBarChart data={entranceExitData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardHeader
                                title={
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="h6" style={{ flexGrow: 1 }}>Gesamte Eintritte und Austritte</Typography>
                                        <Tooltip title="Hier sehen Sie die Anzahl und Verteilung der Eintritte und Austritte in den letzten 6 Monaten.">
                                            <IconButton>
                                                <InfoIcon />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                }
                            />
                            <CardContent>
                                <PieChartFluktuation
                                    data={[
                                        { Typ: 'Eintritte', ZuAbgang: totalEntries },
                                        { Typ: 'Austritte', ZuAbgang: totalExits },
                                    ]}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title={<Typography variant="h6">Mitarbeiter mit Eintritt im Zeitraum</Typography>}
                                    />
                                    <CardContent>
                                        <List>
                                            {entryTableData.map((item, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={item.Name}
                                                        secondary={`Typ: ${item.Arbeitnehmertyp}, Wochensoll: ${item.Wochensoll}, Eintritt: ${item.Eintritt}`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Card>
                                    <CardHeader
                                        title={<Typography variant="h6">Mitarbeiter mit Austritt im Zeitraum</Typography>}
                                    />
                                    <CardContent>
                                        <List>
                                            {exitTableData.map((item, index) => (
                                                <ListItem key={index}>
                                                    <ListItemText
                                                        primary={item.Name}
                                                        secondary={`Typ: ${item.Arbeitnehmertyp}, Wochensoll: ${item.Wochensoll}, Austritt: ${item.Austritt}`}
                                                    />
                                                </ListItem>
                                            ))}
                                        </List>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPersonalFluktuation;
