import Snackbar from "@mui/material/Snackbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import React, {useState} from "react";

const InfoBar = ({ open, severity, message, onClose }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={6000}
            onClose={onClose}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            message={
                <span style={{display: 'flex', alignItems: 'center'}}>
                    {severity === 'success' ? (
                        <CheckCircleIcon style={{color: 'green', marginRight: 8}}/>
                    ) : (
                        <ErrorIcon style={{color: 'red', marginRight: 8}}/>
                    )}
                    {message}
                </span>
            }
        />
    );
};

export const InfoBarContext = React.createContext();

export const InfoBarProvider = ({ children }) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severitySnackbar, setSeveritySnackbar] = useState('success');
    const [messageSnackbar, setMessageSnackbar] = useState('');

    const handleClose = () => {
        setOpenSnackbar(false);
    };

    const showError = (message) => {
        setSeveritySnackbar('error');
        setOpenSnackbar(true);
        setMessageSnackbar(message);
    };

    const showSuccess = (message) => {
        setSeveritySnackbar('success');
        setOpenSnackbar(true);
        setMessageSnackbar(message);
    };

    return (
        <InfoBarContext.Provider value={{ showError, showSuccess }}>
            {children}
            <InfoBar 
                open={openSnackbar}
                severity={severitySnackbar}
                message={messageSnackbar}
                onClose={handleClose}
            />
        </InfoBarContext.Provider>
    );
};