// Sidebar.js
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import WindowIcon from '@mui/icons-material/Window';
import BarChartIcon from '@mui/icons-material/BarChart';
import TimelineIcon from '@mui/icons-material/Timeline';
import GroupIcon from '@mui/icons-material/Group';
import BakeryDiningIcon from '@mui/icons-material/BakeryDining';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Euro from '@mui/icons-material/Euro';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import ShoppingCart from '@mui/icons-material/ShoppingCart';
import AddShoppingCart from '@mui/icons-material/AddShoppingCart';
import Timer from '@mui/icons-material/Timer';
import Vaccines from '@mui/icons-material/Vaccines';
import GroupRemove from '@mui/icons-material/GroupRemove';
import ManageSearch from '@mui/icons-material/ManageSearch';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import AutoGraph from '@mui/icons-material/AutoGraph';
import HighlightOff from '@mui/icons-material/HighlightOff';
import PublishedWithChanges from '@mui/icons-material/PublishedWithChanges';
import CurrencyExchange from '@mui/icons-material/CurrencyExchange';
import AppsIcon from '@mui/icons-material/Apps';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Button from '@mui/material/Button';
import { ReactComponent as DatabitesLogo } from '../../../assets/images/logo_dashboard.svg';
import { ReactComponent as DatabitesLogoSmall } from '../../../assets/images/logo_dashboard_small.svg';
import DropdownFilter from '../../filter/DropdownFilter';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import PersonAddAlt1OutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import AbcOutlinedIcon from '@mui/icons-material/AbcOutlined';
import UpdateIcon from '@mui/icons-material/Update';


const drawerWidth = 275;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const PermanentDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

const Sidebar = ({ open, toggleDrawer, variant }) => {
  const { filterState, handleFilialeChange, handleDatumChange } = useContext(FilterContext);
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [datumOptions, setDatumOptions] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    fetchOptions();
  }, []);

  const fetchOptions = async () => {
    try {
      const [filialeResponse, datumResponse] = await Promise.all([
        axiosInstance.get('stammdaten/unique-filiale/'),
        axiosInstance.get('stammdaten/unique-jahr-monat/'),
      ]);

      const filialeOpts = filialeResponse.data.map((item) => ({
        label: item.filiale,
        value: item.filiale,
      }));
      const datumOpts = datumResponse.data.map((item) => ({ label: item, value: item }));

      setFilialeOptions(filialeOpts);
      setDatumOptions(datumOpts);

      if (!filterState.filiale && filialeOpts.length > 0) {
        handleFilialeChange(filialeOpts[0]);
      }
      if (!filterState.datum && datumOpts.length > 0) {
        handleDatumChange(datumOpts[0]);
      }
    } catch (error) {
      console.error('Fehler beim Abrufen der Optionen:', error);
    }
  };

  const handleClick = (section) => {
    setOpenSections((prev) => ({ ...prev, [section]: !prev[section] }));
  };

  const isMenuItemActive = (path) => location.pathname === path;
  const isSectionActive = (subItems) => subItems.some((item) => location.pathname === item.path);

  const menuItems = [
    {
      text: 'Kunden und Umsatz',
      path: '/frequenzbericht/overview/index',
      icon: <ViewTimelineOutlinedIcon sx={{ color: 'primary.main' }} />,
      subItems: [],
    },
    {
      text: 'Artikelfrequenz',
      path: '/frequenzbericht/overview/artikel',
      icon: <UpdateIcon sx={{ color: 'primary.main' }} />,
      subItems: [],
    },


  ];

// Sidebar-Inhalt
const SidebarContent = (
  <Box
    className="db_sidbar_head"
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      overflowY: 'auto',
      paddingBottom: isMobile ? theme.spacing(10) : 0, // Padding nur bei kleinen Bildschirmen hinzufügen

    }}
  >
    <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start', // Zentriert das Logo auf mobilen Geräten
    }}
  >
    {open ? (
      <DatabitesLogo
        className="db_logo"
        style={{ width: isMobile ? '120px' : '200px', height: 'auto' }} // Logo-Größe für kleine Bildschirme im offenen Zustand anpassen
      />
    ) : (
      <DatabitesLogoSmall
        className="db_logo db_logo_small"
        style={{ width: '40px', height: 'auto' }} // Kleines Logo im geschlossenen Zustand anzeigen
      />
    )}
    <Typography
      variant="h4"
      className="db_sidebar_headline"
      sx={{ display: open ? 'block' : 'none', textAlign: isMobile ? 'center' : 'left' }} // Zentriere den Text auf mobilen Geräten
    >
      Frequenzberichte
    </Typography>
    <br />
    {/* {open && (
      <>
        <DropdownFilter label="Filiale" options={filialeOptions} value={filterState.filiale} onChange={handleFilialeChange} />
        <DropdownFilter label="Datum" options={datumOptions} value={filterState.datum} onChange={handleDatumChange} />
      </>
    )} */}
  </Box>


    <List className="db_sidbar_menu">
      {menuItems.map((menuItem) => (
        <React.Fragment key={menuItem.text}>
          <ListItem disablePadding sx={{ display: 'block' }} className={'db_sidebar_menu_item' + (isSectionActive(menuItem.subItems) || isMenuItemActive(menuItem.path) ? ' active' : '')}>
            <ListItemButton
              onClick={() => {
                if (!open) {
                  toggleDrawer();
                }
                if (menuItem.subItems.length > 0) {
                  handleClick(menuItem.text);
                } else {
                  navigate(menuItem.path);
                }
              }}
              sx={{ minHeight: 48, justifyContent: open ? 'initial' : 'center', px: 2.5, borderRadius: '8px' }}
            >
              <ListItemIcon sx={{ minWidth: 0, mr: open ? 3 : 'auto', justifyContent: 'center', color: 'primary.main' }}>
                {menuItem.icon}
              </ListItemIcon>
              <ListItemText className="db_sidebar_menu_item_text" primary={menuItem.text} sx={{ opacity: open ? 1 : 0 }} />
              {open && menuItem.subItems.length > 0 && (openSections[menuItem.text] ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>
          </ListItem>
          {open && menuItem.subItems.length > 0 && (
            <Collapse in={openSections[menuItem.text]} timeout="auto" unmountOnExit className="db_sidebar_submenu">
              <List component="div" disablePadding>
                {menuItem.subItems.map((subItem) => (
                  <ListItem key={subItem.text} disablePadding sx={{ display: 'block' }} className={'db_sidebar_submenu_item ' + (isMenuItemActive(subItem.path) ? 'active' : '')}>
                    <ListItemButton onClick={() => navigate(subItem.path)} sx={{ minHeight: 36, justifyContent: open ? 'initial' : 'center', pl: 7, borderRadius: '8px' }}>
                      <ListItemIcon sx={{ minWidth: 0, mr: 3, justifyContent: 'center', color: 'primary.main' }}>
                        {subItem.icon}
                      </ListItemIcon>
                      <ListItemText className="db_sidebar_submenu_item_text" primary={subItem.text} sx={{ opacity: open ? 1 : 0 }} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Collapse>
          )}
        </React.Fragment>
      ))}
    </List>
    <Box
  className="db_sidbar_foot"
  sx={{
    display: 'flex',
    flexDirection: open ? 'row' : 'column', // Wechselt zwischen Zeilen- und Spaltenrichtung basierend auf 'open'
    alignItems: 'center', // Zentriere die Icons horizontal
    marginTop: 'auto', // Schiebe den Footer-Bereich nach unten
    padding: theme.spacing(2),
    gap: theme.spacing(2), // Abstand zwischen den Icons
    justifyContent: open ? 'space-between' : 'center', // Für gleichmäßigen Abstand im aufgeklappten Zustand
  }}
>
  {open ? (
    <Button
      className="db_all_apps_button"
      variant="outlined"
      startIcon={<AppsIcon sx={{ color: 'primary.main' }} />}
      onClick={() => navigate('/')}
    >
      <Typography sx={{ opacity: open ? 1 : 0 }}>Data Apps</Typography>
    </Button>
  ) : (
    <IconButton onClick={() => navigate('/')}>
      <AppsIcon sx={{ color: 'primary.main' }} />
    </IconButton>
  )}
  <IconButton onClick={toggleDrawer}>
    {open ? (
      <ChevronLeftIcon sx={{ color: 'primary.main' }} />
    ) : (
      <ChevronRightIcon sx={{ color: 'primary.main' }} />
    )}
  </IconButton>
</Box>



  </Box>
);

return variant === 'temporary' ? (
  <MuiDrawer
    variant="temporary"
    open={open}
    onClose={toggleDrawer}
    className="db_sidebar"
    ModalProps={{
      keepMounted: true,
    }}
    PaperProps={{
      style: { width: drawerWidth, height: '100vh' },
    }}
  >
    {SidebarContent}
  </MuiDrawer>
) : (
  <PermanentDrawer variant="permanent" open={open} className="db_sidebar">
    {SidebarContent}
  </PermanentDrawer>
);
};

export default Sidebar;
