import React from 'react';
import { Box, useTheme } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parse, isValid } from 'date-fns';
import { useMediaQuery } from '@mui/material';

const RechartsLineChart = ({ data, title, height }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    const dateFormat = 'yyyy.MM';
    // Prepare data for the chart dynamically based on labels
    const processedData = data.reduce((acc, item) => {
        const dateString = item.x; // Original-Datum
        const date = parse(dateString, dateFormat, new Date());
        
        // Überprüfe, ob das Datum gültig ist
        if (!isValid(date)) {
          console.warn(`Invalid date: ${dateString}`);
          return acc; // Skip if the date is invalid
        }

        const xValue = format(date, 'yyyy.MM');
        let entry = acc.find(e => e.x === xValue);
        if (!entry) {
            entry = { x: xValue };
            acc.push(entry);
        }
        
        // Setze 0 oder einen anderen Standardwert, wenn item.y undefined ist
        entry[item.label] = item.y || 0; 
        return acc;
    }, []);

    const uniqueLabels = [...new Set(data.map(item => item.label))];

    // Berechne den minimalen und maximalen Y-Wert aus den Daten
    const allYValues = data.map(item => item.y || 0);
    const minYValue = Math.min(...allYValues);
    const maxYValue = Math.max(...allYValues);

    // Setze ein Padding von 20% für den minimalen und maximalen Wert
    const adjustedMinYValue = minYValue > 0 ? Math.max(0, minYValue - (maxYValue - minYValue) * 0.2) : minYValue - (maxYValue - minYValue) * 0.2;
    const adjustedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.2;

    const getFontSize = () => {
        if (isSmallScreen) {
            return 8;
        } else if (isMediumScreen) {
            return 10;
        } else {
            return 14;
        }
    };

    // Funktion zum Formatieren der Zahlen mit Tausendertrennzeichen
    const formatNumberWithCommas = (number) => {
        return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <Box>
            <ResponsiveContainer width="100%" aspect={isSmallScreen ? 1 : 3}>
                <LineChart data={processedData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="x"
                        tick={{ fontSize: getFontSize() }}
                        padding={{ left: 10, right: 10 }}
                    />
                    <YAxis
                        tick={{ fontSize: getFontSize() }}
                        width={isSmallScreen ? 40 : 60}
                        tickFormatter={formatNumberWithCommas} // Tausendertrennzeichen für Y-Achse
                        domain={[adjustedMinYValue, adjustedMaxYValue]} // Dynamischer Bereich der Y-Achse mit 20% Puffer
                    />
                    <Tooltip
                        contentStyle={{ fontSize: getFontSize() }}
                        formatter={(value) => formatNumberWithCommas(value)} // Formatierung für Tooltip
                    />
                    <Legend 
                        wrapperStyle={{ fontSize: getFontSize() }}
                        iconType="circle"
                        iconSize={getFontSize()}
                    />
                    {uniqueLabels.map(label => (
                        <Line
                            key={label}
                            type="monotone"
                            dataKey={label}
                            stroke={label === 'Vorjahr' ? theme.palette.chart.pink : theme.palette.primary.main}
                            activeDot={{ r: 8 }}
                        />
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default RechartsLineChart;
