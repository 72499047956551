import React, {useState} from 'react';
import {styled} from '@mui/material/styles';
import Sidebar from '../Documentation/DocumentationSidebar';

const drawerWidth = 275;

const Main = styled('main', {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin-left', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `calc(${theme.spacing(7)} + 1px)`,
        ...(open && {
            transition: theme.transitions.create('margin-left', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: drawerWidth,
        }),
    }),
);

// const AppBarStyled = styled(AppBar, {shouldForwardProp: (prop) => prop !== 'open'})(
//     ({theme, open}) => ({
//         transition: theme.transitions.create(['width', 'margin-left'], {
//             easing: theme.transitions.easing.sharp,
//             duration: theme.transitions.duration.leavingScreen,
//         }),
//         width: `calc(100% - ${theme.spacing(7)} - 1px)`,
//         marginLeft: `calc(${theme.spacing(7)} + 1px)`,
//         ...(open && {
//             transition: theme.transitions.create(['width', 'margin-left'], {
//                 easing: theme.transitions.easing.easeOut,
//                 duration: theme.transitions.duration.enteringScreen,
//             }),
//             width: `calc(100% - ${drawerWidth}px)`,
//             marginLeft: drawerWidth,
//         }),
//     }),
// );

const Layout = ({children}) => {
    const [open, setOpen] = useState(false);
    // const theme = useTheme();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <>
            <Sidebar open={open} toggleDrawer={toggleDrawer}/>
            <Main open={open}>
                {children}
            </Main>
        </>
    );
};

export default Layout;
