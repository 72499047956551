import React, { useState, useMemo } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  TableSortLabel,
  Paper,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

const VariableTable = ({ columns, data, onRowClick }) => {
  const theme = useTheme();

  const [orderBy, setOrderBy] = useState('');
  const [order, setOrder] = useState('asc');
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(75);

  const sortedData = useMemo(() => {
    if (orderBy) {
      return [...data].sort((a, b) => {
        let aValue = a[orderBy];
        let bValue = b[orderBy];

        // Überprüfen, ob die Werte Zahlen (mit Tausendertrennzeichen) sind
        if (typeof aValue === 'string' && typeof bValue === 'string') {
          // Entferne Tausendertrennzeichen und ersetze Komma mit Punkt für Nachkommastellen
          const parsedA = parseFloat(aValue.replace(/\./g, '').replace(',', '.'));
          const parsedB = parseFloat(bValue.replace(/\./g, '').replace(',', '.'));

          // Wenn beide Werte gültige Zahlen sind, sortiere sie als Zahlen
          if (!isNaN(parsedA) && !isNaN(parsedB)) {
            aValue = parsedA;
            bValue = parsedB;
          }
        }

        if (aValue === bValue) return 0;
        if (order === 'asc') {
          return aValue < bValue ? -1 : 1;
        } else {
          return aValue > bValue ? -1 : 1;
        }
      });
    } else {
      return data;
    }
  }, [data, orderBy, order]);

  const paginatedData = useMemo(() => {
    const start = pageIndex * pageSize;
    return sortedData.slice(start, start + pageSize);
  }, [sortedData, pageIndex, pageSize]);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPageIndex(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
    setPageIndex(0);
  };

  const renderTable = () => (
    <>
      <TableContainer component={Paper} sx={{ maxHeight: 500, padding: 0 }}>
        <Table stickyHeader size="small" sx={{ borderCollapse: 'collapse' }}>
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell
                  key={column.accessorKey}
                  sortDirection={orderBy === column.accessorKey ? order : false}
                  sx={{
                    fontSize: {
                      xs: '0.6rem',
                      sm: '0.8rem',
                      md: '1rem',
                    },
                    textAlign: index === 0 ? 'left' : 'right',
                    position: index === 0 ? 'sticky' : 'static',
                    left: index === 0 ? 0 : 'auto',
                    zIndex: index === 0 ? 3 : 1,
                    backgroundColor: 'white',
                    minWidth: index === 0 ? '120px' : 'auto',
                    padding: '8px',
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.accessorKey}
                    direction={orderBy === column.accessorKey ? order : 'asc'}
                    onClick={() => handleRequestSort(column.accessorKey)}
                    sx={{ flexDirection: 'row-reverse' }}
                  >
                    {column.header}
                  </TableSortLabel>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {paginatedData.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                hover={!!onRowClick}
                onClick={onRowClick ? () => onRowClick(row) : undefined}
                sx={{
                  cursor: onRowClick ? 'pointer' : 'default',
                  '&:hover': onRowClick ? {
                    backgroundColor: theme.palette.action.hover,
                  } : {},
                }}
              >
                {columns.map((column, colIndex) => (
                  <TableCell
                    key={column.accessorKey}
                    sx={{
                      fontSize: {
                        xs: '0.6rem',
                        sm: '0.8rem',
                        md: '1rem',
                      },
                      textAlign: colIndex === 0 ? 'left' : 'right',
                      position: colIndex === 0 ? 'sticky' : 'static',
                      left: colIndex === 0 ? 0 : 'auto',
                      zIndex: colIndex === 0 ? 2 : 1,
                      backgroundColor: 'white',
                      minWidth: colIndex === 0 ? '120px' : 'auto',
                      padding: '8px',
                    }}
                  >
                    {row[column.accessorKey]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      {data.length > pageSize && (
        <TablePagination
          component="div"
          count={data.length}
          page={pageIndex}
          onPageChange={handleChangePage}
          rowsPerPage={pageSize}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[25, 50, 75, 100]}
          labelRowsPerPage="Zeilen pro Seite"
          labelDisplayedRows={({ from, to, count }) =>
            `${from}-${to} von ${count !== -1 ? count : `mehr als ${to}`}`
          }
          nextIconButtonText="Nächste Seite"
          backIconButtonText="Vorherige Seite"
        />
      )}
    </>
  );

  return (
    <Box>
      {renderTable()}
    </Box>
  );
};

export default VariableTable;
