import React, { useEffect, useState } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Table,
  TableBody, TableCell, TableHead, TableRow, Button, IconButton, TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from '../../services/axiosInstance';

const TagesplanerBackhinweis = ({ open, onClose, hour, filiale }) => {
  const [articles, setArticles] = useState([]);
  // Speichert die Auswahl und die backMenge pro Artikelindex
  const [selectedDecisions, setSelectedDecisions] = useState({});

  // Funktion zur Abfrage der Filialnummer basierend auf der Bezeichnung
  const getFilialnummer = async (filialbezeichnung) => {
    try {
      console.log('Abfrage der Filialnummer für:', filialbezeichnung);
  
      const response = await axios.get('/stammdaten/stammdatenfiliale/');
      console.log('Antwort von /stammdaten/stammdatenfiliale/:', response.data);
  
      const filialeData = response.data.results.find(
        (filiale) => filiale.filiale === filialbezeichnung
      );
      
      console.log('Gefundene Filialdaten:', filialeData);
      return filialeData ? filialeData.filialnummer : null;
    } catch (error) {
      console.error('Fehler beim Abrufen der Filialnummer:', error);
      return null; // Tippfehler hier entfernt
    }
  };

  // Effekt zum Abrufen der Artikel, wenn das Dialogfenster geöffnet wird oder sich die Props ändern
  useEffect(() => {
    const fetchArticles = async () => {
      if (open) {
        try {
          const today = new Date().toISOString().split('T')[0];
          const response = await axios.get(`/tagesplaner/tagesplaner/?datum=${today}&filialbezeichnung=${filiale}`);
          const filteredArticles = Array.isArray(response.data.results)
            ? response.data.results.filter(article => article.uhrzeit.startsWith(`${hour.toString().padStart(2, '0')}:`))
            : [];
          setArticles(filteredArticles);
        } catch (error) {
          console.error('Error fetching articles:', error);
          setArticles([]);
        }
      }
    };
    fetchArticles();
  }, [open, hour, filiale]);

  // Handler zum Auswählen der Entscheidung (Ja/Nein)
  const handleBakeDecision = (index, decision) => {
    setSelectedDecisions(prev => {
      const newDecisions = { ...prev };
      if (newDecisions[index]?.decision === decision) {
        // Wenn die gleiche Entscheidung erneut ausgewählt wird, entfernen wir sie (Toggle-Off)
        delete newDecisions[index];
      } else {
        if (decision === 'Ja') {
          // Wenn "Ja" ausgewählt wird, initialisieren wir backMenge mit einem leeren String
          newDecisions[index] = { decision: 'Ja', backMenge: '' };
        } else {
          // Wenn "Nein" ausgewählt wird, entfernen wir backMenge
          newDecisions[index] = { decision: 'Nein' };
        }
      }
      return newDecisions;
    });
  };

  // Handler zum Aktualisieren der backMenge
  const handleBackMengeChange = (index, value) => {
    setSelectedDecisions(prev => ({
      ...prev,
      [index]: {
        ...prev[index],
        backMenge: value,
      },
    }));
  };

  // Handler zum Speichern der Entscheidungen und Mengen
  const handleSave = async () => {
    try {
      const today = new Date().toISOString().split('T')[0];
      const filialnummer = await getFilialnummer(filiale); // Hole die Filialnummer basierend auf der Bezeichnung
      
      if (!filialnummer) {
        console.error('Filialnummer nicht gefunden');
        return;
      }
  
      const savePromises = Object.entries(selectedDecisions).map(([index, decisionObj]) => {
        const article = articles[index];
        const postData = {
          filialnummer: filialnummer, // Verwende die erhaltene Filialnummer
          artikelnummer: article.artikelnummer,
          datum: today,
          uhrzeit: `${hour}:00`,
          entscheidung: decisionObj.decision,
          ...(decisionObj.decision === 'Ja' && { backMenge: decisionObj.backMenge }),
        };
  
        console.log('POST to /tagesplaner/backdecision/', postData);
  
        return axios.post('/tagesplaner/backdecision/', postData);
      });
  
      await Promise.all(savePromises);
      console.log('Decisions saved successfully');
      onClose(); // Schließe das Popup nach dem Speichern
    } catch (error) {
      console.error('Error saving decisions:', error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        Artikelübersicht für {filiale} um {hour}:00
        <IconButton onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Filiale</TableCell>
              <TableCell>Uhrzeit</TableCell>
              <TableCell>Artikel</TableCell>
              <TableCell>Verkaufsmenge2h</TableCell>
              <TableCell>Backen?</TableCell>
              <TableCell>Menge</TableCell> {/* Neue Spalte für Menge */}
            </TableRow>
          </TableHead>
          <TableBody>
            {articles.map((article, index) => (
              <TableRow key={index}>
                <TableCell>{filiale}</TableCell>
                <TableCell>{hour}:00</TableCell>
                <TableCell>{article.artikel}</TableCell>
                <TableCell>{article.verkaufsmenge2h}</TableCell>
                <TableCell>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleBakeDecision(index, 'Ja')}
                    sx={{
                      backgroundColor: selectedDecisions[index]?.decision === 'Ja' ? 'green' : 'transparent',
                      color: selectedDecisions[index]?.decision === 'Ja' ? '#fff' : 'inherit',
                      borderColor: selectedDecisions[index]?.decision === 'Ja' ? 'green' : 'rgba(0, 0, 0, 0.23)',
                    }}
                  >
                    Ja
                  </Button>
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={() => handleBakeDecision(index, 'Nein')}
                    sx={{
                      ml: 1,
                      backgroundColor: selectedDecisions[index]?.decision === 'Nein' ? 'red' : 'transparent',
                      color: selectedDecisions[index]?.decision === 'Nein' ? '#fff' : 'inherit',
                      borderColor: selectedDecisions[index]?.decision === 'Nein' ? 'red' : 'rgba(0, 0, 0, 0.23)',
                    }}
                  >
                    Nein
                  </Button>
                </TableCell>
                <TableCell>
                  {selectedDecisions[index]?.decision === 'Ja' && (
                    <TextField
                      label="Menge"
                      variant="outlined"
                      size="small"
                      value={selectedDecisions[index].backMenge}
                      onChange={(e) => handleBackMengeChange(index, e.target.value)}
                      sx={{ mt: 1, maxWidth: '80px' }} // Optional: fügt etwas Abstand nach oben hinzu
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary" variant="contained">
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TagesplanerBackhinweis;
