// Mitarbeiter.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Paper,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Grid,
  Card,
  CardContent,
  LinearProgress,
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance'; // Deine axios-Instanz
import Layout from '../../../components/layout/Onboarding/Onboarding'; // Dein Layout-Wrapper
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Der Header
import ChecklistIcon from '@mui/icons-material/Checklist'; // Icon für den Header
import { FilterContext } from '../../../provider/FilterProvider'; // Importiere den FilterContext

const Mitarbeiter = () => {
  const [employees, setEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [assignments, setAssignments] = useState([]);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [categories, setCategories] = useState([]);
  const [topics, setTopics] = useState([]);
  const [steps, setSteps] = useState({});
  const [topicProgress, setTopicProgress] = useState({}); // Fortschritt für die Themen
  const [stepProgress, setStepProgress] = useState({}); // Fortschritt für die Schritte
  const [planSteps, setPlanSteps] = useState([]); // Alle Schritte des Plans
  const [planTopics, setPlanTopics] = useState([]); // Alle Themen des Plans

  const { filterState, handleEmployeeChange } = useContext(FilterContext); // Verwende den FilterContext
  const selectedEmployee = filterState.selectedEmployee; // Hole den ausgewählten Mitarbeiter

  // **Hier finden wir das Mitarbeiterobjekt des ausgewählten Mitarbeiters**
  const selectedEmployeeData = employees.find(
    (employee) => employee.mitarbeiterId === selectedEmployee
  );

  // Mitarbeiterdaten laden
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get('personaldaten/stammdaten-mitarbeiter/')
      .then((response) => {
        setEmployees(response.data.results);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Mitarbeiter:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // Onboarding-Zuweisungen laden
  useEffect(() => {
    axiosInstance
      .get('/onboarding/employee-assignments/')
      .then((response) => {
        setAssignments(response.data.results);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Onboarding-Zuweisungen:', error);
      });
  }, []);

  // Onboarding-Pläne laden
  useEffect(() => {
    axiosInstance
      .get('/onboarding/onboarding-plans/')
      .then((response) => {
        setOnboardingPlans(response.data.results);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Onboarding-Pläne:', error);
      });
  }, []);

  // Kategorien, Themen, Schritte und Fortschritt laden, wenn ein Mitarbeiter ausgewählt ist
  useEffect(() => {
    if (!selectedEmployee) return;

    const assignment = assignments.find((a) => a.employee === selectedEmployee);
    if (assignment && assignment.onboarding_plan) {
      const planId = assignment.onboarding_plan;

      // Kategorien laden
      axiosInstance
        .get(`/onboarding/categories/`)
        .then((response) => {
          const filteredCategories = response.data.results.filter(
            (category) => category.onboarding_plan === planId
          );
          setCategories(filteredCategories);

          // Themen laden
          axiosInstance
            .get(`/onboarding/topics/`)
            .then((response) => {
              const allTopics = response.data.results;
              const filteredTopics = allTopics.filter((topic) =>
                filteredCategories.some((category) => category.id === topic.category)
              );
              setTopics(filteredTopics);
              setPlanTopics(filteredTopics); // Themen des Plans speichern

              // Schritte laden
              axiosInstance
                .get(`/onboarding/onboarding-steps/`)
                .then((response) => {
                  const allSteps = response.data.results;
                  const filteredSteps = allSteps.filter((step) =>
                    filteredTopics.some((topic) => topic.id === step.topic)
                  );
                  const stepsByTopic = filteredSteps.reduce((acc, step) => {
                    if (!acc[step.topic]) {
                      acc[step.topic] = [];
                    }
                    acc[step.topic].push(step);
                    return acc;
                  }, {});
                  setSteps(stepsByTopic);
                  setPlanSteps(filteredSteps); // Schritte des Plans speichern
                })
                .catch((error) => {
                  console.error('Fehler beim Laden der Schritte:', error);
                });
            })
            .catch((error) => {
              console.error('Fehler beim Laden der Themen:', error);
            });
        })
        .catch((error) => {
          console.error('Fehler beim Laden der Kategorien:', error);
        });

      // Topic Progress laden
      axiosInstance
        .get(`/onboarding/topic-progress/`, {
          params: { employee: selectedEmployee },
        })
        .then((response) => {
          const progressData = response.data.results.reduce((acc, item) => {
            acc[item.topic_id] = {
              employee_status: item.employee_status,
              supervisor_status: item.supervisor_status,
              id: item.id,
            };
            return acc;
          }, {});
          setTopicProgress(progressData);
        })
        .catch((error) => {
          console.error('Fehler beim Laden des Themenfortschritts:', error);
        });

      // Step Progress laden
      axiosInstance
        .get(`/onboarding/step-progress/`, {
          params: { employee: selectedEmployee },
        })
        .then((response) => {
          const progressData = response.data.results.reduce((acc, item) => {
            acc[item.onboarding_step_id] = {
              status: item.status,
              id: item.id,
            };
            return acc;
          }, {});
          setStepProgress(progressData);
        })
        .catch((error) => {
          console.error('Fehler beim Laden des Schrittfortschritts:', error);
        });
    }
  }, [selectedEmployee, assignments]);

  // Fortschritt berechnen
  const calculateProgress = () => {
    // Themenfortschritt Mitarbeiter
    const totalTopics = planTopics.length;
    const completedTopics = planTopics.filter(
      (topic) => topicProgress[topic.id]?.employee_status === true
    ).length;
    const topicCompletionPercentage =
      totalTopics > 0 ? Math.round((completedTopics / totalTopics) * 100) : 0;

    // Themenfortschritt Vorgesetzter
    const completedSupervisorTopics = planTopics.filter(
      (topic) => topicProgress[topic.id]?.supervisor_status === true
    ).length;
    const supervisorTopicCompletionPercentage =
      totalTopics > 0 ? Math.round((completedSupervisorTopics / totalTopics) * 100) : 0;

    // Schrittfortschritt
    const totalSteps = planSteps.length;
    const completedSteps = planSteps.filter(
      (step) => stepProgress[step.id]?.status === true
    ).length;
    const stepCompletionPercentage =
      totalSteps > 0 ? Math.round((completedSteps / totalSteps) * 100) : 0;

    return {
      topicCompletionPercentage,
      supervisorTopicCompletionPercentage,
      stepCompletionPercentage,
    };
  };

  const {
    topicCompletionPercentage,
    supervisorTopicCompletionPercentage,
    stepCompletionPercentage,
  } = calculateProgress();

  // Checkbox für den Fortschritt eines Schritts umschalten
  const handleStepChange = (stepId) => {
    const progress = stepProgress[stepId];
    const newStatus = !progress?.status;
    const progressId = progress?.id;

    if (progressId) {
      // Update des bestehenden Fortschritts
      axiosInstance
        .patch(`/onboarding/step-progress/${progressId}/`, {
          status: newStatus,
        })
        .then((response) => {
          setStepProgress((prevState) => ({
            ...prevState,
            [stepId]: {
              ...prevState[stepId],
              status: newStatus,
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren des Schrittfortschritts:', error);
        });
    }
  };

  // Checkbox für den Fortschritt eines Themas umschalten
  const handleTopicChange = (topicId, field) => {
    const progress = topicProgress[topicId];
    const newStatus = !progress?.[field];
    const progressId = progress?.id;

    if (progressId) {
      // Update des bestehenden Fortschritts
      axiosInstance
        .patch(`/onboarding/topic-progress/${progressId}/`, {
          [field]: newStatus,
        })
        .then((response) => {
          setTopicProgress((prevState) => ({
            ...prevState,
            [topicId]: {
              ...prevState[topicId],
              [field]: newStatus,
            },
          }));
        })
        .catch((error) => {
          console.error('Fehler beim Aktualisieren des Themenfortschritts:', error);
        });
    }
  };

  return (
    <Layout>
      <Box sx={{ maxWidth: 800, mx: 'auto', mt: 4 }}>
        {/* Header der Seite */}
        <PageHeader
          title="Onboarding Konfiguration"
          subtitle="Verwalte deine Onboarding-Themen und -Schritte."
          Icon={ChecklistIcon} // Übergabe des Icons
        />

        <Typography variant="h5" gutterBottom>
          Mitarbeiter Onboarding
        </Typography>

        {/* **Anzeigen des ausgewählten Mitarbeiters** */}
        {selectedEmployeeData && (
          <>
            <Typography variant="h6" gutterBottom>
              Ausgewählter Mitarbeiter: {selectedEmployeeData.name}
            </Typography>

            {/* Fortschrittskarten */}
            <Grid container spacing={2} sx={{ mb: 4 }}>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Themenfortschritt (Mitarbeiter)
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${topicCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress variant="determinate" value={topicCompletionPercentage} />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Themenfortschritt (Vorgesetzter)
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${supervisorTopicCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      value={supervisorTopicCompletionPercentage}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Card>
                  <CardContent>
                    <Typography variant="subtitle1" gutterBottom>
                      Schrittfortschritt
                    </Typography>
                    <Typography variant="h4" gutterBottom>
                      {`${stepCompletionPercentage}%`}
                    </Typography>
                    <LinearProgress variant="determinate" value={stepCompletionPercentage} />
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </>
        )}

        {/* Mitarbeiter-Auswahl nur anzeigen, wenn kein Mitarbeiter ausgewählt ist */}
        {!selectedEmployee && (
          <Select
            value={selectedEmployee || ''}
            onChange={(e) => handleEmployeeChange(e.target.value)}
            displayEmpty
            sx={{ mb: 4, width: '100%' }}
          >
            <MenuItem value="" disabled>
              Mitarbeiter auswählen
            </MenuItem>
            {employees.map((employee) => (
              <MenuItem key={employee.mitarbeiterId} value={employee.mitarbeiterId}>
                {employee.name}
              </MenuItem>
            ))}
          </Select>
        )}

        {loading && <CircularProgress />}

        {/* Formular in Paper */}
        {selectedEmployee && assignments.length > 0 && (
          <Paper sx={{ mt: 4, p: 4, boxShadow: 3 }}>
            <Typography variant="h6" gutterBottom>
              Onboarding-Plan:{' '}
              {onboardingPlans.find(
                (plan) =>
                  plan.id ===
                  assignments.find((a) => a.employee === selectedEmployee)?.onboarding_plan
              )?.name || 'Kein Plan zugewiesen'}
            </Typography>

            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kategorie/Topic/Step</TableCell>
                  <TableCell>Mitarbeiter</TableCell>
                  <TableCell>Vorgesetzter</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map((category) => (
                  <React.Fragment key={category.id}>
                    {/* Kategorie mit Hintergrundfarbe */}
                    <TableRow sx={{ backgroundColor: '#f0f0f0' }}>
                      <TableCell colSpan={3}>
                        <Typography variant="h6" sx={{ color: '#3f51b5' }}>
                          {category.name}
                        </Typography>
                      </TableCell>
                    </TableRow>
                    {topics
                      .filter((topic) => topic.category === category.id)
                      .map((topic) => (
                        <React.Fragment key={topic.id}>
                          {/* Thema mit leichter Absetzung */}
                          <TableRow sx={{ backgroundColor: '#f9f9f9' }}>
                            <TableCell>{topic.name}</TableCell>
                            <TableCell>
                              <Checkbox
                                checked={topicProgress[topic.id]?.employee_status || false}
                                onChange={() => handleTopicChange(topic.id, 'employee_status')}
                              />
                            </TableCell>
                            <TableCell>
                              <Checkbox
                                checked={topicProgress[topic.id]?.supervisor_status || false}
                                onChange={() => handleTopicChange(topic.id, 'supervisor_status')}
                              />
                            </TableCell>
                          </TableRow>

                          {/* Schritte innerhalb eines Themas */}
                          {steps[topic.id]?.map((step) => (
                            <TableRow key={step.id}>
                              <TableCell sx={{ pl: 4 }}>{step.description}</TableCell>
                              <TableCell>
                                <Checkbox
                                  checked={stepProgress[step.id]?.status || false}
                                  onChange={() => handleStepChange(step.id)}
                                />
                              </TableCell>
                              <TableCell></TableCell>{' '}
                              {/* Keine Checkbox für Vorgesetzten bei Schritten */}
                            </TableRow>
                          ))}
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </TableBody>
            </Table>
          </Paper>
        )}
      </Box>
    </Layout>
  );
};

export default Mitarbeiter;
