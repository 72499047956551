import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import axios from '../../../services/axiosInstance'; // Axios-Service importieren

const BewerberCard = ({ kundengruppeFilter }) => {
  const [bewerberCount, setBewerberCount] = useState(0);
  const [absagenCount, setAbsagenCount] = useState(0);
  const [zusagenCount, setZusagenCount] = useState('--');
  const [vertragCount, setVertragCount] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth()+1; // 0-basiert, daher 0 = Januar
    const currentYear = currentDate.getFullYear();
    const lastMonth = currentMonth === 0 ? 12 : currentMonth;
    const lastMonthYear = currentMonth === 0 ? currentYear - 1 : currentYear;
    const lastMonthString = `${currentYear}.${currentMonth.toString().padStart(2, '0')}`; // z.B. '2024.09'

    // API-Aufruf für Bewerber
    axios.get('/recruiting/bewerber', {
      params: {
        jahrMonat: lastMonthString
      }
    })
    .then(response => {
      const results = response.data.results;
      setBewerberCount(results.length); // Gesamtanzahl Bewerber
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Bewerber-Daten', error);
    });

    // API-Aufruf für Absagen im letzten Monat
    axios.get('/recruiting/bewerber', {
      params: {
        absage_jahrMonat: lastMonthString
      }
    })
    .then(response => {
      const absagenResults = response.data.results;
      setAbsagenCount(absagenResults.length);
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Absagen-Daten', error);
    });

    // API-Aufruf für Vorstellungsgespräche (Zusagen) im letzten Monat
    axios.get('/recruiting/bewerber', {
      params: {
        vorstellungsgespräch_jahrMonat: lastMonthString
      }
    })
    .then(response => {
      const zusagenResults = response.data.results;
      setZusagenCount(zusagenResults.length || '--'); // Fallback für keine Daten
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Zusagen-Daten', error);
    });

    // API-Aufruf für Vertragsunterzeichnungen im letzten Monat
    axios.get('/recruiting/bewerber', {
      params: {
        vertragsunterzeichnung_jahrMonat: lastMonthString
      }
    })
    .then(response => {
      const vertragResults = response.data.results;
      setVertragCount(vertragResults.length);
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Vertragsunterzeichnungen-Daten', error);
    });
    
  }, [kundengruppeFilter]);

  return (
    <Card sx={{ height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <CardContent>
        <Typography variant="h6" align="center">Bewerber</Typography>
        <Typography variant="h5" align="center">{bewerberCount}</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container justifyContent="space-between">
          <Grid item xs={4} textAlign="center">
            <Typography variant="body2">Absagen</Typography>
            <Typography variant="h6">{absagenCount}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Typography variant="body2">Zusage</Typography>
            <Typography variant="h6">{zusagenCount}</Typography>
          </Grid>
          <Grid item xs={4} textAlign="center">
            <Typography variant="body2">Vertragsunterzeichnungen</Typography>
            <Typography variant="h6">{vertragCount}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BewerberCard;
