import React from 'react';
import flattenRoutes from '../../utils/flattenRoutes';
import {routes} from '../../routes/dynamicRoutes';

const RouteList = () => {
    const routesFlat = flattenRoutes(routes);

    return (
        <div>
            <h2 className="text-2xl">All dynamically generated routes</h2>
            <ul>
                {routesFlat.map((route, index) => (
                    <li key={index}>{route}</li>
                ))}
            </ul>
        </div>
    );
};

export default RouteList;
