import React, {useContext, useEffect, useState} from 'react';
import {
    Typography,
    Grid,
    CardHeader,
    Card,
    CardContent,
    Paper,
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCard';
import {FilterContext} from "../../../provider/FilterProvider";
import WochentagFilter from '../../../components/filter/WochentagFilter';
import VariableTable from "../../../components/table/VariableTable";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import {roundToDecimals, tausenderTrennung} from "../../../utils/mathStuff";
import TAPerformanceLeistung from "../../../components/scorecards/tiefenanalyse/TAPerformanceLeistung";
import {Alert} from "@mui/material";
import Timer from '@mui/icons-material/Timer';
import {getAlertSeverity} from "../../../components/utility/AlertPopup";
 
 
 
const Leistung = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const {filterState} = useContext(FilterContext);
    const [wochentagFilter, setWochentagFilter] = useState(null);
    const [wochentagOptions, setWochentagOptions] = useState([]);
    const activeCategory = 'Leistung';
 
    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum, wochentagFilter]);
 
    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(4, 'month');
 
            const response = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                    wochentag: wochentagFilter?.value
                }
            });
 
            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-unternehmen-monat/', {
                params: {
                    startdatum: endDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                }
            });
 
            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Umsatz: item.scoreUmsatz,
                    Kundenanzahl: item.scoreKundenanzahl,
                    Kundenbon: item.scoreKundenbon,
                    Leistung: item.scoreLeistung,
                }));
 
            const dataLineChart = response.data.results.map(item => ({
                x: item.datum,
                y: item.umsatz,
            }));
 
            console.log("responseUnternehmen", responseUnternehmen)
 
            const leistungUnternehmen = responseUnternehmen.data.results[0].leistung;
 
            const comparisonCardData = response.data.results.slice(-1).map(item => ({
                ...item,
                leistungUnternehmen,  // Add the kundenbonUnternehmen to the item
            }));
 
            console.log("comparisonCardData", comparisonCardData)
 
            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };
 
    const fetchTableData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/fact/kundenstatistik/', {
                params: {
                    filiale: filterState.filiale?.value,
                    jahrMonat: filterState.datum?.value,
                    wochentag: wochentagFilter?.value,
                },
            });
            if (Array.isArray(response.data.results)) {
                const mappedData = response.data.results.map((item) => ({
                    datum: dayjs(item.datum.split(' ')[0]).format('dddd, D. MMMM YYYY'),
                    umsatz: item.umsatz !== null ? tausenderTrennung(item.umsatz,2) : 0,
                    leistung: item.umsatz && item.produktivstunden ? Math.round(item.umsatz / item.produktivstunden) : 0,  // Sicherstellen, dass beide Werte gültig sind
                    produktivstunden: item.produktivstunden !== null ? Math.round(item.produktivstunden) : 0,  // Sicherstellen, dass produktivstunden nicht null ist
                }));
                setTableData(mappedData);
            } else {
                console.error('No data received from the backend');
            }
        } catch (error) {
            console.error('Error while fetching table data:', error);
        } finally {
            setLoading(false);
        }
    };
    
 
    const fetchWochentagOptions = async () => {
        try {
            const response = await axiosInstance.get('stammdaten/unique-wochentag/');
            setWochentagOptions(response.data.map(item => ({label: item, value: item})));
        } catch (error) {
            console.error('Fehler beim Abrufen der Wochentag-Optionen:', error);
        }
    };
 
    useEffect(() => {
        fetchWochentagOptions();
    }, []);
 
    const handleWochentagFilterChange = (newValue) => {
        setWochentagFilter(newValue);
    };
 
    const columns = [
        {accessorKey: 'datum', header: 'Datum'},
        {accessorKey: 'umsatz', header: 'Umsatz €'},
        {accessorKey: 'leistung', header: 'Leistung €'},
        {accessorKey: 'produktivstunden', header: 'Stunden'},
    ];
 
    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{overflowX: 'auto'}}>
                        {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="performance"
                                    categoryData={
                                        activeCategory === category
                                            ? `${comparisonCardData.length > 0 ? roundToDecimals(comparisonCardData[0].leistung, 2) : null} €`
                                            : ''
                                    }
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>
 
                <Grid container item mt={3} spacing ={2} >
                    
                            <Grid item xs={12} md={6} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                            <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreLeistung"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackLeistung"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                                <ComparisonCard
                                    zeitraum="Unternehmen"
                                    data={comparisonCardData}
                                    scoreField="scoreLeistung"
                                    rangField="rangLeistungAbs"
                                    extraField1="leistung"
                                    extraIcon1={<Timer sx={{color: 'action.active'}}/>}
                                    extraField2="leistungUnternehmen"
                                    extraLabel2="Unternehmen"
                                    extraLabel1="Leistung"
                                    extraIcon2={<AddBusinessIcon sx={{color: 'action.active'}}/>}
                                    TiefenanalyseComponent={TAPerformanceLeistung}
                                />
                                <ComparisonCard
                                    zeitraum="Vorjahr"
                                    data={comparisonCardData}
                                    scoreField="scoreLeistungWachstumJ"
                                    rangField="rangLeistungWachstumJ"
                                    percentageField="leistungWachstumJ"
                                    extraField1="leistung"
                                    extraLabel1="Leistung"
                                    extraIcon1={<Timer sx={{color: 'action.active'}}/>}
                                    TiefenanalyseComponent={TAPerformanceLeistung}

                                />
                            </Grid>
 
                            <Grid item xs={12} md={6}>
                                <Paper>
                                    <Card
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            height: '100%',
                                            padding: '0px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                            backdropFilter: 'blur(10px)',
                                        }}
                                    >
                                        <CardHeader
                                            title={
                                                <Typography variant="h6" component="div">
                                                    Leistung
                                                </Typography>
                                            }
                                        />
                                        <CardContent sx={{flexGrow: 1}}>
                                            <WochentagFilter
                                                options={wochentagOptions}
                                                value={wochentagFilter}
                                                onChange={handleWochentagFilterChange}
                                               
                                            />
                                            {loading ? (
                                                <div>Loading...</div>
                                            ) : (
                                                <VariableTable columns={columns} data={tableData}/>
                                            )}
                                        </CardContent>
                                    </Card>
                                </Paper>
                            </Grid>
                        
                </Grid>
            </Grid>
            <ScorecardsNotes category="Performance" />
        </Layout>
    );
 
};
 
export default Leistung;