import React, {useState} from 'react';
import {TextField, Button, Box, MenuItem, Select, InputLabel, FormControl} from '@mui/material';

const NeueCheckpointForm = ({categories, onAddCheckpoint}) => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [newCheckpoint, setNewCheckpoint] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        onAddCheckpoint(newCheckpoint, selectedCategory);
        setNewCheckpoint('');
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{display: 'flex', gap: 2, flexDirection: 'column'}}>
            <FormControl fullWidth>
                <InputLabel>Kategorie auswählen</InputLabel>
                <Select
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                >
                    {categories.map(category => (
                        <MenuItem key={category.id} value={category.id}>{category.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
            <TextField
                label="Neuer Überprüfungspunkt"
                value={newCheckpoint}
                onChange={(e) => setNewCheckpoint(e.target.value)}
            />
            <Button type="submit" variant="contained">Hinzufügen</Button>
        </Box>
    );
};

export default NeueCheckpointForm;
