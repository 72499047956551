import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent, Box } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTableTree from '../../../components/table/VariableTableTree';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import StoreIcon from '@mui/icons-material/Store';
import BigPieChartCardUnternehmen from '../../../components/card/scorecardsmonat/BigPieChartCardUnternehmen';

const Umsatz = () => {
    const { filterState } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVM: 0,
        umsatzAbweichung6M: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVM: 0,
        kundenanzahlAbweichung6M: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [], // Beibehalten wie zuvor
        filialenMitUmsatz: 0, // Beibehalten wie zuvor
        filialtypenResult: [], // Leeres Array als Standardwert
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLast6Months, setPerformanceLast6Months] = useState([]);
    const activeCategory = 'Umsatz';

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months();
        }
    }, [filterState]);

    const formatNumberWithCommas = (number) => {
        return number.toLocaleString('de-DE', { minimumFractionDigits: 0, maximumFractionDigits: 0 });
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der ersten API
            const response = await axiosInstance.get('/scorecards_month/performance-monat', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;

            // Abrufen der "scoreUmsatz"-Daten von der zweiten API
            const scoreResponse = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const scoreUmsatzData = scoreResponse.data.results;
            const calculatedData = calculatePerformanceData(allFilialenData);

            // Anzahl der Filialen mit Umsatz > 0 berechnen
            const filialenMitUmsatz = allFilialenData.filter((filiale) => filiale.umsatz > 0).length;

            // Berechnung des durchschnittlichen Umsatzes pro Filiale
            const durchschnittUmsatz = filialenMitUmsatz > 0 ? calculatedData.umsatzSumme / filialenMitUmsatz : 0;

            // Zusammenfügen der Daten von beiden APIs
            const mergedData = allFilialenData.map((filiale) => {
                const scoreFiliale = scoreUmsatzData.find((score) => score.filialnummer === filiale.filialnummer);
                return {
                    ...filiale,
                    scoreUmsatz: scoreFiliale ? scoreFiliale.scoreUmsatz : 0,
                };
            });

            // Berechnung der filialtypenResult für das PieChart (Anpassung hier)
            const filialtypenResult = mergedData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.umsatzSumme += filiale.umsatz || 0;
                    existing.umsatzVJSumme += filiale.umsatzVJ || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        umsatzSumme: filiale.umsatz || 0,
                        umsatzVJSumme: filiale.umsatzVJ || 0,
                    });
                }
                return acc;
            }, []);

            // Berechnung der Umsatzabweichung für jeden Filialtyp
            filialtypenResult.forEach(item => {
                item.umsatzAbweichung = item.umsatzVJSumme > 0
                    ? ((item.umsatzSumme - item.umsatzVJSumme) / item.umsatzVJSumme) * 100
                    : 0;
            });

            // Set performance data for cards
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVM: calculatedData.umsatzVMSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatz6M: calculatedData.umsatz6MSumme,
                umsatzAbweichungVM: calculatedData.umsatzAbweichungVM,
                umsatzAbweichung6M: calculatedData.umsatzAbweichung6M,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVM: calculatedData.kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M: calculatedData.kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitUmsatz,
                durchschnittUmsatz,
                filialtypenResult, // Hinzufügen von filialtypenResult für das Chart
            });

            // Tabelle formatieren und "scoreUmsatz"-Wert hinzufügen
            const formattedTableData = mergedData
                .filter(filiale => filiale.umsatz > 0)
                .map((filiale) => ({
                    filialnummer: filiale.filialnummer,
                    filiale: filiale.filiale,
                    verkaufsgebiet: filiale.verkaufsgebiet,
                    filialtyp: filiale.filialtyp,
                    umsatz: filiale.umsatz,
                    umsatzVM: filiale.umsatzVM,
                    umsatzVJ: filiale.umsatzVJ,
                    umsatz6M: filiale.umsatz6M,
                    scoreUmsatz: filiale.scoreUmsatz,
                    umsatzAbweichungVM: filiale.umsatzVM > 0 ? formatPercentage(((filiale.umsatz - filiale.umsatzVM) / filiale.umsatzVM) * 100) : '0.00%',
                    umsatzAbweichung6M: filiale.umsatz6M > 0 ? formatPercentage(((filiale.umsatz - filiale.umsatz6M) / filiale.umsatz6M) * 100) : '0.00%',
                    umsatzAbweichungVJ: filiale.umsatzVJ > 0 ? formatPercentage(((filiale.umsatz - filiale.umsatzVJ) / filiale.umsatzVJ) * 100) : '0.00%',
                }));

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return last6Months.reverse();
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const last6Months = getLast6Months(currentMonth);

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: { jahrMonat: month },
                }))
            );

            const last6MonthsData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);
                const umsatzVJSumme = response.data.results
                    .filter(filiale => filiale.umsatzVJ > 1)
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);
                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung,
                };
            });

            setPerformanceLast6Months(last6MonthsData);
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    // Prepare data for the Line Chart
    const chartData = performanceLast6Months.flatMap((monthData) => [
        { x: monthData.month, y: monthData.umsatz, label: 'aktuelles Jahr' },  // Aktueller Umsatz
        { x: monthData.month, y: monthData.umsatzVJ, label: 'Vorjahr' }
    ]);

    const columns = useMemo(() => [
        // Ihre Spaltendefinitionen bleiben unverändert
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'scoreUmsatz',
            header: 'Score Umsatz',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const validSubRows = subRows.filter(subRow => subRow.getValue('scoreUmsatz') > 0);
                const totalScoreUmsatz = validSubRows.reduce((total, subRow) => total + (subRow.getValue('scoreUmsatz') || 0), 0);
                const averageScoreUmsatz = validSubRows.length > 0 ? totalScoreUmsatz / validSubRows.length : 0;

                return (
                    <span>{formatNumberWithCommas(averageScoreUmsatz.toFixed(2))}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'umsatz',
            header: 'Umsatz',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => (
                <span> {formatNumberWithCommas(cell.getValue())}</span>
            ),
            isVisible: true,
        },
        {
            accessorKey: 'umsatzAbweichungVM',
            header: 'Umsatz Abw. VM',
            enableGrouping: false,
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedUmsatz = subRows.reduce((total, subRow) => total + subRow.getValue('umsatz'), 0);
                const aggregatedUmsatzVM = subRows.reduce((total, subRow) => total + subRow.original.umsatzVM, 0);

                const umsatzAbweichungVM = aggregatedUmsatzVM > 0 ? ((aggregatedUmsatz - aggregatedUmsatzVM) / aggregatedUmsatzVM) * 100 : 0;

                return (
                    <span>
                        {umsatzAbweichungVM.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'umsatzAbweichung6M',
            header: 'Umsatz Abw. 6M',
            enableGrouping: false,
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedUmsatz = subRows.reduce((total, subRow) => total + (subRow.getValue('umsatz') || 0), 0);
                const aggregatedUmsatz6M = subRows.reduce((total, subRow) => total + (subRow.original.umsatz6M || 0), 0);

                const umsatzAbweichung6M = aggregatedUmsatz6M > 0 ? ((aggregatedUmsatz - aggregatedUmsatz6M) / aggregatedUmsatz6M) * 100 : 0;

                return (
                    <span>
                        {umsatzAbweichung6M.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'umsatzAbweichungVJ',
            header: 'Umsatz Abw. VJ',
            enableGrouping: false,
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedUmsatz = subRows.reduce((total, subRow) => total + (subRow.getValue('umsatz') || 0), 0);
                const aggregatedUmsatzVJ = subRows.reduce((total, subRow) => total + (subRow.original.umsatzVJ || 0), 0);

                const umsatzAbweichungVJ = aggregatedUmsatzVJ > 0 ? ((aggregatedUmsatz - aggregatedUmsatzVJ) / aggregatedUmsatzVJ) * 100 : 0;

                return (
                    <span>
                        {umsatzAbweichungVJ.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
    ], [performanceData]);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                {/* spacing={4} adds larger space between items */}
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? formatNumberWithCommas(performanceData.umsatz) : '0.00'}
                                subHeaders={[
                                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.umsatz)}
                            />
                        </Grid>

                        {/* Weitere DataCards bleiben unverändert */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? performanceData.kundenanzahl.toFixed(2) : '0.00'}
                                subHeaders={[
                                    `${performanceData.kundenanzahlAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? performanceData.kundenbon.toFixed(2) : '0.00'}
                                subHeaders={[
                                    `${((performanceData.kundenbon - performanceData.kundenbonVJ) / performanceData.kundenbonVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
                                subHeaders={[
                                    `${((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLast6Months.map(item => item.leistung)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                    
                            <Grid item xs={12} lg={5} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                                {/* DataCard für Filialen mit Umsatz */}
                                <Box>
                                    <Paper elevation={3} sx={{ padding: 3 }}>
                                        <Stack spacing={3.5}>
                                            {/* Anzahl der Filialen mit Umsatz */}
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <StoreIcon fontSize="large" color="primary" />
                                                <Box>
                                                    <Typography variant="h6">
                                                        Anzahl Filialen mit Umsatz im gefilterten Monat
                                                    </Typography>
                                                    <Typography variant="h4">
                                                        {performanceData.filialenMitUmsatz ? performanceData.filialenMitUmsatz : '0'}
                                                    </Typography>
                                                </Box>
                                            </Stack>

                                            {/* Durchschnittsumsatz */}
                                            <Stack direction="row" alignItems="center" spacing={2}>
                                                <MonetizationOnIcon fontSize="large" color="primary" />
                                                <Box>
                                                    <Typography variant="h6">Durchschnittsumsatz pro Filiale</Typography>
                                                    <Typography variant="h4">
                                                        {performanceData.durchschnittUmsatz ? formatNumberWithCommas(performanceData.durchschnittUmsatz) : '0'}
                                                    </Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    </Paper>
                                </Box>

                                {/* PieChart für Filialtypen */}
                                <Box sx={{ flexGrow: 1 }}>
                                    <BigPieChartCardUnternehmen
                                        data={performanceData.filialtypenResult}
                                        title="Umsatzanteil nach Filialtyp"
                                        metric="umsatz" // Hinzufügen des metric-Props
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} lg={7}>
                            <BigChartCard
                                data={chartData}
                                title="Umsatz und Umsatz Vorjahr der letzten 6 Monate"
                            />
                            </Grid>

                        <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Umsatz
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <VariableTableTree
                                        columns={columns}
                                        data={tableData}
                                        initialGrouping={['verkaufsgebiet']} // Gruppierung nach Verkaufsgebiet
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
                   
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Umsatz;