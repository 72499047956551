import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, CardHeader, ListItemIcon, CircularProgress, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardContent, Switch } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import OnboardingPlanHinzufuegen from './OnboardingHinzufuegen';
import OnboardingPlanKonfiguration from './OnboardingPlanKonfiguration';

const OnboardingPlaeneListe = ({ refresh }) => {
    const [onboardingPlaene, setOnboardingPlaene] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);  
    const [editDialogOpen, setEditDialogOpen] = useState(false);  
    const [selectedPlan, setSelectedPlan] = useState(null);  

    const fetchOnboardingPlaene = async () => {
        try {
            const response = await axiosInstance.get('/onboarding/onboarding-plans/');
            if (Array.isArray(response.data)) {
                setOnboardingPlaene(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setOnboardingPlaene(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Einarbeitungspläne:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchOnboardingPlaene();
    }, [refresh]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        fetchOnboardingPlaene(); // Aktualisiere die Liste nach dem Schließen des Dialogs
    };

    const handleEditDialogOpen = (plan) => {
        setSelectedPlan(plan);  
        setEditDialogOpen(true);  
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        fetchOnboardingPlaene(); // Aktualisiere die Liste nach dem Schließen des Bearbeitungsdialogs
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Möchtest du diesen Einarbeitungsplan und alle zugehörigen Einträge wirklich löschen?");
        if (confirmed) {
            try {
                await axiosInstance.delete(`/onboarding/onboarding-plans/${id}/`);  // DELETE-Request an das Backend
                
                // Entferne den gelöschten Plan aus der Liste
                setOnboardingPlaene(onboardingPlaene.filter(plan => plan.id !== id));
            } catch (error) {
                console.error('Fehler beim Löschen des Einarbeitungsplans:', error);
            }
        }
    };

    const handleToggleActive = async (plan, event) => {
        event.stopPropagation(); // Verhindert das Öffnen des Bearbeitungsdialogs
        try {
            await axiosInstance.patch(`/onboarding/onboarding-plans/${plan.id}/`, { is_active: !plan.is_active });  // Aktiv/Passiv umschalten
            // Aktualisiere den Status des Plans in der Liste
            setOnboardingPlaene(onboardingPlaene.map(p => p.id === plan.id ? { ...p, is_active: !plan.is_active } : p));
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                padding: 2,
            }}
        >
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Liste der Einarbeitungspläne
                        </Typography>
                    }
                    action={
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddCircleIcon />}
                            onClick={handleDialogOpen}
                        >
                            Einarbeitungsplan hinzufügen
                        </Button>
                    }
                />
                <CardContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {Array.isArray(onboardingPlaene) && onboardingPlaene.length > 0 ? (
                            onboardingPlaene.map((plan) => (
                                <React.Fragment key={plan.id}>
                                    <ListItem>  
                                        <ListItemIcon>
                                            <AssignmentIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={plan.name} 
                                            secondary={plan.is_active ? "Aktiv" : "Passiv"} 
                                        />
                                        <Switch
                                            edge="end"
                                            checked={plan.is_active}
                                            onChange={(e) => handleToggleActive(plan, e)} 
                                            inputProps={{ 'aria-label': 'Aktiv/Passiv umschalten' }}
                                        />
                                        <IconButton edge="end" aria-label="edit" onClick={(e) => {e.stopPropagation(); handleEditDialogOpen(plan);}}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => {e.stopPropagation(); handleDelete(plan.id);}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="h6">Keine Einarbeitungspläne gefunden</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>
            
            {/* Dialog für das Hinzufügen eines neuen Einarbeitungsplans */}
            <Dialog
                open={dialogOpen} // Öffnen des Dialogs basierend auf dem Zustand
                onClose={handleDialogClose} // Schließen des Dialogs
                maxWidth="md" // Maximale Breite des Dialogs
                fullWidth // Der Dialog nimmt die volle Breite ein
            >
                <DialogTitle>Neuen Einarbeitungsplan hinzufügen</DialogTitle>
                <DialogContent>
                    <OnboardingPlanHinzufuegen 
                        open={dialogOpen} 
                        handleClose={handleDialogClose} 
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Dialog für die Bearbeitung eines bestehenden Einarbeitungsplans */}
            <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Einarbeitungsplan Konfiguration</DialogTitle>
                <DialogContent>
                    {selectedPlan && (
                        <OnboardingPlanKonfiguration
                            open={editDialogOpen}
                            handleClose={handleEditDialogClose}
                            planId={selectedPlan.id}  
                            planName={selectedPlan.name}  
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default OnboardingPlaeneListe;
