import React, { useContext, useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Card, CardContent,Tooltip, CardHeader,Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from "../../../services/axiosInstance";
import { FilterContext } from "../../../provider/FilterProvider";
import ErrorPopup from "../../utility/ErrorPopup";
import GaugeBedarf from '../../charts/GaugeBedarf'; // Importiere die neue GaugeBedarf-Komponente
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TAPersonalBedarf = ({ open, setOpen }) => {
  const { filterState } = useContext(FilterContext);
  const [openError, setOpenError] = useState(false);
  const [personnelData, setPersonnelData] = useState(null);

  useEffect(() => {
    if (filterState.filiale === null || filterState.datum === null) {
      setOpenError(true);
    }
  }, [filterState.filiale, filterState.datum]);

  useEffect(() => {
    if (open && filterState.filiale && filterState.datum) {
      fetchPersonnelData();
    }
  }, [filterState.filiale, filterState.datum, open]);

  const handleCloseError = () => {
    setOpenError(false);
    setOpen(false); // Close the main dialog as well
  };

  if (openError) {
    return (
      <ErrorPopup
        open={openError}
        handleClose={handleCloseError}
        message={"Bitte wählen Sie eine Filiale und ein Datum aus"}
      />
    );
  }

  const handleClose = () => {
    setOpen(false);
  };

  const fetchPersonnelData = async () => {
    try {
      const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
        params: {
          jahrMonat: filterState.datum?.value,
          filiale: filterState.filiale?.value,
        }
      });

      const lastMonthData = response.data.results[0];
      console.log("AbfrageDaten", lastMonthData);
      setPersonnelData(lastMonthData);
    } catch (error) {
      console.error('An error occurred while fetching personnel data:', error);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Tiefenanalyse Personalbedarf
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}>
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
Bedarf an Mitarbeitern
                                    </Typography>
                                }
                                action={
<Tooltip
    title={
<>
Bedarf an Mitarbeitern - Informationen<br /><br />
Der Bedarf an Mitarbeitern wird berechnet, indem die Wochensollstunden durch 30 Stunden geteilt werden. 30 Stunden sind der errechnete Durchschnitt pro Mitarbeiter.<br /><br />
In diesem Diagramm siehst du den berechneten Bedarf an Mitarbeitern im Vergleich zur tatsächlichen Anzahl der Mitarbeiter in der Filiale.

</>

    }
>
    <IconButton>
        <InfoOutlinedIcon />
    </IconButton>
</Tooltip>

                                }
                            />             
                             <CardContent sx={{ flexGrow: 1 }}>
                {personnelData && (
                  <GaugeBedarf
                    value={personnelData.köpfe || 0}
                    target={personnelData.anzahlSollMitarbeiter || 0}
                    maxValue={personnelData.anzahlSollMitarbeiter * 2}
                    description={`Aktuell kann die Filiale mit ${personnelData?.köpfe || 0} Filialmitarbeiter*innen planen, für eine reibungslose Planung 
                    würde die Filiale ${personnelData?.anzahlSollMitarbeiter || 0} benötigen. Hieraus leitet sich ein Score von 
                    ${personnelData?.scoreKöpfeDifferenzQuote || 0} ab.`}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}>
                <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
Bedarf an Wochensollstunden
                                    </Typography>
                                }
                                action={
<Tooltip
    title={
      <>
Bedarf an Wochenstunden - Informationen<br /><br />
      Der Bedarf an Wochenstunden wird berechnet, indem die geleisteten Stunden der letzten 6 Monate auf eine Woche heruntergebrochen werden. Auf diesen Wert wird ein Puffer von 20% für Krankheit, Fluktuation und Urlaub aufgeschlagen.<br /><br />
      In diesem Diagramm siehst du die Sollstunden (Bedarf) im Vergleich zu den tatsächlich im Vertrag vereinbarten Stunden der Mitarbeiter.
  </>
    }
>
    <IconButton>
        <InfoOutlinedIcon />
    </IconButton>
</Tooltip>

                                }
                            />  
              <CardContent sx={{ flexGrow: 1 }}>
                {personnelData && (
                  <GaugeBedarf
                    value={personnelData.wochensollBerechnetSum || 0}
                    target={personnelData.sollStunden || 0}
                    maxValue={personnelData.sollStunden * 2}
                    description={`Die Filiale hat für wöchentliche Einsatzplanung aktuell  
                    ${personnelData?.wochensollBerechnetSum || 0} Stunden zur Verfügung. 
                    Planmäßig benötigt man eine reibungslose Planung von  
                    ${personnelData?.sollStunden || 0} Stunden. Hieraus leitet sich 
                    ein Score von ${personnelData?.scoreStundenDifferenz || 0} ab.`}
                  />
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default TAPersonalBedarf;
