import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const StepDialog = ({ open, onClose, stepName, setStepName, handleSaveStep }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{stepName ? 'Schritt bearbeiten' : 'Neuen Schritt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Schritt Name"
                fullWidth
                value={stepName}
                onChange={(e) => setStepName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveStep} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

export default StepDialog;
