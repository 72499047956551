import React, { useState, useEffect } from 'react';
import { Dialog, MenuItem, Select, DialogTitle, DialogContent, DialogActions, Button, TextField, Box, Typography, List, ListItem, ListItemText, IconButton, Collapse, Alert, Checkbox, FormControlLabel } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';

const FilialcheckKonfiguration = ({ open, handleClose, checkId, checkName }) => {
    const [categoryName, setCategoryName] = useState('');
    const [checkpointName, setCheckpointName] = useState('');
    const [categories, setCategories] = useState([]);
    const [checkpoints, setCheckpoints] = useState([]);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openCheckpointDialog, setOpenCheckpointDialog] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingCheckpoint, setEditingCheckpoint] = useState(null);
    const [ratingType, setRatingType] = useState('scale'); // Default rating type
    const [allowImageUpload, setAllowImageUpload] = useState(false); // New state for image upload permission

    useEffect(() => {
        if (open) {
            axiosInstance.get(`/filialcheck/categories/`)
                .then(response => {
                    const filteredCategories = response.data.results.filter(category => category.check_related === checkId);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });

            axiosInstance.get(`/filialcheck/checkpoints/`)
                .then(response => {
                    setCheckpoints(response.data.results);
                })
                .catch(error => {
                    console.error('Error fetching checkpoints:', error);
                });
        }
    }, [open, checkId]);

    const handleSaveCategory = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            axiosInstance.patch(`/filialcheck/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    setCategories(categories.map(category => category.id === editingCategory.id ? response.data : category));
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Error editing category:', error);
                });
        } else {
            axiosInstance.post('/filialcheck/categories/', { name: categoryName, check_related: checkId })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Error saving category:', error);
                });
        }
    };

    const handleSaveCheckpoint = () => {
        if (checkpointName.trim() === '') {
            setError('Überprüfungspunktname darf nicht leer sein.');
            return;
        }

        const checkpointData = {
            name: checkpointName,
            category: selectedCategory,
            rating_type: ratingType,
            allow_image_upload: allowImageUpload  // Include image upload permission
        };

        if (editingCheckpoint) {
            axiosInstance.patch(`/filialcheck/checkpoints/${editingCheckpoint.id}/`, checkpointData)
                .then((response) => {
                    setCheckpoints(checkpoints.map(checkpoint => checkpoint.id === editingCheckpoint.id ? response.data : checkpoint));
                    setEditingCheckpoint(null);
                    setCheckpointName('');
                    setRatingType('scale'); // Reset rating type
                    setAllowImageUpload(false); // Reset image upload option
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Überprüfungspunkts.');
                    console.error('Fehler beim Bearbeiten des Überprüfungspunkts:', error);
                });
        } else {
            axiosInstance.post('/filialcheck/checkpoints/', checkpointData)
                .then((response) => {
                    setCheckpoints([...checkpoints, response.data]);
                    setCheckpointName('');
                    setRatingType('scale'); // Reset rating type
                    setAllowImageUpload(false); // Reset image upload option
                    setError(null);
                    setOpenCheckpointDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Überprüfungspunkts.');
                    console.error('Fehler beim Speichern des Überprüfungspunkts:', error);
                });
        }
    };

    const handleDeleteCategory = (id) => {
        axiosInstance.delete(`/filialcheck/categories/${id}`)
            .then(() => {
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                console.error('Error deleting category:', error);
            });
    };

    const handleDeleteCheckpoint = (id) => {
        axiosInstance.delete(`/filialcheck/checkpoints/${id}`)
            .then(() => {
                setCheckpoints(checkpoints.filter(checkpoint => checkpoint.id !== id));
            })
            .catch(error => {
                console.error('Error deleting checkpoint:', error);
            });
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditCheckpoint = (checkpoint) => {
        setEditingCheckpoint(checkpoint);
        setCheckpointName(checkpoint.name);
        setRatingType(checkpoint.rating_type);
        setAllowImageUpload(checkpoint.allow_image_upload); // Set initial image upload option
        setOpenCheckpointDialog(true);
    };

    const handleAddCheckpointPlaceholderClick = () => {
        setCheckpointName('');
        setRatingType('scale');
        setAllowImageUpload(false);
        setOpenCheckpointDialog(true);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Filialcheck Konfiguration - {checkName}</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box component="form" sx={{ mb: 2 }}>
                        <List>
                            {categories.length > 0 ? categories.map((category) => (
                                <CategoryItem
                                    key={category.id}
                                    category={category}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    checkpoints={checkpoints}
                                    handleDeleteCategory={handleDeleteCategory}
                                    handleEditCategory={handleEditCategory}
                                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                                    handleEditCheckpoint={handleEditCheckpoint}
                                    handleAddCheckpointPlaceholderClick={handleAddCheckpointPlaceholderClick}
                                />
                            )) : (
                                <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                            )}
                        </List>
                        <Box
                            sx={{
                                border: '2px dashed #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: '#888',
                                mt: 2,
                                width: '100%',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                                boxSizing: 'border-box',
                            }}
                            onClick={() => setOpenCategoryDialog(true)}
                        >
                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Neue Kategorie anlegen
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Schließen</Button>
                </DialogActions>
            </Dialog>

            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => setOpenCategoryDialog(false)}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategory}
            />

            <CheckpointDialog
                open={openCheckpointDialog}
                onClose={() => setOpenCheckpointDialog(false)}
                checkpointName={checkpointName}
                setCheckpointName={setCheckpointName}
                handleSaveCheckpoint={handleSaveCheckpoint}
                ratingType={ratingType}
                setRatingType={setRatingType}
                allowImageUpload={allowImageUpload}
                setAllowImageUpload={setAllowImageUpload}
            />
        </>
    );
};

const CategoryItem = ({ category, selectedCategory, setSelectedCategory, checkpoints, handleDeleteCategory, handleEditCategory, handleDeleteCheckpoint, handleEditCheckpoint, handleAddCheckpointPlaceholderClick }) => (
    <Box sx={{ border: '1px solid #ddd', borderRadius: '8px', p: 2, mb: 2 }}>
        <ListItem
            secondaryAction={
                <>
                    <IconButton edge="end" aria-label="edit" onClick={() => handleEditCategory(category)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCategory(category.id)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            }
            onClick={() => setSelectedCategory(selectedCategory === category.id ? null : category.id)}
            selected={selectedCategory === category.id}
        >
            <ListItemText primary={category.name} />
        </ListItem>
        <Collapse in={selectedCategory === category.id} timeout="auto" unmountOnExit>
            <Box sx={{ mt: 2, mb: 2 }}>
                <CheckpointList
                    checkpoints={checkpoints.filter(checkpoint => checkpoint.category === category.id)}
                    handleDeleteCheckpoint={handleDeleteCheckpoint}
                    handleEditCheckpoint={handleEditCheckpoint}
                />
                <Box
                    sx={{
                        border: '2px dashed #ddd',
                        borderRadius: '8px',
                        padding: '16px',
                        textAlign: 'center',
                        cursor: 'pointer',
                        color: '#888',
                        mt: 2,
                        width: '100%',
                        '&:hover': { backgroundColor: '#f5f5f5' },
                        boxSizing: 'border-box',
                    }}
                    onClick={handleAddCheckpointPlaceholderClick}
                >
                    <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    Checkpoint anlegen
                </Box>
            </Box>
        </Collapse>
    </Box>
);

const CheckpointList = ({ checkpoints, handleDeleteCheckpoint, handleEditCheckpoint }) => (
    <List sx={{ width: '100%' }}>
        {checkpoints.length > 0 ? (
            checkpoints.map((checkpoint) => (
                <Box
                    key={checkpoint.id}
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        p: 2,
                        mb: 1,
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <ListItem
                        secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditCheckpoint(checkpoint)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteCheckpoint(checkpoint.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                    >
                        <ListItemText 
                            primary={checkpoint.name} 
                            secondary={`Bewertungsart: ${checkpoint.rating_type === 'scale' ? 'Skala' : 'Ja/Nein'}, Bild-Upload: ${checkpoint.allow_image_upload ? 'Erlaubt' : 'Nicht erlaubt'}`}
                        />
                    </ListItem>
                </Box>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">Keine Überprüfungspunkte vorhanden.</Typography>
        )}
    </List>
);

const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCategory} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

const CheckpointDialog = ({ open, onClose, checkpointName, setCheckpointName, handleSaveCheckpoint, ratingType, setRatingType, allowImageUpload, setAllowImageUpload }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{checkpointName ? 'Überprüfungspunkt bearbeiten' : 'Neuen Überprüfungspunkt anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Überprüfungspunkt Name"
                fullWidth
                value={checkpointName}
                onChange={(e) => setCheckpointName(e.target.value)}
                margin="normal"
            />
            <Typography variant="body2" sx={{ mt: 2 }}>Bewertungsart</Typography>
            <Select
                value={ratingType}
                onChange={(e) => setRatingType(e.target.value)}
                fullWidth
            >
                <MenuItem value="scale">Skala</MenuItem>
                <MenuItem value="yesNo">Ja/Nein</MenuItem>
            </Select>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allowImageUpload}
                        onChange={(e) => setAllowImageUpload(e.target.checked)}
                    />
                }
                label="Bild-Upload erlauben"
                sx={{ mt: 2 }}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveCheckpoint} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

export default FilialcheckKonfiguration;
