import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from "@sentry/react";
import { PostHogProvider} from 'posthog-js/react'

const options = {
    api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
}

Sentry.init({
    dsn: "https://dd6b9d9e6af875d28a9dae916b375c3c@o4507833997524992.ingest.de.sentry.io/4507859368607824",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
        "localhost",                              // Matches any URL containing "localhost"
        /^https:\/\/databites\.de/,          // Regex to match "https://databites.de"
        /^https:\/\/\w+\.databites\.de$/,         // Regex to match any subdomain of "databites.de" (e.g., "https://sub.databites.de")
        /^http:\/\/\w+\.localhost:3000$/          // Regex to match subdomains of "localhost" on port 3000 (e.g., "http://sub.localhost:3000")
    ],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    enabled: process.env.NODE_ENV === 'production' // only enabled in production
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <PostHogProvider
          apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
          options={options}
      >
          <App />
      </PostHogProvider>
  </React.StrictMode>
);

