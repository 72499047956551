import ProtectedRoute from "./ProtectedRoute";


const importAll = (r) => {
    return r.keys()
        .filter(key => !key.startsWith('./system')) // Exclude the system directory
        .map((key) => {
            const component = r(key).default;
            const pathParts = key.replace('./', '').split('/');
            const module = pathParts[0];
            const submodule = pathParts[1] || null;
            return { key, module, submodule, component };
        });
};

const components = importAll(require.context('../pages', true, /\.jsx$/));

const generateRoutes = (components) => {
    return components.map(({ key, module, submodule, component: Component }) => {
        // Generate the path
        let path = key.replace('./', '').replace('.jsx', '');

        // Replace [param] with :param for dynamic segments
        path = path.replace(/\[([^\]]+)\]/g, ':$1');

        // Convert CamelCase to kebab-case within each path segment
        path = path
            .split('/')
            .map((segment) =>
                segment.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
            )
            .join('/');

        // Prepend '/' to path
        path = '/' + path;

        // Wrap the component in ProtectedRoute, passing module and submodule
        const protectedComponent = () => (
            <ProtectedRoute module={module} submodule={submodule}>
                <Component />
            </ProtectedRoute>
        );

        return {
            path,
            component: protectedComponent,
        };
    });
};

// Generate and export routes
export const routes = generateRoutes(components);
