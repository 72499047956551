import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import axios from '../../../services/axiosInstance'; // Axios-Service importieren

const UebersichtControlling = ({ kundengruppeFilter, datum }) => {
  const [offeneStellenCount, setOffeneStellenCount] = useState(0);
  const [bewerberCount, setBewerberCount] = useState(0);
  const [durchschnittsalter, setDurchschnittsalter] = useState(0);
  const [loading, setLoading] = useState(true); // Ladezustand hinzufügen
  const [error, setError] = useState(null); // Fehlerzustand hinzufügen

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        // Datum in 'YYYY.MM' Format konvertieren für den 'jahrMonat'-Filter
        const dateObj = new Date(datum);
        if (isNaN(dateObj)) {
          throw new Error('Ungültiges Datum');
        }
        const jahr = dateObj.getFullYear();
        const monat = (dateObj.getMonth() + 1).toString().padStart(2, '0'); // Monate von 0-11
        const jahrMonat = `${jahr}.${monat}`; // Änderung von '-' zu '.'

        // Loggen des generierten 'jahrMonat'
        console.log('Generierter jahrMonat:', jahrMonat);

        // Parallel API-Aufrufe
        const [offeneStellenResponse, bewerberCountResponse, bewerberAgeResponse] = await Promise.all([
          axios.get('/recruiting/dispolisten', {
            params: {
              aktiv: 'Ja',
              oeffentlich: 'Ja',
            },
          }),
          axios.get('/recruiting/bewerber', {
            params: {
              kundengruppeID: kundengruppeFilter,
            },
          }),
          axios.get('/recruiting/bewerber', {
            params: {
              jahrMonat: jahrMonat, // Verwenden des aktualisierten Formats
            },
          }),
        ]);

        // Anzahl der offenen Stellen
        const offeneStellenResults = offeneStellenResponse.data.results;
        setOffeneStellenCount(offeneStellenResults.length);

        // Anzahl der Bewerber
        const bewerberCountResults = bewerberCountResponse.data.results;
        setBewerberCount(bewerberCountResults.length);

        // Durchschnittsalter berechnen
        const bewerberAgeResults = bewerberAgeResponse.data.results;

        // Debugging: Loggen Sie die bewerberAgeResults, um zu sehen, welche Daten zurückkommen
        console.log('Bewerber-Daten für Durchschnittsalter:', bewerberAgeResults);

        // Prüfen Sie, ob das 'alter'-Feld vorhanden ist und korrekt benannt ist
        const bewerberMitAlter = bewerberAgeResults.filter(
          (bewerber) => bewerber.alter && Number(bewerber.alter) > 0
        );

        // Debugging: Loggen Sie die gefilterten Bewerber
        console.log('Bewerber mit gültigem Alter:', bewerberMitAlter);

        const summeAlter = bewerberMitAlter.reduce((sum, bewerber) => {
          const alterNum = Number(bewerber.alter);
          if (isNaN(alterNum)) {
            console.warn(`Ungültiges Alter für Bewerber ID ${bewerber.id}:`, bewerber.alter);
            return sum;
          }
          return sum + alterNum;
        }, 0);

        const durchschnitt =
          bewerberMitAlter.length > 0
            ? summeAlter / bewerberMitAlter.length
            : 0;

        setDurchschnittsalter(durchschnitt.toFixed(1)); // Auf eine Nachkommastelle runden
      } catch (err) {
        console.error('Fehler beim Abrufen der Daten', err);
        setError('Beim Laden der Daten ist ein Fehler aufgetreten.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [kundengruppeFilter, datum]);

  return (
    <Card
      sx={{
        height: '150px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <CardContent>
        <Typography variant="h6" align="center">
          Übersicht
        </Typography>
        <Divider sx={{ my: 2 }} />
        {loading ? (
          <Typography variant="body2" align="center">
            Laden...
          </Typography>
        ) : error ? (
          <Typography variant="body2" align="center" color="error">
            {error}
          </Typography>
        ) : (
          <Grid container justifyContent="space-between">
            <Grid item xs={4} textAlign="center">
              <Typography variant="body2">Anzahl offene Stellen</Typography>
              <Typography variant="h5">{offeneStellenCount}</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography variant="body2">Anzahl Bewerber</Typography>
              <Typography variant="h5">{bewerberCount}</Typography>
            </Grid>
            <Grid item xs={4} textAlign="center">
              <Typography variant="body2">Durchschnittsalter</Typography>
              <Typography variant="h5">{durchschnittsalter}</Typography>
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};

export default UebersichtControlling;
