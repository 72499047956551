import React from 'react';
import Sidebar from '../GeneralSidebar'; // Assuming Sidebar is your extracted sidebar component
import GeneralLayout from '../GeneralLayout';
import StorefrontIcon from '@mui/icons-material/Storefront';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

const SortimentLayout = ({ children }) => {
    const menuItems = [
        { text: 'Sortiment', path: '/sortiment/overview/theke', icon: <StorefrontIcon /> },
        { text: 'Kalender', path: '/sortiment/overview/kalender', icon: <CalendarMonthIcon /> },
        { text: 'Einstellung', path: '/sortiment/settings/index', icon: <SettingsIcon /> },
        { text: 'Bilder Upload', path: '/sortiment/pictures/upload', icon: <CloudUploadIcon /> },
        { text: 'Bilder View', path: '/sortiment/pictures/view', icon: <PhotoLibraryIcon /> },
    ];

    return (
        <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} menuItems={menuItems} title={'Einstellungen'} />}>
            {children}
        </GeneralLayout>
    );
};

export default SortimentLayout;
