import React, {useContext, useEffect, useState} from 'react';
import {
    Typography,
    Grid,
    CardHeader,
    Card,
    CardContent,
    Paper,
} from '@mui/material';
import Layout from '../../../components/layout/datentagebuch/datentagebuch';
import axiosInstance from '../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';

import {FilterContext} from "../../../provider/FilterProvider";
import WochentagFilter from '../../../components/filter/WochentagFilter';


import {roundToDecimals, tausenderTrennung} from "../../../utils/mathStuff";
import {Alert} from "@mui/material";
import {getAlertSeverity} from "../../../components/utility/AlertPopup";
import TAPerformanceKundenbon from "../../../components/scorecards/tiefenanalyse/TAPerformanceKundenbon";
import VariableTable from '../../../components/table/VariableTable'; // Import the VariableTable component


const datentagebuch = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
    const { filterState } = useContext(FilterContext);
    const [wochentagFilter, setWochentagFilter] = useState(null);
    const [wochentagOptions, setWochentagOptions] = useState([]);
    const activeCategory = 'Kundenbon';
  
    useEffect(() => {
      if (filterState.filiale && filterState.datum) {
        fetchData();
        fetchTableData();
      }
    }, [filterState.filiale, filterState.datum, wochentagFilter]);
  
    const fetchData = async () => {
      try {
        const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
        const startDate = endDate.subtract(4, 'month');
  
        const response = await axiosInstance.get('scorecards_month/performance-kennzahl/', {
          params: {
            startdatum: startDate.format('YYYY-MM-DD'),
            enddatum: endDate.format('YYYY-MM-DD'),
            filiale: filterState.filiale?.value,
            wochentag: wochentagFilter?.value,
          },
        });
  
        const responseUnternehmen = await axiosInstance.get(
          'scorecards_month/performance-kennzahl-unternehmen/',
          {
            params: {
              startdatum: endDate.format('YYYY-MM-DD'),
              enddatum: endDate.format('YYYY-MM-DD'),
            },
          }
        );
  
        const dataCardData = response.data.results
          .slice(-4)
          .map((item) => ({
            x: item.datum,
            Umsatz: item.scoreUmsatz,
            Kundenanzahl: item.scoreKundenanzahl,
            Kundenbon: item.scoreKundenbon,
            Leistung: item.scoreLeistung,
          }));
  
        const dataLineChart = response.data.results.map((item) => ({
          x: item.datum,
          y: item.umsatz,
        }));
  
        const kundenbonUnternehmen = responseUnternehmen.data.results[0].kundenbon;
  
        const comparisonCardData = response.data.results.slice(-1).map((item) => ({
          ...item,
          kundenbonUnternehmen, // Add the kundenbonUnternehmen to the item
        }));
  
        setComparisonCardData(comparisonCardData);
        setDataCardData(dataCardData);
      } catch (error) {
        console.error('An error occurred while fetching data:', error);
      }
    };
  
    const fetchTableData = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get('/fact/kundenstatistik/', {
          params: {
            filiale: filterState.filiale?.value,
            jahrMonat: filterState.datum?.value,
            wochentag: wochentagFilter?.value,
          },
        });
        if (Array.isArray(response.data.results)) {
          const mappedData = response.data.results.map((item) => ({
            datum: dayjs(item.datum.split(' ')[0]).format('dddd, D. MMMM YYYY'),
            umsatz: tausenderTrennung(item.umsatz, 2),
            kunden: item.kundenanzahl,
            kundenbon: tausenderTrennung(item.umsatz / item.kundenanzahl, 2),
          }));
          setTableData(mappedData);
        } else {
          console.error('No data received from the backend');
        }
      } catch (error) {
        console.error('Error while fetching table data:', error);
      } finally {
        setLoading(false);
      }
    };
  
    const fetchWochentagOptions = async () => {
      try {
        const response = await axiosInstance.get('stammdaten/unique-wochentag/');
        setWochentagOptions(response.data.map((item) => ({ label: item, value: item })));
      } catch (error) {
        console.error('Fehler beim Abrufen der Wochentag-Optionen:', error);
      }
    };
  
    useEffect(() => {
      fetchWochentagOptions();
    }, []);
  
    const handleWochentagFilterChange = (newValue) => {
      setWochentagFilter(newValue);
    };
  
    const columns = [
      { accessorKey: 'datum', header: 'Datum' },
      { accessorKey: 'umsatz', header: 'Umsatz €' },
      { accessorKey: 'kunden', header: 'Kunden' },
      { accessorKey: 'kundenbon', header: 'Kundenbon €' },
    ];
  
    return (
      <Layout>
        <Grid container justifyContent="flex-end" alignItems="stretch">
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
              {['Umsatz', 'Kundenanzahl', 'Kundenbon', 'Leistung'].map((category, index) => (
                <Grid
                  item
                  xs={12}
                  md={6}
                  lg={3}
                  key={index}
                >
                  <DataCard
                    data={dataCardData}
                    category={category}
                    sector="performance"
                    categoryData={
                      activeCategory === category
                        ? `${
                            comparisonCardData.length > 0
                              ? roundToDecimals(comparisonCardData[0].kundenbon, 2)
                              : null
                          } €`
                        : ''
                    }
                  />
                </Grid>
              ))}
            </Stack>
          </Grid>
  
          <Grid container item xs={12} md={12} mt={3} spacing={2}>  
        
  
                <Grid item xs={12} sm={12} md={12}>
                  <Paper>
                    <Card
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                        padding: '0px',
                        backgroundColor: 'rgba(255, 255, 255, 0.7)',
                        backdropFilter: 'blur(10px)',
                      }}
                    >
                      <CardHeader
                        title={
                          <Typography variant="h6" component="div">
                            Kundenbon
                          </Typography>
                        }
                      />
                      <CardContent sx={{ flexGrow: 1 }}>
                        <WochentagFilter
                          options={wochentagOptions}
                          value={wochentagFilter}
                          onChange={handleWochentagFilterChange}
                        />
                        {loading ? (
                          <div>Loading...</div>
                        ) : (
                          <VariableTable columns={columns} data={tableData} />
                        )}
                      </CardContent>
                    </Card>
                  </Paper>
                </Grid>
          </Grid>
        </Grid>
      </Layout>
    );
  };
  
  export default datentagebuch;