// components/card/scorecardsmonat/RetourCard.js

import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Typography, Box, IconButton } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleDetailsPopupRetoure from '../../popup/unternehmen/ArticleDetailsPopupRetoure';
import SalesRegionPopupRetoure from '../../popup/unternehmen/SalesRegionPopupRetoure';

export default function RetourCard({ data, warengruppe, selectedMonth }) {
  const theme = useTheme();
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupOpenRegion, setPopupOpenRegion] = useState(false);

  const handleOpenPopup = () => setPopupOpen(true);
  const handleClosePopup = () => setPopupOpen(false);
  const handleOpenRegionPopup = () => setPopupOpenRegion(true);
  const handleCloseRegionPopup = () => setPopupOpenRegion(false);

  // Berechne den minimalen und maximalen Wert mit einem 15%-Puffer
  const values = data.flatMap((d) => [
    d.retourmenge,
    d.liefermenge,
    d.verkaufsmenge,
  ]);
  const numericValues = values.filter(val => typeof val === 'number'); // Filtere nur numerische Werte
  const minY = numericValues.length > 0 ? Math.min(...numericValues) * 0.85 : 0;
  const maxY = numericValues.length > 0 ? Math.max(...numericValues) * 1.15 : 100;

  const currentMonthData = data.find((entry) => entry.name === selectedMonth);

  // Extrahiere die Retourenquoten direkt aus den Daten
  const retourQuote = currentMonthData?.retourQuote || '0.00';
  const retourQuoteA = currentMonthData?.retourQuoteA || '0.00';
  const retourQuoteBC = currentMonthData?.retourQuoteBC || '0.00';

  return (
    <Card
      sx={{
        padding: theme.spacing(2),
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '300px', // Erhöhte Mindesthöhe
      }}
    >
      <CardHeader
        title={
          <Typography
            variant="h6"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: '0.875rem',
              color: theme.palette.primary.main,
            }}
          >
            {warengruppe}
          </Typography>
        }
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton onClick={handleOpenRegionPopup}>
              <StoreIcon fontSize="small" color="action" />
            </IconButton>
            <IconButton onClick={handleOpenPopup}>
              <InventoryIcon fontSize="small" color="action" />
            </IconButton>
          </Box>
        }
        sx={{ padding: 0, mb: 2 }}
      />

      {/* Card Content */}
      <CardContent sx={{ flexGrow: 1, padding: 0 }}>
        <Box sx={{ width: '100%', height: 250 }}> {/* Erhöhte Höhe */}
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={data} margin={{ left: -50, right: 10, top: 5, bottom: 5 }}>
              <XAxis 
                dataKey="name" 
                tick={{ fontSize: '0.75rem' }} 
                stroke={theme.palette.text.primary} // Optional: X-Achsen-Linie färben
              />
              <YAxis 
                domain={[minY, maxY]} 
                tick={false} // Entfernt die Y-Achsen-Beschriftungen
                axisLine={true} // Entfernt die Y-Achsen-Linie
              />
              <Tooltip 
                formatter={(val) => val ? val.toLocaleString('de-DE') : ''} 
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                }}
              />
              {/* Linien für die Mengen */}
              <Line 
                type="monotone" 
                dataKey="retourmenge" 
                stroke={theme.palette.chart.red} 
                name="Retourmenge" 
                dot={false} // Entfernt die Punkte an den Daten
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="liefermenge" 
                stroke={theme.palette.chart.green} 
                name="Liefermenge" 
                dot={false} 
                strokeWidth={2}
              />
              <Line 
                type="monotone" 
                dataKey="verkaufsmenge" 
                stroke={theme.palette.chart.orange} 
                name="Verkaufsmenge" 
                dot={false} 
                strokeWidth={2}
              />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>

      {/* Card Actions */}
      <CardActions sx={{ paddingLeft: theme.spacing(2) }}>
        {currentMonthData ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              Retourenquote: {retourQuote}%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              RetourenquoteA: {retourQuoteA}%
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              RetourenquoteBC: {retourQuoteBC}%
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontSize: theme.typography.body2.fontSize,
              color: theme.palette.text.secondary,
              mt: theme.spacing(2),
            }}
          >
            Keine Daten für den ausgewählten Monat
          </Typography>
        )}
      </CardActions>

      {/* Popups */}
      <ArticleDetailsPopupRetoure 
        open={popupOpen} 
        onClose={handleClosePopup} 
        warengruppe={warengruppe} 
        jahrMonat={selectedMonth} 
      />
      <SalesRegionPopupRetoure 
        open={popupOpenRegion} 
        onClose={handleCloseRegionPopup} 
        warengruppe={warengruppe} 
        jahrMonat={selectedMonth} 
      />
    </Card>
  );
}
