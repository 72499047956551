import axiosInstance from "./axiosInstance";

export const fetchTeigGeschmackOptions = async () => {
    try {
        const [geschmackResponse, teigResponse] = await Promise.all([
            axiosInstance.get('/theke/geschmack/'),
            axiosInstance.get('/theke/teig/')
        ]);

        const geschmackResult = geschmackResponse.data.results || [];
        const teigResult = teigResponse.data.results || [];

        const geschmackOptions = geschmackResult.map(option => option.geschmack);
        const teigOptions = teigResult.map(option => option.teig);

        console.log('Geschmack options:', geschmackOptions);
        console.log('Teig options:', teigOptions);
        return [geschmackOptions, teigOptions];
    } catch (error) {
        console.error('Error fetching options:', error);
        return [[], []]; // Return empty arrays on error
    }
};