import React from 'react';
import {Badge, Box, Card, CardActionArea, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import BarChartIcon from '@mui/icons-material/BarChart';
import ChecklistIcon from '@mui/icons-material/Checklist';
import CalendarTodayIcon from '@mui/icons-material/Today';
import AnalyticsOutlinedIcon from '@mui/icons-material/AnalyticsOutlined';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';
import logoHalf from '../../assets/images/logo_half.png';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import TroubleshootOutlinedIcon from '@mui/icons-material/TroubleshootOutlined';
import BakeryDiningOutlinedIcon from '@mui/icons-material/BakeryDiningOutlined';
import EngineeringOutlinedIcon from '@mui/icons-material/EngineeringOutlined';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import TransferWithinAStationOutlinedIcon from '@mui/icons-material/TransferWithinAStationOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import {useAuth} from "../../provider/authProvider";
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import DataThresholdingOutlinedIcon from '@mui/icons-material/DataThresholdingOutlined';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';


const Module = () => {
    const navigate = useNavigate();
    const { user} = useAuth();

    const apps = [
        {
            icon: <CalendarTodayIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Tagesreport',
            path: '/tagesreport/overview/index',
            category: 'Analytics'
        },
        {
            icon: <AnalyticsOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Scorecards',
            path: '/scorecards/overview/index',
            category: 'Analytics'
        },
        {
            icon: <BarChartIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Unternehmen',
            path: '/unternehmen/overview/cockpit',
            category: 'Analytics'
        },
        {
            icon: <TimelineOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Warensteuerung',
            path: '/warensteuerung/overview/index',
            category: 'Analytics'
        },
        {
            icon: <StoreOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Filialsteuerung',
            path: '/filialsteuerung/overview/index',
            category: 'Analytics'
        },
        {
            icon: <ViewTimelineOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Frequenzbericht',
            path: '/frequenzbericht/overview/index',
            category: 'Analytics'
        },
        {
            icon: <SummarizeOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Dokumentation',
            path: '/dokumentation/overview/index',
            category: 'Quality'
        },

        {
            icon: <ChecklistIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Filialcheck',
            path: '/filialcheck/overview/index',
            category: 'Quality'
        },
        {
            icon: <QuickreplyOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Qualitaet',
            path: '/qualitaet/overview/index',
            category: 'Quality'
        },
        {
            icon: <BakeryDiningOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Tagesplanner',
            path: '/tagesplanner/overview/timetable',
            category: 'Quality'
        },
        {
            icon: <DataThresholdingOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'DatenTagebuch',
            path: '/datentagebuch/overview/index',
            category: 'Quality'
        },
        {
            icon: <AnnouncementOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'FilialFeed',
            path: '/filialfeed/overview/index',
            category: 'Quality'
        },
        {
            icon: <TroubleshootOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Controlling',
            path: '/controlling/overview/index',
            category: 'Personal'
        },

        {
            icon: <StorefrontOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Sortiment',
            path: '/sortiment/overview/theke',
            category: 'Ware'
        },

        {
            icon: <HailOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Onboarding',
            path: '/onboarding/overview/index',
            category: 'Personal'
        },
        {
            icon: <StarBorderOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Talents',
            path: '/talents/overview/index',
            category: 'Personal'
        },
        {
            icon: <PersonSearchOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Recruiting',
            path: '/recruiting/overview/index',
            category: 'Personal'
        },
        {
            icon: <EngineeringOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Entwicklung',
            path: '/onboarding/overview',
            category: 'Personal'
        },

        {icon: <EngineeringOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>, label: 'PE', path: '/sortiment/theke', category: 'Personal'},
        {
            icon: <WorkspacesOutlinedIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Projekt',
            path: '/projekt/overview/index',
            category: 'Admin'
        },
        {
            icon: <SettingsIcon fontSize="large" sx={{color: 'primary.main'}}/>,
            label: 'Settings',
            path: '/settings/rechte/nutzer-verwalten',
            category: 'Admin'
        },

    ];

    const categorizedApps = apps.reduce((acc, app) => {
        const {category, label} = app;
        if (user?.role?.module_permissions) {
            user.role.module_permissions.forEach((module) => {
                if (module?.name && label) {
                    if (module.name.toLowerCase() === label.toLowerCase()) {
                        if (!acc[category]) acc[category] = [];
                        acc[category].push(app);
                    }
                }
            });
        }
        return acc;
    }, {});

    // console.log("permissions.module_permissions", categorizedApps)

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'flex-start', // Anstatt 'center' verwende 'flex-start'
                minHeight: '100vh', // Statt 'height: 100vh', um sicherzustellen, dass der gesamte Inhalt sichtbar bleibt
                width: '100%',
                padding: 2, // Ein bisschen Padding hinzufügen, um Ränder zu vermeiden
            }}
        >
            {/* Füge das Bild über dem Titel hinzu */}
            <Box component="img" src={logoHalf} alt="Logo" sx={{mb: 2, maxHeight: 100, width: 'auto'}}/>

            <Typography variant="h1" gutterBottom>
                Databites Insights
            </Typography>

            {/* Zeige jede Kategorie mit ihren Apps an */}
            {Object.keys(categorizedApps).map((category) => (
                <Box key={category} sx={{width: '100%', mt: 4}}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        sx={{textAlign: 'center'}} // Überschrift zentrieren
                    >
                        {category}
                    </Typography>
                    <Grid container spacing={4} justifyContent="center">
                        {categorizedApps[category].map((app) => (
                            <Grid item key={app.label}>
                                <Card sx={{width: 150, height: 150}}>
                                    <CardActionArea
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%',
                                        }}
                                        onClick={() => navigate(app.path)}
                                    >
                                        <Badge badgeContent={app.badge} sx={{color: 'primary.main'}} invisible={!app.badge}>
                                            {app.icon}
                                        </Badge>
                                        <Typography variant="subtitle1" sx={{marginTop: 1}}>
                                            {app.label}
                                        </Typography>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Box>

    );
};

export default Module;
