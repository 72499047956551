import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { Paper, Typography, useTheme } from '@mui/material';

const StackedBarChart = ({ data, labels, title, xLabel, yLabel }) => {
  const theme = useTheme();

  // Define colors from the theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.primary.light,
    theme.palette.primary.dark,
    theme.palette.secondary.main,
    theme.palette.secondary.light,
    theme.palette.secondary.dark,
    theme.palette.warning.main,
    theme.palette.error.main,
  ];

  // Convert the data to the correct format for the BarChart component
  const dataset = labels.map((label, index) => ({
    label,
    ...data.reduce((acc, curr) => {
      acc[curr.label] = curr.data[index];
      return acc;
    }, {})
  }));

  // Add series information
  const series = data.map((dataset, index) => ({
    dataKey: dataset.label,
    stack: 'stack1', // All data should be stacked
    label: dataset.label,
    color: COLORS[index % COLORS.length], // Apply colors from the theme
  }));

  return (
      <BarChart
        dataset={dataset}
        series={series}
        xAxis={[{ scaleType: 'band', dataKey: 'label', label: xLabel, show: true }]} // Use 'label' for the x-axis
        yAxis={[{ label: yLabel, show: true }]} // Y-axis label
        slotProps={{ legend: { hidden: true } }} // Show the legend
        width={600}
        height={300}
        margin={{ top: 20, bottom: 30, left: 40, right: 20 }} // Adjust margins
        isAnimationActive={false} // Disable animation
      />
  );
};

export default StackedBarChart;
