import React, { useState, useEffect } from 'react';
import {
  Box,
  Switch,
  FormControlLabel,
  CircularProgress,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
} from '@mui/material';
import axios from '../../../services/axiosInstance';
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Pfad anpassen
import BarChartIcon from '@mui/icons-material/BarChart'; // Beispiel-Icon
import { tausenderTrennung } from '../../../utils/mathStuff'; // Pfad zu deinem Utils-File anpassen
import Layout from '../../../components/layout/Warensteuerung/warensteuerung'; // Passe den Pfad entsprechend an
import DataCard from '../../../components/card/TagesreportDataCard'; // Passe den Pfad entsprechend an
import BigPieChartCard from '../../../components/card/BigPieChartCard'; // Importiere das PieChart
import BigBarChartHorizontalCard from '../../../components/card/BigBarChartHorizontalCard'; // Neue Komponente
import BrushBarChart from '../../../components/charts/BrushBarChart'; // Importiere deine GroupedBarChart-Komponente
import LineChartComponent from '../../../components/charts/LineChartRecharts';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close'; // Icon zum Schließen des Dialogs




const Report = () => {
  const [viewMode, setViewMode] = useState('month'); // 'month' oder 'week'
  const [data, setData] = useState([]);
  const [dataMap, setDataMap] = useState({});
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState([]);



  // Funktion, um den Toggle-Schalter zu handhaben
  const handleToggleChange = () => {
    setViewMode((prevMode) => (prevMode === 'month' ? 'week' : 'month'));
  };

  // Funktion, um die letzten 6 abgeschlossenen Zeiträume zu erhalten
  const getLastSixPeriods = () => {
    // Extrahiere alle Perioden (Monate oder Wochen) aus den Daten
    let periods = [
      ...new Set(
        data
          .map((item) => (viewMode === 'month' ? item.month : item.week))
          .filter((period) => period !== undefined)
      )
    ];

    // Sortiere die Perioden
    periods.sort((a, b) => new Date(a) - new Date(b));

    // Erhalte die aktuelle Periode im gleichen Format wie in den Daten
    let currentPeriod;
    if (viewMode === 'month') {
      const currentDate = new Date();
      currentDate.setDate(1); // Erster Tag des aktuellen Monats
      currentPeriod = currentDate.toISOString();
    } else {
      // Woche: Finde den Montag der aktuellen Woche
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay(); // 0 (Sonntag) bis 6 (Samstag)
      const distanceToMonday = (dayOfWeek + 6) % 7; // Berechnet die Tage zurück zum Montag
      const monday = new Date(currentDate);
      monday.setDate(currentDate.getDate() - distanceToMonday);
      monday.setHours(0, 0, 0, 0);
      currentPeriod = monday.toISOString();
    }

    // Entferne die aktuelle Periode
    const completedPeriods = periods.filter((period) => {
      const periodDate = new Date(period);
      const currentPeriodDate = new Date(currentPeriod);
      return periodDate < currentPeriodDate;
    });

    // Nimm die letzten 6 abgeschlossenen Perioden
    return completedPeriods.slice(-6);
  };

  // Funktion, um die Daten aus der entsprechenden API zu laden
  const fetchData = async () => {
    setLoading(true);

    // Bestimme das Start- und Enddatum basierend auf den Perioden
    const periods = getLastSixPeriods();

    let startDate, endDate;
    if (periods.length > 0) {
      startDate = periods[0].split('T')[0]; // Erster Tag
      endDate = periods[periods.length - 1].split('T')[0]; // Letzter Tag
    } else {
      // Falls keine Perioden vorhanden sind, setze Standardwerte
      const today = new Date();
      startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1)
        .toISOString()
        .split('T')[0];
      endDate = today.toISOString().split('T')[0];
    }

    const apiUrl =
      viewMode === 'month'
        ? '/fact/aggregated/by-month-warengruppe/'
        : '/fact/aggregated/by-week-warengruppe/';

    try {
      // Daten für das aktuelle Jahr abrufen
      const responseCurrent = await axios.get(apiUrl, {
        params: {
          startDate,
          endDate
        }
      });

      setData(responseCurrent.data);
    } catch (error) {
      console.error('Fehler beim Laden der Daten:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Daten abrufen, wenn der View-Modus sich ändert
  }, [viewMode]);

  useEffect(() => {
    // Erstelle eine Datenmappe für schnellen Zugriff
    const map = {};
    data.forEach((item) => {
      const warengruppe = item.warengruppennummer__warengruppe;
      const period = viewMode === 'month' ? item.month : item.week;

      // Füge das Jahr zum Periodenschlüssel hinzu, um Perioden aus verschiedenen Jahren zu unterscheiden
      const periodKey = `${new Date(period).getFullYear()}-${period}`;

      if (!map[warengruppe]) {
        map[warengruppe] = {};
      }

      map[warengruppe][periodKey] = item;
    });

    setDataMap(map);
  }, [data, viewMode]);

  // Berechnung der Top 5 Warengruppen basierend auf dem Umsatz des aktuellen Jahres
  const currentYear = new Date().getFullYear();

  const topFiveGroups = Object.values(
    data.reduce((acc, item) => {
      const warengruppe = item.warengruppennummer__warengruppe;
      const period = viewMode === 'month' ? item.month : item.week;
      const itemYear = new Date(period).getFullYear();

      // Nur Daten des aktuellen Jahres berücksichtigen
      if (itemYear !== currentYear) return acc;

      if (!acc[warengruppe]) {
        acc[warengruppe] = {
          warengruppe,
          umsatz: {},
          retourquote: {},
          totalUmsatz: 0,
          totalUmsatzLastYear: item.total_umsatzVJ || 0 // Vorjahresumsatz aus der API nutzen
        };
      }

      const retourquote =
        item.total_retourwert / (item.total_lieferwert + item.total_korrekturwert) || 0;
      acc[warengruppe].umsatz[period] = item.total_umsatz || 0;
      acc[warengruppe].retourquote[period] = retourquote * 100; // In Prozent umwandeln
      acc[warengruppe].totalUmsatz += item.total_umsatz || 0; // Summe für die Top 5
      return acc;
    }, {})
  )
    .sort((a, b) => b.totalUmsatz - a.totalUmsatz) // Sortiere nach Umsatz
    .slice(0, 5); // Nimm die Top 5

  // Anpassung der Umsatzveränderung
  const getPercentageChange = (current, lastYear) => {
    if (!lastYear || lastYear === 0) return 0;
    return ((current - lastYear) / lastYear) * 100;
  };

  // Daten für das BarChart vorbereiten
  const barChartData = topFiveGroups.map((row) => {
    const periods = getLastSixPeriods();
    const lastPeriod = periods[periods.length - 1];

    const currentScore = row.umsatz[lastPeriod] || 0;
    const previousScore = row.totalUmsatzLastYear || 0;

    return {
      x: row.warengruppe,
      currentScore,
      previousScore
    };
  });

  // Daten für das Pie-Chart vorbereiten
  const pieChartData = topFiveGroups.map((row) => ({
    Warengruppe: row.warengruppe,
    Verkaufsmenge: row.totalUmsatz
  }));

  // Berechnung der Retourenquote für die Top 5 Warengruppen basierend auf dem letzten Zeitraum
  const retourenQuoteData = topFiveGroups.map((row) => {
    const periods = getLastSixPeriods();
    const lastPeriod = periods[periods.length - 1];
    const retourquote = row.retourquote[lastPeriod] || 0;

    return {
      Warengruppe: row.warengruppe,
      Retourenquote: retourquote.toFixed(2) // Rückgabe in Prozentform mit 2 Dezimalstellen
    };
  });

  // Funktion, um den Dialog zu öffnen und die ausgewählten Daten anzuzeigen
  const handleOpenDialog = (warengruppe, data) => {
    const periods = getLastSixPeriods();

      // Formatierung der Daten für das LineChartComponent
      const formattedData = [
        {
          id: warengruppe,
          data: periods.map((period, index) => ({
            x: period,
            y: data[period] || 0,
          })),
        },
      ];
  
      setSelectedGroupData(formattedData);
      setOpen(true);
    };
  
    // Dialog schließen
    const handleCloseDialog = () => {
      setOpen(false);
      setSelectedGroupData([]);
    };

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2
          }}
        >
          <PageHeader
            title="Report Warengruppenentwicklung"
            subtitle="Dieser Bericht zeigt die Umsatzentwicklung und Retouren der Warengruppen im zeitlichen Verlauf."
            Icon={BarChartIcon}
          />
        </Box>

        {/* Toggle für Monat/Woche */}
        <FormControlLabel
          control={<Switch checked={viewMode === 'week'} onChange={handleToggleChange} />}
          label={viewMode === 'week' ? 'Woche' : 'Monat'}
        />

        {/* Data Cards für Top 5 Warengruppen */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            topFiveGroups.map((row, index) => {
              // Berechnung des Umsatzes des letzten Zeitraums und des Vorjahres
              const periods = getLastSixPeriods();
              const lastPeriod = periods[periods.length - 1];

              const revenueCurrent = row.umsatz[lastPeriod] || 0;
              const revenueLastYear = row.totalUmsatzLastYear || 0;

              const percentageChange = getPercentageChange(revenueCurrent, revenueLastYear);

              // Sparkline-Daten für die letzten 6 Perioden
              const sparklineData = periods.map((period) => row.umsatz[period] || 0);

              return (
                <Grid item xs={12} md={6} key={index}>
                  <DataCard
                    chartData={sparklineData}
                    category={row.warengruppe}
                    value={`${tausenderTrennung(revenueCurrent, 2)} €`}
                    subHeader={`${
                      percentageChange >= 0 ? '+' : ''
                    }${percentageChange.toFixed(2)}%`}
                    numDataPoints={6}
                  />
                </Grid>
              );
            })
          )}
        </Grid>

        {/* Grid für nebeneinander angeordnete Karten */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* Bar Chart für Top 5 Warengruppen */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Karte nimmt die volle Höhe ein
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)'
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
Betrachtung des Warengruppenumsatzes im Vergleich zum Vorjahr                  </Typography>
                }
                sx={{ paddingBottom: 0 }} // Verhindert zu viel Leerraum zwischen Header und Diagramm
              />
              <CardContent>
                <BrushBarChart data={barChartData} />
              </CardContent>
            </Card>
          </Grid>

          {/* Pie Chart für Top 5 Warengruppen */}
          <Grid item xs={12} md={6}>
            <BigPieChartCard
              data={pieChartData}
              title="Verteilung der Verkaufsmengen auf die Warengruppen"
            />
          </Grid>
        </Grid>

        <Grid container spacing={2} sx={{ mt: 2, mb: 2 }}>
        {/* Bar Chart für Retourenquote der Top 5 Warengruppen */}
  <Grid item xs={12}>
    <BigBarChartHorizontalCard
      title="Retourenquote der Warengruppen"
      labels={retourenQuoteData.map((item) => item.Warengruppe)}
      data={retourenQuoteData.map((item) => parseFloat(item.Retourenquote))}
      hoch={300} // Optionale Höhe des Diagramms
    />
  </Grid>

  {/* Tabelle mit den Umsätzen und Retourquoten */}
  <Grid item xs={12}>
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        padding: '0px',
        backgroundColor: 'rgba(255, 255, 255, 0.7)',
        backdropFilter: 'blur(10px)',
      }}
    >
      <CardHeader
        title={
          <Typography variant="h6" component="div">
            Umsätze und Retourquoten der Warengruppen im Zeitverlauf
          </Typography>
        }
      />
      <CardContent sx={{ flexGrow: 1 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Warengruppe</TableCell>
                <TableCell>Kennzahl</TableCell>
                {getLastSixPeriods().map((period, index) => (
                  <TableCell key={index}>
                    {viewMode === 'month'
                      ? `Monat ${new Date(period).toLocaleDateString('de-DE', {
                          month: 'long',
                          year: 'numeric',
                        })}`
                      : `Woche ${new Date(period).toLocaleDateString('de-DE')}`}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {topFiveGroups.map((row, index) => (
                <React.Fragment key={index}>
                  {/* Umsatzzeile */}
                  <TableRow>
                    <TableCell rowSpan={2}>
                      <Box display="flex" alignItems="center">
                        {row.warengruppe}
                        <IconButton
                          aria-label="Details anzeigen"
                          onClick={() => handleOpenDialog(row.warengruppe, row.umsatz)}
                        >
                       <SearchIcon />          
                       </IconButton>
                      </Box>
                    </TableCell>
                    <TableCell>Umsatz</TableCell>
                    {getLastSixPeriods().map((period, index) => (
                      <TableCell key={index}>
                        {tausenderTrennung(row.umsatz[period] || 0, 2)} €
                      </TableCell>
                    ))}
                  </TableRow>
                  {/* Retourquote-Zeile */}
                  <TableRow>
  <TableCell>Retourquote</TableCell>
  {getLastSixPeriods().map((period, index) => (
    <TableCell key={index}>
      {row.retourquote[period] !== undefined
        ? row.retourquote[period].toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
        : '0,00'} %
    </TableCell>
  ))}
</TableRow>

                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        )}
      </CardContent>
    </Card>
  </Grid>
</Grid>
{/* Dialog für das Liniendiagramm */}
<Dialog open={open} onClose={handleCloseDialog} maxWidth="md" fullWidth>
        <DialogTitle>
          Details zur Warengruppe
          <IconButton
            aria-label="close"
            onClick={handleCloseDialog}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {selectedGroupData.length > 0 ? (
            <LineChartComponent data={selectedGroupData} />
          ) : (
            <Typography>Keine Daten verfügbar</Typography>
          )}
        </DialogContent>
      </Dialog>

      </Box>
    </Layout>
  );
};

export default Report;
