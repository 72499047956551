import {useDrop} from "react-dnd";
import {Box, Typography} from "@mui/material";
import {useState} from 'react';

const DropZone = ({onDrop, artikel, }) => {
    const [, forceUpdate] = useState({});

    // Generate consistent fake data for Wareneinsatz and Retoure durchschnitt
    const fakeWareneinsatz = artikel 
        ? (10 + (artikel.artikelnummer % 10) + (artikel.umsatz % 123) / 100).toFixed(2)
        : null; // Between 10% and 20%

    // Initialize sortimentType if it doesn't exist
    if (artikel && !artikel.sortimentType) {
        artikel.sortimentType = 'standard';
    }
    const sortimentsType = artikel?.sortimentType || 'standard';

    // Determine the stripe color based on sortimentsType
    const stripeColor = sortimentsType === 'standard' ? 'blue' : 'orange';

    if (artikel) {
        // console.log("artikel in zone", artikel)
    }
    // Determine if drag-and-drop functionality should be enabled
    const [{isOver, canDrop}, drop] = onDrop ? useDrop(() => ({
        accept: 'text',
        drop: (item) => {
            onDrop(item);
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    })) : [{}, null]; // No drop logic if onDrop is not provided

    // const displayedText = artikel ? artikel.artikelbezeichnung.replace(/^(\d+\s+)?(\w)(.*)/, (_, prefix, firstChar, rest) => {
    //     const processedText = (prefix || '') + firstChar.toUpperCase() + rest.toLowerCase();
    //     return processedText.replace(/\b\w/g, c => c.toUpperCase());
    // }) : '';

    const displayedText = artikel ? artikel.artikelbezeichnung : '';

    const changeSortimentType = () => {
        if (artikel) {
            artikel.sortimentType = artikel.sortimentType === 'standard' ? 'wechsel' : 'standard';
            forceUpdate({});
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                minWidth: 190,
                width: 190,
                height: 230,
                padding: 1.5,
                borderRadius: 4,
                cursor: canDrop? 'pointer' : 'default',
                border: isOver? '2px dashed #ccc' : 'none',
                transition: 'background-color 0.2s ease-in-out',
                backgroundColor: 'background.paper',
                overflow: 'hidden',
                position: 'relative'
            }}
        >
            {artikel && (
                <>
                    <Box
                        sx={{
                            width: '5px',
                            backgroundColor: stripeColor,
                            borderTopLeftRadius: 4,
                            borderBottomLeftRadius: 4,
                            mr: 2,
                        }}
                    />
                    <Box
                        onClick={changeSortimentType}
                        sx={{
                            position: 'absolute',
                            top: 8,
                            right: 12,
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            cursor: 'pointer',
                            background: 'linear-gradient(90deg, blue 48%, transparent 48%, transparent 52%, orange 52%)',
                            '&:hover': {
                                opacity: 0.8
                            }
                        }}
                    />
                </>
            )}
            <Box
                ref={onDrop ? drop : null}
                sx={{
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    alignItems: 'stretch',
                }}
            >
                {artikel ? (
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                                WebkitHyphens: 'auto',
                                msHyphens: 'auto',
                                marginBottom: 1,
                                height: '4.5em',
                            }}
                        >
                            {displayedText}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>Wareneinsatz:</Typography>
                                <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>{fakeWareneinsatz}%</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>Retoure:</Typography>
                                <Typography sx={{color: 'text.main', fontSize: '0.8rem'}}>{artikel.retoure}%</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>Geschmack:</Typography>
                                <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>-</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>Teig:</Typography>
                                <Typography sx={{color: 'text.secondary', fontSize: '0.8rem'}}>-</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'success.main', fontSize: '0.8rem'}}>Umsatz:</Typography>
                                <Typography sx={{color: 'success.main', fontSize: '0.8rem'}}>{artikel.umsatz} €</Typography>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <Typography color="text.secondary"></Typography>
                )}
            </Box>
        </Box>
    );
};

export default DropZone;
