import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import VariableTable from '../table/VariableTable';
import axiosInstance from '../../services/axiosInstance';
import { formatPercentage, formatPercentageWithoutMultiplier } from "../../utils/mathStuff";
import CloseIcon from '@mui/icons-material/Close';

const TagesreportMainArtikelPopup = ({ open, handleClose, warengruppe, filiale, datum }) => {
    const [articleData, setArticleData] = useState([]);
    const [loading, setLoading] = useState(false);

    const articleColumns = [
        { id: 'Artikelname', accessorKey: 'Artikelname', header: 'Artikelname' },
        { id: 'Verkaufsmenge', accessorKey: 'Verkaufsmenge', header: 'Verkaufsmenge' },
        { id: 'Retourmenge', accessorKey: 'Retourmenge', header: 'Retourmenge' },
        { id: 'Retourquote', accessorKey: 'Retourquote', header: 'Retourquote' },
    ];

    useEffect(() => {
        if (open && warengruppe) {
            fetchArticleData();
        }
    }, [open, warengruppe]);

    const fetchArticleData = async () => {
        setLoading(true);
        try {
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    warengruppenbezeichnung: warengruppe,
                    filiale: filiale,
                    datum: datum,
                }
            });
            
            if (Array.isArray(response.data.results)) {
                const aggregatedData = response.data.results.map(item => ({
                    Artikelname: item.artikel,
                    Verkaufsmenge: parseFloat(item.verkaufsmenge) || 0,
                    Retourmenge: parseFloat(item.retourmenge) || 0,
                    Retourquote: `${formatPercentage((parseFloat(item.retourmenge) || 0) / (parseFloat(item.verkaufsmenge) + parseFloat(item.retourmenge) || 1))} %`
                }));

                setArticleData(aggregatedData);
            } else {
                console.error('Unexpected response structure', response.data);
            }
        } catch (error) {
            console.error('Error fetching article data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth>
            <DialogTitle>Details für Warengruppe {warengruppe}

            <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers={true} sx={{ maxHeight: '70vh' }}>
                <VariableTable
                    data={articleData}
                    columns={articleColumns}
                />
            </DialogContent>
            
        </Dialog>
    );
};

export default TagesreportMainArtikelPopup;
