import {useEffect, useState} from "react";
import {Typography, Paper, TextField, Box} from "@mui/material";
import DraggableText from "./DraggableText";

const ArtikelListe = ({artikel}) => {
    const [displayedArtikel, setDisplayedArtikel] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const filteredArtikel = artikel.filter(item =>
            item.artikelbezeichnung.toLowerCase().includes(searchTerm.toLowerCase())
        );
        const sortedArtikel = [...filteredArtikel].sort((a, b) => b.umsatz - a.umsatz);
        setDisplayedArtikel(sortedArtikel);
    }, [artikel, searchTerm]);

    return (
        <Paper elevation={3} sx={{ padding: 2, margin: 0, width: '100%' }}>
            {/*<Typography variant="h6" gutterBottom>Artikel</Typography>*/}
            <TextField
                variant="outlined"
                placeholder="Artikel suchen"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 1, width: '300px', '& .MuiOutlinedInput-root': { height: '32px' } }}
                size="small"
            />
            {displayedArtikel.length > 0 ? (
                <Box sx={{ 
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))',
                    gap: 0.5,
                    alignItems: 'start',
                }}>
                    {displayedArtikel.map((item, index) => (
                        <DraggableText
                            key={`${item.artikelbezeichnung || item}-${index}`}
                            artikel={item}
                        />
                    ))}
                </Box>
            ) : (
                <Typography variant="body2" align="center">
                    keine Artikel gefunden
                </Typography>
            )}
        </Paper>
    );
};

export default ArtikelListe;
