import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Tabs,
  Tab,
  Box,
  Typography,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import dayjs from 'dayjs';

const FilialDetailDialog = ({
  open,
  onClose,
  filiale,
  selectedTab,
  handleTabChange,
  handleImageClick,
}) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {filiale && (
        <>
          <DialogTitle>
            Details zu {filiale?.filiale} ({filiale?.filialnummer})
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="Check Tabs"
            >
              {filiale.checks.map((check, index) => (
                <Tab key={index} label={check.checkName} />
              ))}
            </Tabs>
            {filiale.checks.map((check, index) => (
              <div
                role="tabpanel"
                hidden={selectedTab !== index}
                key={index}
              >
                {selectedTab === index && (
                  <Box sx={{ p: 3 }}>
                    {/* Anzeigen der Kategorien und Checkpoints */}
                    {(() => {
                      // Alle einzigartigen Daten sammeln und sortieren
                      const allDatesSet = new Set();
                      Object.values(check.categories).forEach(category => {
                        Object.values(category.checkpoints).forEach(checkpoint => {
                          checkpoint.ratings.forEach(rating => {
                            const dateStr = dayjs(rating.datum).format('DD.MM.YYYY');
                            allDatesSet.add(dateStr);
                          });
                        });
                      });
                      const allDates = Array.from(allDatesSet).sort((a, b) => dayjs(a, 'DD.MM.YYYY') - dayjs(b, 'DD.MM.YYYY'));

                      // Bestimmen der maximalen Anzahl von Bewertungen pro Datum
                      const dateMaxOccurrences = {};
                      allDates.forEach(dateStr => {
                        let maxOccurrences = 0;
                        Object.values(check.categories).forEach(category => {
                          Object.values(category.checkpoints).forEach(checkpoint => {
                            const ratingsOnDate = checkpoint.ratings.filter(rating => dayjs(rating.datum).format('DD.MM.YYYY') === dateStr);
                            if (ratingsOnDate.length > maxOccurrences) {
                              maxOccurrences = ratingsOnDate.length;
                            }
                          });
                        });
                        dateMaxOccurrences[dateStr] = maxOccurrences;
                      });

                      // Erstellen der Spaltenköpfe
                      const columnKeys = [];
                      allDates.forEach(dateStr => {
                        const occurrences = dateMaxOccurrences[dateStr];
                        for (let i = 1; i <= occurrences; i++) {
                          columnKeys.push(`${dateStr} (${i})`);
                        }
                      });

                      return Object.values(check.categories).map((category, catIndex) => (
                        <Box key={catIndex} sx={{ mb: 2 }}>
                          <Typography variant="h6">{category.categoryName}</Typography>
                          <TableContainer component={Paper}>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Checkpoint</TableCell>
                                  {columnKeys.map((columnKey) => (
                                    <TableCell key={columnKey}>{columnKey}</TableCell>
                                  ))}
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {Object.values(category.checkpoints).map((checkpoint, cpIndex) => {
                                  // Mapping von Datum zu Bewertungen für jeden Checkpoint
                                  const dateToRatings = {};
                                  checkpoint.ratings.forEach(rating => {
                                    const dateStr = dayjs(rating.datum).format('DD.MM.YYYY');
                                    if (!dateToRatings[dateStr]) {
                                      dateToRatings[dateStr] = [];
                                    }
                                    dateToRatings[dateStr].push(rating);
                                  });

                                  return (
                                    <TableRow key={cpIndex}>
                                      <TableCell>{checkpoint.checkpointName}</TableCell>
                                      {columnKeys.map((columnKey) => {
                                        const match = columnKey.match(/(\d{2}\.\d{2}\.\d{4}) \((\d+)\)/);
                                        const dateStr = match[1];
                                        const occurrence = parseInt(match[2], 10) - 1; // Nullbasiert
                                        const ratingsOnDate = dateToRatings[dateStr] || [];
                                        const rating = ratingsOnDate[occurrence];

                                        return (
                                          <TableCell key={columnKey}>
                                            {rating ? (
                                              <div>
                                                <Typography>{rating.value}</Typography>
                                                {rating.comment && (
                                                  <Typography variant="body2" color="textSecondary">
                                                    {rating.comment}
                                                  </Typography>
                                                )}
                                                {rating.image_id && (
                                                  <IconButton
                                                    onClick={() => handleImageClick(rating.image_id)}
                                                    color="primary"
                                                  >
                                                    <PhotoLibraryIcon />
                                                  </IconButton>
                                                )}
                                              </div>
                                            ) : (
                                              <Typography>-</Typography>
                                            )}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  );
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      ));
                    })()}
                  </Box>
                )}
              </div>
            ))}
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};

export default FilialDetailDialog;
