import React, { useState } from 'react';
import { Card, CardHeader, CardContent, CardActions, Typography, Box, IconButton } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { useTheme } from '@mui/material/styles';
import StoreIcon from '@mui/icons-material/Store';
import InventoryIcon from '@mui/icons-material/Inventory';
import ArticleDetailsPopup from '../../popup/unternehmen/ArticleDetailsPopup';
import SalesRegionPopup from '../../popup/unternehmen/SalesRegionPopup';

export default function DataCard({ chartData, value, selectedMonth, articleCount, soldArticleCount, warengruppe }) {
  const theme = useTheme();
  const [popupOpen, setPopupOpen] = useState(false);
  const [popupOpenRegion, setPopupOpenRegion] = useState(false);

  const handleOpenPopup = () => setPopupOpen(true);
  const handleClosePopup = () => setPopupOpen(false);
  const handleOpenRegionPopup = () => setPopupOpenRegion(true);
  const handleCloseRegionPopup = () => setPopupOpenRegion(false);

  // Berechne den minimalen und maximalen Wert mit einem 15%-Puffer
  const values = chartData.flatMap((data) => [data.value, data.previousValue]);
  const minY = Math.min(...values) * 0.85;
  const maxY = Math.max(...values) * 1.15;

  const currentMonthData = chartData.find((entry) => entry.name === selectedMonth);

  // Berechnung der Differenz % zwischen Verkaufsmenge und Verkaufsmenge VJ
  let differenzProzent = 'N/A';
  let differenzProzentValue = null;

  if (currentMonthData?.previousValue && currentMonthData.previousValue !== 0) {
    differenzProzentValue = ((currentMonthData.value - currentMonthData.previousValue) / currentMonthData.previousValue) * 100;
    differenzProzent = differenzProzentValue.toFixed(2) + '%';
  }

  return (
    <Card sx={{
      padding: theme.spacing(2),
      backgroundColor: 'rgba(255, 255, 255, 0.7)',
      backdropFilter: 'blur(10px)',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      minHeight: '230px',
    }}>
      <CardHeader
        title={
          <Typography
            variant="h6"
            sx={{
              fontWeight: theme.typography.fontWeightMedium,
              fontSize: '0.875rem',
              color: theme.palette.primary.main,
            }}
          >
            {value}
          </Typography>
        }
        action={
          <Box sx={{ display: 'flex', gap: 1 }}>
            <IconButton onClick={handleOpenRegionPopup}>
              <StoreIcon fontSize="small" color="action" />
            </IconButton>
            <IconButton onClick={handleOpenPopup}>
              <InventoryIcon fontSize="small" color="action" />
            </IconButton>
          </Box>
        }
        sx={{ padding: 0, mb: 2 }}
      />

      {/* Card Content */}
      <CardContent sx={{ flexGrow: 1, padding: 0 }}>
        <Box sx={{ width: '100%', height: 250 }}>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart data={chartData} margin={{ left: -50, right: 10, top: 5, bottom: 5 }}>
              <XAxis dataKey="name" tick={{ fontSize: '0.75rem' }} />
              <YAxis domain={[minY, maxY]} tick={false} />
              <Tooltip formatter={(val) => val ? val.toLocaleString('de-DE') : ''} />
              <Line type="monotone" dataKey="value" stroke={theme.palette.primary.main} name="Aktuelles Jahr" dot />
              <Line type="monotone" dataKey="previousValue" stroke={theme.palette.chart.pink} name="Vorjahr" dot />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>

      {/* Card Actions */}
      <CardActions sx={{ paddingLeft: theme.spacing(2) }}>
        {currentMonthData ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(2),
              }}
            >
              Verkaufsmenge: {currentMonthData.value.toLocaleString('de-DE')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              Verkaufsmenge VJ: {currentMonthData.previousValue.toLocaleString('de-DE')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Differenz %: {differenzProzent}
              {differenzProzentValue != null && differenzProzentValue !== 0 && (
                <Box sx={{ ml: 1 }}>
                  <Box
                    sx={{
                      color:
                        differenzProzentValue > 0
                          ? theme.palette.success.main
                          : theme.palette.error.main,
                    }}
                  >
                    {differenzProzentValue > 0 ? '↑' : '↓'}
                  </Box>
                </Box>
              )}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              Anzahl Artikel im Unternehmens A-Bereich: {articleCount.toLocaleString('de-DE')}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                fontSize: theme.typography.body2.fontSize,
                color: theme.palette.text.secondary,
                mt: theme.spacing(1),
              }}
            >
              Anzahl verkaufter Artikel: {soldArticleCount.toLocaleString('de-DE')}
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{
              fontSize: theme.typography.body2.fontSize,
              color: theme.palette.text.secondary,
              mt: theme.spacing(2),
            }}
          >
            Keine Daten für den ausgewählten Monat
          </Typography>
        )}
      </CardActions>

      {/* Popups */}
      <ArticleDetailsPopup 
        open={popupOpen} 
        onClose={handleClosePopup} 
        warengruppe={value} 
        jahrMonat={selectedMonth} 
      />
      <SalesRegionPopup 
        open={popupOpenRegion} 
        onClose={handleCloseRegionPopup} 
        warengruppe={value} 
        jahrMonat={selectedMonth} 
      />
    </Card>
  );
}
