// KundenstatistikTabelle.jsx
import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Box,
  IconButton,
  Tooltip,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Icon für PDF
import GridOnIcon from '@mui/icons-material/GridOn'; // Icon für Excel
import { formatQuantity, formatValue, formatPercentageMitZeichen } from '../../utils/mathStuff';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import * as XLSX from 'xlsx'; // Importieren von xlsx
import jsPDF from 'jspdf'; // Importieren von jsPDF
import 'jspdf-autotable'; // Importieren von jspdf-autotable'

const KundenstatistikTabelle = ({
  viewMode, // 'filiale', 'verkaufsgebiet', 'verkaufsgebiet_filiale'
  timeFilter, // 'Monat', 'Woche', 'Tag'
  months = [],
  weeks = [],
  days = [],
  groupedData = {},
  selectedDataKeys = [],
  availableDataKeys = [],
}) => {
  // State für das Suchfeld
  const [searchTerm, setSearchTerm] = useState('');

  // Gefilterte und valide DataKeys
  const validSelectedDataKeys = useMemo(
    () => selectedDataKeys.filter((key) => typeof key === 'string'),
    [selectedDataKeys]
  );

  // Perioden
  const periods = useMemo(() => {
    if (timeFilter === 'Monat') {
      return [...months].sort((a, b) =>
        dayjs(a, 'MMMM YYYY').isAfter(dayjs(b, 'MMMM YYYY')) ? 1 : -1
      );
    } else if (timeFilter === 'Woche') {
      return [...weeks].sort((a, b) => {
        const weekNumA = parseInt(a.replace('KW', '').trim(), 10);
        const weekNumB = parseInt(b.replace('KW', '').trim(), 10);
        return weekNumA - weekNumB;
      });
    } else {
      return [...days].sort((a, b) =>
        dayjs(a, 'DD.MM.YYYY').isAfter(dayjs(b, 'DD.MM.YYYY')) ? 1 : -1
      );
    }
  }, [timeFilter, months, weeks, days]);

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = useMemo(() => {
    if (!searchTerm) return groupedData;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return Object.fromEntries(
      Object.entries(groupedData || {}).filter(([key]) =>
        key.toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  }, [searchTerm, groupedData]);

  // Berechnung der Gesamtsummen
  const totalSums = useMemo(() => {
    const total = {};
    validSelectedDataKeys.forEach((key) => {
      total[key] = {};
      periods.forEach((period) => {
        const rowSum = Object.values(filteredData || {}).reduce((sum, row) => {
          const value = row[key]?.[period];
          return sum + (typeof value === 'number' ? value : 0);
        }, 0);
        total[key][period] = rowSum;
      });
    });
    return total;
  }, [filteredData, validSelectedDataKeys, periods]);

  // Gruppierte Items
  const groupedItems = useMemo(() => {
    return Object.entries(filteredData || {});
  }, [filteredData]);

  // Formatierung der Typ-Namen mit nur dem ersten Buchstaben groß
  const formatTypName = (dataKey) => {
    if (!dataKey) {
      console.warn('Undefined dataKey in formatTypName:', dataKey);
      return 'UNDEFINED';
    }
    const formatted = dataKey
      .replace(/^total_/, '') // Entferne den 'total_' Präfix
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^./, (str) => str.toUpperCase()); // Nur den ersten Buchstaben groß
    console.log('Formatted Typ Name:', formatted);
    return formatted;
  };

  // Für verkaufsgebiet_filiale View, gruppieren nach verkaufsgebiet und filiale
  const groupedByVerkaufsgebiet = useMemo(() => {
    if (viewMode !== 'verkaufsgebiet_filiale') return null;

    const result = {};
    groupedItems.forEach(([groupKey, values]) => {
      if (!groupKey) {
        console.warn('Gruppenschlüssel ist undefined oder null:', groupKey);
        return;
      }
      const [verkaufsgebiet, filiale] = groupKey.split('||');

      if (!verkaufsgebiet || !filiale) {
        console.warn('Gruppenschlüssel enthält kein "||" oder die Teile sind undefined:', groupKey);
        return;
      }

      if (!result[verkaufsgebiet]) {
        result[verkaufsgebiet] = {};
      }

      result[verkaufsgebiet][filiale] = values;
    });
    return result;
  }, [groupedItems, viewMode]);

  // Formatierungsfunktion basierend auf der Kennzahl
  const getFormattedValue = (dataKey, value) => {
    if (typeof dataKey !== 'string') {
      console.warn('Undefined or non-string dataKey:', dataKey);
      return 'N/A';
    }

    // Entferne den 'total_' Präfix und Leerzeichen, dann in Kleinbuchstaben umwandeln
    const key = dataKey.replace(/^total_/, '').replace(/\s+/g, '').toLowerCase();

    if (!key) {
      console.warn('Nach der Verarbeitung ist key leer:', dataKey);
      return 'N/A';
    }

    // Mapping der Kennzahlen zu ihren Formatierungsfunktionen
    const formatFunctions = {
      'umsatz': formatValue,
      'umsatzvj': formatValue,
      'planumsatz': formatValue,
      'lieferwert': formatValue,
      'retourwert': formatValue,
      'korrekturwert': formatValue,
      'kundenbon': formatValue,
      'kundenanzahl': formatQuantity,
      'kundenanzahlvj': formatQuantity,
      'stundenleistung': formatQuantity,
      'produktivstunden': formatQuantity,
      'retourquote': formatPercentageMitZeichen,
      'umsatzvj%': formatPercentageMitZeichen,
      'kundenanzahlvj%': formatPercentageMitZeichen,
    };

    if (key in formatFunctions) {
      return formatFunctions[key](value);
    }

    // Standardformatierung, falls keine spezifische Funktion gefunden wurde
    return formatValue(value);
  };

  // Berechnung der speziellen Kennzahlen in der Summenzeile
  const calculateSpecialTotals = () => {
    const specialTotals = {};

    // UmsatzVJ%
    if (
      typeof totalSums['umsatz'] === 'number' &&
      typeof totalSums['umsatzvj'] === 'number' &&
      totalSums['umsatzvj'] !== 0
    ) {
      specialTotals['umsatzvj%'] = ((totalSums['umsatz'] / totalSums['umsatzvj']) - 1) * 100;
    } else {
      specialTotals['umsatzvj%'] = 0; // oder eine andere sinnvolle Standardwert
      console.warn('Berechnung von UmsatzVJ% fehlgeschlagen. Überprüfen Sie die Werte.');
    }

    // KundenanzahlVJ%
    if (
      typeof totalSums['kundenanzahl'] === 'number' &&
      typeof totalSums['kundenanzahlvj'] === 'number' &&
      totalSums['kundenanzahlvj'] !== 0
    ) {
      specialTotals['kundenanzahlvj%'] = ((totalSums['kundenanzahl'] / totalSums['kundenanzahlvj']) - 1) * 100;
    } else {
      specialTotals['kundenanzahlvj%'] = 0; // oder eine andere sinnvolle Standardwert
      console.warn('Berechnung von KundenanzahlVJ% fehlgeschlagen. Überprüfen Sie die Werte.');
    }

    // Stundenleistung
    if (
      typeof totalSums['umsatz'] === 'number' &&
      typeof totalSums['produktivstunden'] === 'number' &&
      totalSums['produktivstunden'] !== 0
    ) {
      specialTotals['stundenleistung'] = totalSums['umsatz'] / totalSums['produktivstunden'];
    } else {
      specialTotals['stundenleistung'] = 0;
      console.warn('Berechnung von Stundenleistung fehlgeschlagen. Überprüfen Sie die Werte.');
    }

    // Kundenbon
    if (
      typeof totalSums['umsatz'] === 'number' &&
      typeof totalSums['kundenanzahl'] === 'number' &&
      totalSums['kundenanzahl'] !== 0
    ) {
      specialTotals['kundenbon'] = totalSums['umsatz'] / totalSums['kundenanzahl'];
    } else {
      specialTotals['kundenbon'] = 0;
      console.warn('Berechnung von Kundenbon fehlgeschlagen. Überprüfen Sie die Werte.');
    }

    // Retourquote
    if (
      typeof totalSums['retourwert'] === 'number' &&
      (
        (typeof totalSums['lieferwert'] === 'number' && totalSums['lieferwert'] !== 0) ||
        (typeof totalSums['korrekturwert'] === 'number' && totalSums['korrekturwert'] !== 0)
      )
    ) {
      const lieferwert = typeof totalSums['lieferwert'] === 'number' ? totalSums['lieferwert'] : 0;
      const korrekturwert = typeof totalSums['korrekturwert'] === 'number' ? totalSums['korrekturwert'] : 0;
      const gesamtLieferKorrekturwert = lieferwert + korrekturwert;

      if (gesamtLieferKorrekturwert !== 0) {
        specialTotals['retourquote'] = (totalSums['retourwert'] / gesamtLieferKorrekturwert) * 100;
      } else {
        specialTotals['retourquote'] = 0;
        console.warn('Gesamtliefer- und Korrekturwert ist 0. Berechnung von Retourquote nicht möglich.');
      }
    } else {
      specialTotals['retourquote'] = 0;
      console.warn('Berechnung von Retourquote fehlgeschlagen. Überprüfen Sie die Werte.');
    }

    return specialTotals;
  };

  const specialTotals = useMemo(() => calculateSpecialTotals(), [totalSums]);

  // Funktion zum Exportieren nach Excel
  const exportToExcel = () => {
    try {
      // Erstellen eines neuen Arbeitsblatts
      const worksheetData = [];

      // Tabellenkopf hinzufügen
      const header = [
        viewMode === 'verkaufsgebiet_filiale'
          ? 'Verkaufsgebiet'
          : viewMode === 'filiale'
          ? 'Filiale'
          : 'Verkaufsgebiet',
        ...(viewMode === 'verkaufsgebiet_filiale' ? ['Filiale'] : []),
        'Typ',
        ...periods,
        'Summe',
      ];
      worksheetData.push(header);

      // Tabelleninhalt hinzufügen
      groupedItems.forEach(([groupKey, values]) => {
        if (viewMode === 'verkaufsgebiet_filiale') {
          const [verkaufsgebiet, filiale] = groupKey.split('||');
          validSelectedDataKeys.forEach((dataKey) => {
            const row = [
              verkaufsgebiet,
              filiale,
              formatTypName(dataKey),
              ...periods.map((period) => getFormattedValue(dataKey, values[dataKey]?.[period])),
              getFormattedValue(dataKey, Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
            ];
            worksheetData.push(row);
          });
        } else {
          validSelectedDataKeys.forEach((dataKey) => {
            const row = [
              groupKey,
              formatTypName(dataKey),
              ...periods.map((period) => getFormattedValue(dataKey, values[dataKey]?.[period])),
              getFormattedValue(dataKey, Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
            ];
            worksheetData.push(row);
          });
        }
      });

      // Summenzeilen hinzufügen
      validSelectedDataKeys.forEach((dataKey) => {
        const row = [
          'Gesamtsumme',
          formatTypName(dataKey),
          ...periods.map((period) => getFormattedValue(dataKey, totalSums[dataKey]?.[period])),
          dataKey.toLowerCase().endsWith('%')
            ? getFormattedValue(dataKey, specialTotals[dataKey.toLowerCase()] ?? 'N/A')
            : getFormattedValue(dataKey, Object.values(totalSums[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
        ];
        worksheetData.push(row);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Kundenstatistik');
      XLSX.writeFile(workbook, 'Kundenstatistik.xlsx');

      console.log('Export nach Excel erfolgreich!');
    } catch (error) {
      console.error('Export nach Excel fehlgeschlagen:', error);
      alert('Export nach Excel fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  // Funktion zum Exportieren nach PDF
  const exportToPDF = () => {
    try {
      const doc = new jsPDF();

      // Tabelleninhalt vorbereiten
      const tableColumn = [
        viewMode === 'verkaufsgebiet_filiale'
          ? 'Verkaufsgebiet'
          : viewMode === 'filiale'
          ? 'Filiale'
          : 'Verkaufsgebiet',
        ...(viewMode === 'verkaufsgebiet_filiale' ? ['Filiale'] : []),
        'Typ',
        ...periods,
        'Summe',
      ];
      const tableRows = [];

      groupedItems.forEach(([groupKey, values]) => {
        if (viewMode === 'verkaufsgebiet_filiale') {
          const [verkaufsgebiet, filiale] = groupKey.split('||');
          validSelectedDataKeys.forEach((dataKey) => {
            const row = [
              verkaufsgebiet,
              filiale,
              formatTypName(dataKey),
              ...periods.map((period) => getFormattedValue(dataKey, values[dataKey]?.[period])),
              getFormattedValue(dataKey, Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
            ];
            tableRows.push(row);
          });
        } else {
          validSelectedDataKeys.forEach((dataKey) => {
            const row = [
              groupKey,
              formatTypName(dataKey),
              ...periods.map((period) => getFormattedValue(dataKey, values[dataKey]?.[period])),
              getFormattedValue(dataKey, Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
            ];
            tableRows.push(row);
          });
        }
      });

      // Summenzeilen hinzufügen
      validSelectedDataKeys.forEach((dataKey) => {
        const row = [
          'Gesamtsumme',
          formatTypName(dataKey),
          ...periods.map((period) => getFormattedValue(dataKey, totalSums[dataKey]?.[period])),
          dataKey.toLowerCase().endsWith('%')
            ? getFormattedValue(dataKey, specialTotals[dataKey.toLowerCase()] ?? 'N/A')
            : getFormattedValue(dataKey, Object.values(totalSums[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)),
        ];
        tableRows.push(row);
      });

      // AutoTable hinzufügen
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [22, 160, 133] },
      });

      doc.text('Kundenstatistik', 14, 15);
      doc.save('Kundenstatistik.pdf');

      console.log('Export nach PDF erfolgreich!');
    } catch (error) {
      console.error('Export nach PDF fehlgeschlagen:', error);
      alert('Export nach PDF fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  return (
    <>
      {/* Card mit Header für Suchfeld und Export-Icons, und Content für die Tabelle */}
      <Box mb={2}>
        <Card>
          <CardHeader
            title={
              <Box display="flex" alignItems="center">
                {/* Export-Icons mit Tooltipps */}
                <Tooltip title="Export nach PDF">
                  <IconButton
                    color="secondary"
                    aria-label="Export nach PDF"
                    onClick={exportToPDF}
                    sx={{ mr: 1 }}
                  >
                    <PictureAsPdfIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Export nach Excel">
                  <IconButton
                    color="primary"
                    aria-label="Export nach Excel"
                    onClick={exportToExcel}
                    sx={{ mr: 2 }}
                  >
                    <GridOnIcon />
                  </IconButton>
                </Tooltip>
                {/* Suchfeld mit fester Breite */}
                <TextField
                  label="Suchen"
                  variant="outlined"
                  size="small"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  sx={{ width: '200px' }} // Feste Breite wie zuvor
                />
              </Box>
            }
          />
          <CardContent>
            {/* Tabelle */}
            <TableContainer component={Paper} style={{ maxHeight: '600px', overflow: 'auto' }}>
              <Table stickyHeader size="small">
                <TableHead>
                  <TableRow>
                    {viewMode === 'verkaufsgebiet_filiale' ? (
                      <>
                        <TableCell
                          sx={{
                            position: 'sticky',
                            top: -20,
                            backgroundColor: '#fff',
                            zIndex: 1000,
                          }}
                        >
                          Verkaufsgebiet
                        </TableCell>
                        <TableCell
                          sx={{
                            position: 'sticky',
                            top: -20,
                            backgroundColor: '#fff',
                            zIndex: 1000,
                          }}
                        >
                          Filiale
                        </TableCell>
                      </>
                    ) : (
                      <TableCell
                        sx={{
                          position: 'sticky',
                          top: -20,
                          backgroundColor: '#fff',
                          zIndex: 1000,
                        }}
                      >
                        {viewMode === 'filiale' ? 'Filiale' : 'Verkaufsgebiet'}
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: -20,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                      }}
                    >
                      Typ
                    </TableCell>
                    {periods.map((period) => (
                      <TableCell
                        key={period}
                        sx={{
                          position: 'sticky',
                          top: -20,
                          backgroundColor: '#fff',
                          zIndex: 1000,
                        }}
                      >
                        {period}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: -20,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                      }}
                    >
                      Summe
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {viewMode === 'verkaufsgebiet_filiale' ? (
                    Object.entries(groupedByVerkaufsgebiet).map(([verkaufsgebiet, filialen]) => {
                      const filialenKeys = Object.keys(filialen);
                      const verkaufsgebietRowSpan = filialenKeys.length * validSelectedDataKeys.length;

                      return (
                        <React.Fragment key={verkaufsgebiet}>
                          {filialenKeys.map((filiale, filialeIndex) => {
                            const values = filialen[filiale];
                            return validSelectedDataKeys.map((dataKey, dataKeyIndex) => {
                              return (
                                <TableRow key={`${verkaufsgebiet}-${filiale}-${dataKey}`}>
                                  {/* Verkaufsgebiet nur einmal mit rowSpan */}
                                  {filialeIndex === 0 && dataKeyIndex === 0 && (
                                    <TableCell
                                      rowSpan={verkaufsgebietRowSpan}
                                      sx={{ fontWeight: 'bold', verticalAlign: 'top' }}
                                    >
                                      {verkaufsgebiet}
                                    </TableCell>
                                  )}
                                  {/* Filiale nur einmal pro Filiale mit rowSpan */}
                                  {dataKeyIndex === 0 && (
                                    <TableCell
                                      rowSpan={validSelectedDataKeys.length}
                                      sx={{ fontWeight: 'bold', verticalAlign: 'top' }}
                                    >
                                      {filiale}
                                    </TableCell>
                                  )}
                                  <TableCell>{formatTypName(dataKey)}</TableCell>
                                  {periods.map((period) => (
                                    <TableCell key={period}>
                                      {getFormattedValue(dataKey, values[dataKey]?.[period])}
                                    </TableCell>
                                  ))}
                                  <TableCell>
                                    {dataKey
                                      ? dataKey.toLowerCase().endsWith('%')
                                        ? getFormattedValue(
                                            dataKey,
                                            specialTotals[dataKey.toLowerCase()] ?? 'N/A'
                                          )
                                        : getFormattedValue(
                                            dataKey,
                                            Object.values(values[dataKey] || {}).reduce(
                                              (sum, val) => sum + (val || 0),
                                              0
                                            )
                                          )
                                      : 'N/A'}
                                  </TableCell>
                                </TableRow>
                              );
                            });
                          })}
                        </React.Fragment>
                      );
                    })
                  ) : (
                    // Original rendering code für andere Ansichten
                    groupedItems.map(([groupKey, values]) => {
                      const typeCount = validSelectedDataKeys.length;
                      return (
                        <React.Fragment key={groupKey}>
                          {/* Hauptzeile */}
                          <TableRow>
                            <TableCell
                              rowSpan={typeCount}
                              sx={{ fontWeight: 'bold', verticalAlign: 'top' }}
                            >
                              {groupKey}
                            </TableCell>
                            {/* Erste Typ-Zeile */}
                            <TableCell>{formatTypName(validSelectedDataKeys[0])}</TableCell>
                            {periods.map((period) => (
                              <TableCell key={period}>
                                {getFormattedValue(validSelectedDataKeys[0], values[validSelectedDataKeys[0]]?.[period])}
                              </TableCell>
                            ))}
                            <TableCell>
                              {validSelectedDataKeys[0].toLowerCase().endsWith('%')
                                ? getFormattedValue(
                                    validSelectedDataKeys[0],
                                    specialTotals[validSelectedDataKeys[0].toLowerCase()] ?? 'N/A'
                                  )
                                : getFormattedValue(
                                    validSelectedDataKeys[0],
                                    Object.values(values[validSelectedDataKeys[0]] || {}).reduce(
                                      (sum, val) => sum + (val || 0),
                                      0
                                    )
                                  )}
                            </TableCell>
                          </TableRow>

                          {/* Weitere Typ-Zeilen */}
                          {validSelectedDataKeys.slice(1).map((dataKey) => {
                            return (
                              <TableRow key={`${groupKey}-${dataKey}`}>
                                <TableCell>{formatTypName(dataKey)}</TableCell>
                                {periods.map((period) => (
                                  <TableCell key={period}>
                                    {getFormattedValue(dataKey, values[dataKey]?.[period])}
                                  </TableCell>
                                ))}
                                <TableCell>
                                  {dataKey.toLowerCase().endsWith('%')
                                    ? getFormattedValue(
                                        dataKey,
                                        specialTotals[dataKey.toLowerCase()] ?? 'N/A'
                                      )
                                    : getFormattedValue(
                                        dataKey,
                                        Object.values(values[dataKey] || {}).reduce(
                                          (sum, val) => sum + (val || 0),
                                          0
                                        )
                                      )}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </React.Fragment>
                      );
                    })
                  )}

                  {/* Gesamtsummen */}
                  <TableRow>
                    <TableCell
                      colSpan={viewMode === 'verkaufsgebiet_filiale' ? 2 : 1}
                      rowSpan={validSelectedDataKeys.length}
                      sx={{ fontWeight: 'bold', backgroundColor: '#f0f0f0', verticalAlign: 'top' }}
                    >
                      Gesamtsumme
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#f0f0f0' }}>
                      {validSelectedDataKeys[0] ? formatTypName(validSelectedDataKeys[0]) : 'N/A'}
                    </TableCell>
                    {periods.map((period) => (
                      <TableCell key={period} sx={{ backgroundColor: '#f0f0f0' }}>
                        {validSelectedDataKeys[0]
                          ? getFormattedValue(validSelectedDataKeys[0], totalSums[validSelectedDataKeys[0]]?.[period])
                          : 'N/A'}
                      </TableCell>
                    ))}
                    <TableCell sx={{ backgroundColor: '#f0f0f0' }}>
                      {validSelectedDataKeys[0]
                        ? validSelectedDataKeys[0].toLowerCase().endsWith('%')
                          ? getFormattedValue(
                              validSelectedDataKeys[0],
                              specialTotals[validSelectedDataKeys[0].toLowerCase()] ?? 'N/A'
                            )
                          : getFormattedValue(
                              validSelectedDataKeys[0],
                              Object.values(totalSums[validSelectedDataKeys[0]] || {}).reduce(
                                (sum, val) => sum + (val || 0),
                                0
                              )
                            )
                        : 'N/A'}
                    </TableCell>
                  </TableRow>

                  {/* Weitere Gesamtsummen */}
                  {validSelectedDataKeys.slice(1).map((dataKey) => {
                    return (
                      <TableRow key={`total-${dataKey}`}>
                        <TableCell sx={{ backgroundColor: '#f0f0f0' }}>
                          {formatTypName(dataKey)}
                        </TableCell>
                        {periods.map((period) => (
                          <TableCell key={period} sx={{ backgroundColor: '#f0f0f0' }}>
                            {getFormattedValue(dataKey, totalSums[dataKey]?.[period])}
                          </TableCell>
                        ))}
                        <TableCell sx={{ backgroundColor: '#f0f0f0' }}>
                          {dataKey.toLowerCase().endsWith('%')
                            ? getFormattedValue(
                                dataKey,
                                specialTotals[dataKey.toLowerCase()] ?? 'N/A'
                              )
                            : getFormattedValue(
                                dataKey,
                                Object.values(totalSums[dataKey] || {}).reduce(
                                  (sum, val) => sum + (val || 0),
                                  0
                                )
                              )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>

      {/* Paginierung entfernen */}
      {/* <TablePagination
        component="div"
        count={groupedItems.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50]}
      /> */}
    </>
  );
};

export default KundenstatistikTabelle;
