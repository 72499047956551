import React from 'react';
import { Grid, Box } from '@mui/material';
import FilialchecksListe from '../../../components/Filialcheck/FilialchecksListe';
import Layout from '../../../components/layout/Filialcheck/Filialcheck';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import ChecklistIcon from '@mui/icons-material/Checklist';  // Import des Icons


const FilialcheckKofigurationPage = () => {
    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Filialcheck- Analyse" 
                    subtitle="Verwalten deine Filialchecks und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                    Icon={ChecklistIcon}  // Übergabe des Icons
                />
                
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={12} md={8}>
                        <FilialchecksListe />
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default FilialcheckKofigurationPage;
