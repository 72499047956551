import React, { useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Autocomplete,
  TextField,
} from '@mui/material';

const data = [
  {
    warengruppe: 'Brot',
    artikel: [
      {
        name: 'Roggenbrot',
        abverkauf: { januar: 500, februar: 450, märz: 470 },
        liefermenge: { januar: 520, februar: 480, märz: 500 },
      },
      {
        name: 'Vollkornbrot',
        abverkauf: { januar: 300, februar: 280, märz: 310 },
        liefermenge: { januar: 320, februar: 290, märz: 320 },
      },
    ],
  },
  {
    warengruppe: 'Brötchen',
    artikel: [
      {
        name: 'Kaiserbrötchen',
        abverkauf: { januar: 1000, februar: 950, märz: 980 },
        liefermenge: { januar: 1020, februar: 970, märz: 1000 },
      },
      {
        name: 'Sesambrötchen',
        abverkauf: { januar: 700, februar: 680, märz: 710 },
        liefermenge: { januar: 720, februar: 690, märz: 730 },
      },
    ],
  },
];

// Alle Artikel extrahieren
const allArticles = data.flatMap((gruppe) => gruppe.artikel.map((artikel) => artikel.name));

const WarenanalyseTable = () => {
  const [showGroupedView, setShowGroupedView] = useState(false);
  const [showAbverkauf, setShowAbverkauf] = useState(true);
  const [showLiefermenge, setShowLiefermenge] = useState(true);
  const [showOnlyArticles, setShowOnlyArticles] = useState(false);

  // Monate wählen
  const [showJanuar, setShowJanuar] = useState(true);
  const [showFebruar, setShowFebruar] = useState(true);
  const [showMärz, setShowMärz] = useState(true);

  // Artikelfilter
  const [selectedArticles, setSelectedArticles] = useState(allArticles);

  const getAggregatedValues = (gruppe) => {
    const aggregated = {
      abverkauf: { januar: 0, februar: 0, märz: 0 },
      liefermenge: { januar: 0, februar: 0, märz: 0 },
    };

    gruppe.artikel.forEach((artikel) => {
      if (selectedArticles.includes(artikel.name)) {
        aggregated.abverkauf.januar += artikel.abverkauf.januar;
        aggregated.abverkauf.februar += artikel.abverkauf.februar;
        aggregated.abverkauf.märz += artikel.abverkauf.märz;

        aggregated.liefermenge.januar += artikel.liefermenge.januar;
        aggregated.liefermenge.februar += artikel.liefermenge.februar;
        aggregated.liefermenge.märz += artikel.liefermenge.märz;
      }
    });

    return aggregated;
  };

  return (
    <div>
      <Button variant="contained" onClick={() => setShowGroupedView(!showGroupedView)}>
        {showGroupedView ? 'Vollständige Ansicht' : 'Gruppierte Ansicht'}
      </Button>

      {!showGroupedView && (
        <Button variant="contained" style={{ marginLeft: '10px' }} onClick={() => setShowOnlyArticles(!showOnlyArticles)}>
          {showOnlyArticles ? 'Mit Warengruppe' : 'Nur Artikel'}
        </Button>
      )}

      <div style={{ marginTop: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={showAbverkauf} onChange={() => setShowAbverkauf(!showAbverkauf)} />}
          label="Abverkauf anzeigen"
        />
        <FormControlLabel
          control={<Checkbox checked={showLiefermenge} onChange={() => setShowLiefermenge(!showLiefermenge)} />}
          label="Liefermenge anzeigen"
        />

        <div style={{ marginTop: '20px' }}>
          <FormControlLabel
            control={<Checkbox checked={showJanuar} onChange={() => setShowJanuar(!showJanuar)} />}
            label="Januar anzeigen"
          />
          <FormControlLabel
            control={<Checkbox checked={showFebruar} onChange={() => setShowFebruar(!showFebruar)} />}
            label="Februar anzeigen"
          />
          <FormControlLabel
            control={<Checkbox checked={showMärz} onChange={() => setShowMärz(!showMärz)} />}
            label="März anzeigen"
          />
        </div>

        {!showGroupedView && (
          <div style={{ marginTop: '20px', width: '300px' }}>
            <Autocomplete
              multiple
              options={allArticles}
              value={selectedArticles}
              onChange={(event, newValue) => {
                setSelectedArticles(newValue);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" label="Artikel filtern" placeholder="Artikel auswählen" />
              )}
            />
          </div>
        )}
      </div>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table aria-label="Warengruppenanalyse">
          <TableHead>
            <TableRow>
              {showGroupedView || showOnlyArticles ? (
                <TableCell>Warengruppe</TableCell>
              ) : (
                <>
                  <TableCell>Warengruppe</TableCell>
                  <TableCell>Artikel</TableCell>
                </>
              )}
              <TableCell>Typ</TableCell>
              {showJanuar && <TableCell>Januar</TableCell>}
              {showFebruar && <TableCell>Februar</TableCell>}
              {showMärz && <TableCell>März</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((gruppe) => {
              const gefilterteArtikel = gruppe.artikel.filter((artikel) => selectedArticles.includes(artikel.name));

              if (showGroupedView) {
                const aggregated = getAggregatedValues(gruppe);

                const hasValues =
                  (showAbverkauf && Object.values(aggregated.abverkauf).some((val) => val > 0)) ||
                  (showLiefermenge && Object.values(aggregated.liefermenge).some((val) => val > 0));

                if (!hasValues) {
                  return null;
                }

                return (
                  <React.Fragment key={gruppe.warengruppe}>
                    {showAbverkauf && (
                      <TableRow>
                        <TableCell rowSpan={showLiefermenge ? 2 : 1}>{gruppe.warengruppe}</TableCell>
                        <TableCell>Abverkauf</TableCell>
                        {showJanuar && <TableCell>{aggregated.abverkauf.januar}</TableCell>}
                        {showFebruar && <TableCell>{aggregated.abverkauf.februar}</TableCell>}
                        {showMärz && <TableCell>{aggregated.abverkauf.märz}</TableCell>}
                      </TableRow>
                    )}
                    {showLiefermenge && (
                      <TableRow>
                        {!showAbverkauf && <TableCell>{gruppe.warengruppe}</TableCell>}
                        <TableCell>Liefermenge</TableCell>
                        {showJanuar && <TableCell>{aggregated.liefermenge.januar}</TableCell>}
                        {showFebruar && <TableCell>{aggregated.liefermenge.februar}</TableCell>}
                        {showMärz && <TableCell>{aggregated.liefermenge.märz}</TableCell>}
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              } else {
                if (gefilterteArtikel.length === 0) {
                  return null;
                }

                return gefilterteArtikel.map((artikel, index) => (
                  <React.Fragment key={artikel.name}>
                    {showAbverkauf && (
                      <TableRow>
                        {!showOnlyArticles && index === 0 && (
                          <TableCell rowSpan={gefilterteArtikel.length * (showAbverkauf + showLiefermenge)}>{gruppe.warengruppe}</TableCell>
                        )}
                        <TableCell rowSpan={showLiefermenge ? 2 : 1}>{artikel.name}</TableCell>
                        <TableCell>Abverkauf</TableCell>
                        {showJanuar && <TableCell>{artikel.abverkauf.januar}</TableCell>}
                        {showFebruar && <TableCell>{artikel.abverkauf.februar}</TableCell>}
                        {showMärz && <TableCell>{artikel.abverkauf.märz}</TableCell>}
                      </TableRow>
                    )}
                    {showLiefermenge && (
                      <TableRow>
                        {!showAbverkauf && !showOnlyArticles && index === 0 && (
                          <TableCell rowSpan={gefilterteArtikel.length}>{gruppe.warengruppe}</TableCell>
                        )}
                        {!showAbverkauf && <TableCell rowSpan={1}>{artikel.name}</TableCell>}
                        <TableCell>Liefermenge</TableCell>
                        {showJanuar && <TableCell>{artikel.liefermenge.januar}</TableCell>}
                        {showFebruar && <TableCell>{artikel.liefermenge.februar}</TableCell>}
                        {showMärz && <TableCell>{artikel.liefermenge.märz}</TableCell>}
                      </TableRow>
                    )}
                  </React.Fragment>
                ));
              }
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default WarenanalyseTable;
