import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@mui/material';
import Layout from '../../../components/layout/recruiting/recruiting';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import axios from '../../../services/axiosInstance';
import BigTableCard from '../../../components/card/BigTableCard'; 
import BewerberCard from '../../../components/card/personalcontrolling/BewerberCard'; 
import OffeneStellenBewerberCard from '../../../components/card/personalcontrolling/OffeneStellenBewerberCard';
import BigBarChartHorizontalCard from '../../../components/card/BigBarChartHorizontalCard';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import { format, subMonths } from 'date-fns'; // Importieren der date-fns Funktionen

const Overview = () => {
  const [data, setData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]); // Neuer State für das Liniendiagramm
  const kundengruppeFilter = '1,2,3,4,5'; // Beispiel für Kundengruppen-Filter

  useEffect(() => {
    axios.get('/recruiting/bewerber', {
      params: {
        kundengruppeID: kundengruppeFilter
      }
    })
    .then(response => {
      const results = response.data.results;
      if (Array.isArray(results)) {
        // Berechne die Anzahl der Bewerber pro Verkaufsgebiet
        const verkaufsgebietCount = results.reduce((acc, curr) => {
          const gebiet = curr.verkaufsgebiet; 
          if (!acc[gebiet]) acc[gebiet] = 0;
          acc[gebiet] += 1;
          return acc;
        }, {});

        const chartLabels = Object.keys(verkaufsgebietCount);
        const chartData = Object.values(verkaufsgebietCount);

        setChartLabels(chartLabels);
        setChartData(chartData);

        const kundengruppen = results.reduce((acc, curr) => {
          const gruppe = curr.prozesschritt; 
          if (!acc[gruppe]) acc[gruppe] = 0;
          acc[gruppe] += 1;
          return acc;
        }, {});

        const chartDataForBarChart = Object.keys(kundengruppen).map(gruppe => ({
          kundengruppe: gruppe,
          anzahlBewerber: kundengruppen[gruppe]
        }));

        setData(chartDataForBarChart);

        const tableDataFormatted = results.map(item => ({
          bewerber: item.bewerber,
          prozessschritt: item.prozessschritt,
          eingangsdatum: item.eingangsdatum,
          eingangVor: item.bw_eintrittsdatum_tage,
          filiale: item.filiale,
          stelle: item.dispolisten
        }));

        setTableData(tableDataFormatted);
      } else {
        console.error('results ist kein Array:', results);
      }
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Daten', error);
    });
  }, [kundengruppeFilter]);

  // Neuer useEffect für das Liniendiagramm
  useEffect(() => {
    // Aktuelles Datum und Startdatum vor 5 Monaten berechnen
    const now = new Date();
    const startDate = format(subMonths(now, 5), 'yyyy-MM-dd');
    const endDate = format(now, 'yyyy-MM-dd');

    // Daten vom API ohne 'kundengruppeFilter' abrufen
    axios.get('/recruiting/bewerber', {
      params: {
        startDate: startDate,
        endDate: endDate
      }
    })
    .then(response => {
      const results = response.data.results;

      if (Array.isArray(results)) {
        // Monate der letzten 6 Monate vorbereiten
        const months = [];
        for (let i = 5; i >= 0; i--) {
          const date = subMonths(now, i);
          const month = format(date, 'yyyy-MM');
          months.push(month);
        }

        // Bewerber pro Monat zählen
        const applicantsPerMonth = months.map(month => {
          const count = results.filter(item => {
            const eingangsdatum = new Date(item.eingangsdatum);
            const itemMonth = format(eingangsdatum, 'yyyy-MM');
            return itemMonth === month;
          }).length;
          return { x: month, y: count };
        });

        const chartData = [
          {
            id: 'Bewerber',
            data: applicantsPerMonth
          }
        ];

        setLineChartData(chartData);
      } else {
        console.error('results ist kein Array:', results);
      }
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Daten für das Liniendiagramm', error);
    });
  }, []); // Leerer Dependency-Array, damit es nur einmal beim Mounten ausgeführt wird

  // Neue Labels und Daten für die BigBarChartHorizontalCard erstellen
  const chartLabelsForKundengruppe = data.map(item => item.kundengruppe);
  const chartDataForKundengruppe = data.map(item => item.anzahlBewerber);

  return (
    <Layout>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title="Recruiting"
            subtitle="Diese Übersicht bietet einen schnellen Überblick über den aktuellen Recruiting-Status im gesamten Unternehmen."
            Icon={BarChartIcon}
          />
        </Box>
      </Box>

      <Grid container spacing={2}>
        {/* Obere Karten und Liniendiagramm */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            {/* Linke Seite mit den beiden Karten */}
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <OffeneStellenBewerberCard kundengruppeFilter={kundengruppeFilter} />
                </Grid>
                <Grid item xs={12}>
                  <BewerberCard kundengruppeFilter={kundengruppeFilter} />
                </Grid>
              </Grid>
            </Grid>
            {/* Rechte Seite mit dem Liniendiagramm */}
            <Grid item xs={12} md={6}>
              <BigChartCardRecharts
                data={lineChartData}
                title="Bewerber im Verlauf"
                infoContent="Anzahl der Bewerber in den letzten 6 Monaten"
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Untere Barcharts nebeneinander */}
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <BigBarChartHorizontalCard
                title="Anzahl der Bewerber pro Prozessschritt"
                labels={chartLabelsForKundengruppe}
                data={chartDataForKundengruppe}
                hoch={300}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <BigBarChartHorizontalCard
                title="Bewerber pro Verkaufsgebiet"
                labels={chartLabels}
                data={chartData}
                hoch={300}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* Tabelle unten */}
        <Grid item xs={12}>
          <BigTableCard
            title="Bewerber Übersicht"
            columns={[
              { header: 'Bewerber', accessorKey: 'bewerber' },
              { header: 'Prozessschritt', accessorKey: 'prozessschritt' },
              { header: 'Eingangsdatum', accessorKey: 'eingangsdatum' },
              { header: 'Eingang vor', accessorKey: 'eingangVor' },
              { header: 'Filiale', accessorKey: 'filiale' },
              { header: 'Stelle', accessorKey: 'stelle' }
            ]}
            data={tableData}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Overview;
