// DetailDialog.js
import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const DetailDialog = ({
  open,
  onClose,
  detailData,
  chartData,
  selectedDataKeys,
  timeFilter,
  months,
  weeks,
  days,
}) => {
  const colors = ['#8884d8', '#82ca9d', '#ff7300', '#ff0000', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Details für {detailData ? detailData.name : ''}</DialogTitle>
      <DialogContent>
        {detailData && (
          <>
            <Typography variant="h6">
              {detailData.type} Details für {detailData.name}
            </Typography>

            {/* Liniendiagramm */}
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="period" />
                <YAxis />
                <Tooltip />
                <Legend />
                {selectedDataKeys.map((key, index) => (
                  <Line
                    key={key}
                    type="monotone"
                    dataKey={key}
                    stroke={colors[index % colors.length]}
                    name={key.replace('total_', '').replace('_menge', '').toUpperCase()}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>

            {/* Tabelle */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Zeitraum</TableCell>
                  {selectedDataKeys.map((key) => (
                    <TableCell key={key}>
                      {key.replace('total_', '').replace('_menge', '').toUpperCase()}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {chartData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>{row.period}</TableCell>
                    {selectedDataKeys.map((key) => (
                      <TableCell key={key}>{row[key]}</TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailDialog;
