import React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme, Box, Typography, CardActions } from '@mui/material';

const GroupedBarChartFluktuation = ({ data }) => {
    const theme = useTheme();

    const maxValue = Math.max(...data.map(item => Math.max(item.EintritteM, item.AustritteM)));

    const yDataSeries = [
        {
            data: data.map(item => item.EintritteM),
            name: 'Eintritte',
            color: theme.palette.primary.main, // Color for Eintritte
        },
        {
            data: data.map(item => item.AustritteM),
            name: 'Austritte',
            color: theme.palette.secondary.main, // Color for Austritte
        }
    ];

    return (
        <>
            <Box>
                <BarChart
                    xAxis={[{
                        scaleType: 'band',
                        data: data.map(item => item.month), // X-axis data (e.g., months)
                        show: true,
                        tickSize: 0,
                    }]}
                    yAxis={[{
                        show: true,
                        label: null, // Remove the y-axis label
                        min: 0,
                        max: maxValue * 2, // Set max Y value 20% higher than the max data value
                    }]}
                    series={yDataSeries}
                    height={300}
                    margin={{ top: 10, bottom: 20, left: 60, right: 10 }}
                    borderRadius={1}
                    isAnimationActive={false}
                />
            </Box>
            <CardActions sx={{ justifyContent: 'center' }}>
                {yDataSeries.map((series) => (
                    <Typography key={series.name} variant="body2" sx={{ marginX: 1, display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: series.color,
                            marginRight: 4,
                        }} />
                        {series.name}
                    </Typography>
                ))}
            </CardActions>
        </>
    );
};

export default GroupedBarChartFluktuation;
