import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Stack, Card } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigTableCard from '../../../components/card/BigTableCard';
import { useTheme } from '@mui/material/styles'; // Importiere das Thema
import VerkaufsmengenWarengruppenGesamtPopup from '../../../components/popup/VerkaufsmengenWarengruppenGesamtPopup'; // Import the popup component


const Verkaufsmenge = () => {
    const { filterState, handleDatumChange } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const [warengruppenData, setWarengruppenData] = useState([]);
    const [monthlyData, setMonthlyData] = useState([]); // State für die Daten der letzten 6 Monate
    const theme = useTheme(); // Access the theme
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedWarengruppe, setSelectedWarengruppe] = useState(null);


    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchWarengruppenData();
            fetchMonthlyWarengruppenData(); // Neue Funktion, um Daten der letzten 6 Monate zu holen
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
    
        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
    
        return last6Months.reverse();
    };
    
    
    

    const fetchWarengruppenData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/produkte-warengruppe-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const aggregatedData = response.data.results.reduce((acc, item) => {
                const existingEntry = acc.find(entry => entry.warengruppennummer === item.warengruppennummer);
                const verkaufsmenge = item.verkaufsmenge || 0;
                const verkaufsmengeVJ = item.verkaufsmengeVJ || 0;

                if (existingEntry) {
                    existingEntry.verkaufsmenge += verkaufsmenge;
                    existingEntry.verkaufsmengeVJ += verkaufsmengeVJ;
                } else {
                    acc.push({
                        warengruppennummer: item.warengruppennummer,
                        warengruppe: item.warengruppe,
                        verkaufsmenge,
                        verkaufsmengeVJ,
                    });
                }

                return acc;
            }, []);

            const finalData = aggregatedData.map((entry) => {
                const { verkaufsmenge, verkaufsmengeVJ } = entry;
                const steigerungProzent = verkaufsmengeVJ > 0
                    ? ((verkaufsmenge - verkaufsmengeVJ) / verkaufsmengeVJ) * 100
                    : 0;

                return {
                    ...entry,
                    steigerungProzent: steigerungProzent.toFixed(2),
                };
            });

            setWarengruppenData(finalData);
        } catch (error) {
            console.error('Error fetching warengruppen data:', error);
        }
    };

    const fetchMonthlyWarengruppenData = async () => {
        const last6MonthsArray = getLast6Months(filterState.datum?.value);
    
        try {
            // Create an array of promises for each month
            const promises = last6MonthsArray.map(month =>
                axiosInstance.get('/scorecards_month/produkte-warengruppe-gesamt/', {
                    params: {
                        jahrMonat: month,
                    },
                }).then(response => ({ month, data: response.data.results }))
            );
    
            // Wait for all promises to resolve
            const results = await Promise.all(promises);
            console.log('API responses:', results);
    
            // Initialize an empty object to store aggregated data
            const aggregatedData = {};
    
            // Process each month's data
            results.forEach(({ month, data }) => {
                data.forEach(item => {
                    const warengruppennummer = item.warengruppennummer;
                    const warengruppe = item.warengruppe;
                    const verkaufsmenge = item.verkaufsmenge || 0;
                    const monthKey = month;
    
                    if (!aggregatedData[warengruppennummer]) {
                        // Initialize the entry
                        aggregatedData[warengruppennummer] = {
                            warengruppe,
                            [monthKey]: verkaufsmenge,
                        };
                    } else {
                        // If the monthKey doesn't exist, initialize it
                        if (!aggregatedData[warengruppennummer][monthKey]) {
                            aggregatedData[warengruppennummer][monthKey] = verkaufsmenge;
                        } else {
                            // Sum the verkaufsmenge
                            aggregatedData[warengruppennummer][monthKey] += verkaufsmenge;
                        }
                    }
                });
            });
    
            // Log the aggregated data
            console.log('Aggregated data:', aggregatedData);
    
            // Convert the aggregated data object to an array
            const finalData = Object.values(aggregatedData);
    
            // Update state
            setMonthlyData(finalData);
    
            // Log the final data
            console.log('Final monthly data:', finalData);
        } catch (error) {
            console.error('Error fetching monthly warengruppen data:', error);
        }
    };
    


    useEffect(() => {
        console.log('Updated monthlyData:', monthlyData);
    }, [monthlyData]);
    
    
    
    

    const columns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ' },
        { accessorKey: 'steigerungProzent', header: 'Steigerung (%)' },
    ];

    const monthlyColumns = [
        { accessorKey: 'warengruppe', header: 'Warengruppe' },
        ...getLast6Months(filterState.datum?.value).map(month => ({
            header: month,
            accessorFn: (row) => row[month],
            id: month, // Provide a unique ID for the column
        })),
    ];

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Verkaufsmengen"
                        subtitle="Übersicht über die Verkaufsmengen im Sortiment"
                        Icon={BarChartIcon}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <DropdownFilter
                        label="Datum"
                        options={datumOptions}
                        value={filterState.datum}
                        onChange={handleDatumChange}
                    />
                </Box>
            </Box>

            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <BigTableCard
                        columns={columns}
                        data={warengruppenData}
                        onRowClick={(row) => {
                            console.log('Clicked row:', row);
                            setSelectedWarengruppe(row);
                            setPopupOpen(true);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <BigTableCard
                        columns={monthlyColumns}
                        data={monthlyData}
                        onRowClick={(row) => console.log('Clicked row:', row)}
                    />
                </Grid>
            </Grid>

            {selectedWarengruppe && (
    <VerkaufsmengenWarengruppenGesamtPopup
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        warengruppennummer={selectedWarengruppe.warengruppennummer}
        warengruppe={selectedWarengruppe.warengruppe}
        datum={filterState.datum?.value}
    />
)}
        </Layout>
    );
};

export default Verkaufsmenge;
