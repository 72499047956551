import dayjs from 'dayjs';

// Function to get the same weekday one year back
export const getSameWeekdayOneYearBack = (date) => {
    // Parse the given date
    const givenDate = dayjs(date);

    // Subtract one year
    const dateOneYearBack = givenDate.subtract(1, 'year');

    // Find the same weekday of the original date
    const sameWeekdayOneYearBack = dateOneYearBack.day(givenDate.day());

    return sameWeekdayOneYearBack.format('YYYY-MM-DD');
};

export const getLast6WeeksSameWeekday = (currentDate) => {
    const dates = [];
    for (let i = 1; i <= 6; i++) {
        dates.push(dayjs(currentDate).subtract(i, 'week').format('YYYY-MM-DD'));
    }
    return dates;
};
