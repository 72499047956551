// components/charts/BarChartVariableYAxis.js

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Slider, Typography } from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTheme } from '@mui/material/styles';

const BarChartVariableYAxis = ({
  data,
  dataKeys,
  xAxisKey,
  yAxisMax,
  yAxisMin,
  height,
  width,
  barColors,
  legendNames,
}) => {
  const theme = useTheme();

  // Berechne den maximalen Datenwert, falls yAxisMax nicht angegeben ist
  const maxDataValue = data.reduce((max, item) => {
    dataKeys.forEach((key) => {
      if (item[key] > max) max = item[key];
    });
    return max;
  }, 0);

  const initialYAxisMax = yAxisMax || maxDataValue;

  const [currentYAxisMax, setCurrentYAxisMax] = useState(initialYAxisMax);

  const handleSliderChange = (event, newValue) => {
    setCurrentYAxisMax(newValue);
  };

  // Standardfarben setzen, falls keine Farben übergeben werden
  const defaultBarColors = [
    theme.palette.primary.main,        // Für das aktuelle Jahr
    theme.palette.chart?.pink || '#D81B60', // Für das Vorjahr (Fallback-Farbe)
  ];

  return (
    <Box sx={{ width: width || '100%', padding: 2 }}>
      <Box sx={{ width: '100%', height: height || 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis
              dataKey={xAxisKey}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
            />
            <YAxis
              domain={[yAxisMin || 0, currentYAxisMax]}
              allowDataOverflow={true}
              tickFormatter={(value) => value.toLocaleString('de-DE')}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip
              formatter={(value) => value.toLocaleString('de-DE')}
              contentStyle={{ fontSize: '12px' }}
            />
            <Legend wrapperStyle={{ fontSize: '12px' }}
                                    iconType="circle"
                                    />
            {dataKeys.map((key, index) => (
              <Bar
                key={key}
                dataKey={key}
                fill={
                  barColors && barColors[index]
                    ? barColors[index]
                    : defaultBarColors[index] || '#8884d8'
                }
                name={
                  legendNames && legendNames[index]
                    ? legendNames[index]
                    : key
                }
                barSize={40}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ width: '100%', mt: 2 }}>

        <Slider
          value={currentYAxisMax}
          onChange={handleSliderChange}
          min={yAxisMin || 0}
          max={initialYAxisMax}
          step={initialYAxisMax / 100}
          marks={false} // Entfernt die Markierungen

          valueLabelDisplay="auto"
        />
      </Box>
    </Box>
  );
};

BarChartVariableYAxis.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  yAxisMax: PropTypes.number,
  yAxisMin: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barColors: PropTypes.arrayOf(PropTypes.string),
  legendNames: PropTypes.arrayOf(PropTypes.string),
};

export default BarChartVariableYAxis;
