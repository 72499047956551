// Overview.jsx

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Select,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  Typography,
  IconButton,
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance';
import Layout from '../../../components/layout/Onboarding/Onboarding';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import { useNavigate } from 'react-router-dom';
import { FilterContext } from '../../../provider/FilterProvider'; // Importiere den FilterContext
import Button from '@mui/material/Button';


// **Import der Icons**
import EditIcon from '@mui/icons-material/Edit';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const Overview = () => {
  const [employees, setEmployees] = useState([]);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [filterState, setFilterState] = useState({ filiale: null });
  const [openAssignDialog, setOpenAssignDialog] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState('');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [editingAssignment, setEditingAssignment] = useState(null);

  const navigate = useNavigate();
  const { handleEmployeeChange } = useContext(FilterContext); // Verwende den FilterContext

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
          params: {
            filiale: filterState.filiale?.value,
          },
        });

        // Berechne das Datum, das 4 Monate vor dem heutigen Datum liegt
        const fourMonthsAgo = new Date();
        fourMonthsAgo.setMonth(fourMonthsAgo.getMonth() - 4);

        // Filtere die Mitarbeiter nach Eintrittsdatum in den letzten 4 Monaten
        const filteredEmployees = response.data.results.filter((employee) => {
          const eintrittDate = new Date(employee.eintritt);
          return eintrittDate >= fourMonthsAgo;
        });

        setEmployees(filteredEmployees);
      } catch (error) {
        console.error('Fehler beim Laden der Mitarbeiterdaten:', error);
      }
    };

    const fetchOnboardingPlans = async () => {
      try {
        const response = await axiosInstance.get('/onboarding/onboarding-plans/');
        setOnboardingPlans(response.data.results);
      } catch (error) {
        console.error('Fehler beim Laden der Onboarding-Pläne:', error);
      }
    };

    const fetchAssignments = async () => {
      try {
        const response = await axiosInstance.get('/onboarding/employee-assignments/');
        setAssignments(response.data.results);
      } catch (error) {
        console.error('Fehler beim Laden der Zuweisungen:', error);
      }
    };

    fetchEmployees();
    fetchOnboardingPlans();
    fetchAssignments();
  }, [filterState]);

  const handleAssignPlan = (employee) => {
    const assignment = assignments.find((a) => a.employee === employee.mitarbeiterId);
    setSelectedEmployee(employee);
    setSelectedPlan(assignment ? assignment.onboarding_plan : '');
    setSelectedStatus(assignment ? assignment.status : 'in_progress');
    setEditingAssignment(assignment || null);
    setOpenAssignDialog(true);
  };

  const handleSaveAssignment = async () => {
    try {
      const data = {
        employee: selectedEmployee.mitarbeiterId,
        onboarding_plan: selectedPlan,
        status: selectedStatus,
      };

      if (editingAssignment) {
        // Aktualisieren der bestehenden Zuweisung
        await axiosInstance.patch(`/onboarding/employee-assignments/${editingAssignment.id}/`, data);
      } else {
        // Erstellen einer neuen Zuweisung
        await axiosInstance.post('/onboarding/employee-assignments/', data);
      }

      // Aktualisieren der Zuweisungen
      const response = await axiosInstance.get('/onboarding/employee-assignments/');
      setAssignments(response.data.results);

      setOpenAssignDialog(false);
      setSelectedEmployee(null);
      setSelectedPlan('');
      setSelectedStatus('');
      setEditingAssignment(null);
    } catch (error) {
      if (error.response) {
        console.error('Fehler beim Zuweisen des Onboarding-Plans:', error.response.data);
      } else {
        console.error('Fehler beim Zuweisen des Onboarding-Plans:', error);
      }
    }
  };

  const handleViewOnboarding = (employeeId) => {
    handleEmployeeChange(employeeId); // Setze den ausgewählten Mitarbeiter im Kontext
    navigate('/onboarding/overview/mitarbeiter'); // Navigiere zur Mitarbeiter-Seite
  };

  return (
    <Layout>
      <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
        <PageHeader
          title="Onboarding"
          subtitle="Verwalte deine Onboarding-Pläne und überprüfe den Stand der aktuellen Einarbeitung."
          Icon={HailOutlinedIcon}
        />
        <TableContainer component={Paper} sx={{ mt: 4 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Mitarbeiter-ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Stammkostenstelle</TableCell>
                <TableCell>Eintritt</TableCell>
                <TableCell>Onboarding-Plan</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Aktionen</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.map((employee) => {
                const assignment = assignments.find((a) => a.employee === employee.mitarbeiterId);

                return (
                  <TableRow key={employee.mitarbeiterId}>
                    <TableCell>{employee.mitarbeiterId}</TableCell>
                    <TableCell>{employee.name}</TableCell>
                    <TableCell>{employee.stammkostenstelle}</TableCell>
                    <TableCell>
                      {employee.eintritt
                        ? new Date(employee.eintritt).toLocaleDateString('de-DE')
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {assignment ? assignment.onboarding_plan_name : 'Kein Plan zugewiesen'}
                    </TableCell>
                    <TableCell>
                      {assignment
                        ? assignment.status === 'in_progress'
                          ? 'begonnen'
                          : 'Abgeschlossen'
                        : 'N/A'}
                    </TableCell>
                    <TableCell>
                      {/* **Icon für Plan zuweisen/Bearbeiten** */}
                      <IconButton
                        color="primary"
                        onClick={() => handleAssignPlan(employee)}
                        sx={{ mr: 1 }}
                      >
                        <EditIcon />
                      </IconButton>
                      {/* **Icon für Onboarding anzeigen** */}
                      <IconButton
                        color="primary"
                        onClick={() => handleViewOnboarding(employee.mitarbeiterId)}
                      >
                        <ArrowForwardOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Dialog zum Zuweisen eines Onboarding-Plans und Bearbeiten des Status */}
      <Dialog open={openAssignDialog} onClose={() => setOpenAssignDialog(false)}>
        <DialogTitle>
          {editingAssignment ? 'Zuweisung bearbeiten' : 'Onboarding-Plan zuweisen'}
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="plan-label">Onboarding-Plan</InputLabel>
            <Select
              labelId="plan-label"
              value={selectedPlan}
              onChange={(e) => setSelectedPlan(e.target.value)}
              label="Onboarding-Plan"
            >
              {onboardingPlans.map((plan) => (
                <MenuItem key={plan.id} value={plan.id}>
                  {plan.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              labelId="status-label"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              label="Status"
            >
              <MenuItem value="in_progress">In Bearbeitung</MenuItem>
              <MenuItem value="completed">Abgeschlossen</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAssignDialog(false)}>Abbrechen</Button>
          <Button onClick={handleSaveAssignment} variant="contained" disabled={!selectedPlan}>
            {editingAssignment ? 'Speichern' : 'Zuweisen'}
          </Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Overview;
