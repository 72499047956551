import WarningIcon from "@mui/icons-material/Warning";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React from "react";

export const getAlertSeverity = (scoreUmsatz) => {
    if (scoreUmsatz < 4) return 'error';
    if (scoreUmsatz >= 4 && scoreUmsatz < 7) return 'warning';
    if (scoreUmsatz >= 7) return 'success';
    return 'info';
};

export const getAlertIcon = (scoreUmsatz) => {
    const severity = getAlertSeverity(scoreUmsatz);
    switch (severity) {
        case 'error':
            return <WarningIcon sx={{color: 'red'}}/>;
        case 'warning':
            return <WarningIcon sx={{color: 'orange'}}/>;
        case 'success':
            return <CheckCircleIcon sx={{color: 'green'}}/>;
        default:
            return <WarningIcon/>;
    }
};
