import * as React from 'react';
import { Card, Typography, Stack, Paper, useTheme, Box } from '@mui/material';
import Sparkline from '../charts/Sparkline2';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';


const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };

    switch (category) {
        case 'Umsatz':
            return <EuroIcon {...iconProps} />;
        case 'Kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'Stundenleistung':
            return <TimerIcon {...iconProps} />;
        case 'Retourquote':
            return <CurrencyExchangeIcon {...iconProps} />;
        case 'Kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({ chartData, category, value, subHeader, numDataPoints = 7 }) {
    const theme = useTheme(); // Access the theme
    

    return (
        <Card sx={{
            padding: theme.spacing(2), // Increased padding for better spacing
            backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly transparent white background
            backdropFilter: 'blur(10px)', // Optional blur effect
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        {getCategoryIcon(category)}
                        <Typography
                            variant="h6"
                            className="db_datacard_category"
                            sx={{
                                fontWeight: theme.typography.h5.fontWeight, // Use theme font weight
                                fontSize: theme.typography.h6.fontSize, // Use theme font size
                                color: theme.palette.primary.main, // Use theme text color
                            }}
                        >
                            {category}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h4"
                        className="db_datacard_value"
                        sx={{
                            fontWeight: theme.typography.h4.fontWeight, // Use theme font weight
                            fontSize: theme.typography.h4.fontSize, // Use theme font size
                            color: theme.palette.primary.main, // Use theme success color
                            mt: theme.spacing(1), // Add a slight margin-top
                        }}
                    >
                        {value}
                    </Typography>
                </Box>
                {/* Subheader and trend icons conditionally rendered if subHeader is not empty */}
                {subHeader && (
                    <Typography
                        variant="body2"
                        className="db_datacard_subheader"
                        sx={{
                            fontSize: theme.typography.body2.fontSize, // Use theme font size
                            color: theme.palette.text.secondary, // Use theme secondary text color
                            mt: theme.spacing(2), // Margin top to create space between value and subheader
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <Box sx={{ mr: 1 }}>
                            {subHeader.startsWith('-') 
                                ? <Box sx={{ color: theme.palette.error.main }}>↓</Box> 
                                : <Box sx={{ color: theme.palette.success.main }}>↑</Box>}
                        </Box>
                        {subHeader} letztes Jahr
                    </Typography>
                )}
            </Box>
            <Box sx={{ width: '40%' }}>
                <Sparkline data={chartData} numDataPoints={numDataPoints} />
            </Box>
        </Card>
    );
    
}
