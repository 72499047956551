import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Checkbox,
  OutlinedInput,
  ListItemText,
  Box,
  Typography,
  Grid,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import 'dayjs/locale/de';

dayjs.extend(updateLocale);
dayjs.locale('de');
dayjs.updateLocale('de', {
  weekStart: 1, // Setze den Wochenstart auf Montag
});

const FilterDialog = ({
  open,
  onClose,
  dateRange,
  setDateRange,
  timeFilter,
  setTimeFilter,
  allFilialen,
  selectedFilialen,
  setSelectedFilialen,
  allWarengruppen,
  selectedWarengruppen,
  setSelectedWarengruppen,
  allArticles,
  selectedArticles,
  setSelectedArticles,
  isAllSelected,
  handleSelectAll,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          maxHeight: '80vh', // Hohe des Dialogs
          padding: 2, // Padding für den Dialog
        },
      }}
    >
      <DialogTitle sx={{ fontSize: '1.25rem' }}>Filter auswählen</DialogTitle>
      <DialogContent>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
          {/* Schrittweiser Auswahlprozess */}
          <Box>
            <Grid container spacing={3}>
              {/* 1. Zeit auswählen */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  1. Zeitraum
                </Typography>
                {/* DateRangePicker mit SingleInputField */}
                <DateRangePicker
                  value={dateRange}
                  onChange={(newValue) => setDateRange(newValue)}
                  slots={{ field: SingleInputDateRangeField }} // Der SingleInput wird verwendet
                  calendars={1}
                  slotProps={{
                    shortcuts: {
                      items: [
                        { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                        { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                        { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                        { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                        { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                        { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                        { label: 'Zurücksetzen', getValue: () => [null, null] },
                      ],
                    },
                  }}
                />
                {/* Zeitraum-Modus */}
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel sx={{ fontSize: '0.875rem' }}>Zeitraum auswählen</InputLabel>
                  <Select
                    value={timeFilter}
                    onChange={(event) => setTimeFilter(event.target.value)}
                    input={<OutlinedInput label="Zeitraum auswählen" />}
                    sx={{ fontSize: '0.875rem' }} // Kleinere Schriftgröße
                  >
                    <MenuItem value="Monat">Monat</MenuItem>
                    <MenuItem value="Woche">Woche</MenuItem>
                    <MenuItem value="Tag">Tag</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* 2. Filialen auswählen */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  2. Filialen
                </Typography>
                <FormControl fullWidth>
                  <InputLabel sx={{ fontSize: '0.875rem' }}>Filialen auswählen</InputLabel>
                  <Select
                    multiple
                    value={selectedFilialen}
                    onChange={(event) => setSelectedFilialen(event.target.value)}
                    input={<OutlinedInput label="Filialen auswählen" />}
                    renderValue={(selected) => (isAllSelected(selected, allFilialen) ? 'Alle' : selected.join(', '))}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    <MenuItem key="all" value="all">
                      <Checkbox
                        checked={isAllSelected(selectedFilialen, allFilialen)}
                        onChange={(event) => handleSelectAll(event, allFilialen, selectedFilialen, setSelectedFilialen)}
                      />
                      <ListItemText primary="Alle anzeigen" />
                    </MenuItem>
                    {allFilialen.map((filiale) => (
                      <MenuItem key={filiale} value={filiale}>
                        <Checkbox checked={selectedFilialen.indexOf(filiale) > -1} />
                        <ListItemText primary={filiale} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* 3. Warengruppen und Artikel */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', fontWeight: 'bold' }}>
                  3. Warengruppen und Artikel
                </Typography>
                {/* Warengruppen */}
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel sx={{ fontSize: '0.875rem' }}>Warengruppen auswählen</InputLabel>
                  <Select
                    multiple
                    value={selectedWarengruppen}
                    onChange={(event) => setSelectedWarengruppen(event.target.value)}
                    input={<OutlinedInput label="Warengruppen auswählen" />}
                    renderValue={(selected) => (isAllSelected(selected, allWarengruppen) ? 'Alle' : selected.join(', '))}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    <MenuItem key="all" value="all">
                      <Checkbox
                        checked={isAllSelected(selectedWarengruppen, allWarengruppen)}
                        onChange={(event) => handleSelectAll(event, allWarengruppen, selectedWarengruppen, setSelectedWarengruppen)}
                      />
                      <ListItemText primary="Alle anzeigen" />
                    </MenuItem>
                    {allWarengruppen.map((warengruppe) => (
                      <MenuItem key={warengruppe} value={warengruppe}>
                        <Checkbox checked={selectedWarengruppen.indexOf(warengruppe) > -1} />
                        <ListItemText primary={warengruppe} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {/* Artikel */}
                <FormControl fullWidth>
                  <InputLabel sx={{ fontSize: '0.875rem' }}>Artikel auswählen</InputLabel>
                  <Select
                    multiple
                    value={selectedArticles}
                    onChange={(event) => setSelectedArticles(event.target.value)}
                    input={<OutlinedInput label="Artikel auswählen" />}
                    renderValue={(selected) => (isAllSelected(selected, allArticles) ? 'Alle' : selected.join(', '))}
                    sx={{ fontSize: '0.875rem' }}
                  >
                    <MenuItem key="all" value="all">
                      <Checkbox
                        checked={isAllSelected(selectedArticles, allArticles)}
                        onChange={(event) => handleSelectAll(event, allArticles, selectedArticles, setSelectedArticles)}
                      />
                      <ListItemText primary="Alle anzeigen" />
                    </MenuItem>
                    {allArticles.map((artikel) => (
                      <MenuItem key={artikel} value={artikel}>
                        <Checkbox checked={selectedArticles.indexOf(artikel) > -1} />
                        <ListItemText primary={artikel} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </LocalizationProvider>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Abbrechen</Button>
        <Button onClick={onClose} color="primary">Bestätigen</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
