import React, { useEffect, useState, useRef } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';

const GroupedBarChart = ({ data }) => {
  const theme = useTheme();
  const [chartData, setChartData] = useState([]);
  const chartContainerRef = useRef(); // Ref for the chart container
  const { width = 1 } = useResizeObserver({ ref: chartContainerRef });
  const [fontSize, setFontSize] = useState(14); // Default font size for labels
  const [leftMargin, setLeftMargin] = useState(60); // Default margin for labels

  useEffect(() => {
    // Prepare the chart data by combining current year and previous year scores
    const formattedData = data.map((item) => ({
      name: item.x,
      currentScore: item.currentScore || 0,
      previousScore: item.previousScore || 0,
    }));
    setChartData(formattedData);
  }, [data]);

  useEffect(() => {
    // Dynamically adjust the font size based on the container width
    if (width < 400) {
      setFontSize(10);
    } else if (width < 800) {
      setFontSize(12);
    } else {
      setFontSize(14);
    }
  }, [width]);

  // Define the container height based on the number of bars or a default height
  const containerHeight = 350;

  // Custom Legend Renderer to make symbols round
  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ display: 'flex', alignItems: 'center', marginRight: 10 }}>
            <div
              style={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: entry.color,
                marginRight: 5,
              }}
            />
            <span style={{ fontSize }}>{entry.value}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={chartContainerRef} style={{ width: '100%', height: `${containerHeight}px` }}>
      <ResponsiveContainer width="100%" height={containerHeight}>
        <BarChart
          data={chartData}
          margin={{ top: 10, right: 20, bottom: 5, left: leftMargin }}
          layout="horizontal"
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="name"
            tick={{ fontSize: fontSize, fill: theme.palette.text.primary }}
          />
          <YAxis
            tick={{ fontSize: fontSize, fill: theme.palette.text.primary }}
            type="number"
          />
<Tooltip
  formatter={(value, name, props) => {
    // Nutze props.payload um den DatenKey zu ermitteln und korrekt zuzuweisen
    const label = props.dataKey === 'currentScore' ? 'aktuelles Jahr' : 'Vorjahr';

    if (value === null || value === undefined || isNaN(value)) {
      return ['Keine Daten', label];
    }

    return [`${value.toLocaleString('de-DE')} €`, label];
  }}
/>


          <Legend content={renderLegend} />
          <Bar
            dataKey="previousScore"
            name="Vorjahr"
            fill={theme.palette.chart.pink || theme.palette.secondary.main}
            radius={[5, 5, 0, 0]}
          />
          <Bar
            dataKey="currentScore"
            name="Aktuelles Jahr"
            fill={theme.palette.primary.main}
            radius={[5, 5, 0, 0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GroupedBarChart;
