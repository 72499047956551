import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, Typography, Stack, Card } from '@mui/material';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import DataCard from '../../../components/card/personalcontrolling/DataCardMonat';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import { useTheme } from '@mui/material/styles'; // Importiere das Thema

const Overview = () => {
    const { filterState, handleDatumChange } = useContext(FilterContext);
    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVM: 0,
        umsatzAbweichung6M: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVM: 0,
        kundenanzahlAbweichung6M: 0,
        kundenanzahlAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        chartData: [],
    });
    const [datumOptions, setDatumOptions] = useState([]);
    const [performanceLast6Months, setPerformanceLast6Months] = useState([]); // State for the last 6 months' data
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });
    const [totalEmployees, setTotalEmployees] = useState(0); // Neuer State für die Gesamtanzahl der Mitarbeiter
    const theme = useTheme(); // Access the theme

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLast6Months(); // Fetch last 6 months data when the date is filtered
            fetchPersonalData(); // Fetch Krankenquote and Fluktuation data
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    const fetchPerformanceData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            const allFilialenData = response.data.results;

            const {
                umsatzSumme,
                umsatzVJSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                kundenanzahlSumme,
                kundenanzahlVJSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
            } = calculatePerformanceData(allFilialenData);

            setPerformanceData({
                umsatz: umsatzSumme,
                umsatzVJ: umsatzVJSumme,
                umsatzAbweichungVM,
                umsatzAbweichung6M,
                umsatzAbweichungVJ,
                kundenanzahl: kundenanzahlSumme,
                kundenanzahlVJ: kundenanzahlVJSumme,
                kundenanzahlAbweichungVM,
                kundenanzahlAbweichung6M,
                kundenanzahlAbweichungVJ,
                leistung,
                leistungVJ,
                kundenbon,
                kundenbonVJ,
                chartData: allFilialenData.map((item) => ({
                    umsatz: item.umsatz || 0,
                    kundenanzahl: item.kundenanzahl || 0,
                    leistung: item.leistung || 0,
                    kundenbon: item.kundenbon || 0,
                })),
            });
        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren

        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }

        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    const fetchPerformanceDataLast6Months = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende 'YYYY.MM'
        const last6Months = getLast6Months(currentMonth);

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/performance-kennzahl-gesamt/', {
                    params: { jahrMonat: month },
                }))
            );

            const last6MonthsData = responses.map((response, index) => {
                const umsatzSumme = response.data.results
                    .filter(filiale => filiale.umsatz > 1) // Nur Umsätze > 1
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                return {
                    month: last6Months[index],
                    umsatz: umsatzSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenbon: kundenbon,
                    leistung: leistung
                };
            });

            setPerformanceLast6Months(last6MonthsData); // Setze die Daten für alle Diagramme und Karten
        } catch (error) {
            console.error('Error fetching data for last 6 months:', error);
        }
    };

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );

            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Kumulieren der Fluktuation-Daten
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0 
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100 
                    : 0; // Ergebnis in %

                // Nur Eintritte und Austritte des gefilterten Monats
                const eintritteFiltered = data.reduce((sum, item) => sum + (item.eintritte || 0), 0);
                const austritteFiltered = data.reduce((sum, item) => sum + (item.austritte || 0), 0);

                // **Kumulieren der EintritteM und AustritteM** für alle Filialen
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0); // Kumulierte Eintritte (M)
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0); // Kumulierte Austritte (M)

                // Gesamtanzahl der Mitarbeiter für den aktuellen Monat ermitteln
                if (index === last6Months.length - 1) { // Aktueller Monat
                    const mitarbeiterbestand = data.reduce((sum, item) => sum + (item.mitarbeiterbestand || 0), 0);
                    setTotalEmployees(mitarbeiterbestand);
                }

                return {
                    month: last6Months[index],
                    austritte6M,
                    mitarbeiterbestand6M,
                    eintritte6M,
                    fluktuation,
                    eintritteFiltered,
                    austritteFiltered,
                    eintritteM, // Kumulierte Eintritte (M)
                    austritteM,  // Kumulierte Austritte (M)
                    köpfeDifferenz, // Kumulierte Köpfe-Differenz (Bedarf an Mitarbeiter)
                    stundenDifferenz, // Kumulierte Stunden-Differenz (Bedarf an Stunden)
                    überstundenAbs,  // Kumulierte Überstunden (Auslastung)
                };
            });

            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });
        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Personalcontrolling"
                        subtitle="Dieser Übersicht bietet einen schnellen Überblick über die Personalsituation im gesamten Unternehmen. Beim Klicken auf die entsprechende Card können detaillierte Informationen abgerufen werden, um tiefere Einblicke zu erhalten und gezielte Maßnahmen abzuleiten. Ziel ist es, den Ressourceneinsatz im Unternehmen effizient zu steuern und eine optimale Personalverteilung sicherzustellen."
                        Icon={BarChartIcon}
                    />
                </Box>

                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <DropdownFilter
                        label="Datum"
                        options={datumOptions}
                        value={filterState.datum}
                        onChange={handleDatumChange}
                    />
                </Box>
            </Box>

            <Grid container spacing={3} mt={4}>
                {/* Leistung */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Leistung"
                            sector="leistung"
                            value={performanceData.leistung ? performanceData.leistung.toFixed(2) : '0.00'}
                            subHeaders={[
                                `${performanceData.leistungVJ.toFixed(2)} € im letzten Jahr`,
                                `${((performanceData.leistung - performanceData.leistungVJ) / performanceData.leistungVJ * 100).toFixed(2)}% zum Vorjahr`
                            ]}
                            chartData={performanceLast6Months.map(item => item.leistung)} // Nutze Daten der letzten 6 Monate für das Diagramm
                        />
                    </Stack>
                </Grid>

                {/* Krankenquote */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Krankenquote"
                            sector="krankenquote"
                            value={`${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2) : '0.00'}%`}
                            subHeaders={[
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2) : '0.00'}% zum Vormonat`,
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                            ]}
                            chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutze die Krankenquote-Daten für den Sparkline-Chart
                        />
                    </Stack>
                </Grid>

                {/* Abwesenheit */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Abwesenheiten"
                            sector="abwesenheiten"
                            value={`${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2) : '0.00'}%`}
                            subHeaders={[
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2) : '0.00'}% zum Vormonat`,
                                `${personalData.krankenquote.length > 0 ? personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                            ]}
                            chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutze die Krankenquote-Daten für den Sparkline-Chart
                        />
                    </Stack>
                </Grid>

                {/* Struktur */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Struktur"
                            sector="struktur"
                            value={`${totalEmployees} Mitarbeiter`}
                            chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                        />
                    </Stack>
                </Grid>

                {/* Fluktuation */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Fluktuation"
                            sector="fluktuation"
                            value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2) : '0.00'}%`}
                            subHeaders={[
                                `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM.toFixed(2) : '0.00'} Eintritte im gefilterten Monat`,
                                `${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM.toFixed(2) : '0.00'} Austritte im gefilterten Monat`
                            ]}
                            chartData={personalData.fluktuation.map(item => item.fluktuation)} // Nutze die Fluktuation-Daten für den Sparkline-Chart
                        />
                    </Stack>
                </Grid>

                {/* Bedarf */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Bedarf"
                            sector="bedarf"
                            value={
                                <>
                                    <Typography variant="h4" component="span">
                                        {personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].köpfeDifferenz : '0'}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ fontSize: '0.8rem', ml: 1 }}>
                                        Köpfe
                                    </Typography>
                                    <Typography variant="h4" component="span" sx={{ ml: 2 }}>
                                        {personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].stundenDifferenz : '0'}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ fontSize: '0.8rem', ml: 1 }}>
                                        Stunden
                                    </Typography>
                                </>
                            }
                            chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                        />
                    </Stack>
                </Grid>

                {/* Neuzugänge */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Neuzugänge"
                            sector="neuzugaenge"
                            value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM.toFixed(2) : '0.00'} Eintritte`}
                            chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                        />
                    </Stack>
                </Grid>

                {/* Abgänge */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Abgänge"
                            sector="austritte"
                            value={`${personalData.fluktuation.length > 0 ? personalData.fluktuation[personalData.fluktuation.length - 1].austritteM.toFixed(2) : '0.00'} Austritte`}
                            chartData={personalData.fluktuation.map(item => item.köpfeDifferenz)}
                        />
                    </Stack>
                </Grid>

                {/* Auslastung */}
                <Grid item xs={12}>
                    <Stack direction="column" spacing={2}>
                        <DataCard
                            category="Auslastung"
                            sector="auslastung"
                            value={`${personalData.fluktuation.length > 0 ? Math.round(personalData.fluktuation[personalData.fluktuation.length - 1].überstundenAbs) : '0'} h`}
                            subHeaders={[]}
                            chartData={personalData.fluktuation.map(item => Math.round(item.überstundenAbs))} // Runde auf 0 Kommastellen
                        />
                    </Stack>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Overview;
