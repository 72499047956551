// Todo.jsx
import React, { useState, useEffect } from 'react';
import {
    Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Select,
    MenuItem, InputLabel, FormControl, Checkbox, ListItemText, OutlinedInput,
    Snackbar, Alert, Typography
} from '@mui/material';
import Layout from '../../../components/layout/Tagesplaner/Tagesplaner';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance'; // Importiere deine Axios-Instanz

const WEEKDAYS = [
    { name: 'Montag', id: 0 },
    { name: 'Dienstag', id: 1 },
    { name: 'Mittwoch', id: 2 },
    { name: 'Donnerstag', id: 3 },
    { name: 'Freitag', id: 4 },
    { name: 'Samstag', id: 5 },
    { name: 'Sonntag', id: 6 },
];

const weekdays = WEEKDAYS.map(wd => wd.name); // ['Montag', 'Dienstag', ...]
const hoursArray = Array.from({ length: 16 }, (_, i) => i + 5); // 5 bis 20 Uhr

const Todo = () => {
    const [open, setOpen] = useState(false);
    const [selectedHour, setSelectedHour] = useState(null);
    const [selectedWeekday, setSelectedWeekday] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        description: '',
        filialnummer: '',
        due_time: '',
        weekdays: [],
    });
    const [todos, setTodos] = useState([]); // Zustand für bestehende Todos
    const [filialeOptions, setFilialeOptions] = useState([]); // Zustand für Filialoptionen
    const [selectedFilialnummer, setSelectedFilialnummer] = useState([]); // Zustand für ausgewählte Filialen
    const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

    // Fetch Filialoptionen vom Backend
    useEffect(() => {
        const fetchFilialeOptions = async () => {
            try {
                const response = await axiosInstance.get('stammdaten/unique-filiale/');
                setFilialeOptions(response.data); // Annahme: response.data ist ein Array von Filialen
            } catch (error) {
                console.error('Fehler beim Laden der Filialen:', error);
                setSnackbar({ open: true, message: 'Fehler beim Laden der Filialen.', severity: 'error' });
            }
        };
        fetchFilialeOptions();
    }, []);

    // Fetch bestehende Todos vom Backend
    useEffect(() => {
        const fetchTodos = async () => {
            try {
                const response = await axiosInstance.get('/tagesplaner/recurring-todos/');
                console.log('Todos API Response:', response.data); // Debugging

                if (response.data.results) {
                    setTodos(response.data.results);
                } else if (Array.isArray(response.data)) {
                    setTodos(response.data);
                } else {
                    console.warn('Unerwartete API-Antwortstruktur:', response.data);
                    setTodos([]); // Setze auf leeres Array, um Fehler zu vermeiden
                }
            } catch (error) {
                console.error('Fehler beim Abrufen der Todos:', error);
                setSnackbar({ open: true, message: 'Fehler beim Laden der Todos.', severity: 'error' });
            }
        };

        fetchTodos();
    }, []);

    // Handler für Filialfilter
    const handleFilialChange = (event) => {
        const {
            target: { value },
        } = event;
        const filialnummern = typeof value === 'string' ? value.split(',') : value;
        setSelectedFilialnummer(filialnummern);

        // Voreinstellung im Formular auf die erste ausgewählte Filialnummer
        if (Array.isArray(filialnummern) && filialnummern.length === 1) {
            setFormData(prevState => ({
                ...prevState,
                filialnummer: Number(filialnummern[0]),
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                filialnummer: '',
            }));
        }
    };

    const handleCellClick = (hour, weekdayId) => {
        console.log(`Zelle geklickt: Uhrzeit=${hour}, Wochentag-ID=${weekdayId}`); // Debugging
        setSelectedHour(hour);
        setSelectedWeekday(weekdayId);
        setFormData(prevState => ({
            ...prevState,
            due_time: `${hour.toString().padStart(2, '0')}:00`, // Formatierung sicherstellen
            weekdays: [weekdayId], // Standardmäßig den ausgewählten Wochentag setzen
            // Voreinstellung der Filialnummer, falls genau eine Filiale ausgewählt ist
            filialnummer: selectedFilialnummer.length === 1 ? selectedFilialnummer[0] : '',
        }));
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setFormData({
            title: '',
            description: '',
            filialnummer: selectedFilialnummer.length === 1 ? selectedFilialnummer[0] : '',
            due_time: '',
            weekdays: [],
        });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: name === 'filialnummer' ? Number(value) : value, // Konvertiere filialnummer zu Number
        }));
    };

    const handleWeekdaysChange = (event) => {
        const { target: { value } } = event;
        setFormData(prevState => ({
            ...prevState,
            weekdays: typeof value === 'string' ? value.split(',') : value,
        }));
    };

    const handleSubmit = async () => {
        // Validierung der erforderlichen Felder
        if (!formData.title || !formData.filialnummer || !formData.due_time || formData.weekdays.length === 0) {
            setSnackbar({ open: true, message: 'Bitte alle erforderlichen Felder ausfüllen.', severity: 'error' });
            return;
        }
    
        console.log('Form Data:', formData); // Debugging
    
        try {
            const response = await axiosInstance.post('/tagesplaner/recurring-todos/', { // Korrigierte URL ohne führenden '/'
                titel: formData.title,
                beschreibung: formData.description,
                filialnummer: formData.filialnummer, // ID der Filiale
                due_time: formData.due_time,
                weekdays: formData.weekdays, // Liste der Wochentags-IDs
                todo_type: 'recurring',
            });
    
            // Erfolgreich erstellt
            const newTodo = response.data;
            setTodos(prevTodos => [...prevTodos, newTodo]);
            handleClose();
            setSnackbar({ open: true, message: 'Todo erfolgreich erstellt!', severity: 'success' });
        } catch (error) {
            console.error('Fehler beim Erstellen des Todos:', error);
            if (error.response && error.response.data) {
                console.error('Backend-Fehlermeldung:', error.response.data);
                setSnackbar({ open: true, message: JSON.stringify(error.response.data), severity: 'error' });
            } else {
                setSnackbar({ open: true, message: 'Fehler beim Erstellen des Todos.', severity: 'error' });
            }
        }
    };
    
    

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbar(prev => ({ ...prev, open: false }));
    };

    // Filtern der Todos basierend auf ausgewählten Filialnummern
    const filteredTodos = selectedFilialnummer.length > 0
        ? todos.filter(todo => selectedFilialnummer.includes(todo.filialnummer))
        : todos;

    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader
                    title="Filialcheck- Analyse & Todos Überblick"
                    subtitle="Verwalten deine Filialchecks, erstellen neue Todos und überblicke alle Todos nach Filialen."
                    Icon={ChecklistIcon}
                />

                {/* Filialfilter */}
                <Box mt={3} mb={3}>
                    <FormControl fullWidth>
                        <InputLabel id="filial-filter-label">Filialen</InputLabel>
                        <Select
                            labelId="filial-filter-label"
                            id="filial-filter"
                            multiple
                            value={selectedFilialnummer}
                            onChange={handleFilialChange}
                            input={<OutlinedInput label="Filialen" />}
                            renderValue={(selected) => {
                                if (selected.length === 0) return 'Alle Filialen';
                                const selectedNames = filialeOptions
                                    .filter(filiale => selected.includes(filiale.filialnummer))
                                    .map(filiale => filiale.filiale)
                                    .join(', ');
                                return selectedNames;
                            }}
                        >
                            {filialeOptions.map((filiale) => (
                                <MenuItem key={filiale.filialnummer} value={filiale.filialnummer}>
                                    <Checkbox checked={selectedFilialnummer.indexOf(filiale.filialnummer) > -1} />
                                    <ListItemText primary={filiale.filiale} />
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {/* Matrix anzeigen */}
                <Box>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Uhrzeit</TableCell>
                                    {weekdays.map((day) => (
                                        <TableCell key={day} align="center">{day}</TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {hoursArray.map((hour) => (
                                    <TableRow key={hour}>
                                        <TableCell component="th" scope="row">
                                            {`${hour}:00`}
                                        </TableCell>
                                        {WEEKDAYS.map((day) => (
                                            <TableCell
                                                key={day.id}
                                                align="center"
                                                sx={{ 
                                                    cursor: 'pointer', // Macht die Zelle anklickbar
                                                    bgcolor: '#f5f5f5', 
                                                    minWidth: 100, 
                                                    height: 50 
                                                }}
                                                onClick={() => handleCellClick(hour, day.id)} // onClick Handler mit Wochentag-ID
                                            >
                                                {Array.isArray(filteredTodos) && filteredTodos.filter(todo => {
                                                    // Annahme: todo.due_time ist im Format 'HH:MM'
                                                    const [todoHour, todoMinute] = todo.due_time.split(':').map(Number);
                                                    return todoHour === hour && todo.weekdays.includes(day.id);
                                                }).map(todo => (
                                                    <Box
                                                        key={todo.id}
                                                        sx={{
                                                            bgcolor: '#1976d2',
                                                            color: '#fff',
                                                            borderRadius: 1,
                                                            p: 0.5,
                                                            mb: 0.5,
                                                            textOverflow: 'ellipsis',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap'
                                                        }}
                                                    >
                                                        {todo.titel}
                                                    </Box>
                                                ))}
                                                {filteredTodos.filter(todo => {
                                                    const [todoHour, todoMinute] = todo.due_time.split(':').map(Number);
                                                    return todoHour === hour && todo.weekdays.includes(day.id);
                                                }).length === 0 && (
                                                    <Typography variant="body2" color="textSecondary">
                                                        Keine Todos
                                                    </Typography>
                                                )}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {/* Dialog/Formular */}
                <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                    <DialogTitle>Neues wiederkehrendes Todo erstellen</DialogTitle>
                    <DialogContent>
                        <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                            <TextField
                                fullWidth
                                label="Titel"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                margin="normal"
                                required
                            />
                            <TextField
                                fullWidth
                                label="Beschreibung"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                margin="normal"
                                multiline
                                rows={3}
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="filialnummer-label">Filiale</InputLabel>
                                <Select
                                    labelId="filialnummer-label"
                                    id="filialnummer"
                                    name="filialnummer"
                                    value={formData.filialnummer}
                                    onChange={handleChange}
                                    required
                                >
                                    {filialeOptions.map((filiale) => (
                                        <MenuItem key={filiale.filialnummer} value={filiale.filialnummer}>
                                            {filiale.filiale}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                fullWidth
                                label="Uhrzeit"
                                name="due_time"
                                type="time"
                                value={formData.due_time}
                                onChange={handleChange}
                                margin="normal"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                inputProps={{
                                    step: 300, // 5 Minuten Schritte
                                }}
                                required
                            />
                            <FormControl fullWidth margin="normal">
                                <InputLabel id="weekdays-label">Wochentage</InputLabel>
                                <Select
                                    labelId="weekdays-label"
                                    id="weekdays"
                                    multiple
                                    name="weekdays"
                                    value={formData.weekdays}
                                    onChange={handleWeekdaysChange}
                                    input={<OutlinedInput label="Wochentage" />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) return 'Alle Wochentage';
                                        const selectedNames = WEEKDAYS
                                            .filter(wd => selected.includes(wd.id))
                                            .map(wd => wd.name)
                                            .join(', ');
                                        return selectedNames;
                                    }}
                                >
                                    {WEEKDAYS.map((day) => (
                                        <MenuItem key={day.id} value={day.id}>
                                            <Checkbox checked={formData.weekdays.indexOf(day.id) > -1} />
                                            <ListItemText primary={day.name} />
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="secondary">Abbrechen</Button>
                        <Button onClick={handleSubmit} variant="contained" color="primary">Erstellen</Button>
                    </DialogActions>
                </Dialog>

                {/* Snackbar für Erfolg und Fehler */}
                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                >
                    <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
                        {snackbar.message}
                    </Alert>
                </Snackbar>
            </Box>
        </Layout>
    );
};

export default Todo;
