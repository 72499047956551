import React, { useRef } from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import useResizeObserver from 'use-resize-observer';

const BarChartVerfuegbarkeit = ({ title, data, labels }) => {
    const theme = useTheme();
    const chartContainerRef = useRef(); // Ref for the container
    const { width = 1, height = 1 } = useResizeObserver({ ref: chartContainerRef }); // Get container dimensions

    return (
        <Paper elevation={3} sx={{ padding: 2, width: '100%', height: '100%' }} ref={chartContainerRef}>
            <Typography variant="h6" gutterBottom>
                {title}
            </Typography>
            <BarChart
                xAxis={[
                    {
                        scaleType: 'band',
                        data: labels, // Use labels for the x-axis
                        show: true, // Display the x-axis
                        tickSize: 5, // Size of the ticks on the x-axis
                        label: null, // Axis label (adjust if needed)
                    },
                ]}
                yAxis={[
                    {
                        show: true, // Display the y-axis
                        label: null, // Axis label (adjust if needed)
                    },
                ]}
                series={[
                    {
                        data: data, // Data for the y-axis
                        label: title,
                        color: theme.palette.primary.main, // Bar color
                    },
                ]}
                height={300} // Dynamically adjust the height with a fallback
                width={width ? width - 20 : 400} // Dynamically adjust the width with a fallback
                margin={{ top: 10, bottom: 20, left: 30, right: 10 }} // Margins of the chart
                isAnimationActive={false} // Disable animation
            />
        </Paper>
    );
};

export default BarChartVerfuegbarkeit;
