// Kundenstatistik.js

import React, { useEffect, useState, useMemo } from 'react';
import axios from '../../../services/axiosInstance';
import {
  Box,
  Typography,
  Button,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  ListItemText,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BarChartIcon from '@mui/icons-material/BarChart';
import qs from 'qs';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/de';

import PageHeader from '../../../components/layout/Title/TitelSmall';
import KundenstatistikTabelle from '../../../components/table/KundenstatistikTabelle';
import Layout from '../../../components/layout/Filialsteuerung/filialsteuerung';

import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; // Korrigierter Import
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LicenseInfo } from '@mui/x-license-pro';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import MultiSelectDropdownFilter from '../../../components/filter/MultiSelectDropdownFilter'; // Import der Komponente

dayjs.extend(isoWeek);
dayjs.locale('de');

LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

const Kundenstatistik = () => {
  // States für Daten und Filter
  const [groupedData, setGroupedData] = useState({});
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]);
  const [viewMode, setViewMode] = useState('verkaufsgebiet'); // Ändern von 'filiale' zu 'verkaufsgebiet'

  const [selectedFilialen, setSelectedFilialen] = useState([]); // Standardmäßig nichts ausgewählt
  const [allFilialen, setAllFilialen] = useState([]);

  const [selectedVerkaufsgebiete, setSelectedVerkaufsgebiete] = useState([]); // Standardmäßig nichts ausgewählt
  const [allVerkaufsgebiete, setAllVerkaufsgebiete] = useState([]);

  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [timeFilter, setTimeFilter] = useState('Monat'); // "Monat", "Woche", "Tag"

  const [selectedDataKeys, setSelectedDataKeys] = useState([]);
  const [availableDataKeys, setAvailableDataKeys] = useState([]);

  const [filtersExpanded, setFiltersExpanded] = useState(false); // State für das Ein- und Ausklappen der Filter

  // Funktion zum Überprüfen, ob "Alle" ausgewählt ist
  const isAllSelected = (selected, allOptions) =>
    Array.isArray(selected) && Array.isArray(allOptions) && selected.length === allOptions.length;

  // Filialen abrufen bei Komponenten-Mount
  useEffect(() => {
    axios
      .get('/stammdaten/unique-filiale/')
      .then((response) => {
        const uniqueFilialen = response.data.map((filiale) => filiale.filiale);
        setAllFilialen(uniqueFilialen);
        setSelectedFilialen([]); // Standardmäßig nichts ausgewählt
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Filialen:', error);
      });
  }, []);

  // Verkaufsgebiete abrufen
  useEffect(() => {
    axios
      .get('/stammdaten/unique-verkaufsgebiet/')
      .then((response) => {
        const uniqueVerkaufsgebiete = response.data;
        setAllVerkaufsgebiete(uniqueVerkaufsgebiete);
        setSelectedVerkaufsgebiete([]); // Standardmäßig nichts ausgewählt
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Verkaufsgebiete:', error);
      });
  }, []);

  // Daten basierend auf viewMode, dateRange, timeFilter und ausgewählten Filialen/Verkaufsgebieten laden
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');
      let endpoint;

      if (viewMode === 'filiale') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/kund-month-filiale/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/kund-week-filiale/';
        } else {
          endpoint = '/fact/aggregated/kund-day-filiale/';
        }
      } else if (viewMode === 'verkaufsgebiet') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/kund-month-verkaufsgebiet/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/kund-week-verkaufsgebiet/';
        } else {
          endpoint = '/fact/aggregated/kund-day-verkaufsgebiet/';
        }
      } else if (viewMode === 'verkaufsgebiet_filiale') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/kund-month-verkaufsgebiet-filiale/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/kund-week-verkaufsgebiet-filiale/';
        } else {
          endpoint = '/fact/aggregated/kund-day-verkaufsgebiet-filiale/';
        }
      }

      axios
        .get(endpoint, {
          params: {
            start_date: startDate,
            end_date: endDate,
            filiale: selectedFilialen.length ? selectedFilialen : undefined, // undefined entfernt den Parameter
            verkaufsgebiet: selectedVerkaufsgebiete.length ? selectedVerkaufsgebiete : undefined,
          },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) => {
          const filteredData = response.data;

          if (filteredData.length === 0) {
            setAvailableDataKeys([]);
            setSelectedDataKeys([]);
            setGroupedData({});
            setMonths([]);
            setWeeks([]);
            setDays([]);
            return;
          }

          const dataKeys = Object.keys(filteredData[0]).filter(
            (key) => key.startsWith('total_') && key !== 'total_faelle'
          );

          setAvailableDataKeys(dataKeys);

          const grouped = filteredData.reduce((acc, row) => {
            let key;
            if (viewMode === 'filiale') {
              key = row.filialnummer__filiale;
            } else if (viewMode === 'verkaufsgebiet') {
              key = row.filialnummer__verkaufsgebiet;
            } else if (viewMode === 'verkaufsgebiet_filiale') {
              key = `${row.filialnummer__verkaufsgebiet}||${row.filialnummer__filiale}`; // Verwende '||' als Trenner
            }

            const period =
              timeFilter === 'Monat'
                ? dayjs(row.month).format('MMMM YYYY')
                : timeFilter === 'Woche'
                ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                : dayjs(row.day).format('DD.MM.YYYY');

            if (!acc[key]) {
              acc[key] = {};
            }

            dataKeys.forEach((dataKey) => {
              if (!acc[key][dataKey]) {
                acc[key][dataKey] = {};
              }
              acc[key][dataKey][period] = row[dataKey];
            });

            return acc;
          }, {});

          setGroupedData(grouped);

          if (timeFilter === 'Monat') {
            const uniqueMonths = Array.from(
              new Set(filteredData.map((row) => dayjs(row.month).format('MMMM YYYY')))
            ).sort();
            setMonths(uniqueMonths);
          } else if (timeFilter === 'Woche') {
            const uniqueWeeks = Array.from(
              new Set(filteredData.map((row) => `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`))
            ).sort();
            setWeeks(uniqueWeeks);
          } else {
            const dayArray = [];
            let currentDate = dayjs(dateRange[0]);
            const endDateObj = dayjs(dateRange[1]);
            while (currentDate.isBefore(endDateObj) || currentDate.isSame(endDateObj, 'day')) {
              dayArray.push(currentDate.format('DD.MM.YYYY'));
              currentDate = currentDate.add(1, 'day');
            }
            setDays(dayArray);
          }

          // Setze Standard-Kennzahlen, falls noch keine ausgewählt wurden
          if (dataKeys.length > 0 && selectedDataKeys.length === 0) {
            // Priorisieren Sie 'umsatz' über 'planumsatz'
            const defaultKey = dataKeys.find((key) => key.toLowerCase().includes('umsatz') && !key.toLowerCase().includes('plan'));
            if (defaultKey) {
              setSelectedDataKeys([defaultKey]);
            } else {
              const planKey = dataKeys.find((key) => key.toLowerCase().includes('kundenanzahl'));
              if (planKey) {
                setSelectedDataKeys([planKey]);
              } else {
                setSelectedDataKeys([dataKeys[0]]);
              }
            }
          }
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error);
        });
    }
  }, [dateRange, timeFilter, viewMode, selectedFilialen, selectedVerkaufsgebiete]);

  // Kennzahlen umschalten
  const handleDataKeyToggle = (key) => {
    setSelectedDataKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          // Wenn die letzte Kennzahl abgewählt wird
          if (key.toLowerCase() !== 'umsatz' && availableDataKeys.map(k => k.toLowerCase()).includes('umsatz')) {
            // Setze 'Umsatz' als Standardauswahl, wenn verfügbar
            const originalKey = availableDataKeys.find((k) => k.toLowerCase() === 'umsatz');
            return originalKey ? [originalKey] : [prev[0]]; // Fallback auf die vorherige Kennzahl
          } else if (key.toLowerCase() === 'umsatz') {
            // Wenn 'Umsatz' die letzte Kennzahl ist, verhindern Sie das Abwählen
            return prev;
          } else {
            // Falls 'Umsatz' nicht verfügbar ist, wählen Sie die erste verfügbare Kennzahl
            return [availableDataKeys[0]];
          }
        }
        // Entfernen Sie die Kennzahl aus der Auswahl
        return prev.filter((k) => k !== key);
      } else {
        // Fügen Sie die Kennzahl zur Auswahl hinzu
        return [...prev, key];
      }
    });
  };

  // Automatisches Auswählen von "Umsatz", wenn keine Kennzahlen ausgewählt sind
  useEffect(() => {
    if (selectedDataKeys.length === 0 && availableDataKeys.length > 0) {
      const defaultKey = availableDataKeys.find((key) => key.toLowerCase().includes('umsatz') && !key.toLowerCase().includes('plan'));
      if (defaultKey) {
        setSelectedDataKeys([defaultKey]);
      } else {
        const planKey = availableDataKeys.find((key) => key.toLowerCase().includes('kundenanzahl'));
        if (planKey) {
          setSelectedDataKeys([planKey]);
        } else {
          setSelectedDataKeys([availableDataKeys[0]]);
        }
      }
    }
  }, [selectedDataKeys, availableDataKeys]);

  return (
    <Layout>
      <Box>
        <PageHeader
          title="Kundenstatistik"
          subtitle="Datenwürfel zur Kundenstatistik."
          Icon={BarChartIcon}
        />

        {/* Filterbereich */}
        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            onClick={() => setFiltersExpanded(!filtersExpanded)}
            startIcon={filtersExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            Filter {filtersExpanded ? 'schließen' : 'öffnen'}
          </Button>
          <Collapse in={filtersExpanded}>
            <Box sx={{ mt: 2, p: 2, border: '1px solid #ccc', borderRadius: '4px' }}>
              <Grid container spacing={2}>
                {/* Perspektive */}
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Perspektive
                  </Typography>
                  <ButtonGroup orientation="vertical" fullWidth>
                    <Button onClick={() => setViewMode('verkaufsgebiet')} disabled={viewMode === 'verkaufsgebiet'}>
                      Verkaufsgebiet
                    </Button>
                    <Button
                      onClick={() => setViewMode('verkaufsgebiet_filiale')}
                      disabled={viewMode === 'verkaufsgebiet_filiale'}
                    >
                      Verkaufsgebiet-Filiale
                    </Button>
                    <Button onClick={() => setViewMode('filiale')} disabled={viewMode === 'filiale'}>
                      Filiale
                    </Button>
                  </ButtonGroup>
                </Grid>

                {/* Zeitraum */}
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Zeitraum
                  </Typography>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                    <DateRangePicker
                      value={dateRange}
                      onChange={(newValue) => setDateRange(newValue)}
                      slots={{ field: SingleInputDateRangeField }}
                      calendars={1}
                      slotProps={{
                        shortcuts: {
                          items: [
                            { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                            { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                            { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                            { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                            { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                            { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                            { label: 'Zurücksetzen', getValue: () => [null, null] },
                          ],
                        },
                      }}
                      sx={{
                        width: '100%',
                        '& .MuiInputBase-root': {
                          height: '56px', // Gleiche Höhe wie die Select-Komponente
                          fontSize: '0.875rem',
                        },
                        '& .MuiSvgIcon-root': {
                          fontSize: '1.2rem',
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <FormControl fullWidth sx={{ mt: 2, width: '100%' }}>
                    <InputLabel sx={{ fontSize: '0.875rem' }}>Zeitraum auswählen</InputLabel>
                    <Select
                      value={timeFilter}
                      onChange={(event) => setTimeFilter(event.target.value)}
                      input={<OutlinedInput label="Zeitraum auswählen" />}
                      sx={{
                        height: '56px', // Gleiche Höhe wie das DateRangePicker-Feld
                        fontSize: '0.875rem',
                      }}
                    >
                      <MenuItem value="Monat">Monat</MenuItem>
                      <MenuItem value="Woche">Woche</MenuItem>
                      <MenuItem value="Tag">Tag</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                {/* Filialen und Verkaufsgebiete */}
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Filialen
                  </Typography>
                  {/* Filialen Filter mit MultiSelectDropdownFilter */}
                  <MultiSelectDropdownFilter
                    label="Filialen auswählen"
                    options={allFilialen}
                    value={selectedFilialen}
                    onChange={(newValue) => setSelectedFilialen(newValue)}
                  />

                  <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
                    Verkaufsgebiete
                  </Typography>
                  {/* Verkaufsgebiete Filter mit MultiSelectDropdownFilter */}
                  <MultiSelectDropdownFilter
                    label="Verkaufsgebiete auswählen"
                    options={allVerkaufsgebiete}
                    value={selectedVerkaufsgebiete}
                    onChange={(newValue) => setSelectedVerkaufsgebiete(newValue)}
                  />
                </Grid>

                {/* Kennzahlauswahl */}
                <Grid item xs={12} md={3}>
                  <Typography variant="h6" sx={{ mb: 2 }}>
                    Kennzahlauswahl
                  </Typography>
                  {/* Sicherstellen, dass immer mindestens eine Kennzahl ausgewählt ist */}
                  <Box
                    sx={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: 1,
                    }}
                  >
                    {availableDataKeys.map((key) => (
                      <FormControlLabel
                        key={key}
                        control={
                          <Checkbox
                            checked={selectedDataKeys.includes(key)}
                            onChange={() => handleDataKeyToggle(key)}
                          />
                        }
                        label={key
                          .replace('total_', '')
                          .replace('_menge', '')
                          .replace('_wert', '')
                          .replace('_retourquote', '')
                          .toLowerCase()
                          .replace(/^\w/, (c) => c.toUpperCase())}
                        sx={{
                          flex: '1 1 45%', // Flexibler Basiswert für Anpassung
                          maxWidth: '45%',
                        }}
                      />
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>

        {/* Daten Tabelle */}
        <Grid item xs={12} mt={2}>
          <KundenstatistikTabelle
            viewMode={viewMode}
            timeFilter={timeFilter}
            months={months}
            weeks={weeks}
            days={days}
            groupedData={groupedData}
            selectedDataKeys={selectedDataKeys}
            availableDataKeys={availableDataKeys}
          />
        </Grid>
      </Box>
    </Layout>
  );
};

// Kennzahlen umschalten mit Sicherstellung, dass mindestens eine Kennzahl ausgewählt bleibt
const handleDataKeyToggle = (key) => {
  setSelectedDataKeys((prev) => {
    if (prev.includes(key)) {
      if (prev.length === 1) {
        // Wenn die letzte Kennzahl abgewählt wird
        if (key.toLowerCase() !== 'umsatz' && availableDataKeys.map(k => k.toLowerCase()).includes('umsatz')) {
          // Setze 'Umsatz' als Standardauswahl, wenn verfügbar
          const originalKey = availableDataKeys.find((k) => k.toLowerCase() === 'umsatz');
          return originalKey ? [originalKey] : [prev[0]]; // Fallback auf die vorherige Kennzahl
        } else if (key.toLowerCase() === 'umsatz') {
          // Wenn 'Umsatz' die letzte Kennzahl ist, verhindern Sie das Abwählen
          return prev;
        } else {
          // Falls 'Umsatz' nicht verfügbar ist, wählen Sie die erste verfügbare Kennzahl
          return [availableDataKeys[0]];
        }
      }
      // Entfernen Sie die Kennzahl aus der Auswahl
      return prev.filter((k) => k !== key);
    } else {
      // Fügen Sie die Kennzahl zur Auswahl hinzu
      return [...prev, key];
    }
  });
};

export default Kundenstatistik;
