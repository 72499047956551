// KombinierteAnalyseTable.js
import React, { useEffect, useState } from 'react';
import axios from '../../../services/axiosInstance';
import { Stepper, Step, StepLabel } from '@mui/material';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/de'; // Deutsche Lokalisierung
import qs from 'qs'; // Importiere die qs-Bibliothek
import FilterListIcon from '@mui/icons-material/FilterList';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import FilterDialog from '../../../components/filter/FilterDialog'; // Passe den Pfad entsprechend an
import BarChartIcon from '@mui/icons-material/BarChart';
import Layout from '../../../components/layout/Warensteuerung/warensteuerung'; // Passe den Pfad entsprechend an
import DataTable from '../../../components/table/DataTable'; // Passe den Pfad entsprechend an
import DetailDialog from '../../../components/popup/warensteuerung/DetailDialog'; // Importiere die neue Dialog-Komponente


import {
  Button,
  ButtonGroup,
  Checkbox,
  Box,
  Grid,
  Fab,
  Typography,
  FormControlLabel,
} from '@mui/material';

dayjs.extend(isoWeek);
dayjs.locale('de'); // Setzt die Lokalisierung auf Deutsch

// Hilfsfunktion zur Berechnung aller Tage zwischen zwei Daten
const getDaysArray = (start, end) => {
  let daysArray = [];
  let currentDate = dayjs(start);
  while (currentDate.isBefore(end) || currentDate.isSame(end, 'day')) {
    daysArray.push(currentDate.format('DD.MM.YYYY'));
    currentDate = currentDate.add(1, 'day');
  }
  return daysArray;
};

const KombinierteAnalyseTable = () => {
  const [groupedData, setGroupedData] = useState({});
  const [warengruppenData, setWarengruppenData] = useState({});
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]); // State zur Speicherung der Tage
  const [viewMode, setViewMode] = useState('warengruppe'); // 'warengruppe', 'artikel', 'detail', 'filialeWarengruppe', 'filialeArtikel'
  const [selectedWarengruppen, setSelectedWarengruppen] = useState([]);
  const [allWarengruppen, setAllWarengruppen] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [allFilialen, setAllFilialen] = useState([]);
  const [selectedFilialen, setSelectedFilialen] = useState([]);
  const [timeFilter, setTimeFilter] = useState('Monat'); // "Monat", "Woche", "Tag"
  const [dialogOpen, setDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [detailData, setDetailData] = useState(null);
  

  // Neue States für die dynamischen Datentypen
  const [availableDataKeys, setAvailableDataKeys] = useState([]);
  const [selectedDataKeys, setSelectedDataKeys] = useState([]);

  // Funktion zum Überprüfen, ob "Alle" ausgewählt ist
  const isAllSelected = (selected, allOptions) => selected.length === allOptions.length;

  // Funktion zur Handhabung der Auswahl
  const handleSelectAll = (event, allOptions, selected, setSelected) => {
    if (event.target.checked) {
      setSelected(allOptions); // Wenn "Alle" ausgewählt wird, wähle alles aus
    } else {
      setSelected([]); // Wenn "Alle" abgewählt wird, leere die Auswahl
    }
  };

  useEffect(() => {
    if (availableDataKeys.length > 0 && selectedDataKeys.length === 0) {
      // Setze nur Verkaufsmenge als Standardauswahl
      const defaultKey = availableDataKeys.find((key) => key.includes('verkaufsmenge'));
      if (defaultKey) {
        setSelectedDataKeys([defaultKey]); // Wählt nur Verkaufsmenge aus
      }
    }
  }, [availableDataKeys, selectedDataKeys]);
  

  // Filialen abrufen bei Komponenten-Mount
  useEffect(() => {
    axios
      .get('/stammdaten/unique-filiale/')
      .then((response) => {
        const uniqueFilialen = response.data.map((filiale) => filiale.filiale);
        setAllFilialen(uniqueFilialen);
        setSelectedFilialen(uniqueFilialen); // Standardmäßig alle auswählen
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Filialen:', error);
      });
  }, []);

  // Daten für Artikel basierend auf Datum und Filialen abrufen
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');
      let endpoint;

      if (viewMode === 'filialeWarengruppe' || viewMode === 'filialeArtikel') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-filiale-warengruppe-artikel/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-filiale-warengruppe-artikel/';
        } else {
          endpoint = '/fact/aggregated/by-day-filiale-warengruppe-artikel/';
        }
      } else {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-warengruppe-artikel/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-warengruppe-artikel/';
        } else {
          endpoint = '/fact/aggregated/by-day-warengruppe-artikel/';
        }
      }

      axios
        .get(endpoint, {
          params: {
            start_date: startDate,
            end_date: endDate,
            filiale: selectedFilialen.length ? selectedFilialen : null,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((response) => {
          const filteredData = response.data;

          // Extrahiere die verfügbaren Datenfelder (z.B. total_verkaufsmenge, total_liefermenge, etc.)
          const dataKeys = Object.keys(filteredData[0]).filter(
            (key) => key.startsWith('total_') && key !== 'total_faelle'
          );

          setAvailableDataKeys(dataKeys);
          // Standardmäßig alle Datenfelder auswählen
          // setSelectedDataKeys(dataKeys);

          if (viewMode === 'filialeWarengruppe' || viewMode === 'filialeArtikel') {
            const grouped = filteredData.reduce((acc, row) => {
              const filiale = row.filialnummer__filiale;
              const warengruppe = row.warengruppennummer__warengruppe;
              const artikel = row.artikelnummer__artikelbezeichnung;
              const period =
                timeFilter === 'Monat'
                  ? dayjs(row.month).format('MMMM YYYY')
                  : timeFilter === 'Woche'
                  ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                  : dayjs(row.day).format('DD.MM.YYYY');

              if (!acc[filiale]) {
                acc[filiale] = {};
              }

              if (!acc[filiale][warengruppe]) {
                acc[filiale][warengruppe] = { articles: {} };
              }

              if (!acc[filiale][warengruppe].articles[artikel]) {
                acc[filiale][warengruppe].articles[artikel] = {};
              }

              dataKeys.forEach((key) => {
                if (!acc[filiale][warengruppe].articles[artikel][key]) {
                  acc[filiale][warengruppe].articles[artikel][key] = {};
                }
                acc[filiale][warengruppe].articles[artikel][key][period] = row[key];
              });

              return acc;
            }, {});

            setGroupedData(grouped);
          } else {
            const grouped = filteredData.reduce((acc, row) => {
              const warengruppe = row.warengruppennummer__warengruppe;
              const artikel = row.artikelnummer__artikelbezeichnung;
              const period =
                timeFilter === 'Monat'
                  ? dayjs(row.month).format('MMMM YYYY')
                  : timeFilter === 'Woche'
                  ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                  : dayjs(row.day).format('DD.MM.YYYY');

              if (!acc[warengruppe]) {
                acc[warengruppe] = { articles: {} };
              }

              if (!acc[warengruppe].articles[artikel]) {
                acc[warengruppe].articles[artikel] = {};
              }

              dataKeys.forEach((key) => {
                if (!acc[warengruppe].articles[artikel][key]) {
                  acc[warengruppe].articles[artikel][key] = {};
                }
                acc[warengruppe].articles[artikel][key][period] = row[key];
              });

              return acc;
            }, {});

            setGroupedData(grouped);
          }

          if (timeFilter === 'Monat') {
            const uniqueMonths = Array.from(
              new Set(filteredData.map((row) => dayjs(row.month).format('MMMM YYYY')))
            ).sort();
            setMonths(uniqueMonths);
          } else if (timeFilter === 'Woche') {
            const uniqueWeeks = Array.from(
              new Set(filteredData.map((row) => `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`))
            ).sort();
            setWeeks(uniqueWeeks);
          } else {
            const dayArray = getDaysArray(dateRange[0], dateRange[1]);
            setDays(dayArray); // Alle Tage im ausgewählten Bereich berechnen
          }

          const uniqueWarengruppen = [...new Set(filteredData.map((row) => row.warengruppennummer__warengruppe))];
          setAllWarengruppen(uniqueWarengruppen);

          // Behalte die aktuelle Auswahl bei, wenn möglich
          setSelectedWarengruppen((prevSelected) => {
            // Wenn die vorherige Auswahl leer ist, initialisiere sie mit allen verfügbaren Warengruppen
            if (prevSelected.length === 0) {
              return uniqueWarengruppen;
            }

            // Wenn die verfügbaren Optionen sich geändert haben, passe die Auswahl an
            const newSelection = prevSelected.filter((wg) => uniqueWarengruppen.includes(wg));
            return newSelection.length > 0 ? newSelection : uniqueWarengruppen;
          });

          const uniqueArticles = [...new Set(filteredData.map((row) => row.artikelnummer__artikelbezeichnung))];
          setAllArticles(uniqueArticles);

          setSelectedArticles((prevSelected) => {
            if (prevSelected.length === 0) {
              return uniqueArticles;
            }

            const newSelection = prevSelected.filter((art) => uniqueArticles.includes(art));
            return newSelection.length > 0 ? newSelection : uniqueArticles;
          });
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error);
        });
    }
  }, [dateRange, selectedFilialen, timeFilter, viewMode]);

  // Daten für Warengruppen abrufen
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');
      let endpoint;

      if (viewMode === 'filialeWarengruppe' || viewMode === 'filialeArtikel') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-filiale-warengruppe/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-filiale-warengruppe/';
        } else {
          endpoint = '/fact/aggregated/by-day-filiale-warengruppe/';
        }
      } else {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-warengruppe/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-warengruppe/';
        } else {
          endpoint = '/fact/aggregated/by-datum-warengruppe/';
        }
      }

      axios
        .get(endpoint, {
          params: {
            start_date: startDate,
            end_date: endDate,
            filiale: selectedFilialen.length ? selectedFilialen : null,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { arrayFormat: 'repeat' });
          },
        })
        .then((response) => {
          const filteredData = response.data;

          const dataKeys = Object.keys(filteredData[0]).filter(
            (key) => key.startsWith('total_') && key !== 'total_faelle'
          );

          if (viewMode === 'filialeWarengruppe' || viewMode === 'filialeArtikel') {
            const grouped = filteredData.reduce((acc, row) => {
              const filiale = row.filialnummer__filiale;
              const warengruppe = row.warengruppennummer__warengruppe;
              const period =
                timeFilter === 'Monat'
                  ? dayjs(row.month).format('MMMM YYYY')
                  : timeFilter === 'Woche'
                  ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                  : dayjs(row.day).format('DD.MM.YYYY');

              if (!acc[filiale]) {
                acc[filiale] = {};
              }

              if (!acc[filiale][warengruppe]) {
                acc[filiale][warengruppe] = {};
              }

              dataKeys.forEach((key) => {
                if (!acc[filiale][warengruppe][key]) {
                  acc[filiale][warengruppe][key] = {};
                }
                acc[filiale][warengruppe][key][period] = row[key];
              });

              return acc;
            }, {});

            setWarengruppenData(grouped);
          } else {
            const grouped = filteredData.reduce((acc, row) => {
              const warengruppe = row.warengruppennummer__warengruppe;
              const period =
                timeFilter === 'Monat'
                  ? dayjs(row.month).format('MMMM YYYY')
                  : timeFilter === 'Woche'
                  ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                  : dayjs(row.day).format('DD.MM.YYYY');

              if (!acc[warengruppe]) {
                acc[warengruppe] = {};
              }

              dataKeys.forEach((key) => {
                if (!acc[warengruppe][key]) {
                  acc[warengruppe][key] = {};
                }
                acc[warengruppe][key][period] = row[key];
              });

              return acc;
            }, {});

            setWarengruppenData(grouped);
          }
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der aggregierten Warengruppen-Daten:', error);
        });
    }
  }, [dateRange, selectedFilialen, timeFilter, viewMode]);

  // Dialog öffnen/schließen
  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // Funktion zum Öffnen des Detail-Dialogs für Warengruppe oder Artikel
  const handleOpenDetailDialog = (type, name, data) => {
    setDetailData({ type, name, data });
    setDetailDialogOpen(true);
  };

  // Funktion zum Schließen des Detail-Dialogs
  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
    setDetailData(null);
  };

  // Daten umformen, um sie im Diagramm zu verwenden
  const prepareChartData = () => {
    if (!detailData) return [];

    const periods = timeFilter === 'Monat' ? months : timeFilter === 'Woche' ? weeks : days;

    return periods.map((period) => {
      const dataPoint = { period };

      selectedDataKeys.forEach((key) => {
        dataPoint[key] = detailData.data[key][period] || 0;
      });

      return dataPoint;
    });
  };


  // Funktion zum Auswählen oder Abwählen aller Kennzahlen
const handleSelectAllDataKeys = (selectAll) => {
  if (selectAll) {
    setSelectedDataKeys(availableDataKeys); // Wählt alle Kennzahlen aus
  } else {
    setSelectedDataKeys([]); // Abwählen aller Kennzahlen
  }
};


  const chartData = prepareChartData(); // Vorbereitung der Daten für das Diagramm

  // Funktion zum Umschalten der Datenfelder
  const handleDataKeyToggle = (key) => {
    setSelectedDataKeys((prev) => {
      if (prev.includes(key)) {
        return prev.filter((k) => k !== key);
      } else {
        return [...prev, key];
      }
    });
  };

  return (
    <Layout>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title="Warengruppenanalyse"
            subtitle="Datenwürfel zum Thema Warensteuerung."
            Icon={BarChartIcon}
          />
        </Box>
      </Box>
      {/* Icon für den Filter */}
      <Fab
        color="primary"
        aria-label="filter"
        sx={{
          position: 'fixed',
          bottom: 50,
          right: 50,
          width: {
            xs: 50, // Kleinere Breite auf kleinen Bildschirmen
            sm: 60, // Mittelgroße Breite auf mittleren Bildschirmen
            md: 70, // Standardbreite auf großen Bildschirmen
          },
          height: {
            xs: 50, // Kleinere Höhe auf kleinen Bildschirmen
            sm: 60, // Mittelgroße Höhe auf mittleren Bildschirmen
            md: 70, // Standardhöhe auf großen Bildschirmen
          },
          fontSize: {
            xs: '1.5rem', // Kleinere Schriftgröße auf kleinen Bildschirmen
            sm: '1rem', // Mittelgroße Schriftgröße auf mittleren Bildschirmen
            md: '2rem', // Standardgröße auf großen Bildschirmen
          },
        }}
        onClick={handleDialogOpen} // Zum Öffnen des Dialogs
      >
        <FilterListIcon fontSize="inherit" />
      </Fab>

      {/* Dialog für Zeitraum-, Filialen-, Warengruppen- und Artikelfilter */}
      <FilterDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        dateRange={dateRange}
        setDateRange={setDateRange}
        timeFilter={timeFilter}
        setTimeFilter={setTimeFilter}
        allFilialen={allFilialen}
        selectedFilialen={selectedFilialen}
        setSelectedFilialen={setSelectedFilialen}
        allWarengruppen={allWarengruppen}
        selectedWarengruppen={selectedWarengruppen}
        setSelectedWarengruppen={setSelectedWarengruppen}
        allArticles={allArticles}
        selectedArticles={selectedArticles}
        setSelectedArticles={setSelectedArticles}
        isAllSelected={isAllSelected}
        handleSelectAll={handleSelectAll}
      />

<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
  {/* Überschrift für die erste Gruppe */}
  <Typography variant="h6">Perspektive:</Typography>

  <Grid container spacing={2}>
    {/* Erste Button-Gruppe */}
    <Grid item xs={6}>
      <Typography variant="h6">Artikel</Typography>
      <ButtonGroup fullWidth>
        <Button onClick={() => setViewMode('warengruppe')} disabled={viewMode === 'warengruppe'}>
          Warengruppen
        </Button>
        <Button onClick={() => setViewMode('detail')} disabled={viewMode === 'detail'}>
          Warengruppen/ Artikel
        </Button>
        <Button onClick={() => setViewMode('artikel')} disabled={viewMode === 'artikel'}>
          Artikel
        </Button>
      </ButtonGroup>
    </Grid>

    {/* Zweite Button-Gruppe */}
    <Grid item xs={6}>
      <Typography variant="h6">Filiale und Artikel</Typography>
      <ButtonGroup fullWidth>
        <Button onClick={() => setViewMode('filialeWarengruppe')} disabled={viewMode === 'filialeWarengruppe'}>
          Filiale und Warengruppe
        </Button>
        <Button onClick={() => setViewMode('filialeArtikel')} disabled={viewMode === 'filialeArtikel'}>
          Filiale und Artikel
        </Button>
      </ButtonGroup>
    </Grid>
  </Grid>
</Box>

<div style={{ marginTop: '20px', marginBottom: '20px' }}>
  {/* Überschrift für die Datentypen Filter */}
  <Typography variant="h6" style={{ marginBottom: '10px' }}>
    Kennzahlauswahl
  </Typography>

  <Button
    variant="contained"
    onClick={() =>
      selectedDataKeys.length === availableDataKeys.length
        ? handleSelectAllDataKeys(false) // Wenn alles ausgewählt ist, abwählen
        : handleSelectAllDataKeys(true) // Andernfalls alle auswählen
    }
  >
    {selectedDataKeys.length === availableDataKeys.length ? 'Alle abwählen' : 'Alle auswählen'}
    </Button>

  {/* Filter für die Datentypen */}
  {availableDataKeys.map((key) => (
    <FormControlLabel
      key={key}
      control={
        <Checkbox
          checked={selectedDataKeys.includes(key)}
          onChange={() => handleDataKeyToggle(key)}
        />
      }
      // Umwandlung der Kennzahl in lowercase, aber der erste Buchstabe bleibt groß
      label={key.replace('total_', '').replace('_menge', '').toLowerCase().replace(/^\w/, (c) => c.toUpperCase())}
    />
  ))}
</div>


      {/* Daten Tabelle */}
      <DataTable
        viewMode={viewMode}
        timeFilter={timeFilter}
        months={months}
        weeks={weeks}
        days={days}
        groupedData={groupedData}
        warengruppenData={warengruppenData}
        selectedWarengruppen={selectedWarengruppen}
        selectedArticles={selectedArticles}
        selectedFilialen={selectedFilialen}
        handleOpenDetailDialog={handleOpenDetailDialog}
        selectedDataKeys={selectedDataKeys}
        availableDataKeys={availableDataKeys}
      />

      {/* DetailDialog */}
      <DetailDialog
        open={detailDialogOpen}
        onClose={handleCloseDetailDialog}
        detailData={detailData}
        chartData={chartData}
        selectedDataKeys={selectedDataKeys}
        timeFilter={timeFilter}
        months={months}
        weeks={weeks}
        days={days}
      />
    </Layout>
  );
};

export default KombinierteAnalyseTable;
