import React from 'react';
import { Button, ButtonGroup } from '@mui/material';

const db_grey_dark = '#575756'; // Definiere die Farbe

const ButtonGroupFilter = ({ options, value, onChange }) => {

  const handleButtonClick = (option) => {
    if (value?.value === option.value) {
      onChange(null); // Deselektieren, wenn erneut ausgewählt
    } else {
      onChange(option); // Andernfalls den neuen Wert setzen
    }
  };

  return (
    <ButtonGroup
      variant="text"
      aria-label="button group"
      sx={{
        maxWidth: '100%', // Setzt eine maximale Breite
        flexWrap: 'wrap',  // Lässt die Buttons umschlagen, falls der Platz nicht ausreicht
      }}
    >
      {options.map((option) => (
        <Button
          key={option.value}
          onClick={() => handleButtonClick(option)}
          sx={{
            backgroundColor: value?.value === option.value ? 'transparent' : 'transparent',
            color: value?.value === option.value ? '#395D67' : db_grey_dark, // Primary color wenn ausgewählt, sonst db_grey_dark
            borderBottom: value?.value === option.value ? '2px solid #395D67' : 'none', // Unterstreichung wenn ausgewählt
            fontSize: {
              xs: '0.75rem', // Schriftgröße für kleine Bildschirme
              sm: '0.875rem', // Schriftgröße für mittlere Bildschirme
              md: '1rem', // Standardgröße für größere Bildschirme
            },
          }}
        >
          {option.label}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default ButtonGroupFilter;
