import React, { useState, useEffect } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  FormControlLabel,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Card,
  CardContent,
  CardHeader,
  Typography
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance'; // Dein vorhandenes axios-Setup
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Passe den Pfad entsprechend an
import BarChartIcon from '@mui/icons-material/BarChart'; // Beispiel-Icon
import Layout from '../../../components/layout/Filialsteuerung/filialsteuerung'; // Passe den Pfad entsprechend an
import DataCard from '../../../components/card/TagesreportDataCard'; // Passe den Pfad entsprechend an
import BrushBarChart from '../../../components/charts/BrushBarChart'; // Importiere deine GroupedBarChart-Komponente
import { tausenderTrennung } from '../../../utils/mathStuff'; // Pfad zu deinem Utils-File anpassen

const Report = () => {
  const [viewMode, setViewMode] = useState('month'); // 'month' oder 'week'
  const [data, setData] = useState([]);
  const [dataMap, setDataMap] = useState({});
  const [loading, setLoading] = useState(true);

  // Funktion, um den Toggle-Schalter zu handhaben
  const handleToggleChange = () => {
    setViewMode((prevMode) => (prevMode === 'month' ? 'week' : 'month'));
  };

  // Funktion, um die letzten 6 abgeschlossenen Zeiträume zu erhalten (ohne den laufenden Monat/Woche)
  const getLastSixPeriods = () => {
    const today = new Date();

    // Extrahiere alle Perioden (Monate oder Wochen) aus den Daten
    const allPeriods = [
      ...new Set(
        data
          .map((item) => (viewMode === 'month' ? item.month : item.week))
          .filter((period) => period !== undefined)
      )
    ];

    // Filtere die abgeschlossenen Perioden
    const filteredPeriods = allPeriods.filter((period) => {
      const periodDate = new Date(period);

      if (viewMode === 'month') {
        // Überprüfe, ob der Zeitraum nicht der aktuelle Monat ist
        return (
          periodDate.getMonth() !== today.getMonth() ||
          periodDate.getFullYear() !== today.getFullYear()
        );
      }

      if (viewMode === 'week') {
        // Finde den Montag der aktuellen Woche
        const currentDay = today.getDay(); // 0 (Sonntag) bis 6 (Samstag)
        const distanceToMonday = (currentDay + 6) % 7; // Berechnet die Tage zurück zum Montag
        const startOfCurrentWeek = new Date(today);
        startOfCurrentWeek.setDate(today.getDate() - distanceToMonday);
        startOfCurrentWeek.setHours(0, 0, 0, 0);

        // Überprüfe, ob der Zeitraum vor der aktuellen Woche liegt
        return periodDate < startOfCurrentWeek;
      }

      return true;
    });

    // Sortiere die Perioden chronologisch
    filteredPeriods.sort((a, b) => new Date(a) - new Date(b));

    // Nimm die letzten 6 abgeschlossenen Perioden
    return filteredPeriods.slice(-6);
  };

  // Funktion, um die Daten aus der entsprechenden API zu laden
  const fetchData = async () => {
    setLoading(true);
    const apiUrl =
      viewMode === 'month'
        ? '/fact/aggregated/kund-month-filiale/'
        : '/fact/aggregated/kund-week-filiale/';

    try {
      const response = await axiosInstance.get(apiUrl);
      setData(response.data);
    } catch (error) {
      console.error('Fehler beim Laden der Daten:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect, um die Daten beim Laden der Komponente oder beim Wechsel des View-Modus zu holen
  useEffect(() => {
    fetchData();
  }, [viewMode]);

  // useEffect, um eine Datenmappe für schnellen Zugriff zu erstellen
  useEffect(() => {
    const map = {};
    data.forEach((item) => {
      const warengruppe = item.warengruppe;
      const period = viewMode === 'month' ? item.month : item.week;

      // Füge das Jahr zum Periodenschlüssel hinzu, um Perioden aus verschiedenen Jahren zu unterscheiden
      const periodKey = `${new Date(period).getFullYear()}-${period}`;

      if (!map[warengruppe]) {
        map[warengruppe] = {};
      }

      map[warengruppe][periodKey] = item;
    });

    setDataMap(map);
  }, [data, viewMode]);

  // Berechnung der Top 5 Warengruppen basierend auf dem Umsatz des aktuellen Jahres
  const currentYear = new Date().getFullYear();

  const topFiveGroups = Object.values(
    data.reduce((acc, item) => {
      const warengruppe = item.warengruppe;
      const period = viewMode === 'month' ? item.month : item.week;
      const itemYear = new Date(period).getFullYear();

      // Nur Daten des aktuellen Jahres berücksichtigen
      if (itemYear !== currentYear) return acc;

      if (!acc[warengruppe]) {
        acc[warengruppe] = {
          warengruppe,
          umsatz: {},
          umsatzVJ: {}, // Umsatz des Vorjahres
          kundenanzahl: {},
          kundenanzahlVJ: {}, // Kundenanzahl des Vorjahres
          kundenbonVJ: {}, // Kundenbon des Vorjahres
          totalUmsatz: 0,
          totalKunden: 0,
        };
      }

      const totalUmsatz = item.total_Umsatz || 0;
      const totalKunden = item.total_Kundenanzahl || 0;
      const totalUmsatzVJ = item.total_UmsatzVJ || 0;
      const totalKundenVJ = item.total_KundenanzahlVJ || 0;
      const kundenbonVJ = totalKundenVJ > 0 ? totalUmsatzVJ / totalKundenVJ : 0;

      acc[warengruppe].umsatz[period] = totalUmsatz;
      acc[warengruppe].umsatzVJ[period] = totalUmsatzVJ;
      acc[warengruppe].kundenanzahl[period] = totalKunden;
      acc[warengruppe].kundenanzahlVJ[period] = totalKundenVJ;
      acc[warengruppe].kundenbonVJ[period] = kundenbonVJ;
      acc[warengruppe].totalUmsatz += totalUmsatz;
      acc[warengruppe].totalKunden += totalKunden;

      return acc;
    }, {})
  )
    .sort((a, b) => b.totalUmsatz - a.totalUmsatz) // Sortiere nach Umsatz
    .slice(0, 5); // Nimm die Top 5

  // Berechnung der letzten 6 Perioden
  const lastSixPeriods = getLastSixPeriods();

  const calculatePercentageChange = (current, previous) => {
    if (!previous || previous === 0) return 0;
    return ((current - previous) / previous) * 100;
  };

  // Aggregierte Daten für die DataCards und Tabelle
  const aggregatedData = lastSixPeriods.map((period) => {
    const periodData = data.filter(
      (item) => (viewMode === 'month' ? item.month === period : item.week === period)
    );

    const totalUmsatz = periodData.reduce((sum, item) => sum + (item.total_Umsatz || 0), 0);
    const totalKunden = periodData.reduce((sum, item) => sum + (item.total_Kundenanzahl || 0), 0);
    const totalUmsatzVJ = periodData.reduce((sum, item) => sum + (item.total_UmsatzVJ || 0), 0);
    const totalKundenVJ = periodData.reduce((sum, item) => sum + (item.total_KundenanzahlVJ || 0), 0);
    const totalProduktivstunden = periodData.reduce((sum, item) => sum + (item.total_Produktivstunden || 0), 0);

    const kundenbon = totalKunden > 0 ? totalUmsatz / totalKunden : 0;
    const kundenbonVJ = totalKundenVJ > 0 ? totalUmsatzVJ / totalKundenVJ : 0;
    const stundenleistung = totalProduktivstunden > 0 ? totalUmsatz / totalProduktivstunden : 0;

    return {
      period,
      totalUmsatz,
      totalKunden,
      totalUmsatzVJ,
      totalKundenVJ,
      kundenbon,
      kundenbonVJ,
      stundenleistung,
      umsatzChange: calculatePercentageChange(totalUmsatz, totalUmsatzVJ),
      kundenChange: calculatePercentageChange(totalKunden, totalKundenVJ),
    };
  });

  const getWeekNumber = (date) => {
    const d = new Date(date);
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
    return weekNo;
  };
  

  // Daten für die GroupedBarCharts
const barChartDataUmsatz = aggregatedData.map((row) => ({
    x: viewMode === 'month'
      ? new Date(row.period).toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric',
        })
      : `KW ${getWeekNumber(row.period)}`, // Kalenderwoche anzeigen
    currentScore: row.totalUmsatz,
    previousScore: row.totalUmsatzVJ, // Umsatz des Vorjahres
  }));
  
  const barChartDataKunden = aggregatedData.map((row) => ({
    x: viewMode === 'month'
      ? new Date(row.period).toLocaleDateString('de-DE', {
          month: 'long',
          year: 'numeric',
        })
      : `KW ${getWeekNumber(row.period)}`, // Kalenderwoche anzeigen
    currentScore: row.totalKunden,
    previousScore: row.totalKundenVJ, // Kundenanzahl des Vorjahres
  }));
  

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2
          }}
        >
          <PageHeader
            title="Report Filialsteuerung"
            subtitle="Dieser Bericht analysiert Umsatz, Kunden, Kundenbon und Stundenleistung."
            Icon={BarChartIcon}
          />
        </Box>

        {/* Toggle für Monat/Woche */}
        <FormControlLabel
          control={<Switch checked={viewMode === 'week'} onChange={handleToggleChange} />}
          label={viewMode === 'week' ? 'Woche' : 'Monat'}
        />

        {/* Data Cards für Kennzahlen */}
        <Grid container spacing={2} sx={{ mt: 2 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {/* Umsatz DataCard */}
              <Grid item xs={12} md={6}>
                <DataCard
                  chartData={aggregatedData.map((row) => row.totalUmsatz)}
                  category="Umsatz"
                  value={`${tausenderTrennung(
                    aggregatedData[aggregatedData.length - 1]?.totalUmsatz || 0,
                    2
                  )} €`}
                  subHeader={`Vorjahr: ${
                    aggregatedData[aggregatedData.length - 1]?.umsatzChange >= 0 ? '+' : ''
                  }${aggregatedData[aggregatedData.length - 1]?.umsatzChange?.toFixed(2)}%`}
                  numDataPoints={6}
                />
              </Grid>

              {/* Kundenanzahl DataCard */}
              <Grid item xs={12} md={6}>
                <DataCard
                  chartData={aggregatedData.map((row) => row.totalKunden)}
                  category="Kundenanzahl"
                  value={`${tausenderTrennung(
                    aggregatedData[aggregatedData.length - 1]?.totalKunden || 0,
                    0
                  )}`}
                  subHeader={`Vorjahr: ${
                    aggregatedData[aggregatedData.length - 1]?.kundenChange >= 0 ? '+' : ''
                  }${aggregatedData[aggregatedData.length - 1]?.kundenChange?.toFixed(2)}%`}
                  numDataPoints={6}
                />
              </Grid>

              {/* Kundenbon DataCard */}
              <Grid item xs={12} md={6}>
                <DataCard
                  chartData={aggregatedData.map((row) => row.kundenbon)}
                  category="Kundenbon"
                  value={`${tausenderTrennung(
                    aggregatedData[aggregatedData.length - 1]?.kundenbon || 0,
                    2
                  )} €`}
                  subHeader={`Vorjahr: ${tausenderTrennung(
                    aggregatedData[aggregatedData.length - 1]?.kundenbonVJ || 0,
                    2
                  )} €`}
                  numDataPoints={6}
                />
              </Grid>

              {/* Stundenleistung DataCard */}
              <Grid item xs={12} md={6}>
                <DataCard
                  chartData={aggregatedData.map((row) => row.stundenleistung)}
                  category="Stundenleistung"
                  value={`${tausenderTrennung(
                    aggregatedData[aggregatedData.length - 1]?.stundenleistung || 0,
                    2
                  )} €/h`}
                  subHeader="Umsatz pro Produktivstunde"
                  numDataPoints={6}
                />
              </Grid>
            </>
          )}
        </Grid>

        {/* Grouped Bar Charts */}
        <Grid container spacing={3} sx={{ mt: 5 }}>
          {/* Grouped Bar Chart für Umsatz und Umsatz Vorjahr */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Karte nimmt die volle Höhe ein
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)'
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Umsatz vs. Vorjahr
                  </Typography>
                }
                sx={{ paddingBottom: 0 }} // Verhindert zu viel Leerraum zwischen Header und Diagramm
              />
              <CardContent>
                <BrushBarChart data={barChartDataUmsatz} />
              </CardContent>
            </Card>
          </Grid>

          {/* Grouped Bar Chart für Kundenanzahl und Kundenanzahl Vorjahr */}
          <Grid item xs={12} md={6}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%', // Karte nimmt die volle Höhe ein
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)'
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Kundenanzahl vs. Vorjahr
                  </Typography>
                }
                sx={{ paddingBottom: 0 }} // Verhindert zu viel Leerraum zwischen Header und Diagramm
              />
              <CardContent>
                <BrushBarChart data={barChartDataKunden} />
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Tabelle für die letzten 6 Perioden */}
        <Box sx={{ mt: 4 }}>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Kennzahl</TableCell>
                  {lastSixPeriods.map((period, index) => (
                    <TableCell key={index}>
                      {viewMode === 'month'
                        ? `${new Date(period).toLocaleDateString('de-DE', {
                            month: 'long',
                            year: 'numeric',
                          })}`
                        : ` ${new Date(period).toLocaleDateString('de-DE')}`}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* Zeile für Umsatz */}
                <TableRow>
                  <TableCell>Umsatz</TableCell>
                  {aggregatedData.map((row, index) => (
                    <TableCell key={index}>
                      {tausenderTrennung(row?.totalUmsatz?.toFixed(2) || 0, 2)} €
                    </TableCell>
                  ))}
                </TableRow>

                {/* Zeile für Kundenanzahl */}
                <TableRow>
                  <TableCell>Kundenanzahl</TableCell>
                  {aggregatedData.map((row, index) => (
                    <TableCell key={index}>
                      {tausenderTrennung(row?.totalKunden?.toFixed(0) || 0, 0)}
                    </TableCell>
                  ))}
                </TableRow>

                {/* Zeile für Kundenbon */}
                <TableRow>
                  <TableCell>Kundenbon</TableCell>
                  {aggregatedData.map((row, index) => (
                    <TableCell key={index}>
                      {tausenderTrennung(row?.kundenbon?.toFixed(2) || 0, 2)} €
                    </TableCell>
                  ))}
                </TableRow>

                {/* Zeile für Stundenleistung */}
                <TableRow>
                  <TableCell>Stundenleistung</TableCell>
                  {aggregatedData.map((row, index) => (
                    <TableCell key={index}>
                      {tausenderTrennung(row?.stundenleistung?.toFixed(2) || 0, 2)} €/h
                    </TableCell>
                  ))}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Layout>
  );
};

export default Report;
