import React from 'react';
import {List, ListItem, ListItemText, TextField} from '@mui/material';

const CheckpointListeKomponente = ({checkpoints, onRatingChange, ratings}) => (
    <List>
        {checkpoints.map(checkpoint => (
            <ListItem key={checkpoint.id} sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                <ListItemText primary={checkpoint.name}/>
                <TextField
                    type="number"
                    inputProps={{min: "1", max: "10", step: "1"}}
                    value={ratings[checkpoint.id] || ''}
                    onChange={(e) => onRatingChange(checkpoint.id, e.target.value)}
                />
            </ListItem>
        ))}
    </List>
);

export default CheckpointListeKomponente;
