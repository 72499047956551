// abwesenheiten.jsx

import React, { useContext, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Grid,
  Typography,
  Stack,
  Card,
  Paper,
  CardHeader,
  CardContent,
} from '@mui/material';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTableTree from '../../../components/table/VariableTableTree';
import BigChartCard from '../../../components/card/scorecardsmonat/BigChartCardUnternehmen';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';
import FilialePopup from '../../../components/popup/personalcontrolling/FilialePopUpAbwesenheiten'; // Passen Sie den Pfad entsprechend an

const Abwesenheiten = () => {
  const { filterState } = useContext(FilterContext);
  const [datumOptions, setDatumOptions] = useState([]);
  const [personalData, setPersonalData] = useState({
    abwesenheitsquote: [],
  });
  const [tableData, setTableData] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [lineChartData, setLineChartData] = useState([]);

  // State für das Popup
  const [popupFiliale, setPopupFiliale] = useState(null);

  useEffect(() => {
    fetchDatumOptions();
  }, []);

  useEffect(() => {
    if (filterState.datum) {
      fetchPersonalData();
      fetchTableData();
    }
  }, [filterState]);

  const fetchDatumOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
      setDatumOptions(response.data.map(item => ({ label: item, value: item })));
    } catch (error) {
      console.error('Error while fetching datum options:', error);
    }
  };

  const getLast6Months = (currentMonth) => {
    const last6Months = [];
    const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

    for (let i = 0; i < 6; i++) {
      last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
    }

    return last6Months.reverse();
  };

  const fetchPersonalData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
    const last6Months = getLast6Months(currentMonth);

    try {
      const attendanceResponses = await Promise.all(
        last6Months.map(month =>
          axiosInstance.get('/scorecards_month/personal-kennzahl/', {
            params: { jahrMonat: month },
          })
        )
      );

      const absenceResponses = await Promise.all(
        last6Months.map(month =>
          axiosInstance.get('/personaldaten/abwesenheiten/', {
            params: { jahrMonat: month },
          })
        )
      );

      const abwesenheitsquoteData = last6Months.map((month, index) => {
        const attendanceData = attendanceResponses[index].data.results;
        const absenceData = absenceResponses[index].data.results;

        const anwesenheitsstunden = attendanceData.reduce(
          (sum, item) => sum + (item.arbeitszeitKrankenqoute || 0),
          0
        );
        const abwesenheitsstunden = absenceData.reduce(
          (sum, item) => sum + (item.abwesenheitsstunden || 0),
          0
        );
        const abwesenheitsquote =
          anwesenheitsstunden + abwesenheitsstunden > 0
            ? (abwesenheitsstunden / (anwesenheitsstunden + abwesenheitsstunden)) * 100
            : 0;

        return {
          month,
          anwesenheitsstunden,
          abwesenheitsstunden,
          abwesenheitsquote,
        };
      });

      setPersonalData({
        abwesenheitsquote: abwesenheitsquoteData,
      });

      const chartData = abwesenheitsquoteData.filter(item => item.month).flatMap(item => [
        { x: item.month, y: item.abwesenheitsquote, label: 'Abwesenheitsquote' },
      ]);

      setChartData(chartData);

      // Berechnen der Abwesenheitsquote pro Verkaufsgebiet für die letzten 6 Monate
      const salesAreaData = last6Months.map((month, index) => {
        const attendanceData = attendanceResponses[index].data.results;
        const absenceData = absenceResponses[index].data.results;

        const salesAreaGroup = {};

        attendanceData.forEach(item => {
          const salesArea = item.verkaufsgebiet;
          if (!salesAreaGroup[salesArea]) {
            salesAreaGroup[salesArea] = {
              anwesenheitsstunden: 0,
              abwesenheitsstunden: 0,
            };
          }
          salesAreaGroup[salesArea].anwesenheitsstunden += item.arbeitszeitKrankenqoute || 0;
        });

        absenceData.forEach(item => {
          const salesArea = item.verkaufsgebiet;
          if (!salesAreaGroup[salesArea]) {
            salesAreaGroup[salesArea] = {
              anwesenheitsstunden: 0,
              abwesenheitsstunden: 0,
            };
          }
          salesAreaGroup[salesArea].abwesenheitsstunden += item.abwesenheitsstunden || 0;
        });

        const salesAreaRates = {};
        Object.keys(salesAreaGroup).forEach(salesArea => {
          const anwesenheitsstunden = salesAreaGroup[salesArea].anwesenheitsstunden;
          const abwesenheitsstunden = salesAreaGroup[salesArea].abwesenheitsstunden;
          const abwesenheitsquote =
            anwesenheitsstunden + abwesenheitsstunden > 0
              ? (abwesenheitsstunden / (anwesenheitsstunden + abwesenheitsstunden)) * 100
              : 0;
          salesAreaRates[salesArea] = {
            anwesenheitsstunden,
            abwesenheitsstunden,
            abwesenheitsquote,
          };
        });

        return {
          month,
          salesAreaRates,
        };
      });

      // Daten für das Liniendiagramm vorbereiten
      const salesAreas = new Set();
      salesAreaData.forEach(monthData => {
        Object.keys(monthData.salesAreaRates).forEach(salesArea => {
          salesAreas.add(salesArea);
        });
      });

      const lineChartData = Array.from(salesAreas).map(salesArea => {
        return {
          id: salesArea,
          data: salesAreaData.map(monthData => {
            const abwesenheitsquote = monthData.salesAreaRates[salesArea]
              ? monthData.salesAreaRates[salesArea].abwesenheitsquote
              : null;
            return {
              x: monthData.month,
              y: abwesenheitsquote,
            };
          }),
        };
      });

      setLineChartData(lineChartData);
    } catch (error) {
      console.error('Error fetching personal data:', error);
    }
  };

  const fetchTableData = async () => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

    try {
      const attendanceResponse = await axiosInstance.get('/scorecards_month/personal-kennzahl/', {
        params: { jahrMonat: currentMonth },
      });

      const absenceResponse = await axiosInstance.get('/personaldaten/abwesenheiten/', {
        params: { jahrMonat: currentMonth },
      });

      const attendanceData = attendanceResponse.data.results;
      const absenceData = absenceResponse.data.results;

      const formattedTableData = attendanceData.map((item) => {
        const filiale = item.filiale;
        const anwesenheitsstunden = item.arbeitszeitKrankenqoute || 0;
        const filialeAbwesenheitsstunden = absenceData
          .filter(absenceItem => absenceItem.filiale === filiale)
          .reduce((sum, absenceItem) => sum + (absenceItem.abwesenheitsstunden || 0), 0);

        const abwesenheitsquote =
          anwesenheitsstunden + filialeAbwesenheitsstunden > 0
            ? (filialeAbwesenheitsstunden / (anwesenheitsstunden + filialeAbwesenheitsstunden)) * 100
            : 0;

        return {
          verkaufsgebiet: item.verkaufsgebiet,
          filiale: item.filiale,
          anwesenheitsstunden,
          abwesenheitsstunden: filialeAbwesenheitsstunden,
          abwesenheitsquote,
        };
      });

      setTableData(formattedTableData);
    } catch (error) {
      console.error('Error fetching table data:', error);
    }
  };

  const columns = useMemo(() => [
    {
      accessorKey: 'verkaufsgebiet',
      header: 'Verkaufsgebiet',
      enableGrouping: true,
      isVisible: true,
    },
    {
      accessorKey: 'filiale',
      header: 'Filiale',
      enableGrouping: false,
      isVisible: true,
    },
    {
      accessorKey: 'anwesenheitsstunden',
      header: 'Anwesenheitsstunden',
      enableGrouping: false,
      aggregationFn: 'sum',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsstunden',
      header: 'Abwesenheitsstunden',
      enableGrouping: false,
      aggregationFn: 'sum',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsquote',
      header: 'Abwesenheitsquote',
      enableGrouping: false,
      aggregationFn: 'mean',
      Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
      AggregatedCell: ({ cell, row }) => {
        const subRows = row.subRows || [];
        const aggregatedAbwesenheitsstunden = subRows.reduce(
          (total, subRow) => total + subRow.original.abwesenheitsstunden,
          0
        );
        const aggregatedArbeitszeit = subRows.reduce(
          (total, subRow) => total + subRow.original.anwesenheitsstunden,
          0
        );
        const abwesenheitsquote =
          aggregatedArbeitszeit + aggregatedAbwesenheitsstunden > 0
            ? (aggregatedAbwesenheitsstunden /
                (aggregatedArbeitszeit + aggregatedAbwesenheitsstunden)) *
              100
            : 0;
        return <span>{abwesenheitsquote.toFixed(2)}%</span>;
      },
      isVisible: true,
    },
  ], []);


  const handleRowClick = (row) => {
    // Stellen Sie sicher, dass es sich um eine Filialzeile handelt
    if (row.original && row.original.filiale && !row.getIsGrouped()) {
      const filiale = row.original.filiale;
      setPopupFiliale(filiale);
    }
  };

  return (
    <Layout>
      <Grid container justifyContent="flex-start" alignItems="stretch">
        <PageHeader
          title="Abwesenheitsquote"
          subtitle="Dieser Bericht bietet eine Übersicht über die Abwesenheitsquote Ihrer Mitarbeiter über die letzten sechs Monate."
          Icon={NoAccountsOutlinedIcon}
        />

        <Grid container mt={3} spacing={2}>
          <Grid item xs={12} lg={6}>
            <BigChartCard
              data={chartData}
              title="Abwesenheitsquote der letzten 6 Monate"
              xKey="x"
              yKeys={['y']}
              labels={['Abwesenheitsquote']}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <BigChartCardRecharts
              data={lineChartData}
              title="Abwesenheitsquote pro Verkaufsgebiet über die letzten 6 Monate"
            />
          </Grid>

          <Grid item xs={12} md={12} mt={3}>
            <Paper>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  padding: '0px',
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  backdropFilter: 'blur(10px)',
                }}
              >
                <CardHeader
                  title={
                    <Typography variant="h6" component="div">
                      Abwesenheitsquote Übersicht
                    </Typography>
                  }
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <VariableTableTree
                    columns={columns}
                    data={tableData}
                    initialGrouping={['verkaufsgebiet']}
                    onRowClick={handleRowClick}
                  />
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog für Filialdetails */}
      <FilialePopup
        open={Boolean(popupFiliale)}
        onClose={() => setPopupFiliale(null)}
        filialeName={popupFiliale}
        filterState={filterState}
        getLast6Months={getLast6Months}
      />
    </Layout>
  );
};

export default Abwesenheiten;
