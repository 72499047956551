import {Outlet, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import CircularIndeterminate from "../components/utility/Loading";
import axiosInstance from "../services/axiosInstance";
import {useAuth} from "../provider/authProvider";


export const ProtectedRoute = ({module, submodule, children}) => {
    const [loading, setLoading] = useState(true);
    const { getToken, refreshTokenExpired } = useAuth();
    const navigate = useNavigate();
    const token = getToken();

    useEffect(() => {
        async function checkPermission() {
            if (!token && !refreshTokenExpired) {
                return
            }

            setLoading(true)
            try {
                if (module) {
                    const {data: {has_permission = false} = {}} = await axiosInstance.get('/auth/check-permission/', {params: {module, submodule}});
                    if (!has_permission) {
                        navigate("/access-denied");
                    }
                }
            } catch (error) {
                console.error("Authentication error:", error);
                navigate("/access-denied")
            } finally {
                setLoading(false)
            }
        }

        checkPermission();
    }, [module, submodule, token, refreshTokenExpired, navigate]);

    if (loading) {
        return <CircularIndeterminate/>;
    } else {
        return children ? children : <Outlet/>;
    }
};

export default ProtectedRoute;
