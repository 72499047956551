import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Stack, Paper, Card, CardHeader, Typography, CardContent } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import VariableTableTree from '../../../components/table/VariableTableTree';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import { calculatePerformanceData } from '../../../utils/calculationPerformance';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import BigChartCardSecondY from "../../../components/card/scorecardsmonat/BigChartCardSecondYAxis";

import BigPieChartCardTotal from '../../../components/card/scorecardsmonat/BigPieChartCardTotal';

const Kundenbon = () => {
    const { filterState } = useContext(FilterContext);
    const [allFilialenData, setAllFilialenData] = useState([]);
    const [filialtypData, setFilialtypData] = useState([]);

    const [performanceData, setPerformanceData] = useState({
        umsatz: 0,
        umsatzVJ: 0,
        umsatzAbweichungVJ: 0,
        kundenanzahl: 0,
        kundenanzahlVJ: 0,
        kundenanzahlAbweichungVJ: 0,
        kundenbon: 0,
        kundenbonVJ: 0,
        kundenbonAbweichungVJ: 0,
        leistung: 0,
        leistungVJ: 0,
        leistungAbweichungVJ: 0,
        chartData: [],
        verkaufsgebieteResult: [],
        filialenMitKundenbon: 0,
        filialtypenResult: [],
        durchschnittKundenbon: 0,
    });
    const [tableData, setTableData] = useState([]);
    const [performanceLastMonths, setPerformanceLastMonths] = useState([]);

    useEffect(() => {
        if (filterState.datum) {
            fetchPerformanceData();
            fetchPerformanceDataLastMonths();
        }
    }, [filterState]);

    const formatNumberWithCommas = (number) => {
        if (typeof number !== 'number' || isNaN(number)) {
            return '0.00';
        }
        return number.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    };

    const formatPercentage = (value) => {
        return `${value.toFixed(2)}%`;
    };

    const fetchScoreKundenbonData = async () => {
        try {
            const response = await axiosInstance.get('/scorecards_month/performance-monat/', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });
            return response.data.results;
        } catch (error) {
            console.error('Error fetching scoreKundenbon data:', error);
            return [];
        }
    };
    

    const fetchPerformanceData = async () => {
        try {
            // Abrufen der Daten von der API
            const response = await axiosInstance.get('/scorecards_month/performance-monat', {
                params: {
                    jahrMonat: filterState.datum?.value,
                },
            });

            // Abrufen der scoreKundenbon-Daten
            const scoreKundenbonData = await fetchScoreKundenbonData();

            // Setzen der gesamten Filialdaten in den State
            setAllFilialenData(response.data.results);

            // Berechnungen durchführen
            const calculatedData = calculatePerformanceData(response.data.results);

            // Anzahl der Filialen mit Kundenbon > 0 berechnen
            const filialenMitKundenbon = response.data.results.filter((filiale) => filiale.kundenbon > 0).length;

            // Durchschnittlicher Kundenbon berechnen
            const durchschnittKundenbon = filialenMitKundenbon > 0 ? calculatedData.kundenbon : 0;

            // Setzen der Performance-Daten
            setPerformanceData({
                umsatz: calculatedData.umsatzSumme,
                umsatzVJ: calculatedData.umsatzVJSumme,
                umsatzAbweichungVJ: calculatedData.umsatzAbweichungVJ,
                kundenanzahl: calculatedData.kundenanzahlSumme,
                kundenanzahlVJ: calculatedData.kundenanzahlVJSumme,
                kundenanzahlAbweichungVJ: calculatedData.kundenanzahlAbweichungVJ,
                kundenbon: calculatedData.kundenbon,
                kundenbonVJ: calculatedData.kundenbonVJ,
                kundenbonAbweichungVJ: calculatedData.kundenbonVJ > 0 ? ((calculatedData.kundenbon - calculatedData.kundenbonVJ) / calculatedData.kundenbonVJ) * 100 : 0,
                leistung: calculatedData.leistung,
                leistungVJ: calculatedData.leistungVJ,
                leistungAbweichungVJ: calculatedData.leistungVJ > 0 ? ((calculatedData.leistung - calculatedData.leistungVJ) / calculatedData.leistungVJ) * 100 : 0,
                chartData: response.data.results.map((item) => ({
                    kundenbon: item.kundenbon || 0,
                })),
                verkaufsgebieteResult: calculatedData.verkaufsgebieteResult,
                filialenMitKundenbon,
                durchschnittKundenbon,
                filialtypenResult: calculatedData.filialtypenResult,
            });

            // Tabelle formatieren
            const formattedTableData = response.data.results
            .filter(filiale => filiale.kundenbon > 0)
            .map((filiale) => {
                const scoreData = scoreKundenbonData.find(item => item.filialnummer === filiale.filialnummer) || {};
                return {
                    filialnummer: filiale.filialnummer,
                    filiale: filiale.filiale,
                    verkaufsgebiet: filiale.verkaufsgebiet,
                    filialtyp: filiale.filialtyp,
                    kundenbon: filiale.kundenbon || 0,
                    kundenbonVJ: filiale.kundenbonVJ || 0,
                    kundenbonAbweichungVJ: filiale.kundenbonVJ > 0 ? ((filiale.kundenbon - filiale.kundenbonVJ) / filiale.kundenbonVJ) * 100 : 0,
                    scoreKundenbon: scoreData.scoreKundenbon || 0, // Hier wird scoreKundenbon hinzugefügt
                };
            });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching performance data:', error);
        }
    };

    const getLastMonths = (currentMonth) => {
        const lastMonths = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            lastMonths.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }
        return lastMonths.reverse();
    };

    const fetchPerformanceDataLastMonths = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const lastMonths = getLastMonths(currentMonth);

        try {
            const responses = await Promise.all(
                lastMonths.map(month => axiosInstance.get('/scorecards_month/performance-monat/', {
                    params: { jahrMonat: month },
                }))
            );

            const lastMonthsData = responses.map((response, index) => {
                const kundenanzahlSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahl || 0), 0);

                const umsatzSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatz || 0), 0);

                const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;

                const kundenanzahlVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.kundenanzahlVJ || 0), 0);

                const umsatzVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.umsatzVJ || 0), 0);

                const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;

                // Leistung und Leistung VJ berechnen
                const produktivstundenSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstunden || 0), 0);

                const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;

                const produktivstundenVJSumme = response.data.results
                    .reduce((sum, filiale) => sum + (filiale.produktivstundenVJ || 0), 0);

                const leistungVJ = produktivstundenVJSumme > 0 ? umsatzVJSumme / produktivstundenVJSumme : 0;

                return {
                    month: lastMonths[index],
                    kundenbon: kundenbon,
                    kundenbonVJ: kundenbonVJ,
                    umsatz: umsatzSumme,
                    umsatzVJ: umsatzVJSumme,
                    kundenanzahl: kundenanzahlSumme,
                    kundenanzahlVJ: kundenanzahlVJSumme,
                    leistung: leistung,
                    leistungVJ: leistungVJ,
                };
            });

            setPerformanceLastMonths(lastMonthsData);
        } catch (error) {
            console.error('Error fetching data for last months:', error);
        }
    };

    // Daten für das Kundenbon-Diagramm
    const chartDataKundenbon = performanceLastMonths.flatMap((monthData) => [
        { x: monthData.month, y: monthData.kundenbon, label: 'aktuelles Jahr' },
        { x: monthData.month, y: monthData.kundenbonVJ, label: 'Vorjahr' }
    ]);

    // **Angepasste Daten für Umsatz und Kundenanzahl Diagramm**
    const chartDataUmsatzKundenanzahl = [
        {
            id: 'Umsatz',
            data: performanceLastMonths.map((item) => ({ x: item.month, y: item.umsatz })),
        },
        {
            id: 'Kundenanzahl',
            data: performanceLastMonths.map((item) => ({ x: item.month, y: item.kundenanzahl })),
        },
    ];

    // Daten für Leistung (für die DataCard)
    const leistungDataCard = {
        value: performanceData.leistung,
        abweichungVJ: performanceData.leistungAbweichungVJ,
        chartData: performanceLastMonths.map(item => item.leistung),
    };

    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },

        {
            accessorKey: 'scoreKundenbon',
            header: 'Score Kundenbon',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const validSubRows = subRows.filter(subRow => subRow.getValue('scoreKundenbon') > 0);
                const totalScoreKundenbon = validSubRows.reduce((total, subRow) => total + (subRow.getValue('scoreKundenbon') || 0), 0);
                const averageScoreKundenbon = validSubRows.length > 0 ? totalScoreKundenbon / validSubRows.length : 0;

                return (
                    <span>{formatNumberWithCommas(averageScoreKundenbon.toFixed(2))}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'kundenbon',
            header: 'Kundenbon',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <span>{formatNumberWithCommas(value)}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'kundenbonVJ',
            header: 'Kundenbon VJ',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => formatNumberWithCommas(cell.getValue()),
            AggregatedCell: ({ cell }) => {
                const value = cell.getValue();
                return (
                    <span>{formatNumberWithCommas(value)}</span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'kundenbonAbweichungVJ',
            header: 'Kundenbon Abw. VJ',
            enableGrouping: false,
            Cell: ({ cell }) => (
                <span>{cell.getValue().toFixed(2)}%</span>
            ),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedKundenbon = subRows.reduce((total, subRow) => total + subRow.getValue('kundenbon'), 0);
                const aggregatedKundenbonVJ = subRows.reduce((total, subRow) => total + subRow.original.kundenbonVJ, 0);

                const kundenbonAbweichungVJ = aggregatedKundenbonVJ > 0 ? ((aggregatedKundenbon - aggregatedKundenbonVJ) / aggregatedKundenbonVJ) * 100 : 0;

                return (
                    <span>
                        {kundenbonAbweichungVJ.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
    ], [performanceData]);

    // Berechnung von filialtypData bei Änderungen von allFilialenData
    useEffect(() => {
        if (allFilialenData.length > 0) {
            // Daten vorbereiten
            const filialtypData = allFilialenData.reduce((acc, filiale) => {
                const existing = acc.find(item => item.filialtyp === filiale.filialtyp);
                if (existing) {
                    existing.totalUmsatz += filiale.umsatz || 0;
                    existing.totalKundenanzahl += filiale.kundenanzahl || 0;
                } else {
                    acc.push({
                        filialtyp: filiale.filialtyp,
                        totalUmsatz: filiale.umsatz || 0,
                        totalKundenanzahl: filiale.kundenanzahl || 0,
                    });
                }
                return acc;
            }, []);

            // Kundenbon für jeden Filialtyp berechnen
            filialtypData.forEach(item => {
                const totalUmsatz = item.totalUmsatz || 0;
                const totalKundenanzahl = item.totalKundenanzahl || 0;

                item.kundenbon = totalKundenanzahl > 0 ? totalUmsatz / totalKundenanzahl : 0;
            });

            // Debugging: Überprüfen Sie die Daten
            console.log('filialtypData:', filialtypData);

            setFilialtypData(filialtypData);
        }
    }, [allFilialenData]);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch" mt={3}>
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* DataCards */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Umsatz"
                                sector="performance"
                                value={performanceData.umsatz ? formatNumberWithCommas(performanceData.umsatz) : '0.00'}
                                subHeaders={[
                                    `${performanceData.umsatzAbweichungVJ.toFixed(2)}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.umsatz)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Kundenanzahl"
                                sector="performance"
                                value={performanceData.kundenanzahl ? formatNumberWithCommas(performanceData.kundenanzahl) : '0'}
                                subHeaders={[
                                    `${performanceData.kundenanzahlAbweichungVJ ? performanceData.kundenanzahlAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenanzahl)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Kundenbon"
                                sector="performance"
                                value={performanceData.kundenbon ? formatNumberWithCommas(performanceData.kundenbon) : '0.00'}
                                subHeaders={[
                                    `${performanceData.kundenbonAbweichungVJ ? performanceData.kundenbonAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={performanceLastMonths.map(item => item.kundenbon)}
                            />
                        </Grid>

                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Leistung"
                                sector="performance"
                                value={performanceData.leistung ? formatNumberWithCommas(performanceData.leistung) : '0.00'}
                                subHeaders={[
                                    `${performanceData.leistungAbweichungVJ ? performanceData.leistungAbweichungVJ.toFixed(2) : '0.00'}% zum Vorjahr`
                                ]}
                                chartData={leistungDataCard.chartData}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                        
                            {/* Linke Seite */}
                            <Grid item xs={12} lg={6}>
                                <Stack direction="column" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                                    {/* Umsatz und Kundenanzahl Chart */}
                                    <BigChartCardSecondY
                                        data={chartDataUmsatzKundenanzahl}
                                        title="Umsatz und Kundenanzahl der letzten Monate"
                                        height={300}
                                    />

                                    {/* PieChart für Filialtypen */}
                                    {filialtypData && filialtypData.length > 0 && (
                                        <BigPieChartCardTotal
                                            data={filialtypData}
                                            title="Kundenbon nach Filialtyp"
                                            metric="kundenbon"  // Verwenden Sie das Metric "kundenbon"
                                        />
                                    )}
                                </Stack>
                            </Grid>

                            {/* Rechte Seite */}
                            <Grid item xs={12} lg={6}>
                                {/* Kundenbon Chart */}
                                <BigChartCard
                                    data={chartDataKundenbon}
                                    title="Kundenbon und Kundenbon Vorjahr der letzten Monate"
                                />
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={12} mt={3} sx={{ mt: 2 }}>
                            <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
                                <CardHeader
                                    title={
                                        <Typography variant="h6" component="div">
                                            Kundenbon
                                        </Typography>
                                    }
                                />
                                <CardContent sx={{ flexGrow: 1 }}>
                                    <VariableTableTree
                                        columns={columns}
                                        data={tableData}
                                        initialGrouping={['verkaufsgebiet']}
                                    />
                                </CardContent>
                            </Card>
                        </Grid>
            </Grid>
        </Layout>
    );
};

export default Kundenbon;
