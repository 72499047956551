import React, { useEffect, useState, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'; // Recharts importieren
import VariableTableTree from '../../table/VariableTableTree';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import qs from 'qs';

const FilialePopup = ({
  open,
  onClose,
  filialeName,
  filterState,
  getLast6Months,
}) => {
  const [chartData, setChartData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [chartTableData, setChartTableData] = useState([]); // Neuer State für die Diagrammdaten-Tabelle

  useEffect(() => {
    if (open && filialeName) {
      fetchChartDataForFiliale(filialeName);
      fetchEmployeeData(filialeName);
    }
  }, [open, filialeName]);

  const fetchChartDataForFiliale = async (filialeId) => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
    const last6Months = getLast6Months(currentMonth);

    try {
      const attendanceResponses = await Promise.all(
        last6Months.map(month =>
          axiosInstance.get('/personaldaten/anwesenheiten/', {
            params: { jahrMonat: month, arbeitsbereichKostenstelle: filialeId },
            paramsSerializer: params => qs.stringify(params, { encode: true }),
          })
        )
      );

      const absenceResponses = await Promise.all(
        last6Months.map(month =>
          axiosInstance.get('/personaldaten/abwesenheiten/', {
            params: { jahrMonat: month, arbeitsbereichKostenstelle: filialeId },
            paramsSerializer: params => qs.stringify(params, { encode: true }),
          })
        )
      );

      const abwesenheitsquoteData = last6Months.map((month, index) => {
        const attendanceData = attendanceResponses[index]?.data?.results || [];
        const absenceData = absenceResponses[index]?.data?.results || [];

        const anwesenheitsstunden = attendanceData.reduce(
          (sum, item) => sum + (parseFloat(item.anwesenheitsstunden) || 0),
          0
        );
        const abwesenheitsstunden = absenceData.reduce(
          (sum, item) => sum + (parseFloat(item.abwesenheitsstunden) || 0),
          0
        );
        const totalHours = anwesenheitsstunden + abwesenheitsstunden;
        const abwesenheitsquote = totalHours > 0 ? (abwesenheitsstunden / totalHours) * 100 : 0;

        return {
          month, // Hinzugefügt für die Tabelle und das Diagramm
          anwesenheitsstunden, // Hinzugefügt für die Tabelle
          abwesenheitsstunden, // Hinzugefügt für die Tabelle
          abwesenheitsquote, // Hinzugefügt für die Tabelle
        };
      });

      // Setzen der Daten für das Diagramm und die Tabelle
      setChartData(abwesenheitsquoteData); // Setzt die Diagrammdaten direkt
      setChartTableData(abwesenheitsquoteData); // Setzt die Daten für die Tabelle
    } catch (error) {
      console.error('Error fetching chart data for filiale:', error);
    }
  };

  const fetchEmployeeData = async (filiale) => {
    const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

    try {
      const attendanceResponse = await axiosInstance.get('/personaldaten/anwesenheiten/', {
        params: { jahrMonat: currentMonth, filiale },
        paramsSerializer: params => qs.stringify(params, { encode: true }),
      });

      const absenceResponse = await axiosInstance.get('/personaldaten/abwesenheiten/', {
        params: { jahrMonat: currentMonth, filiale },
        paramsSerializer: params => qs.stringify(params, { encode: true }),
      });

      const attendanceData = attendanceResponse.data.results;
      const absenceData = absenceResponse.data.results;

      const employeeMap = {};

      attendanceData.forEach(item => {
        const employeeId = item.mitarbeiterId;
        if (!employeeMap[employeeId]) {
          employeeMap[employeeId] = {
            mitarbeiterId: employeeId,
            name: item.name,
            anwesenheitsstunden: 0,
            abwesenheitsstunden: 0,
          };
        }
        employeeMap[employeeId].anwesenheitsstunden += parseFloat(item.anwesenheitsstunden) || 0;
      });

      absenceData.forEach(item => {
        const employeeId = item.mitarbeiterId;
        if (!employeeMap[employeeId]) {
          employeeMap[employeeId] = {
            mitarbeiterId: employeeId,
            name: item.name,
            anwesenheitsstunden: 0,
            abwesenheitsstunden: 0,
          };
        }
        employeeMap[employeeId].abwesenheitsstunden += parseFloat(item.abwesenheitsstunden) || 0;
      });

      const employeeList = Object.values(employeeMap).map(emp => {
        const totalHours = emp.anwesenheitsstunden + emp.abwesenheitsstunden;
        const abwesenheitsquote = totalHours > 0
          ? (emp.abwesenheitsstunden / totalHours) * 100
          : 0;

        return {
          ...emp,
          abwesenheitsquote,
        };
      });

      setEmployeeData(employeeList);
    } catch (error) {
      console.error('Error fetching employee data:', error);
    }
  };

  // Define columns for the employee table
  const employeeColumns = useMemo(() => [
    {
      accessorKey: 'name',
      header: 'Name',
      isVisible: true,
    },
    {
      accessorKey: 'anwesenheitsstunden',
      header: 'Anwesenheitsstunden',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsstunden',
      header: 'Abwesenheitsstunden',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsquote',
      header: 'Abwesenheitsquote',
      Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
      isVisible: true,
    },
  ], []);

  // Define columns for the chart data table
  const chartTableColumns = useMemo(() => [
    {
      accessorKey: 'month',
      header: 'Monat',
      isVisible: true,
    },
    {
      accessorKey: 'anwesenheitsstunden',
      header: 'Anwesenheitsstunden',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsstunden',
      header: 'Abwesenheitsstunden',
      Cell: ({ cell }) => cell.getValue().toFixed(2),
      isVisible: true,
    },
    {
      accessorKey: 'abwesenheitsquote',
      header: 'Abwesenheitsquote',
      Cell: ({ cell }) => cell.getValue().toFixed(2) + '%',
      isVisible: true,
    },
  ], []);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="xl"
      fullWidth
    >
      <DialogTitle>
        Abwesenheitsquote für Filiale {filialeName}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {/* Gefiltertes Diagramm */}
        <Grid container spacing={2}>

          {/* Recharts Line Chart für die Abwesenheitsquote */}
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '400px',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Abwesenheitsquote über die letzten 6 Monate
                  </Typography>
                }
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart
                    data={chartData} // Die Abwesenheitsquote-Daten
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis domain={[0, 100]} />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="abwesenheitsquote" stroke="#8884d8" />
                  </LineChart>
                </ResponsiveContainer>
              </CardContent>
            </Card>
          </Grid>

          {/* Mitarbeitertabelle */}
          <Grid item xs={12}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                padding: '0px',
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                backdropFilter: 'blur(10px)',
              }}
            >
              <CardHeader
                title={
                  <Typography variant="h6" component="div">
                    Mitarbeiterübersicht
                  </Typography>
                }
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <VariableTableTree
                  columns={employeeColumns}
                  data={employeeData}
                  initialGrouping={[]}
                  onRowClick={() => {}}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default FilialePopup;
