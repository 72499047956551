import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const VerkaufsgebietDropdown = ({ options, value, onChange }) => {
  return (
    <Autocomplete
      disablePortal
      id="verkaufsgebiet-combo-box"
      options={options}
      getOptionLabel={(option) => option.label || ''}
      value={value}
      onChange={(event, newValue) => onChange(newValue)}
      sx={{ width: 230, marginBottom: 1 }} // Gleiche Breite und Abstand wie im DropdownFilter
      renderInput={(params) => (
        <TextField 
          {...params} 
          label="Verkaufsgebiet" 
          variant="outlined" 
          size="small" // Verkleinert die Höhe des TextField
          sx={{ fontSize: '0.8rem' }} // Verkleinert die Schriftgröße
        />
      )}
    />
  );
};

export default VerkaufsgebietDropdown;
