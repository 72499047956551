import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Box,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PieChartTotalValues from '../../charts/PieChartTotalValues';
import CloseIcon from '@mui/icons-material/Close';

const BigPieChartCardTotal = ({ data, title, metric }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  // Definiere die Farben aus dem Theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart?.yellow || "#FFEB3B",
    theme.palette.chart?.orange || "#FF9800",
    theme.palette.chart?.red || "#F44336",
    theme.palette.chart?.pink || "#E91E63",
    theme.palette.chart?.purpleLight || "#AB47BC",
    theme.palette.chart?.purpleDark || "#7B1FA2",
  ];

  // Sortiere die Daten nach dem ausgewählten Metric in absteigender Reihenfolge
  const sortedData = [...data].sort((a, b) => b[metric] - a[metric]);

  // Zeige nur die Top 3 Kategorien an
  const top3Data = sortedData.slice(0, 3);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderLegend = (chartData) => (
    <Box sx={{ marginTop: 2 }}>
      {chartData.map((entry, index) => (
        <Box key={`legend-${index}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
          <Box
            sx={{
              width: 16,
              height: 16,
              backgroundColor: COLORS[index % COLORS.length],
              mr: 1,
              borderRadius: '50%',
            }}
          />
          <Typography variant="body2">
            {`${entry.filialtyp || 'Unbekannt'}: ${entry[metric].toFixed(2)}`}
          </Typography>
        </Box>
      ))}
    </Box>
  );

  return (
    <Grid item xs={12} md={12}>
      <Paper>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            padding: '0px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            }
          />
          <CardContent>
            {data && data.length > 0 && (
              <Box>
                {/* PieChart mit allen Kategorien */}
                <PieChartTotalValues data={sortedData} metric={metric} />

                {/* Box für die Legende mit den Top 3 Filialtypen */}
                {renderLegend(top3Data)}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'left', mt: 0 }}>
              <Typography
                onClick={handleClickOpen}
                sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontWeight: 'bold' }}
              >
                ...
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Paper>

      {/* Dialog zum Anzeigen aller Kategorien */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Tiefenanalyse {metric.charAt(0).toUpperCase() + metric.slice(1)}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            {/* PieChart mit allen Daten */}
            <Grid item xs={12} md={6}>
              <PieChartTotalValues data={sortedData} metric={metric} height={400} width={'100%'} />
            </Grid>

            {/* Tabelle mit Werten */}
            <Grid item xs={12} md={6}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Filialtyp</TableCell>
                      <TableCell align="right">Wert</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortedData.map((item, index) => (
                      <TableRow key={item.filialtyp}>
                        <TableCell>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Box
                              sx={{
                                width: 16,
                                height: 16,
                                backgroundColor: COLORS[index % COLORS.length],
                                mr: 1,
                                borderRadius: '50%',
                              }}
                            />
                            {item.filialtyp}
                          </Box>
                        </TableCell>
                        <TableCell align="right">{item[metric].toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default BigPieChartCardTotal;
