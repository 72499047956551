import { Box,  } from "@mui/material";
import DropZone from "./DropZone";
import { useRef, useEffect } from 'react';

const ThekeGroup = ({ sectionCount, onDrop, selectedArtikel, sortimentType }) => {
    const isScrollable = sectionCount >= 8;
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const handleWheel = (e) => {
            if (isScrollable && scrollContainerRef.current) {
                e.preventDefault();
                scrollContainerRef.current.scrollLeft += e.deltaY;
            }
        };

        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            scrollContainer.addEventListener('wheel', handleWheel, { passive: false });
        }

        return () => {
            if (scrollContainer) {
                scrollContainer.removeEventListener('wheel', handleWheel);
            }
        };
    }, [isScrollable]);

    return (
        <Box
            sx={{
                mb: 1,
                borderColor: "secondary.main",
                borderRadius: 6,
                width: "100%",
                margin: "0",
            }}
        >
            <Box 
                ref={scrollContainerRef}
                sx={{ 
                    display: "flex", 
                    flexWrap: "nowrap", 
                    gap: 1,
                    overflowX: isScrollable ? "scroll" : "visible",
                    pb: isScrollable ? 2 : 0,
                    '&::-webkit-scrollbar': {
                        height: '8px',
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'rgba(0,0,0,0.1)',
                        borderRadius: '4px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: 'rgba(0,0,0,.2)',
                        borderRadius: '4px',
                        '&:hover': {
                            backgroundColor: 'rgba(0,0,0,.3)',
                        },
                    },
                }}
            >
                {[...Array(sectionCount)].map((_, index) => (
                    <DropZone
                        key={index}
                        onDrop={onDrop}
                        sortimentType={sortimentType}
                        artikel={selectedArtikel.slice(index, index + 1)[0]?.artikelData}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default ThekeGroup;
