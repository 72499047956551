import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, IconButton } from '@mui/material';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Close as CloseIcon } from '@mui/icons-material';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import BigTableCard from '../../card/BigTableCard'; // BigTableCard importieren

export default function ArticleDetailsPopup({ open, onClose, warengruppe, jahrMonat }) {
    const [articleData, setArticleData] = useState([]);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        if (open) {
            fetchTableData();
            fetchChartData();
        }
    }, [open]);

    const fetchTableData = async () => {
        try {
            const previousYear = (parseInt(jahrMonat.split('.')[0]) - 1).toString() + '.' + jahrMonat.split('.')[1];
            
            const currentYearData = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                params: {
                    warengruppe,
                    jahrMonat,
                },
            });
    
            const previousYearData = await axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                params: {
                    warengruppe,
                    jahrMonat: previousYear,
                },
            });
    
            const articleMap = {};
    
            // Summe der Verkaufsmenge für das aktuelle Jahr
            currentYearData.data.results.forEach(item => {
                const { artikelnummer, verkaufsmenge = 0 } = item;
                const artikel = artikelnummer.artikel;
                if (!articleMap[artikel]) {
                    articleMap[artikel] = { verkaufsmenge: 0, verkaufsmengeVJ: 0 };
                }
                articleMap[artikel].verkaufsmenge += verkaufsmenge;
            });
    
            // Summe der Verkaufsmenge für das Vorjahr
            previousYearData.data.results.forEach(item => {
                const { artikelnummer, verkaufsmenge = 0 } = item;
                const artikel = artikelnummer.artikel;
                if (!articleMap[artikel]) {
                    articleMap[artikel] = { verkaufsmenge: 0, verkaufsmengeVJ: 0 };
                }
                articleMap[artikel].verkaufsmengeVJ += verkaufsmenge;
            });
    
            // Formatierung der Daten in ein Array für die Tabelle und Filterung
            const formattedTableData = Object.entries(articleMap)
                .map(([artikel, data]) => {
                    const differenz = data.verkaufsmenge - data.verkaufsmengeVJ;
                    const differenzProzent = data.verkaufsmengeVJ
                        ? ((differenz / data.verkaufsmengeVJ) * 100).toFixed(2) + '%'
                        : 'N/A';
    
                    return {
                        artikel,
                        verkaufsmenge: data.verkaufsmenge.toLocaleString('de-DE'),
                        verkaufsmengeVJ: data.verkaufsmengeVJ.toLocaleString('de-DE'),
                        differenz: differenz.toLocaleString('de-DE'),
                        differenzProzent,
                    };
                })
                .filter(item => item.verkaufsmenge !== "0" || item.verkaufsmengeVJ !== "0"); // Filterung der Einträge mit Verkaufsmenge und Verkaufsmenge VJ gleich 0
    
            setArticleData(formattedTableData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Artikeldaten:', error);
        }
    };
    

    const fetchChartData = async () => {
        try {
            const last6Months = getLast6Months(jahrMonat);

            const dataPromises = last6Months.map(month =>
                axiosInstance.get('/scorecard_month_article/ware-artikel-monat/', {
                    params: {
                        warengruppe,
                        jahrMonat: month,
                    },
                }).then(response => ({ month, data: response.data.results }))
            );

            const results = await Promise.all(dataPromises);

            const articleMap = {};

            // Daten für die letzten 6 Monate sammeln und aggregieren
            results.forEach(({ month, data }) => {
                data.forEach(item => {
                    const { artikelnummer, verkaufsmenge = 0 } = item;
                    const artikel = artikelnummer.artikel;

                    if (!articleMap[artikel]) {
                        articleMap[artikel] = {};
                    }
                    
                    // Aggregiere die Verkaufsmenge für denselben Artikel und Monat
                    if (!articleMap[artikel][month]) {
                        articleMap[artikel][month] = 0;
                    }
                    articleMap[artikel][month] += verkaufsmenge;
                });
            });

            // Finde die Top 10 Artikel basierend auf der Verkaufsmenge im aktuellen ausgewählten Monat
            const topArticles = Object.entries(articleMap)
                .sort(([, a], [, b]) => (b[jahrMonat] || 0) - (a[jahrMonat] || 0))
                .slice(0, 10)
                .map(([artikel]) => artikel);

            // Daten für das Chart formatieren und nur die Top 10 Artikel berücksichtigen
            const formattedChartData = last6Months.map(month => {
                const monthData = { name: month };
                topArticles.forEach(artikel => {
                    monthData[artikel] = articleMap[artikel][month] || 0;
                });
                return monthData;
            });

            setChartData(formattedChartData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Verkaufsdaten für das Chart:', error);
        }
    };

    const getLast6Months = (currentMonth) => {
        const months = [];
        let month = dayjs(currentMonth, 'YYYY.MM');
        for (let i = 0; i < 6; i++) {
            months.push(month.format('YYYY.MM'));
            month = month.subtract(1, 'month');
        }
        return months.reverse();
    };

    // Spaltenkonfiguration für die Tabelle
    const columns = [
        { accessorKey: 'artikel', header: 'Artikel' },
        { accessorKey: 'verkaufsmenge', header: 'Verkaufsmenge', align: 'right' },
        { accessorKey: 'verkaufsmengeVJ', header: 'Verkaufsmenge VJ', align: 'right' },
        { accessorKey: 'differenz', header: 'Differenz', align: 'right' },
        { accessorKey: 'differenzProzent', header: 'Differenz %', align: 'right' },
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
            <DialogTitle>
                Artikel Details - {warengruppe}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {/* Line Chart */}
                <Box sx={{ width: '100%', height: 300, mb: 3 }}>
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={chartData} margin={{ left: 20, right: 20, top: 5, bottom: 5 }}>
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip formatter={(val) => val ? val.toLocaleString('de-DE') : ''} />
                            {Object.keys(chartData[0] || {}).filter(key => key !== 'name').map((artikel, index) => (
                                <Line 
                                    key={index} 
                                    type="monotone" 
                                    dataKey={artikel} 
                                    stroke={`hsl(${(index * 60) % 360}, 70%, 50%)`} 
                                    name={artikel} 
                                    dot={false} 
                                />
                            ))}
                        </LineChart>
                    </ResponsiveContainer>
                </Box>

                {/* Data Table */}
                <BigTableCard
                    title="Artikeldetails"
                    data={articleData}
                    columns={columns}
                    onRowClick={(row) => console.log("Row clicked:", row)}
                    muiTableBodyCellProps={{ align: 'right' }}
                />
            </DialogContent>
        </Dialog>
    );
}
