import React, {useEffect, useState} from 'react';
import {Box, Button, Checkbox, Container, Divider, Paper, Switch, TextField, Typography,} from '@mui/material';
import DropdownFilter from "../../../components/filter/DropdownFilter";
import axiosInstance from "../../../services/axiosInstance";
import Snackbar from '@mui/material/Snackbar';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsLayout from "../../../components/layout/settings/SettingsLayout";
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from "@mui/material/CircularProgress";
import {useNavigate} from 'react-router-dom';

const FilialenVerwalten = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [availableVerkaufsgebiete, setAvailableVerkaufsgebiete] = useState([]);
    const [userVerkaufsgebiete, setUserVerkaufsgebiete] = useState([]);
    const [expandedVerkaufsgebiete, setExpandedVerkaufsgebiete] = useState({});

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const [isLoading, setIsLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                await Promise.all([fetchUsers(), fetchVerkaufsgebiete()]);
            } catch (error) {
                console.error("Error fetching data:", error);
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get('/auth/users/');
            console.log("Users data:", response.data);
            setUsers(response.data);
            setUserOptions(response.data.map(user => ({label: user.username, value: user.id, name: user.username})));
        } catch (error) {
            console.error("Error while fetching users", error.message);
        }
    };

    const fetchVerkaufsgebiete = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/verkaufsgebiete/');
            console.log("Verkaufsgebiete data:", response.data);
            const filteredData = response.data.filter(vkg =>
                !vkg.verkaufsgebiet.includes('Lieferkunden') &&
                !vkg.verkaufsgebiet.includes('Lieferservice')
            );
            console.log("Filtered Verkaufsgebiete data:", filteredData);
            setAvailableVerkaufsgebiete(filteredData);
        } catch (error) {
            console.error("Error while fetching verkaufsgebiete", error.message);
        }
    };

    const handleUserChange = (selectedUser) => {
        console.log("Selected user:", selectedUser);
        setSelectedUser(selectedUser);
        if (selectedUser) {
            const user = users.find(u => u.id === selectedUser.value);
            console.log("Found user:", user);
            if (user) {
                console.log("User verkaufsgebiete:", user.verkaufsgebiete);
                console.log("User all_filialen:", user.all_filialen);
                console.log("availableVerkaufsgebiete", availableVerkaufsgebiete)

                const userVerkaufsgebieteData = availableVerkaufsgebiete.map(vkg => {
                    const userVkg = user.verkaufsgebiete.find(userVkg => userVkg.name === vkg.verkaufsgebiet);
                    return {
                        verkaufsgebiet: vkg.verkaufsgebiet,
                        vkg_ID: vkg.vkg_ID,  // Save the vkg_id here
                        enabled: userVkg !== undefined,
                        filialen: vkg.filialen.map(filiale => ({
                            filiale: filiale.filiale,
                            filialnummer: filiale.filialnummer,
                            enabled: user.all_filialen.includes(filiale.filiale)
                        }))
                    };
                });
                console.log("Processed user verkaufsgebiete:", userVerkaufsgebieteData);
                setUserVerkaufsgebiete(userVerkaufsgebieteData);
            }
        } else {
            setUserVerkaufsgebiete([]);
        }
        setExpandedVerkaufsgebiete({});
    };

    const toggleVerkaufsgebietExpansion = (verkaufsgebiet, isOpen = null) => {
        // if isOpen has value
        if (isOpen !== null) {
            setExpandedVerkaufsgebiete(prev => ({
                ...prev,
                [verkaufsgebiet]: isOpen,
            }))
        } else {
            setExpandedVerkaufsgebiete(prev => ({
                ...prev,
                [verkaufsgebiet]: !prev[verkaufsgebiet],
            }))
        }
    };

    const handleVerkaufsgebietChange = (verkaufsgebiet, isEnabled) => {
        if (isEnabled) {
            toggleVerkaufsgebietExpansion(verkaufsgebiet, false)
        } else {
            toggleVerkaufsgebietExpansion(verkaufsgebiet, true)
        }

        setUserVerkaufsgebiete(prev => prev.map(vkg => {
            if (vkg.verkaufsgebiet === verkaufsgebiet) {
                return {
                    ...vkg,
                    enabled: !isEnabled,
                    filialen: vkg.filialen.map(filiale => ({...filiale, enabled: !isEnabled}))
                };
            }
            return vkg;
        }));
    };

    const handleFilialChange = (verkaufsgebiet, filiale, isEnabled) => {
        setUserVerkaufsgebiete(prev => prev.map(vkg => {
            if (vkg.verkaufsgebiet === verkaufsgebiet) {
                const updatedFilialen = vkg.filialen.map(f =>
                    f.filiale === filiale
                        ? {...f, enabled: !isEnabled}
                        : f
                );
                const anyFilialeEnabled = updatedFilialen.some(f => f.enabled);
                return {
                    ...vkg,
                    enabled: anyFilialeEnabled,
                    filialen: updatedFilialen
                };
            }
            return vkg;
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!selectedUser) {
            setSnackbarMessage('Bitte wähle einen Benutzer aus!');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }
        console.log("submit userVerkaufsgebiete", userVerkaufsgebiete)
        const updatedUserData = {
            verkaufsgebiete_ids: userVerkaufsgebiete
                .filter(vkg => vkg.enabled)
                .map(vkg => vkg.vkg_ID)
                .flat(),
            filialen_ids: userVerkaufsgebiete
                .filter(vkg => vkg.enabled)
                .map(vkg => vkg.filialen.filter(filiale => filiale.enabled).map(filiale => filiale.filialnummer))
                .flat()
        };

        console.log("Sending data:", updatedUserData);  // For debugging

        axiosInstance.put(`/auth/users/${selectedUser.value}/`, updatedUserData)
            .then(response => {
                setSnackbarMessage('Filialen erfolgreich aktualisiert!');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
                fetchUsers(); // Refresh user data
            })
            .catch(error => {
                console.error("Error updating user's verkaufsgebiete", error.response?.data);
                setSnackbarMessage(`Fehler beim Aktualisieren der Filialen: ${error.response?.data[0] || 'Unbekannter Fehler'}`);
                setSnackbarSeverity('error');
                setSnackbarOpen(true);
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleSearchChange = (event) => {
        const newSearchTerm = event.target.value;
        setSearchTerm(newSearchTerm);

        // Automatically expand all Verkaufsgebiete when searching
        if (newSearchTerm) {
            const allExpanded = userVerkaufsgebiete.reduce((acc, vkg) => {
                acc[vkg.verkaufsgebiet] = true;
                return acc;
            }, {});
            setExpandedVerkaufsgebiete(allExpanded);
        } else {
            // Collapse all when search is cleared
            setExpandedVerkaufsgebiete({});
        }
    };

    const filterFilialen = (filialen) => {
        return filialen.filter(filiale =>
            filiale.filiale.toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    return (
        <SettingsLayout>
            <Container maxWidth="md" sx={{maxWidth: '700px !important', height: '100%', display: 'flex', flexDirection: 'column'}}>
                <Paper sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', height: '100%', minHeight: '400px'}}>
                    <Box component="form" onSubmit={handleSubmit} sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', p: 1}}>
                        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                            Filialen Zuweisung
                        </Typography>
                        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <DropdownFilter
                                label={"Benutzer"}
                                options={userOptions}
                                value={selectedUser}
                                onChange={handleUserChange}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate("/settings/rechte/rollen-verwalten")}
                                sx={{mt: 1, mb: 1, p: 1}}
                            >
                                Nutzer verwalten
                            </Button>
                        </Box>

                        {isLoading ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    mt: 10,
                                    mb: 10,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                }}
                            >
                                <CircularProgress size={80}/>
                            </Box>
                        ) : selectedUser ? (
                            <Box sx={{mt: 3}}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    placeholder="Filiale suchen..."
                                    value={searchTerm}
                                    size="small"
                                    onChange={handleSearchChange}
                                    sx={{mb: 2}}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon/>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                                {userVerkaufsgebiete.length > 0 ? (
                                    userVerkaufsgebiete.map((verkaufsgebietEntry, index) => {
                                        const filteredFilialen = filterFilialen(verkaufsgebietEntry.filialen);
                                        if (filteredFilialen.length === 0 && searchTerm) return null;

                                        return (
                                            <React.Fragment key={verkaufsgebietEntry.verkaufsgebiet}>
                                                <Box sx={{mb: 2, mx: 1}}>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center',
                                                            cursor: 'pointer'
                                                        }}
                                                        onClick={() => toggleVerkaufsgebietExpansion(verkaufsgebietEntry.verkaufsgebiet)}
                                                    >
                                                        <Typography variant="h6">
                                                            {verkaufsgebietEntry.verkaufsgebiet}
                                                            <span style={{
                                                                marginLeft: '8px',
                                                                transform: expandedVerkaufsgebiete[verkaufsgebietEntry.verkaufsgebiet] || searchTerm ? 'rotate(90deg)' : 'rotate(0deg)',
                                                                transition: 'transform 0.2s'
                                                            }}>
                                                                ▼
                                                            </span>
                                                        </Typography>
                                                        <Switch
                                                            checked={verkaufsgebietEntry.enabled}
                                                            onChange={() => handleVerkaufsgebietChange(verkaufsgebietEntry.verkaufsgebiet, verkaufsgebietEntry.enabled)}
                                                            color="primary"
                                                        />
                                                    </Box>
                                                    {(expandedVerkaufsgebiete[verkaufsgebietEntry.verkaufsgebiet] || searchTerm) && filteredFilialen.map((filiale) => (
                                                        <Box key={filiale.filialnummer} sx={{display: 'flex', alignItems: 'center', ml: 4}}>
                                                            <Checkbox
                                                                checked={filiale.enabled}
                                                                onChange={() => handleFilialChange(
                                                                    verkaufsgebietEntry.verkaufsgebiet,
                                                                    filiale.filiale,
                                                                    filiale.enabled
                                                                )}
                                                                color="primary"
                                                                // disabled={!verkaufsgebietEntry.enabled}
                                                            />
                                                            <Typography>{filiale.filiale}</Typography>
                                                        </Box>
                                                    ))}
                                                </Box>
                                                {index < userVerkaufsgebiete.length - 1 && <Divider/>}
                                            </React.Fragment>
                                        );
                                    })
                                ) : (
                                    <Typography>No Verkaufsgebiete available.</Typography>
                                )}
                                <Box sx={{display: 'flex', justifyContent: 'flex-end', mt: 2}}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Änderungen Speichern
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Typography variant="body1" color="error" sx={{mt: 2}}>
                                Bitte wähle einen Benutzer aus
                            </Typography>
                        )}

                        <Snackbar
                            open={snackbarOpen}
                            autoHideDuration={6000}
                            onClose={handleSnackbarClose}
                            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                            message={
                                <span style={{display: 'flex', alignItems: 'center'}}>
                                    {snackbarSeverity === 'success' ? (
                                        <CheckCircleIcon style={{color: 'green', marginRight: 8}}/>
                                    ) : (
                                        <ErrorIcon style={{color: 'red', marginRight: 8}}/>
                                    )}
                                    {snackbarMessage}
                                </span>
                            }
                        />
                    </Box>
                </Paper>
            </Container>
        </SettingsLayout>
    )
        ;
};

export default FilialenVerwalten;
