// components/charts/LineChartVariableYAxis.js

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, Slider, Typography } from '@mui/material';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useTheme } from '@mui/material/styles';

const LineChartVariableYAxis = ({
  data,
  dataKeys,
  xAxisKey,
  yAxisMax,
  yAxisMin,
  height,
  width,
  lineColors,
  legendNames, // Wird jetzt nicht mehr verwendet, da die Legende entfernt wird
}) => {
  const theme = useTheme();

  // Initialisierung des Y-Achsenbereichs mit Puffer
  const initialYAxisMax = yAxisMax || Math.max(...data.flatMap(item => dataKeys.map(key => item[key])), 0) * 1.1;
  const initialYAxisMin = yAxisMin || 0;

  const [yAxisRange, setYAxisRange] = useState([initialYAxisMin, initialYAxisMax]);

  useEffect(() => {
    const newMax = yAxisMax || Math.max(...data.flatMap(item => dataKeys.map(key => item[key])), 0) * 1.1;
    const newMin = yAxisMin || 0;
    setYAxisRange([newMin, newMax]);
  }, [yAxisMax, yAxisMin, data, dataKeys]);

  const handleSliderChange = (event, newValue) => {
    setYAxisRange(newValue);
  };

  // Standardfarben setzen, falls keine Farben übergeben werden
  const defaultLineColors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.error.main,
    theme.palette.warning.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  return (
    <Box sx={{ width: width || '100%', padding: 2 }}>
      <Box sx={{ width: '100%', height: height || 300 }}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
          >
            <CartesianGrid stroke={theme.palette.grey[300]} />
            <XAxis
              dataKey={xAxisKey}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
              angle={-45}
              textAnchor="end"
            />
            <YAxis
              domain={[yAxisRange[0], yAxisRange[1]]}
              allowDataOverflow={true}
              tickFormatter={(value) => value.toLocaleString('de-DE')}
              tick={{ fontSize: 12 }}
              tickLine={false}
              axisLine={false}
            />
            <Tooltip
              formatter={(value) => value.toLocaleString('de-DE')}
              labelFormatter={(label) => label}
              contentStyle={{ fontSize: '12px' }}
            />
            {/* Entfernte Legende */}
            {dataKeys.map((key, index) => (
              <Line
                key={key}
                type="monotone"
                dataKey={key}
                stroke={
                  lineColors && lineColors[index]
                    ? lineColors[index]
                    : defaultLineColors[index] || '#8884d8'
                }
                name={legendNames && legendNames[index] ? legendNames[index] : key}
                strokeWidth={2}
                dot={false}
              />
            ))}
          </LineChart>
        </ResponsiveContainer>
      </Box>
      <Box sx={{ width: '100%', mt: 2 }}>

        <Slider
          value={yAxisRange}
          onChange={handleSliderChange}
          valueLabelDisplay="auto"
          min={yAxisMin || 0}
          max={yAxisMax || Math.max(...data.flatMap(item => dataKeys.map(key => item[key])), 0) * 1.1}
          step={(yAxisMax || Math.max(...data.flatMap(item => dataKeys.map(key => item[key])), 0) * 1.1) / 100}
          marks={false} // Entfernt die Markierungen

          aria-labelledby="range-slider"
        />
      </Box>
    </Box>
  );
};

LineChartVariableYAxis.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  yAxisMax: PropTypes.number,
  yAxisMin: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lineColors: PropTypes.arrayOf(PropTypes.string),
  legendNames: PropTypes.arrayOf(PropTypes.string),
};

export default LineChartVariableYAxis;
