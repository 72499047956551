import React from "react";
import { PieChart } from '@mui/x-charts/PieChart';
import { useTheme } from '@mui/material/styles';
import { CardActions, Typography } from '@mui/material';

const PieChartFluktuation = ({ data }) => {
    const theme = useTheme();

    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
    ];

    const chartData = data.map((item, index) => ({
        id: item.Typ,
        value: item.ZuAbgang,
        color: COLORS[index % COLORS.length],
    }));

    return (
        <>
            <PieChart
                series={[
                    {
                        data: chartData,
                        innerRadius: 60,
                        outerRadius: 100,
                        label: {
                            visible: false, // Labels im Diagramm verstecken
                        },
                        colorBy: 'color',
                    },
                ]}
                width={300}
                height={300}
                margin={{ top: 0, bottom: 10, left: 10, right: 10 }}
            />
            <CardActions sx={{ justifyContent: 'center' }}>
                {chartData.map((item) => (
                    <Typography key={item.id} variant="body2" sx={{ marginX: 1 }}>
                        <span style={{ color: item.color }}>⬤</span> {item.id}: {item.value}
                    </Typography>
                ))}
            </CardActions>
        </>
    );
};

export default PieChartFluktuation;
