import {AppBar, Toolbar, Typography} from "@mui/material";
import React from "react";

export const TitleBar = ({title}) => {
    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h3" component="div" sx={{flexGrow: 1}}>
                    {title}
                </Typography>
            </Toolbar>
        </AppBar>
    )
}
