import React, { useState, useEffect, useContext } from 'react';
import {
    Grid, Box, Typography, Paper, CircularProgress, IconButton, Card, CardContent,
    LinearProgress, CardHeader, Dialog, DialogTitle, DialogContent, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Button, Collapse, Stack
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ChecklistIcon from '@mui/icons-material/Checklist';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/Filialcheck/Filialcheck';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from '../../../provider/FilterProvider';

const FilialcheckAnalyseManagementPage = () => {
    const [loading, setLoading] = useState(true);
    const [activeChecks, setActiveChecks] = useState([]);
    const [allChecks, setAllChecks] = useState([]);
    const [error, setError] = useState(null);
    const [filialen, setFilialen] = useState([]);
    const [scaleCheckpoints, setScaleCheckpoints] = useState([]);
    const [filialeData, setFilialeData] = useState([]);
    const [verkaufsgebietCounts, setVerkaufsgebietCounts] = useState([]);
    const [checkCounts, setCheckCounts] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCheck, setSelectedCheck] = useState(null);
    const [verkaufsleiterChecks, setVerkaufsleiterChecks] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});
    const [chartData, setChartData] = useState([]);
    const [datumOptions, setDatumOptions] = useState([]);
    const [checkOptions, setCheckOptions] = useState([]);
    const maxRating = 10;

    const { filterState, handleDatumChange } = useContext(FilterContext);

    const [selectedFilterCheck, setSelectedFilterCheck] = useState({ label: 'Alle Checks', value: 'all' });

    useEffect(() => {
        const fetchFilialen = async () => {
            try {
                const response = await axiosInstance.get('/stammdaten/stammdatenfiliale/');
                setFilialen(response.data.results || []);
            } catch (error) {
                console.error('Fehler beim Abrufen der Filialdaten:', error);
            }
        };

        fetchFilialen();
    }, []);

    useEffect(() => {
        const fetchActiveChecks = async () => {
            try {
                const response = await axiosInstance.get('/filialcheck/checks/');
                const activeChecksData = response.data.results.filter(check => check.is_active);
                setActiveChecks(activeChecksData);
                setAllChecks(response.data.results);
                setLoading(false);

                // Check-Optionen für den Filter vorbereiten
                const checkOptionsData = [
                    { label: 'Alle Checks', value: 'all' },
                    ...activeChecksData.map(check => ({ label: check.name, value: check.id }))
                ];
                setCheckOptions(checkOptionsData);

            } catch (error) {
                setError('Fehler beim Laden der aktiven Checks.');
                setLoading(false);
            }
        };

        fetchActiveChecks();
    }, []);

    useEffect(() => {
        const fetchScaleCheckpoints = async () => {
            try {
                const response = await axiosInstance.get('/filialcheck/checkpoints/');
                const scaleCps = response.data.results.filter(cp => cp.rating_type === 'scale');
                setScaleCheckpoints(scaleCps);
            } catch (error) {
                console.error('Fehler beim Abrufen der Checkpoints:', error);
            }
        };

        fetchScaleCheckpoints();
    }, []);

    useEffect(() => {
        const fetchDatumOptions = async () => {
            try {
                const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
                setDatumOptions(response.data.map(item => ({ label: item, value: item })));
            } catch (error) {
                console.error('Fehler beim Abrufen der Datum-Optionen:', error);
            }
        };

        fetchDatumOptions();
    }, []);

    useEffect(() => {
        const fetchChecksPerMonth = async () => {
            try {
                if (!filterState.datum) return;

                const selectedMonth = dayjs(filterState.datum.value, 'YYYY-MM');
                const startOfMonth = selectedMonth.startOf('month').format('YYYY-MM-DD');
                const endOfMonth = selectedMonth.endOf('month').format('YYYY-MM-DD');

                const response = await axiosInstance.get('/filialcheck/ratings/', {
                    params: {
                        datum__gte: startOfMonth,
                        datum__lte: endOfMonth
                    }
                });

                const filteredRatings = response.data.results.filter(rating => {
                    const ratingDate = dayjs(rating.datum);
                    return ratingDate.isSame(selectedMonth, 'month');
                });

                // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
                const enrichedRatings = filteredRatings.map(rating => {
                    const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
                    return {
                        ...rating,
                        filiale: filialeData ? filialeData.filiale : 'Unbekannt',
                        verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
                    };
                });

                // Filtern der Ratings, um nur 'scale'-Checkpoints zu berücksichtigen
                const scaleCheckpointIds = new Set(scaleCheckpoints.map(cp => cp.id));
                const scaleRatings = enrichedRatings.filter(rating => scaleCheckpointIds.has(rating.checkpoint));

                // Anwendung des Check-Filters
                const filteredScaleRatings = selectedFilterCheck.value === 'all' ? scaleRatings : scaleRatings.filter(rating => rating.check_related === selectedFilterCheck.value);

                // Gruppierung der Daten pro Filiale
                const filialeDataMap = {};
                const checkCountsMap = {};

                filteredScaleRatings.forEach(rating => {
                    const { filialnummer, filiale, verkaufsgebiet, session_id, value, check_related } = rating;

                    if (!filialeDataMap[filialnummer]) {
                        filialeDataMap[filialnummer] = {
                            filialnummer,
                            filiale,
                            verkaufsgebiet,
                            sessions: new Set(),
                            totalValue: 0,
                            ratingCount: 0,
                            checks: {},
                        };
                    }

                    filialeDataMap[filialnummer].sessions.add(session_id);
                    filialeDataMap[filialnummer].totalValue += value;
                    filialeDataMap[filialnummer].ratingCount += 1;

                    if (!filialeDataMap[filialnummer].checks[check_related]) {
                        filialeDataMap[filialnummer].checks[check_related] = {
                            checkId: check_related,
                            count: 0,
                            sessionIds: new Set(),
                        };
                    }
                    filialeDataMap[filialnummer].checks[check_related].count += 1;
                    filialeDataMap[filialnummer].checks[check_related].sessionIds.add(session_id);

                    if (!checkCountsMap[check_related]) {
                        checkCountsMap[check_related] = new Set();
                    }
                    checkCountsMap[check_related].add(session_id);
                });

                const filialeDataArray = Object.values(filialeDataMap).map(filiale => {
                    return {
                        ...filiale,
                        checks: Object.values(filiale.checks),
                    };
                });

                setFilialeData(filialeDataArray);

                // Setzen der Check Counts
                setCheckCounts(checkCountsMap);

                // Berechnung der Checks und Durchschnittswerte pro Verkaufsgebiet
                const verkaufsgebietCountsData = {};
                filialeDataArray.forEach(filiale => {
                    const verkaufsgebiet = filiale.verkaufsgebiet || 'Unbekannt';
                    const sessionCount = filiale.sessions.size;

                    if (!verkaufsgebietCountsData[verkaufsgebiet]) {
                        verkaufsgebietCountsData[verkaufsgebiet] = {
                            sessionCount: 0,
                            totalValue: 0,
                            ratingCount: 0,
                            filialen: [],
                        };
                    }

                    verkaufsgebietCountsData[verkaufsgebiet].sessionCount += sessionCount;
                    verkaufsgebietCountsData[verkaufsgebiet].totalValue += filiale.totalValue;
                    verkaufsgebietCountsData[verkaufsgebiet].ratingCount += filiale.ratingCount;
                    verkaufsgebietCountsData[verkaufsgebiet].filialen.push(filiale);
                });

                const verkaufsgebietCountsArray = Object.keys(verkaufsgebietCountsData).map(key => {
                    const data = verkaufsgebietCountsData[key];
                    const averagePercentage = (data.totalValue / (data.ratingCount * maxRating)) * 100;

                    return {
                        verkaufsgebiet: key,
                        sessionCount: data.sessionCount,
                        averagePercentage: averagePercentage.toFixed(2),
                        filialen: data.filialen,
                    };
                });

                setVerkaufsgebietCounts(verkaufsgebietCountsArray);
            } catch (error) {
                setError('Fehler beim Laden der Bewertungsdaten.');
            }
        };

        if (filialen.length > 0 && scaleCheckpoints.length > 0) {
            fetchChecksPerMonth();
        }
    }, [filialen, scaleCheckpoints, selectedFilterCheck, filterState.datum]);

    // useEffect für die Chartdaten, angepasst mit Check-Filter
    useEffect(() => {
        const fetchChartData = async () => {
            try {
                if (!filterState.datum) return;

                const selectedMonth = dayjs(filterState.datum.value, 'YYYY-MM');
                const endDate = selectedMonth.endOf('month');
                const startDate = endDate.subtract(5, 'months').startOf('month');

                const response = await axiosInstance.get('/filialcheck/ratings/', {
                    params: {
                        datum__gte: startDate.format('YYYY-MM-DD'),
                        datum__lte: endDate.format('YYYY-MM-DD')
                    }
                });

                const ratings = response.data.results;

                // Anreicherung der Ratings mit Filial- und Verkaufsgebiets-Daten
                const enrichedRatings = ratings.map(rating => {
                    const filialeData = filialen.find(f => f.filialnummer === rating.filialnummer);
                    return {
                        ...rating,
                        filiale: filialeData ? filialeData.filiale : 'Unbekannt',
                        verkaufsgebiet: filialeData ? filialeData.verkaufsgebiet : 'Unbekannt',
                    };
                });

                // Anwendung des Check-Filters
                const filteredRatings = selectedFilterCheck.value === 'all' ? enrichedRatings : enrichedRatings.filter(rating => rating.check_related === selectedFilterCheck.value);

                // Gruppierung der Daten nach Verkaufsgebiet und Monat, Zählen einzigartiger session_ids
                const groupedData = {};

                filteredRatings.forEach(rating => {
                    const ratingMonth = dayjs(rating.datum).format('YYYY-MM');
                    const verkaufsgebiet = rating.verkaufsgebiet || 'Unbekannt';

                    if (!groupedData[verkaufsgebiet]) {
                        groupedData[verkaufsgebiet] = {};
                    }

                    if (!groupedData[verkaufsgebiet][ratingMonth]) {
                        groupedData[verkaufsgebiet][ratingMonth] = new Set();
                    }

                    groupedData[verkaufsgebiet][ratingMonth].add(rating.session_id);
                });

                // Vorbereitung der Daten für BigChartCardRecharts
                const seriesData = [];

                // Liste der Monate generieren
                const months = [];
                for (let i = 5; i >= 0; i--) {
                    months.push(endDate.subtract(i, 'months').format('YYYY-MM'));
                }

                Object.keys(groupedData).forEach(verkaufsgebiet => {
                    const series = {
                        id: verkaufsgebiet,
                        data: months.map(month => {
                            const sessionsSet = groupedData[verkaufsgebiet][month] || new Set();
                            return {
                                x: month,
                                y: sessionsSet.size
                            };
                        })
                    };
                    seriesData.push(series);
                });

                setChartData(seriesData);

            } catch (error) {
                console.error('Fehler beim Laden der Chartdaten:', error);
            }
        };

        if (filialen.length > 0 && filterState.datum) {
            fetchChartData();
        }

    }, [filialen, filterState.datum, selectedFilterCheck]);

    const handleCheckClick = (check) => {
        setSelectedCheck(check);
        const checkData = filialeData.filter(filiale => filiale.checks.some(c => c.checkId === check.id));
        const verkaufsleiterCounts = {};
        checkData.forEach(filiale => {
            const verkaufsgebiet = filiale.verkaufsgebiet || 'Unbekannt';
            const checkInfo = filiale.checks.find(c => c.checkId === check.id);
            if (checkInfo) {
                if (!verkaufsleiterCounts[verkaufsgebiet]) {
                    verkaufsleiterCounts[verkaufsgebiet] = 0;
                }
                verkaufsleiterCounts[verkaufsgebiet] += checkInfo.sessionIds.size;
            }
        });

        const verkaufsleiterCountsArray = Object.keys(verkaufsleiterCounts).map(key => ({
            verkaufsgebiet: key,
            count: verkaufsleiterCounts[key],
        }));

        setVerkaufsleiterChecks(verkaufsleiterCountsArray);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setSelectedCheck(null);
    };

    const handleRowClick = (index) => {
        setExpandedRows(prev => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    if (loading) {
        return (
            <Layout>
                <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item xs={12}>
                        <PageHeader
                            title="Management-Analyse"
                            subtitle="Verwalte deine Filialchecks und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                            Icon={ChecklistIcon}
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    if (error) {
        return (
            <Layout>
                <Grid container spacing={2} sx={{ p: 3 }}>
                    <Grid item xs={12}>
                        <PageHeader
                            title="Management-Analyse"
                            subtitle="Verwalte deine Filialchecks und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                            Icon={ChecklistIcon}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" color="error">{error}</Typography>
                    </Grid>
                </Grid>
            </Layout>
        );
    }

    const maxChecks = Math.max(...verkaufsgebietCounts.map(item => item.sessionCount), 0);

    return (
        <Layout>
            <Grid container item spacing={2} alignItems={"center"} sx={{ p: 3 }}>
                <Grid item xs={8} >
                    <PageHeader
                        title="Management-Analyse"
                        subtitle="Verwalte deine Filialchecks und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                        Icon={ChecklistIcon}
                    />
                </Grid>

                {/* Auswahl für den Datum- und Check-Filter */}
                <Grid item xs={12} md={4}>
                    <Stack
                        direction={{ xs: 'column', sm: 'row' }}
                        spacing={2}
                    >
                        <DropdownFilter
                            label="Datum"
                            options={datumOptions}
                            value={filterState.datum}
                            onChange={handleDatumChange}
                        />
                        <DropdownFilter
                            label="Check filtern"
                            options={checkOptions}
                            value={selectedFilterCheck}
                            onChange={setSelectedFilterCheck}
                        />
                    </Stack>
                </Grid>

{/* Zeile mit Linechart und den aktiven Checks */}
<Grid container item spacing={2}>
                    {/* Chart */}
                    <Grid item xs={12} md={8}>
                        <BigChartCardRecharts
                            data={chartData}
                            title="Anzahl der Checks (Sessions) pro Verkaufsgebiet der letzten sechs Monate"
                            infoContent="Dieses Diagramm zeigt die Anzahl der durchgeführten Checks (basierend auf Sessions) pro Verkaufsgebiet über die letzten sechs Monate."
                        />
                    </Grid>

                    {/* Aktive Checks in Cards neben dem Chart */}
                    <Grid item xs={12} md={4}>
                        <Grid container spacing={2}>
                            {activeChecks.length > 0 ? (
                                activeChecks.map((check) => (
                                    <Grid item xs={12} key={check.id}>
                                        <Paper sx={{ p: 3, cursor: 'pointer' }} onClick={() => handleCheckClick(check)}>
                                            <Typography variant="h6" gutterBottom>
                                                {check.name}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                Checks: {checkCounts[check.id] ? checkCounts[check.id].size : 0}
                                            </Typography>
                                        </Paper>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1">Keine aktiven Checks verfügbar.</Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>

                {/* Dialog für Details */}
                <Dialog open={dialogOpen} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Details zu {selectedCheck?.name}</DialogTitle>
                    <DialogContent>
                        {verkaufsleiterChecks.length > 0 ? (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Verkaufsgebiet</TableCell>
                                            <TableCell>Anzahl Checks</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {verkaufsleiterChecks.map((item, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{item.verkaufsgebiet}</TableCell>
                                                <TableCell>{item.count}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography>Keine Daten verfügbar.</Typography>
                        )}
                    </DialogContent>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: 2 }}>
                        <Button onClick={handleCloseDialog} variant="contained">
                            Schließen
                        </Button>
                    </Box>
                </Dialog>

                {/* Anzeige der Checks nach Verkaufsgebiet mit Progress Charts */}
                <Grid item xs={12}>
                    <Card
                        sx={{
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                            mt: 4,
                        }}
                    >
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div">
                                    Anzahl der Checks nach Verkaufsgebiet im {filterState.datum?.label || '...'}
                                </Typography>
                            }
                        />
                        <CardContent>
                            {verkaufsgebietCounts.length > 0 ? (
                                verkaufsgebietCounts.map((item, index) => (
                                    <Paper key={index} sx={{ my: 2 }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                p: 2,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRowClick(index)}
                                        >
                                            {expandedRows[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                                            <Box sx={{ flexGrow: 1, ml: 2 }}>
                                                <Typography variant="body1">
                                                    <strong>{item.verkaufsgebiet}: {item.sessionCount} Checks </strong>
                                                </Typography>
                                                <LinearProgress
                                                    variant="determinate"
                                                    value={(item.sessionCount / maxChecks) * 100}
                                                    sx={{ height: 10, borderRadius: 5, mt: 1 }}
                                                />

                                            </Box>
                                        </Box>
                                        <Collapse in={expandedRows[index]} timeout="auto" unmountOnExit>
                                            <Box sx={{ p: 2 }}>
                                                <TableContainer component={Paper}>
                                                    <Table>
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell>Filiale</TableCell>
                                                                <TableCell>Anzahl Checks</TableCell>
                                                                <TableCell>Durchschnittliche Bewertung (%)</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {item.filialen.map((filiale, idx) => {
                                                                const averagePercentage = (filiale.totalValue / (filiale.ratingCount * maxRating)) * 100;
                                                                return (
                                                                    <TableRow key={idx}>
                                                                        <TableCell>{filiale.filiale}</TableCell>
                                                                        <TableCell>{filiale.sessions.size}</TableCell>
                                                                        <TableCell>{averagePercentage.toFixed(2)}%</TableCell>
                                                                    </TableRow>
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </Box>
                                        </Collapse>
                                    </Paper>
                                ))
                            ) : (
                                <Typography variant="body1">Keine Verkaufsgebiets-Daten verfügbar.</Typography>
                            )}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default FilialcheckAnalyseManagementPage;
