import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme, Box, Typography } from '@mui/material';

const GroupedBarChart = ({ data }) => {
    const theme = useTheme();

    const yDataSeries = [
        {
            data: data.map(item => item.previousScore),
            name: 'Vorjahr',
            color: theme.palette.chart.pink, // Farbe für das Vorjahr
        },
        {
            data: data.map(item => item.currentScore),
            name: 'aktuelles Jahr',
            color: theme.palette.primary.main, // Farbe für das aktuelle Jahr
        }
    ];

    return (
        <Box>
            <BarChart
                xAxis={[{
                    scaleType: 'band',
                    data: data.map(item => item.x), // X-Achsen-Daten (z.B. 'JAN', 'FEB', etc.)
                    show: true,
                    tickSize: 0,
                }]}
                yAxis={[{
                    show: true,
                    label: null, // Entferne das y-Achsen-Label
                }]}
                series={yDataSeries}
                height={150}
                margin={{ top: 10, bottom: 20, left: 60, right: 10 }}
                borderRadius={1}
                isAnimationActive={false}
            />

            {/* Angepasste Legende unter dem Diagramm */}
            <Box display="flex" justifyContent="center" mt={2}>
                {yDataSeries.map(series => (
                    <Typography key={series.name} variant="body2" sx={{ marginX: 1, display: 'flex', alignItems: 'center' }}>
                        <span style={{
                            display: 'inline-block',
                            width: 12,
                            height: 12,
                            borderRadius: '50%',
                            backgroundColor: series.color,
                            marginRight: 4,
                        }} />
                        {series.name}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};

export default GroupedBarChart;
