// Uebersicht.jsx
import React, { useState, useEffect } from 'react';
import Layout from '../../../components/layout/Tagesreport/Tagesreport';
import {
  Container,
  Box,
  Typography,
  Stepper,
  Step,
  StepButton,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Paper,
  CardHeader,
  Grid,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import AddIcon from '@mui/icons-material/Add';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import axiosInstance from '../../../services/axiosInstance';

const ITEM_TYPE = 'TODO';

const ToDoCard = ({ toDo, index, sourceList, setSourceList, handleToggleToDo }) => {
  const [, ref] = useDrag({
    type: ITEM_TYPE,
    item: { toDo, index, sourceList, setSourceList },
  });

  return (
    <Card
      ref={ref}
      sx={{
        marginBottom: 2,
        boxShadow: 3, // Hinzufügen eines kleinen Schattens
        cursor: 'move', // Zeigt an, dass die Karte verschiebbar ist
      }}
    >
      <CardContent>
        <Typography variant="body1">
          {toDo.text} - <strong>{toDo.done ? 'Erledigt' : 'Offen'}</strong>
        </Typography>
        {toDo.assigned_by && (
          <Typography variant="caption" color="textSecondary">
            Zuletzt bearbeitet von: {toDo.assigned_by}
          </Typography>
        )}
      </CardContent>
      <CardActions>
        <Button onClick={() => handleToggleToDo(toDo, sourceList, setSourceList, index)}>
          {toDo.done ? 'Rückgängig' : 'Erledigen'}
        </Button>
      </CardActions>
    </Card>
  );
};

const ToDoList = ({ list, setList, otherList, setOtherList, handleToggleToDo, title }) => {
  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: (item) => handleDrop(item),
  });

  const handleDrop = async (item) => {
    const { toDo } = item;

    // Entferne das To-Do aus der Quellliste
    const updatedSourceList = item.sourceList.filter((_, idx) => idx !== item.index);
    item.setSourceList(updatedSourceList);

    // Aktualisiere das To-Do (z.B. assigned_to ändern)
    const updatedToDo = {
      ...toDo,
      assigned_to: toDo.assigned_to === 'customer' ? 'team' : 'customer',
    };

    try {
      // Sende Update an Backend
      await axiosInstance.put(`/communication/todos/${toDo.id}/`, updatedToDo);

      // Füge das To-Do zur Ziel-Liste hinzu
      setList((prevList) => [...prevList, updatedToDo]);
    } catch (error) {
      console.error('Fehler beim Verschieben des To-Dos:', error);
    }
  };

  return (
    <Box sx={{ flex: 1, minWidth: '300px' }}>
      <Typography variant="h6" gutterBottom>
        {title}
      </Typography>
      <Box
        ref={drop}
        sx={{
          padding: 2,
          border: '1px dashed #ccc',
          minHeight: '200px',
          borderRadius: 1,
        }}
      >
        {list.map((toDo, index) => (
          <ToDoCard
            key={toDo.id}
            toDo={toDo}
            index={index}
            sourceList={list}
            setSourceList={setList}
            handleToggleToDo={handleToggleToDo}
          />
        ))}
      </Box>
    </Box>
  );
};

const Uebersicht = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeStep, setActiveStep] = useState(0);

  // Anpassung der Schritte mit Label und Wert
  const steps = [
    { label: 'Anbindung', value: 'anbindung' },
    { label: 'Plausibilisierung', value: 'plausibilisierung' },
    { label: 'Go Live', value: 'go_live' },
    { label: 'Begleitung', value: 'begleitung' },
  ];

  const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const response = await axiosInstance.get('/auth/current_user/');
        if (response.data && response.data.username) {
          setCurrentUser(response.data.username);
        }
      } catch (error) {
        console.error('Fehler beim Laden des Benutzers:', error);
      }
    };

    fetchCurrentUser();
  }, []);

  const [toDosCustomer, setToDosCustomer] = useState([]);
  const [toDosUs, setToDosUs] = useState([]);
  const [newToDo, setNewToDo] = useState('');

  // Unternehmensinformationen
  const [companyInfo, setCompanyInfo] = useState({
    company_name: '',
    project_lead: '',
    email: '',
    phone_number: '',
    wawi: '',
    time_tracking: '',
    applicant_management: '',
    weighing: '',
    last_data_update: '',
    process_step: 'anbindung',
  });

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await axiosInstance.get('/communication/company-info/');
        if (response.data) {
          setCompanyInfo(response.data);
          // Prozessschritt entsprechend setzen
          const stepIndex = steps.findIndex(
            (step) => step.value === response.data.process_step
          );
          if (stepIndex !== -1) {
            setActiveStep(stepIndex);
          }
        } else {
          console.log('Keine Unternehmensinformationen gefunden.');
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log(
            'Unternehmen existiert nicht, bitte füllen Sie die Informationen aus und speichern Sie.'
          );
        } else {
          console.error('Fehler beim Laden der Unternehmensinformationen:', error);
        }
      }
    };

    fetchCompanyInfo();
  }, []);

  const saveCompanyInfo = async () => {
    try {
      if (companyInfo.id) {
        // Aktualisieren der bestehenden Unternehmensinformationen
        await axiosInstance.put(`/communication/company-info/`, companyInfo);
        console.log('Unternehmensinformationen erfolgreich aktualisiert.');
      } else {
        // Anlegen der neuen Unternehmensinformationen
        const response = await axiosInstance.post(
          '/communication/company-info/create/',
          companyInfo
        );
        setCompanyInfo(response.data); // Setzen Sie die erhaltene ID und andere Daten
        console.log('Unternehmensinformationen erfolgreich angelegt.');
      }
    } catch (error) {
      console.error('Fehler beim Speichern der Unternehmensinformationen:', error);
    }
  };

  const handleCompanyInfoChange = (field) => (event) => {
    setCompanyInfo((prevInfo) => ({
      ...prevInfo,
      [field]: event.target.value,
    }));
  };

  // To-Dos laden
  useEffect(() => {
    const fetchToDos = async () => {
      try {
        const response = await axiosInstance.get('/communication/todos/');
        const todos = response.data.results; // Zugriff auf das 'results'-Feld
        console.log('Geladene To-Dos:', todos);

        // Filtern nach assigned_to
        setToDosCustomer(todos.filter((todo) => todo.assigned_to === 'customer'));
        setToDosUs(todos.filter((todo) => todo.assigned_to === 'team'));
      } catch (error) {
        console.error('Fehler beim Laden der To-Dos:', error);
      }
    };

    fetchToDos();
  }, []);

  // Neues To-Do hinzufügen
  const handleAddToDo = async () => {
    if (newToDo.trim() === '') return;

    const newToDoItem = {
      text: newToDo,
      done: false,
      assigned_to: 'team', // Standardmäßig "team"
      assigned_by: currentUser,
    };

    try {
      const response = await axiosInstance.post('/communication/todos/', newToDoItem);
      setToDosUs([...toDosUs, response.data]);
      setNewToDo('');
    } catch (error) {
      console.error('Fehler beim Hinzufügen des To-Dos:', error);
    }
  };

  // To-Do-Status toggeln
  const handleToggleToDo = async (toDo, list, setList, index) => {
    const updatedToDo = {
      ...toDo,
      done: !toDo.done,
      assigned_by: currentUser,
    };

    try {
      await axiosInstance.put(`/communication/todos/${toDo.id}/`, updatedToDo);

      // Liste aktualisieren
      const updatedList = [...list];
      updatedList[index] = updatedToDo;
      setList(updatedList);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des To-Dos:', error);
    }
  };

  const handleStep = async (stepIndex) => {
    setActiveStep(stepIndex);

    // Prozessschritt aktualisieren
    const newProcessStep = steps[stepIndex].value;
    const updatedCompanyInfo = {
      ...companyInfo,
      process_step: newProcessStep,
    };
    setCompanyInfo(updatedCompanyInfo);

    try {
      await axiosInstance.put(`/communication/company-info/`, updatedCompanyInfo);
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Prozessschritts:', error);
    }
  };

  return (
    <Layout>
      <Container
        maxWidth={false}
        sx={{
          padding: '0 16px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          marginTop: isMobile ? theme.spacing(8) : 0,
        }}
      >
        <PageHeader
          title="Projektplanung Insights"
          subtitle="Von der Anbindung bis zum Go Live"
          Icon={WorkspacesOutlinedIcon}
        />

        {/* Card für Unternehmensinformationen */}
        <Paper sx={{ marginBottom: 4 }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Unternehmensinformationen
                </Typography>
              }
            />
            <CardContent>
              <Grid container spacing={2}>
                {/* Unternehmensname */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Unternehmen"
                    value={companyInfo.company_name}
                    onChange={handleCompanyInfoChange('company_name')}
                  />
                </Grid>
                {/* Projektverantwortlicher */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Projektverantwortlicher"
                    value={companyInfo.project_lead}
                    onChange={handleCompanyInfoChange('project_lead')}
                  />
                </Grid>
                {/* E-Mail */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Mail"
                    value={companyInfo.email}
                    onChange={handleCompanyInfoChange('email')}
                  />
                </Grid>
                {/* Telefonnummer */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Telefonnummer"
                    value={companyInfo.phone_number}
                    onChange={handleCompanyInfoChange('phone_number')}
                  />
                </Grid>
                {/* Schnittstellen */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Schnittstellen: WaWi"
                    value={companyInfo.wawi}
                    onChange={handleCompanyInfoChange('wawi')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Schnittstellen: Zeiterfassung"
                    value={companyInfo.time_tracking}
                    onChange={handleCompanyInfoChange('time_tracking')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Schnittstellen: Bewerbermanagement"
                    value={companyInfo.applicant_management}
                    onChange={handleCompanyInfoChange('applicant_management')}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Schnittstellen: Verwiegung"
                    value={companyInfo.weighing}
                    onChange={handleCompanyInfoChange('weighing')}
                  />
                </Grid>
                {/* Uhrzeit Datenaktualisierung */}
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Uhrzeit Datenaktualisierung"
                    value={companyInfo.last_data_update}
                    onChange={handleCompanyInfoChange('last_data_update')}
                  />
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button variant="contained" color="primary" onClick={saveCompanyInfo}>
                  Speichern
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Paper>

        {/* Neuer Card für den Prozess Stepper */}
        <Paper sx={{ marginBottom: 4 }}>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              padding: '16px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Projektfortschritt
                </Typography>
              }
            />
            <CardContent>
              {/* Prozess Stepper */}
              <Box sx={{ width: '100%' }}>
                <Stepper nonLinear activeStep={activeStep} alternativeLabel>
                  {steps.map((step, index) => (
                    <Step key={step.value}>
                      <StepButton onClick={() => handleStep(index)}>{step.label}</StepButton>
                    </Step>
                  ))}
                </Stepper>
              </Box>
            </CardContent>
          </Card>
        </Paper>

        {/* Card für den To-Do Bereich */}
        <Paper>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '16px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  To-Do Management
                </Typography>
              }
            />

            <CardContent>
              {/* Neues To-Do hinzufügen */}
              <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2 }}>
                <TextField
                  fullWidth
                  label="Neues To-Do"
                  value={newToDo}
                  onChange={(e) => setNewToDo(e.target.value)}
                  size="small"
                />
                <Button
                  onClick={handleAddToDo}
                  sx={{ marginLeft: 1 }}
                  startIcon={<AddIcon />}
                  variant="contained"
                  color="primary"
                >
                  Hinzufügen
                </Button>
              </Box>

              {/* To-Do Listen */}
              <DndProvider backend={HTML5Backend}>
                <Box sx={{ display: 'flex', gap: 4, marginTop: 4, flexWrap: 'wrap' }}>
                  {/* Kunden To-Do Liste */}
                  <ToDoList
                    list={toDosCustomer}
                    setList={setToDosCustomer}
                    otherList={toDosUs}
                    setOtherList={setToDosUs}
                    handleToggleToDo={handleToggleToDo}
                    title="Kunden To-Dos"
                  />

                  {/* Unsere To-Do Liste */}
                  <ToDoList
                    list={toDosUs}
                    setList={setToDosUs}
                    otherList={toDosCustomer}
                    setOtherList={setToDosCustomer}
                    handleToggleToDo={handleToggleToDo}
                    title="Unsere To-Dos"
                  />
                </Box>
              </DndProvider>
            </CardContent>
          </Card>
        </Paper>
      </Container>
    </Layout>
  );
};

export default Uebersicht;
