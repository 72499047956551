import React, { useState, useEffect } from 'react';
import {
    Container, Typography, Box, Button, TextField,
    MenuItem, Select, FormControl, InputLabel, Dialog, DialogActions,
    DialogContent, DialogTitle, Stepper, Step, StepLabel, Grid, Stack
} from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import Instance from '../../../services/axiosInstance';
import KategorieListeKomponente from '../../../components/formular/Filialcheck/KategorieListeKomponente';
import NeueKategorieForm from '../../../components/formular/Filialcheck/NeueKategorieForm';
import CheckpointListeKomponente from '../../../components/formular/Filialcheck/CheckpointListeKomponente';
import NeueCheckpointForm from '../../../components/formular/Filialcheck/NeueCheckpointForm';

const Filialcheck = () => {
    const [categories, setCategories] = useState([]);
    const [ratings, setRatings] = useState({});
    const [filialnummer, setFilialnummer] = useState('');
    const [filialnummern, setFilialnummern] = useState([]);
    const [user, setUser] = useState('');
    const [error, setError] = useState(null);
    const [highestCheckId, setHighestCheckId] = useState(null);
    const [newCheckId, setNewCheckId] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [activeStep, setActiveStep] = useState(0);

    const steps = ['Kategorien', 'Überprüfungspunkte', 'Dashboard'];

    useEffect(() => {
        Instance.get('/filialcheck/categories/')
            .then(response => {
                if (response.data && Array.isArray(response.data.results)) {
                    setCategories(response.data.results);
                } else {
                    throw new Error('Die API hat keine Array-Daten im "results"-Feld zurückgegeben.');
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden der Daten');
            });

        Instance.get('auth/current_user/')
            .then(response => {
                if (response.data && response.data.username) {
                    setUser(response.data.username);
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden des Benutzers');
            });

        Instance.get('/filialcheck/filialnummern/')
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    setFilialnummern(response.data);
                } else {
                    throw new Error('Die API hat keine Array-Daten zurückgegeben.');
                }
            })
            .catch(error => {
                setError(error.message || 'Fehler beim Laden der Filialnummern');
            });
    }, []);

    const getNextCheckId = async () => {
        try {
            const response = await Instance.get('/filialcheck/highest-checkid/');
            const highestCheckId = response.data.highest_checkId ?? 0;
            setHighestCheckId(highestCheckId);
            const nextCheckId = highestCheckId + 1;
            setNewCheckId(nextCheckId);
            return nextCheckId;
        } catch (error) {
            console.error("Error fetching highest checkId:", error);
            setHighestCheckId(0);
            setNewCheckId(1);
            return 1;
        }
    };

    useEffect(() => {
        if (activeStep === 2) {
            getNextCheckId();
        }
    }, [activeStep]);

    const handleRatingChange = (checkpointId, value) => {
        setRatings({
            ...ratings,
            [checkpointId]: value,
        });
    };

    const handleSubmit = async () => {
        const currentDate = new Date().toISOString().split('T')[0]; 
        const checkId = await getNextCheckId(); 

        const ratingPromises = Object.entries(ratings).map(([checkpointId, value]) => {
            return Instance.post('/filialcheck/ratings/', {
                checkpoint: checkpointId,
                value: value,
                user: user,
                filialnummer: filialnummer,
                datum: currentDate,
                checkId: checkId,
            });
        });

        try {
            await Promise.all(ratingPromises);
            console.log('Bewertungen erfolgreich gesendet');
        } catch (error) {
            setError(error.message || 'Fehler beim Senden der Bewertungen');
            console.error("Error details:", error);
        }
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setActiveStep(0); 
    };

    return (
        <Layout>
            <Container maxWidth="sm">
                <Typography variant="h3" component="div" gutterBottom>
                    Bewertungen
                </Typography>
                <TextField
                    label="User"
                    value={user}
                    onChange={(e) => setUser(e.target.value)}
                    fullWidth
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel>Filialnummer</InputLabel>
                    <Select
                        value={filialnummer}
                        onChange={(e) => setFilialnummer(e.target.value)}
                    >
                        {filialnummern.map((filiale, index) => (
                            <MenuItem key={index} value={filiale.filialnummer}>
                                {filiale.filialnummer}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <Box sx={{ mt: 2, mb: 2 }}>
                    <Typography variant="h6">Höchste Check-ID: {highestCheckId}</Typography>
                    <Typography variant="h6">Neue Check-ID: {newCheckId}</Typography>
                </Box>
                {categories.map(category => (
                    <Box
                        key={category.id}
                        sx={{
                            border: '1px solid',
                            borderColor: 'grey.400',
                            borderRadius: 2,
                            padding: 2,
                            marginBottom: 2,
                        }}
                    >
                        <Typography variant="h5" component="div" gutterBottom>
                            {category.name}
                        </Typography>
                        <CheckpointListeKomponente
                            checkpoints={category.checkpoints}
                            onRatingChange={handleRatingChange}
                            ratings={ratings}
                        />
                    </Box>
                ))}
                <Button onClick={handleSubmit} variant="contained" sx={{ mt: 2 }}>
                    Bewertungen absenden
                </Button>

                <Button onClick={handleOpenDialog} variant="outlined" sx={{ mt: 2 }}>
                    Weitere Aktionen
                </Button>

                <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth maxWidth="md">
                    <DialogTitle>Prozess</DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <Stepper activeStep={activeStep} orientation="vertical">
                                    {steps.map((label, index) => (
                                        <Step key={label}>
                                            <StepLabel>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Grid>
                            <Grid item xs={8}>
                                <Stack spacing={2}>
                                    <Box>
                                        {activeStep === 0 && (
                                            <>
                                                <Typography variant="h4">Kategorien</Typography>
                                                <KategorieListeKomponente
                                                    categories={categories}
                                                    onCategoryNameChange={(categoryId, newName) => { /* Funktion zum Ändern */ }}
                                                    onCategoryDelete={(categoryId) => { /* Funktion zum Löschen */ }}
                                                />
                                                <NeueKategorieForm onAddCategory={(name) => { /* Funktion zum Hinzufügen */ }} />
                                            </>
                                        )}
                                        {activeStep === 1 && (
                                            <>
                                                <Typography variant="h4">Überprüfungspunkte</Typography>
                                                <NeueCheckpointForm categories={categories} onAddCheckpoint={(name, category) => { /* Funktion zum Hinzufügen */ }} />
                                            </>
                                        )}
                                        {activeStep === 2 && (
                                            <>
                                                <Typography variant="h4">Dashboard</Typography>
                                                {/* Dashboard-Inhalte hier einfügen */}
                                            </>
                                        )}
                                    </Box>
                                </Stack>
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleBack} disabled={activeStep === 0}>
                            Zurück
                        </Button>
                        <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
                            Weiter
                        </Button>
                        <Button onClick={handleCloseDialog}>Schließen</Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </Layout>
    );
};

export default Filialcheck;
