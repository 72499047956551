export function toKebabCase(str) {
    if (!str || typeof str !== 'string') {
        return '';  // Return an empty string or handle it as needed
    }

    return str
        .replace(/([a-z])([A-Z])/g, '$1-$2')  // Add a dash between lowercase and uppercase letters
        .replace(/\s+/g, '-')                 // Replace spaces with dashes
        .toLowerCase();                       // Convert the whole string to lowercase
}
