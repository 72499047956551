import * as React from 'react';
import { BarChart } from '@mui/x-charts/BarChart';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';

const BarChartTA = ({ data, category, categories, flat = true }) => {
    const theme = useTheme();

    let xData, yDataSeries;

    if (categories) {
        // Multiple categories
        xData = data.map(item => format(new Date(item.x), 'MM.yyyy')); // Format x-axis values as MM.YYYY
        yDataSeries = categories.map(cat => ({
            data: data.map(item => item[cat]),
            name: cat,
        }));
    } else {
        // Single category
        xData = data.map(item => format(new Date(item.x), 'MM.yyyy')); // Format x-axis values as MM.YYYY
        yDataSeries = [{
            data: data.map(item => item[category]),
            name: category,
        }];
    }

    return (
        <BarChart
            xAxis={[{
                scaleType: 'band',
                data: xData,
                show: true, // Show the x-axis
                tickSize: 5, // Customize tick size if needed
                label: null, // Add label if needed
            }]}
            yAxis={[{
                show: true, // Show the y-axis
                label: null, // Remove the label for the y-axis
                colorMap: {
                    type: 'piecewise',
                    thresholds: [4, 7],
                    colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main],
                },
            }]}
            series={yDataSeries}
            height={flat ? 25 : 100}
            margin={{ top: 10, bottom: 30, left: 40, right: 10 }} // Adjust margins to accommodate axes labels
            borderRadius={1}
            isAnimationActive={false}
        />
    );
};

export default BarChartTA;
