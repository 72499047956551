import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import RechartsLineChart from "../../charts/LineChartVJ";

const BigChartCard = ({ data, title, infoContent, height, width }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Funktion zum Umschalten des Vollbildmodus
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  // Dynamische Höhe für verschiedene Bildschirmgrößen
  const chartHeight = height ? height - 100 : isSmallScreen ? 300 : 400;

  // Inhalt des Diagramms, um Duplizierung zu vermeiden
  const ChartContent = (
    <>
      {data && data.length > 0 ? (
        <RechartsLineChart data={data} height={chartHeight} />
      ) : (
        <Typography variant="body2" color="text.secondary">
          Keine Daten verfügbar
        </Typography>
      )}
    </>
  );

  return (
    <>
      <Paper>
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            height: height || "100%",
            width: width || "100%",
            padding: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(10px)",
          }}
        >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            }
            action={
              <>
                {infoContent && (
                  <Tooltip title={infoContent} arrow placement="top">
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton onClick={toggleFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </>
            }
          />
          <CardContent sx={{ flexGrow: 1, padding: isSmallScreen ? "8px" : "16px" }}>
            {ChartContent}
          </CardContent>
        </Card>
      </Paper>

      {/* Vollbild-Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth={false}
        fullScreen
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={toggleFullscreen}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{ChartContent}</DialogContent>
      </Dialog>
    </>
  );
};

export default BigChartCard;
