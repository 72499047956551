import * as React from 'react';
import {BarChart} from '@mui/x-charts/BarChart';
import {useTheme} from '@mui/material/styles';

const BasicBars = ({data, category, categories, flat = true}) => {
    const theme = useTheme();

    let xData, yDataSeries;

    if (categories) {
        // Multiple categories
        xData = data.map(item => item.x);
        yDataSeries = categories.map(cat => ({
            data: data.map(item => item[cat]),
            name: cat,
        }));
    } else {
        // Single category
        xData = data.map(item => item.x);
        yDataSeries = [{
            data: data.map(item => item[category]),
            name: category,
        }];
    }

    return (
        <BarChart
            xAxis={[{scaleType: 'band', data: xData, show: false}]}
            yAxis={[{
                show: false,
                colorMap: {
                    type: 'piecewise',
                    thresholds: [4, 7],
                    colors: [theme.palette.error.main, theme.palette.warning.main, theme.palette.success.main],
                },
            }]}
            series={yDataSeries}
            height={flat ? 25 : 100}
            margin={{top: 10, bottom: 0, left: 10, right: 75}}
            leftAxis={null}
            bottomAxis={null}
            borderRadius={1}
            isAnimationActive={false}
        />
    );
};

export default BasicBars;
