import React from 'react';
import { Grid, Box } from '@mui/material';
import OnboardingPlaeneListe from '../../../components/onboarding/OnboardingPlaeneListe'; 
import Layout from '../../../components/layout/Onboarding/Onboarding';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';


const OnboardingKofigurationPage = () => {
    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Onboarding Konfiguration" 
                    subtitle="Verwalten deine Filialchecks und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                    Icon={BuildOutlinedIcon}  // Übergabe des Icons
                />
                
                <Grid container spacing={4} justifyContent="flex-start">
                    <Grid item xs={12} md={8}>
                        <OnboardingPlaeneListe />
                    </Grid>
                </Grid>
            </Box>
        </Layout>
    );
};

export default OnboardingKofigurationPage;
