// KombinierteAnalyseTable.jsx
import React, { useEffect, useState, useMemo } from 'react';
import axios from '../../../services/axiosInstance';
import {
  Box,
  Typography,
  Button,
  ButtonGroup,
  FormControlLabel,
  Checkbox,
  Collapse,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  Card,
  CardHeader,
  CardContent,
  IconButton,
  Tooltip,
  Stack, // Import der Stack-Komponente
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import BarChartIcon from '@mui/icons-material/BarChart';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import GridOnIcon from '@mui/icons-material/GridOn';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/de';
import qs from 'qs';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

import PageHeader from '../../../components/layout/Title/TitelSmall';
import Layout from '../../../components/layout/Warensteuerung/warensteuerung';
import DataTable from '../../../components/table/DataTable';
import DetailDialog from '../../../components/popup/warensteuerung/DetailDialog';
import FilialeWarengruppeDialog from '../../../components/popup/warensteuerung/FilialeWarengruppeDialog';
import FilialeArtikelDialog from '../../../components/popup/warensteuerung/FilialeArtikelDialog';
import WarengruppeArtikelDialog from '../../../components/popup/warensteuerung/WarengruppeArtikelDialog';
import MultiSelectDropdownFilter from '../../../components/filter/MultiSelectDropdownFilter'; // Import der Komponente

// Lizenzinfo setzen, falls erforderlich
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

// Import der Date/Time-Picker-Komponenten
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

dayjs.extend(isoWeek);
dayjs.locale('de');

const KombinierteAnalyseTable = () => {
  // States für Daten und Filter
  const [groupedData, setGroupedData] = useState({});
  const [months, setMonths] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [days, setDays] = useState([]);
  const [viewMode, setViewMode] = useState('warengruppe'); // 'warengruppe' oder 'artikel'

  const [selectedFilialen, setSelectedFilialen] = useState([]);
  const [allFilialen, setAllFilialen] = useState([]);

  const [selectedVerkaufsgebiete, setSelectedVerkaufsgebiete] = useState([]);
  const [allVerkaufsgebiete, setAllVerkaufsgebiete] = useState([]);

  const [selectedWarengruppen, setSelectedWarengruppen] = useState([]);
  const [allWarengruppe, setAllWarengruppe] = useState([]);

  // Entfernen des Artikel-Filters
  // const [selectedArticles, setSelectedArticles] = useState([]);
  // const [allArticles, setAllArticles] = useState([]);

  const [dateRange, setDateRange] = useState([dayjs().startOf('month'), dayjs().endOf('month')]);
  const [timeFilter, setTimeFilter] = useState('Monat'); // "Monat", "Woche", "Tag"

  const [selectedDataKeys, setSelectedDataKeys] = useState([]);
  const [availableDataKeys, setAvailableDataKeys] = useState([]);

  // States für Dialoge
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [detailData, setDetailData] = useState(null);

  const [filialeWarengruppeOpen, setFilialeWarengruppeOpen] = useState(false);
  const [selectedWarengruppe, setSelectedWarengruppe] = useState([]); // Initialisierung als leeres Array

  const [filialeArtikelOpen, setFilialeArtikelOpen] = useState(false);
  const [selectedArtikel, setSelectedArtikel] = useState(null);

  const [warengruppeArtikelOpen, setWarengruppeArtikelOpen] = useState(false);
  const [selectedWarengruppeForArtikel, setSelectedWarengruppeForArtikel] = useState([]); // Initialisierung als leeres Array

  // States für den aufklappbaren Filterbereich
  const [filtersExpanded, setFiltersExpanded] = useState(false); // State für das Ein- und Ausklappen der Filter

  // Funktion zum Überprüfen, ob "Alle" ausgewählt ist
  const isAllSelected = (selected, allOptions) =>
    Array.isArray(selected) && Array.isArray(allOptions) && selected.length === allOptions.length;

  // Funktion zur Handhabung der Auswahl
  const handleSelectAll = (event, allOptions, selected, setSelected) => {
    if (event.target.checked) {
      setSelected(allOptions); // Wenn "Alle" ausgewählt wird, wähle alles aus
    } else {
      setSelected([]); // Wenn "Alle" abgewählt wird, leere die Auswahl
    }
  };

  // Filialen abrufen bei Komponenten-Mount
  useEffect(() => {
    axios
      .get('/stammdaten/unique-filiale/')
      .then((response) => {
        const uniqueFilialen = response.data.map((filiale) => filiale.filiale);
        setAllFilialen(uniqueFilialen);
        setSelectedFilialen([]); // Standardmäßig nichts ausgewählt
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Filialen:', error);
      });
  }, []);

  // Verkaufsgebiete abrufen bei Komponenten-Mount
  useEffect(() => {
    axios
      .get('/stammdaten/unique-verkaufsgebiet/')
      .then((response) => {
        const uniqueVerkaufsgebiete = response.data;
        setAllVerkaufsgebiete(uniqueVerkaufsgebiete);
        setSelectedVerkaufsgebiete([]); // Standardmäßig nichts ausgewählt
      })
      .catch((error) => {
        console.error('Fehler beim Abrufen der Verkaufsgebiete:', error);
      });
  }, []);

  // Warengruppen abrufen, wenn viewMode 'warengruppe' ist
  useEffect(() => {
    if (viewMode === 'warengruppe') {
      axios
        .get('/stammdaten/unique-warengruppe/')
        .then((response) => {
          const uniqueWarengruppe = response.data.map((wg) => wg.warengruppe);
          console.log('Warengruppen:', uniqueWarengruppe); // Debugging-Log
          setAllWarengruppe(uniqueWarengruppe);
          setSelectedWarengruppen([]); // Standardmäßig nichts ausgewählt
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Warengruppen:', error);
        });
    }
  }, [viewMode]);

  // Daten basierend auf viewMode, dateRange, timeFilter und ausgewählten Filialen/Verkaufsgebiete/Warengruppen laden
  useEffect(() => {
    if (dateRange[0] && dateRange[1]) {
      const startDate = dateRange[0].format('YYYY-MM-DD');
      const endDate = dateRange[1].format('YYYY-MM-DD');
      let endpoint;

      if (viewMode === 'warengruppe') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-warengruppe/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-warengruppe/';
        } else {
          endpoint = '/fact/aggregated/by-datum-warengruppe/';
        }
      } else if (viewMode === 'artikel') {
        if (timeFilter === 'Monat') {
          endpoint = '/fact/aggregated/by-month-warengruppe-artikel/';
        } else if (timeFilter === 'Woche') {
          endpoint = '/fact/aggregated/by-week-warengruppe-artikel/';
        } else {
          endpoint = '/fact/aggregated/by-day-warengruppe-artikel/';
        }
      }

      axios
        .get(endpoint, {
          params: {
            start_date: startDate,
            end_date: endDate,
            filiale: selectedFilialen.length ? selectedFilialen : undefined, // undefined entfernt den Parameter
            verkaufsgebiet: selectedVerkaufsgebiete.length ? selectedVerkaufsgebiete : undefined,
            warengruppe: selectedWarengruppen.length ? selectedWarengruppen : undefined,
            // artikelnummer__artikelbezeichnung: viewMode === 'artikel' && selectedArticles.length ? selectedArticles : undefined, // Entfernt Artikel Filter
          },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) => {
          const filteredData = response.data;
          console.log('Gefilterte Daten:', filteredData); // Debugging-Log

          if (filteredData.length === 0) {
            setAvailableDataKeys([]);
            setSelectedDataKeys([]);
            setGroupedData({});
            setMonths([]);
            setWeeks([]);
            setDays([]);
            return;
          }

          const dataKeys = Object.keys(filteredData[0]).filter(
            (key) => key.startsWith('total_') && key !== 'total_faelle'
          );

          setAvailableDataKeys(dataKeys);

          const grouped = filteredData.reduce((acc, row) => {
            // **Korrekte Feldnamen verwenden**
            const key = viewMode === 'warengruppe' ? row.warengruppennummer__warengruppe : row.artikelnummer__artikelbezeichnung;
            const period =
              timeFilter === 'Monat'
                ? dayjs(row.month).format('MMMM YYYY')
                : timeFilter === 'Woche'
                ? `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`
                : dayjs(row.day).format('DD.MM.YYYY');

            if (!acc[key]) {
              acc[key] = {};
            }

            dataKeys.forEach((dataKey) => {
              if (!acc[key][dataKey]) {
                acc[key][dataKey] = {};
              }
              acc[key][dataKey][period] = row[dataKey];
            });

            return acc;
          }, {});

          setGroupedData(grouped);

          if (timeFilter === 'Monat') {
            const uniqueMonths = Array.from(
              new Set(filteredData.map((row) => dayjs(row.month).format('MMMM YYYY')))
            ).sort();
            setMonths(uniqueMonths);
          } else if (timeFilter === 'Woche') {
            const uniqueWeeks = Array.from(
              new Set(filteredData.map((row) => `KW ${dayjs(row.week, 'YYYY-MM-DD').isoWeek()}`))
            ).sort();
            setWeeks(uniqueWeeks);
          } else {
            const dayArray = [];
            let currentDate = dayjs(dateRange[0]);
            const endDateObj = dayjs(dateRange[1]);
            while (currentDate.isBefore(endDateObj) || currentDate.isSame(endDateObj, 'day')) {
              dayArray.push(currentDate.format('DD.MM.YYYY'));
              currentDate = currentDate.add(1, 'day');
            }
            setDays(dayArray);
          }

          // Setze nur ausgewählte Kennzahlen als Standardauswahl, falls noch keine ausgewählt wurden
          if (dataKeys.length > 0 && selectedDataKeys.length === 0) {
            const defaultKey = dataKeys.find((key) => key.includes('verkaufsmenge'));
            if (defaultKey) {
              setSelectedDataKeys([defaultKey]);
            } else {
              setSelectedDataKeys([dataKeys[0]]);
            }
          }
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Daten:', error);
        });
    }
  }, [dateRange, timeFilter, viewMode, selectedFilialen, selectedVerkaufsgebiete, selectedWarengruppen]);

  // Formatierung der Typ-Namen mit nur dem ersten Buchstaben groß
  const formatTypName = (dataKey) => {
    if (!dataKey) {
      console.warn('Undefined dataKey in formatTypName:', dataKey);
      return 'UNDEFINED';
    }
    const formatted = dataKey
      .replace(/^total_/, '') // Entferne den 'total_' Präfix
      .replace(/_/g, ' ')
      .toLowerCase()
      .replace(/^./, (str) => str.toUpperCase()); // Nur den ersten Buchstaben groß
    console.log('Formatted Typ Name:', formatted);
    return formatted;
  };

  // Berechnung der speziellen Kennzahlen in der Summenzeile
  const calculateSpecialTotals = () => {
    const specialTotals = {};

    // UmsatzVJ%
    if (
      typeof groupedData['umsatz'] === 'object' &&
      typeof groupedData['umsatzvj'] === 'object' &&
      Object.keys(groupedData['umsatzvj']).length > 0
    ) {
      const totalUmsatz = Object.values(groupedData['umsatz']).reduce((sum, val) => sum + (val || 0), 0);
      const totalUmsatzVJ = Object.values(groupedData['umsatzvj']).reduce((sum, val) => sum + (val || 0), 0);
      if (totalUmsatzVJ !== 0) {
        specialTotals['umsatzvj%'] = ((totalUmsatz / totalUmsatzVJ) - 1) * 100;
      } else {
        specialTotals['umsatzvj%'] = 0;
        console.warn('Berechnung von UmsatzVJ% fehlgeschlagen. GesamtumsatzVJ ist 0.');
      }
    } else {
      specialTotals['umsatzvj%'] = 0;
      console.warn('Berechnung von UmsatzVJ% fehlgeschlagen. Umsatz oder UmsatzVJ fehlen.');
    }

    // KundenanzahlVJ%
    if (
      typeof groupedData['kundenanzahl'] === 'object' &&
      typeof groupedData['kundenanzahlvj'] === 'object' &&
      Object.keys(groupedData['kundenanzahlvj']).length > 0
    ) {
      const totalKundenanzahl = Object.values(groupedData['kundenanzahl']).reduce((sum, val) => sum + (val || 0), 0);
      const totalKundenanzahlVJ = Object.values(groupedData['kundenanzahlvj']).reduce((sum, val) => sum + (val || 0), 0);
      if (totalKundenanzahlVJ !== 0) {
        specialTotals['kundenanzahlvj%'] = ((totalKundenanzahl / totalKundenanzahlVJ) - 1) * 100;
      } else {
        specialTotals['kundenanzahlvj%'] = 0;
        console.warn('Berechnung von KundenanzahlVJ% fehlgeschlagen. GesamtKundenanzahlVJ ist 0.');
      }
    } else {
      specialTotals['kundenanzahlvj%'] = 0;
      console.warn('Berechnung von KundenanzahlVJ% fehlgeschlagen. Kundenanzahl oder KundenanzahlVJ fehlen.');
    }

    // Stundenleistung
    if (
      typeof groupedData['umsatz'] === 'object' &&
      typeof groupedData['produktivstunden'] === 'object' &&
      Object.keys(groupedData['produktivstunden']).length > 0
    ) {
      const totalUmsatz = Object.values(groupedData['umsatz']).reduce((sum, val) => sum + (val || 0), 0);
      const totalProduktivstunden = Object.values(groupedData['produktivstunden']).reduce((sum, val) => sum + (val || 0), 0);
      if (totalProduktivstunden !== 0) {
        specialTotals['stundenleistung'] = totalUmsatz / totalProduktivstunden;
      } else {
        specialTotals['stundenleistung'] = 0;
        console.warn('Berechnung von Stundenleistung fehlgeschlagen. GesamtProduktivstunden ist 0.');
      }
    } else {
      specialTotals['stundenleistung'] = 0;
      console.warn('Berechnung von Stundenleistung fehlgeschlagen. Umsatz oder Produktivstunden fehlen.');
    }

    // Kundenbon
    if (
      typeof groupedData['umsatz'] === 'object' &&
      typeof groupedData['kundenanzahl'] === 'object' &&
      Object.keys(groupedData['kundenanzahl']).length > 0
    ) {
      const totalUmsatz = Object.values(groupedData['umsatz']).reduce((sum, val) => sum + (val || 0), 0);
      const totalKundenanzahl = Object.values(groupedData['kundenanzahl']).reduce((sum, val) => sum + (val || 0), 0);
      if (totalKundenanzahl !== 0) {
        specialTotals['kundenbon'] = totalUmsatz / totalKundenanzahl;
      } else {
        specialTotals['kundenbon'] = 0;
        console.warn('Berechnung von Kundenbon fehlgeschlagen. GesamtKundenanzahl ist 0.');
      }
    } else {
      specialTotals['kundenbon'] = 0;
      console.warn('Berechnung von Kundenbon fehlgeschlagen. Umsatz oder Kundenanzahl fehlen.');
    }

    // Retourquote
    if (
      typeof groupedData['retourwert'] === 'object' &&
      (typeof groupedData['lieferwert'] === 'object' || typeof groupedData['korrekturwert'] === 'object')
    ) {
      const totalRetourwert = Object.values(groupedData['retourwert']).reduce((sum, val) => sum + (val || 0), 0);
      const totalLieferwert = typeof groupedData['lieferwert'] === 'object'
        ? Object.values(groupedData['lieferwert']).reduce((sum, val) => sum + (val || 0), 0)
        : 0;
      const totalKorrekturwert = typeof groupedData['korrekturwert'] === 'object'
        ? Object.values(groupedData['korrekturwert']).reduce((sum, val) => sum + (val || 0), 0)
        : 0;
      const gesamtLieferKorrekturwert = totalLieferwert + totalKorrekturwert;

      if (gesamtLieferKorrekturwert !== 0) {
        specialTotals['retourquote'] = (totalRetourwert / gesamtLieferKorrekturwert) * 100;
      } else {
        specialTotals['retourquote'] = 0;
        console.warn('Berechnung von Retourquote fehlgeschlagen. Gesamtliefer- und Korrekturwert ist 0.');
      }
    } else {
      specialTotals['retourquote'] = 0;
      console.warn('Berechnung von Retourquote fehlgeschlagen. Retourwert, Lieferwert oder Korrekturwert fehlen.');
    }

    return specialTotals;
  };

  const specialTotals = useMemo(() => calculateSpecialTotals(), [groupedData]);

  // Funktion zum Exportieren nach Excel
  const exportToExcel = () => {
    try {
      // Erstellen eines neuen Arbeitsblatts
      const worksheetData = [];

      // Tabellenkopf hinzufügen
      const header = [
        viewMode === 'warengruppe' ? 'Warengruppe' : 'Artikel',
        'Typ',
        ...((timeFilter === 'Monat' && months.length) ||
        (timeFilter === 'Woche' && weeks.length) ||
        (timeFilter === 'Tag' && days.length)
          ? timeFilter === 'Monat'
            ? months
            : timeFilter === 'Woche'
            ? weeks
            : days
          : []),
        'Summe',
      ];
      worksheetData.push(header);

      // Tabelleninhalt hinzufügen
      Object.entries(groupedData).forEach(([groupKey, values]) => {
        selectedDataKeys.forEach((dataKey) => {
          const row = [
            groupKey,
            formatTypName(dataKey),
            ...(
              timeFilter === 'Monat'
                ? months.map((period) => values[dataKey]?.[period] || 0)
                : timeFilter === 'Woche'
                ? weeks.map((period) => values[dataKey]?.[period] || 0)
                : days.map((period) => values[dataKey]?.[period] || 0)
            ),
            dataKey.toLowerCase().endsWith('%')
              ? (specialTotals[dataKey.toLowerCase()] ?? 'N/A')
              : Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0),
          ];
          worksheetData.push(row);
        });
      });

      // Summenzeilen hinzufügen
      selectedDataKeys.forEach((dataKey) => {
        const row = [
          'Gesamtsumme',
          formatTypName(dataKey),
          ...(
            timeFilter === 'Monat'
              ? months.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
              : timeFilter === 'Woche'
              ? weeks.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
              : days.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
          ),
          dataKey.toLowerCase().endsWith('%')
            ? (specialTotals[dataKey.toLowerCase()] ?? 'N/A')
            : Object.values(groupedData).reduce((sum, group) => sum + (group[dataKey]?.[timeFilter === 'Monat' ? months : timeFilter === 'Woche' ? weeks : days] || 0), 0),
        ];
        worksheetData.push(row);
      });

      const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'KombinierteAnalyse');
      XLSX.writeFile(workbook, 'KombinierteAnalyse.xlsx');

      console.log('Export nach Excel erfolgreich!');
    } catch (error) {
      console.error('Export nach Excel fehlgeschlagen:', error);
      alert('Export nach Excel fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  // Funktion zum Exportieren nach PDF
  const exportToPDF = () => {
    try {
      const doc = new jsPDF();

      // Tabelleninhalt vorbereiten
      const tableColumn = [
        viewMode === 'warengruppe' ? 'Warengruppe' : 'Artikel',
        'Typ',
        ...((timeFilter === 'Monat' && months.length) ||
        (timeFilter === 'Woche' && weeks.length) ||
        (timeFilter === 'Tag' && days.length)
          ? timeFilter === 'Monat'
            ? months
            : timeFilter === 'Woche'
            ? weeks
            : days
          : []),
        'Summe',
      ];
      const tableRows = [];

      Object.entries(groupedData).forEach(([groupKey, values]) => {
        selectedDataKeys.forEach((dataKey) => {
          const row = [
            groupKey,
            formatTypName(dataKey),
            ...(
              timeFilter === 'Monat'
                ? months.map((period) => values[dataKey]?.[period] || 0)
                : timeFilter === 'Woche'
                ? weeks.map((period) => values[dataKey]?.[period] || 0)
                : days.map((period) => values[dataKey]?.[period] || 0)
            ),
            dataKey.toLowerCase().endsWith('%')
              ? (specialTotals[dataKey.toLowerCase()] ?? 'N/A')
              : Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0),
          ];
          tableRows.push(row);
        });
      });

      // Summenzeilen hinzufügen
      selectedDataKeys.forEach((dataKey) => {
        const row = [
          'Gesamtsumme',
          formatTypName(dataKey),
          ...(
            timeFilter === 'Monat'
              ? months.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
              : timeFilter === 'Woche'
              ? weeks.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
              : days.map((period) => (groupedData['umsatz']?.[period] || 0)) // Beispiel: Anpassen nach Bedarf
          ),
          dataKey.toLowerCase().endsWith('%')
            ? (specialTotals[dataKey.toLowerCase()] ?? 'N/A')
            : Object.values(groupedData).reduce((sum, group) => sum + (group[dataKey]?.[timeFilter === 'Monat' ? months : timeFilter === 'Woche' ? weeks : days] || 0), 0),
        ];
        tableRows.push(row);
      });

      // AutoTable hinzufügen
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 20,
        styles: { fontSize: 8 },
        headStyles: { fillColor: [22, 160, 133] },
      });

      doc.text('Kombinierte Analyse', 14, 15);
      doc.save('KombinierteAnalyse.pdf');

      console.log('Export nach PDF erfolgreich!');
    } catch (error) {
      console.error('Export nach PDF fehlgeschlagen:', error);
      alert('Export nach PDF fehlgeschlagen. Bitte versuchen Sie es erneut.');
    }
  };

  // Daten für Diagramm vorbereiten
  const prepareChartData = () => {
    if (!detailData) return [];

    const periods = timeFilter === 'Monat' ? months : timeFilter === 'Woche' ? weeks : days;

    return periods.map((period) => {
      const dataPoint = { period };

      selectedDataKeys.forEach((key) => {
        dataPoint[key] = detailData.data[key][period] || 0;
      });

      return dataPoint;
    });
  };

  const chartData = prepareChartData();

  // Kennzahlen umschalten
  const handleDataKeyToggle = (key) => {
    setSelectedDataKeys((prev) => {
      if (prev.includes(key)) {
        if (prev.length === 1) {
          // Wenn die letzte Kennzahl abgewählt wird
          if (key !== 'verkaufsmenge' && availableDataKeys.includes('verkaufsmenge')) {
            // Setze 'verkaufsmenge' als Standardauswahl
            return ['verkaufsmenge'];
          } else if (key === 'verkaufsmenge') {
            // Wenn 'verkaufsmenge' die letzte Kennzahl ist, verhindern Sie das Abwählen
            return prev;
          } else {
            // Falls 'verkaufsmenge' nicht verfügbar ist, wählen Sie die erste verfügbare Kennzahl
            return [availableDataKeys[0]];
          }
        }
        // Entfernen Sie die Kennzahl aus der Auswahl
        return prev.filter((k) => k !== key);
      } else {
        // Fügen Sie die Kennzahl zur Auswahl hinzu
        return [...prev, key];
      }
    });
  };

  // Dialog öffnen/schließen
  const handleOpenDetailDialog = (type, name, data) => {
    if (viewMode === 'warengruppe' && type === 'warengruppe') {
      handleOpenFilialeWarengruppeDialog(name);
    } else if (viewMode === 'artikel' && type === 'artikel') {
      handleOpenFilialeArtikelDialog(name);
    } else if (viewMode === 'warengruppe' && type === 'warengruppe_artikel') {
      handleOpenWarengruppeArtikelDialog(name);
    }
  };

  // Detail-Dialog schließen
  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
    setDetailData(null);
  };

  // Neue Funktionen für FilialeWarengruppeDialog
  const handleOpenFilialeWarengruppeDialog = (warengruppe) => {
    const warengruppeArray = Array.isArray(warengruppe) ? warengruppe : [warengruppe];
    console.log('Öffne FilialeWarengruppeDialog mit Warengruppe:', warengruppeArray);
    setSelectedWarengruppe(warengruppeArray);
    setFilialeWarengruppeOpen(true);
  };

  const handleCloseFilialeWarengruppeDialog = () => {
    setFilialeWarengruppeOpen(false);
    setSelectedWarengruppe([]);
  };

  // Neue Funktionen für FilialeArtikelDialog
  const handleOpenFilialeArtikelDialog = (artikel) => {
    setSelectedArtikel(artikel);
    setFilialeArtikelOpen(true);
  };

  const handleCloseFilialeArtikelDialog = () => {
    setFilialeArtikelOpen(false);
    setSelectedArtikel(null);
  };

  // Neue Funktionen für WarengruppeArtikelDialog
  const handleOpenWarengruppeArtikelDialog = (warengruppe) => {
    const warengruppeArray = Array.isArray(warengruppe) ? warengruppe : [warengruppe];
    console.log('Öffne WarengruppeArtikelDialog mit Warengruppe:', warengruppeArray);
    setSelectedWarengruppeForArtikel(warengruppeArray);
    setWarengruppeArtikelOpen(true);
  };

  const handleCloseWarengruppeArtikelDialog = () => {
    setWarengruppeArtikelOpen(false);
    setSelectedWarengruppeForArtikel([]);
  };

  return (
    <Layout>
      <Box>
        <PageHeader
          title={viewMode === 'warengruppe' ? "Warengruppenanalyse" : "Artikelanalyse"}
          subtitle="Datenwürfel zum Thema Warensteuerung."
          Icon={BarChartIcon}
        />
      </Box>

      {/* Aufklappbarer Filterbereich */}
      <Box sx={{ mt: 2 }}>
        <Button
          variant="contained"
          onClick={() => setFiltersExpanded(!filtersExpanded)}
          startIcon={filtersExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          {filtersExpanded ? 'Filter schließen' : 'Filter öffnen'}
        </Button>
        <Collapse in={filtersExpanded}>
          {/* Geänderte Komponente: Box statt Card */}
          <Box
            sx={{
              mt: 2,
              p: 2,
              border: '1px solid #ccc', // Rahmenfarbe anpassen nach Bedarf
              borderRadius: '4px', // Optional: Abgerundete Ecken
              backgroundColor: 'transparent', // Sicherstellen, dass kein Hintergrund vorhanden ist
            }}
          >
            <Grid container spacing={2}>
              {/* Perspektive auswählen */}
              <Grid item xs={12} md={2}>
                <Typography variant="h6" gutterBottom>
                  Perspektive
                </Typography>
                {/* Ersetzen von ButtonGroup durch Stack für vertikale Anordnung */}
                <Stack direction="column" spacing={1} fullWidth>
                  <Button
                    variant="outlined"
                    onClick={() => setViewMode('warengruppe')}
                    disabled={viewMode === 'warengruppe'}
                    fullWidth
                  >
                    Warengruppen
                  </Button>
                  <Button
                    variant="outlined"
                    onClick={() => setViewMode('artikel')}
                    disabled={viewMode === 'artikel'}
                    fullWidth
                  >
                    Artikel
                  </Button>
                </Stack>
              </Grid>

              {/* Zeitraum auswählen */}
              <Grid item xs={12} md={2}>
                <Typography variant="h6" gutterBottom>
                  Zeitraum
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
                  <DateRangePicker
                    value={dateRange}
                    onChange={(newValue) => setDateRange(newValue)}
                    slots={{ field: SingleInputDateRangeField }}
                    calendars={1}
                    slotProps={{
                      shortcuts: {
                        items: [
                          { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                          { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                          { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                          { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                          { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                          { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                          { label: 'Zurücksetzen', getValue: () => [null, null] },
                        ],
                      },
                    }}
                    sx={{
                      width: '100%',
                      '& .MuiInputBase-root': {
                        height: '56px', // Gleiche Höhe wie die Select-Komponente
                        fontSize: '0.875rem',
                      },
                      '& .MuiSvgIcon-root': {
                        fontSize: '1.2rem',
                      },
                    }}
                  />
                </LocalizationProvider>
                <FormControl fullWidth sx={{ mt: 2 }}>
                  <InputLabel>Zeitraum auswählen</InputLabel>
                  <Select
                    value={timeFilter}
                    onChange={(event) => setTimeFilter(event.target.value)}
                    input={<OutlinedInput label="Zeitraum auswählen" />}
                  >
                    <MenuItem value="Monat">Monat</MenuItem>
                    <MenuItem value="Woche">Woche</MenuItem>
                    <MenuItem value="Tag">Tag</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Filialen auswählen */}
              <Grid item xs={12} md={2}>
                <Typography variant="h6" gutterBottom>
                  Filialen
                </Typography>
                <MultiSelectDropdownFilter
                  label="Filialen auswählen"
                  options={allFilialen}
                  value={selectedFilialen}
                  onChange={(newValue) => setSelectedFilialen(newValue)}
                />
                {/* Verkaufsgebiet Filter hinzufügen */}
                <Box sx={{ mt: 2 }}>
                  <Typography variant="subtitle1" gutterBottom>
                    Verkaufsgebiet
                  </Typography>
                  <MultiSelectDropdownFilter
                    label="Verkaufsgebiete auswählen"
                    options={allVerkaufsgebiete}
                    value={selectedVerkaufsgebiete}
                    onChange={(newValue) => setSelectedVerkaufsgebiete(newValue)}
                  />
                </Box>
              </Grid>

              {/* Warengruppen auswählen */}
              <Grid item xs={12} md={2}>
                <Typography variant="h6" gutterBottom>
                  Warengruppen
                </Typography>
                <MultiSelectDropdownFilter
                  label="Warengruppen auswählen"
                  options={allWarengruppe}
                  value={selectedWarengruppen}
                  onChange={(newValue) => setSelectedWarengruppen(newValue)}
                />
              </Grid>

              {/* Kennzahlauswahl */}
              <Grid item xs={12} md={4}>
                <Typography variant="h6" gutterBottom>
                  Kennzahlauswahl
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    gap: 1,
                  }}
                >
                  {availableDataKeys.map((key) => (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          checked={selectedDataKeys.includes(key)}
                          onChange={() => handleDataKeyToggle(key)}
                        />
                      }
                      label={key
                        .replace('total_', '')
                        .replace('_menge', '')
                        .replace('_wert', '')
                        .replace('_retourquote', '')
                        .toLowerCase()
                        .replace(/^\w/, (c) => c.toUpperCase())}
                      sx={{
                        flex: '1 1 45%', // Flexibler Basiswert für Anpassung
                        maxWidth: '45%',
                      }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Collapse>
      </Box>

      {/* Daten Tabelle */}
      <Grid item xs={12} mt={2}>
        <Card>
          <CardContent>
            <DataTable
              viewMode={viewMode}
              timeFilter={timeFilter}
              months={months}
              weeks={weeks}
              days={days}
              groupedData={groupedData}
              handleOpenDetailDialog={handleOpenDetailDialog}
              selectedDataKeys={selectedDataKeys}
              availableDataKeys={availableDataKeys}
            />
          </CardContent>
        </Card>
      </Grid>

      {/* Dialoge */}
      <FilialeWarengruppeDialog
        open={filialeWarengruppeOpen}
        onClose={handleCloseFilialeWarengruppeDialog}
        timeFilter={timeFilter}
        selectedFiliale={selectedFilialen}
        selectedWarengruppe={selectedWarengruppe}
        selectedVerkaufsgebiete={selectedVerkaufsgebiete}
        selectedDataKeys={selectedDataKeys}
        dateRange={dateRange}
        groupedData={groupedData} // Falls benötigt
      />

      <FilialeArtikelDialog
        open={filialeArtikelOpen}
        onClose={handleCloseFilialeArtikelDialog}
        timeFilter={timeFilter}
        selectedFiliale={selectedFilialen}
        selectedVerkaufsgebiete={selectedVerkaufsgebiete}
        selectedArtikel={selectedArtikel}
        selectedDataKeys={selectedDataKeys}
        dateRange={dateRange}
        groupedData={groupedData} // Falls benötigt
      />

      <WarengruppeArtikelDialog
        open={warengruppeArtikelOpen}
        onClose={handleCloseWarengruppeArtikelDialog}
        timeFilter={timeFilter}
        selectedFiliale={selectedFilialen}
        selectedVerkaufsgebiete={selectedVerkaufsgebiete}
        selectedWarengruppe={selectedWarengruppeForArtikel}
        selectedDataKeys={selectedDataKeys}
        dateRange={dateRange}
        groupedData={groupedData} // Falls benötigt
      />

      <DetailDialog
        open={detailDialogOpen}
        onClose={handleCloseDetailDialog}
        detailData={detailData}
        chartData={chartData}
        selectedDataKeys={selectedDataKeys}
        timeFilter={timeFilter}
        months={months}
        weeks={weeks}
        days={days}
      />
    </Layout>
  );
};

// Aktualisierte handleDataKeyToggle Funktion
const handleDataKeyToggle = (key) => {
  setSelectedDataKeys((prev) => {
    if (prev.includes(key)) {
      if (prev.length === 1) {
        // Wenn die letzte Kennzahl abgewählt wird
        if (key !== 'verkaufsmenge' && availableDataKeys.includes('verkaufsmenge')) {
          // Setze 'verkaufsmenge' als Standardauswahl
          return ['verkaufsmenge'];
        } else if (key === 'verkaufsmenge') {
          // Wenn 'verkaufsmenge' die letzte Kennzahl ist, verhindern Sie das Abwählen
          return prev;
        } else {
          // Falls 'verkaufsmenge' nicht verfügbar ist, wählen Sie die erste verfügbare Kennzahl
          return [availableDataKeys[0]];
        }
      }
      // Entfernen Sie die Kennzahl aus der Auswahl
      return prev.filter((k) => k !== key);
    } else {
      // Fügen Sie die Kennzahl zur Auswahl hinzu
      return [...prev, key];
    }
  });
};

export default KombinierteAnalyseTable;
