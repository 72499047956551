// DataTable.jsx
import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Box,
  TablePagination,
  Typography,
  Tooltip,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArticleIcon from '@mui/icons-material/Article'; // Icon für Artikel
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // Icon für PDF
import ExcelIcon from '@mui/icons-material/GridOn'; // Verwenden wir ein passendes Icon für Excel
import { formatQuantity, formatValue, formatPercentageMitZeichen } from '../../utils/mathStuff';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

// Importieren der notwendigen Pakete für PDF- und Excel-Export
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const DataTable = ({
  viewMode, // 'warengruppe' oder 'artikel'
  timeFilter, // 'Monat', 'Woche', 'Tag'
  months = [],
  weeks = [],
  days = [],
  groupedData = {}, // Gruppierte Daten
  handleOpenDetailDialog,
  selectedDataKeys = [], // ['total_verkaufsmenge', 'total_wert', ...]
  availableDataKeys = [], // ['total_verkaufsmenge', 'total_wert', ...]
}) => {
  // State für das Suchfeld
  const [searchTerm, setSearchTerm] = useState('');

  // State für die Paginierung
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Perioden basierend auf timeFilter und Sortierung
  const periods = useMemo(() => {
    if (timeFilter === 'Monat') {
      return [...months].sort((a, b) =>
        dayjs(a, 'MMMM YYYY').isAfter(dayjs(b, 'MMMM YYYY')) ? 1 : -1
      );
    } else if (timeFilter === 'Woche') {
      return [...weeks].sort((a, b) => {
        const [weekA, yearA] = a.split(' ');
        const [weekB, yearB] = b.split(' ');
        const weekNumA = parseInt(weekA.replace('KW', ''));
        const weekNumB = parseInt(weekB.replace('KW', ''));
        if (yearA !== yearB) {
          return parseInt(yearA) - parseInt(yearB);
        }
        return weekNumA - weekNumB;
      });
    } else {
      // 'Tag'
      return [...days].sort((a, b) =>
        dayjs(a, 'DD.MM.YYYY').isAfter(dayjs(b, 'DD.MM.YYYY')) ? 1 : -1
      );
    }
  }, [timeFilter, months, weeks, days]);

  // Gefilterte Daten basierend auf dem Suchbegriff
  const filteredData = useMemo(() => {
    if (!searchTerm) return groupedData;

    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    return Object.fromEntries(
      Object.entries(groupedData || {}).filter(([key]) =>
        key.toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  }, [searchTerm, groupedData]);

  // Berechnung der Gesamtsummen für jede Kennzahl und Zeitperiode
  const totalSums = useMemo(() => {
    const total = {};
    selectedDataKeys.forEach((key) => {
      total[key] = {};
      periods.forEach((period) => {
        total[key][period] = Object.values(filteredData || {}).reduce((sum, row) => {
          const value = row[key]?.[period] || 0;
          return sum + value;
        }, 0);
      });
    });
    return total;
  }, [filteredData, selectedDataKeys, periods]);

  // Aufteilen der Daten in Gruppen basierend auf dem viewMode
  const groupedItems = useMemo(() => {
    return Object.entries(filteredData || {}).filter(([key, values]) =>
      selectedDataKeys.some((dataKey) =>
        periods.some((period) => (values[dataKey]?.[period] || 0) !== 0)
      )
    );
  }, [filteredData, selectedDataKeys, periods]);

  // Bestimmen der Daten für die aktuelle Seite
  const paginatedItems = useMemo(() => {
    const start = page * rowsPerPage;
    const end = start + rowsPerPage;
    return groupedItems.slice(start, end);
  }, [groupedItems, page, rowsPerPage]);

  // Handlers für die Paginierung
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Helper-Funktion zur Formatierung der Typ-Namen
  const formatTypName = (dataKey) => {
    if (!dataKey) return 'UNDEFINED';
    return dataKey
      .replace('total_', '')
      .replace('_menge', '')
      .replace('_wert', '')
      .replace('_umsatz', '')
      .replace('_retourquote', '')
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  // Funktion für den PDF-Export
  const handleExportPDF = () => {
    const doc = new jsPDF();

    // Titel
    const title = viewMode === 'warengruppe' ? 'Warengruppe' : 'Artikel';
    doc.text(`Datenexport - ${title}`, 14, 16);

    // Tabelle vorbereiten
    const tableColumns = [
      { header: title, dataKey: 'group' },
      { header: 'Typ', dataKey: 'typ' },
      ...periods.map((period) => ({ header: period, dataKey: period })),
      { header: 'Summe', dataKey: 'sum' },
    ];

    const tableRows = [];

    groupedItems.forEach(([group, values]) => {
      selectedDataKeys.forEach((dataKey, index) => {
        const row = {
          group: index === 0 ? group : '',
          typ: formatTypName(dataKey),
          sum: formatValue(
            Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)
          ),
        };
        periods.forEach((period) => {
          let cellValue = '';
          if (dataKey.includes('menge')) {
            cellValue = formatQuantity(values[dataKey]?.[period]);
          } else if (dataKey.includes('wert') || dataKey.includes('umsatz')) {
            cellValue = formatValue(values[dataKey]?.[period]);
          } else if (dataKey.includes('retourquote')) {
            cellValue = formatPercentageMitZeichen(values[dataKey]?.[period]);
          }
          row[period] = cellValue;
        });
        tableRows.push(row);
      });
    });

    // Gesamtsumme hinzufügen
    selectedDataKeys.forEach((dataKey, index) => {
      const row = {
        group: index === 0 ? 'Gesamtsumme' : '',
        typ: formatTypName(dataKey),
        sum: '', // Leere Zelle für Summe
      };
      periods.forEach((period) => {
        let cellValue = '';
        if (dataKey.includes('menge')) {
          cellValue = formatQuantity(totalSums[dataKey]?.[period]);
        } else if (dataKey.includes('wert') || dataKey.includes('umsatz')) {
          cellValue = formatValue(totalSums[dataKey]?.[period]);
        } else if (dataKey.includes('retourquote')) {
          cellValue = formatPercentageMitZeichen(totalSums[dataKey]?.[period]);
        }
        row[period] = cellValue;
      });
      tableRows.push(row);
    });

    doc.autoTable({
      columns: tableColumns,
      body: tableRows,
      startY: 20,
      styles: { fontSize: 8 },
      headStyles: { fillColor: [41, 128, 185] },
      theme: 'grid',
    });

    doc.save('Datenexport.pdf');
  };

  // Funktion für den Excel-Export
  const handleExportExcel = () => {
    const wb = XLSX.utils.book_new();

    // Titel
    const title = viewMode === 'warengruppe' ? 'Warengruppe' : 'Artikel';

    const wsData = [];

    // Header
    const header = [title, 'Typ', ...periods, 'Summe'];
    wsData.push(header);

    // Daten
    groupedItems.forEach(([group, values]) => {
      selectedDataKeys.forEach((dataKey, index) => {
        const row = [];
        row.push(index === 0 ? group : '');
        row.push(formatTypName(dataKey));
        periods.forEach((period) => {
          let cellValue = '';
          if (dataKey.includes('menge')) {
            cellValue = formatQuantity(values[dataKey]?.[period]);
          } else if (dataKey.includes('wert') || dataKey.includes('umsatz')) {
            cellValue = formatValue(values[dataKey]?.[period]);
          } else if (dataKey.includes('retourquote')) {
            cellValue = formatPercentageMitZeichen(values[dataKey]?.[period]);
          }
          row.push(cellValue);
        });
        row.push(
          formatValue(
            Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)
          )
        );
        wsData.push(row);
      });
    });

    // Gesamtsumme hinzufügen
    selectedDataKeys.forEach((dataKey, index) => {
      const row = [];
      row.push(index === 0 ? 'Gesamtsumme' : '');
      row.push(formatTypName(dataKey));
      periods.forEach((period) => {
        let cellValue = '';
        if (dataKey.includes('menge')) {
          cellValue = formatQuantity(totalSums[dataKey]?.[period]);
        } else if (dataKey.includes('wert') || dataKey.includes('umsatz')) {
          cellValue = formatValue(totalSums[dataKey]?.[period]);
        } else if (dataKey.includes('retourquote')) {
          cellValue = formatPercentageMitZeichen(totalSums[dataKey]?.[period]);
        }
        row.push(cellValue);
      });
      row.push(''); // Leere Zelle für Summe
      wsData.push(row);
    });

    const ws = XLSX.utils.aoa_to_sheet(wsData);
    XLSX.utils.book_append_sheet(wb, ws, 'Daten');

    const excelBuffer = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    const data = new Blob([excelBuffer], { type: 'application/octet-stream' });
    saveAs(data, 'Datenexport.xlsx');
  };

  return (
    <>
      {/* Export-Icons und Suchfeld */}
      <Box mb={2} display="flex" justifyContent="flex-end" alignItems="center">
        <Box display="flex" alignItems="center">
          <Tooltip title="Export als PDF">
            <IconButton size="small" onClick={handleExportPDF}>
              <PictureAsPdfIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export als Excel">
            <IconButton size="small" onClick={handleExportExcel}>
              <ExcelIcon />
            </IconButton>
          </Tooltip>
          <TextField
            label="Suchen"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ ml: 1 }}
          />
        </Box>
      </Box>

      {/* TableContainer */}
      <TableContainer
        component={Paper}
        style={{ marginTop: '0px', maxHeight: '600px', overflow: 'auto' }}
      >
        <Table stickyHeader aria-label="Kombinierte Analyse" size="small">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  padding: '6px 8px',
                  position: 'sticky',
                  top: -20,
                  backgroundColor: '#fff',
                  zIndex: 1000,
                }}
              >
                {viewMode === 'warengruppe' ? 'Warengruppe' : 'Artikel'}
              </TableCell>
              <TableCell
                sx={{
                  padding: '6px 8px',
                  position: 'sticky',
                  top: -20,
                  backgroundColor: '#fff',
                  zIndex: 1000,
                }}
              >
                Typ
              </TableCell>
              {periods.map((period, index) => (
                <TableCell
                  key={index}
                  sx={{
                    padding: '6px 8px',
                    position: 'sticky',
                    top: -20,
                    backgroundColor: '#fff',
                    zIndex: 1000,
                  }}
                >
                  {period}
                </TableCell>
              ))}
              <TableCell
                sx={{
                  padding: '6px 8px',
                  position: 'sticky',
                  top: -20,
                  backgroundColor: '#fff',
                  zIndex: 1000,
                }}
              >
                Summe
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedItems.map(([group, values]) => {
              const typeCount = selectedDataKeys.length;

              return (
                <React.Fragment key={group}>
                  {/* Hauptzeile */}
                  <TableRow>
                    <TableCell
                      rowSpan={typeCount} // Verbindet die Hauptzeile mit den Typ-Zeilen
                      sx={{
                        padding: '6px 8px',
                        cursor: 'default',
                        verticalAlign: 'top',
                        fontWeight: 'bold',
                      }}
                    >
                      {group}
                      {viewMode === 'warengruppe' && (
                        <>
                          <IconButton
                            size="small"
                            aria-label="Details anzeigen"
                            onClick={() => handleOpenDetailDialog('warengruppe', group, values)}
                          >
                            <SearchIcon fontSize="small" />
                          </IconButton>
                          <IconButton
                            size="small"
                            aria-label="Artikel anzeigen"
                            onClick={() => handleOpenDetailDialog('warengruppe_artikel', group, values)}
                          >
                            <ArticleIcon fontSize="small" />
                          </IconButton>
                        </>
                      )}
                      {viewMode === 'artikel' && (
                        <IconButton
                          size="small"
                          aria-label="Details anzeigen"
                          onClick={() => handleOpenDetailDialog('artikel', group, values)}
                        >
                          <SearchIcon fontSize="small" />
                        </IconButton>
                      )}
                    </TableCell>
                    {/* Erste Typ-Zeile */}
                    <TableCell sx={{ padding: '6px 8px' }}>
                      {selectedDataKeys[0] ? formatTypName(selectedDataKeys[0]) : ''}
                    </TableCell>
                    {periods.map((period, idx) => (
                      <TableCell key={idx} sx={{ padding: '6px 8px' }}>
                        {selectedDataKeys[0].includes('menge') &&
                          formatQuantity(values[selectedDataKeys[0]]?.[period])}
                        {(selectedDataKeys[0].includes('wert') || selectedDataKeys[0].includes('umsatz')) &&
                          formatValue(values[selectedDataKeys[0]]?.[period])}
                        {selectedDataKeys[0].includes('retourquote') &&
                          formatPercentageMitZeichen(values[selectedDataKeys[0]]?.[period])}
                      </TableCell>
                    ))}
                    <TableCell sx={{ padding: '6px 8px' }}>
                      {selectedDataKeys[0]
                        ? formatValue(
                            Object.values(values[selectedDataKeys[0]] || {}).reduce(
                              (sum, val) => sum + (val || 0),
                              0
                            )
                          )
                        : ''}
                    </TableCell>
                  </TableRow>

                  {/* Weitere Typ-Zeilen */}
                  {selectedDataKeys.slice(1).map((dataKey) => (
                    <TableRow key={`${group}-${dataKey}`}>
                      <TableCell sx={{ padding: '6px 8px' }}>{formatTypName(dataKey)}</TableCell>
                      {periods.map((period, idx) => (
                        <TableCell key={idx} sx={{ padding: '6px 8px' }}>
                          {dataKey.includes('menge') && formatQuantity(values[dataKey]?.[period])}
                          {(dataKey.includes('wert') || dataKey.includes('umsatz')) &&
                            formatValue(values[dataKey]?.[period])}
                          {dataKey.includes('retourquote') &&
                            formatPercentageMitZeichen(values[dataKey]?.[period])}
                        </TableCell>
                      ))}
                      <TableCell sx={{ padding: '6px 8px' }}>
                        {formatValue(
                          Object.values(values[dataKey] || {}).reduce((sum, val) => sum + (val || 0), 0)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              );
            })}

            {/* Globale Gesamtsumme Block */}
            <React.Fragment>
              {/* Gesamtsumme Hauptzeile */}
              <TableRow>
                <TableCell
                  rowSpan={selectedDataKeys.length} // Verbindet die Gesamtsumme mit den Typ-Zeilen
                  sx={{
                    padding: '6px 8px',
                    fontWeight: 'bold',
                    verticalAlign: 'top',
                    backgroundColor: '#f0f0f0',
                  }}
                >
                  Gesamtsumme
                </TableCell>
                {/* Erste Gesamtsumme Typ-Zeile */}
                <TableCell sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}>
                  {selectedDataKeys[0] ? formatTypName(selectedDataKeys[0]) : ''}
                </TableCell>
                {periods.map((period, idx) => (
                  <TableCell key={idx} sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}>
                    {selectedDataKeys[0].includes('menge') &&
                      formatQuantity(totalSums[selectedDataKeys[0]]?.[period])}
                    {(selectedDataKeys[0].includes('wert') || selectedDataKeys[0].includes('umsatz')) &&
                      formatValue(totalSums[selectedDataKeys[0]]?.[period])}
                    {selectedDataKeys[0].includes('retourquote') &&
                      formatPercentageMitZeichen(totalSums[selectedDataKeys[0]]?.[period])}
                  </TableCell>
                ))}
                <TableCell sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}></TableCell>{' '}
                {/* Leere Zelle für Summe */}
              </TableRow>

              {/* Weitere Gesamtsumme Typ-Zeilen */}
              {selectedDataKeys.slice(1).map((dataKey) => (
                <TableRow key={`global-${dataKey}`}>
                  <TableCell sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}>
                    {formatTypName(dataKey)}
                  </TableCell>
                  {periods.map((period, idx) => (
                    <TableCell key={idx} sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}>
                      {dataKey.includes('menge') && formatQuantity(totalSums[dataKey]?.[period])}
                      {(dataKey.includes('wert') || dataKey.includes('umsatz')) &&
                        formatValue(totalSums[dataKey]?.[period])}
                      {dataKey.includes('retourquote') &&
                        formatPercentageMitZeichen(totalSums[dataKey]?.[period])}
                    </TableCell>
                  ))}
                  <TableCell sx={{ padding: '6px 8px', backgroundColor: '#f0f0f0' }}></TableCell>{' '}
                  {/* Leere Zelle für Summe */}
                </TableRow>
              ))}
            </React.Fragment>
          </TableBody>
        </Table>
      </TableContainer>

      {/* Paginierung */}
      <TablePagination
        component="div"
        count={groupedItems.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 20, 50]}
      />
    </>
  );
};

export default DataTable;
