// ActiveChecks.js

import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';

const ActiveChecks = ({ activeChecks, handleCheckClick, checkCounts }) => {
  return (
    <Grid container spacing={2}>
      {activeChecks.length > 0 ? (
        activeChecks.map((check) => (
          <Grid item xs={12} key={check.id}>
            <Paper sx={{ p: 3, cursor: 'pointer' }} onClick={() => handleCheckClick(check)}>
              <Typography variant="h6" gutterBottom>
                {check.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Checks: {checkCounts[check.id] ? checkCounts[check.id].size : 0}
              </Typography>
            </Paper>
          </Grid>
        ))
      ) : (
        <Grid item xs={12}>
          <Typography variant="body1">Keine aktiven Checks verfügbar.</Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default ActiveChecks;
