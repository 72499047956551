import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  useTheme,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import CloseIcon from "@mui/icons-material/Close";
import LineChartComponentSecondYAxis from "../../charts/LineChartComponentSecondYAxis"; // Adjust the import path accordingly

const BigChartCardSecondYAxis = ({ data, title, infoContent, height, width }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Function to toggle fullscreen mode
  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  // Content of the chart to avoid code duplication
  const ChartContent = (
    <>
      {data && data.length > 0 ? (
        <LineChartComponentSecondYAxis
          data={data}
          height="100%" // Pass height to the LineChartComponentSecondYAxis component
          width="100%"  // Pass width to the LineChartComponentSecondYAxis component
        />
      ) : (
        <Typography variant="body2" color="text.secondary">
          Keine Daten verfügbar
        </Typography>
      )}
    </>
  );

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Card
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: width || "100%",
            padding: "0px",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            backdropFilter: "blur(10px)",
          }}
        >
          <CardHeader
            title={
              <Typography
                variant={isSmallScreen ? "h6" : "h6"}
                component="div"
              >
                {title}
              </Typography>
            }
            action={
              <>
                {infoContent && (
                  <Tooltip title={infoContent} arrow placement="top">
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton onClick={toggleFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </>
            }
          />
          <CardContent
            sx={{
              flexGrow: 1,
              height: "100%",
              width: "100%",
            }}
          >
            {ChartContent}
          </CardContent>
        </Card>
      </Paper>

      {/* Fullscreen Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth={false}
        fullScreen
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={toggleFullscreen}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {ChartContent}
        </DialogContent>
      </Dialog>
    </>
  );
};

// Define PropTypes for better type checking and documentation
BigChartCardSecondYAxis.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          x: PropTypes.string.isRequired, // Example: Month
          y: PropTypes.number.isRequired, // Example: Value
        })
      ).isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
  infoContent: PropTypes.string, // Optional
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Optional
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Optional
};

export default BigChartCardSecondYAxis;
