import React from 'react';
import { List, Box, ListItem, ListItemText, IconButton, Collapse, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import StepList from './StepList';
import AddIcon from '@mui/icons-material/Add';

const TopicList = ({
    topics,
    steps,
    openTopics,
    handleToggleTopic,
    handleDeleteTopic,
    handleEditTopic,
    handleDeleteStep,
    handleEditStep,
    handleAddStepPlaceholderClick,
}) => (
    <List sx={{ width: '100%' }}>
        {topics.length > 0 ? (
            topics.map((topic) => (
                <Box
                    key={topic.id}
                    sx={{
                        border: '1px solid #ddd',
                        borderRadius: '8px',
                        p: 2,
                        mb: 1,
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <ListItem
                        secondaryAction={
                            <>
                                <IconButton edge="end" aria-label="edit" onClick={() => handleEditTopic(topic)}>
                                    <EditIcon />
                                </IconButton>
                                <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteTopic(topic.id)}>
                                    <DeleteIcon />
                                </IconButton>
                            </>
                        }
                        onClick={() => handleToggleTopic(topic.id)}
                    >
                        <ListItemText primary={topic.name} />
                    </ListItem>
                    <Collapse in={openTopics[topic.id]} timeout="auto" unmountOnExit>
                        <StepList
                            steps={steps[topic.id] || []}
                            handleDeleteStep={handleDeleteStep}
                            handleEditStep={handleEditStep}
                        />
                        <Box
                            sx={{
                                border: '2px dashed #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: '#888',
                                mt: 2,
                                width: '100%',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                                boxSizing: 'border-box',
                            }}
                            onClick={() => handleAddStepPlaceholderClick(topic.id)}
                        >
                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Neuen Schritt hinzufügen
                        </Box>
                    </Collapse>
                </Box>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">Keine Themen vorhanden.</Typography>
        )}
    </List>
);

export default TopicList;
