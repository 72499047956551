import React, { useEffect, useState, useRef } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';

const BarChartHorizontal = ({ title, data = [], hoch, labelKey, valueKey }) => {
    const theme = useTheme();
    const [labels, setLabels] = useState([]);
    const [chartData, setChartData] = useState([]);
    const chartContainerRef = useRef(); // Ref for the chart container
    const { width = 1 } = useResizeObserver({ ref: chartContainerRef });
    const [fontSize, setFontSize] = useState(14); // Default font size for labels

    // Festgelegte Anzahl von sichtbaren Balken (5 Balken)
    const visibleBars = 5;

    useEffect(() => {
        // Extrahiere die Labels und Daten aus dem übergebenen data-Array anhand der dynamischen Felder
        const extractedData = data.map(item => ({
            [labelKey]: item[labelKey] || 'Unbekannt',
            [valueKey]: item[valueKey] || 0,
        }));

        setChartData(extractedData);
    }, [data, labelKey, valueKey]);

    useEffect(() => {
        // Dynamisch die Schriftgröße basierend auf der Container-Breite anpassen
        if (width < 400) {
            setFontSize(10);
        } else if (width < 800) {
            setFontSize(12);
        } else {
            setFontSize(14);
        }
    }, [width]);

    // Berechne die maximale Höhe basierend auf der Anzahl der sichtbaren Balken
    const barHeight = 50; // Höhe eines einzelnen Balkens (geschätzt)
    const containerHeight = visibleBars * barHeight; // Höhe für 5 sichtbare Balken

    return (
        <div ref={chartContainerRef} style={{ width: '100%', height: `${containerHeight}px`, overflowY: 'auto' }}>
            {title && (
                <h3
                    style={{
                        fontSize: fontSize + 2,
                        color: theme.palette.text.primary,
                        marginBottom: '10px',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </h3>
            )}
            <ResponsiveContainer width="100%" height={data.length * barHeight}>
                <BarChart
                    data={chartData}
                    layout="vertical"
                    margin={{ top: 10, right: 20, bottom: 20, left: 100 }} // Dynamische linke Margin
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        type="number"
                        hide // X-Achse ausblenden
                    />
                    <YAxis
                        type="category"
                        dataKey={labelKey}
                        tick={{ fontSize: fontSize, fill: theme.palette.text.primary }}
                        width={100} // Passen Sie diese Breite an, um die Labelgröße zu berücksichtigen
                    />
                    <Tooltip />
                    <Bar
                        dataKey={valueKey}
                        fill={theme.palette.primary.main}
                        radius={[5, 5, 0, 0]} // Ecken der Balken abrunden
                    >
                        <LabelList
                            dataKey={valueKey}
                            position="insideRight"
                            style={{ fill: 'white' }} // Label-Farbe auf Weiß setzen
                        />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartHorizontal;
