import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography, List, Alert } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import AddIcon from '@mui/icons-material/Add';
import CategoryItem from './CategoryItem';
import CategoryDialog from './CategoryDialog';
import TopicDialog from './TopicDialog';
import StepDialog from './StepDialog'; // Importiere StepDialog für Schritte

const OnboardingPlanKonfiguration = ({ open, handleClose, planId, planName }) => {
    const [categoryName, setCategoryName] = useState('');
    const [topicName, setTopicName] = useState('');
    const [stepName, setStepName] = useState('');  // Für das Erstellen von Schritten
    const [categories, setCategories] = useState([]);
    const [topics, setTopics] = useState([]);
    const [steps, setSteps] = useState({});
    const [openTopics, setOpenTopics] = useState({});
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedTopic, setSelectedTopic] = useState(null);  // Verfolge das ausgewählte Thema
    const [openCategoryDialog, setOpenCategoryDialog] = useState(false);
    const [openTopicDialog, setOpenTopicDialog] = useState(false);
    const [openStepDialog, setOpenStepDialog] = useState(false);  // Für den Schritt-Dialog
    const [editingCategory, setEditingCategory] = useState(null);
    const [editingTopic, setEditingTopic] = useState(null);
    const [editingStep, setEditingStep] = useState(null); // Für die Bearbeitung von Schritten

    useEffect(() => {
        if (open) {
            axiosInstance.get(`/onboarding/categories/`)
                .then(response => {
                    const filteredCategories = response.data.results.filter(category => category.onboarding_plan === planId);
                    setCategories(filteredCategories);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Kategorien:', error);
                });

            axiosInstance.get(`/onboarding/topics/`)
                .then(response => {
                    setTopics(response.data.results);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Themen:', error);
                });

            axiosInstance.get(`/onboarding/onboarding-steps/`)
                .then(response => {
                    const stepsByTopic = response.data.results.reduce((acc, step) => {
                        if (!acc[step.topic]) {
                            acc[step.topic] = [];
                        }
                        acc[step.topic].push(step);
                        return acc;
                    }, {});
                    setSteps(stepsByTopic);
                })
                .catch(error => {
                    console.error('Fehler beim Laden der Schritte:', error);
                });
        }
    }, [open, planId]);

    const handleToggleTopic = (topicId) => {
        setOpenTopics(prevOpenTopics => ({
            ...prevOpenTopics,
            [topicId]: !prevOpenTopics[topicId]
        }));
        setSelectedTopic(topicId);  // Setze die ausgewählte Themen-ID
    };

    const handleSaveCategory = () => {
        if (categoryName.trim() === '') {
            setError('Kategoriename darf nicht leer sein.');
            return;
        }

        if (editingCategory) {
            axiosInstance.patch(`/onboarding/categories/${editingCategory.id}/`, { name: categoryName })
                .then((response) => {
                    setCategories(categories.map(category => category.id === editingCategory.id ? response.data : category));
                    setEditingCategory(null);
                    setCategoryName('');
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten der Kategorie.');
                    console.error('Fehler beim Bearbeiten der Kategorie:', error);
                });
        } else {
            axiosInstance.post('/onboarding/categories/', { name: categoryName, onboarding_plan: planId })
                .then((response) => {
                    setCategories([...categories, response.data]);
                    setCategoryName('');
                    setError(null);
                    setOpenCategoryDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern der Kategorie.');
                    console.error('Fehler beim Speichern der Kategorie:', error);
                });
        }
    };

    const handleSaveTopic = () => {
        if (topicName.trim() === '') {
            setError('Themenname darf nicht leer sein.');
            return;
        }

        if (editingTopic) {
            axiosInstance.patch(`/onboarding/topics/${editingTopic.id}/`, { name: topicName })
                .then((response) => {
                    setTopics(topics.map(topic => topic.id === editingTopic.id ? response.data : topic));
                    setEditingTopic(null);
                    setTopicName('');
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Bearbeiten des Themas.');
                    console.error('Fehler beim Bearbeiten des Themas:', error);
                });
        } else {
            axiosInstance.post('/onboarding/topics/', { name: topicName, category: selectedCategory })
                .then((response) => {
                    setTopics([...topics, response.data]);
                    setTopicName('');
                    setError(null);
                    setOpenTopicDialog(false);
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Themas.');
                    console.error('Fehler beim Speichern des Themas:', error);
                });
        }
    };

    // Logik zum Speichern von Schritten
    const handleSaveStep = () => {
        if (stepName.trim() === '') {
            setError('Schrittname darf nicht leer sein.');
            return;
        }

        if (editingStep) {
            // Bearbeiten eines vorhandenen Schritts
            axiosInstance.patch(`/onboarding/onboarding-steps/${editingStep.id}/`, {
                description: stepName
            })
                .then(response => {
                    // Aktualisiere den Schritt im Zustand 'steps'
                    setSteps(prevSteps => ({
                        ...prevSteps,
                        [editingStep.topic]: prevSteps[editingStep.topic].map(step => step.id === editingStep.id ? response.data : step)
                    }));
                    setEditingStep(null);
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);
                })
                .catch(error => {
                    setError('Fehler beim Bearbeiten des Schritts.');
                    console.error('Fehler beim Bearbeiten des Schritts:', error);
                });
        } else {
            // Erstellen eines neuen Schritts
            axiosInstance.post('/onboarding/onboarding-steps/', {
                description: stepName,  // Schrittbeschreibung
                order: 0,  // Standardreihenfolge auf 0 setzen
                topic: selectedTopic  // Das aktuell ausgewählte Thema
            })
                .then((response) => {
                    // Füge den neuen Schritt zur aktuellen Liste der Schritte unter dem ausgewählten Thema hinzu
                    setSteps(prevSteps => ({
                        ...prevSteps,
                        [selectedTopic]: [...(prevSteps[selectedTopic] || []), response.data]
                    }));
                    setStepName('');
                    setError(null);
                    setOpenStepDialog(false);  // Schließe den Dialog nach dem Speichern
                })
                .catch((error) => {
                    setError('Fehler beim Speichern des Schritts.');
                    console.error('Fehler beim Speichern des Schritts:', error);
                });
        }
    };

    const handleDeleteCategory = (id) => {
        axiosInstance.delete(`/onboarding/categories/${id}`)
            .then(() => {
                setCategories(categories.filter(category => category.id !== id));
            })
            .catch(error => {
                console.error('Fehler beim Löschen der Kategorie:', error);
            });
    };

    const handleDeleteTopic = (id) => {
        axiosInstance.delete(`/onboarding/topics/${id}`)
            .then(() => {
                setTopics(topics.filter(topic => topic.id !== id));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Themas:', error);
            });
    };

    const handleDeleteStep = (step) => {
        axiosInstance.delete(`/onboarding/onboarding-steps/${step.id}`)
            .then(() => {
                setSteps(prevSteps => ({
                    ...prevSteps,
                    [step.topic]: prevSteps[step.topic].filter(s => s.id !== step.id)
                }));
            })
            .catch(error => {
                console.error('Fehler beim Löschen des Schritts:', error);
            });
    };

    const handleEditCategory = (category) => {
        setEditingCategory(category);
        setCategoryName(category.name);
        setOpenCategoryDialog(true);
    };

    const handleEditTopic = (topic) => {
        setEditingTopic(topic);
        setTopicName(topic.name);
        setOpenTopicDialog(true);
    };

    const handleEditStep = (step) => {
        setEditingStep(step);
        setStepName(step.description);
        setOpenStepDialog(true);
    };

    const handleAddTopicPlaceholderClick = (categoryId) => {
        setTopicName('');
        setEditingTopic(null);
        setSelectedCategory(categoryId);  // Setze die ausgewählte Kategorie
        setOpenTopicDialog(true);
    };

    const handleAddStepPlaceholderClick = (topicId) => {
        setStepName('');
        setEditingStep(null);
        setSelectedTopic(topicId);  // Setze das ausgewählte Thema
        setOpenStepDialog(true);
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
                <DialogTitle>Onboarding Plan Konfiguration - {planName}</DialogTitle>
                <DialogContent>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    <Box component="form" sx={{ mb: 2 }}>
                        <List>
                            {categories.length > 0 ? categories.map((category) => (
                                <CategoryItem
                                    key={category.id}
                                    category={category}
                                    selectedCategory={selectedCategory}
                                    setSelectedCategory={setSelectedCategory}
                                    topics={topics.filter(topic => topic.category === category.id)}
                                    steps={steps}
                                    openTopics={openTopics}
                                    handleToggleTopic={handleToggleTopic}
                                    handleDeleteCategory={handleDeleteCategory}
                                    handleEditCategory={handleEditCategory}
                                    handleDeleteTopic={handleDeleteTopic}
                                    handleEditTopic={handleEditTopic}
                                    handleDeleteStep={handleDeleteStep}
                                    handleEditStep={handleEditStep}
                                    handleAddTopicPlaceholderClick={handleAddTopicPlaceholderClick}
                                    handleAddStepPlaceholderClick={handleAddStepPlaceholderClick}
                                />
                            )) : (
                                <Typography variant="body2" color="textSecondary">Keine Kategorien vorhanden.</Typography>
                            )}
                        </List>

                        {/* Nur der "Neue Kategorie anlegen"-Button bleibt hier */}
                        <Box
                            sx={{
                                border: '2px dashed #ddd',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                cursor: 'pointer',
                                color: '#888',
                                mt: 2,
                                width: '100%',
                                '&:hover': { backgroundColor: '#f5f5f5' },
                                boxSizing: 'border-box',
                            }}
                            onClick={() => setOpenCategoryDialog(true)}  // Öffnet den Dialog zum Erstellen einer neuen Kategorie
                        >
                            <AddIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                            Neue Kategorie anlegen
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="contained">Schließen</Button>
                </DialogActions>
            </Dialog>

            <CategoryDialog
                open={openCategoryDialog}
                onClose={() => {
                    setOpenCategoryDialog(false);
                    setEditingCategory(null);
                    setCategoryName('');
                    setError(null); // Setze den Fehlerzustand zurück
                }}
                categoryName={categoryName}
                setCategoryName={setCategoryName}
                handleSaveCategory={handleSaveCategory}
            />

            <TopicDialog
                open={openTopicDialog}
                onClose={() => {
                    setOpenTopicDialog(false);
                    setEditingTopic(null);
                    setTopicName('');
                    setError(null); // Setze den Fehlerzustand zurück
                }}
                topicName={topicName}
                setTopicName={setTopicName}
                handleSaveTopic={handleSaveTopic}
            />

            <StepDialog
                open={openStepDialog}
                onClose={() => {
                    setOpenStepDialog(false);
                    setEditingStep(null);
                    setStepName('');
                    setError(null); // Setze den Fehlerzustand zurück
                }}
                stepName={stepName}
                setStepName={setStepName}
                handleSaveStep={handleSaveStep}
            />
        </>
    );
};

export default OnboardingPlanKonfiguration;
