export const calculatePerformanceData = (allFilialenData) => {
    let umsatzSumme = 0;
    let umsatzVJSumme = 0;
    let umsatzVMSumme = 0;
    let umsatz6MSumme = 0;

    let kundenanzahlSumme = 0;
    let kundenanzahlVJSumme = 0;
    let kundenanzahlVMSumme = 0;
    let kundenanzahl6MSumme = 0;

    let produktivstundenSumme = 0;
    let produktivstundenVJSumme = 0;

    // Hilfsobjekte zur Gruppierung der Daten nach Verkaufsgebiet und Filialtyp
    const verkaufsgebiete = {};
    const filialtypen = {};

    // Kumulierung der Daten über alle Filialen hinweg
    allFilialenData.forEach((filiale) => {
        const {
            verkaufsgebiet,
            umsatz,
            umsatzVJ,
            umsatzVM,
            umsatz6M,
            kundenanzahl,
            kundenanzahlVJ,
            kundenanzahlVM,
            kundenanzahl6M,
            produktivstunden,
            produktivstundenVJ,
        } = filiale;
        const filialtyp = filiale.filialtyp ? filiale.filialtyp : 'Keine Zuordnung'; // Setze 'Keine Zuordnung' wenn leer

        // Gesamtsummen für alle Filialen kumulieren
        umsatzSumme += umsatz || 0;
        umsatzVJSumme += umsatzVJ || 0;
        umsatzVMSumme += umsatzVM || 0;
        umsatz6MSumme += umsatz6M || 0;

        kundenanzahlSumme += kundenanzahl || 0;
        kundenanzahlVJSumme += kundenanzahlVJ || 0;
        kundenanzahlVMSumme += kundenanzahlVM || 0;
        kundenanzahl6MSumme += kundenanzahl6M || 0;

        produktivstundenSumme += produktivstunden || 0;
        produktivstundenVJSumme += produktivstundenVJ || 0;

        // Verkaufsgebiet-Summen kumulieren
        if (!verkaufsgebiete[verkaufsgebiet]) {
            verkaufsgebiete[verkaufsgebiet] = {
                umsatzSumme: 0,
                umsatzVJSumme: 0,
                umsatzVMSumme: 0,
                umsatz6MSumme: 0,
                kundenanzahlSumme: 0,
                kundenanzahlVJSumme: 0,
                kundenanzahlVMSumme: 0,
                kundenanzahl6MSumme: 0,
            };
        }
        verkaufsgebiete[verkaufsgebiet].umsatzSumme += umsatz || 0;
        verkaufsgebiete[verkaufsgebiet].umsatzVJSumme += umsatzVJ || 0;
        verkaufsgebiete[verkaufsgebiet].umsatzVMSumme += umsatzVM || 0;
        verkaufsgebiete[verkaufsgebiet].umsatz6MSumme += umsatz6M || 0;

        verkaufsgebiete[verkaufsgebiet].kundenanzahlSumme += kundenanzahl || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahlVJSumme += kundenanzahlVJ || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahlVMSumme += kundenanzahlVM || 0;
        verkaufsgebiete[verkaufsgebiet].kundenanzahl6MSumme += kundenanzahl6M || 0;

        // Filialtyp-Summen kumulieren
        if (!filialtypen[filialtyp]) {
            filialtypen[filialtyp] = {
                umsatzSumme: 0,
                umsatzVJSumme: 0,
                kundenanzahlSumme: 0,
                kundenanzahlVJSumme: 0,
            };
        }
        filialtypen[filialtyp].umsatzSumme += umsatz || 0;
        filialtypen[filialtyp].umsatzVJSumme += umsatzVJ || 0;
        filialtypen[filialtyp].kundenanzahlSumme += kundenanzahl || 0;
        filialtypen[filialtyp].kundenanzahlVJSumme += kundenanzahlVJ || 0;
    });

    // Berechne den Kundenbon: Umsatz / Kundenanzahl
    const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
    const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
    const kundenbonVM = kundenanzahlVMSumme > 0 ? umsatzVMSumme / kundenanzahlVMSumme : 0;
    const kundenbon6M = kundenanzahl6MSumme > 0 ? umsatz6MSumme / kundenanzahl6MSumme : 0;

    // Berechne die Leistung: Umsatz / Produktivstunden
    const leistung = produktivstundenSumme > 0 ? umsatzSumme / produktivstundenSumme : 0;
    const leistungVJ = produktivstundenVJSumme > 0 ? umsatzVJSumme / produktivstundenVJSumme : 0;

    // Berechne prozentuale Abweichungen für Umsatz
    const umsatzAbweichungVM = umsatzVMSumme > 0 ? ((umsatzSumme - umsatzVMSumme) / umsatzVMSumme) * 100 : 0;
    const umsatzAbweichung6M = umsatz6MSumme > 0 ? ((umsatzSumme - umsatz6MSumme) / umsatz6MSumme) * 100 : 0;
    const umsatzAbweichungVJ = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;

    // Berechne prozentuale Abweichungen für Kundenanzahl
    const kundenanzahlAbweichungVM =
        kundenanzahlVMSumme > 0 ? ((kundenanzahlSumme - kundenanzahlVMSumme) / kundenanzahlVMSumme) * 100 : 0;
    const kundenanzahlAbweichung6M =
        kundenanzahl6MSumme > 0 ? ((kundenanzahlSumme - kundenanzahl6MSumme) / kundenanzahl6MSumme) * 100 : 0;
    const kundenanzahlAbweichungVJ =
        kundenanzahlVJSumme > 0 ? ((kundenanzahlSumme - kundenanzahlVJSumme) / kundenanzahlVJSumme) * 100 : 0;

    // Berechne prozentuale Abweichungen für Kundenbon
    const kundenbonAbweichungVM = kundenbonVM > 0 ? ((kundenbon - kundenbonVM) / kundenbonVM) * 100 : 0;
    const kundenbonAbweichung6M = kundenbon6M > 0 ? ((kundenbon - kundenbon6M) / kundenbon6M) * 100 : 0;
    const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;

    // Berechnung des Umsatzwachstums und Kundenbons für jedes Verkaufsgebiet
    const verkaufsgebieteResult = Object.keys(verkaufsgebiete).map((gebiet) => {
        const data = verkaufsgebiete[gebiet];
        const {
            umsatzSumme,
            umsatzVJSumme,
            umsatzVMSumme,
            umsatz6MSumme,
            kundenanzahlSumme,
            kundenanzahlVJSumme,
            kundenanzahlVMSumme,
            kundenanzahl6MSumme,
        } = data;

        const umsatzWachstum = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;
        const umsatzWachstumVM = umsatzVMSumme > 0 ? ((umsatzSumme - umsatzVMSumme) / umsatzVMSumme) * 100 : 0;
        const umsatzWachstum6M = umsatz6MSumme > 0 ? ((umsatzSumme - umsatz6MSumme) / umsatz6MSumme) * 100 : 0;

        // Kundenbon berechnen
        const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
        const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
        const kundenbonVM = kundenanzahlVMSumme > 0 ? umsatzVMSumme / kundenanzahlVMSumme : 0;
        const kundenbon6M = kundenanzahl6MSumme > 0 ? umsatz6MSumme / kundenanzahl6MSumme : 0;

        // Kundenbon Abweichungen
        const kundenbonAbweichungVJ = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;
        const kundenbonAbweichungVM = kundenbonVM > 0 ? ((kundenbon - kundenbonVM) / kundenbonVM) * 100 : 0;
        const kundenbonAbweichung6M = kundenbon6M > 0 ? ((kundenbon - kundenbon6M) / kundenbon6M) * 100 : 0;

        return {
            verkaufsgebiet: gebiet,
            umsatzSumme,
            umsatzVJSumme,
            umsatzWachstum: umsatzWachstum.toFixed(2),
            umsatzWachstumVM: umsatzWachstumVM.toFixed(2),
            umsatzWachstum6M: umsatzWachstum6M.toFixed(2),
            kundenbon: kundenbon.toFixed(2),
            kundenbonVJ: kundenbonVJ.toFixed(2),
            kundenbonAbweichungVJ: kundenbonAbweichungVJ.toFixed(2),
            kundenbonAbweichungVM: kundenbonAbweichungVM.toFixed(2),
            kundenbonAbweichung6M: kundenbonAbweichung6M.toFixed(2),
        };
    });

    // Berechnung des Umsatzwachstums und Kundenbons für jeden Filialtyp
    const filialtypenResult = Object.keys(filialtypen).map((typ) => {
        const data = filialtypen[typ];
        const { umsatzSumme, umsatzVJSumme, kundenanzahlSumme, kundenanzahlVJSumme } = data;

        const umsatzAbweichung = umsatzVJSumme > 0 ? ((umsatzSumme - umsatzVJSumme) / umsatzVJSumme) * 100 : 0;

        // Kundenbon berechnen
        const kundenbon = kundenanzahlSumme > 0 ? umsatzSumme / kundenanzahlSumme : 0;
        const kundenbonVJ = kundenanzahlVJSumme > 0 ? umsatzVJSumme / kundenanzahlVJSumme : 0;
        const kundenbonAbweichung = kundenbonVJ > 0 ? ((kundenbon - kundenbonVJ) / kundenbonVJ) * 100 : 0;

        return {
            filialtyp: typ,
            umsatzSumme,
            umsatzVJSumme,
            umsatzAbweichung: umsatzAbweichung.toFixed(2),
            kundenbon: kundenbon.toFixed(2),
            kundenbonVJ: kundenbonVJ.toFixed(2),
            kundenbonAbweichung: kundenbonAbweichung.toFixed(2),
        };
    });

    return {
        umsatzSumme,
        umsatzVJSumme,
        umsatzVMSumme,
        umsatz6MSumme,
        umsatzAbweichungVM,
        umsatzAbweichung6M,
        umsatzAbweichungVJ,
        kundenanzahlSumme,
        kundenanzahlVJSumme,
        kundenanzahlVMSumme,
        kundenanzahl6MSumme,
        kundenanzahlAbweichungVM,
        kundenanzahlAbweichung6M,
        kundenanzahlAbweichungVJ,
        kundenbon,
        kundenbonVJ,
        kundenbonVM,
        kundenbon6M,
        kundenbonAbweichungVM,
        kundenbonAbweichung6M,
        kundenbonAbweichungVJ,
        leistung,
        leistungVJ,
        verkaufsgebieteResult,
        filialtypenResult,
    };
};
