import React, { useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Box,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { useTheme } from '@mui/material/styles';
import PieChartFilialtyp from '../../charts/PieChartFilialtyp'; 
import CloseIcon from '@mui/icons-material/Close';

const BigPieChartCardUnternehmen = ({ data, title, metric }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  // Definiere die Farben aus dem Theme
  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart?.yellow || "#FFEB3B",
    theme.palette.chart?.orange || "#FF9800",
    theme.palette.chart?.red || "#F44336",
    theme.palette.chart?.pink || "#E91E63",
    theme.palette.chart?.purpleLight || "#AB47BC",
    theme.palette.chart?.purpleDark || "#7B1FA2",
  ];

  const sumField = `${metric}Summe`;
  const abweichungField = `${metric}Abweichung`;

  // Berechne den Gesamtwert, um den prozentualen Anteil zu berechnen
  const totalValue = data.reduce((acc, item) => acc + (item[sumField] || 0), 0);

  // Sortiere die Daten nach dem Summe-Feld in absteigender Reihenfolge
  const sortedData = [...data].sort((a, b) => (b[sumField] || 0) - (a[sumField] || 0));

  // Zeige nur die Top 3 Kategorien an
  const top3Data = sortedData.slice(0, 3);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderLegend = (chartData) => (
    <Box sx={{ marginTop: 2 }}>
      {chartData.map((entry, index) => {
        const percentage = totalValue > 0 ? ((entry[sumField] / totalValue) * 100).toFixed(2) : 0;
        const abweichung = entry[abweichungField]?.toFixed(2) || 0;

        return (
          <Box key={`legend-${index}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box
              sx={{
                width: 16,
                height: 16,
                backgroundColor: COLORS[index % COLORS.length],
                mr: 1,
                borderRadius: '50%',
              }}
            />
            <Typography variant="body2">
              {`${entry.filialtyp || 'Unbekannt'}: ${percentage}% (${abweichung}% VJ Abw.)`}
            </Typography>
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Grid item xs={12} md={12} >
      <Paper>
      <Card
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '100%',
                                    padding: '0px',
                                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                    backdropFilter: 'blur(10px)',
                                }}
                            >
          <CardHeader
            title={
              <Typography variant="h6" component="div">
                {title}
              </Typography>
            }
          />
          <CardContent>
            {data && data.length > 0 && (
              <Box>
                {/* PieChart mit allen Kategorien */}
                <PieChartFilialtyp data={sortedData} metric={metric} />
                
                {/* Box für die Legende mit den Top 3 Filialtypen */}
                {renderLegend(top3Data)}
              </Box>
            )}
            <Box sx={{ display: 'flex', justifyContent: 'left', mt: 0 }}>
          <Typography 
            onClick={handleClickOpen} 
            sx={{ cursor: 'pointer', color: theme.palette.primary.main, fontWeight: 'bold' }}
          >
            ...
          </Typography>
        </Box>
          </CardContent>
        </Card>

        
      </Paper>

      {/* Dialog zum Anzeigen aller Kategorien */}
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>
          Tiefenanalyse {metric.charAt(0).toUpperCase() + metric.slice(1)}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Stack direction="column" spacing={2}>
            {/* PieChart im Dialog */}
            <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
              <PieChartFilialtyp data={sortedData} metric={metric} /> {/* Alle Daten anzeigen */}
            </Box>

            {/* Legende im Dialog mit allen Filialtypen und Abweichung */}
            <Box sx={{ flex: 1 }}>
              {renderLegend(sortedData)} {/* Alle Filialtypen in der Legende anzeigen */}
            </Box>
          </Stack>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default BigPieChartCardUnternehmen;
