import React, { useState } from 'react';
import { OrganizationChart } from 'primereact/organizationchart';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import Battery3Bar from '@mui/icons-material/Battery3Bar';
import { Card, Typography, Box, Paper, CardHeader, CardContent, Grid } from '@mui/material';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';

const OrgChart = () => {
  const [nodes, setNodes] = useState([]);
  const [formData, setFormData] = useState({ label: '', age: '', parentLabel: '' });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const addNode = () => {
    const newNode = {
      label: formData.label,
      age: parseInt(formData.age),
      type: 'person',
      className: 'p-person',
      expanded: false,
      children: []
    };

    if (formData.parentLabel) {
      const addToParent = (parent) => {
        if (parent.label === formData.parentLabel) {
          parent.children = parent.children ? [...parent.children, newNode] : [newNode];
        } else if (parent.children) {
          parent.children.forEach(addToParent);
        }
      };
      const updatedNodes = [...nodes];
      updatedNodes.forEach(addToParent);
      setNodes(updatedNodes);
    } else {
      setNodes([...nodes, newNode]);
    }

    setFormData({ label: '', age: '', parentLabel: '' });
  };

  const nodeTemplate = (node) => {
    return (
      <div className="p-person">
        <div>{node.label}</div>
        <div>Alter: {node.age}</div>
      </div>
    );
  };

  return (
    <Layout>
      <PageHeader
        title="Orga- Chart"
        subtitle="Orga- Chart."
        Icon={Battery3Bar}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
        <Card sx={{ padding: 2 }}>
          <Typography variant="h6">Organigramm bearbeiten</Typography>
          <Box display="flex" flexDirection="column" gap={1} mt={1}>
            <input
              type="text"
              name="label"
              placeholder="Name"
              value={formData.label}
              onChange={handleInputChange}
            />
            <input
              type="number"
              name="age"
              placeholder="Alter"
              value={formData.age}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="parentLabel"
              placeholder="Übergeordneter Name (optional)"
              value={formData.parentLabel}
              onChange={handleInputChange}
            />
            <button onClick={addNode}>Knoten hinzufügen</button>
          </Box>
        </Card>
      </Box>

      <Grid item xs={12} md={12} mt={3}>
        <Paper>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              height: '100%',
              padding: '0px',
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              backdropFilter: 'blur(10px)',
            }}
          >
            <CardHeader
              title={
                <Typography variant="h6" component="div">
                  Organigramm
                </Typography>
              }
            />
            <CardContent sx={{ flexGrow: 1 }}>
              {nodes.length > 0 ? (
                <OrganizationChart value={nodes} nodeTemplate={nodeTemplate} className="p-person"/>
              ) : (
                <Typography variant="body1">Fügen Sie Knoten zum Organigramm hinzu.</Typography>
              )}
            </CardContent>
          </Card>
        </Paper>
      </Grid>
    </Layout>
  );
};

export default OrgChart;
