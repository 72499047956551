import {Box, Card, CardContent, CardHeader, Paper, Typography} from "@mui/material";
import React from "react";

// const TitleCard = ({title, subTitle}) => {
//     return (
//         <Paper>
//             <Card
//                 sx={{
//                     padding: '0px',
//                     backgroundColor: 'rgba(255, 255, 255, 0.7)',
//                     backdropFilter: 'blur(10px)',
//
//                 }}>
//                 <CardHeader
//                     title={
//                         <Typography variant="h5" component="div">
//                             {title}
//                         </Typography>
//                     }
//                 />
//                 <CardContent sx={{padding: '8px'}}>
//                     <Box sx={{display: 'flex', alignItems: 'center'}}>
//                         <Typography variant="h2" component="div" sx={{flexGrow: 1, fontSize: '2.0rem'}}>
//                             {subTitle}
//                         </Typography>
//                     </Box>
//                 </CardContent>
//             </Card>
//         </Paper>
//     )
// }
//
// export default TitleCard;

const TitleCard = ({title, subTitle}) => {
    return (
        <Paper
            sx={{
                padding: '0px',
                backgroundColor: 'transparent', // Make the Paper background transparent
                boxShadow: 'none', // Remove any shadow if needed
            }}
        >
            <Card
                sx={{
                    padding: '0px',
                    backgroundColor: 'transparent', // Make the Card background transparent
                    backdropFilter: 'none', // Remove any backdrop filter if not needed
                    boxShadow: 'none', // Optionally remove the Card shadow as well
                }}
            >
                <CardHeader
                    title={
                        <Box sx={{display: 'flex', alignItems: 'center'}}>
                            <Typography variant="h3" component="div" sx={{flexGrow: 1}}>
                                {title}
                            </Typography>
                            <Typography variant="h2" component="div" sx={{fontSize: '2.0rem', fontWeight: 'bold'}}>
                                {subTitle}
                            </Typography>
                        </Box>
                    }
                />
            </Card>
        </Paper>
    );
};

export default TitleCard;
