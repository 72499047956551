import RouteList from "../../components/development/RouteList";
import React from "react";

// Was taken out for security reasons, enable temporarily for debugging


const Debugging = () => {
    return (
        <div>
            <h1 className="text-3xl">
                Debugging page
            </h1>
            {/*<RouteList/>*/}
        </div>
    )
}

export default Debugging
