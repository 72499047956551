import { Card, CardContent, CardHeader, CardActions, Grid,useMediaQuery, Paper, Box, Typography, Tooltip, IconButton } from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import PieChartFluktuation from '../charts/PieChartFluktuation';
import InfoIcon from '@mui/icons-material/InfoOutlined'

const BigPieChartCardFluktuation = ({ data, title, infoContent }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    // Definiere die Farben aus dem Theme
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart.yellow,
        theme.palette.chart.orange,
        theme.palette.chart.red,
        theme.palette.chart.pink,
        theme.palette.chart.purpleLight,
        theme.palette.chart.purpleDark,
    ];






    // Sortiere die Daten nach Verkaufsmenge in absteigender Reihenfolge und nimm die Top 5
   

    return (
            <Paper
                sx={{
                
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                }}
            >
                <Card
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%", // Nutze dynamicHeight zur Steuerung der Card-Höhe
                    width:  "100%",
                    padding: "0px",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    backdropFilter: "blur(10px)",
                }}>
                            <CardHeader
                                title={
                                <Typography
                                    variant={isSmallScreen ? "h6" : "h6"}
                                    component="div"
                                >
                                    {title}
                                </Typography>
                                }
                                action={
                                infoContent && (
                                    <Tooltip title={infoContent} arrow placement="top">
                                    <IconButton>
                                        <InfoOutlinedIcon />
                                    </IconButton>
                                    </Tooltip>
                                )
                                }
                            />
                            <CardContent
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    flexGrow: 1, // Damit das CardContent den verfügbaren Platz einnimmt
                                }}
                                >
                                <PieChartFluktuation data={data} />
                                </CardContent>

                        </Card>
                </Paper>
    );
};

export default BigPieChartCardFluktuation;
