import React, { useEffect, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Tabs, Tab, Box, IconButton } from '@mui/material';
import VariableTable from '../table/VariableTable';
import axiosInstance from '../../services/axiosInstance';
import TagesreportFilialeArtikelDetailPopup from './TagesreportFilialeArtikelDetailPopup'; // Import
import TagesreportArtikelFilialeDetailPopup from './TagesreportArtikelFilialeDetailPopup'; // Import
import { roundToDecimals } from "../../utils/mathStuff";
import CloseIcon from '@mui/icons-material/Close';

const TagesreportRetoureWarengruppeDetail = ({ open, handleClose, warengruppe, datum, verkaufsgebiet }) => { // Datum als Prop hinzufügen
    const [activeTab, setActiveTab] = useState(0);
    const [filialeData, setFilialeData] = useState([]);
    const [artikelData, setArtikelData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedFiliale, setSelectedFiliale] = useState(null);  // State for selected Filiale
    const [selectedArtikel, setSelectedArtikel] = useState(null);  // State for selected Artikel
    const [filialePopupOpen, setFilialePopupOpen] = useState(false);  // Popup state for Filiale
    const [artikelPopupOpen, setArtikelPopupOpen] = useState(false);  // Popup state for Artikel

    useEffect(() => {
        if (open && warengruppe && datum) {
            fetchFilialeAndArtikelData(); // Datum wird bei der API-Abfrage verwendet
        }
    }, [open, warengruppe, datum]);

    const fetchFilialeAndArtikelData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    warengruppenbezeichnung: warengruppe,  // Filter by the clicked warengruppe
                    startdatum: datum, // Datumsfilter hinzufügen
                    enddatum: datum,   // Das gleiche Datum für Start und Ende
                    verkaufsgebiet: verkaufsgebiet,
                }
            });

            if (Array.isArray(response.data.results)) {
                // Gruppierung der Daten nach Filiale
                const filialeMap = {};
                response.data.results.forEach(item => {
                    const filiale = item.filiale;
                    if (!filialeMap[filiale]) {
                        filialeMap[filiale] = {
                            filiale: filiale,
                            retourmenge: 0,
                            retourwert: 0,
                            lieferwert: 0,
                            korrekturwert: 0,
                        };
                    }
                    filialeMap[filiale].retourmenge += item.retourmenge || 0;
                    filialeMap[filiale].retourwert += item.retourwert || 0;
                    filialeMap[filiale].lieferwert += item.lieferwert || 0;
                    filialeMap[filiale].korrekturwert += item.korrekturwert || 0;
                });

                // Berechnung der Retourquote für jede Filiale
                const mappedFilialeData = Object.values(filialeMap)
                .filter(item => item.retourmenge > 0)
                .map(item => {
                    const lieferwertTotal = item.lieferwert + item.korrekturwert;
                    const retourquote = lieferwertTotal > 0 ? (item.retourwert / lieferwertTotal) * 100 : 0;
                    return {
                        Filiale: item.filiale,
                        Retourmenge: item.retourmenge,
                        Retourwert: `${roundToDecimals(item.retourwert || 0, 2)} €`,
                        Retourquote: `${retourquote.toFixed(2)} %`,
                    };
                });

                setFilialeData(mappedFilialeData);

                // Gruppierung der Daten nach Artikel
                const artikelMap = {};
                response.data.results.forEach(item => {
                    const artikel = item.artikel;
                    if (!artikelMap[artikel]) {
                        artikelMap[artikel] = {
                            artikel: artikel,
                            retourmenge: 0,
                            retourwert: 0,
                            lieferwert: 0,
                            korrekturwert: 0,
                        };
                    }
                    artikelMap[artikel].retourmenge += item.retourmenge || 0;
                    artikelMap[artikel].retourwert += item.retourwert || 0;
                    artikelMap[artikel].lieferwert += item.lieferwert || 0;
                    artikelMap[artikel].korrekturwert += item.korrekturwert || 0;
                });

                // Berechnung der Retourquote für jeden Artikel
                const mappedArtikelData = Object.values(artikelMap)
                .filter(item => item.retourmenge > 0)
                .map(item => {
                    const lieferwertTotal = item.lieferwert + item.korrekturwert;
                    const retourquote = lieferwertTotal > 0 ? (item.retourwert / lieferwertTotal) * 100 : 0;
                    return {
                        Artikel: item.artikel,
                        Retourmenge: item.retourmenge,
                        Retourwert: `${roundToDecimals(item.retourwert || 0, 2)} €`,
                        Retourquote: `${retourquote.toFixed(2)} %`,
                    };
                });

                setArtikelData(mappedArtikelData);
            } else {
                console.error("Response data is not an array", response.data);
            }
        } catch (error) {
            console.error('Error fetching Filiale and Artikel data:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleFilialeClick = (row) => {
        setSelectedFiliale(row.Filiale);  // Speichere die ausgewählte Filiale
        setFilialePopupOpen(true);  // Öffne das Popup
    };

    const handleArtikelClick = (row) => {
        setSelectedArtikel(row.Artikel);  // Speichere den ausgewählten Artikel
        setArtikelPopupOpen(true);  // Öffne das Popup
    };

    const filialeColumns = [
        { accessorKey: 'Filiale', header: 'Filiale', size: 200 },
        { accessorKey: 'Retourmenge', header: 'Retourmenge', size: 150 },
        { accessorKey: 'Retourwert', header: 'Retourwert (€)', size: 150 },
        { accessorKey: 'Retourquote', header: 'Retourquote (%)', size: 150 },
    ];

    const artikelColumns = [
        { accessorKey: 'Artikel', header: 'Artikel', size: 200 },
        { accessorKey: 'Retourmenge', header: 'Retourmenge', size: 150 },
        { accessorKey: 'Retourwert', header: 'Retourwert (€)', size: 150 },
        { accessorKey: 'Retourquote', header: 'Retourquote (%)', size: 150 },
    ];

    return (
        <>
            <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
                <DialogTitle>Warengruppe: {warengruppe} Details

                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <Tabs value={activeTab} onChange={handleTabChange} aria-label="Filiale and Artikel Tabs">
                        <Tab label="Filiale" />
                        <Tab label="Artikel" />
                    </Tabs>
                    <Box sx={{ padding: 2 }}>
                        {activeTab === 0 && (
                            <VariableTable
                                columns={filialeColumns}
                                data={filialeData}
                                loading={loading}
                                onRowClick={handleFilialeClick}  // Handle click on Filiale
                            />
                        )}
                        {activeTab === 1 && (
                            <VariableTable
                                columns={artikelColumns}
                                data={artikelData}
                                loading={loading}
                                onRowClick={handleArtikelClick}  // Handle click on Artikel
                            />
                        )}
                    </Box>
                </DialogContent>
                
            </Dialog>

            {/* Filiale Artikel Detail Popup */}
            {selectedFiliale && (
                <TagesreportFilialeArtikelDetailPopup
                    open={filialePopupOpen}
                    handleClose={() => setFilialePopupOpen(false)}
                    filiale={selectedFiliale}
                    warengruppe={warengruppe}
                    datum={datum}
                />
            )}

            {/* Artikel Filiale Detail Popup */}
            {selectedArtikel && (
                <TagesreportArtikelFilialeDetailPopup
                    open={artikelPopupOpen}
                    handleClose={() => setArtikelPopupOpen(false)}
                    artikel={selectedArtikel}
                    datum={datum}
                    verkaufsgebiet={verkaufsgebiet}
                />
            )}
        </>
    );
};

export default TagesreportRetoureWarengruppeDetail;
