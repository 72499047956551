import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography, Tabs, Tab, TextField, MenuItem } from '@mui/material';
import Layout from '../../../components/layout/Warensteuerung/warensteuerung'; // Passe den Pfad an
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Passe den Pfad an
import BarChartIcon from '@mui/icons-material/BarChart';
import axiosInstance from '../../../services/axiosInstance'; // Nutze deine axios-Instanz
import BigTableCard from '../../../components/card/BigTableCard'; // Importiere die BigTableCard-Komponente
import { tausenderTrennung, formatPercentageWithMultiplier, formatPercentageWithoutMultiplier } from '../../../utils/mathStuff'; // Importiere die Formatierungsfunktionen

const Abc = () => {
  const [tabIndex, setTabIndex] = useState(0); // To manage which tab is active
  const [dataABC, setDataABC] = useState([]); // For ABC tab
  const [dataFilialABC, setDataFilialABC] = useState([]); // For Filial-ABC tab
  const [loading, setLoading] = useState(false);
  const [filiale, setFiliale] = useState('');
  const [monat, setMonat] = useState('');
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [datumOptions, setDatumOptions] = useState([]);
  const [nextPage, setNextPage] = useState(null);

  // Tab change handler
  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Fetch options for Filiale and Monat
  const fetchOptions = async () => {
    try {
      const [filialeResponse, datumResponse] = await Promise.all([
        axiosInstance.get('stammdaten/unique-filiale/'),
        axiosInstance.get('stammdaten/unique-jahr-monat/'),
      ]);

      const filialeOpts = filialeResponse.data.map((item) => ({
        label: item.filiale,
        value: item.filiale, // Use 'filiale' instead of 'filialnummer'
      }));
      const datumOpts = datumResponse.data.map((item) => ({ label: item, value: item }));

      setFilialeOptions(filialeOpts);
      setDatumOptions(datumOpts);
    } catch (error) {
      console.error('Error fetching filter options:', error);
    }
  };

  // Fetch data for ABC (without Filiale)
const fetchDataABC = async () => {
    if (!monat) return; // Don't fetch if the month is not set
    setLoading(true);
    try {
      const response = await axiosInstance.get('/scorecard_month_article/abc-unternehmen/', {
        params: {
          jahrMonat: monat, // Pass the 'jahrMonat' parameter correctly to the API
        }
      });
      // Flatten the data and apply formatting
      let flattenedData = response.data.results.map((row) => ({
        ...row,
        artikel: row.artikelnummer ? row.artikelnummer.artikel : '', // Flach machen und sicherstellen, dass es existiert
        verkaufsmenge: tausenderTrennung(row.verkaufsmenge, 0), // Format Verkaufsmenge with thousand separators
        umsatz: tausenderTrennung(row.umsatz, 2), // Format Umsatz with thousand separators
        retourmenge: tausenderTrennung(row.retourmenge, 0), // Format Retourmenge with thousand separators
        ABCValue: row.ABCValue,
        tageVerkauft: row.tageVerkauft,
        umsatzProzent: formatPercentageWithMultiplier(row.umsatzProzent), // Format UmsatzProzent as a percentage
        kumUmsatz: formatPercentageWithoutMultiplier(row.kumUmsatz * 100), // Kumulierten Umsatz mit 100 multiplizieren und als Prozent anzeigen
      }));

      // Sort the data by Umsatz in descending order
      flattenedData = flattenedData.sort((a, b) => parseFloat(b.umsatz.replace('.', '').replace(',', '.')) - parseFloat(a.umsatz.replace('.', '').replace(',', '.')));

      setDataABC(flattenedData);
      setNextPage(response.data.next); // Capture the next page URL if there is one
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
};




  // Fetch data for Filial-ABC
  const fetchDataFilialABC = async () => {
    if (!filiale || !monat) return; // Don't fetch if filters are not set
    setLoading(true);
    try {
      const response = await axiosInstance.get('/scorecard_month_article/abc/', {
        params: {
          filiale, // Use 'filiale' instead of 'filialnummer'
          jahrMonat: monat, // Pass the 'jahrMonat' parameter correctly to the API
        }
      });
      // Flatten the data and apply formatting
      let flattenedData = response.data.results.map((row) => ({
        ...row,
        artikel: row.artikelnummer ? row.artikelnummer.artikel : '', // Flach machen und sicherstellen, dass es existiert
        warengruppe: row.warengruppe,
        verkaufsmenge: tausenderTrennung(row.verkaufsmenge, 0), // Format Verkaufsmenge with thousand separators
        umsatz: tausenderTrennung(row.umsatz, 2), // Format Umsatz with thousand separators
        retourmenge: tausenderTrennung(row.retourmenge, 0), // Format Retourmenge with thousand separators
        ABCValue: row.ABCValue,
        tageVerkauft: row.tageVerkauft,
        umsatzProzent: formatPercentageWithMultiplier(row.umsatzProzent), // Format UmsatzProzent as a percentage
        kumUmsatz: formatPercentageWithoutMultiplier(row.kumUmsatz * 100), // Kumulierten Umsatz mit 100 multiplizieren und als Prozent anzeigen
      }));

      // Sort the data by Umsatz in descending order
      flattenedData = flattenedData.sort((a, b) => parseFloat(b.umsatz.replace('.', '').replace(',', '.')) - parseFloat(a.umsatz.replace('.', '').replace(',', '.')));

      setDataFilialABC(flattenedData);
      setNextPage(response.data.next); // Capture the next page URL if there is one
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOptions(); // Fetch filter options when component mounts
  }, []);




  

  // Table columns definition for BigTableCard and VariableTable for both tabs
  const columns = [
    { header: 'ABC-Wert', accessorKey: 'ABCValue' },
    { header: 'Artikel', accessorKey: 'artikel' },
    { header: 'Umsatz €', accessorKey: 'umsatz' },
    { header: 'Verkaufsmenge Stk.', accessorKey: 'verkaufsmenge' },
    { header: 'Retourmenge Stk.', accessorKey: 'retourmenge' },
    { header: 'Umsatzanteil', accessorKey: 'umsatzProzent' },
    { header: 'Kumulierter Umsatz', accessorKey: 'kumUmsatz' }, // Korrekt formatierter kumulierter Umsatz
  ];

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2
          }}
        >
          <PageHeader
            title="ABC Analyse"
            subtitle="Dieser Bericht liefert eine detaillierte Analyse der ABC-Statistik sowohl auf Unternehmensebene als auch auf Filialebene."
            Icon={BarChartIcon}
          />
        </Box>

        {/* Tabs Section */}
        <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mt: 3 }}>
          <Tab label="ABC" />
          <Tab label="Filial-ABC" />
        </Tabs>

        {/* Tab content */}
        {tabIndex === 0 && (
          <Box>
            {/* Explanatory Text */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Bitte wähle einen Monat aus, um die ABC-Analyse auf Unternehmensebene zu generieren.
              </Typography>
            </Box>

            {/* Filter Section */}
            <Box sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
              <TextField
                label="Monat"
                value={monat}
                onChange={(e) => setMonat(e.target.value)}
                select
                size="small" // Smaller size for a more compact look
                sx={{ width: 150 }} // Smaller width for compact layout
              >
                {datumOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Button variant="contained" onClick={fetchDataABC} disabled={loading || !monat}>
                {loading ? <CircularProgress size={24} /> : 'Daten Abrufen'}
              </Button>
            </Box>

            {/* BigTableCard Component */}
            <Box sx={{ height: 400, width: '100%', mt: 4 }}>
              {Array.isArray(dataABC) && dataABC.length > 0 ? (
                <BigTableCard
                  title="ABC Unternehmensanalyse"
                  data={dataABC}
                  columns={columns}
                  onRowClick={(row) => console.log('Row clicked:', row)} // Optional click handler for rows
                  muiTableBodyCellProps={{ sx: { padding: '8px' } }} // Example of custom MUI props for styling
                />
              ) : (
                null // Keine Anzeige von Text, wenn keine Daten vorhanden sind
              )}
            </Box>

            {/* Button to load more data */}
            {nextPage && (
              <Button variant="outlined" onClick={fetchNextPage} sx={{ mt: 2 }}>
                Weitere Daten laden
              </Button>
            )}
          </Box>
        )}

        {tabIndex === 1 && (
          <Box>
            {/* Explanatory Text */}
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography variant="body1" sx={{ textAlign: 'left' }}>
                Bitte stelle hier ein, welche Filiale und welchen Monat du sehen möchtest, um die Filial-ABC-Analyse zu generieren.
              </Typography>
            </Box>

            {/* Filter Section */}
            <Box sx={{ display: 'flex', gap: 2, mt: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
              <TextField
                label="Filiale"
                value={filiale}
                onChange={(e) => setFiliale(e.target.value)}
                select
                size="small"
                sx={{ width: 200 }}
              >
                {filialeOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <TextField
                label="Monat"
                value={monat}
                onChange={(e) => setMonat(e.target.value)}
                select
                size="small"
                sx={{ width: 150 }} // Smaller width for compact layout
              >
                {datumOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>

              <Button variant="contained" onClick={fetchDataFilialABC} disabled={loading || !filiale || !monat}>
                {loading ? <CircularProgress size={24} /> : 'Daten Abrufen'}
              </Button>
            </Box>

            {/* BigTableCard Component */}
            <Box sx={{ height: 400, width: '100%', mt: 4 }}>
              {Array.isArray(dataFilialABC) && dataFilialABC.length > 0 ? (
                <BigTableCard
                  title="Filial-ABC Analyse"
                  data={dataFilialABC}
                  columns={columns}
                  onRowClick={(row) => console.log('Row clicked:', row)} // Optional click handler for rows
                  muiTableBodyCellProps={{ sx: { padding: '8px' } }} // Example of custom MUI props for styling
                />
              ) : (
                null // Keine Anzeige von Text, wenn keine Daten vorhanden sind
              )}
            </Box>

            {/* Button to load more data */}
            {nextPage && (
              <Button variant="outlined" onClick={fetchNextPage} sx={{ mt: 2 }}>
                Weitere Daten laden
              </Button>
            )}
          </Box>
        )}
      </Box>
    </Layout>
  );
};

export default Abc;
