import { Card, CardContent, CardHeader, CardActions, Grid, Paper, Box, Typography } from "@mui/material";
import React from "react";
import { useTheme } from '@mui/material/styles';
import PieChartWarengruppe from '../charts/PieChartWarengruppe';

const BigPieChartCard = ({ data, title }) => {
    const theme = useTheme();

    // Definiere die Farben aus dem Theme
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart.yellow,
        theme.palette.chart.orange,
        theme.palette.chart.red,
        theme.palette.chart.pink,
        theme.palette.chart.purpleLight,
        theme.palette.chart.purpleDark,
    ];






    // Sortiere die Daten nach Verkaufsmenge in absteigender Reihenfolge und nimm die Top 5
    const top5Data = data
        .sort((a, b) => b.Verkaufsmenge - a.Verkaufsmenge)
        .slice(0, 5);

    return (
                <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0px',
                    backgroundColor: 'rgba(255, 255, 255, 0.7)',
                    backdropFilter: 'blur(10px)',
                }}>
                    <CardHeader
                        title={
                            <Typography variant="h6" component="div">
                                {title}
                            </Typography>
                        }
                    />
                    <CardContent sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {data && data.length > 0 && (
                            <PieChartWarengruppe data={top5Data} />
                        )}
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center', paddingBottom: 2 }}>
                        <Grid container spacing={1} justifyContent="center">
                            {top5Data.map((item, index) => (
                                <Grid item key={item.Warengruppe}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Box
                                            sx={{
                                                width: 16,
                                                height: 16,
                                                backgroundColor: COLORS[index % COLORS.length],
                                                mr: 1,
                                                borderRadius: '50%',
                                            }}
                                        />
                                        <Typography variant="body2">{item.Warengruppe}</Typography>
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>
                    </CardActions>
                </Card>
    );
};

export default BigPieChartCard;
