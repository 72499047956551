import { Button } from "@mui/material";
import React from "react";

const VerkaufsgebietFilterSelection = ({ verkaufsgebietFilter, setVerkaufsgebietFilter, verkaufsgebietOptions }) => {
    return (
        <div>
            {/* 'Gesamt' Button */}
            <Button
                onClick={() => setVerkaufsgebietFilter(null)}
                variant={verkaufsgebietFilter === null ? 'contained' : 'outlined'}
                color={verkaufsgebietFilter === null ? 'primary' : 'inherit'}
                style={{ margin: '8px', padding: '8px 16px', borderRadius: '4px' }}
            >
                Gesamt
            </Button>

            {/* Verkaufsgebiete dynamisch basierend auf den verfügbaren Optionen */}
            {verkaufsgebietOptions.map(option => (
                <Button
                    key={option.value}
                    onClick={() => setVerkaufsgebietFilter(option)}
                    variant={verkaufsgebietFilter?.value === option.value ? 'contained' : 'outlined'}
                    color={verkaufsgebietFilter?.value === option.value ? 'primary' : 'inherit'}
                    style={{ margin: '8px', padding: '8px 16px', borderRadius: '4px' }}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    );
}

export default VerkaufsgebietFilterSelection;
