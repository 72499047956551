

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  IconButton,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  useMediaQuery,
  useTheme,
  Paper,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import BarChartVariableYAxis from '../../charts/BarChartVariableYAxis'; // Passe den Pfad entsprechend an

const BarChartCardFlexibleYAxis = ({
  data,
  dataKeys,
  xAxisKey,
  title,
  infoContent,
  height,
  width,
  barColors,
  legendNames,
  yAxisMin,
  yAxisMax,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [isFullscreen, setIsFullscreen] = useState(false);

  const toggleFullscreen = () => {
    setIsFullscreen((prev) => !prev);
  };

  const ChartContent = (
    <BarChartVariableYAxis
      data={data}
      dataKeys={dataKeys}
      xAxisKey={xAxisKey}
      yAxisMin={yAxisMin}
      yAxisMax={yAxisMax}
      height={height}
      barColors={barColors}
      legendNames={legendNames}
    />
  );

  return (
    <>
      <Paper sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Card
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: width || '100%',
            padding: '0px',
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
          }}
        >
          <CardHeader
            title={
              <Typography variant={isSmallScreen ? 'h6' : 'h6'} component="div">
                {title}
              </Typography>
            }
            action={
              <>
                {infoContent && (
                  <Tooltip title={infoContent} arrow placement="top">
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                )}
                <IconButton onClick={toggleFullscreen}>
                  <FullscreenIcon />
                </IconButton>
              </>
            }
          />
          <CardContent sx={{ flexGrow: 1, height: '100%', width: '100%' }}>
            {ChartContent}
          </CardContent>
        </Card>
      </Paper>

      {/* Vollbild-Dialog */}
      <Dialog
        open={isFullscreen}
        onClose={toggleFullscreen}
        maxWidth={false}
        fullScreen
      >
        <DialogTitle>
          {title}
          <IconButton
            aria-label="close"
            onClick={toggleFullscreen}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>{ChartContent}</DialogContent>
      </Dialog>
    </>
  );
};

BarChartCardFlexibleYAxis.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  dataKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
  xAxisKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  infoContent: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  barColors: PropTypes.arrayOf(PropTypes.string),
  legendNames: PropTypes.arrayOf(PropTypes.string),
  yAxisMin: PropTypes.number,
  yAxisMax: PropTypes.number,
};

export default BarChartCardFlexibleYAxis;
