import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

// Import der Date/Time-Picker-Komponenten
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/Frequenz/frequenz';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';
import BigTableCard from '../../../components/card/BigTableCard';
import DropdownFilter from '../../../components/filter/DropdownFilter'; // Pfad anpassen
import UpdateIcon from '@mui/icons-material/Update';

const ArtikelStatistik = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [columns, setColumns] = useState([]);

  // Filter states
  const [filiale, setFiliale] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [wochentag, setWochentag] = useState(null);
  const [artikelnummer, setArtikelnummer] = useState(null);

  const [filialeOptions, setFilialeOptions] = useState([]);
  const [artikelnummerOptions, setArtikelnummerOptions] = useState([]);
  const wochentagOptions = [
    { label: 'Montag', value: 'Montag' },
    { label: 'Dienstag', value: 'Dienstag' },
    { label: 'Mittwoch', value: 'Mittwoch' },
    { label: 'Donnerstag', value: 'Donnerstag' },
    { label: 'Freitag', value: 'Freitag' },
    { label: 'Samstag', value: 'Samstag' },
    { label: 'Sonntag', value: 'Sonntag' },
  ];

  const fetchData = async (pageNumber = 1) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(`/frequenz/artikelstatistik/`, {
        params: {
          page: pageNumber,
          filiale: filiale ? filiale.value : undefined,
          startdatum: dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
          enddatum: dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : undefined,
          wochentag: wochentag ? wochentag.value : undefined,
          artikelnummer: artikelnummer ? artikelnummer.value : undefined,
        },
      });

      // Gruppiere die Daten nach Uhrzeit und erstelle dynamische Spalten basierend auf den Datum-Werten
      const groupedData = {};
      const uniqueDates = new Set();

      response.data.results.forEach((row) => {
        const formattedDate = dayjs(row.datum).format('DD.MM.YYYY');
        uniqueDates.add(formattedDate);

        if (!groupedData[row.uhrzeit]) {
          groupedData[row.uhrzeit] = { uhrzeit: row.uhrzeit };
        }
        groupedData[row.uhrzeit][formattedDate] = row.verkaufsmenge;
      });

      // Daten nach Uhrzeiten sortieren
      const sortedData = Object.values(groupedData).sort((a, b) => {
        const timeA = dayjs(a.uhrzeit, 'HH:mm');
        const timeB = dayjs(b.uhrzeit, 'HH:mm');
        return timeA.isBefore(timeB) ? -1 : 1;
      });

      // Spalten dynamisch erstellen, basierend auf den einzigartigen Datumswerten
      const dynamicColumns = [
        { accessorKey: 'uhrzeit', header: 'Uhrzeit' },
        ...Array.from(uniqueDates)
          .sort((a, b) => dayjs(a, 'DD.MM.YYYY').isBefore(dayjs(b, 'DD.MM.YYYY')) ? -1 : 1)
          .map((date) => ({
            accessorKey: date,
            header: date,
          })),
      ];

      setColumns(dynamicColumns);
      setData(sortedData);
      setRowCount(response.data.count);
      setPage(pageNumber);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data on mount
    fetchFilialeOptions(); // Fetch filter options for Filiale
    fetchArtikelnummerOptions(); // Fetch filter options for Artikelnummer
  }, []);

  // Fetch options for Filiale filter
  const fetchFilialeOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-filiale/');
      setFilialeOptions(response.data.map((item) => ({
        label: item.filiale,
        value: item.filiale,
      })));
    } catch (error) {
      console.error('Error fetching filiale options:', error);
    }
  };

  // Fetch options for Artikelnummer filter
  const fetchArtikelnummerOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-artikel/');
      setArtikelnummerOptions(response.data.map((item) => ({
        label: item.artikel,
        value: item.artikelnummer,
      })));
    } catch (error) {
      console.error('Error fetching artikelnummer options:', error);
    }
  };

  // Reset Filters
  const resetFilters = () => {
    setFiliale(null);
    setDateRange([null, null]);
    setWochentag(null);
    setArtikelnummer(null);
    fetchData(1); // Optional: Reset to first page
  };

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
        <PageHeader
          title="Artikelfrequenz"
          subtitle="Dieser Bericht zeigt die Verkaufsmengenentwicklung der Artikel im Tagesverlauf."
          Icon={UpdateIcon}
        />
        </Box>

        {/* Filter Section */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 3 }}>
          {/* Filiale Filter */}
          <DropdownFilter
            label="Filiale"
            options={filialeOptions}
            value={filiale}
            onChange={setFiliale}
          />

          {/* Date Range Picker */}
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DateRangePicker
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              slots={{ field: SingleInputDateRangeField }}
              calendars={1}
              slotProps={{
                shortcuts: {
                  items: [
                    { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                    { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                    { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                    { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                    { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                    { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                    { label: 'Zurücksetzen', getValue: () => [null, null] },
                  ],
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minWidth: '250px',
                  fontSize: '0.875rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
            />
          </LocalizationProvider>

          {/* Wochentag Filter */}
          <DropdownFilter
            label="Wochentag"
            options={wochentagOptions}
            value={wochentag}
            onChange={setWochentag}
          />

          {/* Artikelnummer Filter */}
          <DropdownFilter
            label="Artikelnummer"
            options={artikelnummerOptions}
            value={artikelnummer}
            onChange={setArtikelnummer}
          />

          {/* Apply Filters Button */}
          <Button
            variant="contained"
            onClick={() => fetchData(1)}
            sx={{
              height: '40px',
              minWidth: '150px',
              fontSize: '0.875rem',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            Anwenden
          </Button>
          <Button
            variant="outlined"
            onClick={resetFilters}
            sx={{
              height: '40px',
              minWidth: '150px',
              fontSize: '0.875rem',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            Zurücksetzen
          </Button>
        </Box>

        {/* Table */}
        <Box sx={{ height: 500, width: '100%', mt: 3 }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <BigTableCard
              title="Verkaufsstatistik nach Artikel"
              data={data}
              columns={columns}
              onRowClick={(row) => console.log('Row clicked:', row)}
            />
          )}
        </Box>
      </Box>
    </Layout>
  );
};

export default ArtikelStatistik;
