import React, { useContext, useState, useEffect } from "react";
import {
  Drawer,
  TextField,
  Button,
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { FilterContext } from "../../../provider/FilterProvider";
import axiosInstance from "../../../services/axiosInstance";
import dayjs from "dayjs";
import Summary from "./Summary"; // Import der neuen Summary-Komponente
import { useAuth } from "../../../provider/authProvider";
import FilialeSummarySmall from '../../../components/documentation/FilialeSummarySmall';


const drawerWidth = 400;

const Documentation = ({ open, handleClose, category }) => {
  const { filterState } = useContext(FilterContext);
  const { user } = useAuth();
  const username = user?.username;
  const [filialnummer, setFilialnummer] = useState("");
  const [existingEntry, setExistingEntry] = useState(null);
  const [scoreUmsatz, setScoreUmsatz] = useState("");
  const [scoreKunden, setScoreKunden] = useState("");
  const [scoreKundenbon, setScoreKundenbon] = useState("");
  const [scoreLeistung, setScoreLeistung] = useState("");
  const [scoreKrankenquote, setScoreKrankenquote] = useState("");
  const [scoreBedarf, setScoreBedarf] = useState("");
  const [scoreAuslastung, setScoreAuslastung] = useState("");
  const [scoreFluktuation, setScoreFluktuation] = useState("");
  const [scoreWachstum, setScoreWachstum] = useState("");
  const [scoreVerfügbarkeit, setScoreVerfügbarkeit] = useState("");
  const [scoreRetoureA, setScoreRetoureA] = useState("");
  const [scoreRetoureBC, setScoreRetoureBC] = useState("");
  const [bemerkungUmsatz, setBemerkungUmsatz] = useState("");
  const [bemerkungKunden, setBemerkungKunden] = useState("");
  const [bemerkungKundenbon, setBemerkungKundenbon] = useState("");
  const [bemerkungLeistung, setBemerkungLeistung] = useState("");
  const [bemerkungKrankenquote, setBemerkungKrankenquote] = useState("");
  const [bemerkungBedarf, setBemerkungBedarf] = useState("");
  const [bemerkungAuslastung, setBemerkungAuslastung] = useState("");
  const [bemerkungFluktuation, setBemerkungFluktuation] = useState("");
  const [bemerkungWachstum, setBemerkungWachstum] = useState("");
  const [bemerkungVerfügbarkeit, setBemerkungVerfügbarkeit] = useState("");
  const [bemerkungRetoureA, setBemerkungRetoureA] = useState("");
  const [bemerkungRetoureBC, setBemerkungRetoureBC] = useState("");
  const [bemerkungSonstiges, setBemerkungSonstiges] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  const [isSummaryOpen, setIsSummaryOpen] = useState(false); // State für das Öffnen/Schließen des Summary-Dialogs

    // Initialisiere den Tab-Index basierend auf der Kategorie
    const getInitialTabIndex = () => {
      switch (category) {
        case "Performance":
          return 0;
        case "Personal":
          return 1;
        case "Ware":
          return 2;
        case "Sonstiges":
          return 3;
        default:
          return 0;
      }
    };
    const [mainTabIndex, setMainTabIndex] = useState(0);
    const [subTabIndex, setSubTabIndex] = useState(getInitialTabIndex()); // Setzt den initialen Tab
  
  

  // Verwende filterState.datum für currentMonth
  const currentMonth = dayjs(`${filterState.datum?.value}-01`);
  console.log("Userkomplett", user)
  console.log("Username", username)  



  useEffect(() => {
  const fetchFilialnummer = async () => {
    console.log("FilterState:", filterState); // Debugging
    if (filterState.filiale) {
      try {
        const response = await axiosInstance.get("/stammdaten/unique-filialnummer/", {
          params: { filiale: filterState.filiale.value },
        });
        if (response.data.filialnummer) {
          console.log("Filiale vor set", response.data.filialnummer);
          setFilialnummer(response.data.filialnummer);
          console.log("Filiale nach set", response.data.filialnummer);
          await checkForExistingEntry(response.data.filialnummer, filterState.datum.value);
          fetchScores();
        }
      } catch (error) {
        console.error("Fehler beim Laden der Filialnummer:", error);
      }
    } else {
      console.log("Keine Filiale im FilterState vorhanden");
    }
  };

  fetchFilialnummer();
}, [filterState.filiale, filterState.datum]);

  
  

  const checkForExistingEntry = async (filialnummer, datum) => {
    try {
      console.log("Filiale vor abfrage",filialnummer);
      const response = await axiosInstance.get("/scorecard_documentation/documentation/", {
        params: {
          filialnummer: filialnummer,
          datum: dayjs(datum, "YYYY.MM").format("YYYY-MM-DD"),
        },
      });

      if (response.data.results.length > 0) {
        const entry = response.data.results[0];
        setExistingEntry(entry);

        setBemerkungUmsatz(entry.bemerkungUmsatz || "");
        setBemerkungKunden(entry.bemerkungKunden || "");
        setBemerkungKundenbon(entry.bemerkungKundenbon || "");
        setBemerkungLeistung(entry.bemerkungLeistung || "");
        setBemerkungKrankenquote(entry.bemerkungKrankenquote || "");
        setBemerkungBedarf(entry.bemerkungBedarf || "");
        setBemerkungAuslastung(entry.bemerkungAuslastung || "");
        setBemerkungFluktuation(entry.bemerkungFluktuation || "");
        setBemerkungWachstum(entry.bemerkungWachstum || "");
        setBemerkungVerfügbarkeit(entry.bemerkungVerfügbarkeit || "");
        setBemerkungRetoureA(entry.bemerkungRetoureA || "");
        setBemerkungRetoureBC(entry.bemerkungRetoureBC || "");
      } else {
        setExistingEntry(null);
        setBemerkungUmsatz("");
        setBemerkungKunden("");
        setBemerkungKundenbon("");
        setBemerkungLeistung("");
        setBemerkungKrankenquote("");
        setBemerkungBedarf("");
        setBemerkungAuslastung("");
        setBemerkungFluktuation("");
        setBemerkungWachstum("");
        setBemerkungVerfügbarkeit("");
        setBemerkungRetoureA("");
        setBemerkungRetoureBC("");
      }
    } catch (error) {
      console.error("Fehler beim Überprüfen auf vorhandenen Eintrag:", error);
    }
  };

  const fetchScores = async () => {
    try {
      const params = {
        jahrMonat: filterState.datum?.value,
        filiale: filterState.filiale?.value,
      };

      const [performanceResponse, personalResponse, wareResponse] = await Promise.all([
        axiosInstance.get("scorecards_month/performance-kennzahl/", { params }),
        axiosInstance.get("scorecards_month/personal-kennzahl/", { params }),
        axiosInstance.get("scorecards_month/produkte-filiale/", { params }),
      ]);

      const performanceData = performanceResponse.data.results[0] || {};
      const personalData = personalResponse.data.results[0] || {};
      const wareData = wareResponse.data.results[0] || {};

      setScoreUmsatz(performanceData.scoreUmsatz || 0);
      setScoreKunden(performanceData.scoreKundenanzahl || 0);
      setScoreKundenbon(performanceData.scoreKundenbon || 0);
      setScoreLeistung(performanceData.scoreLeistung || 0);
      setScoreKrankenquote(personalData.scoreKrankenquote || 0);
      setScoreBedarf(personalData.scoreKöpfeDifferenzQuote || 0);
      setScoreAuslastung(personalData.scoreÜberstunden || 0);
      setScoreFluktuation(personalData.scoreFluktuation || 0);
      setScoreWachstum(wareData.scoreProduktwachstumGesamt || 0);
      setScoreVerfügbarkeit(wareData.scoreVerfügbarkeitFiliale || 0);
      setScoreRetoureA(wareData.scoreRetoureFilialeA || 0);
      setScoreRetoureBC(wareData.scoreRetoureFilialeBC || 0);
    } catch (error) {
      console.error("Fehler beim Abrufen der Scores:", error);
    }
  };

  const handleMainTabChange = (event, newIndex) => {
    setMainTabIndex(newIndex);
  };

  const handleSubTabChange = (event, newIndex) => {
    setSubTabIndex(newIndex);
  };

  const handleSubmit = async () => {
    try {
      const formattedDatum = filterState.datum
        ? dayjs(`${filterState.datum.value}-01`).format("YYYY-MM-DD")
        : null;
  
      const postData = {
        scoreUmsatz,
        bemerkungUmsatz,
        scoreKunden,
        bemerkungKunden,
        scoreKundenbon,
        bemerkungKundenbon,
        scoreLeistung,
        bemerkungLeistung,
        scoreKrankenquote,
        bemerkungKrankenquote,
        scoreBedarf,
        bemerkungBedarf,
        scoreAuslastung,
        bemerkungAuslastung,
        scoreFluktuation,
        bemerkungFluktuation,
        scoreWachstum,
        bemerkungWachstum,
        scoreVerfügbarkeit,
        bemerkungVerfügbarkeit,
        scoreRetoureA,
        bemerkungRetoureA,
        scoreRetoureBC,
        bemerkungRetoureBC,
        bemerkungSonstiges,
        datum: formattedDatum,
        user: username,
        filialnummer,
      };
  
      if (existingEntry) {
        const response = await axiosInstance.put(
          `/scorecard_documentation/documentation/${existingEntry.id}/`,
          postData
        );
  
        if (response.status === 200) {
          console.log("Daten erfolgreich aktualisiert:", response.data);
        }
      } else {
        const response = await axiosInstance.post(
          "/scorecard_documentation/documentation/",
          postData
        );
  
        if (response.status === 201) {
          console.log("Daten erfolgreich gespeichert:", response.data);
        }
      }
  
      handleClose();
    } catch (error) {
      // Hier werden detaillierte Fehlerinformationen geloggt
      console.error("Ein Fehler ist aufgetreten:", error);
  
      if (error.response) {
        console.error("Server-Antwortstatus:", error.response.status);
        console.error("Server-Fehlermeldung:", error.response.data);
      } else {
        console.error("Fehler ohne Serverantwort:", error.message);
      }
    }
  };
  

  const handlePreviousMonth = () => {
    setCurrentMonth((prev) => prev.subtract(1, "month"));
  };

  const handleNextMonth = () => {
    setCurrentMonth((prev) => prev.add(1, "month"));
  };

  const handleOpenSummary = () => {
    setIsSummaryOpen(true); // Öffnet den Dialog
  };

  const handleCloseSummary = () => {
    setIsSummaryOpen(false); // Schließt den Dialog
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      variant="persistent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
    >
      <Box sx={{ padding: 2 }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6">Dokumentation für den Monat {filterState.datum?.label}</Typography>
          <IconButton onClick={handleClose}>
            <ChevronRightIcon />
          </IconButton>
        </Box>

        <Tabs
          value={subTabIndex}
          onChange={handleSubTabChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          sx={{ marginTop: 2 }}
        >
          <Tab label="Performance" sx={{ fontSize: "0.875rem" }} />
          <Tab label="Personal" sx={{ fontSize: "0.875rem" }} />
          <Tab label="Ware" sx={{ fontSize: "0.875rem" }} />
          <Tab label="Sonstiges" sx={{ fontSize: "0.875rem" }} />
        </Tabs>


            {subTabIndex === 0 && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Umsatz:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreUmsatz}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Umsatz"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungUmsatz}
                  onChange={(e) => setBemerkungUmsatz(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Kunden:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreKunden}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Kunden"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungKunden}
                  onChange={(e) => setBemerkungKunden(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Kundenbon:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreKundenbon}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Kundenbon"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungKundenbon}
                  onChange={(e) => setBemerkungKundenbon(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Leistung:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreLeistung}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Leistung"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungLeistung}
                  onChange={(e) => setBemerkungLeistung(e.target.value)}
                />
              </>
            )}

            {subTabIndex === 1 && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Krankenquote:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreKrankenquote}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Krankenquote"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungKrankenquote}
                  onChange={(e) => setBemerkungKrankenquote(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Bedarf:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreBedarf}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Bedarf"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungBedarf}
                  onChange={(e) => setBemerkungBedarf(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Auslastung:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreAuslastung}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Auslastung"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungAuslastung}
                  onChange={(e) => setBemerkungAuslastung(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Fluktuation:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreFluktuation}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Fluktuation"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungFluktuation}
                  onChange={(e) => setBemerkungFluktuation(e.target.value)}
                />
              </>
            )}

            {subTabIndex === 2 && (
              <>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Wachstum:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreWachstum}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Wachstum"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungWachstum}
                  onChange={(e) => setBemerkungWachstum(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Verfügbarkeit:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreVerfügbarkeit}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Verfügbarkeit"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungVerfügbarkeit}
                  onChange={(e) => setBemerkungVerfügbarkeit(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Retoure A:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreRetoureA}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Retoure A"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungRetoureA}
                  onChange={(e) => setBemerkungRetoureA(e.target.value)}
                />

                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Retoure B/C:</Typography>
                  <Typography sx={{ marginLeft: 1 }}>{scoreRetoureBC}</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Retoure B/C"
                  type="text"
                  multiline
                  rows={4}
                  fullWidth
                  value={bemerkungRetoureBC}
                  onChange={(e) => setBemerkungRetoureBC(e.target.value)}
                />
              </>
            )}

            {subTabIndex === 3 && (
              <Box sx={{ marginTop: 2 }}>
                <Box sx={{ display: "flex", alignItems: "center", marginTop: 2 }}>
                  <Typography variant="h6">Sonstiges:</Typography>
                </Box>
                <TextField
                  margin="dense"
                  label="Bemerkung Sonstiges"
                  type="text"
                  fullWidth
                  multiline
                  rows={8} // Optional: Größe des Bemerkungsfeldes für Sonstiges
                  value={bemerkungSonstiges}
                  onChange={(e) => setBemerkungSonstiges(e.target.value)}
                />
              </Box>
            )}



        <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Speichern
          </Button>
        </Box>

        <Box sx={{ marginTop: 2 }}>
            <Button variant="contained" color="primary" onClick={handleOpenSummary}>
              Zusammenfassung anzeigen
            </Button>
          </Box>
      </Box>

      <FilialeSummarySmall
        open={isSummaryOpen}
        onClose={handleCloseSummary}
        datum={filterState.datum?.value} // Verwende das initiale Datum als String
        filteredResults={filteredResults}
        handlePreviousMonth={handlePreviousMonth}
        handleNextMonth={handleNextMonth}
        filialnummer={filialnummer || "Filiale"}
        filiale={filterState.filiale?.value || "Filiale"}
      />
    </Drawer>
  );
};

export default Documentation;
