import React, { useContext, useEffect, useState } from 'react';
import { Grid, Paper } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/FilialeMonat';
import axiosInstance from '../../../services/axiosInstance';
import Stack from '@mui/material/Stack';
import DataCard from '../../../components/card/DataCard';
import dayjs from 'dayjs';
import ComparisonCard from '../../../components/card/ComparisonCard';
import { FilterContext } from "../../../provider/FilterProvider";
import ScorecardsNotes from "../../../components/formular/Scorecards/ScorecardsNotes";
import BigTableCardPersonal from "../../../components/card/BigTableCardPersonal";
import EmployeeDetailsPopup from '../../../components/popup/EmployeeDetailsPopup';
import { Alert } from "@mui/material";
import { getAlertSeverity } from "../../../components/utility/AlertPopup";
import TAPersonalFluktuation from '../../../components/scorecards/tiefenanalyse/TAPersonalFluktuation';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import GroupRemove from '@mui/icons-material/GroupRemove';

dayjs.extend(isSameOrBefore);

const Fluktuation = () => {
    const [comparisonCardData, setComparisonCardData] = useState([]);
    const [dataCardData, setDataCardData] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { filterState } = useContext(FilterContext);
    const activeCategory = 'Fluktuation';

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchData();
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchData = async () => {
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const response = await axiosInstance.get('scorecards_month/personal-kennzahl/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value
                }
            });

            const dataCardData = response.data.results
                .slice(-4)
                .map(item => ({
                    x: item.datum,
                    Krankenquote: item.scoreKrankenquote,
                    Fluktuation: item.scoreFluktuation,
                    Bedarf: item.scoreKöpfeStunden,
                    Auslastung: item.scoreÜberstunden,
                }));

            const comparisonCardData = response.data.results.slice(-1).map(item => ({
                ...item,
                fluktuation6M: `${(item.fluktuation6M * 100).toFixed(2)}%`,  // Krankenquote in Prozent und auf 2 Dezimalstellen runden
                fluktuation6MOriginal: item.fluktuation6M,
            }));

            setComparisonCardData(comparisonCardData);
            setDataCardData(dataCardData);
        } catch (error) {
            console.error('An error occurred while fetching data:', error);
        }
    };

    const fetchTableData = async () => {
        try {
            const response = await axiosInstance.get('personaldaten/stammdaten-mitarbeiter/', {
                params: {
                    filiale: filterState.filiale?.value
                }
            });
  
            const filteredMonth = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
          
            const dataTableData = response.data.results
                .filter(item => {
                    const austrittDatum = item.austritt ? dayjs(item.austritt, 'YYYY-MM-DD') : null;
                    const eintrittDatum = item.eintritt ? dayjs(item.eintritt, 'YYYY-MM-DD') : null;
                    return (!austrittDatum || austrittDatum.isAfter(filteredMonth)) &&
                    (eintrittDatum.isSameOrBefore(filteredMonth));
                })
                .map(item => ({
                    mitarbeiterId: item.mitarbeiterId,
                    Name: item.name,
                    arbeitsbereich: item.arbeitsbereich,
                    arbeitnehmertyp: item.arbeitnehmertyp,
                    wochensollBerechnet: item.wochensollBerechnet,
                    kopf: item.kopf,
                    eintritt: dayjs(item.eintritt).format('DD.MM.YYYY'),  // Eintrittsdatum formatieren
                    austritt: item.austritt ? dayjs(item.austritt).format('DD.MM.YYYY') : '-',  // Austrittsdatum formatieren, falls vorhanden
                }));
  
            const newColumns = [
                { id: 'Name', accessorKey: 'Name', header: 'Name' },
                { id: 'arbeitnehmertyp', accessorKey: 'arbeitnehmertyp', header: 'Arbeitnehmertyp' },
                { id: 'wochensollBerechnet', accessorKey: 'wochensollBerechnet', header: 'Wochensoll h' },
                { id: 'eintritt', accessorKey: 'eintritt', header: 'Eintritt' },
                { id: 'austritt', accessorKey: 'austritt', header: 'Austritt' },
            ];
  
            setTableColumns(newColumns);
            setTableData(dataTableData);
  
        } catch (error) {
            console.error('An error occurred while fetching table data:', error);
        }
    };

    const handleRowClick = (employee) => {
        setSelectedEmployee(employee);
        setIsPopupOpen(true);
    };

    const handleClosePopup = () => {
        setIsPopupOpen(false);
        setSelectedEmployee(null);
    };

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" spacing={2} sx={{ overflowX: 'auto' }}>
                        {['Krankenquote', 'Fluktuation', 'Bedarf', 'Auslastung'].map((category, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                lg={3}
                                key={index}
                                className={activeCategory === category ? 'db_datacard_active' : 'db_datacard_not_active'}
                            >
                                <DataCard
                                    data={dataCardData}
                                    category={category}
                                    sector="personal"
                                    categoryData={
                                        activeCategory === category
                                            ? `${comparisonCardData.length > 0 ? comparisonCardData[0].fluktuation6M : null} `
                                            : ''
                                    }
                                />
                            </Grid>
                        ))}
                    </Stack>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Paper className="db_datacard_active_content">
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6} order={{ xs: 2, md: 1 }}>
                                <ComparisonCard
                                    zeitraum="Unternehmen"
                                    data={comparisonCardData}
                                    rangField="rangFluktuation"
                                    // percentageField="fluktuation6MOriginal"
                                    scoreField="scoreFluktuation"
                                    extraField1="fluktuation6MOriginal"
                                    extraLabel1="Fluktuation   "
                                    extraIcon1={<GroupRemove sx={{color: 'action.active'}}/>}
                                    TiefenanalyseComponent={TAPersonalFluktuation}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} order={{ xs: 1, md: 2 }}>
                                <Alert
                                    severity={getAlertSeverity(comparisonCardData.length > 0 ? comparisonCardData[0]["scoreFluktuation"] : null)}
                                >
                                    {comparisonCardData.length > 0 ? comparisonCardData[0]["feedbackFluktuation"] : 'Keine Rückmeldung verfügbar'}
                                </Alert>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} sx={{ mt: 2 }}>
                            <BigTableCardPersonal
                                title={"aktueller Personalstamm"}
                                data={tableData}
                                columns={tableColumns}
                                onRowClick={handleRowClick}
                            />
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            <ScorecardsNotes category="Personal" />

            <EmployeeDetailsPopup
                open={isPopupOpen}
                onClose={handleClosePopup}
                employee={selectedEmployee}
                jahrMonat={filterState.datum?.value}
            />
        </Layout>
    );
};

export default Fluktuation;
