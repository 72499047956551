import React, {useContext, useEffect, useState} from 'react';
import {Box, Button, ButtonGroup, Typography} from '@mui/material';
import axiosInstance from "../../../services/axiosInstance";
import {DndProvider} from 'react-dnd';
import {HTML5Backend} from 'react-dnd-html5-backend';
import dayjs from "dayjs";
import PageHeader from '../../../components/layout/Title/TitelSmall';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import ThekeHeader from "../../../components/header/ThekeHeader";
import ThekeGroup from "../../../components/theke/ThekeGroup";
import ArtikelListe from "../../../components/theke/ArtikelListe";
import ThekeSettingsPopup from "../../../components/popup/ThekeSettingsPopup";
import ThekeOverview from "../../../components/theke/ThekeOverview";
import {useLocation, useNavigate} from 'react-router-dom';
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import SettingsIcon from '@mui/icons-material/Settings';
import SaveIcon from '@mui/icons-material/Save';
import ViewListIcon from '@mui/icons-material/ViewList';
import {InfoBarContext} from "../../../provider/InfoBar";

const ThekeNewPage = () => {
    const [warengruppen, setWarengruppen] = useState([]);
    const [fetchedWarengruppen, setFetchedWarengruppen] = useState([]);
    const [allArtikel, setAllArtikel] = useState([]);
    const [initialLoading, setInitialLoading] = useState(true);
    const [additionalDataLoading, setAdditionalDataLoading] = useState(false);
    const [filter, setFilter] = useState(null);
    const [planStartDatum, setPlanStartDatum] = useState(null);
    const [planEndDatum, setPlanEndDatum] = useState(null);
    const [selectedArtikel, setSelectedArtikel] = useState([]);
    const [showOverview, setShowOverview] = useState(false);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [sectionCounts, setSectionCounts] = useState({});
    const [savingData, setSavingData] = useState(false)
    const [sortimentType, setSortimentType] = useState('standard');
    const [sortimentsID, setSortimentsID] = useState(null);
    const {showError, showSuccess} = useContext(InfoBarContext);


    const location = useLocation();
    const navigate = useNavigate();
    const aggDataEndDatum = dayjs().startOf('month'); // Start of the current month
    const aggDataStartDatum = aggDataEndDatum.subtract(6, 'months');
    const defaultSectionCount = 9

    // fetches all artikel, with teig, geschamck etc, and the warenguppen
    const fetchData = async () => {
        try {
            const [warengruppenResponse, thekeArtikel] = await Promise.all([
                axiosInstance.get('/stammdaten/stammdatenwarengruppe/'),
                axiosInstance.get('/theke/artikel-properties/')
            ]);

            const warengruppenData = warengruppenResponse.data.results;
            const thekeArtikelData = thekeArtikel.data.results || thekeArtikel.data;

            console.log("warengruppenData", warengruppenData)
            console.log("thekeArtikelData", thekeArtikelData)

            setAllArtikel(thekeArtikelData)

            if (Array.isArray(warengruppenData)) {
                setWarengruppen(warengruppenData.filter(warengruppe => warengruppe.warengruppennummer < 7));
            } else {
                console.error('Unerwartetes Antwortformat für Warengruppen:', warengruppenResponse.data);
                showError("Fehler beim Laden der Daten");
                setWarengruppen([]);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
            showError("Fehler beim Laden der Daten");
            setWarengruppen([]);
            setAllArtikel([]);
        }
    };

    // fetches existing data based on the ID provided and then set the selected artikel to that
    const fetchExistingArtikel = async (ID) => {
        console.log("fetching selected artikel", ID)
        try {
            const response = await axiosInstance.get(`/theke/sortiment-plans/${ID}/`);
            const responseSortimentPlan = response.data.warengruppen_plans;

            console.log("raw sortiments response", responseSortimentPlan);

            const fetchedSectionCounts = responseSortimentPlan
                .filter(item => item.slot_count !== defaultSectionCount)
                .reduce((acc, item) => ({
                    ...acc,
                    [item.warengruppennummer]: item.slot_count
                }), {});
            console.log("fetched section counts", fetchedSectionCounts);
            setSectionCounts(fetchedSectionCounts)

            // First group by warengruppe
            const groupedByWarengruppe = responseSortimentPlan.reduce((acc, warengruppe) => {
                acc[warengruppe.warengruppennummer] = warengruppe.artikel;
                return acc;
            }, {});

            // Then process each group into final format
            const finalSelectedArtikel = Object.entries(groupedByWarengruppe).map(([warengruppennummer, artikel]) => ({
                id: parseInt(warengruppennummer),
                artikel: artikel.map(wArtikel => {
                    const artikelData = allArtikel.find(artikel => artikel.artikelnummer === wArtikel.artikelnummer);
                    return {
                        // TODO This never seems to evaluate to true
                        artikelData: artikelData ? {...artikelData} : {
                            artikelnummer: wArtikel.artikelnummer,
                            artikel: `${wArtikel.artikelnummer} ${wArtikel.artikel_name}`,
                            artikelbezeichnung: wArtikel.artikel_name
                        },
                    };
                })
            }));
            console.log("final existing artikel", finalSelectedArtikel)
            setSelectedArtikel(finalSelectedArtikel);
        } catch (error) {
            console.error('Fehler beim Laden der Daten:', error);
            showError("Fehler beim Laden der Daten");
            setAllArtikel([]);
        }
    }

    // Init function to fetch data based on the query params
    const initializeData = async () => {
        setInitialLoading(true);
        const params = new URLSearchParams(location.search);
        const start = params.get('startDate');
        const end = params.get('endDate');
        const ID = params.get('id');
        // if a date is already set
        if (start && end) {
            setPlanStartDatum(dayjs(start));
            setPlanEndDatum(dayjs(end));
        }
        if (ID) {
            setSortimentsID(ID);
        }
        await fetchData();
    };

    useEffect(() => {
        initializeData();
    }, [location.search]);

    useEffect(() => {
        if (sortimentsID) {
            fetchExistingArtikel(sortimentsID);
        } else {
            // if no ID given, initialize selectedArtikel with empty arrays
            setSelectedArtikel(
                warengruppen.map(wg => ({id: wg.warengruppennummer, artikel: []}))
            );
        }
        console.log("finished init")
        setInitialLoading(false);
    }, [warengruppen, sortimentsID]);

    async function fetchWarengruppenArtikel({warengruppennummer}) {
        setAdditionalDataLoading(true);

        try {
            const response = await axiosInstance.get('/fact/aggregated/by-week-warengruppe-artikel/', {
                params: {
                    start_date: aggDataStartDatum.format('YYYY-MM-DD'),
                    end_date: aggDataEndDatum.format('YYYY-MM-DD'),
                    warengruppennummer: JSON.stringify(warengruppennummer),
                }
            });

            const weekData = response.data.results || response.data;

            // Aggregate data in a single pass
            const processedData = weekData.reduce((acc, item) => {
                const key = item.artikelnummer__artikelbezeichnung;
                if (!acc[key]) {
                    acc[key] = {
                        artikelbezeichnung: key,
                        total_umsatz: 0,
                        total_retourwert: 0,
                        total_lieferwert: 0,
                        total_korrekturwert: 0,
                        count: 0
                    };
                }

                const entry = acc[key];
                entry.total_umsatz += item.total_umsatz;
                entry.total_retourwert += item.total_retourwert;
                entry.total_lieferwert += item.total_lieferwert;
                entry.total_korrekturwert += item.total_korrekturwert;
                entry.count++;
                return acc;
            }, {});

            // Convert processedData to a Map for O(1) access
            const newArtikelMap = new Map();
            for (const item of Object.values(processedData)) {
                newArtikelMap.set(item.artikelbezeichnung, item);
            }

            // Reusable function to merge and calculate data for allArtikel
            const mergeAllArtikel = (artikelList) => {
                return artikelList
                    .map(oldArtikel => {
                        const newArtikel = newArtikelMap.get(oldArtikel.artikelbezeichnung);
                        if (!newArtikel) {
                            return {
                                ...oldArtikel,
                                umsatz: oldArtikel.umsatz || 0,
                                retoure: oldArtikel.retoure || 0,
                            };
                        }
                        return {
                            ...oldArtikel,
                            umsatz: Math.round(newArtikel.total_umsatz / newArtikel.count),
                            retoure: Math.round(
                                (newArtikel.total_retourwert /
                                    (newArtikel.total_lieferwert + newArtikel.total_korrekturwert)) * 1000
                            ) / 10,
                        };
                    })
                    .sort((a, b) => b.umsatz - a.umsatz);
            };

            // THis looks really wierd and complicated.
            const mergeSelectedArtikel = (selectedArtikelList) => {
                return selectedArtikelList.map(item => {
                    return {
                        ...item,
                        artikel: item.artikel.map(artikelItem => {
                            return {
                                ...artikelItem,
                                artikelData: {
                                    ...artikelItem.artikelData,
                                    artikel: artikelItem.artikelData.artikel,
                                    umsatz: (() => {
                                        const newArtikel = newArtikelMap.get(artikelItem.artikelData.artikelbezeichnung);
                                        return newArtikel
                                            ? Math.round(newArtikel.total_umsatz / newArtikel.count)
                                            : artikelItem.artikelData.umsatz || 0;
                                    })(),
                                    retoure: (() => {
                                        const newArtikel = newArtikelMap.get(artikelItem.artikelData.artikelbezeichnung);
                                        return newArtikel
                                            ? Math.round(
                                            (newArtikel.total_retourwert /
                                                (newArtikel.total_lieferwert + newArtikel.total_korrekturwert)) *
                                            1000
                                        ) / 10
                                            : artikelItem.artikelData.retoure || 0;
                                    })(),
                                },
                            };
                        }),
                    };
                });
            };

            // console.time("merge data");
            const mergedData = mergeAllArtikel(allArtikel);
            // console.log("before merge", selectedArtikel)
            const mergedSelectedArtikel = mergeSelectedArtikel(selectedArtikel);
            // console.timeEnd("merge data");

            // console.log("mergedartikel", mergedSelectedArtikel)

            // Update state
            setAllArtikel(mergedData);
            setSelectedArtikel(mergedSelectedArtikel);
            setFetchedWarengruppen(prev => [...prev, warengruppennummer]);
            // console.timeEnd("start")

        } catch (error) {
            console.error("Fehler beim Laden der Daten", error);
        } finally {
            setAdditionalDataLoading(false);
        }
    }

    const handleSectionCountChange = (warengruppeId, count) => {
        console.log("sectioncounts", sectionCounts)
        setSectionCounts(prev => ({...prev, [warengruppeId]: count}));
    };

    const handleDrop = (warengruppeId, artikelData) => {
        // console.log("dropped artikel", artikelData)
        console.log("selected Artikel", selectedArtikel)
        setSelectedArtikel(prevSelected =>
            prevSelected.map(wg =>
                wg.id === warengruppeId
                    ? {...wg, artikel: [...wg.artikel, artikelData]}
                    : wg,
            ),
        );
    };

    const handleWarengruppenChange = async (warengruppennummer) => {
        if (!additionalDataLoading && !initialLoading) {
            setFilter(warengruppennummer);
            setShowOverview(false);
            // only fetch new Data if not already fetched
            if (!fetchedWarengruppen.includes(warengruppennummer)) {
                await fetchWarengruppenArtikel({warengruppennummer: warengruppennummer});
            }
        } else {
            console.log("else")
            showError("Bitte warten Sie, bis die Daten geladen sind.");
        }
    }

    const handleSave = async () => {
        if (savingData) {
            return
        }
        setSavingData(true);
        if (!planStartDatum || !planEndDatum) {
            console.error("Startdatum oder Enddatum ist nicht gesetzt.");
            showError("Datum nicht gesetzt")
            setSavingData(false)
            return;
        }

        const sortimentsPlanData = {
            start_datum: planStartDatum.format('YYYY-MM-DD'),
            end_datum: planEndDatum.format('YYYY-MM-DD'),
            global_plan: true,
            filialnummer: null,
            warengruppen_plans: warengruppen.map(wg => {
                const selected = selectedArtikel.find(sa => sa.id === wg.warengruppennummer);

                return {
                    warengruppennummer: wg.warengruppennummer,
                    slot_count: sectionCounts[wg.warengruppennummer] || 9,
                    artikel: selected?.artikel.map(artikelWrapper => {
                        const artikelData = artikelWrapper.artikelData;
                        return {
                            artikelnummer: artikelData?.artikelnummer,
                            artikel_name: artikelData?.artikelbezeichnung,
                            angebot: false
                        };
                    }) || []
                };
            })
        };

        console.log("On save sortiments plan data", sortimentsPlanData )

        try {
            if (sortimentsID) {
                console.log("save with soritmentsID")
                await axiosInstance.put(`/theke/sortiment-plans/${sortimentsID}/`, sortimentsPlanData);
            } else {
                console.log("save withOUT soritmentsID")
                await axiosInstance.post('/theke/sortiment-plans/', sortimentsPlanData);
            }
            showSuccess('Sortimentsplan erfolgreich gespeichert.');
        } catch (error) {
            console.error('Fehler beim Speichern:', error);
            showError('Fehler beim Speichern der Daten.');
        } finally {
            setSavingData(false);
        }
    };

    return (
        <SortimentLayout>
            <Box sx={{p: 1, mr: 2}}>
                <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 2}}>
                    <PageHeader
                        title="Sortimentsplanung"
                        subtitle="Dieses Modul unterstützt dich bei der Sortimentsplanung."
                        Icon={StorefrontOutlinedIcon}
                    />
                </Box>

                <Box sx={{mb: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                setShowOverview(true);
                                setFilter(null);
                            }}
                            sx={{mr: 2}}
                        >
                            <ViewListIcon/>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setSettingsOpen(true)}
                            sx={{mr: 2}}
                        >
                            <SettingsIcon/>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSave}
                            disabled={savingData}
                            sx={{mr: 2}}
                        >
                            <SaveIcon/>
                        </Button>
                        <Box>
                            {planStartDatum && planStartDatum ? (
                                <Typography variant="body1" sx={{fontSize: '1.35rem', padding: '3px'}}>
                                    {planStartDatum.format('DD.MM.YYYY')} - {planEndDatum.format('DD.MM.YYYY')}
                                </Typography>
                            ) : (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => navigate('/sortiment/overview/kalender')}
                                >
                                    <CalendarTodayIcon/>
                                </Button>
                            )}
                        </Box>
                    </Box>
                    <ButtonGroup
                        variant="contained"
                        color="inherit"
                        aria-label="sortiment type selection"
                    >
                        <Button
                            onClick={() => setSortimentType('alles')}
                            sx={{
                                backgroundColor: sortimentType === 'alles' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                '&:hover': {
                                    backgroundColor: sortimentType === 'alles' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                                },
                                boxShadow: 'none',
                                color: 'black',
                            }}
                        >
                            Alles
                        </Button>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Button
                                onClick={() => setSortimentType('standard')}
                                sx={{
                                    backgroundColor: sortimentType === 'standard' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: sortimentType === 'standard' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                                    },
                                    boxShadow: 'none', // Remove shadow
                                    color: 'black', // Keep text color black
                                }}
                            >
                                Standard Sortiment
                            </Button>
                            <Box sx={{width: '100%', height: '2px', backgroundColor: 'blue'}}></Box>
                        </Box>
                        <Box sx={{display: 'flex', flexDirection: 'column'}}>
                            <Button
                                onClick={() => setSortimentType('wechsel')}
                                sx={{
                                    backgroundColor: sortimentType === 'wechsel' ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                                    '&:hover': {
                                        backgroundColor: sortimentType === 'wechsel' ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                                    },
                                    boxShadow: 'none', // Remove shadow
                                    color: 'black', // Keep text color black
                                }}
                            >
                                Wechsel Sortiment
                            </Button>
                            <Box sx={{width: '100%', height: '2px', backgroundColor: 'orange'}}></Box>
                        </Box>
                    </ButtonGroup>
                </Box>

                <ThekeHeader
                    warengruppen={warengruppen}
                    filter={filter}
                    handleWarengruppenChange={handleWarengruppenChange}
                />
                <DndProvider backend={HTML5Backend}>
                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%'}}>
                            {showOverview ? (
                                <ThekeOverview
                                    warengruppen={warengruppen}
                                    sectionCounts={sectionCounts}
                                    handleDrop={handleDrop}
                                    selectedArtikel={selectedArtikel}
                                    setFilter={setFilter}
                                    setShowOverview={setShowOverview}
                                />
                            ) : filter ? (
                                <Box>
                                    <Box sx={{display: 'flex', flexDirection: 'column', mb: 2}}>
                                        {warengruppen
                                            .filter(warengruppe => warengruppe.warengruppennummer === filter)
                                            .map(warengruppe => (
                                                <ThekeGroup
                                                    key={warengruppe.warengruppennummer}
                                                    sectionCount={sectionCounts[warengruppe.warengruppennummer] || 9}
                                                    onDrop={artikelData => handleDrop(warengruppe.warengruppennummer, artikelData)}
                                                    sortimentType={sortimentType}
                                                    selectedArtikel={selectedArtikel.find(
                                                        wg => wg.id === warengruppe.warengruppennummer,
                                                    )?.artikel || []}
                                                />
                                            ))}
                                    </Box>
                                    <Box>
                                        <ArtikelListe
                                            artikel={allArtikel.filter(artikel => artikel.warengruppennummer === filter)}
                                        />
                                    </Box>
                                </Box>
                            ) : (
                                <Box sx={{display: 'flex', justifyContent: 'center', width: '100%', mt: 4}}>
                                    <Typography variant="h6">
                                        Bitte wählen Sie eine Warengruppe aus
                                    </Typography>
                                </Box>
                            )}
                        </Box>
                        <ThekeSettingsPopup
                            open={settingsOpen}
                            onClose={() => setSettingsOpen(false)}
                            warengruppen={warengruppen}
                            sectionCounts={sectionCounts}
                            onSectionCountChange={handleSectionCountChange}
                        />
                    </Box>
                </DndProvider>
            </Box>
        </SortimentLayout>
    );
};

export default ThekeNewPage;
