import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, ListItemText, CardHeader, ListItemIcon, CircularProgress, Divider, IconButton, Dialog, DialogTitle, DialogContent, DialogActions, Button, Card, CardContent, Switch } from '@mui/material';
import axiosInstance from '../../services/axiosInstance';
import ChecklistIcon from '@mui/icons-material/Checklist';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import FilialcheckHinzufuegen from './FilialcheckHinzufuegen';
import FilialcheckKonfiguration from '../Filialcheck/FilialcheckKofiguration';

const FilialchecksListe = ({ refresh }) => {
    const [filialchecks, setFilialchecks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);  
    const [editDialogOpen, setEditDialogOpen] = useState(false);  
    const [selectedCheck, setSelectedCheck] = useState(null);  

    const fetchFilialchecks = async () => {
        try {
            const response = await axiosInstance.get('/filialcheck/checks/');
            if (Array.isArray(response.data)) {
                setFilialchecks(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setFilialchecks(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Filialchecks:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilialchecks();
    }, [refresh]);

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        fetchFilialchecks(); // Aktualisiere die Liste nach dem Schließen des Dialogs
    };

    const handleEditDialogOpen = (check) => {
        setSelectedCheck(check);  
        setEditDialogOpen(true);  
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        fetchFilialchecks(); // Aktualisiere die Liste nach dem Schließen des Bearbeitungsdialogs
    };

    const handleDelete = async (id) => {
        const confirmed = window.confirm("Möchtest du diesen Filialcheck und alle zugehörigen Einträge wirklich löschen?");
        if (confirmed) {
            try {
                await axiosInstance.delete(`/filialcheck/checks/${id}/`);  // DELETE-Request an das Backend
                
                // Filtere den gelöschten Check aus der Liste heraus, ohne die Seite neu zu laden
                setFilialchecks(filialchecks.filter(check => check.id !== id));
            } catch (error) {
                console.error('Fehler beim Löschen des Filialchecks:', error);
            }
        }
    };

    const handleToggleActive = async (check, event) => {
        event.stopPropagation(); // Verhindert das Öffnen des Bearbeitungsdialogs
        try {
            await axiosInstance.patch(`/filialcheck/checks/${check.id}/`, { is_active: !check.is_active });  // Aktiv/Passiv Toggle
            // Aktualisiere den Status des Checks in der Liste
            setFilialchecks(filialchecks.map(c => c.id === check.id ? { ...c, is_active: !check.is_active } : c));
        } catch (error) {
            console.error('Fehler beim Ändern des Status:', error);
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
                padding: 2,
            }}
        >
            <Card sx={{ width: '100%' }}>
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            Liste der Filialchecks
                        </Typography>
                    }
                    action={
                        <Button
                            variant="outlined"
                            color="primary"
                            size="small"
                            startIcon={<AddCircleIcon />}
                            onClick={handleDialogOpen}
                        >
                            Filialcheck hinzufügen
                        </Button>
                    }
                />
                <CardContent>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {Array.isArray(filialchecks) && filialchecks.length > 0 ? (
                            filialchecks.map((check) => (
                                <React.Fragment key={check.id}>
                                    <ListItem>  
                                        <ListItemIcon>
                                            <ChecklistIcon />
                                        </ListItemIcon>
                                        <ListItemText 
                                            primary={check.name} 
                                            secondary={check.is_active ? "Aktiv" : "Passiv"} 
                                        />
                                        <Switch
                                            edge="end"
                                            checked={check.is_active}
                                            onChange={(e) => handleToggleActive(check, e)} 
                                            inputProps={{ 'aria-label': 'Aktiv/Passiv umschalten' }}
                                        />
                                        <IconButton edge="end" aria-label="edit" onClick={(e) => {e.stopPropagation(); handleEditDialogOpen(check);}}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton edge="end" aria-label="delete" onClick={(e) => {e.stopPropagation(); handleDelete(check.id);}}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </ListItem>
                                    <Divider />
                                </React.Fragment>
                            ))
                        ) : (
                            <Typography variant="h6">Keine Filialchecks gefunden</Typography>
                        )}
                    </List>
                </CardContent>
            </Card>
            
            <FilialcheckHinzufuegen 
                open={dialogOpen} 
                handleClose={handleDialogClose} 
            />

            <Dialog
                open={editDialogOpen}
                onClose={handleEditDialogClose}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Filialcheck Konfiguration</DialogTitle>
                <DialogContent>
                    {selectedCheck && (
                        <FilialcheckKonfiguration
                            open={editDialogOpen}
                            handleClose={handleEditDialogClose}
                            checkId={selectedCheck.id}  
                            checkName={selectedCheck.name}  
                        />
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleEditDialogClose} color="primary">
                        Schließen
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default FilialchecksListe;
