import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from "recharts";
import { useTheme } from "@mui/material/styles";
import { Typography, Box } from "@mui/material";
import { tausenderTrennung } from '../../utils/mathStuff';

// Custom tooltip component
const CustomTooltip = ({ active, payload, totalUmsatz }) => {
    if (active && payload && payload.length) {
        const { name, value } = payload[0];
        const formattedValue = tausenderTrennung(value, 0); // Format the value with thousand separators
        const percentage = ((value / totalUmsatz) * 100).toFixed(2); // Calculate percentage

        return (
            <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.7)', padding: '8px', border: '1px solid #ccc', borderRadius: '4px' }}>
                <Typography variant="body2">{name}</Typography>
                <Typography variant="body2">{`Umsatz: ${formattedValue}`}</Typography>
                <Typography variant="body2">{`Anteil am Gesamtumsatz: ${percentage}%`}</Typography>
            </Box>
        );
    }

    return null;
};

const PieChartWarengruppe = ({ data }) => {
    const theme = useTheme();

    // Define colors from the theme
    const COLORS = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart?.yellow || "#FFEB3B", // Fallback color if not defined
        theme.palette.chart?.orange || "#FF9800",
        theme.palette.chart?.red || "#F44336",
        theme.palette.chart?.pink || "#E91E63",
        theme.palette.chart?.purpleLight || "#BA68C8",
        theme.palette.chart?.purpleDark || "#8E24AA",
    ];

    // Prepare data for the pie chart with percentages
    const totalUmsatz = data.reduce((acc, item) => acc + item.Umsatz, 0);

    const chartData = data.map((item, index) => ({
        name: item.Warengruppe,
        value: item.Umsatz,
        color: COLORS[index % COLORS.length],
    }));

    return (
        <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer>
                <PieChart>
                    <Pie
                        data={chartData}
                        dataKey="value"
                        nameKey="name"
                        cx="50%"
                        cy="50%"
                        innerRadius="40%"
                        outerRadius="80%"
                        paddingAngle={2}
                        labelLine={false} // Hide label lines for a cleaner look
                    >
                        {chartData.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color} />
                        ))}
                    </Pie>
                    <Tooltip content={<CustomTooltip totalUmsatz={totalUmsatz} />} />
                </PieChart>
            </ResponsiveContainer>
        </div>
    );
};

export default PieChartWarengruppe;