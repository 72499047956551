import React, {useEffect, useState} from "react";
import axiosInstance from "../../../services/axiosInstance";
import {Box, Button, Container, IconButton, Paper, Typography} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import DropdownFilter from "../../../components/filter/DropdownFilter";
import {useNavigate} from "react-router-dom"; // Added `useSearchParams`
import SettingsIcon from "@mui/icons-material/Settings";
import NutzerPopup from './NutzerPopup';
import SettingsLayout from "../../../components/layout/settings/SettingsLayout";
import {useAuth} from "../../../provider/authProvider"; // Import the popup component
import Tooltip from '@mui/material/Tooltip'; // Add this import

/*
A user has:
- username
- actual name?
- password
- role (modules + submodules)
- filialen
- Verkaufsgebiete
- email ?

I can
- create a new user
- delete a user (not myself)
- change a user's role (not myself)
- change a user's password ?
- change a user's email ?
- change users filialen (not myself?)
- change users Verkaufsgebiete (not myself?)

I can't
- change a user's username


ui:
display all users with their roles?
or select a single user and configure it?
both?
 */

const NutzerVerwalten = () => {
    const [users, setUsers] = useState([]);  // saves all users and their roles
    const [roleOptions, setRoleOptions] = useState([])
    const [snackbarOpen, setSnackbarOpen] = useState(false);  // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState('');  // Message to display in Snackbar
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');  // Severity of the Snackbar
    const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected user
    const [isCreatingUser, setIsCreatingUser] = useState(false);
    const { user: currentUser } = useAuth(); // Add this line to get the current user
    const navigate = useNavigate();

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axiosInstance.get('/auth/users/');
            const filteredUsers = response.data;
            console.log("users data", filteredUsers);
            setUsers(filteredUsers);
        } catch (error) {
            console.error("Error while fetching users", error.message);
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await axiosInstance.get('/auth/roles/');
            setRoleOptions(response.data.map(item => ({label: item.name, value: item.name, name: item.name})));
        } catch (error) {
            console.error("Error while fetching roles", error.message);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleRoleChange = async (newValue, userId) => {
        if (!newValue || !userId) return;

        console.log("userId", userId)

        if (userId === currentUser.id) {
            setSnackbarMessage("Sie können Ihre eigene Rolle nicht ändern");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        try {
            // Find the user in the users array
            const userToUpdate = users.find(user => user.id === userId);
            if (!userToUpdate) {
                throw new Error("User not found");
            }

            // Prepare the data to send
            const userData = {
                username: userToUpdate.username,
                email: userToUpdate.email,
                first_name: userToUpdate.first_name,
                last_name: userToUpdate.last_name,
                role_name: newValue.label  // Send role name
            };

            const response = await axiosInstance.put(`/auth/users/${userId}/`, userData);
            if (response.status === 200) {
                setSnackbarMessage("Rolle erfolgreich aktualisiert");
                setSnackbarSeverity("success");
                setSnackbarOpen(true);
                // Update the local state to reflect the change
                setUsers(users.map(user =>
                    user.id === userId ? { ...user, role: { name: newValue.label } } : user
                ));
            }
        } catch (error) {
            console.error("Error updating user role:", error);
            setSnackbarMessage("Fehler beim Aktualisieren der Rolle");
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
        }
    };

    const handleSettingsClick = (user) => {
        setSelectedUser(user); // Set the selected user to pass to the popup
    };

    return (
        <SettingsLayout>
            <Container maxWidth="md" sx={{ maxWidth: '700px !important' }}>
                <Paper>
                    <Box>
                        <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                            Nutzer verwalten
                        </Typography>
                        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setSelectedUser(null);
                                    setIsCreatingUser(true);
                                }}
                                sx={{mt: 1, mb: 1, p: 1}}
                            >
                                Neuen Nutzer hinzufügen
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => navigate("/settings/rechte/rollen-verwalten")}
                                sx={{mt: 1, mb: 1, p: 1}}
                            >
                                Rollen verwalten
                            </Button>
                        </Box>
                    </Box>

                    <Box sx={{mt: 2, display: 'flex', flexDirection: 'column'}}>
                        {users.map((user) => (
                            <Box key={user.id} sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mb: 1,
                                p: 1,
                                border: '1px solid #ddd',
                                borderRadius: 1
                            }}>
                                <Typography variant="body1" sx={{ml:1}}>
                                    {user.username}
                                </Typography>
                                <Box sx={{display: 'flex', flexDirection: 'row'}}>
                                    <Tooltip title={user.id === currentUser.id ? "Sie können Ihre eigene Rolle nicht ändern" : ""}>
                                        <span>
                                            <DropdownFilter
                                                label={"Rolle"}
                                                options={roleOptions}
                                                value={user.role}
                                                onChange={(newValue) => handleRoleChange(newValue, user.id)}
                                                sx={{mt: 1}}
                                                useNameAsLabel={true}
                                                disabled={user.id === currentUser.id}
                                            />
                                        </span>
                                    </Tooltip>
                                    <IconButton
                                        aria-label="settings"
                                        onClick={() => handleSettingsClick(user)}
                                    >
                                        <SettingsIcon/>
                                    </IconButton>
                                </Box>
                            </Box>
                        ))}
                    </Box>

                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={6000}
                        onClose={handleSnackbarClose}
                        anchorOrigin={{vertical: 'top', horizontal: 'right'}}  // Positioning Snackbar
                        message={
                            <span style={{display: 'flex', alignItems: 'center'}}>
                            {snackbarSeverity === 'success' ? (
                                <CheckCircleIcon style={{color: 'green', marginRight: 8}}/>
                            ) : (
                                <ErrorIcon style={{color: 'red', marginRight: 8}}/>
                            )}
                                {snackbarMessage}
                        </span>
                        }
                    />
                </Paper>

                {(selectedUser || isCreatingUser) && (
                    <NutzerPopup
                        user={selectedUser}
                        onClose={(newUser) => {
                            if (newUser) {
                                // Handle the newly created or updated user
                                fetchUsers(); // Refresh the user list
                            }
                            setSelectedUser(null);
                            setIsCreatingUser(false);
                        }}
                        roleOptions={roleOptions}
                        handleRoleChange={handleRoleChange}
                        isCreating={isCreatingUser}
                    />
                )}
            </Container>
        </SettingsLayout>
    );
};

export default NutzerVerwalten;
