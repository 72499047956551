import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Box, Grid, Typography, Stack, Card, Paper, CardHeader, CardContent } from '@mui/material';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTableTree from '../../../components/table/VariableTableTree';
import BigChartCardSecondYAxis from "../../../components/card/scorecardsmonat/BigChartCardSecondYAxis";
import { useTheme } from '@mui/material/styles'; // Importiere das Thema
import BigChartCardRecharts from "../../../components/card/scorecardsmonat/BigChartCardRecharts";
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import Battery3Bar from '@mui/icons-material/Battery3Bar';




const Auslastung = () => {
    const { filterState } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const theme = useTheme(); // Zugriff auf das Theme
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
    });
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [lineChartData, setLineChartData] = useState([]); // Daten für das Liniendiagramm der Verkaufsgebiete

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPersonalData(); // Krankenquote- und Fluktuationsdaten abrufen
            fetchTableData(); // Daten für die Tabelle abrufen
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren

        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }

        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );

            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Kumulieren der Fluktuation-Daten (wie in der funktionierenden Seite)
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);
                const mitarbeiterbestand = data.reduce((sum, item) => sum + (item.mitarbeiterbestand || 0), 0);

                const auslastungPerson = überstundenAbs > 0 ? (überstundenAbs / mitarbeiterbestand)  : 0;

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
                    : 0;

                // Eintritte und Austritte im gefilterten Monat
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0);
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0);

                return {
                    month: last6Months[index],
                    fluktuation,
                    eintritteM,
                    austritteM,
                    köpfeDifferenz,
                    stundenDifferenz,
                    überstundenAbs,
                    auslastungPerson,
                    mitarbeiterbestand,
                };
            });

            // Berechnung der Auslastung/Person pro Verkaufsgebiet
            const salesAreaAuslastungData = last6Months.map((month, index) => {
                const data = responses[index].data.results;

                // Gruppiere die Daten nach Verkaufsgebiet
                const salesAreaGroup = {};

                data.forEach(item => {
                    const salesArea = item.verkaufsgebiet;
                    if (!salesAreaGroup[salesArea]) {
                        salesAreaGroup[salesArea] = {
                            überstundenAbs: 0,
                            mitarbeiterbestand: 0,
                        };
                    }
                    salesAreaGroup[salesArea].überstundenAbs += item.überstundenAbs || 0;
                    salesAreaGroup[salesArea].mitarbeiterbestand += item.mitarbeiterbestand || 0;
                });

                // Berechne die Auslastung/Person pro Verkaufsgebiet
                const salesAreaRates = {};
                Object.keys(salesAreaGroup).forEach(salesArea => {
                    const überstundenAbs = salesAreaGroup[salesArea].überstundenAbs;
                    const mitarbeiterbestand = salesAreaGroup[salesArea].mitarbeiterbestand;
                    const auslastungProPerson = mitarbeiterbestand > 0 ? (überstundenAbs / mitarbeiterbestand) : 0;
                    salesAreaRates[salesArea] = {
                        überstundenAbs,
                        mitarbeiterbestand,
                        auslastungProPerson,
                    };
                });

                return {
                    month,
                    salesAreaRates,
                };
            });

            // Vorbereitung der Daten für das Liniendiagramm
            const salesAreas = new Set();
            salesAreaAuslastungData.forEach(monthData => {
                Object.keys(monthData.salesAreaRates).forEach(salesArea => {
                    salesAreas.add(salesArea);
                });
            });

            const lineChartData = Array.from(salesAreas).map(salesArea => {
                return {
                    id: salesArea,
                    data: salesAreaAuslastungData.map(monthData => {
                        const auslastungProPerson = monthData.salesAreaRates[salesArea]
                            ? monthData.salesAreaRates[salesArea].auslastungProPerson
                            : null; // Falls keine Daten vorhanden sind
                        return {
                            x: monthData.month,
                            y: auslastungProPerson,
                        };
                    }),
                };
            });

            setPersonalData({
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
            });

            setLineChartData(lineChartData); // Setze die Daten für das Liniendiagramm

            // Daten für das Chart vorbereiten (gesamt)
            // Daten für das Chart vorbereiten (gesamt)
            const chartData = [
                {
                id: 'Stundendifferenz',
                data: fluktuationData
                    .filter(item => item.month)
                    .map(item => ({
                    x: item.month,
                    y: item.stundenDifferenz, // Using 'stundenDifferenz' based on your data
                    })),
                },
                {
                id: 'Auslastung/Person',
                data: fluktuationData
                    .filter(item => item.month)
                    .map(item => ({
                    x: item.month,
                    y: item.auslastungPerson,
                    })),
                },
            ];
            
            setChartData(chartData);
  

        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };

    const fetchTableData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

        try {
            const response = await axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                params: { jahrMonat: currentMonth },
            });

            const data = response.data.results;

            // Tabelle formatieren
            const formattedTableData = data.map((item) => {
                const überstundenAbs = item.überstundenAbs || 0;
                const mitarbeiterbestand = item.mitarbeiterbestand || 0;

                // Berechne die Auslastung pro Person
                const auslastungProPerson = mitarbeiterbestand > 0 ? (überstundenAbs / mitarbeiterbestand)  : 0;

                return {
                    verkaufsgebiet: item.verkaufsgebiet,
                    filiale: item.filiale,
                    scoreÜberstunden: item.scoreÜberstunden || 0,
                    überstundenAbs: überstundenAbs,
                    mitarbeiterbestand: mitarbeiterbestand,
                    auslastungProPerson: auslastungProPerson,
                    
                };
            });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    // Definition der Spalten für die Tabelle
    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'scoreÜberstunden',
            header: 'Score',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
        {
            accessorKey: 'auslastungProPerson',
            header: 'Auslastung/Person',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2) ,
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedÜberstunden = subRows.reduce((total, subRow) => total + subRow.getValue('überstundenAbs'), 0);
                const mitarbeiterbestand = subRows.reduce((total, subRow) => total + subRow.original.mitarbeiterbestand, 0);
                const aggregatedÜberstundenPerson = mitarbeiterbestand > 0 ? (aggregatedÜberstunden / mitarbeiterbestand)  : 0;
                return (
                    <span>
                        {aggregatedÜberstundenPerson.toFixed(2)}
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'überstundenAbs',
            header: 'Stundendifferenz',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
       
    ], []);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
            <PageHeader
      title="Auslastung "
      subtitle=" Dieser Bericht betrachtet die Personaleinsatzplanung pro Kostenstelle, indem er analysiert, wie viele Stunden die Mitarbeiter vertraglich vereinbart haben und wie viele Anwesenheits- und Abwesenheitsstunden sie im betrachteten Zeitraum hatten. Dadurch wird eine detaillierte Übersicht der tatsächlichen Auslastung der Mitarbeiter gegeben, um mögliche Unter- oder Überauslastungen zu identifizieren und entsprechend zu steuern.."
      Icon={Battery3Bar}
    />

                {/* Liniendiagramme */}
                <Grid container mt={3} spacing={2}>
                    <Grid item xs={12} sm={12} lg={6}>
                        <BigChartCardSecondYAxis
                            data={chartData}
                            title="Auslastung/Person und Sstundendifferenz über die letzten 6 Monate"
                            xKey="month"
                        />
                    </Grid>

                    {/* Liniendiagramm für Auslastung/Person pro Verkaufsgebiet */}
                    <Grid item xs={12} sm={12} lg={6}>
                        <BigChartCardRecharts
                            data={lineChartData}
                            title="Auslastung/Person pro Verkaufsgebiet über die letzten 6 Monate"
                            // height={400} // Optional: Höhe des Diagramms anpassen
                        />
                    </Grid>
                </Grid>

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3}>
                    <Paper>
                        <Card
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                          }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Auslastung Übersicht
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <VariableTableTree
                                    columns={columns}
                                    data={tableData}
                                    initialGrouping={['verkaufsgebiet']}
                                />
                            </CardContent>
                        </Card>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    );
};

export default Auslastung;
