import React, {useState} from 'react';
import {Box, Button, Card, CardContent, CardMedia, CircularProgress, TextField, Typography} from '@mui/material';
import axiosInstance from "../../../services/axiosInstance";
import SortimentLayout from "../../../components/layout/Sortiment/SortimentLayout";

const ImageViewer = () => {
    const [imageId, setImageId] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [imageData, setImageData] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!imageId) return;

        setLoading(true);
        setError('');
        setImageData(null);

        try {
            const response = await axiosInstance.get(`/pictures/image-url/${imageId}/`);
            const imageUrlResponse = await axiosInstance.get(`/pictures/image-url/${imageId}/`);

            setImageData({
                url: imageUrlResponse.data.url,
                title: response.data.title,
                uploadedAt: response.data.uploaded_at
            });
        } catch (error) {
            console.error('Error fetching image:', error);
            setError(error.response?.data?.error || 'Failed to load image');
        } finally {
            setLoading(false);
        }
    };

    return (
        <SortimentLayout>
            <Box sx={{maxWidth: 800, margin: 'auto', padding: 2}}>
                <Typography variant="h4" gutterBottom>
                    Image Viewer
                </Typography>

                <form onSubmit={handleSubmit}>
                    <Box sx={{display: 'flex', gap: 2, mb: 3}}>
                        <TextField
                            fullWidth
                            label="Image ID"
                            value={imageId}
                            onChange={(e) => setImageId(e.target.value)}
                            required
                        />
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={loading || !imageId}
                        >
                            View Image
                        </Button>
                    </Box>
                </form>

                {loading && (
                    <Box sx={{display: 'flex', justifyContent: 'center', my: 4}}>
                        <CircularProgress/>
                    </Box>
                )}

                {error && (
                    <Typography color="error" sx={{mt: 2}}>
                        {error}
                    </Typography>
                )}

                {imageData && (
                    <Card sx={{mt: 3}}>
                        <CardMedia
                            component="img"
                            image={imageData.url}
                            alt={imageData.title}
                            sx={{
                                height: 'auto',
                                maxHeight: '500px',
                                objectFit: 'contain'
                            }}
                        />
                        <CardContent>
                            <Typography variant="h6">
                                {imageData.title}
                            </Typography>
                            {imageData.uploadedAt && (
                                <Typography variant="body2" color="text.secondary">
                                    Uploaded: {new Date(imageData.uploadedAt).toLocaleString()}
                                </Typography>
                            )}
                        </CardContent>
                    </Card>
                )}
            </Box>
        </SortimentLayout>
    );
};

export default ImageViewer;
