import {Box, ButtonGroup} from "@mui/material";
import Button from "@mui/material/Button";

const ThekeHeader = ({warengruppen, filter,  handleWarengruppenChange}) => {
    return (
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" sx={{mb: 3, width: '100%'}}>
            <ButtonGroup
                variant="contained"
                color="inherit"
                aria-label="sortiment type selection"
                sx={{
                    flexGrow: 1,
                    '& .MuiButton-root': {
                        flex: 1,
                        width: 0,
                        minWidth: 0,
                    }
                }}
            >
                {warengruppen.map((wg, index) => (
                    <Button
                        key={index}
                        onClick={() => handleWarengruppenChange(wg.warengruppennummer)}
                        sx={{
                            backgroundColor: filter === wg.warengruppennummer ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                            '&:hover': {
                                backgroundColor: filter === wg.warengruppennummer ? 'rgba(0, 0, 0, 0.2)' : 'rgba(0, 0, 0, 0.1)',
                            },
                        }}
                    >
                        {wg.warengruppe}
                    </Button>
                ))}
            </ButtonGroup>
        </Box>
    );
};

export default ThekeHeader;
