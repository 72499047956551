import React from 'react';
import {
  GaugeContainer,
  GaugeValueArc,
  GaugeReferenceArc,
  useGaugeState,
} from '@mui/x-charts/Gauge';
import { Typography } from '@mui/material';

function GaugePointer({ target, maxValue }) {
  const { cx, cy, outerRadius, startAngle, endAngle, value } = useGaugeState();

  if (target === null || maxValue === 0) {
    return null;
  }

  const range = endAngle - startAngle;
  const targetRatio = target / maxValue;
  const targetAngle = startAngle + range * targetRatio;

  const targetPosition = {
    x: cx + (outerRadius * 1) * Math.sin(targetAngle),
    y: cy - (outerRadius * 1) * Math.cos(targetAngle),
  };

  const innerTargetPosition = {
    x: cx + (outerRadius * 0.8) * Math.sin(targetAngle),
    y: cy - (outerRadius * 0.8) * Math.cos(targetAngle),
  };

  return (
    <g>
      {/* Zielwert-Anzeige */}
      <line
        x1={innerTargetPosition.x}
        y1={innerTargetPosition.y}
        x2={targetPosition.x}
        y2={targetPosition.y}
        stroke="red"
        strokeWidth={3}
        strokeLinecap="round"
      />
      {/* Zielwert im Halbkreis */}
      <text
        x={targetPosition.x}
        y={targetPosition.y - 10}
        fill="red"
        fontWeight="bold"

        fontSize="14"
        textAnchor="middle"
      >
        {target} Soll
      </text>

      {/* Aktueller Wert-Anzeige im Halbkreis */}
      <text
        x={cx}
        y={cy}  // Positionierung etwas unterhalb der Mitte des Halbkreises
        fill="black"
        fontSize="18"
        fontWeight="bold"
        textAnchor="middle"
      >
        {value} Ist
      </text>
    </g>
  );
}

const GaugeBedarf = ({ title, value, target, maxValue, description }) => {
  return (
    <>
      <Typography variant="h6" component="div">
        {title}
      </Typography>
      <GaugeContainer
        width={200}
        height={200}
        startAngle={-110}
        endAngle={110}
        value={value || 0}
        valueMax={maxValue}
        cornerRadius="50%"
      >
        <GaugeReferenceArc value={value || 0} />
        <GaugeValueArc />
        <GaugePointer target={target} maxValue={maxValue || 1} />
      </GaugeContainer>
      <Typography variant="body2" sx={{ mt: 2 }}>
        {description}
      </Typography>
    </>
  );
};

export default GaugeBedarf;
