import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, TextField, MenuItem, Grid } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
LicenseInfo.setLicenseKey('96e93ece4fd7c9c05c2528e39fa78ebeTz0xMDAyNTAsRT0xNzYwODYxODQwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9aW5pdGlhbCxLVj0y');

// Import der Date/Time-Picker-Komponenten
import { LocalizationProvider } from '@mui/x-date-pickers-pro/LocalizationProvider'; 
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import dayjs from 'dayjs';
import Layout from '../../../components/layout/Frequenz/frequenz';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import axiosInstance from '../../../services/axiosInstance';
import BigTableCard from '../../../components/card/BigTableCard';
import ViewTimelineOutlinedIcon from '@mui/icons-material/ViewTimelineOutlined';
import BigChartCardFrequenz from '../../../components/card/frequenz/BigChartCardFrequenz';
import DropdownFilter from '../../../components/filter/DropdownFilter';

const Kundenstatistik = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);

  // Filter states
  const [filiale, setFiliale] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [wochentag, setWochentag] = useState(null);
  const [displayOption, setDisplayOption] = useState('umsatz');

  const [filialeOptions, setFilialeOptions] = useState([]);
  const [wochentagOptions] = useState([
    { label: 'Montag', value: 'Montag' },
    { label: 'Dienstag', value: 'Dienstag' },
    { label: 'Mittwoch', value: 'Mittwoch' },
    { label: 'Donnerstag', value: 'Donnerstag' },
    { label: 'Freitag', value: 'Freitag' },
    { label: 'Samstag', value: 'Samstag' },
    { label: 'Sonntag', value: 'Sonntag' },
  ]);

  const [columns, setColumns] = useState([]);
  const [averageData, setAverageData] = useState([]); // Zustand für die Durchschnittsdaten

  // Daten abrufen
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/frequenz/kundenstatistik/', {
        params: {
          filiale: filiale ? filiale.value : undefined,
          startdatum: dateRange[0] ? dayjs(dateRange[0]).format('YYYY-MM-DD') : undefined,
          enddatum: dateRange[1] ? dayjs(dateRange[1]).format('YYYY-MM-DD') : undefined,
          wochentag: wochentag ? wochentag.value : undefined,
        },
      });

      const groupedData = {};
      const uniqueDates = new Set();
      const hourTotals = {};
      const hourCounts = {};

      response.data.results.forEach((row) => {
        const formattedDate = dayjs(row.datum).format('DD.MM.YYYY');
        uniqueDates.add(formattedDate);

        if (!groupedData[row.uhrzeit]) {
          groupedData[row.uhrzeit] = { uhrzeit: row.uhrzeit };
          hourTotals[row.uhrzeit] = 0;
          hourCounts[row.uhrzeit] = 0;
        }

        const value = displayOption === 'umsatz' ? parseFloat(row.umsatz) : parseInt(row.kundenanzahl);
        hourTotals[row.uhrzeit] += value;
        hourCounts[row.uhrzeit] += 1;

        if (displayOption === 'umsatz') {
          groupedData[row.uhrzeit][formattedDate] = value
            ? `${value.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })} €`
            : 'NaN €';
        } else {
          groupedData[row.uhrzeit][formattedDate] = value || '0';
        }
      });

      // Durchschnittswerte für jede Stunde berechnen und sortieren
      const averageData = Object.keys(hourTotals)
        .map((hour) => ({
          uhrzeit: hour,
          average: hourTotals[hour] / hourCounts[hour],
        }))
        .sort((a, b) => {
          const timeA = dayjs(a.uhrzeit, 'HH:mm');
          const timeB = dayjs(b.uhrzeit, 'HH:mm');
          return timeA.isBefore(timeB) ? -1 : 1;
        });

      // Sortiere die Uhrzeiten aufsteigend
      const sortedData = Object.values(groupedData).sort((a, b) => {
        const timeA = dayjs(a.uhrzeit, 'HH:mm');
        const timeB = dayjs(b.uhrzeit, 'HH:mm');
        return timeA.isBefore(timeB) ? -1 : 1;
      });

      const dynamicColumns = [
        { accessorKey: 'uhrzeit', header: 'Uhrzeit' },
        ...Array.from(uniqueDates)
          .sort((a, b) => dayjs(a, 'DD.MM.YYYY').isBefore(dayjs(b, 'DD.MM.YYYY')) ? -1 : 1)
          .map((date) => ({
            accessorKey: date,
            header: date,
          })),
      ];

      setColumns(dynamicColumns);
      setData(sortedData);
      setRowCount(response.data.count);
      setAverageData(averageData); // Durchschnittsdaten für das Diagramm speichern
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(); // Daten beim Mount abrufen
    fetchFilialeOptions(); // Optionen für Filiale abrufen
  }, [displayOption]);

  // Optionen für Filiale abrufen
  const fetchFilialeOptions = async () => {
    try {
      const response = await axiosInstance.get('/stammdaten/unique-filiale/');
      setFilialeOptions(response.data.map((item) => ({
        label: item.filiale,
        value: item.filiale,
      })));
    } catch (error) {
      console.error('Error fetching filiale options:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [displayOption, filiale, dateRange, wochentag]);

  const resetFilters = () => {
    setFiliale(null);
    setDateRange([null, null]);
    setWochentag(null);
    setDisplayOption('umsatz');
    fetchData();
  };

  return (
    <Layout>
      <Box>
        {/* Header */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mt: 2,
          }}
        >
          <PageHeader
            title="Umsatz- und Kundenfrequenz"
            subtitle="Dieser Bericht zeigt die Tagesverläufe der Umsätze und Kunden auf Filialebene."
            Icon={ViewTimelineOutlinedIcon}
          />
        </Box>

        {/* Filter Section */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 3 }}>
          <DropdownFilter
            label="Filiale"
            options={filialeOptions}
            value={filiale}
            onChange={setFiliale}
            size="small"
            sx={{ width: 200 }}
          />

          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
            <DateRangePicker
              value={dateRange}
              onChange={(newValue) => setDateRange(newValue)}
              slots={{ field: SingleInputDateRangeField }}
              calendars={1}
              slotProps={{
                shortcuts: {
                  items: [
                    { label: 'Diese Woche', getValue: () => [dayjs().startOf('isoWeek'), dayjs().endOf('isoWeek')] },
                    { label: 'Letzte Woche', getValue: () => [dayjs().subtract(1, 'week').startOf('isoWeek'), dayjs().subtract(1, 'week').endOf('isoWeek')] },
                    { label: 'Letzte 6 Wochen', getValue: () => [dayjs().subtract(6, 'week').startOf('isoWeek'), dayjs()] },
                    { label: 'Letzter Monat', getValue: () => [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')] },
                    { label: 'Letzte 6 Monate', getValue: () => [dayjs().subtract(6, 'month').startOf('month'), dayjs().endOf('month')] },
                    { label: 'Letzte 12 Monate', getValue: () => [dayjs().subtract(12, 'month').startOf('month'), dayjs().endOf('month')] },
                    { label: 'Zurücksetzen', getValue: () => [null, null] },
                  ],
                },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  height: '40px',
                  minWidth: '250px',
                  fontSize: '0.875rem',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: '1.2rem',
                },
              }}
            />
          </LocalizationProvider>

          <DropdownFilter
            label="Wochentag"
            options={wochentagOptions}
            value={wochentag}
            onChange={setWochentag}
            size="small"
            sx={{ width: 150 }}
          />

          <TextField
            label="Anzeigen"
            value={displayOption}
            onChange={(e) => setDisplayOption(e.target.value)}
            select
            size="small"
            sx={{ width: 150 }}
          >
            <MenuItem value="umsatz">Umsatz</MenuItem>
            <MenuItem value="kundenanzahl">Kundenanzahl</MenuItem>
          </TextField>

          <Button
            variant="outlined"
            size="small"
            onClick={resetFilters}
            sx={{
              height: '40px',
              minWidth: '150px',
              fontSize: '0.875rem',
              '& .MuiSvgIcon-root': {
                fontSize: '1.2rem',
              },
            }}
          >
            Zurücksetzen
          </Button>
        </Box>

        {/* Grid für Tabelle und Diagramm */}
        <Grid container spacing={4} sx={{ mt: 2 }}>
          {/* Tabelle */}
          <Grid item xs={12}>
            <BigTableCard
              title="Tagesbericht über Umsatz und Kunden pro Stunden"
              data={data}
              columns={columns}
              onRowClick={(row) => console.log('Row clicked:', row)}
              muiTableBodyCellProps={{}}
            />
          </Grid>

          {/* Diagramm */}
          <Grid item xs={12}>
            <BigChartCardFrequenz
              data={averageData} // Verwende die Durchschnittsdaten, die du bereits berechnet hast
              title={displayOption === 'umsatz' ? 'Durchschnittlicher Umsatz pro Stunde' : 'Durchschnittliche Kundenanzahl pro Stunde'}
              infoContent="Dieses Diagramm zeigt den Durchschnittswert pro Stunde basierend auf den ausgewählten Daten."
            />
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default Kundenstatistik;
