import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const CategoryDialog = ({ open, onClose, categoryName, setCategoryName, handleSaveCategory }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{categoryName ? 'Kategorie bearbeiten' : 'Neue Kategorie anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Kategoriename"
                fullWidth
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
    <Button onClick={handleSaveCategory} variant="contained" color="primary">
        Speichern
    </Button>
    <Button onClick={onClose} variant="outlined">
        Abbrechen
    </Button>
</DialogActions>

    </Dialog>
);

export default CategoryDialog;
