import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, TextField, Button, IconButton, Badge, Avatar, Chip, CircularProgress, Alert } from '@mui/material';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PollIcon from '@mui/icons-material/Poll';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import axiosInstance from '../../../services/axiosInstance';
import { useTheme } from '@mui/material/styles';

const Index = () => {
    const theme = useTheme();

    const tagColors = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.chart?.yellow || "#FFEB3B",
        theme.palette.chart?.orange || "#FF9800",
        theme.palette.chart?.red || "#F44336",
        theme.palette.chart?.pink || "#E91E63",
        theme.palette.chart?.purpleLight || "#AB47BC",
        theme.palette.chart?.purpleDark || "#7B1FA2",
    ];

    const [tags, setTags] = useState([]);
    const [posts, setPosts] = useState([]);
    const [selectedTag, setSelectedTag] = useState('Alle');
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [newComments, setNewComments] = useState({});
    const [notifications, setNotifications] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Tags laden
                const tagsResponse = await axiosInstance.get('/filialfeed/tags/');
                const tagData = tagsResponse.data.results || tagsResponse.data;
                const tagNames = tagData.map((tag) => tag.name);

                // Tags mit Farben verknüpfen
                const tagsWithColors = ['Alle', ...tagNames].map((tagName, index) => ({
                    name: tagName,
                    color: tagColors[index % tagColors.length],
                }));

                setTags(tagsWithColors);

                // Posts laden
                const postsResponse = await axiosInstance.get('/filialfeed/posts/');
                const postsData = postsResponse.data.results || postsResponse.data;

                // Aktuelle Zeit
                const now = new Date();

                // Posts filtern, die veröffentlicht sind
                const visiblePostsData = postsData.filter(post => new Date(post.publish_date) <= now);

                // Posts nach Veröffentlichungsdatum sortieren (neueste zuerst)
                visiblePostsData.sort((a, b) => new Date(b.publish_date) - new Date(a.publish_date));

                // Bild-URLs abrufen
                const postsWithImages = await Promise.all(
                    visiblePostsData.map(async (post) => {
                        if (post.image_id) {
                            try {
                                const imageResponse = await axiosInstance.get(`/pictures/get-image-url/${post.image_id}/`);
                                return { ...post, image_url: imageResponse.data.url };
                            } catch (error) {
                                console.error(`Fehler beim Laden des Bildes für Post ${post.id}:`, error);
                                return { ...post, image_url: null };
                            }
                        } else {
                            return { ...post, image_url: null };
                        }
                    })
                );

                setPosts(postsWithImages);

                setLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der Daten:', error);
                setError('Fehler beim Laden der Daten.');
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const handleCommentSubmit = async (postId) => {
        const commentText = newComments[postId];
        if (!commentText) return;

        try {
            await axiosInstance.post(`/filialfeed/posts/${postId}/comments/`, {
                content: commentText,
                // Weitere erforderliche Felder können hier hinzugefügt werden
            });
            // Kommentare aktualisieren
            const updatedPostResponse = await axiosInstance.get(`/filialfeed/posts/${postId}/`);
            const updatedPost = updatedPostResponse.data;

            setPosts((prevPosts) =>
                prevPosts.map((post) => (post.id === postId ? updatedPost : post))
            );

            // Kommentar-Eingabefeld leeren
            setNewComments((prev) => ({ ...prev, [postId]: '' }));
        } catch (error) {
            console.error('Fehler beim Hinzufügen des Kommentars:', error);
        }
    };

    const handleVote = async (postId, optionId) => {
        try {
            await axiosInstance.post(`/filialfeed/posts/${postId}/poll/vote/`, {
                option_id: optionId,
                // Weitere erforderliche Felder können hier hinzugefügt werden
            });
            // Umfrageergebnisse aktualisieren
            const updatedPostResponse = await axiosInstance.get(`/filialfeed/posts/${postId}/`);
            const updatedPost = updatedPostResponse.data;

            setPosts((prevPosts) =>
                prevPosts.map((post) => (post.id === postId ? updatedPost : post))
            );
        } catch (error) {
            console.error('Fehler beim Abstimmen:', error);
        }
    };

    const filteredPosts = selectedTag === "Alle"
        ? posts
        : posts.filter(post => post.tag?.name === selectedTag);

    return (
        <Layout>
            <Box sx={{ flexGrow: 1, p: 3, maxWidth: 1200, margin: '0 auto', bgcolor: 'transparent' }}>
                <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                    <PageHeader
                        title="FilialFeed"
                        subtitle="Eure zentrale Plattform für aktuelle Themen, direkten Austausch und schnelle Umfragen, damit ihr immer auf dem Laufenden seid."
                        Icon={AnnouncementOutlinedIcon}
                    />
                    {/* Benachrichtigungssymbol */}
                    <IconButton color="primary">
                        <Badge badgeContent={notifications} color="error">
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                </Box>

                {/* Tag-Filter */}
                <Box display="flex" overflow="auto" mb={2} sx={{ gap: 2 }}>
                    {tags.map((tag) => (
                        <Avatar
                            key={tag.name}
                            onClick={() => setSelectedTag(tag.name)}
                            sx={{
                                cursor: 'pointer',
                                width: 80,
                                height: 80,
                                bgcolor: selectedTag === tag.name ? tag.color : 'transparent',
                                color: selectedTag === tag.name ? 'white' : tag.color,
                                border: `2px solid ${tag.color}`,
                            }}
                        >
                            <Typography variant="caption" align="center" color="inherit">
                                {tag.name}
                            </Typography>
                        </Avatar>
                    ))}
                </Box>

                {loading ? (
                    <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <Alert severity="error">{error}</Alert>
                ) : (
                    filteredPosts.map((post) => {
                        // Veröffentlichungsdatum formatieren
                        const publishDate = new Date(post.publish_date);
                        const formattedDate = publishDate.toLocaleDateString('de-DE', {
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit',
                        });

                        return (
                            <Card key={post.id} sx={{ mb: 3 }}>
                                <CardContent>
                                    <Typography variant="h4">{post.title}</Typography>

                                    {/* Tag-Anzeige */}
                                    <Box mb={1}>
                                        <Chip
                                            label={post.tag?.name || "Kein Tag"}
                                            variant="outlined"
                                            size="small"
                                        />
                                    </Box>

                                    <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                                        Von: <strong>{post.sender}</strong> | Veröffentlicht am: <strong>{formattedDate}</strong>
                                    </Typography>

                                    {/* Bildanzeige */}
                                    {post.image_url && (
                                        <Box display="flex" justifyContent="center" mb={2}>
                                            <img src={post.image_url} alt={post.title} style={{ width: "50%", borderRadius: 8 }} />
                                        </Box>
                                    )}

                                    <Typography variant="body1" color="text.secondary">{post.content}</Typography>

                                    {/* Umfrage */}
                                    {post.poll && (
                                        <Box mt={2}>
                                            <Typography variant="subtitle1">Umfrage</Typography>
                                            <Typography variant="body2">{post.poll.question}</Typography>
                                            {post.poll.options.map((option) => (
                                                <Button
                                                    key={option.id}
                                                    variant="outlined"
                                                    startIcon={<PollIcon />}
                                                    onClick={() => handleVote(post.id, option.id)}
                                                    sx={{ mr: 1, mt: 1 }}
                                                >
                                                    {option.option_text} ({option.votes_count || 0})
                                                </Button>
                                            ))}
                                        </Box>
                                    )}

                                    {/* Kommentare */}
                                    <Box mt={2}>
                                        <Typography variant="subtitle2">Kommentare</Typography>
                                        {post.comments.map((comment) => (
                                            <Typography key={comment.id} variant="body2" sx={{ pl: 2 }}>
                                                <strong>{comment.author}:</strong> {comment.content}
                                            </Typography>
                                        ))}
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            placeholder="Neuen Kommentar hinzufügen..."
                                            fullWidth
                                            value={newComments[post.id] || ''}
                                            onChange={(e) => setNewComments(prev => ({ ...prev, [post.id]: e.target.value }))}
                                            sx={{ mt: 1 }}
                                        />
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={() => handleCommentSubmit(post.id)}
                                            sx={{ mt: 1 }}
                                        >
                                            Kommentar hinzufügen
                                        </Button>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })
                )}
            </Box>
        </Layout>
    );
};

export default Index;
