import React, { useEffect, useState, useContext } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Grid, Typography, Card, CardHeader, CardContent, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance';
import { FilterContext } from '../../../provider/FilterProvider';
import BasicLines from '../../charts/LineChartSameYAxis';  // Importiere die BasicLines Komponente
import VariableTable from "../../table/VariableTable";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { tausenderTrennung } from '../../../utils/mathStuff';

const TAPerformanceKundenbon = ({ open, setOpen }) => {
    const { filterState } = useContext(FilterContext);
    const [tableData, setTableData] = useState([]);
    const [columns, setColumns] = useState([]); // Columns for the table
    const [chartData, setChartData] = useState([]);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (filterState.filiale && filterState.datum) {
            fetchTableData();
        }
    }, [filterState.filiale, filterState.datum]);

    const fetchTableData = async () => {
        setLoading(true);
        try {
            const endDate = dayjs(`${filterState.datum?.value}-01`, 'YYYY-MM-DD');
            const startDate = endDate.subtract(6, 'month');

            const responseFiliale = await axiosInstance.get('scorecards_month/performance-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                    filiale: filterState.filiale?.value,
                }
            });

            const responseUnternehmen = await axiosInstance.get('scorecards_month/performance-unternehmen-monat/', {
                params: {
                    startdatum: startDate.format('YYYY-MM-DD'),
                    enddatum: endDate.format('YYYY-MM-DD'),
                }
            });

            if (Array.isArray(responseFiliale.data.results) && Array.isArray(responseUnternehmen.data.results)) {
                const months = responseFiliale.data.results.map((item) => dayjs(item.datum).format('YYYY.MM'));

                // Set columns for the table (Datum)
                setColumns([
                    { id: 'kennzahl', accessorKey: 'kennzahl', header: 'Kennzahl' },
                    ...months.map((month) => ({
                        id: month,
                        accessorKey: month,
                        header: month,
                    }))
                ]);

                // Set rows for the table (Kennzahlen)
                setTableData([
                    {
                        kennzahl: "Kundenbon Filiale €",
                        ...responseFiliale.data.results.reduce((acc, item) => {
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = tausenderTrennung(item.kundenbon, 2);
                            return acc;
                        }, {})
                    },
                    {
                        kennzahl: "Kundenbon Unternehmen €",
                        ...responseUnternehmen.data.results.reduce((acc, item) => {
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = item.kundenbon !== 0 ? tausenderTrennung(item.kundenbon, 2) : '-';
                            return acc;
                        }, {})
                    },
                    {
                        kennzahl: "% Differenz",
                        ...responseFiliale.data.results.reduce((acc, item, index) => {
                            const filialeKundenbon = item.kundenbon;
                            const unternehmenKundenbon = responseUnternehmen.data.results[index]?.kundenbon || 0;
                            const differenz = unternehmenKundenbon
                                ? ((filialeKundenbon - unternehmenKundenbon) / unternehmenKundenbon * 100).toFixed(2)
                                : '-';
                            const month = dayjs(item.datum).format('YYYY.MM');
                            acc[month] = differenz !== '-' ? `${tausenderTrennung(differenz, 2)} %` : '-';
                            return acc;
                        }, {})
                    }
                ]);

                // Set chart data (same as before)
                const chartDataFormatted = responseFiliale.data.results.map((item) => ({
                    x: item.datum,
                    y: item.kundenbon,
                    label: 'Filiale'
                })).concat(responseUnternehmen.data.results.map((item) => ({
                    x: item.datum,
                    y: item.kundenbon,
                    label: 'Unternehmen'
                })));

                setChartData(chartDataFormatted);
            }
        } catch (error) {
            console.error('Error fetching table data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
            <DialogTitle>
                Tiefenanalyse Kundenbon
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Verlauf des Kundenbons in den letzten 6 Monaten im Vergleich zum Unternehmensdurchschnitt
                                    </Typography>
                                }
                                action={
                                    <Tooltip
                                        title={
                                            <>
                                                Kundenbonverlauf - Informationen<br /><br />
                                                Dieses Diagramm zeigt den Verlauf des Kundenbons der ausgewählten Filiale im Vergleich zum Gesamtunternehmen.<br /><br />
                                                <strong>Filiale:</strong> Die dunklere Linie zeigt den Kundenbon der Filiale über die Zeit.<br />
                                                <strong>Unternehmen:</strong> Die hellere Linie repräsentiert den Kundenbon des gesamten Unternehmens.<br /><br />
                                                So kannst du Trends und Unterschiede zwischen der Filiale und dem Unternehmen erkennen.
                                            </>
                                        }
                                    >
                                        <IconButton>
                                            <InfoOutlinedIcon />
                                        </IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <BasicLines data={chartData} />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardContent sx={{ flexGrow: 1 }}>
                                {loading ? (
                                    <div>Loading...</div>
                                ) : (
                                    <VariableTable
                                        columns={columns}
                                        data={tableData}
                                    />
                                )}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default TAPerformanceKundenbon;
