import React from 'react';
import { List, ListItem, ListItemText, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const StepList = ({ steps, handleDeleteStep, handleEditStep }) => (
    <List sx={{ pl: 4 }}>
        {steps.length > 0 ? (
            steps.map(step => (
                <ListItem
                    key={step.id}
                    secondaryAction={
                        <>
                            <IconButton edge="end" aria-label="edit" onClick={() => handleEditStep(step)}>
                                <EditIcon />
                            </IconButton>
                            <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteStep(step)}>
                                <DeleteIcon />
                            </IconButton>
                        </>
                    }
                >
                    <ListItemText primary={step.description} />
                </ListItem>
            ))
        ) : (
            <Typography variant="body2" color="textSecondary">Keine Schritte vorhanden.</Typography>
        )}
    </List>
);

export default StepList;
