import React from 'react';
import { Grid, Box } from '@mui/material';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';


const Index = () => {
    return (
        <Layout>
            <Box
                sx={{
                    flexGrow: 1,
                    p: 3,
                    maxWidth: 1200,
                    margin: '0 auto',
                    bgcolor: 'transparent',
                }}
            >
                <PageHeader 
                    title="Quali-Meldung" 
                    subtitle="Verwalten deine Quali-Meldung und konfiguriere diese nach den Bedürfnissen des Unternehmens."
                    Icon={QuickreplyOutlinedIcon}  // Übergabe des Icons
                />
                
            </Box>
        </Layout>
    );
};

export default Index;
