import React, { useState, useEffect, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Tabs, Tab, Box, List, ListItem, ListItemText, Paper, Card, CardContent, CardHeader } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import VariableTable from '../table/VariableTable';  // Importiere deine VariableTable-Komponente
import axiosInstance from '../../services/axiosInstance'; // Importiere axiosInstance für API-Aufrufe
import StackedBarChart from '../charts/StackedBarCharts';  // Importiere die StackedBarChart-Komponente
import BigTableCard from '../card/BigTableCard';  // Importiere die BigTableCard-Komponente
import dayjs from 'dayjs';
import { tausenderTrennung } from "../../utils/mathStuff";


const TabPanel = (props) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const EmployeeDetailsPopup = ({ open, onClose, employee, jahrMonat }) => {
    const [tabIndex, setTabIndex] = useState(0);
    const [employeeDetails, setEmployeeDetails] = useState(null);
    const [workTimes, setWorkTimes] = useState([]);
    const [absences, setAbsences] = useState([]);
    const [chartAbsences, setChartAbsences] = useState([]);
    const [utilizationData, setUtilizationData] = useState([]);

    const handleChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    useEffect(() => {
        if (open && employee) {
            fetchEmployeeDetails();
        }
    }, [open, employee]);

    useEffect(() => {
        if (open && employeeDetails) {
            fetchWorktimeAbsence();
            fetchAbsenceDataForChart();
            fetchUtilizationData();
        }
    }, [open, employeeDetails]);


const fetchWorktimeAbsence = async () => {
    try {
        const [anwesenheitenResponse, abwesenheitenResponse] = await Promise.all([
            axiosInstance.get(`/personaldaten/anwesenheiten/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                    jahrMonat: jahrMonat,
                }
            }),
            axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                    jahrMonat: jahrMonat,
                }
            })
        ]);

        // Runden der Anwesenheitsstunden und Formatieren des Datums
        const roundedWorkTimes = anwesenheitenResponse.data.results.map(item => ({
            ...item,
            anwesenheitsstunden: tausenderTrennung(parseFloat(item.anwesenheitsstunden).toFixed(2), 2),  // Hier wird die Formatierung angewendet
            datum: dayjs(item.datum).format('DD.MM.YYYY'),
        }));

        // Runden der Abwesenheitsstunden und Formatieren des Datums
        const roundedAbsences = abwesenheitenResponse.data.results.map(item => ({
            ...item,
            abwesenheitsstunden: tausenderTrennung(parseFloat(item.abwesenheitsstunden).toFixed(2), 2),  // Hier wird die Formatierung angewendet
            datum: dayjs(item.datum).format('DD.MM.YYYY'),
        }));

        setWorkTimes(roundedWorkTimes || []);
        setAbsences(roundedAbsences || []);
    } catch (error) {
        console.error("Fehler beim Abrufen der Arbeits- und Abwesenheitszeiten:", error);
        setWorkTimes([]);
        setAbsences([]);
    }
};

    

    const fetchEmployeeDetails = async () => {
        try {
            const response = await axiosInstance.get(`/personaldaten/stammdaten-mitarbeiter/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                }
            });

            if (response.data && response.data.results.length > 0) {
                setEmployeeDetails(response.data.results[0]);
            } else {
                console.error('Keine Stammdaten gefunden.');
            }
        } catch (error) {
            console.error('Fehler beim Abrufen der Stammdaten:', error);
            setEmployeeDetails(null);
        }
    };

    const fetchAbsenceDataForChart = async () => {
        try {
            const startDate = dayjs().subtract(6, 'months').startOf('month').format('YYYY-MM-DD');
            const endDate = dayjs().endOf('month').format('DD.MM.YYYY');
            
            const response = await axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                params: {
                    mitarbeiterId: employee.mitarbeiterId,
                    startDate: startDate,
                    endDate: endDate,
                }
            });

            setChartAbsences(response.data.results || []);
        } catch (error) {
            console.error("Fehler beim Abrufen der Abwesenheitsdaten für das Diagramm:", error);
            setChartAbsences([]);
        }
    };

    const fetchUtilizationData = async () => {
        try {
            const startDate = dayjs().subtract(6, 'months').startOf('month').format('DD.MM.YYYY');
            const endDate = dayjs().endOf('month').format('DD.MM.YYYY');

            const [anwesenheitenResponse, abwesenheitenResponse] = await Promise.all([
                axiosInstance.get(`/personaldaten/anwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        startDate: startDate,
                        endDate: endDate,
                    }
                }),
                axiosInstance.get(`/personaldaten/abwesenheiten/`, {
                    params: {
                        mitarbeiterId: employee.mitarbeiterId,
                        startDate: startDate,
                        endDate: endDate,
                    }
                })
            ]);

            const months = [];
            for (let i = 5; i >= 0; i--) {
                months.push(dayjs().subtract(i, 'month').format('YYYY.MM'));
            }

            console.log("Anwesend/Abwesen", anwesenheitenResponse);

            const utilizationData = months.map(month => {
                const anwesenheitsData = anwesenheitenResponse.data.results.filter(a => dayjs(a.datum).format('YYYY.MM') === month);
                const abwesenheitsData = abwesenheitenResponse.data.results.filter(a => dayjs(a.datum).format('YYYY.MM') === month);
                

                console.log("Anwesend", anwesenheitsData);

                const anwesenheitsstunden = anwesenheitsData.reduce((sum, item) => sum + parseFloat(item.anwesenheitsstunden), 0);
                const abwesenheitsstunden = abwesenheitsData.reduce((sum, item) => sum + parseFloat(item.abwesenheitsstunden), 0);
                const sollzeit = (employeeDetails.wochensollBerechnet || 0) * 4.25;

                return {
                    month,
                    Name: employee.Name,
                    Sollzeit: tausenderTrennung(sollzeit.toFixed(2), 2),  // Formatierung direkt hier anwenden
                    Anwesenheiten: anwesenheitsstunden.toFixed(2),
                    Abwesenheiten: abwesenheitsstunden.toFixed(2),
                    Differenz: (sollzeit - (anwesenheitsstunden + abwesenheitsstunden)).toFixed(2),
                };
            });

            setUtilizationData(utilizationData);
        } catch (error) {
            console.error("Fehler beim Abrufen der Auslastungsdaten:", error);
            setUtilizationData([]);
        }
    };

    const aggregateAbsencesByMonth = useMemo(() => {
        const months = [];
        for (let i = 5; i >= 0; i--) {
            months.push(dayjs().subtract(i, 'month').format('YYYY.MM'));
        }
    
        const aggregatedData = months.map(month => {
            const monthlyData = chartAbsences.filter(absence => dayjs(absence.datum).format('YYYY.MM') === month);
    
            const aggregatedByType = monthlyData.reduce((acc, item) => {
                if (!acc[item.abwesenheitstyp]) {
                    acc[item.abwesenheitstyp] = 0;
                }
                acc[item.abwesenheitstyp] += parseFloat(item.abwesenheitsstunden);
                return acc;
            }, {});
    
            // Runde hier die Abwesenheitsstunden auf 2 Nachkommastellen und konvertiere zurück in eine Zahl
            Object.keys(aggregatedByType).forEach(key => {
                aggregatedByType[key] = parseFloat(aggregatedByType[key].toFixed(2));
            });
    
            return { month, ...aggregatedByType };
        });
    
        return aggregatedData;
    }, [chartAbsences]);
    

    const chartData = useMemo(() => {
        const types = [...new Set(chartAbsences.map(a => a.abwesenheitstyp))];
        return types.map(type => ({
            label: type,
            data: aggregateAbsencesByMonth.map(item => item[type] || 0)
        }));
    }, [aggregateAbsencesByMonth, chartAbsences]);

    const labels = useMemo(() => aggregateAbsencesByMonth.map(item => item.month), [aggregateAbsencesByMonth]);

    const tableColumns = useMemo(() => {
        return [
            { id: 'type', accessorKey: 'type', header: 'Abwesenheitstyp' },
            ...labels.map((label, index) => ({
                id: `col-${index}`,
                accessorKey: label,
                header: label,
            }))
        ];
    }, [labels]);

    const tableData = useMemo(() => {
        return chartData.map(row => {
            const rowData = { type: row.label };
            row.data.forEach((value, index) => {
                rowData[labels[index]] = value;
            });
            return rowData;
        });
    }, [chartData, labels]);

    const utilizationColumns = useMemo(() => {
        return [
            { id: 'month', accessorKey: 'month', header: 'Monat' },
            { id: 'Sollzeit', accessorKey: 'Sollzeit', header: 'Soll h' },
            { id: 'Anwesenheiten', accessorKey: 'Anwesenheiten', header: 'Anwesend h' },
            { id: 'Abwesenheiten', accessorKey: 'Abwesenheiten', header: 'Abwesend h' },
            { id: 'Differenz', accessorKey: 'Differenz', header: 'Diff. h' },
        ];
    }, []);

   

    const workTimesColumns = useMemo(() => [
        { accessorKey: 'datum', header: 'Datum', cell: info => dayjs(info.getValue()).format('YYYY-MM-DD') },
        { accessorKey: 'arbeitsbereichKostenstelle', header: 'Filiale' },
        { accessorKey: 'anwesenheitsstunden', header: 'Anwesenheitsstunden h', cell: info => tausenderTrennung(info.getValue(), 2) },  // Anwenden der Formatierungsfunktion
    ], []);
    
    const absencesColumns = useMemo(() => [
        { accessorKey: 'datum', header: 'Datum', cell: info => dayjs(info.getValue()).format('YYYY-MM-DD') },
        { accessorKey: 'abwesenheitstyp', header: 'Abwesenheitstyp' },
        { accessorKey: 'abwesenheitsstunden', header: 'Abwesenheitsstunden h', cell: info => tausenderTrennung(info.getValue(), 2) },  // Anwenden der Formatierungsfunktion

    ], []);
    

    const anwesenheitsstundenSumme = workTimes.reduce((sum, item) => sum + parseFloat(item.anwesenheitsstunden), 0);
    const abwesenheitsstundenSumme = absences.reduce((sum, item) => sum + parseFloat(item.abwesenheitsstunden), 0);
    const sollstunden = (employeeDetails?.wochensollBerechnet || 0) * 4.25;
    const delta = sollstunden - (anwesenheitsstundenSumme + abwesenheitsstundenSumme);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Mitarbeiter: {employeeDetails?.name || 'Unbekannt'}
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Tabs value={tabIndex} onChange={handleChange} aria-label="Employee Details Tabs">
                    <Tab label="Stammdaten" />
                    <Tab label="Arbeitszeiten" />
                    <Tab label="Abwesenheiten" />
                    <Tab label="Analyse" />
                    <Tab label="Auslastung" />
                </Tabs>
                <TabPanel value={tabIndex} index={0}>
                    <List>
                        <ListItem>
                            <ListItemText primary="Name" secondary={employeeDetails?.name || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Arbeitsbereich" secondary={employeeDetails?.arbeitsbereich || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Arbeitnehmertyp" secondary={employeeDetails?.arbeitnehmertyp || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Wochensoll " secondary={employeeDetails?.wochensollBerechnet || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Eintritt" secondary={dayjs(employeeDetails?.eintritt).format('DD.MM.YYYY') || 'Nicht verfügbar'} />
                        </ListItem>
                        <ListItem>
                            <ListItemText primary="Austritt" secondary={employeeDetails?.austritt && dayjs(employeeDetails.austritt).isValid() ? dayjs(employeeDetails.austritt).format('DD.MM.YYYY') : '-'} />
                        </ListItem>
                    </List>
                </TabPanel>
                <TabPanel value={tabIndex} index={1}>
                    <Typography variant="subtitle1">
                        Im Monat {jahrMonat} hatte {employeeDetails?.name} insgesamt {anwesenheitsstundenSumme.toFixed(2)} Anwesenheitsstunden und {abwesenheitsstundenSumme.toFixed(2)} Abwesenheitsstunden. Die Sollstunden betragen {sollstunden.toFixed(2)}. Die Differenz beträgt damit {delta.toFixed(2)}.
                    </Typography>
                    <VariableTable
                        columns={workTimesColumns}
                        data={workTimes}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={2}>
                    <Typography variant="subtitle1">
                        Im Monat {jahrMonat} hatte {employeeDetails?.name} insgesamt {anwesenheitsstundenSumme.toFixed(2)} Anwesenheitsstunden und {abwesenheitsstundenSumme.toFixed(2)} Abwesenheitsstunden. Die Sollstunden betragen {sollstunden.toFixed(2)}. Die Differenz beträgt damit {delta.toFixed(2)}.
                    </Typography>
                    <VariableTable
                        columns={absencesColumns}
                        data={absences}
                    />
                </TabPanel>
                <TabPanel value={tabIndex} index={3}>
                    <Paper>
                        <Card
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                padding: '0px',
                                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                                backdropFilter: 'blur(10px)',
                            }}
                        >
                            <CardHeader
                                title={
                                    <Typography variant="h6" component="div">
                                        Abwesenheitsanalyse
                                    </Typography>
                                }
                            />
                            <CardContent sx={{ flexGrow: 1 }}>
                                <StackedBarChart
                                    data={chartData}
                                    labels={labels}
                                    title="Abwesenheiten der letzten 6 Monate"
                                    xLabel="Monate"
                                    yLabel="Abwesenheitsstunden"
                                />
                                <VariableTable
                                    columns={tableColumns}
                                    data={tableData}
                                />
                            </CardContent>
                        </Card>
                    </Paper>
                </TabPanel>
                <TabPanel value={tabIndex} index={4}>
                    <BigTableCard
                        title="Auslastung der letzten 6 Monate"
                        data={utilizationData}
                        columns={utilizationColumns}
                    />
                </TabPanel>
            </DialogContent>
        </Dialog>
    );
};

export default EmployeeDetailsPopup;
