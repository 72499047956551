// Layout.js
import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Sidebar from './TagesplanerSidebar'; // Passen Sie den Pfad entsprechend an
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

const drawerWidth = 275;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' && prop !== 'variant' })(
  ({ theme, open, variant }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    ...(variant === 'temporary' && {
      marginTop: theme.spacing(8),
    }),
    ...(variant === 'permanent' && {
      transition: theme.transitions.create('margin-left', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `calc(${theme.spacing(7)} + 1px)`,
      ...(open && {
        transition: theme.transitions.create('margin-left', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: drawerWidth,
      }),
    }),
  }),
);

const Layout = ({ children }) => {
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      {isMobile && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          sx={{
            position: 'fixed',
            top: theme.spacing(1),
            left: theme.spacing(1),
            zIndex: theme.zIndex.appBar + 1,
            backgroundColor: 'transparent',
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Sidebar open={open} toggleDrawer={toggleDrawer} variant={isMobile ? 'temporary' : 'permanent'} />
      <Main open={open} variant={isMobile ? 'temporary' : 'permanent'}>
        {children}
      </Main>
    </>
  );
};

export default Layout;
