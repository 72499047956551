import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';

const TopicDialog = ({ open, onClose, topicName, setTopicName, handleSaveTopic }) => (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle>{topicName ? 'Thema bearbeiten' : 'Neues Thema anlegen'}</DialogTitle>
        <DialogContent>
            <TextField
                label="Thema Name"
                fullWidth
                value={topicName}
                onChange={(e) => setTopicName(e.target.value)}
                margin="normal"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={handleSaveTopic} variant="contained" color="primary">Speichern</Button>
            <Button onClick={onClose} variant="outlined">Abbrechen</Button>
        </DialogActions>
    </Dialog>
);

export default TopicDialog;
