// src/pages/Bedarf.jsx

import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Typography, Stack, Card, CardHeader, CardContent } from '@mui/material';
import Layout from '../../../components/layout/Scorecards/Unternehmen/UnternehmenMonat';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTableTree from '../../../components/table/VariableTableTree';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import BigChartCardRecharts from "../../../components/card/scorecardsmonat/BigChartCardRecharts";
import BigGaugeCard from "../../../components/card/BigGaugeCard"; // Pfad ggf. anpassen

const Bedarf = () => {
    const { filterState } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const [personalData, setPersonalData] = useState({
        krankenquote: [],
        fluktuation: [],
        bedarf: [],
        auslastung: [],
    });
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [gaugeData, setGaugeData] = useState({
        sollStunden: 0,
        wochenSollBerechnetSum: 0,
    });

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPersonalData(); // Daten abrufen
            fetchTableData(); // Daten für die Tabelle abrufen
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM');

        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM'));
        }

        return last6Months.reverse();
    };

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');
        const last6Months = getLast6Months(currentMonth);

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );

            // Kumulieren der Krankenquote-Daten
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Kumulieren der Fluktuation-Daten
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0 
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100 
                    : 0; // Ergebnis in %

                // Nur Eintritte und Austritte des gefilterten Monats
                const eintritteFiltered = data.reduce((sum, item) => sum + (item.eintritte || 0), 0);
                const austritteFiltered = data.reduce((sum, item) => sum + (item.austritte || 0), 0);

                // **Kumulieren der EintritteM und AustritteM** für alle Filialen
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0); // Kumulierte Eintritte (M)
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0); // Kumulierte Austritte (M)

                return {
                    month: last6Months[index],
                    austritte6M,
                    mitarbeiterbestand6M,
                    eintritte6M,
                    fluktuation,
                    eintritteFiltered,
                    austritteFiltered,
                    eintritteM, // Kumulierte Eintritte (M)
                    austritteM,  // Kumulierte Austritte (M)
                    köpfeDifferenz, // Kumulierte Köpfe-Differenz (Bedarf an Mitarbeiter)
                    stundenDifferenz, // Kumulierte Stunden-Differenz (Bedarf an Stunden)
                    überstundenAbs,  // Kumulierte Überstunden (Auslastung)
                };
            });

            // Kumulieren der Bedarf-Daten
            const bedarfData = responses.map((response, index) => {
                const data = response.data.results;

                const scoreKöpfeStunden = data.reduce((sum, item) => sum + (item.scoreKöpfeStunden || 0), 0);
                const anzahlSollMitarbeiter = data.reduce((sum, item) => sum + (item.anzahlSollMitarbeiter || 0), 0);
                const köpfe = data.reduce((sum, item) => sum + (item.köpfe || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const sollStunden = data.reduce((sum, item) => sum + (item.sollStunden || 0), 0);
                const wochenSollBerechnetSum = data.reduce((sum, item) => sum + (item.wochensollBerechnetSum || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);

                return {
                    month: last6Months[index],
                    scoreKöpfeStunden,
                    anzahlSollMitarbeiter,
                    köpfe,
                    köpfeDifferenz,
                    sollStunden,
                    wochenSollBerechnetSum,
                    stundenDifferenz,
                };
            });

            // Kumulieren der Auslastung-Daten
            const auslastungData = responses.map((response, index) => {
                const data = response.data.results;

                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                return {
                    month: last6Months[index],
                    überstundenAbs,
                };
            });

            // Setzen des Zustands mit allen Daten
            setPersonalData(prev => ({
                ...prev,
                krankenquote: krankenquoteData,
                fluktuation: fluktuationData,
                bedarf: bedarfData,
                auslastung: auslastungData,
            }));

            // Daten für das Liniendiagramm vorbereiten (Beispiel für Bedarf)
            const chartData = bedarfData.map(item => ({
                x: item.month,
                y: item.scoreKöpfeStunden,
                label: 'Score Köpfe Stunden',
            }));

            setChartData(chartData);

            // Daten für die GaugeCard vorbereiten (letzter Monat)
            const latestData = bedarfData[bedarfData.length - 1] || {};
            setGaugeData({
                sollStunden: latestData.sollStunden || 0,
                wochenSollBerechnetSum: latestData.wochenSollBerechnetSum || 0,
                köpfe: latestData.köpfe || 0,
                anzahlSollMitarbeiter: latestData.anzahlSollMitarbeiter || 0,
            });

            // Debugging: Konsolenausgabe der Daten
            console.log('Personal Data:', personalData);

        } catch (error) {
            console.error('Error fetching Personal data:', error);
        }
    };

    const fetchTableData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

        try {
            const response = await axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                params: { jahrMonat: currentMonth },
            });

            const data = response.data.results;

            // Tabelle formatieren
            const formattedTableData = data.map((item) => ({
                verkaufsgebiet: item.verkaufsgebiet,
                filiale: item.filiale,
                scoreKöpfeStunden: item.scoreKöpfeStunden || 0,
                anzahlSollMitarbeiter: item.anzahlSollMitarbeiter || 0,
                köpfe: item.köpfe || 0,
                köpfeDifferenz: item.köpfeDifferenz || 0,
                sollStunden: item.sollStunden || 0,
                wochenSollBerechnetSum: item.wochensollBerechnetSum || 0,
                stundenDifferenz: item.stundenDifferenz || 0,
                überstundenAbs: item.überstundenAbs || 0, // Hinzugefügt für die Auslastung DataCard
            }));

            setTableData(formattedTableData);

            // Debugging: Konsolenausgabe der Tabelle
            console.log('Table Data:', formattedTableData);

        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    // Definition der Spalten für die Tabelle
    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'scoreKöpfeStunden',
            header: 'Score',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
        {
            accessorKey: 'anzahlSollMitarbeiter',
            header: 'Soll MA',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'köpfe',
            header: 'Ist MA',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'köpfeDifferenz',
            header: 'Bedarf Köpfe',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'sollStunden',
            header: 'Soll-Stunden',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'wochenSollBerechnetSum',
            header: 'Vertragsstunden',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'stundenDifferenz',
            header: 'Bedarf Stunden',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        
        // Weitere Spalten nach Bedarf hinzufügen
    ], []);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
                <Grid item xs={12}>
                    <Stack direction="row" alignItems="stretch" spacing={2} sx={{ overflowX: 'auto' }}>
                        {/* Krankenquote DataCard */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Krankenquote"
                                sector="personal"
                                value={
                                    personalData.krankenquote.length > 0
                                        ? `${personalData.krankenquote[personalData.krankenquote.length - 1].krankenquote.toFixed(2)}%`
                                        : '0.00%'
                                }
                                subHeaders={[
                                    personalData.krankenquote.length > 0
                                        ? `${personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVM.toFixed(2)}% zum Vormonat`
                                        : '0.00% zum Vormonat',
                                    personalData.krankenquote.length > 0
                                        ? `${personalData.krankenquote[personalData.krankenquote.length - 1].krankenquoteVJ.toFixed(2)}% zum Vorjahr`
                                        : '0.00% zum Vorjahr'
                                ]}
                                chartData={personalData.krankenquote.map(item => item.krankenquote)} // Nutzen Sie die Krankenquote-Daten für den Sparkline-Chart
                            />
                        </Grid>

                        {/* Fluktuation DataCard */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Fluktuation"
                                sector="personal"
                                value={
                                    personalData.fluktuation.length > 0
                                        ? `${personalData.fluktuation[personalData.fluktuation.length - 1].fluktuation.toFixed(2)}%`
                                        : '0.00%'
                                }
                                subHeaders={[
                                    personalData.fluktuation.length > 0
                                        ? `${personalData.fluktuation[personalData.fluktuation.length - 1].eintritteM} Eintritte im gefilterten Monat`
                                        : '0 Eintritte im gefilterten Monat',
                                    personalData.fluktuation.length > 0
                                        ? `${personalData.fluktuation[personalData.fluktuation.length - 1].austritteM} Austritte im gefilterten Monat`
                                        : '0 Austritte im gefilterten Monat'
                                ]}
                                chartData={personalData.fluktuation.map(item => item.fluktuation)} // Nutzen Sie die Fluktuation-Daten für den Sparkline-Chart
                            />
                        </Grid>
                        
                        {/* Bedarf DataCard */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_active">
                            <DataCard
                                category="Bedarf"
                                sector="personal"
                                value={
                                    personalData.bedarf.length > 0
                                        ? `${personalData.bedarf[personalData.bedarf.length - 1].köpfeDifferenz} Köpfe und ${personalData.bedarf[personalData.bedarf.length - 1].stundenDifferenz} Stunden`
                                        : '0 Köpfe und 0 Stunden'
                                }
                                chartData={personalData.bedarf.map(item => item.köpfeDifferenz)}
                            />
                        </Grid>

                        {/* Auslastung DataCard */}
                        <Grid item xs={12} md={6} lg={3} className="db_datacard_not_active">
                            <DataCard
                                category="Auslastung"
                                sector="personal"
                                value={
                                    personalData.auslastung.length > 0
                                        ? `${personalData.auslastung[personalData.auslastung.length - 1].überstundenAbs} Überstunden`
                                        : '0 Überstunden'
                                }
                                subHeaders={[]}
                                chartData={personalData.auslastung.map(item => item.überstundenAbs)}
                            />
                        </Grid>
                    </Stack>
                </Grid>

                <Grid container spacing={2} mt={3}>
                            {/* Zweites Liniendiagramm */}
                            <Grid item xs={12} md={12} lg={8}>
                                <BigChartCardRecharts
                                    data={[
                                        {
                                            id: 'Bedarf Stunden',
                                            data: personalData.bedarf.map(item => ({
                                                x: item.month,
                                                y: item.stundenDifferenz,
                                            })),
                                        },
                                        {
                                            id: 'Bedarf Köpfe',
                                            data: personalData.bedarf.map(item => ({
                                                x: item.month,
                                                y: item.köpfeDifferenz,
                                            })),
                                        },
                                        // Weitere Datenreihen nach Bedarf hinzufügen
                                    ]}
                                    title="Anzahl Soll Mitarbeiter und Köpfe Differenz über die letzten 6 Monate"
                                    // Optional: height={400}
                                />
                            </Grid>

                    {/* Rechter Bereich: GaugeChart */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        lg={2}
                        display="flex" 
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <BigGaugeCard 
                            title="Bedarf an Soll Stunden"
                            infoContent={
                                <>
                                    Bedarf an Soll Stunden - Informationen<br /><br />
                                    Die Soll-Stunden werden berechnet, indem die wöchentlichen Sollstunden summiert werden. 
                                    Dieses Gauge zeigt den aktuellen Bedarf an Soll-Stunden im Vergleich zum Zielwert.
                                </>
                            }
                            value={gaugeData.wochenSollBerechnetSum}
                            target={gaugeData.sollStunden}
                            maxValue={gaugeData.sollStunden * 2} // Optional, kann angepasst werden
                            description={`Aktuell wurden ${gaugeData.wochenSollBerechnetSum} Soll-Stunden berechnet, während das Ziel bei ${gaugeData.sollStunden} Soll-Stunden liegt.`}
                        />
                    </Grid>

                    {/* zweites: GaugeChart */}
                    <Grid 
                        item 
                        xs={12} 
                        sm={6} 
                        lg={2}
                        display="flex" 
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <BigGaugeCard 
                            title="Bedarf an Soll Stunden"
                            infoContent={
                                <>
                                    Bedarf an Soll Stunden - Informationen<br /><br />
                                    Die Soll-Stunden werden berechnet, indem die wöchentlichen Sollstunden summiert werden. 
                                    Dieses Gauge zeigt den aktuellen Bedarf an Soll-Stunden im Vergleich zum Zielwert.
                                </>
                            }
                            value={gaugeData.anzahlSollMitarbeiter}
                            target={gaugeData.köpfe}
                            maxValue={gaugeData.anzahlSollMitarbeiter * 1.5} // Optional, kann angepasst werden
                            description={`Aktuell wurden ${gaugeData.köpfe} Soll-Stunden berechnet, während das Ziel bei ${gaugeData.anzahlSollMitarbeiter} Soll-Stunden liegt.`}
                        />
                    </Grid>
                </Grid>

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                        }}
                    >
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div">
                                    Bedarf Übersicht
                                </Typography>
                            }
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <VariableTableTree
                                columns={columns}
                                data={tableData}
                                initialGrouping={['verkaufsgebiet']}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );

};

export default Bedarf;
