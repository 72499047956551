import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  LinearProgress,
} from '@mui/material';
import axiosInstance from '../../../services/axiosInstance'; // Deine axios-Instanz
import Layout from '../../../components/layout/Onboarding/Onboarding'; // Dein Layout-Wrapper
import PageHeader from '../../../components/layout/Title/TitelSmall'; // Der Header
import AssessmentIcon from '@mui/icons-material/Assessment'; // Icon für den Header
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined'; // Pfeil-Icon
import { useNavigate } from 'react-router-dom'; // React Router für Navigation
import { FilterContext } from '../../../provider/FilterProvider'; // FilterProvider-Context für den Mitarbeiter-Filter

const Analytics = () => {
  const [loading, setLoading] = useState(true);
  const [onboardingPlans, setOnboardingPlans] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [topics, setTopics] = useState([]);
  const [steps, setSteps] = useState([]);
  const [topicProgress, setTopicProgress] = useState([]);
  const [stepProgress, setStepProgress] = useState([]);
  const [categories, setCategories] = useState([]); // Kategorien hinzufügen

  const navigate = useNavigate();
  const { handleEmployeeChange } = useContext(FilterContext); // Verwende den FilterContext

  useEffect(() => {
    // Daten gleichzeitig abrufen
    Promise.all([
      axiosInstance.get('/onboarding/onboarding-plans/'),
      axiosInstance.get('/onboarding/employee-assignments/'),
      axiosInstance.get('personaldaten/stammdaten-mitarbeiter/'),
      axiosInstance.get('/onboarding/topics/'),
      axiosInstance.get('/onboarding/onboarding-steps/'),
      axiosInstance.get('/onboarding/topic-progress/'),
      axiosInstance.get('/onboarding/step-progress/'),
      axiosInstance.get('/onboarding/categories/'), // Kategorien abrufen
    ])
      .then(([
        plansResponse,
        assignmentsResponse,
        employeesResponse,
        topicsResponse,
        stepsResponse,
        topicProgressResponse,
        stepProgressResponse,
        categoriesResponse, // Kategorien hinzufügen
      ]) => {
        setOnboardingPlans(plansResponse.data.results);
        setAssignments(assignmentsResponse.data.results);
        setEmployees(employeesResponse.data.results);
        setTopics(topicsResponse.data.results);
        setSteps(stepsResponse.data.results);
        setTopicProgress(topicProgressResponse.data.results);
        setStepProgress(stepProgressResponse.data.results);
        setCategories(categoriesResponse.data.results); // Kategorien setzen
        setLoading(false);
      })
      .catch((error) => {
        console.error('Fehler beim Laden der Daten:', error);
        setLoading(false);
      });
  }, []);

  // Berechnungen durchführen
  const activePlans = onboardingPlans.filter((plan) => plan.is_active);

  // Daten für die Data Cards vorbereiten
  const analyticsData = activePlans.map((plan) => {
    const inProgressAssignments = assignments.filter(
      (assignment) =>
        assignment.onboarding_plan === plan.id &&
        assignment.status === 'in_progress'
    );
    return {
      planId: plan.id,
      planName: plan.name,
      totalEmployees: inProgressAssignments.length,
    };
  });

  // Mitarbeiterdaten für die Tabelle vorbereiten
  const activeAssignments = assignments.filter(
    (assignment) => assignment.status === 'in_progress'
  );

  // Mitarbeiteransicht navigieren und filtern
  const handleViewEmployee = (employeeId) => {
    handleEmployeeChange(employeeId); // Setze den Mitarbeiter im Kontext
    navigate('/onboarding/overview/mitarbeiter'); // Navigiere zur Mitarbeiter-Seite
  };

  return (
    <Layout>
      <Box sx={{ maxWidth: 1200, mx: 'auto', mt: 4 }}>
        {/* Header der Seite */}
        <PageHeader
          title="Onboarding Analytics"
          subtitle="Übersicht der aktuellen Onboarding-Pläne und Mitarbeiter."
          Icon={AssessmentIcon} // Übergabe des Icons
        />

        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {/* Data Cards */}
            <Grid container spacing={2} sx={{ mt: 2 }}>
              {analyticsData.length > 0 ? (
                analyticsData.map((data) => (
                  <Grid item xs={12} sm={6} md={4} key={data.planId}>
                    <Card sx={{ minWidth: 275 }}>
                      <CardContent>
                        <Typography variant="h6" component="div">
                          {data.planName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Mitarbeiter in Bearbeitung:
                        </Typography>
                        <Typography variant="h4" sx={{ mt: 1 }}>
                          {data.totalEmployees}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                ))
              ) : (
                <Typography variant="body1" sx={{ mt: 2 }}>
                  Derzeit sind keine aktiven Onboarding-Pläne vorhanden.
                </Typography>
              )}
            </Grid>

            {/* Tabelle der aktiven Mitarbeiter */}
            <Paper sx={{ mt: 4, p: 2 }}>
              <Typography variant="h6" gutterBottom>
                Mitarbeiter in aktiven Onboarding-Plänen
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Mitarbeiter-ID</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Onboarding-Plan</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Startzeitpunkt</TableCell>
                    <TableCell>Fortschritt Themen (MA)</TableCell>
                    <TableCell>Fortschritt Themen (VG)</TableCell>
                    <TableCell>Fortschritt Schritte</TableCell>
                    <TableCell>Aktionen</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {activeAssignments.length > 0 ? (
                    activeAssignments.map((assignment) => {
                      const employeeId = assignment.employee;
                      const plan = onboardingPlans.find(
                        (plan) => plan.id === assignment.onboarding_plan
                      );

                      const employee = employees.find(
                        (emp) => emp.mitarbeiterId === employeeId
                      );

                      // Kategorien für den Onboarding-Plan filtern
                      const planCategories = categories.filter(
                        (category) => category.onboarding_plan === plan.id
                      );

                      // Themen für die Kategorien des Plans filtern
                      const planTopics = topics.filter((topic) =>
                        planCategories.some((category) => category.id === topic.category)
                      );

                      // Schritte für die Themen des Plans filtern
                      const planSteps = steps.filter((step) =>
                        planTopics.some((topic) => topic.id === step.topic)
                      );

                      // Fortschritt des Mitarbeiters filtern
                      const employeeTopicProgress = topicProgress.filter(
                        (progress) => progress.employee === employeeId
                      );

                      const employeeStepProgress = stepProgress.filter(
                        (progress) => progress.employee === employeeId
                      );

                      // Fortschritt des Mitarbeiters auf die Themen und Schritte des Plans beschränken
                      const employeeTopicProgressForPlan = employeeTopicProgress.filter(
                        (progress) =>
                          planTopics.some((topic) => topic.id === progress.topic_id)
                      );

                      const employeeStepProgressForPlan = employeeStepProgress.filter(
                        (progress) =>
                          planSteps.some((step) => step.id === progress.onboarding_step_id)
                      );

                      // Berechnung des Themenfortschritts (Mitarbeiter)
                      const completedTopics = employeeTopicProgressForPlan.filter(
                        (progress) => progress.employee_status === true
                      ).length;

                      const totalTopics = planTopics.length;
                      const topicCompletionPercentage =
                        totalTopics > 0
                          ? Math.round((completedTopics / totalTopics) * 100)
                          : 0;

                      // **Berechnung des Themenfortschritts (Vorgesetzter)**
                      const completedSupervisorTopics = employeeTopicProgressForPlan.filter(
                        (progress) => progress.supervisor_status === true
                      ).length;

                      const supervisorTopicCompletionPercentage =
                        totalTopics > 0
                          ? Math.round((completedSupervisorTopics / totalTopics) * 100)
                          : 0;

                      // Berechnung des Schrittfortschritts
                      const completedSteps = employeeStepProgressForPlan.filter(
                        (progress) => progress.status === true
                      ).length;

                      const totalSteps = planSteps.length;
                      const stepCompletionPercentage =
                        totalSteps > 0
                          ? Math.round((completedSteps / totalSteps) * 100)
                          : 0;

                      // Optional: Konsolenlogs für Debugging
                      /*
                      console.log('Employee ID:', employeeId);
                      console.log('Total Topics:', totalTopics);
                      console.log('Completed Topics:', completedTopics);
                      console.log('Topic Completion %:', topicCompletionPercentage);
                      console.log('Completed Supervisor Topics:', completedSupervisorTopics);
                      console.log('Supervisor Topic Completion %:', supervisorTopicCompletionPercentage);
                      console.log('Total Steps:', totalSteps);
                      console.log('Completed Steps:', completedSteps);
                      console.log('Step Completion %:', stepCompletionPercentage);
                      */

                      // Datumsformatierung für Startzeitpunkt
                      const formatDate = (dateString) => {
                        const date = new Date(dateString);
                        return date.toLocaleDateString('de-DE', {
                          year: 'numeric',
                          month: '2-digit',
                          day: '2-digit',
                        });
                      };

                      return (
                        <TableRow key={assignment.id}>
                          <TableCell>{employeeId}</TableCell>
                          <TableCell>{employee ? employee.name : 'N/A'}</TableCell>
                          <TableCell>{plan ? plan.name : 'N/A'}</TableCell>
                          <TableCell>
                            {assignment.status === 'in_progress'
                              ? 'In Bearbeitung'
                              : assignment.status === 'completed'
                              ? 'Abgeschlossen'
                              : assignment.status}
                          </TableCell>
                          <TableCell>{formatDate(assignment.assigned_at)}</TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${topicCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={topicCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${supervisorTopicCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={supervisorTopicCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <Typography variant="body2" color="textSecondary">
                              {`${stepCompletionPercentage}%`}
                            </Typography>
                            <LinearProgress
                              variant="determinate"
                              value={stepCompletionPercentage}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton onClick={() => handleViewEmployee(employeeId)}>
                              <ArrowForwardOutlinedIcon />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        Keine aktiven Mitarbeiter in Onboarding-Plänen.
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Paper>
          </>
        )}
      </Box>
    </Layout>
  );
};

export default Analytics;
