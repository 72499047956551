import axiosInstance, {setupAxiosInterceptors} from "../services/axiosInstance";
import {createContext, useContext, useEffect, useMemo, useRef, useState} from "react";

const AuthContext = createContext();

const AuthProvider = ({children}) => {
    const tokenRef = useRef(null);
    const [currentUser, setcurrentUser] = useState(null)

    const [refreshTokenExpired, setRefreshTokenExpired] = useState(() => {
        return JSON.parse(localStorage.getItem('refreshTokenExpired') || 'false');
    });

    const setToken = (newToken) => {
        tokenRef.current = newToken;
        if (newToken) {
            axiosInstance.defaults.headers.common["Authorization"] = "Bearer " + newToken;
        } else {
            delete axiosInstance.defaults.headers.common["Authorization"];
        }
    };

    const setRefreshTokenExpiredWithStorage = (value) => {
        setRefreshTokenExpired(value);
        localStorage.setItem('refreshTokenExpired', JSON.stringify(value));
    };

    const refreshAccessToken = async () => {
        try {
            const response = await axiosInstance.post("/auth/refresh/", {}, {withCredentials: true});
            const newAccessToken = response.data.access;
            setToken(newAccessToken);
            setRefreshTokenExpiredWithStorage(false);
            return true;
        } catch (error) {
            console.error("Error while refreshing token: ", error.message);
            setToken(null);
            setRefreshTokenExpiredWithStorage(true);
            return null;
        }
    };

    async function fetchUserData() {
        try {
            const response = await axiosInstance.get("/auth/user/");
            console.log("user data", response.data)
            setcurrentUser(response.data);
        } catch (error) {
            console.error("Error while fetching user data: ", error.message);
            return null;
        }
    }

    function getToken() {
        return tokenRef.current
    }

    useEffect(() => {
        setupAxiosInterceptors(getToken, refreshAccessToken);

        const initializeAuth = async () => {
            if (!tokenRef.current && !refreshTokenExpired) {
                await refreshAccessToken();
            }
            if (tokenRef.current) {
                await fetchUserData();
            }
        };

        initializeAuth().catch(console.error);
    }, []);

    const contextValue = useMemo(
        () => ({
            user: currentUser,
            setToken,
            getToken,
            refreshAccessToken,
            fetchUserData,
            refreshTokenExpired,
            setRefreshTokenExpiredWithStorage,
        }),
        [refreshTokenExpired, currentUser]
    );

    return (
        <AuthContext.Provider value={contextValue}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthProvider;
