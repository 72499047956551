import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Card, CardActionArea, CircularProgress, Badge, CardContent, Dialog, DialogTitle, DialogContent, IconButton, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axiosInstance from '../../services/axiosInstance';
import RatingForm from './RatingForm';

const FilialcheckFiliale = () => {
    const [filialchecks, setFilialchecks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedCheck, setSelectedCheck] = useState(null);
    const [saveSuccess, setSaveSuccess] = useState(false); // Neuer Zustand für das Erfolgs-Feedback

    const fetchFilialchecks = async () => {
        try {
            const response = await axiosInstance.get('/filialcheck/checks/');
            if (Array.isArray(response.data)) {
                setFilialchecks(response.data);
            } else if (response.data.results && Array.isArray(response.data.results)) {
                setFilialchecks(response.data.results);
            } else {
                console.error('Unerwartetes Antwortformat:', response.data);
            }
        } catch (error) {
            console.error('Fehler beim Laden der Filialchecks:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchFilialchecks();
    }, []);

    const handleCloseDialog = () => {
        setSelectedCheck(null);
    };

    // Funktion für erfolgreiches Speichern
    const handleSaveSuccess = () => {
        setSelectedCheck(null); // Schließt den Dialog
        setSaveSuccess(true); // Zeigt das Erfolgs-Feedback an
        setTimeout(() => setSaveSuccess(false), 3000); // Versteckt das Feedback nach 3 Sekunden
    };

    if (loading) {
        return <CircularProgress />;
    }

    const activeFilialchecks = filialchecks.filter(check => check.is_active);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: 2,
                maxWidth: 1200,
                margin: '0 auto',
            }}
        >
            {saveSuccess && (
                <Alert severity="success" sx={{ mb: 2 }}>
                    Bewertungen erfolgreich gespeichert!
                </Alert>
            )}

            {activeFilialchecks.length > 0 ? (
                <Grid container spacing={4} justifyContent="center">
                    {activeFilialchecks.map(check => (
                        <Grid item key={check.id}>
                            <Card sx={{ width: 300, height: 150 }}>
                                <CardActionArea
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}
                                    onClick={() => setSelectedCheck(check.id)}
                                >
                                    <Badge badgeContent={check.badge} sx={{ color: 'primary.main' }} invisible={!check.badge} />
                                    <CardContent sx={{ textAlign: 'left', width: '100%' }}>
                                        <Typography variant="subtitle1">
                                            {check.name}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {check.description}
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            ) : (
                <Typography variant="body1">Keine aktiven Filialchecks vorhanden.</Typography>
            )}

            {/* Fullscreen Dialog für RatingForm */}
            <Dialog
                open={Boolean(selectedCheck)}
                onClose={handleCloseDialog}
                fullScreen
                PaperProps={{
                    sx: { backgroundColor: '#f5f9f7' } // Setzt die Hintergrundfarbe des gesamten Dialogs
                }}
            >
                <DialogTitle>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleCloseDialog}
                        aria-label="close"
                        sx={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <RatingForm checkId={selectedCheck} onSaveSuccess={handleSaveSuccess} /> {/* Übergabe der Funktion */}
                </DialogContent>
            </Dialog>
        </Box>
    );
};

export default FilialcheckFiliale;
