import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import { Typography, useMediaQuery } from '@mui/material';
import { tausenderTrennung } from '../../utils/mathStuff';

const LineChartComponent = ({ data }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));

  const chartColors = [
    theme.palette.chart.yellow,
    theme.palette.chart.purpleLight,
    theme.palette.chart.orange,
    theme.palette.chart.pink,
    theme.palette.chart.red,
    theme.palette.chart.purpleDark,
  ];

  // Transformiere die Daten in ein Format, das Recharts versteht
  const chartData = [];
  if (data.length > 0) {
    const months = data[0].data.map((d) => d.x); // Hole die Monate
    months.forEach((month, index) => {
      const dataPoint = { month };
      data.forEach((series) => {
        dataPoint[series.id] = series.data[index]?.y || 0;
      });
      chartData.push(dataPoint);
    });
  }

  // Berechne den minimalen und maximalen Wert der Y-Achse
  const allYValues = data.flatMap(series => series.data.map(point => point.y || 0));
  const minYValue = Math.min(...allYValues);
  const maxYValue = Math.max(...allYValues);

  // Setze ein Padding von 20% für den minimalen und maximalen Wert
  const adjustedMinYValue = minYValue > 0 ? Math.max(0, minYValue - (maxYValue - minYValue) * 0.2) : minYValue - (maxYValue - minYValue) * 0.2;
  const adjustedMaxYValue = maxYValue + (maxYValue - minYValue) * 0.2;

  // Funktion zur konsistenten Farbauswahl basierend auf Serien-ID
  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let color = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xff;
      color += (`00${value.toString(16)}`).substr(-2);
    }
    return color;
  };

  const getColor = (index, seriesId) => {
    if (index < chartColors.length) {
      return chartColors[index];
    }
    // Generiere eine konsistente Farbe basierend auf der Serien-ID
    return stringToColor(seriesId);
  };

  // Dynamische Anpassung der Fontgrößen basierend auf Bildschirmgröße
  const getFontSize = () => {
    if (isSmallScreen) {
      return 8;
    } else if (isMediumScreen) {
      return 10;
    } else {
      return 14;
    }
  };

  const formatNumberWithCommas = (number) => {
    return tausenderTrennung(number, 0);
  };

  return (
    <ResponsiveContainer width="100%" aspect={isSmallScreen ? 1 : 3}>
      <LineChart data={chartData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="month"
          tick={{ fontSize: getFontSize() }}
          padding={{ left: 10, right: 10 }}
        />
        <YAxis
          tick={{ fontSize: getFontSize() }}
          tickFormatter={formatNumberWithCommas}
          domain={[adjustedMinYValue, adjustedMaxYValue]} // Setze dynamischen Y-Achsenbereich mit 20% Puffer
          width={isSmallScreen ? 40 : 60}
        />
        <Tooltip
          contentStyle={{
            fontSize: getFontSize(),
          }}
          formatter={(value) => formatNumberWithCommas(value)}
        />
        <Legend
          wrapperStyle={{
            fontSize: getFontSize(),
          }}
          iconType="circle"
          iconSize={getFontSize()}
        />
        {data.map((series, index) => (
          <Line
            key={series.id}
            type="monotone"
            dataKey={series.id}
            stroke={getColor(index, series.id)}
            connectNulls
            activeDot={{ r: 8 }}
          />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
