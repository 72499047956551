import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton } from '@mui/material';
import VariableTable from '../table/VariableTable';
import axiosInstance from '../../services/axiosInstance';
import { roundToDecimals } from "../../utils/mathStuff";
import CloseIcon from '@mui/icons-material/Close';


const TagesreportArtikelFilialeDetailPopup = ({ open, handleClose, artikel, datum, verkaufsgebiet }) => {
    const [filialeData, setFilialeData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (open && artikel && datum) {
            fetchFilialeData(); // Filter nach Artikel
        }
    }, [open, artikel, datum]);

    const fetchFilialeData = async () => {
        try {
            setLoading(true);
            const response = await axiosInstance.get('/fact/facts/', {
                params: {
                    artikel: artikel,
                    startdatum: datum,
                    enddatum: datum,
                    verkaufsgebiet:verkaufsgebiet
                }
            });

            if (Array.isArray(response.data.results)) {
                const mappedFilialeData = response.data.results
                .filter(item => item.retourmenge > 0)
                .map(item => {
                    const lieferwertTotal = item.lieferwert + item.korrekturwert;
                    const retourquote = lieferwertTotal > 0 ? (item.retourwert / lieferwertTotal) * 100 : 0;
                    return {
                        Filiale: item.filiale,
                        Retourmenge: item.retourmenge || 0,
                        Retourwert: `${roundToDecimals(item.retourwert || 0, 2)} €`,
                        Retourquote: `${retourquote.toFixed(2)} %`,
                    };
                });

                setFilialeData(mappedFilialeData);
            }
        } catch (error) {
            console.error('Error fetching Filiale data:', error);
        } finally {
            setLoading(false);
        }
    };

    const filialeColumns = [
        { accessorKey: 'Filiale', header: 'Filiale', size: 200 },
        { accessorKey: 'Retourmenge', header: 'Retourmenge', size: 150 },
        { accessorKey: 'Retourwert', header: 'Retourwert (€)', size: 150 },
        { accessorKey: 'Retourquote', header: 'Retourquote (%)', size: 150 },
    ];

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>Details für Artikel: {artikel}

            <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <VariableTable
                    columns={filialeColumns}
                    data={filialeData}
                    loading={loading}
                />
            </DialogContent>
            
        </Dialog>
    );
};

export default TagesreportArtikelFilialeDetailPopup;
