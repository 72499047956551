// WarengruppeArtikelDialog.jsx

import React, { useState, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Typography,
  Box,
  TablePagination,
} from '@mui/material';
import axios from '../../../services/axiosInstance'; // Passen Sie den Pfad an
import qs from 'qs'; // Für Query-Serialisierung
import { formatQuantity, formatValue, formatPercentageMitZeichen } from '../../../utils/mathStuff';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

const WarengruppeArtikelDialog = ({
  open,
  onClose,
  timeFilter, // 'Monat', 'Woche', 'Tag'
  selectedFiliale, // Array von Filialnamen
  selectedWarengruppe, // Die ausgewählte Warengruppe (String)
  selectedVerkaufsgebiete,
  selectedDataKeys, // Array der ausgewählten Kennzahlen
  dateRange, // Datumsbereich von der Hauptkomponente
}) => {
  const [data, setData] = useState([]);
  const [periods, setPeriods] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Pagination States
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // API-Endpunkte basierend auf timeFilter
  const getApiEndpoint = () => {
    switch (timeFilter) {
      case 'Monat':
        return '/fact/aggregated/by-month-warengruppe-artikel/';
      case 'Woche':
        return '/fact/aggregated/by-week-warengruppe-artikel/';
      case 'Tag':
        return '/fact/aggregated/by-day-warengruppe-artikel/';
      default:
        return '/fact/aggregated/by-month-warengruppe-artikel/';
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      setError(null);
      axios
        .get(getApiEndpoint(), {
          params: {
            filiale: selectedFiliale.length ? selectedFiliale : undefined,
            verkaufsgebiet: selectedVerkaufsgebiete.length? selectedVerkaufsgebiete: undefined,
            warengruppe: selectedWarengruppe ? [selectedWarengruppe] : undefined,
            start_date: dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : undefined,
            end_date: dateRange[1] ? dateRange[1].format('YYYY-MM-DD') : undefined,
          },
          paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        })
        .then((response) => {
          setData(response.data);

          // Extrahiere und formatiere die Perioden basierend auf timeFilter
          let formattedPeriods;
          if (timeFilter === 'Monat') {
            formattedPeriods = Array.from(
              new Set(response.data.map((row) => dayjs(row.month).format('MMMM YYYY')))
            ).sort((a, b) =>
              dayjs(a, 'MMMM YYYY').isAfter(dayjs(b, 'MMMM YYYY')) ? 1 : -1
            );
            setPeriods(formattedPeriods);
          } else if (timeFilter === 'Woche') {
            formattedPeriods = Array.from(
              new Set(response.data.map((row) => `KW ${dayjs(row.week).isoWeek()}`))
            ).sort((a, b) => {
              const weekA = parseInt(a.replace('KW ', ''));
              const weekB = parseInt(b.replace('KW ', ''));
              return weekA - weekB;
            });
            setPeriods(formattedPeriods);
          } else {
            // 'Tag'
            formattedPeriods = Array.from(
              new Set(response.data.map((row) => dayjs(row.day).format('DD.MM.YYYY')))
            ).sort((a, b) =>
              dayjs(a, 'DD.MM.YYYY').isAfter(dayjs(b, 'DD.MM.YYYY')) ? 1 : -1
            );
            setPeriods(formattedPeriods);
          }

          // Reset Pagination beim Laden neuer Daten
          setPage(0);
        })
        .catch((error) => {
          console.error('Fehler beim Abrufen der Artikel-Daten:', error);
          setError('Fehler beim Laden der Daten.');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [open, timeFilter, selectedFiliale, selectedWarengruppe, dateRange]);

  // Helper-Funktion zur Formatierung der Typ-Namen
  const formatTypName = (dataKey) => {
    if (!dataKey) return 'UNDEFINED';
    return dataKey
      .replace('total_', '')
      .replace('_menge', '')
      .replace('_wert', '')
      .replace('_umsatz', '')
      .replace('_retourquote', '')
      .replace(/_/g, ' ')
      .toUpperCase();
  };

  // Berechnung der Gesamtsummen pro dataKey und per period
  const calculateTotalSums = () => {
    const total = {};
    data.forEach((row) => {
      let period;
      if (timeFilter === 'Monat') {
        period = dayjs(row.month).format('MMMM YYYY');
      } else if (timeFilter === 'Woche') {
        period = `KW ${dayjs(row.week).isoWeek()}`;
      } else {
        // 'Tag'
        period = dayjs(row.day).format('DD.MM.YYYY');
      }

      selectedDataKeys.forEach((dataKey) => {
        if (!total[dataKey]) {
          total[dataKey] = {};
        }
        if (!total[dataKey][period]) {
          total[dataKey][period] = 0;
        }
        total[dataKey][period] += row[dataKey] || 0;
      });
    });
    return total;
  };

  const totalSums = useMemo(
    () => calculateTotalSums(),
    [data, selectedDataKeys, timeFilter]
  );

  // Gruppiere Daten nach Artikel und Typ, mit Zuordnung zu den Perioden
  const grouped = useMemo(() => {
    const group = {};

    data.forEach((row) => {
      const artikel = row.artikelnummer__artikelbezeichnung;
      let period;
      if (timeFilter === 'Monat') {
        period = dayjs(row.month).format('MMMM YYYY');
      } else if (timeFilter === 'Woche') {
        period = `KW ${dayjs(row.week).isoWeek()}`;
      } else {
        // 'Tag'
        period = dayjs(row.day).format('DD.MM.YYYY');
      }

      if (!group[artikel]) {
        group[artikel] = {};
      }

      selectedDataKeys.forEach((dataKey) => {
        if (!group[artikel][dataKey]) {
          group[artikel][dataKey] = {};
        }
        group[artikel][dataKey][period] = row[dataKey] || 0;
      });
    });

    return group;
  }, [data, selectedDataKeys, timeFilter]);

  // Handle Pagination Change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle Rows Per Page Change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Transformation der Daten für die Tabelle mit Pagination
  const tableRows = useMemo(() => {
    const allRows = [];

    Object.entries(grouped).forEach(([artikel, dataKeysObj]) => {
      Object.entries(dataKeysObj).forEach(([dataKey, periodValues]) => {
        allRows.push({
          artikel,
          dataKey,
          periodValues,
        });
      });
    });

    return allRows;
  }, [grouped]);

  // Berechne die angezeigten Zeilen basierend auf der aktuellen Seite und den Zeilen pro Seite
  const displayedRows = useMemo(() => {
    return tableRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [tableRows, page, rowsPerPage]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      {/* Dialog-Titel mit Warengruppenname */}
      <DialogTitle>{`Artikel in Warengruppe ${selectedWarengruppe}`}</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="200px">
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error">{error}</Typography>
        ) : data.length === 0 ? (
          <Typography>Noch keine Daten verfügbar.</Typography>
        ) : (
          <>
            {/* Tabelle mit Scroll und Pagination */}
            <TableContainer component={Paper} sx={{ maxHeight: 400 }}>
              <Table stickyHeader aria-label="Artikel Tabelle" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: -20,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                        padding: '6px',
                      }}
                    >
                      Artikel
                    </TableCell>
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: -20,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                        padding: '6px',
                      }}
                    >
                      Typ
                    </TableCell>
                    {periods.map((period, index) => (
                      <TableCell
                        key={index}
                        sx={{
                          position: 'sticky',
                          top: -20,
                          backgroundColor: '#fff',
                          zIndex: 1000,
                          padding: '6px',
                        }}
                      >
                        {period}
                      </TableCell>
                    ))}
                    <TableCell
                      sx={{
                        position: 'sticky',
                        top: -20,
                        backgroundColor: '#fff',
                        zIndex: 1000,
                        padding: '6px',
                      }}
                    >
                      Summe
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedRows.map((row, rowIndex) => (
                    <TableRow key={`${row.artikel}-${row.dataKey}-${rowIndex}`}>
                      {/* Artikel nur in der ersten Zeile jedes Artikels anzeigen */}
                      {tableRows.indexOf(row) % selectedDataKeys.length === 0 && (
                        <TableCell rowSpan={selectedDataKeys.length} sx={{ padding: '6px' }}>
                          {row.artikel}
                        </TableCell>
                      )}
                      <TableCell sx={{ padding: '6px' }}>{formatTypName(row.dataKey)}</TableCell>
                      {periods.map((period, periodIdx) => (
                        <TableCell key={periodIdx} sx={{ padding: '6px' }}>
                          {row.dataKey.includes('menge') && formatQuantity(row.periodValues[period])}
                          {(row.dataKey.includes('wert') || row.dataKey.includes('umsatz')) &&
                            formatValue(row.periodValues[period])}
                          {row.dataKey.includes('retourquote') &&
                            formatPercentageMitZeichen(row.periodValues[period])}
                        </TableCell>
                      ))}
                      <TableCell sx={{ padding: '6px' }}>
                        {formatValue(
                          Object.values(row.periodValues || {}).reduce((sum, val) => sum + (val || 0), 0)
                        )}
                      </TableCell>
                    </TableRow>
                  ))}

                  {/* Globale Gesamtsumme Block */}
                  <React.Fragment>
                    {/* Gesamtsumme Hauptzeile */}
                    <TableRow>
                      <TableCell
                        rowSpan={selectedDataKeys.length} // Verbindet die Gesamtsumme mit den Typ-Zeilen
                        sx={{ padding: '6px', fontWeight: 'bold', verticalAlign: 'top' }}
                      >
                        Gesamtsumme
                      </TableCell>
                      {/* Erste Gesamtsumme Typ-Zeile */}
                      <TableCell sx={{ padding: '6px' }}>
                        {selectedDataKeys[0] ? formatTypName(selectedDataKeys[0]) : ''}
                      </TableCell>
                      {periods.map((period, idx) => (
                        <TableCell key={idx} sx={{ padding: '6px' }}>
                          {selectedDataKeys[0].includes('menge') &&
                            formatQuantity(totalSums[selectedDataKeys[0]]?.[period])}
                          {(selectedDataKeys[0].includes('wert') ||
                            selectedDataKeys[0].includes('umsatz')) &&
                            formatValue(totalSums[selectedDataKeys[0]]?.[period])}
                          {selectedDataKeys[0].includes('retourquote') &&
                            formatPercentageMitZeichen(totalSums[selectedDataKeys[0]]?.[period])}
                        </TableCell>
                      ))}
                      <TableCell sx={{ padding: '6px' }}></TableCell> {/* Leere Zelle für Summe */}
                    </TableRow>

                    {/* Weitere Gesamtsumme Typ-Zeilen */}
                    {selectedDataKeys.slice(1).map((dataKey) => (
                      <TableRow key={`global-${dataKey}`}>
                        <TableCell sx={{ padding: '6px' }}>{formatTypName(dataKey)}</TableCell>
                        {periods.map((period, idx) => (
                          <TableCell key={idx} sx={{ padding: '6px' }}>
                            {dataKey.includes('menge') && formatQuantity(totalSums[dataKey]?.[period])}
                            {(dataKey.includes('wert') || dataKey.includes('umsatz')) &&
                              formatValue(totalSums[dataKey]?.[period])}
                            {dataKey.includes('retourquote') &&
                              formatPercentageMitZeichen(totalSums[dataKey]?.[period])}
                          </TableCell>
                        ))}
                        <TableCell sx={{ padding: '6px' }}></TableCell> {/* Leere Zelle für Summe */}
                      </TableRow>
                    ))}
                  </React.Fragment>
                </TableBody>
              </Table>
              {/* Pagination */}
              <TablePagination
                component="div"
                count={tableRows.length} // Gesamte Zeilenanzahl ohne Gesamtsumme
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                rowsPerPageOptions={[5, 10, 25, 50]}
              />
            </TableContainer>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Schließen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default WarengruppeArtikelDialog;
