import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Layout from '../../../components/layout/recruiting/recruiting';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import axiosInstance from '../../../services/axiosInstance';
import BigTableCard from '../../../components/card/BigTableCard';

const Stellen = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Daten aus der API abrufen
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/recruiting/dispolisten/');
            const results = response.data.results;

            // Konvertiere boolean-Werte zu "Ja" und "Nein"
            const modifiedData = results.map(item => ({
                ...item,
                aktiv: item.aktiv ? 'Ja' : 'Nein',
                extranet_freigeben: item.extranet_freigeben ? 'Ja' : 'Nein',
                oeffentlich: item.oeffentlich ? 'Ja' : 'Nein',
            }));

            setData(modifiedData);
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Spalten-Definition für die Tabelle
    const columns = [
        { header: 'ID', accessorKey: 'id' },
        { header: 'Name', accessorKey: 'name' },
        { header: 'Beschreibung', accessorKey: 'beschreibung' },
        { header: 'Aktiv', accessorKey: 'aktiv' },
        { header: 'Extranet Freigeben', accessorKey: 'extranet_freigeben' },
        { header: 'Einsatzort', accessorKey: 'einsatzort' },
        { header: 'Erstellt Am', accessorKey: 'erstellt_am' },
        { header: 'Anzahl Bewerber', accessorKey: 'anzahl_bewerber' },
        { header: 'Öffentlich', accessorKey: 'oeffentlich' },
    ];

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Stellen"
                        subtitle="Diese Übersicht zeigt alle im System erfassten Stellen. Sie bietet eine einfache Möglichkeit, schnell einen Überblick über sämtliche angelegten Positionen zu erhalten, inklusive relevanter Details wie Status, Einsatzort und Anzahl der Bewerber."
                        Icon={BarChartIcon}
                    />
                </Box>
            </Box>

            {/* Daten laden */}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <BigTableCard
                    title="Recruiting Übersicht"
                    data={data}
                    columns={columns}
                    onRowClick={(row) => console.log('Zeile geklickt:', row)}
                    muiTableBodyCellProps={{
                        sx: {
                            padding: '8px',
                        },
                    }}
                />
            )}
        </Layout>
    );
};

export default Stellen;
