import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BigChartCardRecharts from '../card/scorecardsmonat/BigChartCardRecharts';

const WareWachstumTAWarengruppePopup = ({ open, handleClose, data, warengruppeName }) => {
    // Helper function to clean and format the number
    const formatData = (inputData) => {
        return inputData.map(item => ({
            ...item,
            data: item.data.map(point => {
                let yValue = point.y;

                // Debugging: Log the original value
                console.log('Original yValue:', yValue);

                // Check if the value has more than 3 decimal places
                if (yValue % 1 !== 0 && yValue.toString().split('.')[1]?.length >= 3) {
                    yValue *= 1000; // Multiply by 1000 if there are more than 3 decimal places
                }

                // Debugging: Log the formatted value
                console.log('Formatted yValue:', yValue);

                // Return the cleaned point
                return {
                    ...point,
                    y: isNaN(yValue) ? 0 : yValue,
                };
            }),
        }));
    };

    // Format the data
    const formattedData = formatData(data);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Verlauf für {warengruppeName}
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <BigChartCardRecharts
                    data={formattedData} // Use the formatted data
                    title={`Verkaufstrend - ${warengruppeName}`}
                    height={300}
                />
            </DialogContent>
        </Dialog>
    );
};

export default WareWachstumTAWarengruppePopup;
