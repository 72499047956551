import React, {useState} from 'react';
import {List, ListItem, ListItemText, IconButton} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditCategoryDialog from './EditCategoryDialog';

const KategorieListeKomponente = ({categories, onCategoryNameChange, onCategoryDelete}) => {
    const [open, setOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const handleEditClick = (category) => {
        setSelectedCategory(category);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedCategory(null);
    };

    const handleSave = (categoryId, newName) => {
        onCategoryNameChange(categoryId, newName);
    };

    return (
        <>
            <List>
                {categories.map((category) => (
                    <ListItem key={category.id}>
                        <ListItemText primary={category.name}/>
                        <IconButton onClick={() => handleEditClick(category)}>
                            <EditIcon/>
                        </IconButton>
                        <IconButton onClick={() => onCategoryDelete(category.id)}>
                            <DeleteIcon/>
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            {selectedCategory && (
                <EditCategoryDialog
                    open={open}
                    onClose={handleClose}
                    category={selectedCategory}
                    onSave={handleSave}
                />
            )}
        </>
    );
};

export default KategorieListeKomponente;
