import React from "react";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material"; 

const PieChartFilialtyp = ({ data, metric, height, width }) => {
  const theme = useTheme();

  const COLORS = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.chart?.yellow || "#FFEB3B",
    theme.palette.chart?.orange || "#FF9800",
    theme.palette.chart?.red || "#F44336",
    theme.palette.chart?.pink || "#E91E63",
    theme.palette.chart?.purpleLight || "#AB47BC",
    theme.palette.chart?.purpleDark || "#7B1FA2",
  ];

  const sumField = `${metric}Summe`;

  const totalValue = data.reduce((acc, item) => acc + (item[sumField] || 0), 0);

  if (totalValue <= 0) {
    return (
      <Box sx={{ textAlign: 'center', padding: 2 }}>
        <Typography variant="body1">Keine Daten zum Anzeigen verfügbar.</Typography>
      </Box>
    );
  }

  // Berechnen der Daten für das Diagramm
  const chartData = data.map((item, index) => {
    const value = item[sumField] || 0;
    const percentage = totalValue > 0 ? ((value / totalValue) * 100).toFixed(2) : 0;

    return {
      name: item.filialtyp || 'Unbekannt',  // Sicherstellen, dass es einen Namen gibt
      value: value,
      percentage: percentage,
    };
  });

  return (
    <Box sx={{ width: '100%', height: height || 300, minWidth: 400 }}>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie
            data={chartData}
            cx="50%"  // Zentriert das Diagramm horizontal
            cy="50%"
            innerRadius={60}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={5}
            dataKey="value"
          >
            {chartData.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      {/* Box für die Legende */}

    </Box>
  );
};

export default PieChartFilialtyp;
