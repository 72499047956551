import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const DropdownFilter = ({ label, options, value, onChange,  error, helperText, sx, disabled = false, small = false, useNameAsLabel = false }) => {
    return (
        <Autocomplete
            disablePortal
            id={`${label.toLowerCase()}-combo-box`}
            options={options}
            getOptionLabel={(option) => useNameAsLabel ? option.name || '' : option.label || ''}
            value={value}
            onChange={(event, newValue) => onChange(newValue)}
            isOptionEqualToValue={(option, value) => option.name === value.name}
            sx={{ ...sx, width: small ? 180 : 230, marginBottom: 0 }}
            disabled={disabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    size="small"
                    sx={{ fontSize: '0.8rem' }}
                    error={error}
                    helperText={helperText}
                    disabled={disabled}
                />
            )}
        />
    );
};

export default DropdownFilter;
