import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import DropdownFilter from '../filter/DropdownFilter';
import React, {useState} from "react";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const ArtikelDetailPopup = ({open, onClose, artikel}) => {
    // if (artikel) {
    //     console.log("current artikel", artikel)
    // }
    // Define options for dropdowns
    const geschmackOptions = [
        { label: '-', name: '' },
        { label: 'Pikant', name: 'pikant' },
        { label: 'Süß', name: 'sues' },
        { label: 'Scharf', name: 'scharf' }
    ];

    const teigOptions = [
        { label: '-', name: '' },
        { label: 'Hefe', name: 'hefe' },
        { label: 'Blätter', name: 'bleatter' },
        { label: 'Mürb', name: 'muerb' }
    ];

    let fakeWareneinsatz = Math.floor(Math.random() * 100);
    const displayedText = artikel.artikelbezeichnung.replace(/[a-zäöüß]+/gi, word =>
        word.toLowerCase().replace(/^./, c => c.toUpperCase())
    );

    // Add state for Geschmack and Teig
    const [geschmack, setGeschmack] = useState(geschmackOptions[0]);
    const [teig, setTeig] = useState(teigOptions[0]);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle>Artikel Details
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon/>
                </IconButton></DialogTitle>
            <DialogContent>
                {artikel ?
                    <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
                        <Typography
                            sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: 3,
                                WebkitBoxOrient: 'vertical',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                                WebkitHyphens: 'auto',
                                msHyphens: 'auto',
                                marginBottom: 1,
                                height: '4.5em',
                                fontSize: '1.1rem'
                            }}
                        >
                            {displayedText}
                        </Typography>
                        <Box sx={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', gap: 1}}>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.main', fontSize: '1rem'}}>Wareneinsatz:</Typography>
                                <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{fakeWareneinsatz}</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'text.main', fontSize: '1rem'}}>Retoure:</Typography>
                                <Typography sx={{color: 'text.main', fontSize: '1rem'}}>{artikel.retoure}%</Typography>
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 2}}>
                                <Typography sx={{color: 'text.secondary', fontSize: '1rem', minWidth: '80px'}}>Geschmack:</Typography>
                                <DropdownFilter
                                    label=""
                                    options={geschmackOptions}
                                    value={geschmack}
                                    onChange={setGeschmack}
                                    useNameAsLabel={false}
                                    small={true}
                                />
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center', gap: 2}}>
                                <Typography sx={{color: 'text.secondary', fontSize: '1rem', minWidth: '80px'}}>Teig:</Typography>
                                <DropdownFilter
                                    label=""
                                    options={teigOptions}
                                    value={teig}
                                    onChange={setTeig}
                                    useNameAsLabel={false}
                                    small={true}
                                />
                            </Box>
                            <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                                <Typography sx={{color: 'success.main', fontSize: '1rem'}}>Umsatz:</Typography>
                                <Typography sx={{color: 'success.main', fontSize: '1rem'}}>{artikel.umsatz} €</Typography>
                            </Box>
                        </Box>
                    </Box> : null}
            </DialogContent>
        </Dialog>
    );
};

export default ArtikelDetailPopup;