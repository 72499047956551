import React, { useEffect, useState, useContext } from 'react';
import axios from '../../../services/axiosInstance';
import Layout from '../../../components/layout/recruiting/recruiting';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import BigChartCardRecharts from '../../../components/card/scorecardsmonat/BigChartCardRecharts';
import UebersichtControlling from '../../../components/card/personalcontrolling/UebersichtControlling';
import { FilterContext } from '../../../provider/FilterProvider';

// Importiere die anderen Komponenten
import CustomBarChart from '../../../components/card/personalcontrolling/CustomBarChart';
import CustomPieChart from '../../../components/card/personalcontrolling/CustomPieChart';

// Import date-fns functions
import { format, subMonths } from 'date-fns';

const Controlling = () => {
  const [bewerberData, setBewerberData] = useState([]);
  const [monatVerlaufDataRaw, setMonatVerlaufDataRaw] = useState([]);
  const [absagenAnzahl, setAbsagenAnzahl] = useState(0);
  const [durchschnittlicheZeitBisAbsage, setDurchschnittlicheZeitBisAbsage] = useState(0);
  const [zusagenAnzahl, setZusagenAnzahl] = useState(0);
  const [durchschnittlicheZeitBisZusage, setDurchschnittlicheZeitBisZusage] = useState(0);
  const { filterState } = useContext(FilterContext);

  // Bewerberdaten-Abfrage
  useEffect(() => {
    axios
      .get('/recruiting/bewerber/', {
        params: {
          jahrMonat: filterState.datum?.value,
        },
      })
      .then((response) => {
        const data = response.data.results;
        if (Array.isArray(data)) {
          setBewerberData(data);
        } else {
          console.error('Die API-Antwort ist kein Array: ', data);
          setBewerberData([]);
        }
      })
      .catch((error) => console.error('Fehler beim Laden der Daten: ', error));
  }, [filterState]);

  // Anzahl der Absagen im ausgewählten Monat
  useEffect(() => {
    axios
      .get('/recruiting/bewerber/', {
        params: {
          absage_jahrMonat: filterState.datum?.value,
        },
      })
      .then((response) => {
        setAbsagenAnzahl(response.data.results.length);
      })
      .catch((error) => console.error('Fehler beim Laden der Absagedaten: ', error));
  }, [filterState.datum]);

  // Durchschnittliche Zeit bis zur Absage im ausgewählten Monat
  useEffect(() => {
    axios
      .get('/recruiting/bewerber/', {
        params: {
          jahrMonat: filterState.datum?.value,
        },
      })
      .then((response) => {
        const bewerberMitAbsage = response.data.results.filter((b) => b.absage); // Nur Bewerbungen mit Absage
        const durchschnittZeit = bewerberMitAbsage.reduce((acc, curr) => {
          const eingangsdatum = new Date(curr.eingangsdatum);
          const absagedatum = new Date(curr.absage);
          const diffInTagen = (absagedatum - eingangsdatum) / (1000 * 60 * 60 * 24); // Unterschied in Tagen
          return acc + diffInTagen;
        }, 0) / bewerberMitAbsage.length;
        setDurchschnittlicheZeitBisAbsage(durchschnittZeit || 0);
      })
      .catch((error) => console.error('Fehler beim Berechnen der Zeit bis zur Absage', error));
  }, [filterState.datum]);

  // Anzahl der Zusagen im ausgewählten Monat
  useEffect(() => {
    axios
      .get('/recruiting/bewerber/', {
        params: {
          vertragsunterzeichnung_jahrMonat: filterState.datum?.value,
        },
      })
      .then((response) => {
        setZusagenAnzahl(response.data.results.length);
      })
      .catch((error) => console.error('Fehler beim Laden der Zusagedaten: ', error));
  }, [filterState.datum]);

  // Durchschnittliche Zeit bis zur Zusage (Vertragsunterzeichnung) im ausgewählten Monat
  useEffect(() => {
    axios
      .get('/recruiting/bewerber/', {
        params: {
          jahrMonat: filterState.datum?.value,
        },
      })
      .then((response) => {
        const bewerberMitZusage = response.data.results.filter((b) => b.vertragsunterzeichnung); // Nur Bewerbungen mit Vertragsunterzeichnung
        const durchschnittZeit = bewerberMitZusage.reduce((acc, curr) => {
          const eingangsdatum = new Date(curr.eingangsdatum);
          const zusagedatum = new Date(curr.vertragsunterzeichnung);
          const diffInTagen = (zusagedatum - eingangsdatum) / (1000 * 60 * 60 * 24); // Unterschied in Tagen
          return acc + diffInTagen;
        }, 0) / bewerberMitZusage.length;
        setDurchschnittlicheZeitBisZusage(durchschnittZeit || 0);
      })
      .catch((error) => console.error('Fehler beim Berechnen der Zeit bis zur Zusage', error));
  }, [filterState.datum]);

  // Fetch data for the MonatVerlauf chart
  useEffect(() => {
    const selectedDateStr = filterState.datum?.value;
    const selectedDate = selectedDateStr ? new Date(selectedDateStr) : new Date();

    const endDate = selectedDate;
    const startDate = subMonths(endDate, 5);

    axios
      .get('/recruiting/bewerber/', {
        params: {
          startDate: format(startDate, 'yyyy-MM-dd'),
          endDate: format(endDate, 'yyyy-MM-dd'),
        },
      })
      .then((response) => {
        const data = response.data.results;
        if (Array.isArray(data)) {
          setMonatVerlaufDataRaw(data);
        } else {
          console.error('Die API-Antwort ist kein Array: ', data);
          setMonatVerlaufDataRaw([]);
        }
      })
      .catch((error) => console.error('Fehler beim Laden der Daten: ', error));
  }, [filterState.datum]);

  // Datenaufbereitung für die Diagramme
  const monatVerlauf = prepareMonatVerlaufData(
    monatVerlaufDataRaw,
    filterState.datum?.value
  );
  const arbeitszeitFormData = prepareArbeitszeitFormData(bewerberData);
  const aufmerksamDurchData = prepareAufmerksamDurchData(bewerberData);
  const geschlechtData = prepareGeschlechtData(bewerberData);
  const absageGrundData = prepareAbsageGrundData(bewerberData);
  const absageQuote = calculateAbsageQuote(bewerberData);
  const einstellungsQuote = calculateEinstellungsQuote(bewerberData);
  const durchschnittsAlter = calculateDurchschnittsAlter(bewerberData);

  return (
    <Layout>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PageHeader
            title="Controlling"
            subtitle="Diese Übersicht bietet einen schnellen Überblick über den aktuellen Recruiting-Status im gesamten Unternehmen. In den Berichten können detaillierte Informationen eingesehen werden, um tiefere Einblicke in die Bewerbungsprozesse zu gewinnen und gezielte Maßnahmen zur Personalbeschaffung abzuleiten. Ziel ist es, die Recruiting-Aktivitäten effizient zu steuern und den Personalbedarf optimal zu decken."
            Icon={BarChartIcon}
          />
        </Box>
      </Box>

      {/* Erste Zeile */}
      <Grid container spacing={2}>
        
        {/* Linke Spalte: Zusagen, Absagen und Offene Stellen Karten untereinander */}
        <Grid item xs={12} md={6}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <UebersichtControlling kundengruppeFilter={'1,2,3,4,5'} datum={filterState.datum?.value} /> {/* Beispiel für den Kundengruppenfilter */}
            </Grid>
            <Grid item>
              <Card>
                <CardHeader title={<Typography variant="h6">Zusagen</Typography>} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Einstellungsquote</Typography>
                      <Typography variant="h6">{einstellungsQuote.toFixed(2)}%</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Zeit bis zur Zusage</Typography>
                      <Typography variant="h6">{durchschnittlicheZeitBisZusage.toFixed(2)} Tage</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Anzahl Zusagen</Typography>
                      <Typography variant="h6">{zusagenAnzahl}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item>
              <Card>
                <CardHeader title={<Typography variant="h6">Absagen</Typography>} />
                <CardContent>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Absagequote</Typography>
                      <Typography variant="h6">{absageQuote.toFixed(2)}%</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Zeit bis zur Absage</Typography>
                      <Typography variant="h6">{durchschnittlicheZeitBisAbsage.toFixed(2)} Tage</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography variant="subtitle1">Anzahl Absagen</Typography>
                      <Typography variant="h6">{absagenAnzahl}</Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Grid>

        {/* Rechte Spalte: Line Chart für Monatsverlauf */}
        <Grid item xs={12} md={6}>
          <BigChartCardRecharts
            data={monatVerlauf}
            title="Anzahl Bewerber im Monatsverlauf"
            infoContent="Dies zeigt den Verlauf der Anzahl der Bewerber über die letzten Monate."
            height={300}
            width="100%"
          />
        </Grid>
      </Grid>

      {/* Zweite Zeile mit 3 Bar Charts in 2 Reihen */}
      <Grid container spacing={2}>
        
        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={<Typography variant="h6">Anzahl Bewerber nach Arbeitszeitform</Typography>} />
            <CardContent>
              <CustomBarChart
                data={arbeitszeitFormData}
                labelKey="arbeitszeitform"
                valueKey="anzahl"
                color="#8884d8"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={<Typography variant="h6">Anzahl Bewerber nach Aufmerksamkeitsquelle</Typography>} />
            <CardContent>
              <CustomBarChart
                data={aufmerksamDurchData}
                labelKey="aufmerksamDurch"
                valueKey="anzahl"
                color="#82ca9d"
              />
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Card>
            <CardHeader title={<Typography variant="h6">Absagegründe</Typography>} />
            <CardContent>
              <CustomBarChart
                data={absageGrundData}
                labelKey="absagegrund"
                valueKey="anzahl"
                color="#ff7300"
              />
            </CardContent>
          </Card>
        </Grid>

         {/* Rechtes Grid: Pie Chart und Bar Chart untereinander */}
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <CardHeader title={<Typography variant="h6">Anzahl Bewerber nach Geschlecht</Typography>} />
            <CardContent>
              <CustomPieChart
                data={geschlechtData}
                dataKey="anzahl"
                nameKey="geschlecht"
              />
            </CardContent>
          </Card>
        </Grid>

      </Grid>

    </Layout>
  );
};

export default Controlling;



// Helper functions
const prepareMonatVerlaufData = (data, selectedDateStr) => {
  const selectedDate = selectedDateStr ? new Date(selectedDateStr) : new Date();
  const months = [];
  for (let i = 5; i >= 0; i--) {
    const date = subMonths(selectedDate, i);
    const month = format(date, 'yyyy-MM');
    months.push(month);
  }

  const monatVerlaufData = months.map((month) => {
    const count = data.filter((item) => {
      const eingangsdatum = new Date(item.eingangsdatum);
      const itemMonth = format(eingangsdatum, 'yyyy-MM');
      return itemMonth === month;
    }).length;
    return { x: month, y: count };
  });

  return [
    {
      id: 'bewerber',
      data: monatVerlaufData,
    },
  ];
};


const prepareArbeitszeitFormData = (data) => {
  const arbeitszeitFormData = data.reduce((acc, curr) => {
    const form = curr.arbeitszeitform || 'Unbekannt';
    acc[form] = acc[form] ? acc[form] + 1 : 1;
    return acc;
  }, {});

  return Object.keys(arbeitszeitFormData).map(key => ({
    arbeitszeitform: key,
    anzahl: arbeitszeitFormData[key],
  })).sort((a, b) => b.anzahl - a.anzahl);
};

const prepareAufmerksamDurchData = (data) => {
  const aufmerksamDurchData = data.reduce((acc, curr) => {
    const source = curr.aufmerksamDurch || 'Unbekannt';
    acc[source] = acc[source] ? acc[source] + 1 : 1;
    return acc;
  }, {});

  return Object.keys(aufmerksamDurchData).map(key => ({
    aufmerksamDurch: key,
    anzahl: aufmerksamDurchData[key],
  })).sort((a, b) => b.anzahl - a.anzahl);
};

const prepareAbsageGrundData = (data) => {
  const absageGrundData = data.reduce((acc, curr) => {
    if (curr.absagegrund) {
      const grund = curr.absagegrund || 'Unbekannt';
      acc[grund] = acc[grund] ? acc[grund] + 1 : 1;
    }
    return acc;
  }, {});

  return Object.keys(absageGrundData).map(key => ({
    absagegrund: key,
    anzahl: absageGrundData[key],
  })).sort((a, b) => b.anzahl - a.anzahl);
};




const prepareGeschlechtData = (data) => {
  const geschlechtData = data.reduce((acc, curr) => {
    const gender = curr.geschlecht || 'Unbekannt';
    acc[gender] = acc[gender] ? acc[gender] + 1 : 1;
    return acc;
  }, {});

  return Object.keys(geschlechtData).map(key => ({
    geschlecht: key,
    anzahl: geschlechtData[key],
  }));
};

const calculateAbsageQuote = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }

  const absagen = data.filter(b => b.absage).length;
  const gesamt = data.length;

  return (absagen / gesamt) * 100;
};


const calculateEinstellungsQuote = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return 0;
    }
  
    const absagen = data.filter(b => b.vertragsunterzeichnung).length;
    const gesamt = data.length;
  
    return (absagen / gesamt) * 100;
  };

const calculateDurchschnittsAlter = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return 0;
  }

  const totalAge = data.reduce((sum, b) => sum + (b.alter || 0), 0);
  return totalAge / data.length;
};
