import React, { useEffect, useState } from 'react';
import { Card, CardContent, Grid, Typography, Divider } from '@mui/material';
import axios from '../../../services/axiosInstance'; // Axios-Service importieren

const OffeneStellenBewerberCard = ({ kundengruppeFilter }) => {
  const [offeneStellenCount, setOffeneStellenCount] = useState(0);
  const [bewerberCount, setBewerberCount] = useState(0);

  useEffect(() => {
    // API-Aufruf für offene Stellen
    axios.get('/recruiting/dispolisten', {
      params: {
        aktiv: 'Ja',
        oeffentlich: 'Ja'
      }
    })
    .then(response => {
      const offeneStellenResults = response.data.results;
      setOffeneStellenCount(offeneStellenResults.length); // Anzahl der offenen Stellen
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der offenen Stellen-Daten', error);
    });

    // API-Aufruf für Bewerber mit Kundengruppenfilter
    axios.get('/recruiting/bewerber', {
      params: {
        kundengruppeID: kundengruppeFilter
      }
    })
    .then(response => {
      const bewerberResults = response.data.results;
      setBewerberCount(bewerberResults.length); // Anzahl der Bewerber
    })
    .catch(error => {
      console.error('Fehler beim Abrufen der Bewerber-Daten', error);
    });
  }, [kundengruppeFilter]);

  return (
    <Card sx={{ height: '150px', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <CardContent>
        <Typography variant="h6" align="center">Übersicht</Typography>
        <Divider sx={{ my: 2 }} />
        <Grid container justifyContent="space-between">
          <Grid item xs={6} textAlign="center">
            <Typography variant="body2">Anzahl offene Stellen</Typography>
            <Typography variant="h5">{offeneStellenCount}</Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Typography variant="body2">Anzahl Bewerber</Typography>
            <Typography variant="h5">{bewerberCount}</Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default OffeneStellenBewerberCard;
