import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import Layout from '../../../components/layout/recruiting/recruiting';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import axiosInstance from '../../../services/axiosInstance';
import BigTableCard from '../../../components/card/BigTableCard';

const Bewerber = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    // Daten aus der API abrufen
    const fetchData = async () => {
        try {
            const response = await axiosInstance.get('/recruiting/bewerber/');
            const results = response.data.results;

            setData(results); // API-Ergebnisse in den State setzen
        } catch (error) {
            console.error('Fehler beim Abrufen der Daten:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    // Spalten-Definition für die Tabelle
    const columns = [
        { header: 'Kundennummer', accessorKey: 'bw_kundennummer' },
        { header: 'Bewerber', accessorKey: 'bewerber' },
        { header: 'Geburtsdatum', accessorKey: 'geburtsdatum' },
        { header: 'Alter', accessorKey: 'alter' },
        { header: 'Geschlecht', accessorKey: 'geschlecht' },
        { header: 'Filialnummer', accessorKey: 'filialnummer' },
        { header: 'Kundengruppe', accessorKey: 'bw_kundengruppe' },
        { header: 'Eingangsdatum', accessorKey: 'bw_eintrittsdatum' },
    ];

    return (
        <Layout>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', mt: 2 }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <PageHeader
                        title="Bewerber"
                        subtitle="Diese Übersicht zeigt alle im System erfassten Bewerber, inklusive relevanter Details wie Geburtsdatum, Alter und Filialnummer."
                        Icon={BarChartIcon}
                    />
                </Box>
            </Box>

            {/* Daten laden */}
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                    <CircularProgress />
                </Box>
            ) : (
                <BigTableCard
                    title="Bewerber Übersicht"
                    data={data}
                    columns={columns}
                    onRowClick={(row) => console.log('Zeile geklickt:', row)} // Hier kannst du eine Funktion hinzufügen, die bei einem Klick auf eine Zeile ausgeführt wird
                    muiTableBodyCellProps={{
                        sx: {
                            padding: '8px',
                        },
                    }}
                />
            )}
        </Layout>
    );
};

export default Bewerber;
