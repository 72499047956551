// src/components/BigGaugeCard.jsx

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  CardHeader,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import GaugeBedarf from "../charts/GaugeBedarf"; // Passe den Pfad entsprechend an
import { useTheme } from "@mui/material/styles";

const BigGaugeCard = ({
  title,
  infoContent,
  value,
  target,
  maxValue,
  description,
  height,
  width,
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Dynamische Anpassung der Höhe basierend auf Bildschirmgröße
  const dynamicHeight = height || (isSmallScreen ? 300 : 400);

  return (
    <Paper
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          width: width || "100%",
          padding: "0px",
          backgroundColor: "rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
        }}
      >
        <CardHeader
          title={
            <Typography
              variant={isSmallScreen ? "h6" : "h6"}
              component="div"
            >
              {title}
            </Typography>
          }
          action={
            infoContent && (
              <Tooltip title={infoContent} arrow placement="top">
                <IconButton>
                  <InfoOutlinedIcon />
                </IconButton>
              </Tooltip>
            )
          }
        />
        <CardContent
          sx={{
            flexGrow: 1, // Das Gauge füllt den verbleibenden Platz
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            padding: {
              xs: 1, // 8px Innenabstand auf kleinen Geräten
              sm: 2, // 16px auf Tablets
              md: 3, // 24px auf größeren Geräten
            },
          }}
        >
          <GaugeBedarf
            value={value}
            target={target}
            maxValue={maxValue}
          />
          {description && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{ marginTop: 2, textAlign: "center" }}
            >
              {description}
            </Typography>
          )}
        </CardContent>
      </Card>
    </Paper>
  );
};

// Definiere die PropTypes für bessere Typprüfung und Dokumentation
BigGaugeCard.propTypes = {
  title: PropTypes.string.isRequired,
  infoContent: PropTypes.node, // Optional, kann JSX sein
  value: PropTypes.number.isRequired,
  target: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  description: PropTypes.string, // Optional
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Optional
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // Optional
};

export default BigGaugeCard;
