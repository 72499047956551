import React, {useState, useEffect} from 'react';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button} from '@mui/material';

const EditCategoryDialog = ({open, onClose, category, onSave}) => {
    const [name, setName] = useState('');

    useEffect(() => {
        if (category) {
            setName(category.name);
        }
    }, [category]);

    const handleSave = () => {
        onSave(category.id, name);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Kategorie bearbeiten</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ändere den Namen der Kategorie und speichere die Änderungen.
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Kategorie Name"
                    type="text"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Abbrechen
                </Button>
                <Button onClick={handleSave} color="primary">
                    Speichern
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EditCategoryDialog;
