// SettingsLayout.js
import React from 'react';
import Sidebar from '../GeneralSidebar'; // Assuming Sidebar is your extracted sidebar component
import GeneralLayout from '../GeneralLayout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import GroupIcon from '@mui/icons-material/Group';
import StoreIcon from "@mui/icons-material/Store";

const SettingsLayout = ({ children }) => {
    const menuItems = [
        { text: 'Nutzerverwaltung', path: '/settings/rechte/nutzer-verwalten', icon: <AccountCircleIcon /> },
        { text: 'Rollenverwaltung', path: '/settings/rechte/rollen-verwalten', icon: <GroupIcon /> },
        { text: 'Filialenzuweisung', path: '/settings/rechte/filialen-verwalten', icon: <StoreIcon /> },
    ];

    return (
        <GeneralLayout SidebarComponent={(props) => <Sidebar {...props} menuItems={menuItems} title={'Einstellungen'} />}>
            {children}
        </GeneralLayout>
    );
};

export default SettingsLayout;
