// Pfad: pages/your-page-path/Timetable.jsx

import React, { useEffect, useState, useRef, useContext, useMemo } from 'react';
import Layout from '../../../components/layout/Tagesplaner/Tagesplaner';
import {
  Box, Card, Typography, useTheme, Button, IconButton, Dialog, DialogTitle,
  DialogContent, TextField, DialogActions, List, ListItem, Checkbox, Snackbar, Alert
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import axiosInstance from '../../../services/axiosInstance'; // Verwende die konfigurierte Axios-Instanz
import TagesplanerBackhinweis from '../../../components/popup/TagesplanerBackhinweis';
import DropdownFilter from '../../../components/filter/DropdownFilter';
import { FilterContext } from "../../../provider/FilterProvider";
import { useAuth } from "../../../provider/authProvider";

// Import für Recharts
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Dot } from 'recharts';

const WEEKDAYS = [
    { name: 'Montag', id: 0 },
    { name: 'Dienstag', id: 1 },
    { name: 'Mittwoch', id: 2 },
    { name: 'Donnerstag', id: 3 },
    { name: 'Freitag', id: 4 },
    { name: 'Samstag', id: 5 },
    { name: 'Sonntag', id: 6 },
];

const Timetable = () => {
  const theme = useTheme();
  const { user, loading } = useAuth(); // Ladezustand aus `useAuth`
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [currentHour, setCurrentHour] = useState(dayjs().hour());
  const [showAllHours, setShowAllHours] = useState(false);
  const [filialeOptions, setFilialeOptions] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: 'success' });

  // Zustände für OneTimeTodo
  const [oneTimeTodoDialogOpen, setOneTimeTodoDialogOpen] = useState(false);
  const [oneTimeTodoData, setOneTimeTodoData] = useState({
      title: '',
      description: '',
      due_date: '',
      due_time: '',
      filialnummer: '',
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedHour, setSelectedHour] = useState(null);
  const [newTodo, setNewTodo] = useState('');
  const timetableRef = useRef(null);
  const [articleDialogOpen, setArticleDialogOpen] = useState(false);
  const [articleDialogHour, setArticleDialogHour] = useState(null);
  const [lastOpenedHour, setLastOpenedHour] = useState(null);
  const [tagesplanerData, setTagesplanerData] = useState([]);
  const [chartDialogOpen, setChartDialogOpen] = useState(false); // Neuer State für das Chart-Popup

  // Neue Zustände für To-Dos
  const [oneTimeTodos, setOneTimeTodos] = useState([]);
  const [recurringTodos, setRecurringTodos] = useState([]);
  const [mappedTodos, setMappedTodos] = useState({}); // Struktur: { hour: [todos] }

  const { filterState, handleFilialeChange } = useContext(FilterContext);

  // Aktualisieren der aktuellen Stunde jede Minute
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentHour(dayjs().hour());
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  // Scrollen zur aktuellen Stunde
  useEffect(() => {
    if (timetableRef.current) {
      const currentHourElement = timetableRef.current.querySelector(`#hour-${currentHour}`);
      if (currentHourElement) {
        currentHourElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  }, [currentHour]);

  // Fetch Filialoptionen vom Backend
  // Fetch Filialoptionen vom Backend und setzen in FilterContext
useEffect(() => {
  const fetchFilialeOptions = async () => {
    try {
      const response = await axiosInstance.get('stammdaten/unique-filiale/');
      const options = response.data.map((item) => ({
        label: item.filiale,
        value: item.filialnummer,
      }));

      setFilialeOptions(options);

      // Setze die erste Filiale im FilterContext nur, wenn noch keine ausgewählt wurde
      if (options.length > 0 && !filterState.filiale) {
        handleFilialeChange(options[0]);  // Nur initiale Auswahl, wenn keine Filiale gesetzt ist
      }
    } catch (error) {
      console.error('Fehler beim Laden der Filialen:', error);
      setSnackbar({ open: true, message: 'Fehler beim Laden der Filialen.', severity: 'error' });
    }
  };

  fetchFilialeOptions();
}, [filterState.filiale, handleFilialeChange]);  // Abhängig von `filterState.filiale`


  // Fetch Tagesplaner-Daten vom Backend
  useEffect(() => {
    const fetchTagesplanerData = async () => {
      try {
        const currentDate = dayjs().format('YYYY-MM-DD');
        const filialnummer = filterState.filiale?.value;

        if (filialnummer) {
          const response = await axiosInstance.get('tagesplaner/tagesplaner-summary/', {
            params: {
              filialbezeichnung: filialnummer,
              datum: currentDate,
            },
          });
          console.log('API Response:', response.data);

          // Extrahieren des 'results'-Arrays
          let data = response.data.results || [];

          setTagesplanerData(data);
        }
      } catch (error) {
        console.error('Fehler beim Laden der Tagesplaner-Daten:', error);
        setTagesplanerData([]);
      }
    };

    fetchTagesplanerData();
  }, [filterState.filiale]);

  // Fetch OneTimeTodos und RecurringTodos basierend auf Filialnummer und Datum/Wochentag
  useEffect(() => {
    const fetchTodos = async () => {
      try {
        const filialnummer = filterState.filiale?.value;
        if (!filialnummer) {
          setOneTimeTodos([]);
          setRecurringTodos([]);
          return;
        }

        const today = dayjs().format('YYYY-MM-DD'); // Heutiges Datum
        const todayWeekday = dayjs().day(); // 0 (Sunday) - 6 (Saturday)

        // Abrufen der einmaligen To-Dos für heute
        const oneTimeResponse = await axiosInstance.get('tagesplaner/one-time-todos/', {
          params: {
            filialnummer: filialnummer,
            due_date: today, // Nur To-Dos mit Fälligkeitsdatum heute abrufen
          },
        });

        // Abrufen der wiederkehrenden To-Dos für den heutigen Wochentag
        const recurringResponse = await axiosInstance.get('tagesplaner/recurring-todos/', {
          params: {
            filialnummer: filialnummer,
            weekdays: todayWeekday, // Annahme: 'day' ist ein Integer von 0 bis 6
          },
        });

        setOneTimeTodos(oneTimeResponse.data.results);
        setRecurringTodos(recurringResponse.data.results);

      } catch (error) {
        console.error('Fehler beim Laden der To-Dos:', error);
        setOneTimeTodos([]);
        setRecurringTodos([]);
      }
    };

    fetchTodos();
  }, [filterState.filiale]);

  // Daten nach Stunden strukturieren und To-Dos zuordnen
  useEffect(() => {
    const mapTodosToHours = () => {
      const mapping = {};

      // One-Time Todos für heute
      const filteredOneTimeTodos = oneTimeTodos.filter((todo) => {
        const dueDate = dayjs(todo.due_date).format('YYYY-MM-DD');
        const today = dayjs().format('YYYY-MM-DD');
        return dueDate === today;
      });

      filteredOneTimeTodos.forEach((todo) => {
        const hour = parseInt(todo.due_time.split(':')[0], 10);
        if (!mapping[hour]) {
          mapping[hour] = [];
        }
        mapping[hour].push({
          id: todo.id,
          title: todo.titel,
          description: todo.beschreibung,
          completed: todo.completed || false, // Annahme: Es gibt ein 'completed' Feld
          completedBy: todo.completed_by || '', // Annahme: Es gibt ein 'completed_by' Feld
          type: 'one_time',
        });
      });

      // Recurring Todos für den aktuellen Wochentag
      const todayWeekday = dayjs().day();
      const filteredRecurringTodos = recurringTodos.filter((todo) => {
        return todo.weekdays.includes(todayWeekday);
      });

      filteredRecurringTodos.forEach((todo) => {
        const hour = parseInt(todo.due_time.split(':')[0], 10);
        if (!mapping[hour]) {
          mapping[hour] = [];
        }
        mapping[hour].push({
          id: todo.id,
          title: todo.titel,
          description: todo.beschreibung,
          completed: todo.completed || false,
          completedBy: todo.completed_by || '',
          type: 'recurring',
        });
      });

      setMappedTodos(mapping);
    };

    mapTodosToHours();
  }, [oneTimeTodos, recurringTodos]);

  const hours = Array.from({ length: 18 }, (_, index) => index + 4); // Stunden von 4 bis 21
  const visibleHours = showAllHours ? hours : hours.filter((hour) => hour >= currentHour);

  // Handler für das Öffnen des allgemeinen Todo-Dialogs (nicht genutzt, aber beibehalten)
  const handleOpenDialog = (hour) => {
    setSelectedHour(hour);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setNewTodo('');
  };

  const handleSaveTodo = () => {
    setTodos((prevTodos) => ({
      ...prevTodos,
      [selectedHour]: [
        ...(prevTodos[selectedHour] || []),
        { text: newTodo, completed: false, completedBy: '' },
      ],
    }));
    handleCloseDialog();
  };

  const handleOpenArticleDialog = (hour) => {
    setArticleDialogHour(hour);
    setArticleDialogOpen(true);
  };

  const handleCloseArticleDialog = () => {
    setArticleDialogOpen(false);
    setArticleDialogHour(null);
  };

  const handleToggleTodo = async (hour, todoId, type) => {
    try {
      let url = '';
      if (type === 'one_time') {
        url = `tagesplaner/one-time-todos/${todoId}/`;
      } else if (type === 'recurring') {
        url = `tagesplaner/recurring-todos/${todoId}/`;
      }

      // Abrufen des aktuellen Todo-Status
      const response = await axiosInstance.get(url);
      const todo = response.data;

      // Aktualisieren des 'completed' Status
      const updatedTodo = {
        ...todo,
        completed: !todo.completed,
        completed_by: user.username, // Annahme: 'completed_by' ist ein Feld, das den User speichert
      };

      // Senden der Aktualisierung an das Backend
      await axiosInstance.patch(url, updatedTodo);

      // Aktualisieren des lokalen Zustands
      setMappedTodos((prevMappedTodos) => {
        const updatedTodos = { ...prevMappedTodos };
        const todosForHour = updatedTodos[hour].map((t) => {
          if (t.id === todoId && t.type === type) {
            return {
              ...t,
              completed: updatedTodo.completed,
              completedBy: updatedTodo.completed_by,
            };
          }
          return t;
        });
        updatedTodos[hour] = todosForHour;
        return updatedTodos;
      });

      setSnackbar({ open: true, message: 'Todo erfolgreich aktualisiert.', severity: 'success' });
    } catch (error) {
      console.error('Fehler beim Aktualisieren des Todos:', error);
      setSnackbar({ open: true, message: 'Fehler beim Aktualisieren des Todos.', severity: 'error' });
    }
  };

  // Handler zum Öffnen des OneTimeTodo-Dialogs
  const handleOpenOneTimeTodoDialog = (hour) => {
    setSelectedHour(hour);
    setOneTimeTodoDialogOpen(true);
    setOneTimeTodoData({
        title: '',
        description: '',
        due_date: dayjs().format('YYYY-MM-DD'), // Setze das Datum auf heute
        due_time: dayjs().hour(hour).minute(0).format('HH:mm'), // Setze die Zeit auf die Startstunde der ausgewählten Karte
        filialnummer: filterState.filiale?.value || '', // Setze die Filialnummer aus dem Filter
    });
  };

  // Handler zum Schließen des OneTimeTodo-Dialogs
  const handleCloseOneTimeTodoDialog = () => {
    setOneTimeTodoDialogOpen(false);
    setOneTimeTodoData({
        title: '',
        description: '',
        due_date: dayjs().format('YYYY-MM-DD'), // Optional: Setze das Datum auf heute
        due_time: '', // Optional: Leere Zeit oder behalten
        filialnummer: filterState.filiale?.value || '', // Setze die Filialnummer aus dem Filter
    });
  };

   // Daten nach Stunden strukturieren
   const tagesplanerByHour = useMemo(() => {
    const mapping = {};
    if (Array.isArray(tagesplanerData)) {
      tagesplanerData.forEach((item) => {
        if (item && typeof item.uhrzeit === 'string') {
          const hour = parseInt(item.uhrzeit.split(':')[0], 10);
          mapping[hour] = item;
        } else {
          console.warn('Item mit undefinierter uhrzeit:', item);
        }
      });
    }
    return mapping;
  }, [tagesplanerData]);

  // Handler zum Ändern der OneTimeTodo-Formulardaten
  const handleOneTimeTodoChange = (e) => {
      const { name, value } = e.target;
      setOneTimeTodoData(prevState => ({
          ...prevState,
          [name]: value,
      }));
  };

  // Handler zum Speichern des OneTimeTodo
  const handleSaveOneTimeTodo = async () => {
      // Validierung der erforderlichen Felder
      const { title, due_date, due_time, filialnummer } = oneTimeTodoData;
      console.log('OneTimeTodoData:', oneTimeTodoData); // Debugging
      if (!title || !due_date || !due_time || !filialnummer) {
          setSnackbar({ open: true, message: 'Bitte alle erforderlichen Felder ausfüllen.', severity: 'error' });
          return;
      }

      // Validierung des Fälligkeitsdatums
      const selectedDate = dayjs(due_date);
      const today = dayjs().startOf('day');
      if (selectedDate.isBefore(today)) {
          setSnackbar({ open: true, message: 'Das Fälligkeitsdatum kann nicht in der Vergangenheit liegen.', severity: 'error' });
          return;
      }

      try {
          const response = await axiosInstance.post('/tagesplaner/one-time-todos/', {
              titel: oneTimeTodoData.title,
              beschreibung: oneTimeTodoData.description,
              filialnummer: oneTimeTodoData.filialnummer,
              due_date: oneTimeTodoData.due_date,
              due_time: oneTimeTodoData.due_time,
              todo_type: 'one_time',
          });

          // Erfolgreich erstellt
          const newTodo = response.data;
          setSnackbar({ open: true, message: 'Einmaliges Todo erfolgreich erstellt!', severity: 'success' });

          // Aktualisieren der lokalen To-Do-Liste
          setOneTimeTodos((prev) => [...prev, newTodo]);

          handleCloseOneTimeTodoDialog();
      } catch (error) {
          console.error('Fehler beim Erstellen des einmaligen Todos:', error);
          const errorMessage = error.response?.data?.detail || 'Fehler beim Erstellen des einmaligen Todos.';
          setSnackbar({ open: true, message: errorMessage, severity: 'error' });
      }
  };

  // Funktion zum Öffnen des Chart-Popups
  const handleOpenChartDialog = () => {
    setChartDialogOpen(true);
  };

  const handleCloseChartDialog = () => {
    setChartDialogOpen(false);
  };

  // Daten für das Chart vorbereiten
  const chartData = useMemo(() => {
    if (!Array.isArray(tagesplanerData)) return [];
    return tagesplanerData.map((item) => ({
      uhrzeit: item.uhrzeit.substring(0, 5), // Uhrzeit im Format 'HH:mm'
      kundenanzahl: item.kundenanzahl,
      scoreTagesplaner: item.scoreTagesplaner,
    }));
  }, [tagesplanerData]);

  // Funktion zum Bestimmen der Punktfarbe basierend auf scoreTagesplaner
  const getPointColor = (score) => {
    if (score >= 1 && score < 4) {
      return theme.palette.success.main; // Rot
    } else if (score >= 4 && score < 7) {
      return theme.palette.warning.main; // Gelb
    } else if (score >= 7) {
      return theme.palette.error.main; // Grün
    } else {
      return theme.palette.grey[500]; // Standardfarbe
    }
  };

  // Angepasstes Dot-Rendering für die Linie
  const CustomizedDot = (props) => {
    const { cx, cy, payload } = props;
    const color = getPointColor(payload.scoreTagesplaner);
    return (
      <Dot cx={cx} cy={cy} r={5} fill={color} />
    );
  };

  // Funktion zum Schließen der Snackbar
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
        return;
    }
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  // Anzeige einer Ladeanzeige, wenn der Benutzer noch geladen wird
  if (loading) {
      return (
          <Layout>
              <Box sx={{ padding: 3, textAlign: 'center' }}>
                  <Typography variant="h6">Lade Benutzerinformationen...</Typography>
              </Box>
          </Layout>
      );
  }

  // Anzeige einer Nachricht, wenn der Benutzer nicht authentifiziert ist
  if (!user) {
      return (
          <Layout>
              <Box sx={{ padding: 3, textAlign: 'center' }}>
                  <Typography variant="h6" color="error">
                      Du bist nicht authentifiziert. Bitte melde dich an.
                  </Typography>
              </Box>
          </Layout>
      );
  }

  return (
    <Layout>
      <PageHeader
        title="Tagesplaner"
        subtitle="Übersicht des aktuellen Tags"
        Icon={BarChartIcon}
      />
      <Box sx={{ marginTop: 2, padding: 2, maxHeight: '80vh', overflowY: 'auto' }} ref={timetableRef}>
        <DropdownFilter
          label="Filiale"
          options={filialeOptions}
          value={filterState.filiale}
          onChange={handleFilialeChange}
        />

        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: 2 }}>
          <Box>
            {!showAllHours && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowAllHours(true)}
                sx={{ marginRight: 1 }}
              >
                Alle Stunden anzeigen
              </Button>
            )}
            {showAllHours && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setShowAllHours(false)}
                sx={{ marginRight: 1 }}
              >
                Stunden ausblenden
              </Button>
            )}
            {/* Neuer Button zum Öffnen des Chart-Popups */}
            <Button
              variant="contained"
              color="primary"
              onClick={handleOpenChartDialog}
            >
              Kundenverlauf anzeigen
            </Button>
          </Box>
        </Box>

        {visibleHours.map((hour) => {
          const dataForHour = tagesplanerByHour[hour];
          let borderColor = 'transparent';

          if (dataForHour) {
            const score = dataForHour.scoreTagesplaner;
            if (score >= 1 && score < 4) {
              borderColor = theme.palette.success.main; // Rot
            } else if (score >= 4 && score < 7) {
              borderColor = theme.palette.warning.main; // Gelb
            } else if (score >= 7) {
              borderColor = theme.palette.error.main; // Grün
            }
          }

          const todosForHour = mappedTodos[hour] || [];

          return (
            <Card
              key={hour}
              id={`hour-${hour}`}
              sx={{
                marginBottom: 2,
                padding: 2,
                position: 'relative',
                backgroundColor:
                  currentHour === hour ? theme.palette.primary.light : theme.palette.background.paper,
                border: `2px solid ${borderColor}`,
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6">{`${hour}:00 - ${hour + 1}:00`}</Typography>
                <Box>
                  {/* Neues AddIcon zum Öffnen des OneTimeTodo-Dialogs */}
                  <IconButton
                    onClick={() => handleOpenOneTimeTodoDialog(hour)}
                    color="primary"
                    aria-label="add one-time todo"
                  >
                    <AddIcon />
                  </IconButton>

                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpenArticleDialog(hour)}
                  >
                    Artikel anzeigen
                  </Button>
                </Box>
              </Box>

              {/* Anzeige der Kundenanzahl */}
              {dataForHour && (
                <Typography variant="body1" sx={{ marginTop: 1 }}>
                  Kundenanzahl: {dataForHour.kundenanzahl}
                </Typography>
              )}

              {/* Anzeige der To-Dos als Checkboxes */}
              {todosForHour.length > 0 && (
                <Box sx={{ marginTop: 2 }}>
                  <Typography variant="subtitle1">To-Dos:</Typography>
                  <List>
                    {todosForHour.map((todo) => (
                      <ListItem key={`${todo.type}-${todo.id}`} sx={{ paddingLeft: 0 }}>
                        <Checkbox
                          checked={todo.completed}
                          onChange={() => handleToggleTodo(hour, todo.id, todo.type)}
                          color="primary"
                        />
                        <Typography
                          sx={{
                            textDecoration: todo.completed ? 'line-through' : 'none',
                            color: todo.completed ? theme.palette.text.secondary : 'inherit',
                          }}
                        >
                          {todo.title}
                        </Typography>
                        {todo.completed && (
                          <Typography variant="caption" sx={{ marginLeft: 1 }}>
                            (Abgehakt von: {todo.completedBy})
                          </Typography>
                        )}
                      </ListItem>
                    ))}
                  </List>
                </Box>
              )}
            </Card>
          );
        })}
      </Box>

      {/* Dialog zum Hinzufügen eines neuen To-Dos */}
      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Neues To-Do für {selectedHour}:00 hinzufügen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="To-Do"
            type="text"
            fullWidth
            variant="standard"
            value={newTodo}
            onChange={(e) => setNewTodo(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Abbrechen
          </Button>
          <Button onClick={handleSaveTodo} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog zum Hinzufügen eines neuen OneTimeTodo */}
      <Dialog open={oneTimeTodoDialogOpen} onClose={handleCloseOneTimeTodoDialog} fullWidth maxWidth="sm">
          <DialogTitle>Neues einmaliges Todo hinzufügen</DialogTitle>
          <DialogContent>
              <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
                  <TextField
                      fullWidth
                      label="Titel"
                      name="title"
                      value={oneTimeTodoData.title}
                      onChange={handleOneTimeTodoChange}
                      margin="normal"
                      required
                  />
                  <TextField
                      fullWidth
                      label="Beschreibung"
                      name="description"
                      value={oneTimeTodoData.description}
                      onChange={handleOneTimeTodoChange}
                      margin="normal"
                      multiline
                      rows={3}
                  />
                  <TextField
                      fullWidth
                      label="Fälligkeitsdatum"
                      name="due_date"
                      type="date"
                      value={oneTimeTodoData.due_date}
                      onChange={handleOneTimeTodoChange}
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      required
                  />
                  <TextField
                      fullWidth
                      label="Fälligkeitszeit"
                      name="due_time"
                      type="time"
                      value={oneTimeTodoData.due_time}
                      onChange={handleOneTimeTodoChange}
                      margin="normal"
                      InputLabelProps={{
                          shrink: true,
                      }}
                      inputProps={{
                          step: 300, // 5 Minuten Schritte
                      }}
                      required
                  />
              </Box>
          </DialogContent>
          <DialogActions>
              <Button onClick={handleCloseOneTimeTodoDialog} color="secondary">Abbrechen</Button>
              <Button onClick={handleSaveOneTimeTodo} variant="contained" color="primary">Speichern</Button>
          </DialogActions>
      </Dialog>

      {/* Dialog zum Anzeigen der Artikel */}
      <TagesplanerBackhinweis
        open={articleDialogOpen}
        onClose={handleCloseArticleDialog}
        hour={articleDialogHour}
        filiale={filterState.filiale?.label}
      />

      {/* Dialog zum Anzeigen des Liniendiagramms */}
      <Dialog open={chartDialogOpen} onClose={handleCloseChartDialog} maxWidth="md" fullWidth>
        <DialogTitle>Kundenverlauf</DialogTitle>
        <DialogContent>
          <LineChart
            width={600}
            height={400}
            data={chartData}
            margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="uhrzeit" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Line
              type="monotone"
              dataKey="kundenanzahl"
              stroke={theme.palette.primary.main}
              dot={<CustomizedDot />}
            />
          </LineChart>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseChartDialog} color="primary">
            Schließen
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar für Erfolg und Fehler */}
      <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
          <Alert onClose={handleSnackbarClose} severity={snackbar.severity} sx={{ width: '100%' }}>
              {snackbar.message}
          </Alert>
      </Snackbar>
    </Layout>
  );
};

export default Timetable;
