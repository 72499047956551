// DataCard.jsx

import * as React from 'react';
import { Card, Typography, Stack, Box, CardActionArea } from '@mui/material';
import Sparkline from '../../charts/Sparkline2';
import EuroIcon from '@mui/icons-material/Euro';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import TimerIcon from '@mui/icons-material/Timer';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import GroupRemoveIcon from '@mui/icons-material/GroupRemove';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import Battery3BarIcon from '@mui/icons-material/Battery3Bar';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import AccessibilityNewOutlinedIcon from '@mui/icons-material/AccessibilityNewOutlined';
import FaceRetouchingOffOutlinedIcon from '@mui/icons-material/FaceRetouchingOffOutlined';
import NoAccountsOutlinedIcon from '@mui/icons-material/NoAccountsOutlined';


const getCategoryIcon = (category) => {
    const iconProps = { sx: { fontSize: '2.0rem', color: 'primary.main', mr: 1 } };

    switch (category.toLowerCase()) {
        case 'umsatz':
            return <EuroIcon {...iconProps} />;
        case 'kundenanzahl':
            return <ShoppingCartIcon {...iconProps} />;
        case 'kundenbon':
            return <AddShoppingCartIcon {...iconProps} />;
        case 'leistung':
            return <TimerIcon {...iconProps} />;
        case 'krankenquote':
            return <VaccinesIcon {...iconProps} />;
        case 'abwesenheiten':
            return <NoAccountsOutlinedIcon {...iconProps} />;
        case 'fluktuation':
            return <GroupRemoveIcon {...iconProps} />;
        case 'bedarf':
            return <ManageSearchIcon {...iconProps} />;
        case 'auslastung':
            return <Battery3BarIcon {...iconProps} />;
        case 'neuzugänge':
            return <AccessibilityNewOutlinedIcon {...iconProps} />;
         case 'struktur':
            return <PeopleAltOutlinedIcon {...iconProps} />;
            case 'abgänge':
                return <FaceRetouchingOffOutlinedIcon {...iconProps} />;
        default:
            return null;
    }
};

export default function DataCard({ chartData, category, sector, value, subHeaders = [], numDataPoints = 7 }) {
    const theme = useTheme();
    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate(`/controlling/overview/${sector}`); // Navigiere zu '/controlling/sector'
    };

    return (
        <Card sx={{
            padding: theme.spacing(2),
            backgroundColor: 'rgba(255, 255, 255, 0.7)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            alignItems: 'center',
        }}>
            <CardActionArea sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} onClick={handleNavigate}>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                    <Box>
                        <Stack direction="row" alignItems="center" spacing={1}>
                            {getCategoryIcon(category)}
                            <Typography
                                variant="h6"
                                className="db_datacard_category"
                                sx={{
                                    fontWeight: theme.typography.h5.fontWeight,
                                    fontSize: theme.typography.h6.fontSize,
                                    color: theme.palette.primary.main,
                                }}
                            >
                                {category}
                            </Typography>
                        </Stack>
                        <Typography
                            variant="h6"
                            className="db_datacard_value"
                            sx={{
                                fontWeight: theme.typography.h4.fontWeight,
                                fontSize: theme.typography.h4.fontSize,
                                color: theme.palette.primary.main,
                                mt: theme.spacing(1),
                            }}
                        >
                            {value}
                        </Typography>
                    </Box>

                    {subHeaders.map((subHeader, index) => (
                        <Typography
                            key={index}
                            variant="body2"
                            className="db_datacard_subheader"
                            sx={{
                                fontSize: theme.typography.body2.fontSize,
                                color: theme.palette.text.secondary,
                                mt: index === 0 ? theme.spacing(2) : theme.spacing(1),
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Box sx={{ mr: 1 }}>
                                {subHeader.startsWith('-') ? (
                                    <Box sx={{ color: theme.palette.error.main }}>↓</Box>
                                ) : (
                                    <Box sx={{ color: theme.palette.success.main }}>↑</Box>
                                )}
                            </Box>
                            {subHeader}
                        </Typography>
                    ))}
                </Box>

                <Box sx={{ width: '40%', flexShrink: 0 }}>
                    <Sparkline data={chartData} numDataPoints={numDataPoints} />
                </Box>
            </CardActionArea>
        </Card>
    );
}
