import React from 'react';
import { Card, CardContent, CardHeader, Paper, Typography, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/QueryStats';
import BarChartVerfuegbarkeit from '../charts/BarChartVerfuegbarkeit'; // Pfad zur MUI-X-Charts BarChart-Komponente

const BigBarChartVerfuegbarkeitCard = ({ data, title, labels, onSearchClick }) => {
    return (
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    padding: '0px',
                }}
            >
                <CardHeader
                    title={
                        <Typography variant="h6" component="div">
                            {title}
                        </Typography>
                    }
                    action={
                        <IconButton onClick={onSearchClick} sx={{ color: 'primary.main' }}>
                            <SearchIcon />
                        </IconButton>
                    }
                />
                <CardContent
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        padding: '16px',
                    }}
                >
                    <BarChartVerfuegbarkeit
                        data={data}
                        labels={labels}
                        sx={{ width: '100%', height: 'auto' }} // Sicherstellen, dass es die volle Breite nutzt
                    />
                </CardContent>
            </Card>
    );
};

export default BigBarChartVerfuegbarkeitCard;

