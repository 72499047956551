import React from 'react';
import ButtonGroupFilter from '../../components/filter/ButtonGroupFilter';

const WochentagFilter = ({ options, value, onChange }) => {
    return (
        <ButtonGroupFilter
            options={options}
            value={value}
            onChange={onChange}
        />
    );
};

export default WochentagFilter;
 