import React, { useEffect, useState, useRef } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    LabelList,
} from 'recharts';
import { useTheme } from '@mui/material/styles';
import useResizeObserver from 'use-resize-observer';

const BarChartHorizontal = ({ title, labels, data, hoch }) => {
    const theme = useTheme();
    const db_green_dark = '#B6CB83';
    const [chartData, setChartData] = useState([]);
    const chartContainerRef = useRef(); // Ref for the chart container
    const { width = 1 } = useResizeObserver({ ref: chartContainerRef });
    const [fontSize, setFontSize] = useState(14); // Default font size for labels
    const [leftMargin, setLeftMargin] = useState(100); // Default margin for labels

    useEffect(() => {
        // Prepare the chart data by combining labels and values
        const formattedData = labels.map((label, index) => ({
            name: label,
            value: data[index] || 0,
        }));
        setChartData(formattedData);
    }, [labels, data]);

    useEffect(() => {
        // Dynamically adjust the font size based on the container width
        if (width < 400) {
            setFontSize(10);
        } else if (width < 800) {
            setFontSize(12);
        } else {
            setFontSize(14);
        }
    }, [width]);

    useEffect(() => {
        // Calculate maximum label width and set left margin dynamically
        const tempDiv = document.createElement('div');
        document.body.appendChild(tempDiv);
        tempDiv.style.position = 'absolute';
        tempDiv.style.visibility = 'hidden';
        tempDiv.style.whiteSpace = 'nowrap';
        tempDiv.style.fontSize = `${fontSize}px`; // Use dynamic font size for the labels

        let maxWidth = 0;
        labels.forEach((label) => {
            tempDiv.textContent = label;
            maxWidth = Math.max(maxWidth, tempDiv.offsetWidth);
        });

        document.body.removeChild(tempDiv);

        // Add a 10px buffer to the calculated max width for better padding
        setLeftMargin(maxWidth + 10);
    }, [labels, fontSize]);

    // Define the container height based on the number of bars or a default height
    const containerHeight = hoch || labels.length * 50;

    return (
        <div ref={chartContainerRef} style={{ width: '100%', height: `${containerHeight}px`, overflowY: 'auto' }}>
            {title && (
                <h3
                    style={{
                        fontSize: fontSize + 2,
                        color: theme.palette.text.primary,
                        marginBottom: '10px',
                        textAlign: 'left',
                    }}
                >
                    {title}
                </h3>
            )}
            <ResponsiveContainer width="100%" height={containerHeight}>
                <BarChart
                    data={chartData}
                    layout="vertical"
                    margin={{ top: 10, right: 20, bottom: 20, left: leftMargin }}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        type="number"
                        tick={{ fontSize: fontSize, fill: theme.palette.text.primary }}
                        hide
                    />
                    <YAxis
                        type="category"
                        dataKey="name"
                        tick={{ fontSize: fontSize, fill: theme.palette.text.primary }}
                        width={leftMargin}
                    />

                    <Bar 
                        dataKey="value" 
                        fill={db_green_dark} // Set the custom color for the bars
                        radius={[5, 5, 0, 0]} 
                        barSize={20} // Make the bars thinner
                    >
                        <LabelList dataKey="value" position="insideRight" formatter={(value) => `${value}%`} style={{ fill: 'black' }} />
                    </Bar>
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

export default BarChartHorizontal;
