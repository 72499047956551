import { Button, Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const AccessDenied = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', pt: '0vh' }}>
            <Box sx={{ textAlign: 'center' }}>
                <Typography variant="h1" color="error.main">Zugriff Verweigert</Typography>
                <Box sx={{ my: 2 }}>
                    <Typography variant="h5">Du hast keine Berechtigung, diese Seite anzusehen.</Typography>
                </Box>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ fontSize: '1.15rem', padding: '6px 12px', minWidth: '120px' }}
                    onClick={() => navigate('/')}
                >
                    Zurück zur Startseite
                </Button>
            </Box>
        </Box>
    )
}

export default AccessDenied;
