import React, { createContext, useState } from 'react';

export const FilterContext = createContext();

export const FilterProvider = ({ children }) => {
    const [filterState, setFilterState] = useState({ filiale: null, datum: null, selectedEmployee: null });

    const handleFilialeChange = (newFiliale) => {
        setFilterState((prevState) => ({ ...prevState, filiale: newFiliale }));
    };

    const handleDatumChange = (newDatum) => {
        setFilterState((prevState) => ({ ...prevState, datum: newDatum }));
    };

    const handleEmployeeChange = (newEmployee) => {
        setFilterState((prevState) => ({ ...prevState, selectedEmployee: newEmployee }));
    };

    return (
        <FilterContext.Provider value={{ filterState, handleFilialeChange, handleDatumChange, handleEmployeeChange }}>
            {children}
        </FilterContext.Provider>
    );
};
