import React, { useEffect, useState } from 'react';
import { motion, useViewportScroll, useTransform } from 'framer-motion';
import logo from '../../../assets/images/logo.png'; // Korrigierter Logo-Import

// Festgelegte Farben für die Themen
const themeColors = {
  analytics: '#63AC73', // Grün
  personal: '#EFB358',  // Orange
  qualitaet: '#D03B56', // Rot
  team: '#4B568F',      // Dunkelblau
  databites: '#CA6790', // Rosa
  sortimente: '#EFB258' // Gold
};

// Array der Themenfarben
const themeColorsArray = Object.values(themeColors);

// Zufälligen Radius innerhalb eines Bereichs erzeugen
const getRandomRadius = () => 250 + Math.random() * 200; // Zwischen 250 und 450 px

const Ball = () => {
  const randomX = Math.random() * window.innerWidth;
  const randomY = Math.random() * window.innerHeight;
  const delay = Math.random() * 0.3; // Verzögerung weiter reduziert
  // Zufällige Farbe aus den Themenfarben auswählen
  const randomColor = themeColorsArray[
    Math.floor(Math.random() * themeColorsArray.length)
  ];
  const initialSize = Math.random() * 50 + 20;
  const duration = Math.random() * 0.5 + 0.5; // Dauer reduziert

  return (
    <motion.div
      style={{
        width: `${initialSize}px`,
        height: `${initialSize}px`,
        borderRadius: '50%',
        backgroundColor: randomColor,
        position: 'absolute',
        top: randomY,
        left: randomX,
      }}
      initial={{ scale: 1, opacity: 1 }}
      animate={{
        x: window.innerWidth / 3 + randomX / 2,
        y: window.innerHeight / 2 - randomY,
        scale: 0.1,
        opacity: 0,
      }}
      transition={{
        duration: duration,
        delay: delay,
        ease: 'easeIn',
      }}
    />
  );
};

const Header = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      width: '100%',
      height: '60px',
      backgroundColor: 'rgba(255, 255, 255, 0.1)', // Durchsichtiger Hintergrund
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      zIndex: 100,
    }}
  >
    <nav>
      <ul
        style={{
          display: 'flex',
          listStyle: 'none',
          margin: 0,
          padding: 0,
        }}
      >
        <li style={{ margin: '0 15px' }}>
          <a
            href="#analytics"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Analytics
          </a>
        </li>
        <li style={{ margin: '0 15px' }}>
          <a
            href="#personal"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Personal
          </a>
        </li>
        <li style={{ margin: '0 15px' }}>
          <a
            href="#qualität"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Qualität
          </a>
        </li>
        <li style={{ margin: '0 15px' }}>
          <a
            href="#team"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Team
          </a>
        </li>
        <li style={{ margin: '0 15px' }}>
          <a
            href="#databites"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Databites
          </a>
        </li>
        <li style={{ margin: '0 15px' }}>
          <a
            href="#sortimente"
            style={{ color: '#737373', textDecoration: 'none' }}
          >
            Sortimente
          </a>
        </li>
      </ul>
    </nav>
    <img src={logo} alt="Logo" style={{ height: '40px' }} />
  </div>
);

const Logo = () => {
  const { scrollY } = useViewportScroll();
  const scale = useTransform(scrollY, [0, 300], [1, 0.5]); // Verkleinert das Logo beim Scrollen
  const y = useTransform(scrollY, [0, 300], [0, -50]); // Bewegt das Logo nach oben

  return (
    <motion.img
      src={logo}
      alt="Logo"
      style={{
        width: '400px',
        height: 'auto',
        position: 'absolute',
        top: '50%',
        left: '50%',
        zIndex: 10,
        transform: 'translate(-50%, -50%)',
      }}
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: scale, opacity: 1, y: y }}
      transition={{ duration: 1.5, ease: 'easeOut' }}
    />
  );
};

const OrbitingBall = ({ angle, text, color }) => (
  <motion.div
    initial={{ scale: 0, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    transition={{ duration: 0.5, delay: 0 }} // Dauer und Verzögerung reduziert
    style={{
      width: '100px',
      height: '100px',
      borderRadius: '50%',
      backgroundColor: color,
      color: 'white',
      fontSize: '16px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'absolute',
      top: `calc(50% + ${getRandomRadius() * Math.sin(angle)}px)`,
      left: `calc(50% + ${getRandomRadius() * Math.cos(angle)}px)`,
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      fontWeight: 'bold',
    }}
  >
    {text}
  </motion.div>
);

const Section = ({ id, title, children, color, align }) => (
  <section
    id={id}
    style={{
      minHeight: '100vh',
      display: 'flex',
      backgroundColor: color,
      color: 'white',
    }}
  >
    {align === 'left' && (
      <>
        <div
          style={{
            flex: '1',
            padding: '80px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
          }}
        >
          <h1 style={{ fontSize: '3em', marginBottom: '20px' }}>{title}</h1>
          <p style={{ maxWidth: '800px', fontSize: '1.2em', lineHeight: '1.6' }}>
            {children}
          </p>
        </div>
        <div style={{ flex: '1' }}></div>
      </>
    )}
    {align === 'right' && (
      <>
        <div style={{ flex: '1' }}></div>
        <div
          style={{
            flex: '1',
            padding: '80px 20px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'right',
          }}
        >
          <h1 style={{ fontSize: '3em', marginBottom: '20px' }}>{title}</h1>
          <p style={{ maxWidth: '800px', fontSize: '1.2em', lineHeight: '1.6' }}>
            {children}
          </p>
        </div>
      </>
    )}
  </section>
);

const Footer = () => (
  <footer
    style={{
      padding: '20px',
      backgroundColor: '#333',
      color: 'white',
      textAlign: 'center',
    }}
  >
    <p>Impressum: Ihre Firma - Alle Rechte vorbehalten</p>
  </footer>
);

const FallingBallsPage = () => {
  const [balls, setBalls] = useState([]);
  const [showLogo, setShowLogo] = useState(false);
  const [showOrbitingBalls, setShowOrbitingBalls] = useState(false);

  useEffect(() => {
    const ballsArray = Array.from({ length: 150 }, (_, i) => (
      <Ball key={i} />
    ));
    setBalls(ballsArray);

    // Nach 1 Sekunde das Logo anzeigen
    const logoTimer = setTimeout(() => {
      setShowLogo(true);
    }, 1000);

    // Nach 1.5 Sekunden die orbitierenden Bälle anzeigen
    const orbitTimer = setTimeout(() => {
      setShowOrbitingBalls(true);
    }, 1500);

    return () => {
      clearTimeout(logoTimer);
      clearTimeout(orbitTimer);
    };
  }, []);

  return (
    <div style={{ overflowX: 'hidden' }}>
      <Header />
      {/* Animation und Logo */}
      <div
        style={{
          position: 'relative',
          height: '100vh',
          overflow: 'hidden',
        }}
      >
        {balls}
        {showLogo && <Logo />}
        {showOrbitingBalls && (
          <>
            <OrbitingBall
              angle={0}
              text="Analytics"
              color={themeColors.analytics}
            />
            <OrbitingBall
              angle={Math.PI / 3}
              text="Personal"
              color={themeColors.personal}
            />
            <OrbitingBall
              angle={(2 * Math.PI) / 3}
              text="Qualität"
              color={themeColors.qualitaet}
            />
            <OrbitingBall
              angle={Math.PI}
              text="Team"
              color={themeColors.team}
            />
            {/* Databites Ball weiter nach links verschoben */}
            <OrbitingBall
              angle={(5 * Math.PI) / 4}
              text="Databites"
              color={themeColors.databites}
            />
            <OrbitingBall
              angle={(5 * Math.PI) / 3}
              text="Sortimente"
              color={themeColors.sortimente}
            />
          </>
        )}
      </div>

      {/* Scrollbare Sektionen mit festen Farben und wechselnder Ausrichtung */}
      <Section
        id="analytics"
        title="Analytics"
        color={themeColors.analytics}
        align="left"
      >
        Entdecke die Kraft datengetriebener Erkenntnisse. Mit unseren
        fortschrittlichen Analysetools verwandeln wir Rohdaten in
        umsetzbares Wissen.
      </Section>
      <Section
        id="personal"
        title="Personal"
        color={themeColors.personal}
        align="right"
      >
        Stärken Sie Ihr Team mit einem personalisierten Ansatz. Wir
        fördern individuelles Wachstum und eine dynamische Kultur.
      </Section>
      <Section
        id="qualität"
        title="Qualität"
        color={themeColors.qualitaet}
        align="left"
      >
        Exzellenz steht im Mittelpunkt unseres Handelns. Unser Engagement
        für Qualität stellt sicher, dass wir Produkte und Dienstleistungen
        liefern, die die Erwartungen übertreffen.
      </Section>
      <Section id="team" title="Team" color={themeColors.team} align="right">
        Zusammenarbeit ist der Schlüssel. Unser Team arbeitet nahtlos
        zusammen und kombiniert vielfältige Fähigkeiten und Hintergründe.
      </Section>
      <Section
        id="databites"
        title="Databites"
        color={themeColors.databites}
        align="left"
      >
        Datenhäppchen mit großer Wirkung. Databites liefert schnelle,
        verdauliche Einblicke, damit Sie agil und reaktionsfähig bleiben.
      </Section>
      <Section
        id="sortimente"
        title="Sortimente"
        color={themeColors.sortimente}
        align="right"
      >
        Für den Erfolg kuratiert. Unsere Sortimente sind darauf ausgelegt,
        den Bedürfnissen einer vielfältigen Kundschaft gerecht zu werden.
      </Section>

      {/* Footer mit Impressum */}
      <Footer />
    </div>
  );
};

export default FallingBallsPage;
