// MultiSelectDropdownFilter.jsx

import React from 'react';
import { Autocomplete, TextField, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const MultiSelectDropdownFilter = ({ label, options, value = [], onChange }) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOptionToggle = (event, newValue) => {
    if (newValue.includes('all')) {
      if (value.length === options.length) {
        onChange([]);
      } else {
        onChange(options);
      }
    } else {
      onChange(newValue.filter(option => option !== 'all'));
    }
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={['all', ...options]}
      getOptionLabel={(option) => option}
      value={value.includes('all') ? ['all', ...options] : value}
      onChange={handleOptionToggle}
      sx={{ width: '100%', marginBottom: 1.5 }}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={option === 'all' ? value.length === options.length : selected}
          />
          {option === 'all' ? 'Alle auswählen/abwählen' : option}
        </li>
      )}
      renderTags={(selected, getTagProps) => {
        const displayedTags = selected.slice(0, 2);
        const hiddenTagsCount = selected.length - displayedTags.length;

        return (
          <>
            {displayedTags.map((option, index) => (
              option !== 'all' && (
                <Chip
                  key={option}
                  label={option}
                  size="small"
                  {...getTagProps({ index })}
                />
              )
            ))}
            {hiddenTagsCount > 0 && (
              <Chip
                label={`+${hiddenTagsCount}`}
                size="small"
              />
            )}
          </>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          size="small"
          sx={{ fontSize: '0.8rem' }}
        />
      )}
    />
  );
};

export default MultiSelectDropdownFilter;
