import React, { useContext, useEffect, useState, useMemo } from 'react';
import { Grid, Typography, Stack, Card, CardHeader, CardContent } from '@mui/material';
import Layout from '../../../components/layout/personalcontrolling/personalcontrolling';
import DataCard from '../../../components/card/scorecardsmonat/DataCardMonatPage';
import { FilterContext } from '../../../provider/FilterProvider';
import axiosInstance from '../../../services/axiosInstance';
import dayjs from 'dayjs';
import VariableTableTree from '../../../components/table/VariableTableTree';
import BigChartCard from "../../../components/card/scorecardsmonat/BigChartCardUnternehmen";
import BigChartCardRecharts from "../../../components/card/scorecardsmonat/BigChartCardRecharts";
import BigPieChartCardFluktuation from "../../../components/card/BigPieChartCardFluktuation"; // Pfad ggf. anpassen
import GroupRemove from '@mui/icons-material/GroupRemove';
import PageHeader from '../../../components/layout/Title/TitelSmall';  // Import der neuen Komponente



const Fluktuation = () => {
    const { filterState } = useContext(FilterContext);
    const [datumOptions, setDatumOptions] = useState([]);
    const [personalData, setPersonalData] = useState({
        fluktuation: [],
        krankenquote: [],
    });
    const [tableData, setTableData] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [salesAreaData, setSalesAreaData] = useState([]); // Fluktuation pro Verkaufsgebiet
    const [lineChartData, setLineChartData] = useState([]); // Daten für das Liniendiagramm der Verkaufsgebiete
    const [pieChartData, setPieChartData] = useState([]); // Daten für das PieChart

    useEffect(() => {
        fetchDatumOptions();
    }, []);

    useEffect(() => {
        if (filterState.datum) {
            fetchPersonalData(); // Fluktuationsdaten abrufen
            fetchTableData(); // Daten für die Tabelle abrufen
        }
    }, [filterState]);

    const fetchDatumOptions = async () => {
        try {
            const response = await axiosInstance.get('/stammdaten/unique-jahr-monat/');
            setDatumOptions(response.data.map(item => ({ label: item, value: item })));
        } catch (error) {
            console.error('Error while fetching datum options:', error);
        }
    };

    // Helper function to calculate the last 6 months based on the selected date
    const getLast6Months = (currentMonth) => {
        const last6Months = [];
        const formattedMonth = dayjs(currentMonth, 'YYYY.MM'); // Das gefilterte Datum korrekt formatieren

        for (let i = 0; i < 6; i++) {
            last6Months.push(formattedMonth.subtract(i, 'month').format('YYYY.MM')); // Rückwirkend vom gefilterten Monat die letzten 6 Monate berechnen
        }

        return last6Months.reverse(); // Optional, um die Monate in chronologischer Reihenfolge anzuzeigen (älteste zuerst)
    };

    const fetchPersonalData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM'); // Verwende das gefilterte Datum
        const last6Months = getLast6Months(currentMonth); // Letzten 6 Monate berechnen

        try {
            const responses = await Promise.all(
                last6Months.map(month => axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                    params: { jahrMonat: month },
                }))
            );

            // Kumulieren der Fluktuation-Daten
            const fluktuationData = responses.map((response, index) => {
                const data = response.data.results;

                const austritte6M = data.reduce((sum, item) => sum + (item.austritte6M || 0), 0);
                const mitarbeiterbestand6M = data.reduce((sum, item) => sum + (item.mitarbeiterbestand6M || 0), 0);
                const eintritte6M = data.reduce((sum, item) => sum + (item.eintritte6M || 0), 0);
                const köpfeDifferenz = data.reduce((sum, item) => sum + (item.köpfeDifferenz || 0), 0);
                const stundenDifferenz = data.reduce((sum, item) => sum + (item.stundenDifferenz || 0), 0);
                const überstundenAbs = data.reduce((sum, item) => sum + (item.überstundenAbs || 0), 0);

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
                    : 0;

                // Eintritte und Austritte im gefilterten Monat
                const eintritteM = data.reduce((sum, item) => sum + (item.eintritteM || 0), 0);
                const austritteM = data.reduce((sum, item) => sum + (item.austritteM || 0), 0);

                return {
                    month: last6Months[index],
                    fluktuation,
                    eintritteM,
                    austritteM,
                    köpfeDifferenz,
                    stundenDifferenz,
                    überstundenAbs,
                    mitarbeiterbestand6M, // Hinzufügen für die Berechnung in salesAreaData
                };
            });

            // Kumulieren der Krankenquote-Daten (optional, falls benötigt)
            const krankenquoteData = responses.map((response, index) => {
                const data = response.data.results;

                const arbeitszeitKrankenqoute = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqoute || 0), 0);
                const krankenzeit = data.reduce((sum, item) => sum + (item.krankenzeit || 0), 0);
                const arbeitszeitKrankenqouteVM = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVM || 0), 0);
                const krankenzeitVM = data.reduce((sum, item) => sum + (item.krankenzeitVM || 0), 0);
                const arbeitszeitKrankenqouteVJ = data.reduce((sum, item) => sum + (item.arbeitszeitKrankenqouteVJ || 0), 0);
                const krankenzeitVJ = data.reduce((sum, item) => sum + (item.krankenzeitVJ || 0), 0);

                // Berechne die Krankenquote
                const krankenquote = arbeitszeitKrankenqoute > 0 ? (krankenzeit / arbeitszeitKrankenqoute) * 100 : 0;
                const krankenquoteVM = arbeitszeitKrankenqouteVM > 0 ? (krankenzeitVM / arbeitszeitKrankenqouteVM) * 100 : 0;
                const krankenquoteVJ = arbeitszeitKrankenqouteVJ > 0 ? (krankenzeitVJ / arbeitszeitKrankenqouteVJ) * 100 : 0;

                return {
                    month: last6Months[index],
                    arbeitszeitKrankenqoute,
                    krankenzeit,
                    krankenquote,
                    krankenquoteVM,
                    krankenquoteVJ,
                };
            });

            // Berechnung der Fluktuation pro Verkaufsgebiet
            const salesAreaData = last6Months.map((month, index) => {
                const data = responses[index].data.results;

                // Gruppiere die Daten nach Verkaufsgebiet
                const salesAreaGroup = {};

                data.forEach(item => {
                    const salesArea = item.verkaufsgebiet;
                    if (!salesAreaGroup[salesArea]) {
                        salesAreaGroup[salesArea] = {
                            austritte6M: 0,
                            eintritte6M: 0,
                        };
                    }
                    salesAreaGroup[salesArea].austritte6M += item.austritte6M || 0;
                    salesAreaGroup[salesArea].eintritte6M += item.eintritte6M || 0;
                });

                // Berechne die Fluktuation pro Verkaufsgebiet
                const salesAreaRates = {};
                Object.keys(salesAreaGroup).forEach(salesArea => {
                    const austritte6M = salesAreaGroup[salesArea].austritte6M;
                    const eintritte6M = salesAreaGroup[salesArea].eintritte6M;
                    const mitarbeiterbestand6M = fluktuationData[index]?.mitarbeiterbestand6M || 0;
                    const fluktuation = (eintritte6M + mitarbeiterbestand6M) > 0
                        ? (austritte6M / (eintritte6M + mitarbeiterbestand6M)) * 100
                        : 0;
                    salesAreaRates[salesArea] = {
                        austritte6M,
                        eintritte6M,
                        fluktuation,
                    };
                });

                return {
                    month,
                    salesAreaRates,
                };
            });

            setPersonalData({
                fluktuation: fluktuationData,
                krankenquote: krankenquoteData,
            });

            setSalesAreaData(salesAreaData); // Setze die Daten für die Fluktuation pro Verkaufsgebiet

            // Daten für das Liniendiagramm vorbereiten
            // Erstelle für jedes Verkaufsgebiet eine Datenserie
            const salesAreas = new Set();
            salesAreaData.forEach(monthData => {
                Object.keys(monthData.salesAreaRates).forEach(salesArea => {
                    salesAreas.add(salesArea);
                });
            });

            const lineChartData = Array.from(salesAreas).map(salesArea => {
                return {
                    id: salesArea,
                    data: salesAreaData.map(monthData => {
                        const fluktuation = monthData.salesAreaRates[salesArea]
                            ? monthData.salesAreaRates[salesArea].fluktuation
                            : null; // Falls keine Daten vorhanden sind
                        return {
                            x: monthData.month,
                            y: fluktuation,
                        };
                    }),
                };
            });

            setLineChartData(lineChartData); // Setze die Daten für das Liniendiagramm

            // Daten für das Chart vorbereiten (gesamt)
            const chartData = fluktuationData.filter(item => item.month).flatMap(item => [
                { x: item.month, y: item.fluktuation, label: 'Fluktuation' },
               
            ]);

            setChartData(chartData);

            // Berechne die Summe der Austritte und Eintritte über die letzten 6 Monate
            const totalAustritte = fluktuationData.reduce((sum, item) => sum + (item.austritteM || 0), 0);
            const totalEintritte = fluktuationData.reduce((sum, item) => sum + (item.eintritteM || 0), 0);

            setPieChartData([
                { Typ: 'Austritte', ZuAbgang: totalAustritte },
                { Typ: 'Eintritte', ZuAbgang: totalEintritte },
            ]);

        } catch (error) {
            console.error('Error fetching personal data:', error);
        }
    };

    const fetchTableData = async () => {
        const currentMonth = filterState.datum?.value || dayjs().format('YYYY.MM');

        try {
            const response = await axiosInstance.get('/scorecards_month/personal-kennzahl/', {
                params: { jahrMonat: currentMonth },
            });

            const data = response.data.results;

            // Tabelle formatieren
            const formattedTableData = data.map((item) => {
                const austritte6M = item.austritte6M || 0;
                const eintritte6M = item.eintritte6M || 0;
                const mitarbeiterbestand6M = item.mitarbeiterbestand6M || 0;

                // Berechne die Fluktuation
                const fluktuation = (mitarbeiterbestand6M + eintritte6M) > 0
                    ? (austritte6M / (mitarbeiterbestand6M + eintritte6M)) * 100
                    : 0;

                return {
                    verkaufsgebiet: item.verkaufsgebiet,
                    filiale: item.filiale,
                    scoreFluktuation: item.scoreFluktuation || 0,
                    fluktuation: fluktuation,
                    eintritte6M: eintritte6M,
                    austritte6M: austritte6M,
                    eintritteM: item.eintritteM,
                    austritteM: item.austritteM,
                    mitarbeiterbestand6M: item.mitarbeiterbestand6M,
                    köpfeDifferenz: item.köpfeDifferenz || 0,
                    stundenDifferenz: item.stundenDifferenz || 0,
                    überstundenAbs: item.überstundenAbs || 0,
                };
            });

            setTableData(formattedTableData);

        } catch (error) {
            console.error('Error fetching table data:', error);
        }
    };

    // Definition der Spalten für die Tabelle
    const columns = useMemo(() => [
        {
            accessorKey: 'verkaufsgebiet',
            header: 'Verkaufsgebiet',
            enableGrouping: true,
            isVisible: true,
        },
        {
            accessorKey: 'filiale',
            header: 'Filiale',
            enableGrouping: false,
            isVisible: true,
        },
        {
            accessorKey: 'scoreFluktuation',
            header: 'Score',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => cell.getValue().toFixed(2),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(2),
            isVisible: true,
        },
        {
            accessorKey: 'fluktuation',
            header: 'Fluktuation',
            enableGrouping: false,
            aggregationFn: 'mean',
            Cell: ({ cell }) => (cell.getValue() !== null ? cell.getValue().toFixed(2) + '%' : '0.00%'),
            AggregatedCell: ({ cell, row }) => {
                const subRows = row.subRows || [];
                const aggregatedAustritte = subRows.reduce((total, subRow) => total + subRow.original.austritte6M, 0);
                const aggregatedMitarbeiterbestand = subRows.reduce((total, subRow) => total + subRow.original.eintritte6M + subRow.original.mitarbeiterbestand6M, 0);
                const fluktuation = (aggregatedMitarbeiterbestand) > 0 ? (aggregatedAustritte / aggregatedMitarbeiterbestand) * 100 : 0;
                return (
                    <span>
                        {fluktuation.toFixed(2)}%
                    </span>
                );
            },
            isVisible: true,
        },
        {
            accessorKey: 'austritteM',
            header: 'Austritte im Monat',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        {
            accessorKey: 'eintritteM',
            header: 'Eintritte im Monat',
            enableGrouping: false,
            aggregationFn: 'sum',
            Cell: ({ cell }) => cell.getValue().toFixed(0),
            AggregatedCell: ({ cell }) => cell.getValue().toFixed(0),
            isVisible: true,
        },
        // Weitere Spalten nach Bedarf hinzufügen
    ], []);

    return (
        <Layout>
            <Grid container justifyContent="flex-end" alignItems="stretch">
            <PageHeader
      title="Fluktuation "
      subtitle=" Dieser Bericht bietet eine Übersicht über die Fluktuation Ihrer Mitarbeiter, basierend auf den letzten sechs Monaten. Er analysiert die Eintritte und Austritte der Mitarbeiter und zeigt auf, wie sich diese auf die jeweiligen Kostenstellen verteilen. Die Datenvisualisierung ermöglicht es, Muster in der Mitarbeiterbewegung zu erkennen und gibt wertvolle Hinweise darauf, welche Bereiche von höherer Fluktuation betroffen sind, um entsprechende Maßnahmen ergreifen zu können."
      Icon={GroupRemove}
    />

                <Grid container spacing={2} mt={3}  >
      
                    {/* Linker Bereich: Liniendiagramme */}
                    <Grid item xs={12} lg={7}>
                        <Grid container spacing={2} direction="column">
                        
                        {/* Erstes Liniendiagramm */}
                        <Grid item xs={12}>
                            <BigChartCard
                            data={chartData}
                            title="Fluktuation und Vergleich der letzten 6 Monate"
                            xKey="month"
                            yKeys={['fluktuation']} // Anpassung je nach Verfügbarkeit der Vergleichsdaten
                            labels={['Fluktuation']}
                            />
                        </Grid>

                        {/* Zweites Liniendiagramm */}
                        <Grid item xs={12}>
                            <BigChartCardRecharts
                            data={[
                                {
                                id: 'Austritte',
                                data: personalData.fluktuation.map(item => ({
                                    x: item.month,
                                    y: item.austritteM,
                                })),
                                },
                                {
                                id: 'Eintritte',
                                data: personalData.fluktuation.map(item => ({
                                    x: item.month,
                                    y: item.eintritteM,
                                })),
                                },
                            ]}
                            title="Austritte und Eintritte über die letzten 6 Monate"
                            // Optional: height={400}
                            />
                        </Grid>
                        
                        </Grid>
                    </Grid>

                    {/* Rechter Bereich: Kreisdiagramm */}
                    <Grid 
                        item 
                        xs={12} 
                        lg={5} 
                        display="flex" 
                        flexDirection="column"
                        justifyContent="flex-start"
                    >
                        <BigPieChartCardFluktuation 
                        data={pieChartData} 
                        title="Eintritte vs. Austritte"
                        />
                    </Grid>
                    
                    </Grid>
                

                {/* Tabelle */}
                <Grid item xs={12} md={12} mt={3}>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            padding: '0px',
                            backgroundColor: 'rgba(255, 255, 255, 0.7)',
                            backdropFilter: 'blur(10px)',
                        }}
                    >
                        <CardHeader
                            title={
                                <Typography variant="h6" component="div">
                                    Fluktuation Übersicht
                                </Typography>
                            }
                        />
                        <CardContent sx={{ flexGrow: 1 }}>
                            <VariableTableTree
                                columns={columns}
                                data={tableData}
                                initialGrouping={['verkaufsgebiet']}
                            />
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Layout>
    );

};

export default Fluktuation;
