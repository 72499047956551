import React, { useState, useEffect } from 'react';
import {
    Box,
    TextField,
    Button,
    MenuItem,
    Typography,
    Divider,
    IconButton,
    CircularProgress,
    Alert,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import Layout from '../../../components/layout/Qualität/Qualität';
import PageHeader from '../../../components/layout/Title/TitelSmall';
import QuickreplyOutlinedIcon from '@mui/icons-material/QuickreplyOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import axiosInstance from '../../../services/axiosInstance';

const visibilityOptions = ['Alle', 'Spezifische Filialen', 'Verkaufsgebiete'];
const publishOptions = ['Sofort', 'Bestimmtes Datum und Uhrzeit'];

const CreatePost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [image, setImage] = useState(null); // Bild-Objekt mit { id, url }
    const [sender, setSender] = useState('');
    const [tags, setTags] = useState([]); // Tags aus dem Backend laden
    const [newTag, setNewTag] = useState(''); // Neuer State für das neue Tag
    const [selectedTag, setSelectedTag] = useState(''); // Ausgewähltes Tag (ID)
    const [visibility, setVisibility] = useState('Alle');
    const [selectedEntities, setSelectedEntities] = useState('');
    const [addPoll, setAddPoll] = useState(false); // Ob eine Umfrage hinzugefügt werden soll
    const [pollQuestion, setPollQuestion] = useState('');
    const [pollOptions, setPollOptions] = useState(['', '']);
    const [publishOption, setPublishOption] = useState('Sofort');
    const [publishDate, setPublishDate] = useState('');
    const [publishTime, setPublishTime] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submitSuccess, setSubmitSuccess] = useState(false);

    const [tagsLoading, setTagsLoading] = useState(true);
    const [tagsError, setTagsError] = useState(null);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                const response = await axiosInstance.get('/filialfeed/tags/');
                const tagData = response.data.results || response.data;
                setTags(tagData); 
                setTagsLoading(false);
            } catch (error) {
                console.error('Fehler beim Laden der Tags:', error);
                setTagsError('Fehler beim Laden der Tags');
                setTagsLoading(false);
            }
        };

        fetchTags();
    }, []);

    const handleAddOption = () => {
        setPollOptions([...pollOptions, '']);
    };

    const handleOptionChange = (index, value) => {
        const updatedOptions = [...pollOptions];
        updatedOptions[index] = value;
        setPollOptions(updatedOptions);
    };

    const handleAddTag = async () => {
        const trimmedTag = newTag.trim();
        if (trimmedTag !== '' && !tags.some((tag) => tag.name === trimmedTag)) {
            try {
                const response = await axiosInstance.post('/filialfeed/tags/', { name: trimmedTag });
                setTags([...tags, response.data]);
                setNewTag('');
            } catch (error) {
                console.error('Fehler beim Hinzufügen des Tags:', error);
                setTagsError('Fehler beim Hinzufügen des Tags');
            }
        }
    };

    const triggerFileSelect = () => {
        document.getElementById('file-upload').click();
    };

    const handleImageUpload = async (event) => {
        const file = event.target.files[0];

        if (!file) {
            console.error('Keine Datei ausgewählt');
            return;
        }

        const formData = new FormData();
        formData.append('image', file);
        formData.append('title', title || 'Post Bild');

        try {
            const uploadResponse = await axiosInstance.post('/pictures/upload/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            const image_id = uploadResponse.data.image_id;

            const urlResponse = await axiosInstance.get(`/pictures/get-image-url/${image_id}/`);
            const image_url = urlResponse.data.url;

            setImage({ id: image_id, url: image_url });
        } catch (error) {
            console.error('Fehler beim Hochladen des Bildes:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        setSubmitSuccess(false);

        const newPost = {
            title,
            content,
            image_id: image ? image.id : null, // Nur die Bild-ID übermitteln
            sender,
            tag_id: selectedTag,
            visibility,
            publish_date:
                publishOption === 'Bestimmtes Datum und Uhrzeit'
                    ? `${publishDate}T${publishTime}:00`
                    : new Date().toISOString(),
            filialen_ids:
                visibility === 'Spezifische Filialen'
                    ? selectedEntities.split(',').map((id) => id.trim())
                    : [],
            ...(addPoll && pollQuestion && {
                poll: {
                    question: pollQuestion,
                    options: pollOptions
                        .filter((option) => option.trim() !== '')
                        .map((optionText) => ({ option_text: optionText })),
                },
            }),
        };

        try {
            const response = await axiosInstance.post('/filialfeed/posts/', newPost);
            setSubmitSuccess(true);
            alert(`Post erfolgreich erstellt: ${JSON.stringify(response.data, null, 2)}`);
        } catch (error) {
            console.error('Fehler beim Erstellen des Posts:', error);
            setError('Es ist ein Fehler beim Erstellen des Posts aufgetreten.');
        } finally {
            setLoading(false);
        }
    };

    const resetForm = () => {
        setTitle('');
        setContent('');
        setImage(null);
        setSender('');
        setNewTag('');
        setSelectedTag('');
        setVisibility('Alle');
        setSelectedEntities('');
        setAddPoll(false);
        setPollQuestion('');
        setPollOptions(['', '']);
        setPublishOption('Sofort');
        setPublishDate('');
        setPublishTime('');
    };

    return (
        <Layout>
            <Box sx={{ flexGrow: 1, p: 3, maxWidth: 800, margin: '0 auto', bgcolor: 'transparent' }}>
                <PageHeader
                    title="Neuen Post erstellen"
                    subtitle="Erstelle einen neuen Post mit Titel, Inhalt, Bild, Absender, Tag, Umfrageoptionen, Sichtbarkeit und Veröffentlichungszeitpunkt."
                    Icon={QuickreplyOutlinedIcon}
                />

                <Box component="form" sx={{ mt: 3 }} onSubmit={handleSubmit}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {error}
                        </Alert>
                    )}
                    {submitSuccess && (
                        <Alert severity="success" sx={{ mb: 2 }}>
                            Post erfolgreich erstellt!
                        </Alert>
                    )}

                    <TextField
                        label="Titel"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    />

                    <TextField
                        label="Inhalt"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={content}
                        onChange={(e) => setContent(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    />

                    <Box sx={{ mb: 2 }}>
                        <Typography variant="h6">Bild hochladen</Typography>
                        <input
                            type="file"
                            id="file-upload"
                            style={{ display: 'none' }}
                            onChange={handleImageUpload}
                        />
                        <Button variant="contained" color="primary" onClick={triggerFileSelect}>
                            Bild auswählen
                        </Button>
                        {image && image.url && (
                            <Box mt={2}>
                                <Typography>Vorschau:</Typography>
                                <img src={image.url} alt="Bildvorschau" style={{ maxWidth: '100%', height: 'auto' }} />
                            </Box>
                        )}
                    </Box>

                    <TextField
                        label="Absender"
                        variant="outlined"
                        fullWidth
                        value={sender}
                        onChange={(e) => setSender(e.target.value)}
                        placeholder="z.B. Team Einkauf oder Tina Müller, Verkaufsleiterin"
                        sx={{ mb: 2 }}
                        required
                    />

                    {tagsLoading ? (
                        <CircularProgress sx={{ mb: 2 }} />
                    ) : tagsError ? (
                        <Alert severity="error" sx={{ mb: 2 }}>
                            {tagsError}
                        </Alert>
                    ) : (
                        <TextField
                            label="Tag"
                            variant="outlined"
                            fullWidth
                            select
                            value={selectedTag}
                            onChange={(e) => setSelectedTag(e.target.value)}
                            sx={{ mb: 2 }}
                            required
                        >
                            {tags.map((tag) => (
                                <MenuItem key={tag.id} value={tag.id}>
                                    {tag.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                        <TextField
                            label="Neues Tag erstellen"
                            variant="outlined"
                            value={newTag}
                            onChange={(e) => setNewTag(e.target.value)}
                            sx={{ mr: 2, flexGrow: 1 }}
                        />
                        <Button variant="contained" color="primary" onClick={handleAddTag}>
                            Tag hinzufügen
                        </Button>
                    </Box>

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h6" gutterBottom>
                        Sichtbarkeit
                    </Typography>
                    <TextField
                        label="Wer soll den Post sehen?"
                        variant="outlined"
                        fullWidth
                        select
                        value={visibility}
                        onChange={(e) => setVisibility(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    >
                        {visibilityOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {visibility !== 'Alle' && (
                        <TextField
                            label={
                                visibility === 'Spezifische Filialen'
                                    ? 'Filialen auswählen (IDs, getrennt durch Kommas)'
                                    : 'Verkaufsgebiete auswählen'
                            }
                            variant="outlined"
                            fullWidth
                            value={selectedEntities}
                            onChange={(e) => setSelectedEntities(e.target.value)}
                            placeholder={`Geben Sie die ${
                                visibility === 'Spezifische Filialen' ? 'Filialen-IDs' : 'Verkaufsgebiete'
                            } ein, getrennt durch Kommas`}
                            sx={{ mb: 2 }}
                            required
                        />
                    )}

                    <Divider sx={{ my: 2 }} />

                    <Typography variant="h6" gutterBottom>
                        Veröffentlichungszeitpunkt
                    </Typography>
                    <TextField
                        label="Veröffentlichen"
                        variant="outlined"
                        fullWidth
                        select
                        value={publishOption}
                        onChange={(e) => setPublishOption(e.target.value)}
                        sx={{ mb: 2 }}
                        required
                    >
                        {publishOptions.map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>

                    {publishOption === 'Bestimmtes Datum und Uhrzeit' && (
                        <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                            <TextField
                                label="Datum"
                                type="date"
                                fullWidth
                                value={publishDate}
                                onChange={(e) => setPublishDate(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                            <TextField
                                label="Uhrzeit"
                                type="time"
                                fullWidth
                                value={publishTime}
                                onChange={(e) => setPublishTime(e.target.value)}
                                InputLabelProps={{ shrink: true }}
                                required
                            />
                        </Box>
                    )}

                    <Divider sx={{ my: 2 }} />

                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={addPoll}
                                onChange={(e) => setAddPoll(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Umfrage hinzufügen?"
                        sx={{ mb: 2 }}
                    />

                    {addPoll && (
                        <>
                            <Typography variant="h6" gutterBottom>
                                Umfrage hinzufügen
                            </Typography>
                            <TextField
                                label="Umfragefrage"
                                variant="outlined"
                                fullWidth
                                value={pollQuestion}
                                onChange={(e) => setPollQuestion(e.target.value)}
                                sx={{ mb: 2 }}
                            />

                            {pollOptions.map((option, index) => (
                                <TextField
                                    key={index}
                                    label={`Option ${index + 1}`}
                                    variant="outlined"
                                    fullWidth
                                    value={option}
                                    onChange={(e) => handleOptionChange(index, e.target.value)}
                                    sx={{ mb: 1 }}
                                />
                            ))}

                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <IconButton color="primary" onClick={handleAddOption}>
                                    <AddCircleOutlineIcon />
                                </IconButton>
                                <Typography variant="body2">Option hinzufügen</Typography>
                            </Box>

                            <Divider sx={{ my: 2 }} />
                        </>
                    )}

                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        disabled={loading}
                        startIcon={loading && <CircularProgress size={20} />}
                    >
                        {loading ? 'Erstelle Post...' : 'Post erstellen'}
                    </Button>
                </Box>
            </Box>
        </Layout>
    );
};

export default CreatePost;
